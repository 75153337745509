import { createDiscountBanner, fetchDiscountBannersExtendCurrentParams } from 'store/actions/discountBanners'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    createDiscountBanner: (values) => dispatch(createDiscountBanner(values)),
    createDiscountBannerSuccess: () => {
        notification.success({
            message: 'Discount banner added!',
            description: 'A new discount banner was successfully created.',
        })
    },
    createDiscountBannerFailed: ({ message }) => {
        notification.error({
            message: 'Something went wrong!',
            description: message,
        })
    },
    fetchDiscountBanners: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchDiscountBannersExtendCurrentParams(paginationKey))
    },
})

const CreateDiscountBannerBannerRequest = withReduxState(null, mapDispatchToProps)

export default CreateDiscountBannerBannerRequest

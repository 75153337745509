import React from 'react'
import FilterBar from './FilterBar'
import useCurrentUser from '../../hooks/useCurrentUser'
import { useActiveFilters } from '../../hooks/useActiveFilters'

export default function FilterBarWithActiveFilters(props) {
    const userData = useCurrentUser()
    const {
        venueId,
        handleSetActiveVenue,
        handleSetActiveLegalEntity,
    } = useActiveFilters()

    return (
        <FilterBar
            {...props}
            legalEntities={userData.user.legalEntities}
            activeVenueId={venueId}
            setActiveVenue={handleSetActiveVenue}
            setActiveLegalEntity={handleSetActiveLegalEntity}
        />
    )
}

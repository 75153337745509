/* eslint-disable arrow-body-style */
import React, { useCallback } from 'react'
import { InputNumber } from 'antd'
import { negativeOrPositiveWithCurrency } from 'util/currency'
import { useLegalEntity } from 'components/hooks/useLegalEntity'
import useCurrencySymbol from 'components/hooks/useCurrencySymbol'

export function PriceInput({
    name,
    value,
    disabled,
    onChange,
    min = 0,
    currency,
}) {
    const legalEntity = useLegalEntity()
    if (typeof legalEntity === 'undefined')
        throw new Error(`Legal Entity is undefined! Comp: ${PriceInput.name}`)

    const currencySymbol = useCurrencySymbol(legalEntity)
    // const parsedCurrency = typeof currency !== 'undefined' ? currency : legalEntity.currency

    const formatter = useCallback((v) => {
        return negativeOrPositiveWithCurrency(v, currencySymbol)
    }, [currencySymbol])

    const parser = useCallback((v) => {
        return parseFloat(v.replace(currencySymbol, '').trim().replace(',', '.'))
    }, [currencySymbol])

    return (
        <InputNumber
            name={name}
            value={value}
            disabled={disabled}
            onChange={onChange}
            min={min}
            step={0.01}
            precision={2}
            formatter={formatter}
            parser={parser}
            className="full-width"
        />
    )
}

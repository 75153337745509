import { useReducer } from 'react'

const REQUEST = 'REQUEST'
const SUCCESS = 'SUCCESS'
const FAIL = 'FAIL'

/**
 * @typedef {Object} UseFetchPriceStateReturnObject
 * @property {{isLoading: boolean, data: v1BookingPriceOutputDataResource|null=, error: string=}} priceState
 * @property {() => void} setPriceRequest
 * @property {(data: v1BookingPriceOutputDataResource) => void} setPriceRequestSuccess
 * @property {(data?: string) => void} setPriceRequestFailed
 */

/**
 * @returns {UseFetchPriceStateReturnObject}
 */
export function useFetchPriceState() {

    const [priceState, dispatch] = useReducer((state, { type, data, error }) => {
        switch (type) {
            case REQUEST: return {
                ...state,
                isLoading: true,
            }
            case SUCCESS: return {
                ...state,
                data,
                isLoading: false,
            }
            case FAIL: return {
                ...state,
                isLoading: false,
                error,
            }
            default: throw new Error('Unexpected action')
        }
    }, {
        isLoading: false,
        data: {},
        error: '',
    })

    function setPriceRequest() {
        dispatch({ type: REQUEST })
    }

    function setPriceRequestSuccess(data) {
        dispatch({
            type: SUCCESS,
            data,
        })
    }

    function setPriceRequestFailed(data) {
        dispatch({
            type: FAIL,
            data: 'Something went wrong while fetching the price.' || data,
        })
    }

    return {
        priceState: priceState,
        setPriceRequest: setPriceRequest,
        setPriceRequestSuccess: setPriceRequestSuccess,
        setPriceRequestFailed: setPriceRequestFailed,
    }
}

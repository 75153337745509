import createEntityReducer from '../hor/createEntityReducer'
import {
    LEGAL_ENTITY_REQUEST,
    LEGAL_ENTITY_SUCCESS,
    LEGAL_ENTITY_FAILURE,
} from '../../actions/legalEntities'

const legalEntitiesReducer = createEntityReducer({
    types: [
        LEGAL_ENTITY_REQUEST,
        LEGAL_ENTITY_SUCCESS,
        LEGAL_ENTITY_FAILURE,
    ],
})

export default legalEntitiesReducer

import { getPagination, getEntitiesByPagination, getEntitiesForCurrentPage } from 'store/util'
import { getPaginationKey } from 'store/reducers/pagination/equipmentSetsPaginationReducer'
import { fetchEquipmentSetsForActiveVenue } from 'store/actions/equipmentSets'
import withReduxState from 'components/hoc/withReduxState'

const mapStateToProps = (state, ownProps) => {
    const { venueId } = state.activeFilters
    const { paginationKey } = ownProps
    const fullPaginationKey = getPaginationKey(
        paginationKey,
        venueId,
    )
    const pagination = getPagination(state, 'equipmentSets', fullPaginationKey)
    const {
        isFetching, currentPage, total, params,
    } = pagination
    return ({
        isFetching,
        currentPage,
        total,
        params,
        equipmentSets: getEntitiesByPagination(state, 'equipmentSets', fullPaginationKey),
        equipmentSetsForCurrentPage: getEntitiesForCurrentPage(state, 'equipmentSets', fullPaginationKey),
    })
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const {
        paginationKey,
        limit,
    } = ownProps
    const page = 1
    const sort = 'created_at.desc'
    return ({
        fetchEquipmentSets: (params) => {
            const finalParams = {
                page,
                limit,
                sort,
                ...params,
            }
            return dispatch(fetchEquipmentSetsForActiveVenue(paginationKey, finalParams))
        },
    })
}

const EquipmentSets = withReduxState(mapStateToProps, mapDispatchToProps)

export default EquipmentSets

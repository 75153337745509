/* eslint-disable react/require-default-props,react/forbid-prop-types */
import { Input } from 'antd'
import React from 'react'
import ReactInputMask from 'react-input-mask'
import PropTypes from 'prop-types'

function InputMask(props) {
    return (
        <ReactInputMask {...props}>
            { (inputProps) => <Input {...inputProps} disabled={props.disabled || null} /> }
        </ReactInputMask>
    )
}

InputMask.propTypes = {
    mask: PropTypes.string,
    maskChar: PropTypes.string,
    formatChars: PropTypes.object,
    alwaysShowMask: PropTypes.bool,
    inputRef: PropTypes.func,
}

export default InputMask

import React from 'react'
import { Form } from 'antd'

const SIZE = 24
const FIRST_COLUMN_SIZE = 8

/**
 * @component
 * @param {Object} props
 * @param {boolean=} props.isRequired
 * @param {string|React.Element=} props.label
 * @param {string|string[]|React.Element=} props.error
 * @param {string|React.Element|React.Element[]|null=} props.extra
 * @param {React.Element|React.Element[]|null} props.children
 */
export function BookingFormItem(props) {
    return (
        <Form.Item
            labelCol={{
                xs: { span: SIZE },
                sm: { span: FIRST_COLUMN_SIZE },
            }}
            wrapperCol={{
                xs: { span: SIZE },
                sm: { span: SIZE - FIRST_COLUMN_SIZE, offset: props.label ? 0 : FIRST_COLUMN_SIZE },
            }}
            required={props.isRequired}
            label={props.label}
            hasFeedback
            validateStatus={props.error ? 'error' : ''}
            extra={props.extra}
            help={props.error}
        >
            {props.children}
        </Form.Item>
    )
}

import React from 'react'
import './GiftCardDetailItem.css'

function GiftCardDetailItem({
    label,
    children,
}) {
    return (
        <li className="giftCardDetailItem">
            <span className="label">{label}</span>
            <span className="value">
                {children}
            </span>
        </li>
    )
}

export default GiftCardDetailItem

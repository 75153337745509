import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import CreateVenueGameRequest from 'components/pages/settings/venue-games/forms/create/CreateVenueGameRequest'
import { getEntityFromResponse } from 'util/index'
import { toVenueGameApiRequest } from 'util/transformers/venueGame'
import { getVenueGameErrors } from 'util/validation/venueGameErrors'
import { isValidVenueGame } from 'util/validation/venueGame'
import { useLegalEntity } from 'components/hooks/useLegalEntity'

function CreateVenueGameFormState({
    onCancel,
    onCreateSuccess,
    children,
}) {
    const legalEntity = useLegalEntity()
    if (typeof legalEntity === 'undefined')
        throw new Error(`Legal Entity is undefined! Comp: ${CreateVenueGameFormState.name}`)

    return (
        <CreateVenueGameRequest paginationKey="venueGamesOverview">
            {({
                createVenueGame,
                createVenueGameSuccess,
                createVenueGameFailed,
                fetchVenueGames,
            }) => (
                <WithFormState
                    initialValues={{
                        minPlayers: 0,
                        maxPlayers: 0,
                        baseTotalPrice: 0,
                        basePlayerPrice: 0,
                    }}
                    isValidForm={(values) => isValidVenueGame(values, legalEntity.priceSystem)}
                    getFormErrors={(values) => getVenueGameErrors(values, legalEntity.priceSystem)}
                    transformValuesToRequestBody={(values) => toVenueGameApiRequest(values)}
                    onSendRequest={(values) => {
                        return createVenueGame(values)
                    }}
                    onCancel={onCancel}
                    onRequestSuccess={(response) => {
                        const venueGame = getEntityFromResponse(response, 'venueGames')
                        fetchVenueGames()
                        createVenueGameSuccess(venueGame)
                        onCreateSuccess(venueGame)
                    }}
                    onRequestFailed={createVenueGameFailed}
                >
                    {children}
                </WithFormState>
            )}
        </CreateVenueGameRequest>
    )
}

export default CreateVenueGameFormState

import { createValidationErrorObject } from './index'
import * as validation from './coupon'

export const getWeekDayError = createValidationErrorObject(
    validation.isValidWeekDay,
    'day',
    'Please select a weekday.',
)

export const getStartTimeError = createValidationErrorObject(
    validation.isValidStartTime,
    'from',
    'Please provide a valid start time.',
)

export const getEndTimeError = createValidationErrorObject(
    validation.isValidEndTime,
    'until',
    'Please provide a valid end time.',
)

export const getStartIsNotbeforeEndError = createValidationErrorObject(
    validation.startIsBeforeEnd,
    'from',
    'Start time must be before end time.',
)

export const getEndIsNotAfterStartError = createValidationErrorObject(
    validation.endIsAfterstart,
    'until',
    'End time must be after start time.',
)

export const getCouponTimeFrameErrors = (values) => ({
    ...getWeekDayError(values.day),
    ...getStartTimeError(values.isWholeDay, values.from, values.until),
    ...getEndTimeError(values.isWholeDay, values.from, values.until),
    ...getStartIsNotbeforeEndError(values.isWholeDay, values.from, values.until),
    ...getEndIsNotAfterStartError(values.isWholeDay, values.from, values.until),
})

import React from 'react'
import { editVenue, fetchVenuesExtendCurrentParams } from 'store/actions/venues'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    editVenue: (values) => dispatch(editVenue(ownProps.venue.id, values)),
    editVenueSuccess: (venue) => {
        notification.success({
            message: 'Venue edited!',
            description: (
                <span>
                    The venue&nbsp;
                    <strong>{venue.name}</strong>
                    &nbsp;
                    was successfully edited.
                </span>
            ),
        })
    },
    editVenueFailed: ({ message }) => {
        notification.error({
            message: 'Something went wrong',
            description: message,
        })
    },
    fetchVenues: () => {
        const { paginationKey } = ownProps
        return dispatch(fetchVenuesExtendCurrentParams(paginationKey))
    },
})

const EditVenueRequest = withReduxState(null, mapDispatchToProps)

export default EditVenueRequest

import React from 'react'
import {
    Form, Card, Input, Button,
} from 'antd'
import FormItem from 'components/util/forms/FormItem'
import { Link } from 'react-router-dom'

function ForgotPassword({
    values,
    errors,
    handleNativeChange,
    onSubmit,
    loading,
}) {
    return (
        <Card title="Reset Password" extra={<Link to="/">Back</Link>} style={{ width: 400 }}>
            <Form onFinish={onSubmit} className="login-form">
                <FormItem
                    error={errors.email}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="E-mail"
                    autoComplete="email"
                >
                    <Input
                        placeholder="E-mail"
                        name="email"
                        value={values.email}
                        onChange={handleNativeChange}
                    />
                </FormItem>

                <Button
                    type="primary"
                    onClick={onSubmit}
                    loading={loading}
                    htmlType="submit"
                >
                    Send Password Reset Link
                </Button>
            </Form>
        </Card>
    )
}

export default ForgotPassword

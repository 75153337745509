import React from 'react'
import './CouponDetailTitle.css'

function CouponDetailTitle({
    emoji,
    text,
}) {
    return (
        <h3>
            <span className="titleImage" role="img" aria-label={text}>{emoji}</span>
            {text}
        </h3>
    )
}

export default CouponDetailTitle

import React from 'react'
import { Select } from 'antd'

function BookingDealTypeSelect({
    value,
    placeholder = 'Deal Type',
    onChange,
    disabled = false,
    bookingDealTypes,
}) {
    return (
        <Select
            value={value}
            onChange={onChange}
            disabled={disabled}
            placeholder={placeholder}
        >
            <Select.Option
                key={null}
                value={null}
            >
                No Deal
            </Select.Option>
            {bookingDealTypes.map((dealType) => (
                <Select.Option
                    key={dealType.code}
                    value={dealType.code}
                >
                    {dealType.name}
                </Select.Option>
            ))}
        </Select>
    )
}

export default BookingDealTypeSelect

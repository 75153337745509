import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import { MODAL_IDS } from 'util/constants'

function CreatePriceAdjustmentModalState({ venueGame, children }) {
    return (
        <WithActiveModal
            modalId={MODAL_IDS.CREATE_PRICE_ADJUSTMENT}
            entity={venueGame}
            entityKey="venueGame"
        >
            {children}
        </WithActiveModal>
    )
}

export default CreatePriceAdjustmentModalState

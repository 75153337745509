/* eslint-disable import/named */
import React, { useEffect } from 'react'
import { useLegalEntity } from '../../../hooks/useLegalEntity'
import GlobalSettingsContainer from '../../../layout/sidebar/GlobalSettingsContainer'
import { usePaginatedVoucherIssueReasons } from '../../../hooks/usePaginatedVoucherIssueReasons'
import { usePaginatedVenues } from '../../../hooks/usePaginatedVenues'
import { ROLES, MODAL_IDS } from '../../../../util/constants'
import HasPermissions from '../../authorized/HasPermissions'
import NotAuthorized from '../../authorized/NotAuthorized'
import { VouchersTableWithState } from './table/VouchersTableWithState'
import { useUserRole } from '../../../hooks/useCurrentUserRole'
import useModal from '../../../hooks/useModal'
import { useLegalEntitiesArray } from '../../../hooks/useLegalEntitiesArray'
import { CreateVoucherModal } from './modals/CreateVoucherModal'
import { DeleteVoucherModal } from './modals/DeleteVoucherModal'
import { EditVoucherModal } from './modals/EditVoucherModal'
import {Main} from "../../../layout/Main"
import {TopBar} from "../../../layout/TopBar"

const paginationKey = 'overview'
const tableAuthorisedRoleSlugs = [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.READ_ONLY, ROLES.FRANCHISE]

export default function VouchersPage() {
    const createCouponModal = useModal(MODAL_IDS.CREATE_VOUCHER)
    const editVoucherModal = useModal(MODAL_IDS.EDIT_VOUCHER)
    const deleteVoucherModal = useModal(MODAL_IDS.DELETE_VOUCHER)

    const activeLegalEntity = useLegalEntity()
    if (typeof activeLegalEntity === 'undefined')
        throw new Error(`Legal Entity is undefined! Comp: ${VouchersPage.name}`)
    const legalEntities = useLegalEntitiesArray()
    // console.log("Legal entities:", legalEntities)

    const userRole = useUserRole()
    const venuesPagination = usePaginatedVenues(paginationKey)
    const voucherIssueReasonsPagination = usePaginatedVoucherIssueReasons(paginationKey)

    useEffect(() => {
        // Initial fetch
        if (voucherIssueReasonsPagination.initialFetchStarted === false && voucherIssueReasonsPagination.isFetching === false) {
            voucherIssueReasonsPagination.fetchVoucherIssueReasons({})
        }
    }, [voucherIssueReasonsPagination])
    useEffect(() => {
        // Initial fetch
        if (venuesPagination.initialFetchStarted === false && venuesPagination.isFetching === false) {
            venuesPagination.fetchVenues({ page: 1, limit: 500 })
        }
    }, [venuesPagination])

    // console.log("Voucher issue reasons:", voucherIssueReasonsPagination.voucherIssueReasonsForCurrentPage)
    // console.log('Venues:', venuesPagination.venuesForCurrentPage)

    return (
        <>
            <TopBar activeMenuItem="globalSettings" />
            <Main>
                <GlobalSettingsContainer activeMenuItem="vouchers">
                    <HasPermissions
                        roles={tableAuthorisedRoleSlugs}
                        renderNotAuthorized={() => <NotAuthorized />}
                    >
                        <VouchersTableWithState
                            currentUserRoleSlug={userRole !== null ? userRole.slug : ''}
                            openCreateModal={createCouponModal.openModal}
                            openEditModal={editVoucherModal.openModalWithEntity}
                            openDeleteModal={deleteVoucherModal.openModalWithEntity}
                            legalEntities={legalEntities}
                            voucherIssueReasons={voucherIssueReasonsPagination.voucherIssueReasonsForCurrentPage}
                            venues={venuesPagination.venuesForCurrentPage}
                        />
                    </HasPermissions>
                    <CreateVoucherModal
                        isOpen={createCouponModal.isOpen}
                        closeModal={createCouponModal.closeModal}
                        activeLegalEntityId={activeLegalEntity.id}
                        legalEntities={legalEntities}
                        voucherIssueReasons={voucherIssueReasonsPagination.voucherIssueReasonsForCurrentPage}
                        venues={venuesPagination.venuesForCurrentPage}
                    />
                    <EditVoucherModal
                        isOpen={editVoucherModal.isOpen}
                        closeModal={editVoucherModal.closeModal}
                        voucher={editVoucherModal.entity}
                        legalEntities={legalEntities}
                        voucherIssueReasons={voucherIssueReasonsPagination.voucherIssueReasonsForCurrentPage}
                        venues={venuesPagination.venuesForCurrentPage}
                    />
                    <DeleteVoucherModal
                        isOpen={deleteVoucherModal.isOpen}
                        onClose={deleteVoucherModal.closeModal}
                        voucher={deleteVoucherModal.entity}
                    />
                </GlobalSettingsContainer>
            </Main>
        </>
    )
}

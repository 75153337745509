import { Component } from 'react'
import { notification } from 'antd'
import callApi from 'store/middleware/callApi'
import store from 'store/reduxStore'
import { setDiscountBannerActive, setDiscountBannerInactive } from 'store/actions/discountBanners'
import { METHODS } from 'store/middleware/api'

class ToggleDiscountBannerRequest extends Component {
    constructor(props) {
        super(props)
        this.state = { isLoading: false, errors: [] }
        this.toggleActiveStatus = this.toggleActiveStatus.bind(this)
    }

    async toggleActiveStatus(discountBannerId, value) {
        this.setState({ isLoading: true })
        const { venueId } = this.props
        const endPoint = `/venues/${venueId}/discount-banners/${discountBannerId}/toggle-active`
        try {
            await callApi(METHODS.PATCH, endPoint)
            if (value) {
                store.dispatch(setDiscountBannerActive(discountBannerId))
            } else {
                store.dispatch(setDiscountBannerInactive(discountBannerId))
            }
            this.setState({ isLoading: false })
        } catch (e) {
            this.setState({ isLoading: false, errors: e })
            notification.error({
                message: 'Something went wrong',
                description: `${e.message}`,
            })
        }
    }

    render() {
        const { isLoading, errors } = this.state
        return this.props.children({
            toggleActiveStatus: this.toggleActiveStatus,
            isLoading,
            errors,
        })
    }
}

export default ToggleDiscountBannerRequest

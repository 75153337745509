import { getEntitiesByPagination, getPagination, getEntitiesForCurrentPage } from 'store/util/index'
import { getPaginationKey } from 'store/reducers/pagination/roomsPaginationReducer'
import withReduxState from 'components/hoc/withReduxState'
import { fetchRoomsForActiveVenue } from 'store/actions/rooms'

const mapStateToProps = (state, ownProps) => {
    const { venueId } = state.activeFilters
    const { paginationKey } = ownProps
    const fullPaginationKey = getPaginationKey(
        paginationKey,
        venueId,
    )
    const pagination = getPagination(state, 'rooms', fullPaginationKey)
    return ({
        isFetching: pagination.isFetching,
        currentPage: pagination.params.page,
        total: pagination.total,
        params: pagination.params,
        rooms: getEntitiesByPagination(state, 'rooms', fullPaginationKey),
        roomsForCurrentPage: getEntitiesForCurrentPage(state, 'rooms', fullPaginationKey),
    })
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const {
        paginationKey,
        limit,
    } = ownProps
    const page = 1
    return ({
        fetchRooms: (params) => {
            const finalParams = {
                page,
                limit,
                ...params,
            }
            return dispatch(fetchRoomsForActiveVenue(paginationKey, finalParams))
        },
    })
}

const Rooms = withReduxState(mapStateToProps, mapDispatchToProps)

export default Rooms

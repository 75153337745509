import {useCallback, useEffect, useState} from "react"
import callApi, { callApiWithOptions } from "store/middleware/callApi"

const FetchState = {
    IDLE: "idle",
    FETCHING: "fetching",
    SUCCESS: "success",
    ERROR: "error",
}

export function useBookingDealTypes() {
    const [bookingDealTypes, setBookingDealTypes] = useState([])
    const [fetchState, setFetchState] = useState(FetchState.IDLE)

    const fetchAllBookingDealTypes = useCallback(async () => {
        setFetchState(FetchState.FETCHING)
        try {
            const response = await callApi("GET", "/deal-types")
            setBookingDealTypes(response.data)
        } catch (error) {
            setFetchState(FetchState.ERROR)
        }
    }, [])

    useEffect(() => {
        fetchAllBookingDealTypes()
    }, [fetchAllBookingDealTypes])

    return {
        fetchState,
        bookingDealTypes,
        fetchAllBookingDealTypes,
    }
}

/* eslint-disable radix */
import React from 'react'
import { Select } from 'antd'

const { Option, OptGroup } = Select

export default function FilterBar({
    isDisabled = false,
    legalEntities,
    activeVenueId,
    setActiveVenue,
    setActiveLegalEntity,
}) {
    // Find the legal entity by active venue id
    const legalEntity = legalEntities.find((le) => (
        le.venues.find((venue) => venue.id === activeVenueId)
    ))
    // Get selected venues from legal entity
    const selectedVenues = legalEntity.venues
    // Find the selected venue by id
    const selectedVenueName = selectedVenues.find((venue) => venue.id === activeVenueId).name

    return (
        <div className="filterBar">
            <Select
                disabled={isDisabled}
                value={selectedVenueName || ''}
                style={{ width: 150 }}
                onChange={(value, { entity }) => {
                    setActiveLegalEntity(entity.id)
                    setActiveVenue(value === '' ? null : value)
                }}
            >
                {legalEntities.map((entity) => (
                    <OptGroup
                        key={entity.id}
                        label={entity.name}
                    >
                        {entity.venues.map((venue) => (
                            <Option
                                key={venue.id}
                                value={venue.id}
                                entity={entity}
                            >
                                {venue.name}
                            </Option>
                        ))}
                    </OptGroup>
                ))}
            </Select>
        </div>
    )
}

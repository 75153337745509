import { useSelector } from 'react-redux'
import { useMemo } from 'react'

/**
 * @typedef {Object} Country
 * @property {string} code
 * @property {string} currencyCode
 * @property {boolean} isEu
 * @property {string} name
 * @property {string} localizedName
 * @property {string} phoneCountryCode
 * @property {string|undefined} vatCountryCode
 * @property {string|undefined} vatFormat
 * @property {number|undefined} vatFormatMaxLength
 * @property {number|undefined} vatFormatMinLength
 */

// TODO: Add financial config
/**
 * @typedef {Object} LegalEntity
 * @property {number} id
 * @property {boolean} public
 * @property {string} name
 * @property {number} countryId
 * @property {Country} country
 * @property {number} financialConfigId
 * @property {Object} financialConfig
 * @property {number} vatPercentage VAT percentage for ticket sales
 * @property {number} drinksUnitPrice
 * @property {number} drinksVatPercentage
 * @property {number} openbarUnitPrice
 * @property {number} openbarVatPercentage
 * @property {"per_player"} priceSystem
 * @property {string[]} supportedLanguages
 */

/**
 * Only use hook when legal entities have already been loaded into the Redux store
 * @returns {LegalEntity[]}
 */
export function useLegalEntitiesArray() {
    const legalEntities = useSelector(state => state.entities.legalEntities)

    const legalEntitiesArray = useMemo(() => {
        return Object.values(legalEntities)
    }, [legalEntities])

    return legalEntitiesArray
}

import React from 'react'
import { useSelector } from 'react-redux'
import { WithFormState } from 'components/util/forms/WithFormState'
import { getEntityFromResponse } from 'util/index'
import { toBookingApiRequest } from 'util/transformers/booking'
import { isValidCreateBookingForm } from 'util/validation/booking'
import { getBookingErrors } from 'util/validation/bookingErrors'
import CreateBookingRequest from '../../requests/CreateBookingRequest'
import {roomModes} from "../../../../../util/constants"

/** @type {CreateOrEditBookingFormValues} */
const initialValues = {
    // Booking data
    playerAmount: 5,
    gameId: null,
    gameLanguageCode: null,
    roomId: null,
    isPrivate: true,
    isForcedPrivate: false,
    bookingStartDate: null,
    bookingStartTime: null,
    introductionDuration: 0,
    addBuffersBefore: false,
    addBuffersAfter: false,
    roomMode: roomModes.all.value,

    // Booker data
    bookerEmail: null,
    bookerFirstName: null,
    bookerLastName: null,
    bookerPhoneNumber: null,
    bookerLanguageCode: null,

    // Company data
    isCompanyBooking: false,
    companyName: null,
    companyCountryCode: null,
    companyAddress: null,
    companyZipCode: null,
    companyCity: null,
    companyVatNumber: null,
    companyHasVatNumber: true,
    purchaseOrderNumber: null,

    // Public (or online) upselling
    drinksChecked: false,
    openBarChecked: false,
    nachosChecked: false,
    drinkTokensChecked: false,

    // Private (or offline) upselling
    cateringChecked: false,
    infoCatering: null,
    meetingRoomChecked: false,
    infoMeetingRoom: null,
    barForFaitChecked: false,
    infoBarForFait: null,

    // Financial
    markAsAlreadyPaid: false,

    // Discounts
    couponCode: null,
    isSocialDeal: false,
    socialDealDiscountPercentage: null,
    socialDealFixedAmount: null,

    // Meta
    comment: null,
    bookingDealType: null,
    sendConfirmationEmail: false,
}

/**
 * @param {Object} props
 * @param {() => void} props.onCancel
 * @param {Function} props.onCreateSuccess
 * @param {Function} props.onCreateFailed
 * @param {(props: WithFormStateChildProps) => React.Element|React.Element[]|null=} props.children
 * @return {JSX.Element}
 */
export function CreateBookingFormState(props) {
    const activeFilters = useSelector(state => state.activeFilters)

    return (
        <CreateBookingRequest paginationKey="overview">
            {({
                fetchBookings,
                createBooking,
                createBookingSuccess,
                createBookingFailed,
            }) => (
                <WithFormState
                    initialValues={initialValues}
                    isValidForm={isValidCreateBookingForm}
                    getFormErrors={getBookingErrors}
                    onCancel={props.onCancel}
                    transformValuesToRequestBody={toBookingApiRequest}
                    onSendRequest={(values) => createBooking({...values, legalEntityId: activeFilters.legalEntityId})}
                    onRequestSuccess={(response) => {
                        const booking = getEntityFromResponse(response, 'bookings')
                        fetchBookings()
                        createBookingSuccess(booking)
                        props.onCreateSuccess(booking)
                    }}
                    onRequestFailed={(error) => {
                        createBookingFailed(error)
                        if (typeof props.onCreateFailed === "function")
                            props.onCreateFailed(error)
                    }}
                >
                    {props.children}
                </WithFormState>
            )}
        </CreateBookingRequest>
    )
}

import React from 'react'
import { Form, Input } from 'antd'
import FormItem from 'components/util/forms/FormItem'

function MultiLangVenueForm({
    language,
    values,
    errors,
    onChange,
}) {
    return (
        <Form>
            <FormItem
                label="Name"
                required
                error={errors[`name${language}`]}
            >
                <Input
                    name={`name${language}`}
                    value={values[`name${language}`]}
                    placeholder="e.g. Antwerpen"
                    onChange={onChange}
                />
            </FormItem>
            <FormItem
                label="Address"
                required
                error={errors[`address${language}`]}
            >
                <Input
                    name={`address${language}`}
                    value={values[`address${language}`]}
                    placeholder="e.g. Belgiëlei 143, 2000 Antwerpen"
                    onChange={onChange}
                />
            </FormItem>
        </Form>
    )
}

export default MultiLangVenueForm

import React from 'react'
import CreateDiscountBannerFormState, { EMPTY_DISCOUNT_BLOCK } from 'components/pages/settings/discount-banners/forms/create/CreateDiscountBannerFormState'
import CreateDiscountBannerModal from './CreateDiscountBannerModal'
import CreateDiscountBannerModalState from './CreateDiscountBannerModalState'

function CreateDiscountBannerModalWithState() {
    return (
        <CreateDiscountBannerModalState>
            {({ isOpen, closeModal }) => (
                <CreateDiscountBannerFormState onCancel={closeModal} onSaveSuccess={closeModal}>
                    {({
                        values,
                        errors,
                        isLoading,
                        handleChange,
                        handleChangeAsValue,
                        handleCancel,
                        handleSubmit,
                    }) => (
                        <CreateDiscountBannerModal
                            isOpen={isOpen}
                            onClose={closeModal}
                            values={values}
                            errors={errors}
                            isLoading={isLoading}
                            onChange={handleChange}
                            onChangeAsValue={handleChangeAsValue}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                            addBlock={() => handleChangeAsValue('blocks', [
                                ...values.blocks,
                                {
                                    id: values.blocks.length,
                                    ...EMPTY_DISCOUNT_BLOCK,
                                },
                            ])}
                        />
                    )}
                </CreateDiscountBannerFormState>
            )}
        </CreateDiscountBannerModalState>
    )
}

export default CreateDiscountBannerModalWithState

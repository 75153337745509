import React from 'react'
import { Main } from  'components/layout/Main'
import { TopBar } from 'components/layout/TopBar'
import VenueSettingsContainer from 'components/layout/sidebar/VenueSettingsContainer'
import CreatePriceAdjustmentModalWithState from './modals/create-price-adjustment/CreatePriceAdjustmentModalWithState'
import DeletePriceAdjustmentModalWithRedux from './modals/delete-price-adjustment/DeletePriceAdjustmentModalWithRedux'
import EditPriceAdjustmentModalWithState from './modals/edit-price-adjustment/EditPriceAdjustmentModalWithState'
import VenueGamesOverview from './overview/VenueGamesOverview'

function VenueGamesPage() {
    return (
        <>
            <TopBar activeMenuItem="venueSettings" />
            <Main>
                <VenueSettingsContainer activeMenuItem="venueGames">
                    <VenueGamesOverview paginationKey="venueGamesOverview" />
                </VenueSettingsContainer>
            </Main>
            <CreatePriceAdjustmentModalWithState />
            <DeletePriceAdjustmentModalWithRedux />
            <EditPriceAdjustmentModalWithState />
        </>
    )
}

export default VenueGamesPage

import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'

const ID = 'CreateRoomModal'

function CreateRoomModalState({ children }) {
    return <WithActiveModal modalId={ID}>{children}</WithActiveModal>
}

export default CreateRoomModalState

import {isPositiveNumberOrZero, isRequired, isValidHref, isVat100Percentage} from "util/validation/index"
import {LANGUAGES} from "../constants"
import {capitalizeFirstChar} from "../string-utils"

/**
 * @param {string} locale
 * @param {(key: string) => boolean} hasError
 * @return {boolean}
 */
export function legalEntityFormLocalizedKeyHasError(locale, hasError) {
    const key = capitalizeFirstChar(locale.toLowerCase())
    return hasError("termsAndConditions" + key)
        || hasError("privacyPolicy" + key)
}

export const legalEntityFormValidation = [
    {
        path: "name",
        validate: isRequired,
        message: "Please fill in a name.",
    },
    {
        path: "address",
        validate: isRequired,
        message: "Please fill in a valid address.",
    },
    {
        path: "termsAndConditionsNl",
        validate: (value, values) => {
            if (values.supportedLanguages.indexOf(LANGUAGES.nl) === -1)
                return true

            return isRequired(value) && isValidHref(value)
        },
        message: "Please fill in a valid terms and conditions url.",
    },
    {
        path: "termsAndConditionsEn",
        validate: (value, values) => {
            if (values.supportedLanguages.indexOf(LANGUAGES.en) === -1)
                return true

            return isRequired(value) && isValidHref(value)
        },
        message: "Please fill in a valid terms and conditions url.",
    },
    {
        path: "termsAndConditionsFr",
        validate: (value, values) => {
            if (values.supportedLanguages.indexOf(LANGUAGES.fr) === -1)
                return true

            console.log("Got here")
            console.log("Index of:", values.supportedLanguages.indexOf(LANGUAGES.fr))

            return isRequired(value) && isValidHref(value)
        },
        message: "Please fill in a valid terms and conditions url.",
    },
    {
        path: "termsAndConditionsPt",
        validate: (value, values) => {
            if (values.supportedLanguages.indexOf(LANGUAGES.pt) === -1)
                return true

            return isRequired(value) && isValidHref(value)
        },
        message: "Please fill in a valid terms and conditions url.",
    },
    {
        path: "privacyPolicyNl",
        validate: (value, values) => {
            if (values.supportedLanguages.indexOf(LANGUAGES.nl) === -1)
                return true

            return isRequired(value) && isValidHref(value)
        },
        message: "Please fill in a valid terms and conditions url.",
    },
    {
        path: "privacyPolicyEn",
        validate: (value, values) => {
            if (values.supportedLanguages.indexOf(LANGUAGES.en) === -1)
                return true

            return isRequired(value) && isValidHref(value)
        },
        message: "Please fill in a valid terms and conditions url.",
    },
    {
        path: "privacyPolicyFr",
        validate: (value, values) => {
            if (values.supportedLanguages.indexOf(LANGUAGES.fr) === -1)
                return true

            return isRequired(value) && isValidHref(value)
        },
        message: "Please fill in a valid terms and conditions url.",
    },
    {
        path: "privacyPolicyPt",
        validate: (value, values) => {
            if (values.supportedLanguages.indexOf(LANGUAGES.pt) === -1)
                return true

            return isRequired(value) && isValidHref(value)
        },
        message: "Please fill in a valid terms and conditions url.",
    },
    {
        path: "supportedLanguages",
        validate: isRequired,
        message: "Please select supportedLanguages.",
    },
    {
        path: "bic",
        validate: isRequired,
        message: "Please fill in a valid bic.",
    },
    {
        path: "accountNumber",
        validate: isRequired,
        message: "Please fill in a valid account number.",
    },
    {
        path: "countryCode",
        validate: isRequired,
        message: "Please select a country.",
    },
    {
        path: "vatNumber",
        validate: isRequired,
        message: "Please fill in a game VAT number.",
    },
    {
        path: "vatPercentage",
        validate: (value) => isRequired(value) && isVat100Percentage(value),
        message: "Please fill in a valid game VAT percentage.",
    },
    {
        path: "openBarVatPercentage",
        validate: (value) => isRequired(value) && isVat100Percentage(value),
        message: "Please fill in a valid Open bar VAT percentage.",
    },
    {
        path: "openBarUnitPriceInclVat",
        validate: (value) => isRequired(value) && isPositiveNumberOrZero(value),
        message: "Please fill in a valid Open bar Unit Price.",
    },
    {
        path: "drinksVatPercentage",
        validate: (value) => isRequired(value) && isVat100Percentage(value),
        message: "Please fill in a valid drinks VAT percentage.",
    },
    {
        path: "drinksUnitPriceInclVat",
        validate: (value) => isRequired(value) && isPositiveNumberOrZero(value),
        message: "Please fill in a valid drinks unit price.",
    },
    {
        path: "nachosVatPercentage",
        validate: (value) => isRequired(value) && isVat100Percentage(value),
        message: "Please fill in a valid nachos VAT percentage.",
    },
    {
        path: "nachosUnitPriceInclVat",
        validate: (value) => isRequired(value) && isPositiveNumberOrZero(value),
        message: "Please fill in a valid nachos unit price.",
    },
    {
        path: "drinkTokensVatPercentage",
        validate: (value) => isRequired(value) && isVat100Percentage(value),
        message: "Please fill in a valid 2 drink tokens VAT percentage.",
    },
    {
        path: "drinkTokensUnitPriceInclVat",
        validate: (value) => isRequired(value) && isPositiveNumberOrZero(value),
        message: "Please fill in a valid 2 drink tokens unit price.",
    },
]

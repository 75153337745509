import React from 'react'
import CreateVenueGameButton from '../forms/CreateVenueGameButton'
import './NoVenueGames.css'

function NoVenueGames() {
    return (
        <div className="full-height">
            <div className="flex justify-content-space-between no-venue-games-top-bar">
                <h3>Games</h3>
                <CreateVenueGameButton />
            </div>
            <div className="noVenueGamesContainer">
                <p>Start by adding games for this venue</p>
            </div>
        </div>
    )
}

export default NoVenueGames

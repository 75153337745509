import React from 'react'
import { Modal } from 'antd'
import CreateCouponFormState from 'components/pages/settings/coupons/forms/create/CreateCouponFormState'
import CreateCouponForm from 'components/pages/settings/coupons/forms/create/CreateCouponForm'
import { ModalFooter } from 'components/util/modals/ModalFooter'

function CreateCouponModal({
    isOpen,
    onClose,
    activeLegalEntity,
    legalEntities,
}) {
    return (
        <CreateCouponFormState
            onSaveSuccess={onClose}
            onCancel={onClose}
        >
            {({
                values,
                errors,
                isLoading,
                isSubmitted,
                handleChange,
                handleSubmit,
                handleCancel,
                handleChangeAsValue,
                mergeValues,
            }) => (
                <Modal
                    title="Create new coupon"
                    open={isOpen}
                    onCancel={handleCancel}
                    onOk={handleSubmit}
                    closable={!isLoading}
                    maskClosable={!isLoading}
                    footer={(
                        <ModalFooter
                            isLoading={isLoading}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                        />
                    )}
                >
                    {isOpen && (
                        <CreateCouponForm
                            isLoading={isLoading}
                            isSubmitted={isSubmitted}
                            values={values}
                            errors={errors}
                            onChange={handleChange}
                            onChangeAsValue={handleChangeAsValue}
                            mergeValues={mergeValues}
                            legalEntity={legalEntities[values.legalEntity] || activeLegalEntity}
                        />
                    )}
                </Modal>
            )}
        </CreateCouponFormState>
    )
}

export default CreateCouponModal

import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import { MODAL_IDS } from 'util/constants'

function EditGameModalState({ children, game }) {
    return (
        <WithActiveModal
            modalId={MODAL_IDS.EDIT_GAME}
            entity={game}
            entityKey="game"
        >
            {children}
        </WithActiveModal>
    )
}

export default EditGameModalState

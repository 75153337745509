import { createValidationErrorObject } from "./index"
import * as validation from "./outstandingAmount"

export const getOutstandingAmountError = createValidationErrorObject(
    validation.isValidAmount,
    "outstandingAmount",
    "Please fill in a valid amount.",
)

export const getOutstandingAmountErrors = (values) => ({
    ...getOutstandingAmountError(values.outstandingAmount),
})

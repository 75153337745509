import React from 'react'
import ActiveFilters from 'components/layout/filters/WithActiveFilters'
import { useNavigate } from 'react-router'
import ToggleActiveGameSessionRequest from '../../requests/ToggleActiveGameSessionRequest'
import {WithFormState} from "../../../../util/forms/WithFormState"

function ToggleActiveGameSessionFormState({
    onCancel,
    onToggleSuccess = () => {},
    gameSession,
    children,
}) {
    const navigate = useNavigate()

    return (
        <ActiveFilters>
            {({ activeVenueId }) => (
                <ToggleActiveGameSessionRequest
                    venueId={activeVenueId}
                    gameSessionRoomId={gameSession && gameSession.room.id}
                >
                    {({
                        toggleActiveStatus,
                    }) => (
                        <WithFormState
                            key={gameSession && gameSession.id}
                            initialValues={gameSession && {
                                activeInRoomId: gameSession.room.id,
                                gameSessionId: gameSession.id,
                            }}
                            isValidForm
                            getFormErrors={false}
                            onSendRequest={({ gameSessionId, activeInRoomId }) => (
                                toggleActiveStatus(gameSessionId, activeInRoomId, true)
                            )}
                            onCancel={onCancel}
                            onRequestSuccess={() => {
                                onToggleSuccess()
                                navigate(`/game-sessions/${gameSession.id}`)
                            }}
                        >
                            {children}
                        </WithFormState>
                    )}
                </ToggleActiveGameSessionRequest>
            )}
        </ActiveFilters>
    )
}

export default ToggleActiveGameSessionFormState

import React from "react"
import { deleteBookingForActiveVenue, fetchBookingsExtendCurrentParams } from "store/actions/bookings"
import withReduxState from "components/hoc/withReduxState"
import { notification } from "antd"

const mapDispatchToProps = (dispatch, ownProps) => ({
    deleteBooking: (/** BookingResource */ booking) => dispatch(deleteBookingForActiveVenue(booking.id)),
    deleteBookingSuccess: (/** BookingResource */ booking) => {
        notification.success({
            message: "Booking deleted!",
            description: (
                <span>
                    Booking for&nbsp;
                    <strong>{booking && (booking.bookerFirstName + " " + booking.bookerLastName) }</strong>
                    &nbsp;
                    is succesfully deleted.
                </span>
            ),
        })
    },
    deleteBookingFailed: (error) => {
        notification.error({
            message: "Something went wrong",
            description: `${error.message}`,
        })
    },
    fetchBookings: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchBookingsExtendCurrentParams(paginationKey))
    },
})

export const DeleteBookingRequest = withReduxState(null, mapDispatchToProps)

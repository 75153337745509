import React from 'react'
import { InputNumber, Checkbox } from 'antd'
import Entity from 'components/util/Entity'
import { PLAYER_AND_TOTAL } from 'util/constants'
import {GamesSelect} from "../../../settings/games/selects/GamesSelect"
import {BookingFormItem} from "./BookingFormItem"
import SupportedLanguageSelect from "../../../../util/SupportedLanguageSelect"

/**
 * @component
 * @param {Object} props
 * @param {CreateOrEditBookingFormValues} props.values
 * @param {{ [Key in keyof CreateOrEditBookingFormValues]: string[] }} props.errors
 * @param {(e: any) => void} props.onChange
 * @param {(key: keyof CreateOrEditBookingFormValues, value: CreateOrEditBookingFormValues[key]) => void } props.onChangeAsValue
 * @param {(gameId: number, gameVenue: GameVenueResource|null) => void} props.onChangeGame
 * @param {(playerAmount: number, maxPlayers: number) => void} props.onChangePlayer
 * @param {string} props.priceSystem
 * @param {GameVenueResource[]} props.gameVenues
 * @returns {React.Element}
 */
export function BookingPlayersGameItems(props) {
    return (
        <Entity entityKey="venueGames" id={props.values.gameId}>
            {({ maxPlayers, minPlayers }) => (
                <div>
                    <BookingFormItem
                        isRequired
                        label="VR experience"
                        error={props.errors.gameId}
                    >
                        <GamesSelect
                            value={props.values.gameId}
                            onChange={props.onChangeGame}
                            gameVenues={props.gameVenues}
                        />
                    </BookingFormItem>
                    <BookingFormItem
                        isRequired
                        label="Player amount"
                        error={props.errors.playerAmount}
                    >
                        <InputNumber
                            name="players"
                            min={minPlayers}
                            value={props.values.playerAmount}
                            onChange={(value) => props.onChangePlayer(value, maxPlayers)}
                            disabled={typeof props.values.gameId !== "number"}
                        />
                        {/* {teamAmount > 0 && (
                            <TeamsInfo>
                                <strong>{teamAmount}</strong>
                                {' '}
                                Team(s)
                            </TeamsInfo>
                        )} */}
                    </BookingFormItem>
                    <BookingFormItem
                        isRequired
                        label="VR experience language"
                        error={props.errors.gameLanguageCode}
                    >
                        <SupportedLanguageSelect
                            value={props.values.gameLanguageCode}
                            onChange={(value) => props.onChangeAsValue("gameLanguageCode", value)}
                        />
                    </BookingFormItem>
                    {
                        props.priceSystem === PLAYER_AND_TOTAL && (
                            <BookingFormItem>
                                <Checkbox
                                    name="isPrivate"
                                    checked={props.values.isPrivate || props.values.playerAmount >= maxPlayers}
                                    onChange={props.onChange}
                                    disabled={props.values.playerAmount >= maxPlayers || typeof props.values.gameId === "undefined"}
                                >
                                    Private Game
                                </Checkbox>
                            </BookingFormItem>
                        )
                    }
                </div>
            )}
        </Entity>
    )
}

import createPaginationReducer from '../hor/createPaginationReducer'
import {
    VENUE_GAMES_REQUEST,
    VENUE_GAMES_SUCCESS,
    VENUE_GAMES_FAILURE,
} from '../../actions/venueGames'

export const getPaginationKey = (componentName, venueId) => `${componentName}(venues/${venueId})`

const venueGamesPaginationReducer = createPaginationReducer({
    mapActionToKey: ({ componentName, venueId }) => getPaginationKey(componentName, venueId),
    types: [
        VENUE_GAMES_REQUEST,
        VENUE_GAMES_SUCCESS,
        VENUE_GAMES_FAILURE,
    ],
})

export default venueGamesPaginationReducer

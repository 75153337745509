import React from 'react'
import { Link } from 'react-router-dom'
import { Alert } from 'antd'
import ActiveFilters from 'components/layout/filters/WithActiveFilters'
import Mount from 'components/util/Mount'
import ActiveGameSessionRequest from '../requests/ActiveGameSessionRequest'

const gameSessionIsFromAnotherRoom = ({ room, activeInRoom }) => room.id !== activeInRoom.id

function ActiveGameSessionInfo() {
    return (
        <ActiveFilters>
            {({ activeVenueId, activeRoomId }) => {
                if (activeRoomId === null) {
                    return null
                }
                return (
                    <ActiveGameSessionRequest
                        venueId={activeVenueId}
                        roomId={activeRoomId}
                    >
                        {({
                            isFetching,
                            fetchActiveGameSession,
                            activeGameSession,
                            activeGameSessionIds,
                        }) => (
                            <Mount
                                key={`${activeGameSessionIds.length}/${activeRoomId}`}
                                didMount={fetchActiveGameSession}
                            >
                                {(() => {
                                    // requires an explict check for null and undefined i.e Strict equality
                                    if (isFetching || (activeGameSession === null || activeGameSession === undefined)) {
                                        return null
                                    }
                                    let activeGameSessionText = 'There is currently a game session active in this room.'
                                    if (gameSessionIsFromAnotherRoom(activeGameSession)) {
                                        activeGameSessionText = `There is currently a game session from ${activeGameSession.room.name} active in this room.`
                                    }
                                    return (
                                        <Alert
                                            message="Active game session"
                                            description={[
                                                <span key="hack">
                                                    {activeGameSessionText}
                                                    <br />
                                                    <Link to={`/game-sessions/${activeGameSession.id}`}>
                                                        Click here
                                                    </Link>
                                                    {' '}
                                                    to view the active game session.
                                                </span>,
                                            ]}
                                            type="info"
                                            className="margin-bottom"
                                        />
                                    )
                                })()}
                            </Mount>
                        )}
                    </ActiveGameSessionRequest>
                )
            }}
        </ActiveFilters>
    )
}

export default ActiveGameSessionInfo

import React from 'react'
import { editGiftCard, fetchGiftCardsExtendCurrentParams } from 'store/actions/giftCards'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    editGiftCard: (values) => dispatch(editGiftCard(ownProps.giftCard.id, values)),
    editGiftCardSuccess: (giftCard) => {
        notification.success({
            message: 'Gift card edited!',
            description: (
                <span>
                    The gift card&nbsp;
                    <strong>{giftCard.couponCode}</strong>
                    &nbsp;
                    was successfully edited.
                </span>
            ),
        })
    },
    editGiftCardFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchGiftCards: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchGiftCardsExtendCurrentParams(paginationKey))
    },
})

const EditGiftCardRequest = withReduxState(null, mapDispatchToProps)

export default EditGiftCardRequest

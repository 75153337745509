import React from 'react'
import { Select } from 'antd'
import Mount from 'components/util/Mount'
import Games from 'components/pages/settings/games/WithGames'

const PAGE_SIZE = 100

function GamesSelect({
    value,
    placeholder = 'Select a game',
    onChange,
    disabled = false,
}) {
    return (
        <Games paginationKey="selectGames" limit={PAGE_SIZE}>
            {({ fetchGames, isFetching, gamesForCurrentPage }) => (
                <Mount didMount={() => fetchGames()}>
                    <Select
                        value={value}
                        onChange={onChange}
                        disabled={isFetching || disabled}
                        placeholder={isFetching
                            ? 'Loading games...'
                            : placeholder}
                    >
                        {gamesForCurrentPage.map((game) => (
                            <Select.Option
                                key={game.id}
                                value={game.id}
                            >
                                {game.title}
                            </Select.Option>
                        ))}
                    </Select>
                </Mount>
            )}
        </Games>
    )
}

export default GamesSelect

import createEntityReducer from '../hor/createEntityReducer'
import {
    BOOKING_REQUEST,
    BOOKING_SUCCESS,
    BOOKING_FAILURE,
} from '../../actions/bookings'

const bookingsReducer = createEntityReducer({
    types: [
        BOOKING_REQUEST,
        BOOKING_SUCCESS,
        BOOKING_FAILURE,
    ],
})

export default bookingsReducer

import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import { getEntityFromResponse } from 'util/index'
import { isValidCouponTimeFrame } from 'util/validation/coupon'
import { getCouponTimeFrameErrors } from 'util/validation/couponErrors'
import { toCouponTimeFrameApiRequest, mapCouponTimeFrameToValues } from 'util/transformers/coupon'
import EditCouponTimeFrameRequest from './EditCouponTimeFrameRequest'

function EditCouponTimeFrameFormState({
    onCancel,
    onEditSuccess = () => {},
    children,
    couponTimeFrame,
}) {
    return (
        <EditCouponTimeFrameRequest
            paginationKey="overview"
            couponTimeFrame={couponTimeFrame}
        >
            {({
                fetchCoupons,
                editCouponTimeFrame,
                editCouponTimeFrameSuccess,
                editCouponTimeFrameFailed,
            }) => {
                if (couponTimeFrame === null || typeof couponTimeFrame === 'undefined') {
                    return null
                }
                return (
                    <WithFormState
                        key={couponTimeFrame && couponTimeFrame.id}
                        initialValues={{
                            ...mapCouponTimeFrameToValues(couponTimeFrame),
                        }}
                        isValidForm={isValidCouponTimeFrame}
                        getFormErrors={getCouponTimeFrameErrors}
                        transformValuesToRequestBody={toCouponTimeFrameApiRequest}
                        onSendRequest={(values) => editCouponTimeFrame(couponTimeFrame.id, values)}
                        onCancel={onCancel}
                        onRequestSuccess={(response) => {
                            fetchCoupons()
                            const updatedCouponTimeFrame = getEntityFromResponse(response, 'couponTimeFrames')
                            editCouponTimeFrameSuccess(updatedCouponTimeFrame)
                            onEditSuccess(updatedCouponTimeFrame)
                        }}
                        onRequestFailed={editCouponTimeFrameFailed}
                    >
                        {children}
                    </WithFormState>
                )
            }}
        </EditCouponTimeFrameRequest>
    )
}

export default EditCouponTimeFrameFormState

import React from 'react'
import { deleteCouponTimeFrame, fetchCouponsExtendCurrentParams } from 'store/actions/coupons'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    deleteCouponTimeFrame: (timeFrame) => dispatch(deleteCouponTimeFrame(timeFrame.id)),
    deleteCouponTimeFrameSuccess: () => {
        notification.success({
            message: 'Timeframe deleted!',
            description: (
                <span>
                    The selected time frame is succesfully deleted.
                </span>
            ),
        })
    },
    deleteCouponTimeFrameFailed: ({ message }) => {
        notification.error({
            message: 'Something went wrong',
            description: message,
        })
    },
    fetchCoupons: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchCouponsExtendCurrentParams(paginationKey))
    },
})

const DeleteCouponTimeFrameRequest = withReduxState(null, mapDispatchToProps)

export default DeleteCouponTimeFrameRequest

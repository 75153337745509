import React, { useState, useEffect } from 'react'
import { notification } from 'antd'
import { createFormValidation } from 'use-form-state'
import { useDispatch } from 'react-redux'
import { MODAL_IDS } from 'util/constants'
import useFormState from 'components/hooks/useDynamicLanguageFormState'
import useModal from 'components/hooks/useModal'
import { useAppState } from 'components/hooks/useAppState'
import { createLegalEntity } from 'store/actions/legalEntities'
import handleApiErrorResponse from 'util/errorResponse'
import { toLegalEntityApiRequest } from 'util/transformers/legalEntity'
import {legalEntityFormLocalizedKeyHasError, legalEntityFormValidation} from 'util/validation/legalEntities'
import {CreateLegalEntityModal} from "./CreateLegalEntityModal"

const INITIAL_STATE = {
    public: false,
    name: '',
    address: '',

    bic: '',
    rpr: '',
    accountNumber: null,
    countryCode: null,
    vatNumber: '', // game vat number

    // These have to be here to trigger validation
    termsAndConditionsEn: null,
    termsAndConditionsNl: null,
    termsAndConditionsFr: null,
    termsAndConditionsPt: null,

    // These have to be here to trigger validation
    privacyPolicyEn: null,
    privacyPolicyNl: null,
    privacyPolicyFr: null,
    privacyPolicyPt: null,

    supportedLanguages: [],

    vatPercentage: 0.0, // game vat percentage

    openBarVatPercentage: 0,
    openBarUnitPriceInclVat: 0.0,

    drinksVatPercentage: 0,
    drinksUnitPriceInclVat: 0.0,

    nachosVatPercentage: 0,
    nachosUnitPriceInclVat: 0.0,

    drinkTokensVatPercentage: 0,
    drinkTokensUnitPriceInclVat: 0.0,

    mediaItem: {},
}

/**
 * @component
 * @param {Object} props
 * @param {() => void} props.onComplete
 */
export function CreateLegalEntityModalWithState(props) {
    const dispatch = useDispatch()
    const { isOpen, closeModal } = useModal(MODAL_IDS.CREATE_LEGAL_ENTITY)
    const [isUploadingImage, setIsUploadingImage] = useState(false)
    const isLoading = false
    const { currencies, countries } = useAppState()
    const formState = useFormState(INITIAL_STATE, {
        validation: createFormValidation(legalEntityFormValidation),
    })

    const handleSubmit = async () => {
        const isValid = formState.validate()
        console.log("Form is valid:", isValid)
        if (isValid) {
            try {
                const response = await dispatch(
                    createLegalEntity(toLegalEntityApiRequest(formState.values)),
                )

                if (response.error) {
                    handleApiErrorResponse(response.error, formState.updateErrors, true)
                } else {
                    notification.success({
                        message: 'Legal entity added!',
                        description: (
                            <span>
                                A new legal entity&nbsp;
                                <strong>{formState.values.nameEn}</strong>
                                &nbsp;
                                was successfully created.
                            </span>
                        ),
                    })
                    closeModal()
                    props.onComplete()
                }
            } catch (error) {
                notification.error({
                    message: 'Something went wrong',
                    description: `${error.message}`,
                })
            }
        }
    }

    useEffect(() => {
        formState.resetForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    /**
     * @param {string} locale
     * @returns {boolean}
     */
    function hasLocaleError(locale) {
        const hasError = legalEntityFormLocalizedKeyHasError(locale, formState.hasError)
        return hasError
    }

    return (
        <CreateLegalEntityModal
            isOpen={isOpen}
            onClose={closeModal}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            isUploadingImage={isUploadingImage}
            setIsUploadingImage={setIsUploadingImage}
            currencies={currencies}
            countries={countries}
            formState={formState}
            hasLocaleError={hasLocaleError}
            isCreateForm
        />
    )
}

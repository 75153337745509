// eslint-disable-next-line import/no-cycle
import { getPaginationKey } from 'store/reducers/pagination/equipmentSetsPaginationReducer'
import { CALL_API, METHODS } from '../middleware/api'
import { equipmentSetSchema, equipmentSetSchemaArray } from '../schemas/equipmentSets'

export const EQUIPMENT_SETS_REQUEST = 'EQUIPMENT_SETS_REQUEST'
export const EQUIPMENT_SETS_SUCCESS = 'EQUIPMENT_SETS_SUCCESS'
export const EQUIPMENT_SETS_FAILURE = 'EQUIPMENT_SETS_FAILURE'

export const EQUIPMENT_SET_REQUEST = 'EQUIPMENT_SET_REQUEST'
export const EQUIPMENT_SET_SUCCESS = 'EQUIPMENT_SET_SUCCESS'
export const EQUIPMENT_SET_FAILURE = 'EQUIPMENT_SET_FAILURE'

export const RELEASE_EQUIPMENT_SET = 'RELEASE_EQUIPMENT_SET'

export const fetchEquipmentSets = (componentName, venueId, params) => ({
    componentName,
    venueId,
    [CALL_API]: {
        types: [
            EQUIPMENT_SETS_REQUEST,
            EQUIPMENT_SETS_SUCCESS,
            EQUIPMENT_SETS_FAILURE,
        ],
        endpoint: `/venues/${venueId}/equipment-sets`,
        schema: equipmentSetSchemaArray,
        params,
    },
})

export const fetchEquipmentSetsForActiveVenue = (componentName, params) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch(fetchEquipmentSets(componentName, venueId, params))
}

export const fetchEquipmentSetsExtendCurrentParams = (paginationKey, params) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    const equipmentSetPagination = getState().pagination.equipmentSets[getPaginationKey(paginationKey, venueId)]
    const newParams = {
        ...equipmentSetPagination.params,
        ...params,
    }
    return dispatch(fetchEquipmentSets(paginationKey, venueId, newParams))
}

export const createEquipmentSet = (venueId, body) => ({
    [CALL_API]: {
        method: METHODS.POST,
        types: [
            EQUIPMENT_SET_REQUEST,
            EQUIPMENT_SET_SUCCESS,
            EQUIPMENT_SET_FAILURE,
        ],
        endpoint: `/venues/${venueId}/equipment-sets`,
        schema: equipmentSetSchema,
        body,
    },
})

export const createEquipmentSetForActiveVenue = (body) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch(createEquipmentSet(venueId, body))
}

export const deleteEquipmentSet = (equipmentSetId) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch({
        [CALL_API]: {
            method: METHODS.DELETE,
            types: [
                EQUIPMENT_SET_REQUEST,
                EQUIPMENT_SET_SUCCESS,
                EQUIPMENT_SET_FAILURE,
            ],
            endpoint: `/venues/${venueId}/equipment-sets/${equipmentSetId}`,
            schema: equipmentSetSchema,
            entityId: equipmentSetId,
        },
    })
}

export const assignEquipmentSets = (roomId, body) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch({
        [CALL_API]: {
            method: METHODS.PUT,
            types: [
                EQUIPMENT_SET_REQUEST,
                EQUIPMENT_SET_SUCCESS,
                EQUIPMENT_SET_FAILURE,
            ],
            endpoint: `/venues/${venueId}/rooms/${roomId}/equipment-sets`,
            schema: equipmentSetSchema,
            body,
        },
    })
}

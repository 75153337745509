import { createValidationErrorObject } from './index'
import * as validation from './game'

export const getBodyEnError = createValidationErrorObject(
    validation.isValidBody,
    'bodyEn',
    'Please fill in a valid description.',
)

export const getBodyNlError = createValidationErrorObject(
    validation.isValidBody,
    'bodyNl',
    'Please fill in a valid description.',
)

export const getBodyFrError = createValidationErrorObject(
    validation.isValidBody,
    'bodyFr',
    'Please fill in a valid description.',
)

export const getDurationError = createValidationErrorObject(
    validation.isValidDuration,
    'duration',
    'Please fill in a valid duration time.',
)

export const getMaxPlayerError = createValidationErrorObject(
    validation.isValidMaxPlayers,
    'maxPlayers',
    'Please fill in a valid amount.',
)

export const getMediaItemIdError = createValidationErrorObject(
    validation.isValidMediaItem,
    'mediaItemId',
    'Please upload a valid image.',
)

export const getMediaItemError = createValidationErrorObject(
    validation.isValidMediaItem,
    'mediaItem',
    'Please upload a valid image.',
)

export const getMinPlayerError = createValidationErrorObject(
    validation.isValidMinPlayers,
    'minPlayers',
    'Please fill in a valid amount.',
)

export const getValidPricesErrors = createValidationErrorObject(
    validation.isValidPrices,
    'price',
    'Please fill in at least one currency.',
)

export const getReadMoreLinkEnError = createValidationErrorObject(
    validation.isValidReadMoreLink,
    'readMoreLinkEn',
    'Please fill in a valid link.',
)

export const getReadMoreLinkNlError = createValidationErrorObject(
    validation.isValidReadMoreLink,
    'readMoreLinkNl',
    'Please fill in a valid link.',
)

export const getReadMoreLinkFrError = createValidationErrorObject(
    validation.isValidReadMoreLink,
    'readMoreLinkFr',
    'Please fill in a valid link.',
)

export const getExtraConditionsLinkFrError = createValidationErrorObject(
    validation.isValidExtraConditionsLink,
    'extraConditionsLinkFr',
    'Please fill in a valid link.',
)
export const getExtraConditionsLinkNlError = createValidationErrorObject(
    validation.isValidExtraConditionsLink,
    'extraConditionsLinkNl',
    'Please fill in a valid link.',
)
export const getExtraConditionsLinkEnError = createValidationErrorObject(
    validation.isValidExtraConditionsLink,
    'extraConditionsLinkEn',
    'Please fill in a valid link.',
)

export const getTitleEnError = createValidationErrorObject(
    validation.isValidTitle,
    'titleEn',
    'Please fill in a valid title.',
)

export const getTitleNlError = createValidationErrorObject(
    validation.isValidTitle,
    'titleNl',
    'Please fill in a valid title.',
)

export const getTitleFrError = createValidationErrorObject(
    validation.isValidTitle,
    'titleFr',
    'Please fill in a valid title.',
)

export const getNumberOfTeamsError = createValidationErrorObject(
    validation.isValidNumberOfTeams,
    'numberOfTeams',
    'Please fill in a valid amount.',
)

export const getVideoUrlError = createValidationErrorObject(
    validation.isValidVideoLink,
    'videoUrl',
    'Please fill in a valid Vimeo url.',
)

export const getCodeError = createValidationErrorObject(
    validation.isValidCode,
    'code',
    'Has to be alphanumeric, uppercase, numbers only in the back. (underscore allowed)',
)

export const getGameErrors = ({
    bodyEn,
    bodyNl,
    bodyFr,
    duration,
    minPlayers,
    mediaItemId,
    maxPlayers,
    playerPriceEUR,
    playerPriceGBP,
    playerPriceUSD,
    totalPriceEUR,
    totalPriceGBP,
    totalPriceUSD,
    readMoreLinkEn,
    readMoreLinkNl,
    readMoreLinkFr,
    extraConditionsLinkNl,
    extraConditionsLinkEn,
    extraConditionsLinkFr,
    titleEn,
    titleNl,
    titleFr,
    enableTeams,
    numberOfTeams,
    videoUrl,
    code,
}, priceSystem) => ({
    ...getBodyEnError(bodyEn),
    ...getBodyNlError(bodyNl),
    ...getBodyFrError(bodyFr),
    ...getDurationError(duration),
    ...getMaxPlayerError(maxPlayers, minPlayers),
    ...getMediaItemIdError(mediaItemId),
    ...getMinPlayerError(minPlayers, maxPlayers),
    ...getReadMoreLinkEnError(readMoreLinkEn),
    ...getReadMoreLinkNlError(readMoreLinkNl),
    ...getReadMoreLinkFrError(readMoreLinkFr),
    ...getExtraConditionsLinkNlError(extraConditionsLinkNl),
    ...getExtraConditionsLinkEnError(extraConditionsLinkEn),
    ...getExtraConditionsLinkFrError(extraConditionsLinkFr),
    ...getTitleEnError(titleEn),
    ...getTitleNlError(titleNl),
    ...getTitleFrError(titleFr),
    ...getNumberOfTeamsError(enableTeams, numberOfTeams, maxPlayers),
    ...getVideoUrlError(videoUrl),
    ...getValidPricesErrors([
        [playerPriceEUR, totalPriceEUR],
        [playerPriceGBP, totalPriceGBP],
        [playerPriceUSD, totalPriceUSD],
    ], priceSystem),
    ...getCodeError(code),
})

export const getEditGameErrors = ({
    bodyEn,
    bodyNl,
    bodyFr,
    duration,
    mediaItem,
    minPlayers,
    maxPlayers,
    playerPriceEUR,
    playerPriceGBP,
    playerPriceUSD,
    totalPriceEUR,
    totalPriceGBP,
    totalPriceUSD,
    readMoreLinkEn,
    readMoreLinkNl,
    readMoreLinkFr,
    extraConditionsLinkNl,
    extraConditionsLinkEn,
    extraConditionsLinkFr,
    titleEn,
    titleNl,
    titleFr,
    enableTeams,
    numberOfTeams,
    videoUrl,
}, priceSystem) => ({
    ...getBodyEnError(bodyEn),
    ...getBodyNlError(bodyNl),
    ...getBodyFrError(bodyFr),
    ...getDurationError(duration),
    ...getMaxPlayerError(maxPlayers, minPlayers),
    ...getMediaItemError(mediaItem),
    ...getMinPlayerError(minPlayers, maxPlayers),
    ...getReadMoreLinkEnError(readMoreLinkEn),
    ...getReadMoreLinkNlError(readMoreLinkNl),
    ...getReadMoreLinkFrError(readMoreLinkFr),
    ...getExtraConditionsLinkNlError(extraConditionsLinkNl),
    ...getExtraConditionsLinkEnError(extraConditionsLinkEn),
    ...getExtraConditionsLinkFrError(extraConditionsLinkFr),
    ...getTitleEnError(titleEn),
    ...getTitleNlError(titleNl),
    ...getTitleFrError(titleFr),
    ...getNumberOfTeamsError(enableTeams, numberOfTeams, maxPlayers),
    ...getVideoUrlError(videoUrl),
    ...getValidPricesErrors([
        [playerPriceEUR, totalPriceEUR],
        [playerPriceGBP, totalPriceGBP],
        [playerPriceUSD, totalPriceUSD],
    ], priceSystem),
})

export function toGameApiRequest(values) {
    // console.log('gameApiRequest values:', values)

    const body = {
        code: values.code,
        minAge: values.minAge,
        bodyTranslations: {
            en: values.bodyEn,
            nl: values.bodyNl,
            fr: values.bodyFr,
        },
        duration: values.duration,
        maxPlayers: values.maxPlayers,
        mediaItemId: values.mediaItemId,
        minPlayers: values.minPlayers,
        readMoreLinkTranslations: {
            en: values.readMoreLinkEn,
            nl: values.readMoreLinkNl,
            fr: values.readMoreLinkFr,
        },
        extraConditionsLinkTranslations: {
            en: values.extraConditionsLinkEn,
            nl: values.extraConditionsLinkNl,
            fr: values.extraConditionsLinkFr,
        },
        titleTranslations: {
            en: values.titleEn,
            nl: values.titleNl,
            fr: values.titleFr,
        },
        enableTeams: values.enableTeams,
        numberOfTeams: values.numberOfTeams,
        public: values.public,
        videoUrl: values.videoUrl,
        requiredRoomAmount: values.requiredRoomAmount,
        playerPrice: {
            EUR: values.playerPriceEUR,
            GBP: values.playerPriceGBP,
            USD: values.playerPriceUSD,
            AUD: values.playerPriceAUD,
        },
        totalPrice: {
            EUR: values.totalPriceEUR,
            GBP: values.totalPriceGBP,
            USD: values.totalPriceUSD,
            AUD: values.totalPriceAUD,
        },
    }

    // console.log('Api body:', body)
    return body
}

function valueOrNull(val) {
    if (typeof val !== 'undefined' && val !== null)
        return val
    else
        return null
}

export function mapGameToValues(game) {
    return {
        code: game.code,
        minAge: game.minAge,
        bodyEn: game.bodyTranslations.en,
        bodyNl: game.bodyTranslations.nl,
        bodyFr: game.bodyTranslations.fr,
        playerPriceEUR: valueOrNull(game.playerPrice?.EUR),
        playerPriceGBP: valueOrNull(game.playerPrice?.GBP),
        playerPriceUSD: valueOrNull(game.playerPrice?.USD),
        playerPriceAUD: valueOrNull(game.playerPrice?.AUD),
        totalPriceEUR: valueOrNull(game.totalPrice?.EUR),
        totalPriceGBP: valueOrNull(game.totalPrice?.GBP),
        totalPriceUSD: valueOrNull(game.totalPrice?.USD),
        totalPriceAUD: valueOrNull(game.totalPrice?.AUD),
        readMoreLinkEn: game.readMoreLinkTranslations.en,
        readMoreLinkNl: game.readMoreLinkTranslations.nl,
        readMoreLinkFr: game.readMoreLinkTranslations.fr,
        extraConditionsLinkEn: game.extraConditionsLinkTranslations.en || '',
        extraConditionsLinkNl: game.extraConditionsLinkTranslations.nl || '',
        extraConditionsLinkFr: game.extraConditionsLinkTranslations.fr || '',
        titleEn: game.titleTranslations.en,
        titleNl: game.titleTranslations.nl,
        titleFr: game.titleTranslations.fr,
        mediaItem: game.mediaItems[0],
        ...game,
    }
}

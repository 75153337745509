import React from 'react'
import { Button } from 'antd'
import { MODAL_IDS, ROLES } from 'util/constants'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import HasPermissions from 'components/pages/authorized/HasPermissions'

function DiscountBannersTopBar() {
    return (
        <div className="flex justify-content-space-between margin-bottom">
            <HasPermissions roles={[ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.VENUE_MANAGER]}>
                <WithActiveModal modalId={MODAL_IDS.CREATE_DISCOUNT_BANNER}>
                    {({ openModal }) => (
                        <Button type="primary" onClick={openModal}>
                            New discount banner
                        </Button>
                    )}
                </WithActiveModal>
            </HasPermissions>
        </div>
    )
}

export default DiscountBannersTopBar

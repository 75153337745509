import createPaginationReducer from '../hor/createPaginationReducer'
import {
    USERS_REQUEST,
    USERS_SUCCESS,
    USERS_FAILURE,
} from '../../actions/users'

const usersPaginationReducer = createPaginationReducer({
    mapActionToKey: (action) => action.viewName,
    types: [
        USERS_REQUEST,
        USERS_SUCCESS,
        USERS_FAILURE,
    ],
})

export default usersPaginationReducer

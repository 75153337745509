import React from 'react'
import { Tabs } from 'antd'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import WithMe from 'components/pages/authorized/WithMe'
import Room from 'components/pages/settings/rooms/overview/RoomOverview'
import { MODAL_IDS } from 'util/constants'
import CreateRoomButton from '../forms/CreateRoomButton'

const { TabPane } = Tabs

// https://ant.design/components/tabs
function buildTabItems(rooms, games, equipmentSets, assignedSets, onChangeEquipmentSet) {
    if (!Array.isArray(rooms))
        return []

    const items = []

    for (let i = 0, length = rooms.length; i < length; i++) {
        const room = rooms[i]
        items.push({
            key: room.id.toString(),
            label: room.name,
            children: (
                <Room
                    room={room}
                    games={games}
                    equipmentSets={equipmentSets}
                    assignedSets={assignedSets}
                    onChangeEquipmentSet={onChangeEquipmentSet}
                />
            ),
        })
    }

    return items
}

function RoomsTabs({
    activeTabKey,
    rooms,
    games,
    equipmentSets,
    assignedSets,
    onChangeEquipmentSet,
    onChangeTab,
}) {
    return (
        <WithMe>
            {({ isAdmin, isLegalEntityManager, isVenueManager }) => (
                <WithActiveModal modalId={MODAL_IDS.DELETE_ROOM}>
                    {({ openModalWithEntity }) => (
                        <Tabs
                            activeKey={activeTabKey}
                            onChange={onChangeTab}
                            tabBarExtraContent={<CreateRoomButton />}
                            type={isAdmin || isLegalEntityManager || isVenueManager ? 'editable-card' : 'card'}
                            // animated
                            hideAdd
                            onEdit={async (targetKey, action) => {
                                if (action === 'remove') {
                                    openModalWithEntity(parseInt(targetKey, 10))
                                }
                            }}
                            items={buildTabItems(rooms, games, equipmentSets, assignedSets, onChangeEquipmentSet)}
                        />
                    )}
                </WithActiveModal>
            )}
        </WithMe>
    )
}

export default RoomsTabs

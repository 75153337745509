import React from 'react'
import DeleteCouponFormState from 'components/pages/settings/coupons/forms/delete/DeleteCouponFormState'
import { DeleteModal } from 'components/util/modals/DeleteModal'

/**
 * @param {Object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.onClose
 * @param {Object} props.coupon
 * @returns {React.JSX.Element}
 * @constructor
 */
function DeleteCouponModal(props) {
    return (
        <DeleteCouponFormState
            onDeleteSuccess={props.onClose}
            onCancel={props.onClose}
            values={props.coupon}
        >
            {({
                isLoading,
                handleSubmit,
                handleCancel,
            }) => (
                <DeleteModal
                    title="Delete this coupon"
                    isOpen={props.isOpen}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    entityName={props.coupon && props.coupon.code}
                    isLoading={isLoading}
                    closable={!isLoading}
                    maskClosable={!isLoading}
                />
            )}
        </DeleteCouponFormState>
    )
}

export default DeleteCouponModal

import React from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import useDownload from 'components/hooks/useDownload'
import dayjs from 'dayjs'
import GiftCardDetailItem from './GiftCardDetailItem'
import { APP_DATE_FORMAT } from '../../../../../util/constants'

function GiftCardDetails({ giftCard }) {
    let gcCreatedAt = null
    let cUsedAt = null
    let cCreator = null
    if (giftCard) {
        gcCreatedAt = dayjs(giftCard.createdAt).format(APP_DATE_FORMAT)
        // gcCreatedAt = dayjs.unix(giftCard.createdAt).format(APP_DATE_FORMAT)
        cUsedAt = giftCard.usedAt !== null && dayjs.utc(giftCard.usedAt).format(APP_DATE_FORMAT)
        cCreator = giftCard.creator !== null && `${giftCard.creator.firstName} ${giftCard.creator.lastName}`
    }

    const { startDownload: handleDownloadGiftCard, isDownloading } = useDownload({
        endpoint: `/files/gift-cards/${giftCard.id}`,
        saveAs: `${giftCard.couponCode}.pdf`,
    })

    return (
        <div className="flex relative">
            <div className="column">
                <ul>
                    <GiftCardDetailItem label="Email:">
                        {giftCard.email}
                    </GiftCardDetailItem>
                    <GiftCardDetailItem label="Recipient:">
                        {giftCard.recipientName}
                    </GiftCardDetailItem>
                    <GiftCardDetailItem label="Message:">
                        {giftCard.message}
                    </GiftCardDetailItem>
                    <GiftCardDetailItem label="Coupon code:">
                        {giftCard.couponCode}
                    </GiftCardDetailItem>
                    {
                        typeof giftCard.order !== 'undefined'
                        && giftCard.order !== null
                        && (
                            <>
                                {
                                    typeof giftCard.order.orderNumber !== 'undefined'
                                    && giftCard.order.orderNumber !== null
                                    && (
                                        <GiftCardDetailItem label="Order number:">
                                            {giftCard.order.orderNumber}
                                        </GiftCardDetailItem>
                                    )
                                }
                                {
                                    typeof giftCard.order.purchaseOrderNumber !== 'undefined'
                                    && giftCard.order.purchaseOrderNumber !== null
                                    && (
                                        <GiftCardDetailItem label="PO number:">
                                            {giftCard.order.purchaseOrderNumber}
                                        </GiftCardDetailItem>
                                    )
                                }
                            </>
                        )
                    }
                </ul>
            </div>
            <div className="column">
                <ul>
                    <GiftCardDetailItem label="Used:">
                        {cUsedAt || 'No'}
                    </GiftCardDetailItem>
                    <GiftCardDetailItem label="Created at:">
                        {gcCreatedAt}
                    </GiftCardDetailItem>
                    <GiftCardDetailItem label="Created by:">
                        {cCreator || 'Tickets app'}
                    </GiftCardDetailItem>
                    <GiftCardDetailItem>
                        <Button
                            icon={<DownloadOutlined />}
                            onClick={handleDownloadGiftCard}
                            loading={isDownloading}
                        >
                            Download
                        </Button>
                    </GiftCardDetailItem>
                </ul>
            </div>
        </div>
    )
}

export default GiftCardDetails

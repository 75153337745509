import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import { MODAL_IDS } from 'util/constants'
import {DeleteBookingModal} from "./DeleteBookingModal"

export function DeleteBookingModalWithRedux() {
    return (
        <WithActiveModal modalId={MODAL_IDS.DELETE_BOOKING}>
            {(childProps) => (
                <DeleteBookingModal
                    isOpen={childProps.isOpen}
                    onClose={childProps.closeModal}
                    booking={childProps.entity}
                />
            )}
        </WithActiveModal>
    )
}

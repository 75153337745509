import React from 'react'
import { Modal } from 'antd'
import { ModalFooter } from 'components/util/modals/ModalFooter'
import CreateGameSessionForm from '../../forms/create/CreateGameSessionForm'

function CreateGameSessionModal({
    isOpen,
    onClose,
    values,
    errors,
    isLoading,
    mergeValues,
    onChangeAsValue,
    onCancel,
    onSubmit,
}) {
    return (
        <Modal
            title="Create new game session"
            open={isOpen}
            onOk={onSubmit}
            onCancel={onClose}
            closable={!isLoading}
            maskClosable={!isLoading}
            footer={(
                <ModalFooter
                    isLoading={isLoading}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                />
            )}
        >
            {isOpen
      && (
          <CreateGameSessionForm
              isLoading={isLoading}
              values={values}
              errors={errors}
              mergeValues={mergeValues}
              onChangeAsValue={onChangeAsValue}
              onCreateSuccess={onClose}
          />
      )}
        </Modal>
    )
}

export default CreateGameSessionModal

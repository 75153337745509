import { useState } from 'react'
import callApiMiddleware from 'store/middleware/callApi'
import { METHODS } from 'store/middleware/api'

export { METHODS } from 'store/middleware/api'

const useApi = (
    endpoint,
    {
        method = METHODS.GET,
        schema,
        minRequestTime = 0,
        isInternal,
    },
) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)

    const callApi = async (params, body) => {
        setLoading(true)

        try {
            const response = await callApiMiddleware(
                method,
                endpoint,
                params,
                schema,
                body,
                minRequestTime,
                isInternal,
            )
            setData(response)
            return response
        } catch (err) {
            setError(err)
            throw err
        } finally {
            setLoading(false)
        }
    }

    return [
        callApi,
        {
            data,
            loading,
            error,
        },
    ]
}

export default useApi

import React from "react"
import { getEntityFromResponse } from "util/index"
import { WithFormState } from "components/util/forms/WithFormState"
import {DeleteBookingRequest} from "../../requests/DeleteBookingRequest"

/**
 * @param {Object} props
 * @param {() => void} props.onCancel
 * @param {(booking: BookingResource) => void} props.onDeleteSuccess
 * @param {(props: WithFormStateChildProps) => React.Element|React.Element[]|null=} props.children
 * @param {BookingResource} props.booking
 * @return {JSX.Element}
 */
export function DeleteBookingFormState(props) {
    return (
        <DeleteBookingRequest paginationKey="overview">
            {({
                deleteBooking,
                fetchBookings,
                deleteBookingSuccess,
                deleteBookingFailed,
            }) => (
                <WithFormState
                    // transformValuesToRequestBody={() => nu}
                    onSendRequest={() => deleteBooking(props.booking)}
                    onCancel={props.onCancel}
                    onRequestSuccess={(response) => {
                        const booking = getEntityFromResponse(response, "bookings")
                        fetchBookings()
                        deleteBookingSuccess(booking)
                        props.onDeleteSuccess(booking)
                    }}
                    onRequestFailed={deleteBookingFailed}
                >
                    {props.children}
                </WithFormState>
            )}
        </DeleteBookingRequest>
    )
}

import React from 'react'
import Logo from 'components/util/Logo'
import './AppUnavailable.css'

function AppUnavailable() {
    return (
        <div className="appUnavailable">
            <Logo />
            <h2 className="appUnavailableTitle">The app is currently unavailable. <br /> Please try again later.</h2>
        </div>
    )
}

export default AppUnavailable

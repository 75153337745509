import React from 'react'
import Logo from 'components/util/Logo'

function Unauthorized({ children }) {
    return (
        <div className="max-width-container-with-padding auth-container">
            <div className="auth-card-container">
                <div className="logo-container">
                    <Logo />
                </div>
                {children}
            </div>
        </div>
    )
}

export default Unauthorized

import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import { MODAL_IDS } from 'util/constants'

function EditVenueModalState({ venue, children }) {
    return (
        <WithActiveModal
            modalId={MODAL_IDS.EDIT_VENUE}
            entity={venue}
            entityKey="venue"
        >
            {children}
        </WithActiveModal>
    )
}

export default EditVenueModalState

import createEntityReducer from '../hor/createEntityReducer'
import {
    VENUE_REQUEST,
    VENUE_SUCCESS,
    VENUE_FAILURE,
} from '../../actions/venues'

const venuesReducer = createEntityReducer({
    types: [
        VENUE_REQUEST,
        VENUE_SUCCESS,
        VENUE_FAILURE,
    ],
})

export default venuesReducer

import createEntityReducer from '../hor/createEntityReducer'
import {
    VOUCHER_ISSUE_REASONS_REQUEST,
    VOUCHER_ISSUE_REASONS_SUCCESS, VOUCHER_ISSUE_REASONS_FAILURE,
} from '../../actions/voucherIssueReasons'

export const voucherIssueReasonsReducer = createEntityReducer({
    types: [
        VOUCHER_ISSUE_REASONS_REQUEST,
        VOUCHER_ISSUE_REASONS_SUCCESS,
        VOUCHER_ISSUE_REASONS_FAILURE,
    ],
})

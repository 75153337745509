import dayjs from 'dayjs'
import { APP_TIME_FORMAT } from '../constants'

export function toPriceAdjustmentApiRequest(values) {
    const {
        from, until, totalPrice,
        playerPrice, day,
    } = values

    const fromFormatted = from.format(APP_TIME_FORMAT)
    const untilFormatted = until.format(APP_TIME_FORMAT)

    if (from !== null && until !== null) {
        return {
            ...values,
            from: fromFormatted,
            until: untilFormatted,
        }
    }
    return {
        totalPrice, playerPrice, day,
    }
}

export function mapPriceAdjustmentToValues(priceAdjustment) {
    return {
        from: dayjs.utc(priceAdjustment.from, APP_TIME_FORMAT),
        until: dayjs.utc(priceAdjustment.until, APP_TIME_FORMAT),
        totalPrice: priceAdjustment.totalPrice,
        playerPrice: priceAdjustment.playerPrice,
        day: priceAdjustment.day,
        gameId: priceAdjustment.gameId,
    }
}

import createPaginationReducer from '../hor/createPaginationReducer'
import {
    GAMES_REQUEST,
    GAMES_SUCCESS,
    GAMES_FAILURE,
} from '../../actions/games'

const gamesPaginationReducer = createPaginationReducer({
    mapActionToKey: (action) => action.viewName,
    types: [
        GAMES_REQUEST,
        GAMES_SUCCESS,
        GAMES_FAILURE,
    ],
})

export default gamesPaginationReducer

import { createValidationErrorObject } from './index'
import * as validation from './venue'

export const getNameEnError = createValidationErrorObject(
    validation.isValidName,
    'nameEn',
    'Please fill in a valid name.',
)

export const getnameNlError = createValidationErrorObject(
    validation.isValidName,
    'nameNl',
    'Please fill in a valid name.',
)

export const getNameFrError = createValidationErrorObject(
    validation.isValidName,
    'nameFr',
    'Please fill in a valid name.',
)

export const getNamePtError = createValidationErrorObject(
    validation.isValidName,
    'namePt',
    'Please fill in a valid name.',
)

export const getAddressEnError = createValidationErrorObject(
    validation.isValidAddress,
    'addressEn',
    'Please fill in a valid address.',
)

export const getAddressNlError = createValidationErrorObject(
    validation.isValidAddress,
    'addressNl',
    'Please fill in a valid address.',
)

export const getAddressFrError = createValidationErrorObject(
    validation.isValidAddress,
    'addressFr',
    'Please fill in a valid address.',
)

export const getAddressPtError = createValidationErrorObject(
    validation.isValidAddress,
    'addressPt',
    'Please fill in a valid address.',
)

export const getDurationError = createValidationErrorObject(
    validation.isValidDuration,
    'duration',
    'Please fill in a valid duration time.',
)

export const getLegalEntityError = createValidationErrorObject(
    validation.isValidLegalEntity,
    'legalEntity',
    'Please select a legal entity.',
)

export const getMinPlayerError = createValidationErrorObject(
    validation.isValidMinPlayers,
    'minPlayers',
    'Please fill in a valid amount.',
)

// export const getCostCenterCodeError = createValidationErrorObject(
//     validation.isValidCostCenterCode,
//     'costCenterCode',
//     'Please fill in a valid code.',
// )

export const getTimezoneError = createValidationErrorObject(
    validation.isValidTimezone,
    'timezone',
    'Please select a valid timezone.',
)

export const getCodeError = createValidationErrorObject(
    validation.isValidCode,
    'code',
    'Has to be alphanumeric, uppercase, numbers only in the back. (underscore allowed)',
)

export const getVenueErrors = (values) => ({
    ...getNameEnError(values.nameEn),
    ...getnameNlError(values.nameNl),
    ...getNameFrError(values.nameFr),
    ...getNamePtError(values.namePt),
    ...getAddressEnError(values.addressEn),
    ...getAddressNlError(values.addressNl),
    ...getAddressFrError(values.addressFr),
    ...getAddressPtError(values.addressPt),
    ...getLegalEntityError(values.legalEntity),
    ...getDurationError(values.duration),
    ...getMinPlayerError(values.minPlayers),
    // ...getCostCenterCodeError(values.costCenterCode),
    ...getTimezoneError(values.timezone),
    ...getCodeError(values.code),
})

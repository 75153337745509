/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-lone-blocks */
/* eslint-disable max-len */
import React, { useState } from 'react'
import {
    Modal, Form, Input,
} from 'antd'
import './bookingRegisteredPlayerModal.css'
import FormItem from 'components/util/forms/FormItem'
import { ModalFooter } from '../../../util/modals/ModalFooter'

/**
 * @typedef {Object} BookingRegisterPlayerModalProps
 * @property {boolean} isModalOpen
 * @property {() => void} onCancel
 * @property {(firstName: string, lastName: string, email: string, nickname: string) => void} onSubmit
 * @definition Modal to register a player to a booking
*/

/**
 * @param {BookingRegisterPlayerModalProps} props
 */

function CreateBookingRegisteredPlayerModal(props) {
    const {
        isModalOpen,
        onCancel,
        onSubmit,
    } = props

    // Set player details
    const [playerFirstName, setPlayerFirstName] = useState('')
    const [playerLastName, setPlayerLastName] = useState('')
    const [playerEmail, setPlayerEmail] = useState('')
    const [playerNickname, setPlayerNickname] = useState('')
    const [isHandlingRequest, setIsHandlingRequest] = useState(false)

    // Set error messages
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        nickname: '',
    })

    async function onOkClick() {
        if (isHandlingRequest)
            return

        setIsHandlingRequest(true)

        const onSubmitReturn = await onSubmit(
            playerFirstName,
            playerLastName,
            playerEmail,
            playerNickname,
        )

        // if the onSubmit function returns an error, set the error messages
        if (typeof onSubmitReturn !== 'undefined') {
            if (typeof onSubmitReturn.errors !== 'undefined') {
                setErrors(onSubmitReturn.errors)
            }

            if (onSubmitReturn.code === 'email_already_registered') {
                setErrors({ email: 'This email is already registered.' })
            }
        }

        setIsHandlingRequest(false)
    }

    function onCancelClick() {
        onCancel()
    }

    function onNickNameChange(e) {
        let newValue = ''

        for (let i = 0; i < e.target.value.length; i++) {
            const char = e.target.value[i]
            // decimal character code for 😀, first emoji, check https://www.tamasoft.co.jp/en/general-info/unicode-decimal.html
            const utf16FirstEmojiCharCode = 55357
            // decimal character code for 𝟿, last emoji, check https://www.tamasoft.co.jp/en/general-info/unicode-decimal.html
            // const utf16LastEmojiCharCode = 65040
            // decimal character code for current char
            const utf16CharCode = char.charCodeAt(0)

            if (utf16CharCode < utf16FirstEmojiCharCode) {
                newValue += e.target.value[i]
            }
        }

        setPlayerNickname(newValue)
    }

    return (
        <Modal
            title="Register a player."
            open={isModalOpen}
            okText="Register"
            onOk={onOkClick}
            onCancel={onCancelClick}
            okButtonProps={{ disabled: isHandlingRequest }}
            cancelButtonProps={{ disabled: isHandlingRequest }}
            closable={!isHandlingRequest}
            maskClosable={!isHandlingRequest}
            footer={(
                <ModalFooter
                    isLoading={isHandlingRequest}
                    onCancel={onCancelClick}
                    onSubmit={onOkClick}
                />
            )}
        >
            <Form
                className="booking-register-player-form"
                disabled={isHandlingRequest}
            >
                <h3>Fill in the details to register to this booking.</h3>
                <div className="input-wrapper">
                    <label htmlFor="playerFirstName">First name</label>
                    <FormItem
                        wrapperCol={{}}
                        error={errors.firstName}
                    >
                        <Input
                            type="text"
                            value={playerFirstName}
                            onChange={(e) => setPlayerFirstName(e.target.value)}
                            id="playerFirstName"
                            className="input"
                        />
                    </FormItem>
                </div>
                <div className="input-wrapper">
                    <label htmlFor="playerLastName">Last name</label>
                    <FormItem
                        wrapperCol={{}}
                        error={errors.lastName}
                    >
                        <Input
                            type="text"
                            value={playerLastName}
                            onChange={(e) => setPlayerLastName(e.target.value)}
                            id="playerLastName"
                            className="input"
                        />
                    </FormItem>
                </div>
                <div className="input-wrapper">
                    <label htmlFor="playerEmail">Email</label>
                    <FormItem
                        wrapperCol={{}}
                        error={errors.email}
                    >
                        <Input
                            type="text"
                            value={playerEmail}
                            onChange={(e) => setPlayerEmail(e.target.value)}
                            id="playerEmail"
                            className="input"
                        />
                    </FormItem>
                </div>
                <div className="input-wrapper">
                    <label htmlFor="playerNickname">Nickname</label>
                    <FormItem
                        wrapperCol={{}}
                        error={errors.nickname}
                    >
                        <Input
                            type="text"
                            value={playerNickname}
                            onChange={onNickNameChange}
                            id="playerNickname"
                            className="input"
                        />
                    </FormItem>
                </div>
            </Form>
        </Modal>
    )
}

export default CreateBookingRegisteredPlayerModal

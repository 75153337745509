import React from 'react'
import EditUserFormState from 'components/pages/settings/users/forms/edit/EditUserFormState'
import EditUserModal from './EditUserModal'
import EditUserModalState from './EditUserModalState'

function EditUserModalWithState() {
    return (
        <EditUserModalState>
            {({
                isOpen,
                closeModal,
                user,
            }) => (
                <EditUserFormState
                    onEditSuccess={closeModal}
                    onCancel={closeModal}
                    user={user}
                >
                    {({
                        values,
                        errors,
                        isLoading,
                        handleChange,
                        handleChangeAsValue,
                        handleCancel,
                        handleSubmit,
                    }) => (
                        <EditUserModal
                            isOpen={isOpen}
                            onClose={closeModal}
                            user={values}
                            errors={errors}
                            isLoading={isLoading}
                            onChange={handleChange}
                            onChangeAsValue={handleChangeAsValue}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                        />
                    )}
                </EditUserFormState>
            )}
        </EditUserModalState>
    )
}
export default EditUserModalWithState

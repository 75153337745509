import { ERRORS, REASON_CODE } from 'util/constants'
import { isValid, getErrMsg, isRequired } from './index'

export function isValidAmount(amount) {
    return isValid(amount)
}

export function getAmountError(amount) {
    return getErrMsg(!isValidAmount(amount), 'amount', ERRORS.AMOUNT)
}

export function isValidCode(code) {
    return isValid(code)
}

export function getCodeError(code) {
    return getErrMsg(!isValidCode(code), 'code', ERRORS.CODE)
}

export function isValidExpiration(date) {
    return isValid(date)
}

export function getExpirationError(date) {
    return getErrMsg(!isValidExpiration(date), 'expiresAt', ERRORS.EXPIRATION_DATE)
}

export function isValidId(id) {
    return (isValid(id))
}

export function isValidBatchAmount(total) {
    return isValid(total)
}

export function getBatchAmountError(batchAmount) {
    return getErrMsg(!isValidBatchAmount(batchAmount), 'batchAmount', ERRORS.BATCH_AMOUNT)
}

export const isValidLegalEntity = isRequired
export const isValidVenue = isRequired
export const isValidOtherReason = isRequired

export function getLegalEntityError(legalEntity) {
    return getErrMsg(!isValidLegalEntity(legalEntity), 'legalEntity', ERRORS.LEGAL_ENTITY)
}

export function getVenueError(venue) {
    return getErrMsg(!isValidVenue(venue), 'venue', ERRORS.VENUE)
}
export function getOtherReasonError(otherReason) {
    return getErrMsg(!isValidOtherReason(otherReason), 'otherReason', ERRORS.OTHER_REASON)
}

export function isValidCoupon({
    amount,
    venue,
    legalEntity,
    reason,
    otherReason,
}) {
    return isValidAmount(amount)
        && isValidLegalEntity(legalEntity)
        && isValidVenue(venue)
        // eslint-disable-next-line no-use-before-define
        && isValidReason(reason, otherReason)
}

export function getCouponErrors({
    amount,
    venue,
    legalEntity,
    otherReason,
}) {
    return {
        ...getAmountError(amount),
        ...getLegalEntityError(legalEntity),
        ...getVenueError(venue),
        ...getOtherReasonError(otherReason),
    }
}

export function isValidCouponBatch({
    amount,
    batchAmount,
    code,
}) {
    return isValidAmount(amount)
        && isValidBatchAmount(batchAmount)
        && isValidCode(code)
}

export function getCouponBatchErrors({
    amount,
    batchAmount,
    venue,
    legalEntity,
    code,
}) {
    return {
        ...getAmountError(amount),
        ...getBatchAmountError(batchAmount),
        ...getLegalEntityError(legalEntity),
        ...getVenueError(venue),
        ...getCodeError(code),
    }
}

function isValidReason(reason, otherReason) {
    let result = true

    if (reason && reason === REASON_CODE.other) {
        if (!isValidOtherReason(otherReason)) {
            result = false
        }
    }

    return result
}

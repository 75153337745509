import React from 'react'
import { Form, Input, Checkbox } from 'antd'
import FormItem from 'components/util/forms/FormItem'

function EditGiftCardForm({
    values,
    errors,
    onChange,
}) {
    return (
        <Form>
            <FormItem
                label="First name"
                required
                error={errors.firstName}
            >
                <Input
                    name="firstName"
                    value={values.firstName}
                    placeholder="e.g. John"
                    onChange={onChange}
                />
            </FormItem>
            <FormItem
                label="Last name"
                required
                error={errors.lastName}
            >
                <Input
                    name="lastName"
                    value={values.lastName}
                    placeholder="e.g. Doe"
                    onChange={onChange}
                />
            </FormItem>
            <FormItem
                label="Email"
                required
                error={errors.email}
            >
                <Input
                    name="email"
                    value={values.email}
                    placeholder="e.g. john@doe.com"
                    onChange={onChange}
                />
            </FormItem>
            <FormItem
                label="Purchase Order Number"
                error={errors.purchaseOrderNumber}
            >
                <Input
                    name="purchaseOrderNumber"
                    value={values.purchaseOrderNumber}
                    onChange={onChange}
                    maxLength={64}
                />
            </FormItem>
            <FormItem
                error={errors.resendEmail}
                wrapperCol={{
                    xs: { span: 24 },
                    sm: { span: 16, offset: 8 },
                }}
            >
                <Checkbox
                    name="resendEmail"
                    checked={values.resendEmail}
                    onChange={onChange}
                >
                    Resend email
                </Checkbox>
            </FormItem>
        </Form>
    )
}

export default EditGiftCardForm

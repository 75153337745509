import React from 'react'
import {
    Form,
    Radio,
    Input,
    InputNumber,
    DatePicker,
    Checkbox,
} from 'antd'
import ExtraInfoLabel from 'components/util/forms/ExtraInfoLabel'
import VenuesSelectWithRedux from 'components/pages/settings/coupons/forms/VenueSelect'
import CouponDetailItem from 'components/pages/settings/coupons/details/CouponDetailItem'
import useCurrencySymbol from 'components/hooks/useCurrencySymbol'
import dayjs from 'dayjs'
import CouponFormItem from '../../../coupons/forms/create/CouponFormItem'
import { APP_DATE_FORMAT } from '../../../../../../util/constants'

function getVenue(values) {
    let result = 'all_venues'

    if (values.venue !== null && values.venue !== 'all_venues') {
        if (Number.isNaN(values.venue)) {
            result = values.venue.id
        } else {
            result = values.venue
        }
    }

    return result
}
function EditCouponBatchForm({
    values,
    errors,
    onChange,
    onChangeAsValue,
    legalEntity,
}) {
    const currencySymbol = useCurrencySymbol(legalEntity)

    return (
        <Form>
            <CouponDetailItem label="Prefix">
                {values.prefix}
            </CouponDetailItem>
            <CouponDetailItem label="Code:">
                {values.code}
            </CouponDetailItem>
            <CouponFormItem
                label="Total codes"
                error={errors.batchAmount}
                required
            >
                <InputNumber
                    name="total"
                    value={values.batchAmount}
                    onChange={(value) => onChangeAsValue('batchAmount', value)}
                    min={1}
                />
            </CouponFormItem>
            <CouponFormItem>
                <Checkbox
                    name="isAllowedForGiftcards"
                    checked={values.isAllowedForGiftcards}
                    onChange={onChange}
                >
                    Can also be used for gift cards
                </Checkbox>
            </CouponFormItem>
            <CouponFormItem
                label="Available in"
                error={errors.venue}
            >
                <VenuesSelectWithRedux
                    venue={getVenue(values)}
                    onVenueChange={(value) => onChangeAsValue('venue', value)}
                    legalEntity={values.legalEntity}
                    // legalEntity={(!isNaN(values.legalEntity)) ? values.legalEntity : values.legalEntity.id}
                    onLegalEntityChange={(value) => onChangeAsValue('legalEntity', value)}
                />
            </CouponFormItem>
            <CouponFormItem
                label="Amount type"
            >
                <Radio.Group
                    name="amountType"
                    value={values.amountType}
                    onChange={onChange}
                >
                    <Radio.Button value="isAmount">
                        {currencySymbol}
                    </Radio.Button>
                    <Radio.Button value="isPercentage">
                        &#37;
                    </Radio.Button>
                </Radio.Group>
            </CouponFormItem>
            <CouponFormItem
                label="Amount"
                error={errors.amount}
                required
            >
                {values.amountType === 'isAmount'
                    ? (
                        <InputNumber
                            name="amount"
                            value={values.amount}
                            onChange={(value) => onChangeAsValue('amount', value)}
                            min={0}
                            formatter={(value) => `${currencySymbol}${value}`}
                            parser={(value) => value.replace(currencySymbol, '').replace(',', '.')}
                        />
                    )
                    : (
                        <InputNumber
                            name="amount"
                            value={values.amount}
                            onChange={(value) => onChangeAsValue('amount', value)}
                            min={0}
                            max={100}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value.replace('%', '')}
                        />
                    )}
            </CouponFormItem>
            <CouponFormItem
                label="Min. player amount"
            >
                <InputNumber
                    name="minPlayerAmount"
                    value={values.minPlayerAmount}
                    onChange={(value) => onChangeAsValue('minPlayerAmount', value)}
                    min={1}
                    max={9999}
                    precision={0}
                    step={1}
                />
            </CouponFormItem>
            <CouponFormItem
                label="Batch expires at"
                error={errors.expiresAt}
                extra={!values.expiresAt && (
                    <ExtraInfoLabel
                        message="If no date is provided, the codes will permanently be available."
                    />
                )}
            >
                <DatePicker
                    name="expiresAt"
                    value={values.expiresAt}
                    format={APP_DATE_FORMAT}
                    disabledDate={(current) => current && current < dayjs.utc().endOf('day')}
                    onChange={(value) => onChangeAsValue('expiresAt', value)}
                    className="full-width"
                />
            </CouponFormItem>

            <CouponFormItem
                label="Last possible play date"
                extra={!values.lastPossiblePlayDate
                && (
                    <ExtraInfoLabel
                        message="If no date is provided, the customer can book their play session on any future date"
                    />
                )}
            >
                <DatePicker
                    name="lastPossiblePlayDate"
                    format={APP_DATE_FORMAT}
                    value={values.lastPossiblePlayDate}
                    disabledDate={(current) => current && current < dayjs.utc().endOf('day')}
                    onChange={(value) => onChangeAsValue('lastPossiblePlayDate', value)}
                    className="full-width"
                />
            </CouponFormItem>
            <CouponFormItem
                label="Description"
                error={errors.description}
            >
                <Input.TextArea
                    rows="4"
                    name="description"
                    value={values.description}
                    onChange={onChange}
                />
            </CouponFormItem>
        </Form>
    )
}

export default EditCouponBatchForm

import React from 'react'
import { getEntityFromResponse } from 'util/index'
import { WithFormState } from 'components/util/forms/WithFormState'
import DeleteEquipmentSetRequest from './DeleteEquipmentSetRequest'

function DeleteEquipmentSetFormState({
    onCancel,
    onDeleteSuccess,
    values,
    children,
}) {
    return (
        <DeleteEquipmentSetRequest paginationKey="overview">
            {({
                deleteEquipmentSet,
                fetchEquipmentSets,
                deleteEquipmentSetSuccess,
                deleteEquipmentSetFailed,
            }) => (
                <WithFormState
                    transformValuesToRequestBody={() => values}
                    onSendRequest={() => deleteEquipmentSet(values)}
                    onCancel={onCancel}
                    onRequestSuccess={(response) => {
                        const equipmentSet = getEntityFromResponse(response, 'equipmentSets')
                        fetchEquipmentSets()
                        deleteEquipmentSetSuccess(equipmentSet)
                        onDeleteSuccess(equipmentSet)
                    }}
                    onRequestFailed={deleteEquipmentSetFailed}
                >
                    {children}
                </WithFormState>
            )}
        </DeleteEquipmentSetRequest>
    )
}

export default DeleteEquipmentSetFormState

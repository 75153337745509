import React from 'react'
import { TopBar } from 'components/layout/TopBar'
import { Main } from  'components/layout/Main'
import GlobalSettingsContainer from 'components/layout/sidebar/GlobalSettingsContainer'
import SidebarContent from 'components/layout/sidebar/SidebarContent'
import Overview from 'components/pages/settings/games/overview/Overview'
import CreateGameModalWithRedux from './modals/create/CreateGameModalWithRedux'
import EditGameModalWithState from './modals/edit/EditGameModalWithState'
import DeleteGameModalWithRedux from './modals/delete/DeleteGameModalWithRedux'

function GamesPage() {
    return (
        <>
            <TopBar activeMenuItem="globalSettings" />
            <Main>
                <GlobalSettingsContainer activeMenuItem="games">
                    <SidebarContent>
                        <Overview />
                    </SidebarContent>
                    <CreateGameModalWithRedux />
                    <EditGameModalWithState />
                    <DeleteGameModalWithRedux />
                </GlobalSettingsContainer>
            </Main>
        </>
    )
}

export default GamesPage

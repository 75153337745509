import { isRequired, isValidColor as colorValidation } from './index'

export const englishFormHasErrors = (values, errors) => !!(
    errors.titleEn
  || errors.subTitleEn
  || values.blocks.reduce((blockHasError, block, index) => (
      blockHasError
    || errors[`block${index}TitleEn`]
    || errors[`block${index}DiscountEn`]
  ), false)
)

export const dutchFormHasErrors = (values, errors) => !!(
    errors.titleNl
  || errors.subTitleNl
  || values.blocks.reduce((blockHasError, block, index) => (
      blockHasError
    || errors[`block${index}TitleNl`]
    || errors[`block${index}DiscountNl`]
  ), false)
)

export const frenchFormHasErrors = (values, errors) => !!(
    errors.titleFr
  || errors.subTitleFr
  || values.blocks.reduce((blockHasError, block, index) => (
      blockHasError
    || errors[`block${index}TitleFr`]
    || errors[`block${index}DiscountFr`]
  ), false)
)

export const isValidBlocks = (blocks) => isRequired(blocks)

export const isValidTitle = (title) => isRequired(title)

export const isValidSubTitle = (subTitle) => isRequired(subTitle)

export const isValidBlockTitle = (title) => isRequired(title)

export const isValidDiscount = (discount) => isRequired(discount)

export const isValidColor = (color) => colorValidation(color)

export const isValidDiscountBanner = ({
    blocks,
    titleNl,
    titleEn,
    titleFr,
    subTitleNl,
    subTitleEn,
    subTitleFr,
}) => (
    isValidBlocks(blocks)
  && isValidTitle(titleNl)
  && isValidTitle(titleEn)
  && isValidTitle(titleFr)
  && isValidSubTitle(subTitleNl)
  && isValidSubTitle(subTitleEn)
  && isValidSubTitle(subTitleFr)
  && blocks.reduce((isValidBlock, block) => (
      isValidBlock
    && isValidBlockTitle(block.titleNl)
    && isValidBlockTitle(block.titleEn)
    && isValidBlockTitle(block.titleFr)
    && isValidDiscount(block.discountNl)
    && isValidDiscount(block.discountEn)
    && isValidDiscount(block.discountFr)
    && isValidColor(block.color)
  ), true)
)

export const isValidEditForm = ({
    blocks,
    titleNl,
    titleEn,
    titleFr,
    subTitleNl,
    subTitleEn,
    subTitleFr,
}) => (
    isValidBlocks(blocks)
  && isValidTitle(titleNl)
  && isValidTitle(titleEn)
  && isValidTitle(titleFr)
  && isValidSubTitle(subTitleNl)
  && isValidSubTitle(subTitleEn)
  && isValidSubTitle(subTitleFr)
  && blocks.reduce((isValidBlock, block) => (
      isValidBlock
    && isValidBlockTitle(block.titleNl)
    && isValidBlockTitle(block.titleEn)
    && isValidBlockTitle(block.titleFr)
    && isValidDiscount(block.discountNl)
    && isValidDiscount(block.discountEn)
    && isValidDiscount(block.discountFr)
    && isValidColor(block.color)
  ), true)
)

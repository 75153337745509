import createPaginationReducer from '../hor/createPaginationReducer'
import {
    BOOKINGS_REQUEST,
    BOOKINGS_SUCCESS,
    BOOKINGS_FAILURE,
} from '../../actions/bookings'

export const getPaginationKey = (componentName, venueId) => `${componentName}(venues/${venueId})`

const bookingsPaginationReducer = createPaginationReducer({
    mapActionToKey: ({ componentName, venueId }) => getPaginationKey(componentName, venueId),
    types: [
        BOOKINGS_REQUEST,
        BOOKINGS_SUCCESS,
        BOOKINGS_FAILURE,
    ],
})

export default bookingsPaginationReducer

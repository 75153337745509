import { CALL_API, METHODS } from '../middleware/api'
import { voucherSchema, voucherSchemaArray } from '../schemas/vouchers'
import { couponSchema } from '../schemas/coupons'

export const VOUCHER_REQUEST = 'VOUCHER_REQUEST'
export const VOUCHER_SUCCESS = 'VOUCHER_SUCCESS'
export const VOUCHER_FAILURE = 'VOUCHER_FAILURE'

export const VOUCHERS_REQUEST = 'VOUCHERS_REQUEST'
export const VOUCHERS_SUCCESS = 'VOUCHERS_SUCCESS'
export const VOUCHERS_FAILURE = 'VOUCHERS_FAILURE'

/**
 * @param viewName E.g. 'overview'
 * @param params
 * @returns {Object}
 */
export function fetchVouchers(viewName, params) {
    return {
        viewName,
        [CALL_API]: {
            types: [
                VOUCHERS_REQUEST,
                VOUCHERS_SUCCESS,
                VOUCHERS_FAILURE,
            ],
            endpoint: '/vouchers',
            schema: voucherSchemaArray,
            params,
        },
    }
}

export const refreshPaginatedVouchers = (paginationKey, params) => (dispatch, getState) => {
    const voucherPagination = getState().pagination.vouchers[paginationKey]
    const newParams = {
        ...voucherPagination.params,
        ...params,
    }
    return dispatch(fetchVouchers(paginationKey, newParams))
}

/**
 * @param {Object} body request body
 * @returns {Object}
 */
export function createVoucher(body) {
    return {
        [CALL_API]: {
            method: METHODS.POST,
            types: [
                VOUCHER_REQUEST,
                VOUCHER_SUCCESS,
                VOUCHER_FAILURE,
            ],
            endpoint: '/vouchers',
            schema: voucherSchema,
            body,
        },
    }
}

/**
 * @param {number} voucherId Id of the voucher
 * @param {Object} body Request body
 * @returns {Object}
 */
export function editVoucher(voucherId, body) {
    return {
        [CALL_API]: {
            method: METHODS.PATCH,
            types: [
                VOUCHER_REQUEST,
                VOUCHER_SUCCESS,
                VOUCHER_FAILURE,
            ],
            endpoint: `/vouchers/${voucherId}`,
            schema: voucherSchema,
            body,
        },
    }
}

/**
 * @param {number} voucherId Id of the voucher to delete
 * @returns {Object}
 */
export function deleteVoucher(voucherId) {
    return {
        [CALL_API]: {
            method: METHODS.DELETE,
            types: [
                VOUCHER_REQUEST,
                VOUCHER_SUCCESS,
                VOUCHER_FAILURE,
            ],
            endpoint: `/vouchers/${voucherId}`,
            schema: couponSchema,
            entityId: voucherId,
        },
    }
}

import dayjs from 'dayjs'
import { BACKEND_DATE_TIME_FORMAT } from '../constants'

function extractCurrencyCode(amount) {
    let result = ''
    try {
        if (amount) {
            result = amount.slice(1)
        }
    } catch (e) {
        console.error(e)
    }

    return result
}

export function toBatchApiRequest(values) {
    return {
        prefix: values.prefix,
        code: values.code,
        is_percentage: values.amountType === 'isPercentage',
        amount: values.amount,
        usage_limit: values.usageLimit,
        description: values.description,
        is_multi_use: values.type === 'multiple',
        min_player_amount: values.minPlayerAmount,
        expires_at: values.expiresAt
            ? dayjs.utc(values.expiresAt).endOf('day').format(BACKEND_DATE_TIME_FORMAT)
            : null,
        last_possible_play_date: values.lastPossiblePlayDate
            ? dayjs.utc(values.lastPossiblePlayDate).endOf('day').format(BACKEND_DATE_TIME_FORMAT)
            : null,
        batch_amount: values.batchAmount,
        legalEntityId: values.legalEntity,
        venueId: values.venue,
        is_allowed_for_giftcards: values.isAllowedForGiftcards,
    }
}

export function mapCouponBatchToValues(values) {
    return {
        id: values.id,
        prefix: values.prefix,
        isPercentage: values.isPercentage,
        amountType: values.isPercentage ? 'isPercentage' : 'isAmount',
        amount: values.isPercentage ? values.amount * 100 : extractCurrencyCode(values.amount),
        usageLimit: values.usageLimit,
        code: values.code,
        description: values.description,
        isMultiUse: values.type === 'multiple',
        minPlayerAmount: values.minPlayerAmount,
        expiresAt: values.expiresAt ? dayjs.utc(values.expiresAt) : null,
        lastPossiblePlayDate: values.lastPossiblePlayDate ? dayjs.utc(values.lastPossiblePlayDate) : null,
        batchAmount: values.couponCount,
        legalEntity: values.legalEntity ? values.legalEntity.id : null,
        venue: values.venue !== null && values.venue !== 'all_venues' ? values.venue.id : 'all_venues',
        isAllowedForGiftcards: values.isAllowedForGiftcards,
    }
}

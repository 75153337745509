import React from 'react'
import { Modal } from 'antd'
import { ModalFooter } from 'components/util/modals/ModalFooter'
import {EditBookingForm} from "../../forms/edit/EditBookingForm"

/**
 * @component
 * @param {Object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.onClose
 * @param {CreateOrEditBookingFormValues} props.values
 * @param {{ [key in keyof CreateOrEditBookingFormValues]: string[]}} props.errors
 * @param {boolean} props.isLoading
 * @param {(valuesToMerge: Object) => void} props.mergeValues
 * @param {(e: any) => void} props.onChange
 * @param {(key: keyof CreateOrEditBookingFormValues, value: CreateOrEditBookingFormValues[key]) => void } props.onChangeAsValue
 * @param {() => void} props.onCancel
 * @param {(e: any) => void } props.onSubmit
 * @param {string} props.priceSystem
 * @param {(requestBody: Object) => void} props.triggerPriceUpdate
 * @param {UseFetchPriceStateReturnObject} props.priceHandle
 * @returns {Element}
 * @constructor
 */
export function EditBookingModal(props) {
    // console.log("Edit modal props:", props)
    return (
        <Modal
            title={props.isOpen && props.values ? `Edit booking by ${props.values.bookerFirstName} ${props.values.bookerLastName}` : 'Edit booking'}
            width={700}
            open={props.isOpen}
            onOk={props.onSubmit}
            onCancel={props.onClose}
            closable={!props.isLoading}
            maskClosable={!props.isLoading}
            footer={(
                <ModalFooter
                    isLoading={props.isLoading || props.priceHandle.isLoading}
                    onCancel={props.onCancel}
                    onSubmit={props.onSubmit}
                />
            )}
        >
            {
                props.isOpen && (
                    <EditBookingForm
                        isLoading={props.isLoading}
                        values={props.values}
                        errors={props.errors}
                        mergeValues={props.mergeValues}
                        onChange={props.onChange}
                        onChangeAsValue={props.onChangeAsValue}
                        triggerPriceUpdate={props.triggerPriceUpdate}
                        priceHandle={props.priceHandle}
                        priceSystem={props.priceSystem}
                    />
                )
            }
        </Modal>
    )
}

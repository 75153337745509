import React from 'react'
import { Modal } from 'antd'
import { ModalFooter } from 'components/util/modals/ModalFooter'
import CreateGiftCardForm from 'components/pages/settings/gift-cards/forms/create/CreateGiftCardForm'

function CreateGiftCardModal({
    isOpen,
    onClose,
    values,
    errors,
    isLoading,
    onChange,
    onChangeAsValue,
    onCancel,
    onSubmit,
    activeLegalEntity,
    legalEntities,
}) {
    return (
        <Modal
            title="Create new gift card"
            open={isOpen}
            onOk={onSubmit}
            onCancel={onClose}
            closable={!isLoading}
            maskClosable={!isLoading}
            footer={(
                <ModalFooter
                    isLoading={isLoading}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                />
            )}
        >
            {isOpen && (
                <CreateGiftCardForm
                    isLoading={isLoading}
                    values={values}
                    errors={errors}
                    onChange={onChange}
                    onChangeAsValue={onChangeAsValue}
                    onCreateSucces={onClose}
                    activeLegalEntity={activeLegalEntity}
                    legalEntities={legalEntities}
                />
            )}
        </Modal>
    )
}

export default CreateGiftCardModal

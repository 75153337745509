import React, { useMemo, useState, useCallback } from 'react'
import dayjs from 'dayjs'
import './CreateVoucherForm.css'
import { Form, InputNumber, Select, DatePicker, Input } from 'antd'
import ExtraInfoLabel from '../../../../../util/forms/ExtraInfoLabel'
import { getLocaleCurrencySymbol } from '../../../../../../util/currency'
import { APP_DATE_FORMAT } from '../../../../../../util/constants'
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js'
import sanitizeHtml from 'sanitize-html'
import draftToHtml from 'draftjs-to-html'
import { Editor } from 'react-draft-wysiwyg'

const COLUMN_SIZE = 24
const LABEL_COLUMN_SIZE = 8

const labelColProps = {
    xs: { span: COLUMN_SIZE },
    sm: { span: LABEL_COLUMN_SIZE },
}

const wrapperColProps = {
    xs: { span: COLUMN_SIZE },
    sm: { span: COLUMN_SIZE - LABEL_COLUMN_SIZE, offset: 0 },
}

// See 'toolbar' section: https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
const richTextEditorToolBarOptions = {
    options: ['inline', 'list'],
    inline: {
        options: ['bold', 'italic'],
        inDropDown: false,
    },
    list: {
        inDropdown: false,
        options: ['unordered', 'ordered'],
    },
}

const endOfCurrentDay = dayjs().endOf('day')
const labelAlign = 'right'


/**
 * @param {Object} props
 * @param {boolean} props.isLoading
 * @param {boolean} props.isSubmitted
 * @param {Object} props.values
 * @param {Object} props.errors
 * @param {(event: InputEvent) => void} props.onChange
 * @param {(key: string, value: any) => void} props.onChangeAsValue
 * @param {() => void} props.mergeValues
 * @param {Object[]} props.legalEntities
 * @param {Object[]} props.venues
 * @param {Object[]} props.voucherIssueReasons
 * @returns {React.JSX.Element}
 * @constructor
 */
export function CreateVoucherForm(props) {
    // console.log('values:', props.values)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const onEditorStateChange = useCallback((newEditorState) => {
        // See answer from 10 january 2019
        // https://github.com/facebookarchive/draft-js/issues/119
        const newContentState = newEditorState.getCurrentContent()

        const raw = convertToRaw(newContentState)
        // console.log('raw', raw)
        const html = sanitizeHtml(draftToHtml(raw))
        // console.log('html', html)

        // console.log('html length', html.length)
        // const newContentPlainText = newContentState.getPlainText()
        // 512 characters is the max our database column allows!
        if (html.length < 513) {
            setEditorState(newEditorState)
            props.onChangeAsValue('comment', html)
        } else {
            const oldContent = editorState.getCurrentContent()
            const finalEditorState = EditorState.moveFocusToEnd(
                EditorState.push(
                    editorState,
                    ContentState.createFromBlockArray(oldContent.getBlocksAsArray()),
                ),
            )
            setEditorState(finalEditorState)
        }
    }, [editorState, props.onChangeAsValue])

    const venuesInLegalEntity = useMemo(() => {
        if (typeof props.values.legalEntityId !== 'number' || props.values.legalEntityId <= 0) {
            return []
        }
        if (!Array.isArray(props.venues)) {
            return []
        }

        // console.log('props.venues', props.venues)
        return props.venues.filter((venue) => venue.public && venue.legalEntityId === props.values.legalEntityId)
    }, [props.values.legalEntityId, props.venues])

    const currencySymbol = useMemo(() => {
        if (typeof props.values.legalEntityId !== 'number')
            return undefined
        if (!Array.isArray(props.legalEntities))
            return undefined

        const legalEntity = props.legalEntities.find((legalEntity) => legalEntity.id === props.values.legalEntityId)
        const currencyCode = legalEntity.country.currencyCode
        return getLocaleCurrencySymbol(currencyCode, 'en-US')
    }, [props.values.legalEntityId, props.legalEntities])

    // console.log('Venues in Legal Entity:', venuesInLegalEntity)
    // console.log('currencyCode', currencyCode)
    // console.log('props.values.amount', props.values.amount)

    return (
        <Form>
            <Form.Item
                label={<Label label={'Select country'} />}
                labelCol={labelColProps}
                labelAlign={labelAlign}
                colon={false}
                wrapperCol={wrapperColProps}
                required={true}
                hasFeedback={true}
                validateStatus={props.errors.legalEntityId ? 'error' : ''}
                help={props.errors.legalEntityId}
                extra={(
                    <ExtraInfoLabel message={"Vouchers can only be issued and used within the specified country."} />
                )}
            >
                <Select
                    name="legalEntityId"
                    value={props.values.legalEntityId}
                    onChange={(value) => {
                        // Reset venueId when legalEntityId changes
                        if (value !== props.values.legalEntityId)
                            props.onChangeAsValue('issuedInVenueId', null)

                        props.onChangeAsValue('legalEntityId', value)
                    }}
                    placeholder="Select country"
                >
                    {
                        props.legalEntities.map((legalEntity) => (
                            <Select.Option key={legalEntity.id} value={legalEntity.id}>
                                {legalEntity.country.name} - {legalEntity.name}
                            </Select.Option>
                        ))
                    }
                </Select>
            </Form.Item>

            {
                typeof props.values.legalEntityId === 'number' &&
                props.values.legalEntityId > 0 && (
                    <>
                        <Form.Item
                            label={<Label label={'Voucher amount'} />}
                            labelCol={labelColProps}
                            labelAlign={labelAlign}
                            colon={false}
                            wrapperCol={wrapperColProps}
                            required={true}
                            hasFeedback={true}
                            validateStatus={props.errors.amount ? 'error' : ''}
                            help={props.errors.amount}
                            extra={(
                                <ExtraInfoLabel message={"Use a dot instead of a comma"} />
                            )}
                        >
                            <InputNumber
                                name="amount"
                                value={props.values.amount}
                                prefix={currencySymbol}
                                onChange={(value) => props.onChangeAsValue('amount', value)}
                                min={1.00}
                                step={1}
                            />
                        </Form.Item>

                        <Form.Item
                            label={<Label label={'Expiry date'} />}
                            labelCol={labelColProps}
                            labelAlign={labelAlign}
                            colon={false}
                            wrapperCol={wrapperColProps}
                            required={true}
                            hasFeedback={true}
                            validateStatus={props.errors.expiryDate ? 'error' : ''}
                            help={props.errors.expiryDate }
                            extra={(
                                <ExtraInfoLabel message={"Coupon will expiry at the end of selected day"} />
                            )}
                        >
                            <DatePicker
                                name="expiryDate"
                                format={APP_DATE_FORMAT}
                                value={props.values.expiryDate}
                                disabledDate={(current) => current < endOfCurrentDay}
                                onChange={(value) => props.onChangeAsValue('expiryDate', value)}
                            />
                        </Form.Item>

                        <Form.Item
                            label={<Label label={'Issue reason'} />}
                            labelCol={labelColProps}
                            labelAlign={labelAlign}
                            colon={false}
                            wrapperCol={wrapperColProps}
                            required={true}
                            hasFeedback={true}
                            validateStatus={props.errors.issuedReasonId ? 'error' : ''}
                            help={props.errors.issuedReasonId}
                        >
                            <Select
                                name="issueReasonId"
                                value={props.values.issuedReasonId}
                                onChange={(value) => props.onChangeAsValue('issuedReasonId', value)}
                                placeholder="Select reason for issuing voucher"
                            >
                                {
                                    props.voucherIssueReasons.map((issueReason) => (
                                        <Select.Option key={issueReason.id} value={issueReason.id}>
                                            {issueReason.name}
                                        </Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={<Label label={'Issued in venue'} />}
                            labelCol={labelColProps}
                            labelAlign={labelAlign}
                            colon={false}
                            wrapperCol={wrapperColProps}
                            required={false}
                            hasFeedback={true}
                            validateStatus={props.errors.venueId ? 'error' : ''}
                            help={props.errors.venueId}
                            extra={(
                                <ExtraInfoLabel message={"(optional) What venue is the voucher issued in?"} />
                            )}
                        >
                            <Select
                                name="venueId"
                                value={props.values.issuedInVenueId}
                                onChange={(value) => props.onChangeAsValue('issuedInVenueId', value)}
                                placeholder="Select venue"
                            >
                                {
                                    venuesInLegalEntity.map((venue) => (
                                        <Select.Option key={venue.id} value={venue.id}>
                                            {venue.name}
                                        </Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={<Label label={'Deal number'} />}
                            labelCol={labelColProps}
                            labelAlign={labelAlign}
                            colon={false}
                            wrapperCol={wrapperColProps}
                            required={false}
                            hasFeedback={true}
                            validateStatus={props.errors.dealNumber ? 'error' : ''}
                            help={props.errors.dealNumber}
                        >
                            <Input
                                name="dealNumber"
                                placeholder={"Provide an optional deal number"}
                                value={props.values.dealNumber}
                                onChange={(event) => props.onChangeAsValue('dealNumber', event.target.value)}
                                maxLength={15}
                            />
                        </Form.Item>

                        <Form.Item
                            label={<Label label={'Associated order number'} />}
                            labelCol={labelColProps}
                            labelAlign={labelAlign}
                            colon={false}
                            wrapperCol={wrapperColProps}
                            required={false}
                            hasFeedback={true}
                            validateStatus={props.errors.associatedOrderNumber ? 'error' : ''}
                            help={props.errors.associatedOrderNumber}
                        >
                            <Input
                                name="associatedOrderNumber"
                                placeholder={"Provide an optional associated order number"}
                                value={props.values.associatedOrderNumber}
                                onChange={(event) => props.onChangeAsValue('associatedOrderNumber', event.target.value)}
                                maxLength={26}
                            />
                        </Form.Item>


                        <Form.Item
                            label={<Label label={'Comment'} />}
                            labelCol={{ span: COLUMN_SIZE }}
                            labelAlign={labelAlign}
                            colon={false}
                            wrapperCol={{ span: COLUMN_SIZE}}
                            required={false}
                            hasFeedback={true}
                            validateStatus={props.errors.comment ? 'error' : ''}
                            help={props.errors.comment}
                        >

                            <Editor
                                name="comment"
                                toolbar={richTextEditorToolBarOptions}
                                editorState={editorState}
                                wrapperClassName="wrapperClassName"
                                editorClassName={"create-voucher-form-editor"}
                                placeholder={"Provide an optional comment"}
                                onEditorStateChange={onEditorStateChange}
                            />
                        </Form.Item>
                    </>
                )
            }
        </Form>
    )
}

/**
 * @param {Object} props
 * @param {string} props.label
 * @returns {React.JSX.Element}
 * @constructor
 */
function Label(props) {
    return <span className="create-voucher-form-label">{props.label}</span>
}


import {useCallback} from "react"
import callApi from "../../../store/middleware/callApi"
import '../../../types/apiResources/gameSessions/GameSessionResource'

/**
 * @typedef {Object} PutGameSessionRequestBodyPlayer
 * @property {string} nickname
 * @property {number} playerNumber
 * @property {number|undefined} customerProfileId
 */

/**
 * @typedef {Object} PutGameSessionRequestBodyTeam
 * @property {number} id Id of the team
 * @property {string} name Name of the team
 * @property {number | undefined} newAvatarImageMediaId Backend media item id of the team (you need to upload these first)
 */

/**
 * @typedef {Object} PutGameSessionRequestBody
 * @property {PutGameSessionRequestBodyPlayer[]} gameSessionPlayers
 * @property {PutGameSessionRequestBodyTeam[] | undefined} teams
 */

/**
 * @typedef {Object} PutGameSessionByIdRequestArgs
 * @property {number} venueId
 * @property {number} gameSessionId
 * @property {PutGameSessionRequestBody} requestBody
 */

/**
 * @typedef {(gameSession: GameSessionResource | null, errorMessage: string | null) => void} GetGameSessionByIdCallback
 */

/**
 * @typedef {(gameSession: GameSessionResource | null, errorMessage: string | null) => void} PutGameSessionByIdCallback
 */

/**
 * @typedef {Object} UseGameSessionAPIReturnObj
 * @property {(venueId: number, id: number, callBack: GetGameSessionByIdCallback) => Promise<void>} getGameSessionById
 * @property {(args: PutGameSessionByIdRequestArgs, callBack: GetGameSessionByIdCallback) => Promise<void>} putGameSessionById
 */

/**
 *
 * @returns {UseGameSessionAPIReturnObj}
 */
export function useGameSessionAPI() {
    /**
     * @type {(function(venueId: number, gameSessionId: number, callback: GetGameSessionByIdCallback): Promise<void>)|*}
     */
    const getGameSessionById = useCallback(async (venueId, gameSessionId, callback) => {
        const endpoint = `/venues/${venueId}/game-sessions/${gameSessionId}`

        try {
            const response = await callApi(
                "GET",
                endpoint,
            )
            // console.log("json:", response.data)
            callback(response.data, null)
        } catch (/** @type {Error} */ error) {
            console.error("Error fetching game session:", error)
            const message = "Error fetching game session: " + error.message
            callback(null, message)
        }
    }, [])

    /**
     * @type {((args: PutGameSessionByIdRequestArgs, callback: PutGameSessionByIdCallback) => Promise<void>)}
     */
    const putGameSessionById = useCallback(async (args, callback) => {
        const endpoint = `/venues/${args.venueId}/game-sessions/${args.gameSessionId}`

        try {
            const response = await callApi(
                "PUT",
                endpoint,
                undefined,
                undefined,
                args.requestBody,
            )

            callback(response.data, null)
        } catch (/** @type {Error} */ error) {
            console.error("Error updating game session:", error)
            const message = "Error updating game session: " + error.message
            callback(null, message)
        }
    }, [])

    return {
        getGameSessionById: getGameSessionById,
        putGameSessionById: putGameSessionById,
    }
}
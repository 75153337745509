import { defaultPaginationState } from '../reducers/hor/createPaginationReducer'

export function getEntities(state, entityReducerKey) {
    return state.entities[entityReducerKey]
}

export function getEntitiesAsArray(state, entityReducerKey) {
    const entities = state.entities[entityReducerKey]
    const keys = Object.keys(entities)
    const asArray = keys.map((key) => entities[key])
    return asArray
}

export function getPagination(state, entityReducerKey, paginationReducerKey) {
    return state.pagination[entityReducerKey][paginationReducerKey] || defaultPaginationState
}

export function getEntitiesByPagination(state, entityReducerKey, paginationReducerKey) {
    const entities = getEntities(state, entityReducerKey)
    const pagination = getPagination(state, entityReducerKey, paginationReducerKey)
    const existingIds = pagination.ids.filter((id) => typeof entities[id] !== 'undefined')
    const asArray = existingIds.map((id) => entities[id])
    return asArray

    // return pagination.ids.filter((id) => typeof entities[id] !== 'undefined').map((id) => entities[id])
}

export function getEntitiesForCurrentPage(state, entityReducerKey, paginationReducerKey) {
    const entities = getEntities(state, entityReducerKey)
    const pagination = getPagination(state, entityReducerKey, paginationReducerKey)
    const { params, pages } = pagination
    const pageIds = pages[params.page] || []
    return pageIds.filter((id) => typeof entities[id] !== 'undefined').map((id) => entities[id])
}

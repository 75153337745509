/* eslint-disable max-len */
/** @var string Formats to "10:30" for example */
export const TIME_FORMAT = 'H:i'

/** available */
export const STATUS_AVAILABLE = 'available'
/** not available */
export const STATUS_NOT_AVAILABLE = 'not_available'
/** booked */
export const STATUS_BOOKED = 'booked'
/** A slot that is not available but doesn't match with the available status'. */
// const STATUS_BLOCKED = 'blocked';
/** Slot that is reserverd for a specific event e.g. network event, maintenance, special events... */
export const STATUS_EVENT = 'event'
/** Slot that is reserverd but not yet booked, aka 'in option'. */
export const STATUS_OPTION = 'option'
/** Slot that was blocked by daily autoblocking cron job. */
export const STATUS_AUTO_BLOCKED = 'auto_blocked'
/** Unavailable time slot that provides time (aka a time buffer) for potential booking delays. */
export const STATUS_BUFFER = 'buffer'
/** Optional buffers that can be added to bookings */
export const STATUS_BOOKING_BUFFER = 'booking_buffer'
/** @deprecated remove this */
// const STATUS_MANUALLY_CREATED = 'manually_created';
/** Time slot that has passed. */
export const STATUS_PASSED = 'passed'

/** All statusses that are clickable */
export const clickableStatusses = [
    STATUS_AVAILABLE,
    STATUS_EVENT,
    STATUS_OPTION,
    STATUS_AUTO_BLOCKED,
    STATUS_BUFFER,
    STATUS_BOOKING_BUFFER,
]

export const assignableStatusses = [
    STATUS_AVAILABLE,
    STATUS_EVENT,
    STATUS_OPTION,
    STATUS_BUFFER,
]

/** All statusses that are selectable */
export const selectableStatusses = [
    STATUS_AVAILABLE,
]

import { createValidationErrorObject } from './index'
import * as validation from './discountBanner'

export const getBlocksError = createValidationErrorObject(
    validation.isValidBlocks,
    'blocks',
    'Please add blocks.',
)

const titleDescription = 'Please fill in a valid title'

export const getBlockTitleError = (lang, key) => createValidationErrorObject(
    validation.isValidBlockTitle,
    `block${key}Title${lang}`,
    titleDescription,
)

export const getDiscountError = (lang, key) => createValidationErrorObject(
    validation.isValidDiscount,
    `block${key}Discount${lang}`,
    'Please fill in a valid discount',
)

export const getColorError = (key) => createValidationErrorObject(
    validation.isValidColor,
    `block${key}Color`,
    'Please fill in a valid color',
)

export const getBlocksErrors = (blocks) => ({
    ...blocks.reduce((errors, block, key) => ({
        ...errors,
        ...getBlockTitleError('Nl', key)(block.titleNl),
        ...getBlockTitleError('En', key)(block.titleEn),
        ...getBlockTitleError('Fr', key)(block.titleFr),
        ...getDiscountError('Nl', key)(block.discountNl),
        ...getDiscountError('En', key)(block.discountEn),
        ...getDiscountError('Fr', key)(block.discountFr),
        ...getColorError(key)(block.color),
    }), {}),
})

export const getTitleEnError = createValidationErrorObject(
    validation.isValidTitle,
    'titleEn',
    titleDescription,
)

export const getTitleNlError = createValidationErrorObject(
    validation.isValidTitle,
    'titleNl',
    titleDescription,
)

export const getTitleFrError = createValidationErrorObject(
    validation.isValidTitle,
    'titleFr',
    titleDescription,
)

const subTitleDescription = 'Please fill in a valid subtitle'

export const getSubTitleEnError = createValidationErrorObject(
    validation.isValidSubTitle,
    'subTitleEn',
    subTitleDescription,
)

export const getSubTitleNlError = createValidationErrorObject(
    validation.isValidSubTitle,
    'subTitleNl',
    subTitleDescription,
)

export const getSubTitleFrError = createValidationErrorObject(
    validation.isValidSubTitle,
    'subTitleFr',
    subTitleDescription,
)

export const getDiscountBannerErrors = ({
    blocks,
    titleEn,
    titleNl,
    titleFr,
    subTitleEn,
    subTitleNl,
    subTitleFr,
}) => ({
    ...getBlocksError(blocks),
    ...getBlocksErrors(blocks),
    ...getTitleEnError(titleEn),
    ...getTitleNlError(titleNl),
    ...getTitleFrError(titleFr),
    ...getSubTitleEnError(subTitleEn),
    ...getSubTitleNlError(subTitleNl),
    ...getSubTitleFrError(subTitleFr),
})

export const getEditDiscountBannerFormErrors = ({
    blocks,
    titleEn,
    titleNl,
    titleFr,
    subTitleEn,
    subTitleNl,
    subTitleFr,
}) => ({
    ...getBlocksError(blocks),
    ...getBlocksErrors(blocks),
    ...getTitleEnError(titleEn),
    ...getTitleNlError(titleNl),
    ...getTitleFrError(titleFr),
    ...getSubTitleEnError(subTitleEn),
    ...getSubTitleNlError(subTitleNl),
    ...getSubTitleFrError(subTitleFr),
})

import React from 'react'
import { getEntityFromResponse } from 'util/index'
import { WithFormState } from 'components/util/forms/WithFormState'
import DeleteCouponRequest from './DeleteCouponRequest'

function DeleteCouponFormState({
    onCancel,
    onDeleteSuccess,
    values,
    children,
}) {
    return (
        <DeleteCouponRequest paginationKey="overview">
            {({
                deleteCoupon,
                fetchCoupons,
                deleteCouponSuccess,
                deleteCouponFailed,
            }) => (
                <WithFormState
                    transformValuesToRequestBody={() => values}
                    onSendRequest={() => deleteCoupon(values)}
                    onCancel={onCancel}
                    onRequestSuccess={(response) => {
                        const coupon = getEntityFromResponse(response, 'coupons')
                        fetchCoupons()
                        deleteCouponSuccess(coupon)
                        onDeleteSuccess(coupon)
                    }}
                    onRequestFailed={deleteCouponFailed}
                >
                    {children}
                </WithFormState>
            )}
        </DeleteCouponRequest>
    )
}

export default DeleteCouponFormState

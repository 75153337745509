/* eslint-disable max-len */
import React from "react"
import {
    Select,
    Switch,
    Input,
    Radio,
    Checkbox,
} from "antd"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import ExtraInfoLabel from "components/util/forms/ExtraInfoLabel"
import {BookingFormItem} from "./BookingFormItem"

const { Option } = Select

/**
 * @param {Object} props
 * @param {CreateOrEditBookingFormValues} props.values
 * @param {{ [Key in keyof CreateOrEditBookingFormValues]: string[]}} props.errors
 * @param {(e: any) => void} props.onChange
 * @param {(key: keyof CreateOrEditBookingFormValues, value: CreateOrEditBookingFormValues[Key]) => void } props.onChangeAsValue
 * @param {CountryResource[]} props.countries
 */
export function BookingCompanyFormItems(props) {
    return (
        <>
            <BookingFormItem>
                <Radio.Group
                    name="isCompanyBooking"
                    value={props.values.isCompanyBooking}
                    onChange={props.onChange}
                >
                    <Radio.Button value={false}>
                        B2C
                    </Radio.Button>
                    <Radio.Button value={true}>
                        B2B
                    </Radio.Button>
                </Radio.Group>
            </BookingFormItem>

            {
                props.values.isCompanyBooking && (
                    <div className="marginTop">
                        <BookingFormItem
                            label="Company Name"
                            error={props.errors.companyName}
                            isRequired={props.values.isCompanyBooking}
                        >
                            <Input
                                name="companyName"
                                value={props.values.companyName}
                                onChange={props.onChange}
                            />
                        </BookingFormItem>
                        <BookingFormItem
                            label="Country"
                            error={props.errors.companyCountryCode}
                            isRequired={props.values.isCompanyBooking}
                        >
                            <Select
                                onChange={(value) => {
                                    props.onChangeAsValue("companyCountryCode", value)
                                }}
                                value={props.values.companyCountryCode}
                                placeholder="Please select a country"
                            >
                                {
                                    props.countries.map((country) => (
                                        <Option
                                            key={country.code}
                                            value={country.code}
                                        >
                                            {country.name}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </BookingFormItem>

                        <BookingFormItem
                            label="VAT number"
                            error={props.errors.companyHasVatNumber}
                            isRequired={props.values.companyHasVatNumber}
                            extra={(
                                <>
                                    <Checkbox
                                        name="companyHasVatNumber"
                                        checked={props.values.companyHasVatNumber !== true}
                                        onChange={(e) => props.onChangeAsValue("companyHasVatNumber", !e.target.checked)}
                                    >
                                        {"Company doesn't have a VAT number"}
                                    </Checkbox>
                                </>
                            )}
                        >
                            <Input
                                name="companyVatNumber"
                                value={props.values.companyVatNumber}
                                onChange={props.onChange}
                                disabled={props.values.companyHasVatNumber === false}
                            />
                        </BookingFormItem>
                        <BookingFormItem
                            label="Street & Nr"
                            error={props.errors.companyAddress}
                            isRequired={props.values.isCompanyBooking}
                        >
                            <Input
                                name="companyAddress"
                                value={props.values.companyAddress}
                                onChange={props.onChange}
                            />
                        </BookingFormItem>
                        <BookingFormItem
                            label="Zip Code"
                            error={props.errors.companyZipCode}
                            isRequired={props.values.isCompanyBooking}
                        >
                            <Input
                                name="companyZipCode"
                                value={props.values.companyZipCode}
                                onChange={props.onChange}
                            />
                        </BookingFormItem>
                        <BookingFormItem
                            label="City"
                            error={props.errors.companyCity}
                            isRequired={props.values.isCompanyBooking}
                        >
                            <Input
                                name="companyCity"
                                value={props.values.companyCity}
                                onChange={props.onChange}
                            />
                        </BookingFormItem>
                        <BookingFormItem
                            label="Purchase Order Number"
                            error={props.errors.purchaseOrderNumber}
                            isRequired={false}
                            extra={<ExtraInfoLabel message="Optional purchase order number." />}
                        >
                            <Input
                                name="purchaseOrderNumber"
                                value={props.values.purchaseOrderNumber}
                                onChange={props.onChange}
                            />
                        </BookingFormItem>
                    </div>
                )
            }
        </>
    )
}

import React from "react"
import { Input } from "antd"
import SupportedLanguageSelect from "components/util/SupportedLanguageSelect"
import {MAX_FIRST_NAME_LENGTH, MAX_LAST_NAME_LENGTH} from "../../../../../util/constants"
import {BookingFormItem} from "./BookingFormItem"

/**
 * @component
 * @param {Object} props
 * @param {CreateOrEditBookingFormValues} props.values
 * @param {{ [Key in keyof CreateOrEditBookingFormValues]: string[]}} props.errors
 * @param {(e: any) => void} props.onChange
 * @param {(key: keyof CreateOrEditBookingFormValues, value: CreateOrEditBookingFormValues[Key]) => void } props.onChangeAsValue
 * @returns {JSX.Element}
 */
export function BookingBookerFormItems(props) {
    return (
        <>
            <BookingFormItem
                isRequired
                label="First Name"
                error={props.errors.bookerFirstName}
            >
                <Input
                    name="bookerFirstName"
                    value={props.values.bookerFirstName}
                    onChange={props.onChange}
                    maxLength={MAX_FIRST_NAME_LENGTH}
                />
            </BookingFormItem>
            <BookingFormItem
                isRequired
                label="Last Name"
                error={props.errors.bookerLastName}
            >
                <Input
                    name="bookerLastName"
                    value={props.values.bookerLastName}
                    onChange={props.onChange}
                    maxLength={MAX_LAST_NAME_LENGTH}
                />
            </BookingFormItem>
            <BookingFormItem
                isRequired
                label="Email"
                error={props.errors.bookerEmail}
            >
                <Input
                    name="bookerEmail"
                    value={props.values.bookerEmail}
                    onChange={props.onChange}
                />
            </BookingFormItem>
            <BookingFormItem
                label="Phone number"
                error={props.errors.bookerPhoneNumber}
            >
                <Input
                    name="bookerPhoneNumber"
                    value={props.values.bookerPhoneNumber}
                    onChange={props.onChange}
                />
            </BookingFormItem>
            <BookingFormItem
                isRequired
                label="Language"
                error={props.errors.bookerLanguageCode}
            >
                <SupportedLanguageSelect
                    value={props.values.bookerLanguageCode}
                    onChange={(value) => props.onChangeAsValue("bookerLanguageCode", value)}
                />
            </BookingFormItem>
        </>
    )
}

import { useDispatch, useSelector } from 'react-redux'
import { fetchRoles } from '../../store/actions/roles'
import { useCallback, useMemo } from 'react'

/**
 * @param {string} paginationKey
 * @param {number | undefined} limit
 * @returns {{total, roles, isFetching, rolesForCurrentPage, handleFetchRoles: (function(*): {[CALL_API]: {schema: [schema.Entity<any>], types: [string,string,string], endpoint: string, params: *}, viewName: *}), currentPage, params}}
 */
function useRoles(paginationKey, limit) {
    const dispatch = useDispatch()
    const pagination = useSelector(state => state.pagination.roles[paginationKey])
    const roles = useSelector((state) => state.entities.roles)
    // const rolesForCurrentPage = useSelector((state) => getEntitiesForCurrentPage(state, 'roles', paginationKey))

    const rolesForCurrentPage = useMemo(() => {
        if (typeof pagination !== 'object')
            return []
        if (typeof roles !== 'object')
            return []
        if (pagination.isFetching)
            return []

        const idsInPage = pagination.pages[pagination.params.page]
        const length = idsInPage.length
        const entriesInPage = Array.apply(null, Array(length)).map(() => null)
        for (let i = 0; i < length; i++) {
            const id = idsInPage[i]
            entriesInPage[i] = roles[id]
        }
        return entriesInPage
    }, [pagination, roles])

    const handleFetchRoles = useCallback((params) => {
        const page = 1
        const finalParams = {
            page,
            limit,
            ...params,
        }
        return dispatch(fetchRoles(paginationKey, finalParams))
    }, [dispatch, limit, paginationKey])

    const result = useMemo(() => {
        if (typeof pagination !== 'object') {
            return {
                isFetching: false,
                currentPage: 1,
                total: 1,
                params: 10,
                rolesForCurrentPage: rolesForCurrentPage,
                handleFetchRoles: handleFetchRoles,
            }
        } else {
            return {
                isFetching: pagination.isFetching,
                currentPage: pagination.params.page,
                total: pagination.total,
                params: pagination.params,
                rolesForCurrentPage: rolesForCurrentPage,
                handleFetchRoles: handleFetchRoles,
            }
        }
    }, [handleFetchRoles, pagination, rolesForCurrentPage])

    return result
}

export default useRoles

import { Component } from 'react'
import { connect } from 'react-redux'
import callApi from 'store/middleware/callApi'
import { METHODS } from 'store/middleware/api'

class ActiveGameSessionRequest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isFetching: true,
            activeGameSession: null,
        }
        this.fetchActiveGameSession = this.fetchActiveGameSession.bind(this)
    }

    componentDidMount() {
        this.didMounted = true
    }

    componentWillUnmount() {
        this.didMounted = false
    }

    safeSetState(...args) {
        if (this.didMounted) {
            this.setState(...args)
        }
    }

    async fetchActiveGameSession() {
        this.setState({ isFetching: true })
        const { venueId, roomId } = this.props
        const endpoint = `/venues/${venueId}/rooms/${roomId}/game-sessions/active`
        try {
            const { data: activeGameSession } = await callApi(METHODS.GET, endpoint)
            this.safeSetState({
                isFetching: false,
                activeGameSession,
            })
        } catch (e) {
            this.safeSetState({
                isFetching: false,
                activeGameSession: null,
            })
        }
    }

    render() {
        const { activeGameSessionIds, children } = this.props
        const { isFetching, activeGameSession } = this.state
        return children({
            fetchActiveGameSession: this.fetchActiveGameSession,
            isFetching,
            activeGameSession,
            activeGameSessionIds,
        })
    }
}

const mapStateToProps = (state) => ({
    activeGameSessionIds: state.activeEntities.gameSessions,
})

export default connect(mapStateToProps, null)(ActiveGameSessionRequest)

import React from 'react'
import { Modal } from 'antd'
import { ModalFooter } from 'components/util/modals/ModalFooter'
import EditCouponTimeFrameForm from
    'components/pages/settings/coupons/forms/edit-time-frame/EditCouponTimeFrameForm'

function EditCouponTimeFrameModal({
    isOpen,
    onClose,
    couponTimeFrame,
    errors,
    isLoading,
    mergeValues,
    onChange,
    onChangeAsValue,
    onCancel,
    onSubmit,
}) {
    return (
        <Modal
            title="Edit price adjustment"
            open={isOpen}
            onOk={onSubmit}
            onCancel={onClose}
            closable={!isLoading}
            maskClosable={!isLoading}
            footer={(
                <ModalFooter
                    isLoading={isLoading}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                />
            )}
        >
            {isOpen
      && (
          <EditCouponTimeFrameForm
              isLoading={isLoading}
              values={couponTimeFrame}
              errors={errors}
              mergeValues={mergeValues}
              onChange={onChange}
              onChangeAsValue={onChangeAsValue}
          />
      )}
        </Modal>
    )
}

export default EditCouponTimeFrameModal

import React from 'react'

function TableTopBar({
    children,
}) {
    return (
        <div className="flex justify-content-space-between margin-bottom">
            {children}
        </div>
    )
}

export default TableTopBar

import React from 'react'
import DeletePriceAdjustmentFormState
    from 'components/pages/settings/venue-games/forms/delete-price-adjustment/DeletePriceAdjustmentFormState'
import { ConfirmInputModal } from 'components/util/modals/ConfirmInputModal'
import { WEEK_DAYS } from 'util/constants'
import { whereId } from 'util/index'

const getDay = (key) => WEEK_DAYS.find(whereId(key))

function DeletePriceAdjustmentModal({ isOpen, entity, onClose }) {
    return (
        <DeletePriceAdjustmentFormState
            onDeleteSuccess={onClose}
            onCancel={onClose}
            values={entity}
        >
            {({
                isLoading,
                handleSubmit,
                handleCancel,
            }) => {
                if (entity === null || typeof entity === 'undefined') {
                    return null
                }
                const day = getDay(entity.day) || {}
                return (
                    <ConfirmInputModal
                        safeWord={day.name || ''}
                        title={`Are you sure you want to delete the price adjustment for ${day.name}?`}
                        isOpen={isOpen}
                        onConfirm={handleSubmit}
                        onCancel={handleCancel}
                        confirmText="Delete price adjustment"
                        confirmType="primary"
                        confirmIsDanger={true}
                        cancelText="Cancel"
                        isLoading={isLoading}
                        close={onClose}
                    >
                        <p>
                            Removing a price adjustment cannot be undone.
                            {' '}
                            <br />
                            Please type the exact name of the day to confirm.
                        </p>
                    </ConfirmInputModal>
                )
            }}
        </DeletePriceAdjustmentFormState>
    )
}

export default DeletePriceAdjustmentModal

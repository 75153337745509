import React, { useEffect } from 'react'
import PaginationTableBottomBar from 'components/util/table/PaginationTableBottomBar'
import CouponsTableTopBar from './CouponsTableTopBar'
import CouponsTable from './CouponsTable'
import { usePaginatedCoupons } from '../../../../hooks/usePaginatedCoupons'

/**
 * @param {Object} props
 * @param {string} props.currentUserRoleSlug
 * @param {(coupon: Object) => void} props.openEditModal
 * @param {(coupon: Object) => void} props.openDeleteModal
 * @returns {React.JSX.Element}
 * @constructor
 */
function CouponsTableWithCoupons(props) {
    const couponsPagination = usePaginatedCoupons('overview')

    useEffect(() => {
        // Initial fetch
        if (couponsPagination.initialFetchStarted === false && couponsPagination.isFetching === false) {
            couponsPagination.fetchCoupons({})
        }
    }, [couponsPagination])

    return (
        <>
            <CouponsTableTopBar
                activeStatusFilter={couponsPagination.paginationParams.status}
                onFilterCoupons={(status) => { couponsPagination.fetchCoupons({ ...couponsPagination.paginationParams, status: status, page: 1 }) }}
                onSearchCoupons={(search) => {
                    couponsPagination.fetchCoupons({ ...couponsPagination.paginationParams, search: search })
                }}
                searchValue={couponsPagination.paginationParams.search}
            />
            <CouponsTable
                coupons={couponsPagination.couponsForCurrentPage}
                isFetching={couponsPagination.isFetching}
                currentUserRoleSlug={props.currentUserRoleSlug}
                openDeleteModal={props.openDeleteModal}
                openEditModal={props.openEditModal}
                onSort={(sort) => {
                    couponsPagination.fetchCoupons({ ...couponsPagination.paginationParams, sort: sort })
                }}
            />
            <PaginationTableBottomBar
                currentPage={couponsPagination.currentPage}
                total={couponsPagination.totalPages}
                pageSize={couponsPagination.perPage}
                onChangePage={(page, pageSize) => couponsPagination.fetchCoupons({ ...couponsPagination.paginationParams, page: page, limit: pageSize })}
            />
        </>
    )
}
export default CouponsTableWithCoupons

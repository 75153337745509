import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import DeleteCouponTimeFrameRequest from './DeleteCouponTimeFrameRequest'

function DeleteCouponTimeFrameFormState({
    onDeleteSuccess,
    onCancel,
    values,
    children,
}) {
    return (
        <DeleteCouponTimeFrameRequest paginationKey="overview">
            {({
                deleteCouponTimeFrame,
                fetchCoupons,
                deleteCouponTimeFrameSuccess,
                deleteCouponTimeFrameFailed,
            }) => (
                <WithFormState
                    transformValuesToRequestBody={() => values}
                    onSendRequest={() => deleteCouponTimeFrame(values)}
                    onCancel={onCancel}
                    onRequestSuccess={() => {
                        fetchCoupons()
                        deleteCouponTimeFrameSuccess()
                        onDeleteSuccess()
                    }}
                    onRequestFailed={deleteCouponTimeFrameFailed}
                >
                    {children}
                </WithFormState>
            )}
        </DeleteCouponTimeFrameRequest>
    )
}

export default DeleteCouponTimeFrameFormState

import React from 'react'
import CreateEquipmentSetModal from './CreateEquipmentSetModal'
import CreateEquipmentSetModalState from './CreateEquipmentSetModalState'
import CreateEquipmentSetFormState from '../../forms/create/CreateEquipmentSetFormState'

function CreateEquipmentSetModalWithState() {
    return (
        <CreateEquipmentSetModalState>
            {({ isOpen, closeModal }) => (
                <CreateEquipmentSetFormState onCancel={closeModal} onCreateSuccess={closeModal}>
                    {({
                        values,
                        errors,
                        isLoading,
                        handleChange,
                        handleCancel,
                        handleSubmit,
                    }) => (
                        <CreateEquipmentSetModal
                            isOpen={isOpen}
                            onClose={closeModal}
                            values={values}
                            errors={errors}
                            isLoading={isLoading}
                            onChange={handleChange}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                        />
                    )}
                </CreateEquipmentSetFormState>
            )}
        </CreateEquipmentSetModalState>
    )
}
export default CreateEquipmentSetModalWithState

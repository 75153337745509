import React from 'react'
import { Select } from 'antd'
import { useSelector } from 'react-redux'
import { getPagination, getEntitiesByPagination } from 'store/util'

function LegalEntitySelect({
    value,
    placeholder = 'Please select',
    onChange,
    mode = null,
}) {
    const { isFetching } = useSelector((state) => getPagination(state, 'legalEntities', 'overview'))
    const legalEntities = useSelector((state) => getEntitiesByPagination(state, 'legalEntities', 'overview'))

    return (
        <Select
            mode={mode}
            disabled={isFetching}
            onChange={onChange}
            value={isFetching ? 'Loading legal entities...' : value}
            placeholder={isFetching ? 'Loading legal entities...' : placeholder}
            filterOption={(input, option) => (
                option.props.children.toLowerCase().includes(input.toLowerCase())
            )}
        >
            {legalEntities.map((le) => (
                <Select.Option
                    key={le.id}
                    value={le.id}
                >
                    {le.country.code} - {le.name}
                </Select.Option>
            ))}
        </Select>
    )
}

export default LegalEntitySelect

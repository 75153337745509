import React from 'react'
import { getEntityFromResponse } from 'util/index'
import { WithFormState } from 'components/util/forms/WithFormState'
import DeleteRoomRequest from './DeleteRoomRequest'

function DeleteRoomFormState({
    onDeleteSuccess,
    onCancel,
    values,
    children,
}) {
    return (
        <DeleteRoomRequest paginationKey="roomsOverview">
            {({
                deleteRoom,
                fetchRooms,
                deleteRoomSuccess,
                deleteRoomFailed,
            }) => (
                <WithFormState
                    transformValuesToRequestBody={() => values}
                    onSendRequest={() => deleteRoom(values)}
                    onCancel={onCancel}
                    onRequestSuccess={(response) => {
                        const room = getEntityFromResponse(response, 'rooms')
                        const roomId = room.id
                        fetchRooms()
                        deleteRoomSuccess()
                        onDeleteSuccess(roomId)
                    }}
                    onRequestFailed={deleteRoomFailed}
                >
                    {children}
                </WithFormState>
            )}
        </DeleteRoomRequest>
    )
}

export default DeleteRoomFormState

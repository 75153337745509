export function toRoomApiRequest(values) {
    return { ...values }
}

export function toEditRoomApiRequest(values) {
    return {
        bookableHours: {
            monday: {
                start: values.mondayStart,
                end: values.mondayEnd,
            },
            tuesday: {
                start: values.tuesdayStart,
                end: values.tuesdayEnd,
            },
            wednesday: {
                start: values.wednesdayStart,
                end: values.wednesdayEnd,
            },
            thursday: {
                start: values.thursdayStart,
                end: values.thursdayEnd,
            },
            friday: {
                start: values.fridayStart,
                end: values.fridayEnd,
            },
            saturday: {
                start: values.saturdayStart,
                end: values.saturdayEnd,
            },
            sunday: {
                start: values.sundayStart,
                end: values.sundayEnd,
            },
        },
        equipmentSets: values.equipmentSets,
        roomConfigFolder: values.roomConfigFolder,
        gameServerName: values.gameServerName,
        games: values.games,
        name: values.name,
        seats: values.seats,
    }
}

export function mapRoomToValues(room) {
    return {
        ...room,
        mondayStart: room.bookableHours.monday.start,
        mondayEnd: room.bookableHours.monday.end,
        tuesdayStart: room.bookableHours.tuesday.start,
        tuesdayEnd: room.bookableHours.tuesday.end,
        wednesdayStart: room.bookableHours.wednesday.start,
        wednesdayEnd: room.bookableHours.wednesday.end,
        thursdayStart: room.bookableHours.thursday.start,
        thursdayEnd: room.bookableHours.thursday.end,
        fridayStart: room.bookableHours.friday.start,
        fridayEnd: room.bookableHours.friday.end,
        saturdayStart: room.bookableHours.saturday.start,
        saturdayEnd: room.bookableHours.saturday.end,
        sundayStart: room.bookableHours.sunday.start,
        sundayEnd: room.bookableHours.sunday.end,
    }
}

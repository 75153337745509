import React from 'react'
import { Button } from 'antd'

export function ModalFooter({
    isLoading,
    cancelText = 'Cancel',
    disableCancel,
    disableSubmit,
    onCancel,
    onSubmit,
    submitText = 'Submit',
    submitType = 'primary',
    submitIsDanger = false,
}) {
    return (
        <>
            <Button
                key="back"
                onClick={onCancel}
                disabled={isLoading || disableCancel}
            >
                {cancelText}
            </Button>
            <Button
                key="submit"
                danger={submitIsDanger}
                type={submitType}
                onClick={onSubmit}
                disabled={isLoading || disableSubmit}
                loading={isLoading}
            >
                {submitText}
            </Button>
        </>
    )
}

import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import { MODAL_IDS } from 'util/constants'
import DeleteGiftCardModal from './DeleteGiftCardModal'

function DeleteGiftCardModalWithRedux() {
    return (
        <WithActiveModal modalId={MODAL_IDS.DELETE_GIFT_CARD}>
            {({
                isOpen,
                entity,
                closeModal,
            }) => (
                <DeleteGiftCardModal
                    isOpen={isOpen}
                    onClose={closeModal}
                    entity={entity}
                />
            )}
        </WithActiveModal>
    )
}
export default DeleteGiftCardModalWithRedux

import React, { useEffect } from 'react'
import { Select } from 'antd'
import useVenues from '../../../../hooks/useVenues'

const { Option } = Select

function DefaultVenueSelectWithState({
    selectedVenues,
    value,
    placeholder = 'Please select',
    onChange,
}) {
    const { venues, isFetching } = useVenues({ paginationKey: 'overview' })

    let parsedPlaceholder = placeholder
    if (isFetching) {
        parsedPlaceholder = 'Loading venues...'
    }
    if (selectedVenues.length === 0) {
        parsedPlaceholder = 'Please assign venues'
    }

    useEffect(() => {
        // Reset value when selectedVenue is removed
        if (!selectedVenues.includes(value)) {
            onChange(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVenues])

    return (
        <Select
            disabled={isFetching || selectedVenues.length === 0}
            onChange={onChange}
            value={isFetching
                ? 'Loading venues...'
                : value}
            placeholder={parsedPlaceholder}
            allowClear
        >
            {venues.filter(({ id }) => selectedVenues.includes(id))
                .map((venue) => (
                    <Option
                        key={venue.id}
                        value={venue.id}
                    >
                        {venue.name}
                    </Option>
                ))}
        </Select>
    )
}

export default DefaultVenueSelectWithState

import React, { useState, useEffect } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Upload, Button, message } from 'antd'
import useUploadProps from 'components/hooks/useUploadProps'

function GameImage({
    allowedToRemovePreview = true,
    mediaItem,
    multiple = false,
    onChangeAsValue,
    isUploading,
}) {
    const [fileList, setFileList] = useState()
    const uploadProps = useUploadProps()

    useEffect(() => {
        if (typeof mediaItem !== 'undefined' && mediaItem !== null) {
            setFileList([{
                uid: mediaItem.id,
                name: mediaItem.fileName,
                status: 'done',
                url: mediaItem.urls && mediaItem.urls.medium,
            }])
        } else {
            setFileList([])
        }
    }, [mediaItem])

    if (uploadProps === null) {
        return null
    }

    return (
        <Upload
            listType="picture"
            multiple={multiple}
            onChange={(info) => {
                const { file } = info
                let { fileList: updatedFileList } = info
                updatedFileList = updatedFileList.slice(-1)
                isUploading(file.status === 'uploading')
                if (file.status === 'done') {
                    onChangeAsValue('mediaItemId', file.response.data.id)
                    onChangeAsValue('mediaItem', { id: file.response.data.id })
                    message.success(`${file.name} file uploaded successfully`)
                } else if (file.status === 'error') {
                    message.error(`${file.name} file upload failed.`)
                } else if (file.status === 'removed') {
                    onChangeAsValue('mediaItemId', null)
                    onChangeAsValue('mediaItem', null)
                }
                setFileList(updatedFileList)
            }}
            fileList={fileList}
            showUploadList={{
                showRemoveIcon: allowedToRemovePreview,
            }}
            {...uploadProps}
        >
            <Button>
                <UploadOutlined />
                {' '}
                Click to upload
            </Button>
        </Upload>
    )
}

export default GameImage

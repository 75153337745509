import React from 'react'
import { Form, Radio } from 'antd'
import FormItem from 'components/util/forms/FormItem'
import Rooms from 'components/pages/settings/venue/Rooms'
import ExtraInfoLabel from 'components/util/forms/ExtraInfoLabel'

const RadioButton = Radio.Button
const RadioGroup = Radio.Group

// Values = GameSession object
function ToggleActiveGameSessionForm({
    values,
    onChangeAsValue,
}) {
    return (
        <Form>
            <p>
                Where do you want this game session to be activated?
                {' '}
                <br />
                <i>The booked room is selected by default.</i>
            </p>
            <FormItem
                wrapperCol={{ lg: 24 }}
            >
                <Rooms paginationKey="select" limit={15}>
                    {({ rooms }) => (
                        <RadioGroup
                            name="activeInRoomId"
                            value={values.activeInRoomId}
                            onChange={(e) => onChangeAsValue('activeInRoomId', e.target.value)}
                            style={{ width: '100%' }}
                        >
                            {
                                rooms.map(({ id, name }) => (
                                    <RadioButton
                                        key={id}
                                        value={id}
                                        style={{ width: '50%', textAlign: 'center' }}
                                    >
                                        {name}
                                    </RadioButton>
                                ))
                            }
                        </RadioGroup>
                    )}
                </Rooms>
            </FormItem>
            <ExtraInfoLabel
                message="You will be redirected to the game session overview."
            />
        </Form>
    )
}

export default ToggleActiveGameSessionForm

/* eslint-disable radix */
import React from 'react'
import { useAsyncEffect } from '@wappla/react-hooks'
import AppLoading from 'components/app/AppLoading'
import { getEntityFromResponse } from 'util/index'
import { useSearchParams } from 'react-router-dom'
import useCurrentUser from '../../hooks/useCurrentUser'
import useRoles from '../../hooks/useRoles'
import { useActiveFilters } from '../../hooks/useActiveFilters'

/**
 * @param {object[]} userLegalEntities
 * @param {int} venueId
 * @returns {object|null} Legal Entity
 */
function getFirstUserLegalEntityWithVenueId(userLegalEntities, venueId) {
    // Try find legal entity with venue id
    for (let i = 0, length = userLegalEntities.length; i < length; i++) {
        const legalEntity = userLegalEntities[i]

        // eslint-disable-next-line no-loop-func
        const venue = legalEntity.venues.find(v => v.id === venueId)
        if (typeof venue !== 'undefined') {
            return legalEntity
        }
    }

    return null
}

/**
 * @param {object[]} userLegalEntities
 * @param {int[]} userVenueIds
 * @returns {{legalEntityId: number | null , venueId: number | null }}
 */
function getFirstUserLegalEntityAndVenuePair(userLegalEntities, userVenueIds) {
    let foundLegalEntityId = null
    let foundVenueId = null
    for (let i = 0, leLength = userLegalEntities.length; i < leLength; i++) {
        const userLegalEntity = userLegalEntities[i]

        for (let j = 0, venuesLength = userVenueIds.length; j < venuesLength; j++) {
            const userVenueId = userVenueIds[j]
            const userVenue = userLegalEntity.venues.find(v => v.id === userVenueId)
            if (typeof userVenue !== 'undefined') {
                foundLegalEntityId = userLegalEntity.id
                foundVenueId = userVenueId
                break
            }
        }

        if (typeof foundVenueId !== 'number') {
            // Means we found venue
            break
        }
    }

    return { legalEntityId: foundLegalEntityId, venueId: foundVenueId }
}

export default function Authorized({
    children,
}) {
    const paginatedRoles = useRoles('app')
    const currentUserData = useCurrentUser()
    const { handleSetActiveVenue, handleSetActiveLegalEntity } = useActiveFilters()
    const [searchParams, setSearchParams] = useSearchParams()
    const activeFilters = useActiveFilters()

    useAsyncEffect(async () => {
        // Meaning, we have already fetched the user data
        if (currentUserData.hasFetchedUser)
            return

        await paginatedRoles.handleFetchRoles()
        const meAction = await currentUserData.handleFetchMe()
        // console.log('meAction:', meAction)
        const user = getEntityFromResponse(meAction.response, 'users')
        // console.log('user:', user)

        const venueIdParam = searchParams.get('venueId')
        const parsedVenueIdParam = parseInt(venueIdParam)
        let finalVenueId = Number.isNaN(parsedVenueIdParam) ? -1 : parsedVenueIdParam
        let defaultVenueId = null
        if (typeof user.defaultVenue !== 'undefined' && user.defaultVenue !== null) {
            defaultVenueId = user.defaultVenue.id
        }
        if (finalVenueId === -1 && defaultVenueId !== null) {
            finalVenueId = user.defaultVenue.id
        }

        // Debugging
        // console.log('VenueId group:', { finalVenueId, parsedVenueIdParam, defaultVenueId: user.defaultVenue.id })

        if (!Array.isArray(user.legalEntities))
            throw new Error('Legal entities is not an array!')
        if (user.legalEntities.length < 1)
            throw new Error('Legal entities array is empty!')

        // Typed this, because wtf...
        /** @type{number[]} userVenueIds */
        const userVenueIds = user.venues

        let finalLegalEntityId = null
        if (finalVenueId === -1) {
            // Get the first legal entity <> venue pair the user has
            const result = getFirstUserLegalEntityAndVenuePair(
                user.legalEntities,
                userVenueIds,
            )

            if (result.legalEntityId === null || result.venueId === null) {
                throw new Error(`Could not find venue id or legal entity id for user: ${JSON.stringify(user)}`)
            }

            finalLegalEntityId = result.legalEntityId
            finalVenueId = result.venueId
        } else {
            // Try find legal entity with venue id
            let foundLegalEntity = getFirstUserLegalEntityWithVenueId(
                user.legalEntities,
                finalVenueId,
            )

            // Try again with default venue if present
            if (foundLegalEntity === null && typeof defaultVenueId === 'number') {
                finalVenueId = defaultVenueId
                foundLegalEntity = getFirstUserLegalEntityWithVenueId(
                    user.legalEntities,
                    defaultVenueId,
                )
            }

            if (foundLegalEntity !== null) {
                finalLegalEntityId = foundLegalEntity.id
            } else {
                // If not found -> get the first legal entity <> venue pair the user has
                const result = getFirstUserLegalEntityAndVenuePair(
                    user.legalEntities,
                    userVenueIds,
                )
                if (result.legalEntityId === null || result.venueId === null) {
                    throw new Error(`Could not find venue id or legal entity id for user: ${JSON.stringify(user)}`)
                }
                finalLegalEntityId = result.legalEntityId
                finalVenueId = result.venueId
            }
        }

        // Debugging
        // console.log('Final data:', { finalVenueId, finalLegalEntityId })

        await handleSetActiveVenue(finalVenueId)
        await handleSetActiveLegalEntity(finalLegalEntityId)
    }, [])

    // Wait until initial data is fetched and/or set
    // console.log('Active filters:', activeFilters)
    if (!currentUserData.hasFetchedUser ||
        typeof activeFilters.venueId !== 'number' ||
        typeof activeFilters.legalEntityId !== 'number'
    ) {
        return <AppLoading />
    }

    return children
}

import React from 'react'
import {
    Form, Radio, TimePicker, Row, Col,
} from 'antd'
import FormItem from 'components/util/forms/FormItem'
import { PriceInput } from 'components/util/forms/PriceInput'
import { PLAYER_AND_TOTAL, APP_TIME_FORMAT } from 'util/constants'
import WeekdaySelect from '../WeekdaySelect'
import dayjs from 'dayjs'

function EditPriceAdjustmentForm({
    values,
    errors,
    mergeValues,
    onChangeAsValue,
    priceSystem,
}) {
    return (
        <Form>
            <FormItem
                label="Day of the week"
                error={errors.day}
                required
            >
                <WeekdaySelect
                    value={values.day}
                    onChange={(day) => onChangeAsValue('day', day)}
                />
            </FormItem>
            <FormItem
                label="Player price"
                error={errors.playerPrice}
                required
            >
                <PriceInput
                    name="playerPrice"
                    value={values.playerPrice}
                    onChange={(value) => onChangeAsValue('playerPrice', value)}
                />
            </FormItem>
            {priceSystem === PLAYER_AND_TOTAL && (
                <FormItem
                    label="Total price"
                    error={errors.totalPrice}
                    required
                >
                    <PriceInput
                        name="totalPrice"
                        value={values.totalPrice}
                        onChange={(value) => onChangeAsValue('totalPrice', value)}
                    />
                </FormItem>
            )}
            <FormItem
                label="Type"
                error={errors.adjustmentType}
            >
                <Radio.Group
                    name="adjustmentType"
                    value={values.adjustmentType}
                    onChange={(e) => {
                        mergeValues({
                            adjustmentType: e.target.value,
                            from: null,
                            until: null,
                        })
                    }}
                >
                    <Radio.Button value="wholeDay">
                        Whole day
                    </Radio.Button>
                    <Radio.Button value="timeFrame">
                        Timeframe
                    </Radio.Button>
                </Radio.Group>
            </FormItem>
            {values.adjustmentType === 'timeFrame'
      && (
          <Row gutter={8}>
              <Col xs={{ span: 12 }} sm={{ span: 8, offset: 8 }}>
                  <FormItem
                      label="From"
                      error={errors.from}
                      required
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                  >
                      <TimePicker
                          style={{ width: '100%' }}
                          value={values.from}
                          allowClear={false}
                          minuteStep={15}
                          hideDisabledOptions
                          format={APP_TIME_FORMAT}
                          onSelect={(from) => onChangeAsValue('from', dayjs.utc(from))}
                      />
                  </FormItem>
              </Col>
              <Col xs={12} sm={8}>
                  <FormItem
                      label="Until"
                      error={errors.until}
                      required
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                  >
                      <TimePicker
                          style={{ width: '100%' }}
                          value={values.until}
                          placeholder="Select time"
                          allowClear={false}
                          minuteStep={15}
                          hideDisabledOptions
                          format={APP_TIME_FORMAT}
                          onSelect={(until) => onChangeAsValue('until', dayjs.utc(until))}
                      />
                  </FormItem>
              </Col>
          </Row>
      )}
        </Form>
    )
}

export default EditPriceAdjustmentForm

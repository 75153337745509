import React from 'react'
import {
    Form, InputNumber, Input, Row, Col, Switch, Select,
} from 'antd'
import FormItem from './GameFormItem'
import GameImage from './GameImage'

const { Option } = Select

// https://ant.design/components/grid#row
const gutter = 12

function GameForm({
    values,
    errors,
    onChange,
    onChangeAsValue,
    isUploadingImage,
    isCreateForm = false,
}) {
    return (
        <Form layout="vertical">
            <Row gutter={gutter} className="pb-12">
                <Col span={12}>
                    <FormItem label="Bookable in tickets app">
                        <div style={{ height: 30, display: 'flex', alignItems: 'center' }}>
                            <Switch
                                size="small"
                                onChange={(value) => onChangeAsValue('public', value)}
                                checked={values.public}
                            />
                        </div>
                    </FormItem>
                </Col>
                <Col span={gutter}>
                    <FormItem label="Video url" error={errors.videoUrl}>
                        <Input
                            name="videoUrl"
                            value={values.videoUrl}
                            onChange={onChange}
                            placeholder="Enter video url"
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={gutter} className="pb-12">
                <Col span={12}>
                    <FormItem label="Min. number of players" error={errors.minPlayers} isRequired>
                        <InputNumber
                            name="minPlayers"
                            value={values.minPlayers}
                            onChange={(value) => onChangeAsValue('minPlayers', value)}
                            min={2}
                            parser={(value) => value.replace(',', '.')}
                            className="full-width"
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem label="Max. number of players" error={errors.minPlayers} isRequired>
                        <InputNumber
                            name="maxPlayers"
                            value={values.maxPlayers}
                            onChange={(value) => onChangeAsValue('maxPlayers', value)}
                            min={2}
                            parser={(value) => value.replace(',', '.')}
                            className="full-width"
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={gutter} className="pb-12">
                <Col span={12}>
                    <FormItem label="Supports teams">
                        <div className="switch">
                            <Switch
                                size="small"
                                onChange={(value) => {
                                    if (!value)
                                        onChangeAsValue('numberOfTeams', null)
                                    onChangeAsValue('enableTeams', value)
                                }}
                                checked={values.enableTeams}
                            />
                        </div>
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        label="Number of teams per room"
                        error={errors.numberOfTeams}
                        isRequired={values.enableTeams}
                        disabled={!values.enableTeams}
                    >
                        <InputNumber
                            name="numberOfTeams"
                            value={values.numberOfTeams}
                            onChange={(value) => onChangeAsValue('numberOfTeams', value)}
                            max={2}
                            defaultValue={null}
                            parser={(value) => (value === null ? '' : value.replace(',', '.'))}
                            className="full-width"
                            disabled={!values.enableTeams}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={gutter} className="pb-12">
                <Col span={12}>
                    <FormItem label="Game duration (minutes)" error={errors.duration} isRequired>
                        <InputNumber
                            name="duration"
                            value={values.duration}
                            onChange={(value) => onChangeAsValue('duration', value)}
                            min={0}
                            formatter={(value) => `${value}`}
                            parser={(value) => value.replace(',', '.')}
                            className="full-width"
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem label="Room amount" error={errors.requiredRoomAmount} isRequired>
                        <Select
                            value={values.requiredRoomAmount}
                            defaultValue="1"
                            onChange={(value) => onChangeAsValue('requiredRoomAmount', value)}
                            className="full-width"
                        >
                            <Option value="1">1</Option>
                            <Option value="2">2</Option>
                        </Select>
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={gutter} className="pb-12">
                <Col span={12}>
                    <FormItem
                        label="Game code"
                        error={errors.code}
                        isRequired
                    >
                        {isCreateForm ? (
                            <Input
                                name="code"
                                value={values.code}
                                onChange={onChange}
                                placeholder="(alphanumeric, uppercase, numbers at the end)"
                            />
                        ) : (
                            <p placeholder="Code of the game"> {values.code} </p>
                        )}

                    </FormItem>

                </Col>
                <Col span={12}>
                    <FormItem
                        label="Minimum required age"
                        error={errors.minAge}
                        isRequired
                    >
                        <InputNumber
                            name="min_age"
                            value={values.minAge}
                            onChange={(value) => onChangeAsValue('minAge', value)}
                            defaultValue={12}
                            min={1}
                        />
                    </FormItem>

                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <FormItem
                        label="Game image"
                        error={isCreateForm ? errors.mediaItemId : errors.mediaItem}
                        isRequired
                    >
                        {isCreateForm ? (
                            <GameImage onChangeAsValue={onChangeAsValue} isUploading={isUploadingImage} />
                        ) : (
                            <GameImage
                                onChangeAsValue={onChangeAsValue}
                                mediaItem={values.mediaItem}
                                allowedToRemovePreview={false}
                                isUploading={isUploadingImage}
                            />
                        )}
                    </FormItem>
                </Col>
            </Row>
        </Form>
    )
}

export default GameForm

/* eslint-disable no-tabs */
import React from 'react'
import { Affix } from 'antd'
import './SideBarContainer.css'

function SidebarContainer({
    sidebar,
    children,
}) {
    return (
        <div className="container">
            <Affix offsetTop={10} style={{ zIndex: 1 }}>
                <div className="sidebar">
                    <div className="sidebarContainer">
                        {sidebar}
                    </div>
                </div>
            </Affix>
            <div className="content">
                {children}
            </div>
        </div>
    )
}

export default SidebarContainer

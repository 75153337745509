import { Component } from 'react'

class WithState extends Component {
    constructor(props) {
        super(props)
        this.state = this.props.initialState || {}
        this.publicSetState = (...args) => this.setState(...args)
    }

    render() {
        const { children } = this.props
        return typeof children === 'function'
            ? children({
                state: this.state,
                setState: this.publicSetState,
            })
            : children
    }
}

export default WithState

export const SET_ACTIVE_VENUE = 'SET_ACTIVE_VENUE'
export const SET_ACTIVE_ROOM = 'SET_ACTIVE_ROOM'
export const SET_ACTIVE_LEGAL_ENTITY = 'SET_ACTIVE_LEGAL_ENTITY'

export const setActiveVenue = (venueId) => ({
    type: SET_ACTIVE_VENUE,
    venueId,
})

export const setActiveRoom = (roomId) => ({
    type: SET_ACTIVE_ROOM,
    roomId,
})

export const setActiveLegalEntity = (legalEntityId) => ({
    type: SET_ACTIVE_LEGAL_ENTITY,
    legalEntityId,
})

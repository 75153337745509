import React from 'react'
import './BookingDetailTitle.css'

/**
 * @component
 * @param {Object} props
 * @param {string} props.emoji
 * @param {string} props.text
 * @returns {Element}
 */
export function BookingDetailTitle(props) {
    return (
        <h3>
            <span
                className="titleImage"
                role="img"
                aria-label={props.text}
            >
                {props.emoji}
            </span>
            {props.text}
        </h3>
    )
}

import React from 'react'
import { Form } from 'antd'

const SIZE = 24
const FIRST_COLUMN_SIZE = 8

function CouponFormItem({
    isRequired,
    label,
    error,
    extra = null,
    children,
}) {
    return (
        <Form.Item
            labelCol={{
                xs: { span: SIZE },
                sm: { span: FIRST_COLUMN_SIZE },
            }}
            wrapperCol={{
                xs: { span: SIZE },
                sm: { span: SIZE - FIRST_COLUMN_SIZE, offset: label ? 0 : FIRST_COLUMN_SIZE },
            }}
            required={isRequired}
            label={label}
            hasFeedback
            validateStatus={error ? 'error' : ''}
            extra={extra}
            help={error}
        >
            {children}
        </Form.Item>
    )
}

export default CouponFormItem

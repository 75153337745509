import withReduxState from 'components/hoc/withReduxState'
import { fetchMe } from 'store/actions/me'
import { ROLES } from 'util/constants'
import { whereId } from 'util/index'
import { getEntitiesAsArray } from 'store/util'
import { scopeSentryUser } from 'util/sentry'

function mapStateToProps(state) {
    const { me } = state
    let role = {}
    let venues = []
    const isFetching = me === null
    if (!isFetching) {
        [role] = getEntitiesAsArray(state, 'roles').filter(whereId(me.role))
        venues = getEntitiesAsArray(state, 'venues').filter(({ id }) => me.venues.includes(id))
    }
    if (me !== null) {
        scopeSentryUser(me)
    }

    return ({
        ...(me || {}),
        isFetching,
        isAdmin: isFetching ? false : role.slug === ROLES.ADMIN,
        isLegalEntityManager: isFetching ? false : role.slug === ROLES.LEGAL_ENTITY_MANAGER,
        isHost: isFetching ? false : role.slug === ROLES.HOST,
        isVenueManager: isFetching ? false : role.slug === ROLES.VENUE_MANAGER,
        isReadOnly: isFetching ? false : role.slug === ROLES.READ_ONLY,
        isFranchise: isFetching ? false : role.slug === ROLES.FRANCHISE,
        role,
        venues,
    })
}

function mapDispatchToProps(dispatch) {
    return {
        fetchMe: () => (
            dispatch(fetchMe())
        ),
    }
}

const WithMe = withReduxState(mapStateToProps, mapDispatchToProps)

export default WithMe

import React, { useState } from 'react'
import RoomsTabs from './RoomsTabs'

function positionIsAlreadySet(oldSet, newSet) {
    return oldSet.positionNumber === newSet.positionNumber && oldSet.roomId === newSet.roomId
}
function assignNewSet(assignedSets, newSet) {
    return [...assignedSets.filter((aSet) => !positionIsAlreadySet(aSet, newSet)), newSet]
}
function releaseSet(assignedSets, newSet) {
    return [...assignedSets.filter((aSet) => !positionIsAlreadySet(aSet, newSet))]
}

function RoomsTabsWithState({
    activeTabKey,
    rooms,
    games,
    equipmentSets,
    onChangeTab,
}) {
    const [assignedSets, setAssignedSets] = useState(equipmentSets.filter(({ positionNumber }) => positionNumber !== null))
    const [sets] = useState(equipmentSets)
    return (
        <RoomsTabs
            activeTabKey={activeTabKey}
            rooms={rooms}
            games={games}
            equipmentSets={sets}
            assignedSets={assignedSets}
            onChangeEquipmentSet={(newSet) => {
                setAssignedSets(newSet.id === null
                    ? releaseSet(assignedSets, newSet)
                    : assignNewSet(assignedSets, newSet))
            }}
            onChangeTab={onChangeTab}
        />
    )
}
export default RoomsTabsWithState

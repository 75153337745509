import dotProp from 'dot-prop-immutable'
import { METHODS } from '../../middleware/api'

export const defaultEntitiesState = {}

export const entityRequestReducer = (state, action) => {
    const { method, entityId } = action
    if (method === METHODS.GET) {
        return dotProp.set(state, `${entityId}.isFetching`, true)
    }
    if (method === METHODS.PUT) {
        return dotProp.set(state, `${entityId}.isFetching`, true)
    }
    if (method === METHODS.DELETE) {
        return dotProp.set(state, `${entityId}.isFetching`, true)
    }
    return state
}

export const entitySuccessReducer = (state, action) => {
    const { method, entityId } = action
    if (method === METHODS.GET) {
        return dotProp.set(state, `${entityId}.isFetching`, false)
    }
    if (method === METHODS.PUT) {
        return dotProp.set(state, `${entityId}.isFetching`, false)
    }
    if (method === METHODS.DELETE) {
        return dotProp.delete(state, entityId)
    }
    return state
}

export const entityFailureReducer = (state, action) => {
    const { method, entityId } = action
    if (method === METHODS.GET) {
        return dotProp.set(state, `${entityId}.isFetching`, false)
    }
    if (method === METHODS.PUT) {
        return dotProp.set(state, `${entityId}.isFetching`, false)
    }
    if (method === METHODS.DELETE) {
        return dotProp.set(state, `${entityId}.isFetching`, false)
    }
    return state
}

const createEntityReducer = ({ types }) => {
    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error('Expected types to be an array of three elements.')
    }
    if (!types.every((t) => typeof t === 'string')) {
        throw new Error('Expected types to be strings.')
    }

    const [requestType, successType, failureType] = types
    // eslint-disable-next-line default-param-last
    return (state = defaultEntitiesState, action) => {
        switch (action.type) {
            case requestType: {
                return entityRequestReducer(state, action)
            }
            case successType: {
                return entitySuccessReducer(state, action)
            }
            case failureType: {
                return entityFailureReducer(state, action)
            }
            default: {
                return state
            }
        }
    }
}

export default createEntityReducer

import React, { useMemo } from "react"
import {
    Form,
    Col,
    Row,
    Input,
    Select,
    Switch,
    InputNumber,
    // Text,
} from "antd"
import Image from "components/pages/settings/games/forms/GameImage"
import FormItem from "components/pages/settings/games/forms/GameFormItem"
import InputMask from "components/util/forms/InputMask"
import { capitalize } from "util/index"
import { LANGUAGES_OBJECTS_ARRAY } from "util/constants"
import { getCurrencyDisplayName } from "util/currency"
import {capitalizeFirstChar, isValidString} from "../../../../util/string-utils"
import {AppTabs} from "../../../util/AppTabs"

const { Option } = Select

/**
 * @component
 * @param {Object} props
 * @param {(isUploadingImage: boolean) => void} props.setIsUploadingImage
 * @param {boolean} props.isCreateForm
 * @param {CountryResource[]} props.countries
 * @param {UseFormFormState & UseDynamicLanguageFormStateForm} props.formState
 * @param {(locale: string) => boolean} props.hasLocaleError
 */
export function LegalEntityForm(props) {
    const formState = props.formState
    /** @type {{code: string, name: string}[]} */
    const countryOptions = useMemo(() => {
        if (!Array.isArray(props.countries))
            return []

        const countriesLength = props.countries.length
        /** @type {{code: string, name: string}[]} */
        const opts = new Array(countriesLength).fill(null)
        for (let i = 0; i < countriesLength; i++) {
            const country = props.countries[i]
            opts[i] = {
                code: country.code,
                name: country.name,
            }
        }
        return opts
    }, [props.countries])

    const selectedCountry = useMemo(() => {
        const currentCountryCode = formState.values.countryCode
        // console.log("Current country code:", currentCountryCode)
        if (!isValidString(currentCountryCode)) {
            // return undefined if no elements were found.
            return undefined
        }

        return props.countries.find(country => country.code === currentCountryCode)
    }, [formState.values.countryCode, props.countries])

    const currencyDisplayName = useMemo(() => {
        // console.log("Selected country:", selectedCountry)
        if (typeof selectedCountry === "undefined") {
            return "Please select country"
        }

        // return selectedCountry.currencyCode
        return getCurrencyDisplayName(selectedCountry.currencyCode)
    }, [selectedCountry])

    return (
        <Form layout="vertical">
            <AppTabs
                hasErrors={props.hasLocaleError}
                tabs={formState.values.supportedLanguages}
            >
                {({ tab }) => {
                    // "tab" is the language code in this case
                    const key = capitalizeFirstChar(tab.toLowerCase())
                    return (
                        <>
                            <FormItem
                                label="Terms and conditions url"
                                isRequired
                                error={formState.getErrorMessages("termsAndConditions" + key)[0]}
                            >
                                <Input
                                    name={"termsAndConditions" + key}
                                    value={formState.values["termsAndConditions" + key]}
                                    placeholder="Terms and conditions url"
                                    onChange={formState.handleNativeChange}
                                />
                            </FormItem>
                            <FormItem
                                label="Privacy policy url"
                                isRequired
                                error={formState.getErrorMessages("privacyPolicy" + key)[0]}
                            >
                                <Input
                                    name={"privacyPolicy" + key}
                                    value={formState.values["privacyPolicy" + key]}
                                    placeholder="Privacy policy url"
                                    onChange={formState.handleNativeChange}
                                />
                            </FormItem>
                        </>
                    )
                }}
            </AppTabs>

            <Col span={24}>
                <FormItem
                    label="Name"
                    isRequired
                    error={formState.getErrorMessages("name")[0]}
                >
                    <Input
                        name="name"
                        value={formState.values.name}
                        placeholder="Legal entity name"
                        onChange={formState.handleNativeChange}
                    />
                </FormItem>
            </Col>

            <Col span={24}>
                <FormItem
                    label="Address"
                    isRequired
                    error={formState.getErrorMessages("address")[0]}
                >
                    <Input
                        name="address"
                        value={formState.values.address}
                        placeholder="Legal entity address"
                        onChange={formState.handleNativeChange}
                    />
                </FormItem>
            </Col>

            <Row gutter={16}>
                <Col span={12}>
                    <FormItem label="Logo">
                        {
                            props.isCreateForm && typeof formState.values.mediaItem !== "undefined" ? (
                                <Image
                                    onChangeAsValue={formState.handleChange}
                                    isUploading={props.setIsUploadingImage}
                                />
                            ) : (
                                <Image
                                    onChangeAsValue={formState.handleChange}
                                    mediaItem={formState.values.mediaItem}
                                    isUploading={formState.setIsUploadingImage}
                                    allowedToRemovePreview={false}
                                />
                            )
                        }
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        isRequired
                        label="Available on tickets app"
                        error={formState.getErrorMessages("public")[0]}
                    >
                        <Switch
                            onChange={(value) => formState.handleChange("public", value)}
                            checked={formState.values.public}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <FormItem
                        label="Currency"
                    >
                        <span>{currencyDisplayName}</span>
                        {/*<Input*/}
                        {/*    name="currencyCode"*/}
                        {/*    value={currencyDisplayName}*/}
                        {/*    placeholder="Select a country"*/}
                        {/*    disabled*/}
                        {/*/>*/}
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        label="Country"
                        error={formState.getErrorMessages("countryCode")[0]}
                        isRequired
                    >
                        <Select
                            showSearch
                            placeholder="Please select country"
                            onChange={(value) => formState.handleChange("countryCode", value)}
                            value={formState.values.countryCode}
                            filterOption={(input, option) => (
                                option.props.children.toLowerCase().includes(input.toLowerCase())
                            )}
                        >
                            {
                                countryOptions.map((countryOption) => (
                                    <Option
                                        key={countryOption.code}
                                        value={countryOption.code}
                                    >
                                        {countryOption.name}
                                    </Option>
                                ))
                            }
                        </Select>
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <FormItem
                        label="Supported languages"
                        error={formState.getErrorMessages("supportedLanguages")[0]}
                        isRequired
                    >
                        <Select
                            placeholder="Please select supported languages"
                            mode="multiple"
                            value={formState.values.supportedLanguages}
                            onChange={(value) => {
                                formState.handleChange("supportedLanguages", value)
                            }}
                        >
                            {LANGUAGES_OBJECTS_ARRAY.map(({ code, name }) => (
                                <Option
                                    key={code}
                                    value={code}
                                >
                                    {name}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <FormItem
                        label="BIC"
                        isRequired
                        error={formState.getErrorMessages("bic")[0]}
                    >
                        <Input
                            name="bic"
                            value={formState.values.bic}
                            placeholder="bic"
                            onChange={formState.handleNativeChange}
                        />
                    </FormItem>
                    <FormItem
                        label="RPR"
                    >
                        <Input
                            name="rpr"
                            value={formState.values.rpr}
                            placeholder="rpr"
                            onChange={formState.handleNativeChange}
                        />
                    </FormItem>
                    <FormItem
                        label="Bank account number"
                        isRequired
                        error={formState.getErrorMessages("accountNumber")[0]}
                    >
                        <Input
                            name="accountNumber"
                            value={formState.values.accountNumber}
                            placeholder="Account number"
                            onChange={formState.handleNativeChange}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <FormItem
                        label="VAT number"
                        error={formState.getErrorMessages("vatNumber")[0]}
                        isRequired
                    >
                        <Input
                            name="vatNumber"
                            value={formState.values.vatNumber}
                            placeholder="VAT number"
                            onChange={formState.handleNativeChange}
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        label="VR Experience VAT percentage"
                        isRequired
                        error={formState.getErrorMessages("vatPercentage")[0]}
                    >
                        <InputNumber
                            value={formState.values.vatPercentage}
                            defaultValue={0}
                            min={0}
                            max={100}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value.replace("%", "")}
                            onChange={(value) => formState.handleChange("vatPercentage", value)}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <FormItem
                        label="2 Drinks VAT percentage"
                        isRequired
                        error={formState.getErrorMessages("drinksVatPercentage")[0]}
                    >
                        <InputNumber
                            value={formState.values.drinksVatPercentage}
                            defaultValue={0}
                            min={0}
                            max={100}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value.replace("%", "")}
                            onChange={(value) => formState.handleChange("drinksVatPercentage", value)}
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        label="2 Drinks Unit Price (incl VAT)"
                        isRequired
                        error={formState.getErrorMessages("drinksUnitPriceInclVat")[0]}
                    >
                        <InputNumber
                            value={formState.values.drinksUnitPriceInclVat}
                            defaultValue={0}
                            min={0}
                            max={100}
                            onChange={(value) => formState.handleChange("drinksUnitPriceInclVat", value)}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <FormItem
                        label="1h Open Bar VAT percentage"
                        isRequired
                        error={formState.getErrorMessages("openBarVatPercentage")[0]}
                    >
                        <InputNumber
                            value={formState.values.openBarVatPercentage}
                            defaultValue={0}
                            min={0}
                            max={100}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value.replace("%", "")}
                            onChange={(value) => formState.handleChange("openBarVatPercentage", value)}
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        label="1h Open Bar Unit Price (incl VAT)"
                        isRequired
                        error={formState.getErrorMessages("openBarUnitPriceInclVat")[0]}
                    >
                        <InputNumber
                            value={formState.values.openBarUnitPriceInclVat}
                            defaultValue={0}
                            min={0}
                            max={100}
                            onChange={(value) => formState.handleChange("openBarUnitPriceInclVat", value)}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <FormItem
                        label="Nachos VAT percentage"
                        isRequired
                        error={formState.getErrorMessages("nachosVatPercentage")[0]}
                    >
                        <InputNumber
                            value={formState.values.nachosVatPercentage}
                            defaultValue={0}
                            min={0}
                            max={100}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value.replace("%", "")}
                            onChange={(value) => formState.handleChange("nachosVatPercentage", value)}
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        label="Nachos Unit Price  (incl VAT)"
                        isRequired
                        error={formState.getErrorMessages("nachosUnitPriceInclVat")[0]}
                    >
                        <InputNumber
                            value={formState.values.nachosUnitPriceInclVat}
                            defaultValue={0}
                            min={0}
                            max={100}
                            onChange={(value) => formState.handleChange("nachosUnitPriceInclVat", value)}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <FormItem
                        label="2 drink tokens VAT percentage"
                        isRequired
                        error={formState.getErrorMessages("drinkTokensVatPercentage")[0]}
                    >
                        <InputNumber
                            value={formState.values.drinkTokensVatPercentage}
                            defaultValue={0}
                            min={0}
                            max={100}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value.replace("%", "")}
                            onChange={(value) => formState.handleChange("drinkTokensVatPercentage", value)}
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        label="2 drink tokens Unit Price  (incl VAT)"
                        isRequired
                        error={formState.getErrorMessages("drinkTokensUnitPriceInclVat")[0]}
                    >
                        <InputNumber
                            value={formState.values.drinkTokensUnitPriceInclVat}
                            defaultValue={0}
                            min={0}
                            max={100}
                            onChange={(value) => formState.handleChange("drinkTokensUnitPriceInclVat", value)}
                        />
                    </FormItem>
                </Col>
            </Row>
        </Form>
    )
}

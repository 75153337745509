import React, { useState } from 'react'
import { Main } from  'components/layout/Main'
import { TopBar } from 'components/layout/TopBar'
import VenueSettingsContainer from 'components/layout/sidebar/VenueSettingsContainer'
import {
    Button, DatePicker, Tooltip, Spin,
} from 'antd'
import dayjs from 'dayjs'
import useFetchRoomsAndSlots from './useFetchRoomsAndSlots'
import { clickableStatusses, STATUS_AVAILABLE } from './TSConstants'
import UpdateSlotsModal from './UpdateSlotsModal'
import {
    selectPatternSlotsInRoom, selectAllSlotsInRoom, selectAllPatternSlots, selectAllSlots, hasSlotsOutsideBookableHours, slotHasPassed,
} from './TimeSlotsLogic'
// eslint-disable-next-line no-unused-vars
import * as RoomAndTimeSlotTypes from './TimeSlotTypes'
// eslint-disable-next-line no-unused-vars
import * as useLocalStateOfSlotsType from './useLocalStateOfSlots'
import { useLocalStateOfSlots } from './useLocalStateOfSlots'
import { APP_DATE_FORMAT } from '../../../../util/constants'

export const statusColor = {
    available: 'white',
    buffer: 'hsl(24.24 80% 60%)',
    booked: 'rgb(115, 251, 211)',
    event: 'hsl(349 80% 60%)',
    option: 'lightblue',
    auto_blocked: 'lightgrey',
    booking_buffer: 'lightgrey',
}

const todayDate = dayjs.utc().startOf('day')

function TimeSlotsPage() {
    const [activeDateValue, setActiveDateValue] = useState(dayjs.utc())
    const [isModalOpen, setIsModalOpen] = useState(false)

    const {
        errors,
        roomsAndTimeSlots,
        fetchStatus,
        postRoomsAndTimeSlots,
    } = useFetchRoomsAndSlots(activeDateValue.toISOString())

    const {
        updateSelectedStatusOfSlot,
        uiRoomsAndTimeSlots,
        getSelectedRoomsAndTimeSlots,
        getRoomsAndSlotsRequestFormat,
    } = useLocalStateOfSlots(roomsAndTimeSlots)

    const showModal = () => {
        setIsModalOpen(true)
    }

    // eslint-disable-next-line consistent-return
    const onSubmit = async (comment, status) => {
        const date = activeDateValue.format(APP_DATE_FORMAT)

        try {
            await postRoomsAndTimeSlots(
                getRoomsAndSlotsRequestFormat(date),
                activeDateValue,
                status,
                comment,
            )
        } catch (error) {
            return error
        }

        // TODO: ask Joeri if i should return something here or not
        setIsModalOpen(false)
    }

    const onCancel = () => {
        setIsModalOpen(false)
    }

    const onSelectDate = (date) => {
        const now = dayjs.utc()
        if (date !== null) {
            const startOfDate = date.startOf('day').toISOString()
            const startOfToday = todayDate.toISOString()

            if (startOfDate === startOfToday) {
                setActiveDateValue(now)
            } else {
                setActiveDateValue(date.startOf('day'))
            }
        } else {
            setActiveDateValue(now)
        }
    }

    return (
        <>
            <TopBar activeMenuItem="venueSettings" />
            <Main>
                <VenueSettingsContainer activeMenuItem="timeSlots">

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingBottom: '40px',
                            paddingLeft: '40px',
                            paddingRight: '40px',
                            gap: '40px',
                        }}
                    >

                        <DatePicker
                            style={{
                                width: '70%',
                            }}
                            value={activeDateValue}
                            onChange={onSelectDate}
                            format="DD-MM-YYYY"
                        />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '20px',
                                flexWrap: 'wrap',
                                border: '1px solid lightgrey',
                                padding: '20px 40px',
                                width: '70%',
                            }}
                        >
                            {Object.entries(statusColor).map(([status, color]) => (

                                <div
                                    key={status}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '5px',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '13px',
                                            height: '13px',
                                            backgroundColor: color,
                                            border: '1px solid lightgrey',
                                            borderRadius: '50%',
                                        }}
                                    />
                                    <div
                                        style={{
                                            textAlign: 'left',
                                        }}
                                    >
                                        {status.split('_').join(' ')}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {fetchStatus === 'fetching' ? (
                        <Spin style={{ marginTop: '40px' }} size="large" />
                    ) : (
                        <ViewContainer
                            uiRoomsAndTimeSlots={uiRoomsAndTimeSlots}
                            updateSelectedStatusOfSlot={updateSelectedStatusOfSlot}
                            showModal={showModal}
                            isModalOpen={isModalOpen}
                            onCancel={onCancel}
                            onSubmit={onSubmit}
                            activeDateValue={activeDateValue}
                            getSelectedRoomsAndTimeSlots={
                                getSelectedRoomsAndTimeSlots
                            }
                            errors={errors}
                        />
                    )}

                </VenueSettingsContainer>
            </Main>
        </>
    )
}

/**
 * @typedef {Object} ViewContainerProps
 * @property {RoomAndTimeSlotTypes.RoomAndTimeSlots[]} uiRoomsAndTimeSlots
 * @property {useLocalStateOfSlotsType.updateSelectedStatusOfSlot} updateSelectedStatusOfSlot
 * @property {() => void} showModal
 * @property {boolean} isModalOpen
 * @property {() => void} onCancel
 * @property {(comment: string, status: string) => void} onSubmit
 * @property {string} activeDateValue
 * @property {useLocalStateOfSlotsType.getSelectedRoomsAndTimeSlots} getSelectedRoomsAndTimeSlots
 * @property {Object} errors
*/

/**
 * @param {ViewContainerProps} props
 */
function ViewContainer(props) {
    const {
        uiRoomsAndTimeSlots,
        updateSelectedStatusOfSlot,
        showModal,
        isModalOpen,
        onCancel,
        onSubmit,
        activeDateValue,
        getSelectedRoomsAndTimeSlots,
        errors,
    } = props
    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: '1fr',
                gridTemplateRows: 'auto',
                gap: '40px',
            }}
        >
            <RoomsContainer
                /** @type {RoomAndTimeSlotTypes.RoomAndTimeSlots[]} */
                uiRoomsAndTimeSlots={uiRoomsAndTimeSlots}
                updateSelectedStatusOfSlot={updateSelectedStatusOfSlot}
                showModal={showModal}
                isModalOpen={isModalOpen}
                onCancel={onCancel}
                onSubmit={onSubmit}
                activeDateValue={activeDateValue}
                getSelectedRoomsAndTimeSlots={getSelectedRoomsAndTimeSlots}
                errors={errors}
            />
        </div>
    )
}

/**
 * @typedef {Object} RoomsContainerProps
 * @property {RoomAndTimeSlotTypes.RoomAndTimeSlots[]} uiRoomsAndTimeSlots
 * @property {useLocalStateOfSlotsType.updateSelectedStatusOfSlot} updateSelectedStatusOfSlot
 * @property {() => void} showModal
 * @property {boolean} isModalOpen
 * @property {() => void} onCancel
 * @property {(comment: string, status: string) => void} onSubmit
 * @property {string} activeDateValue
 * @property {useLocalStateOfSlotsType.getSelectedRoomsAndTimeSlots} getSelectedRoomsAndTimeSlots
 * @property {Object} errors
*/

/**
 * @param {RoomsContainerProps} props
 */
function RoomsContainer(props) {
    const {
        /** @type {RoomAndTimeSlotTypes.RoomAndTimeSlots[]} */
        uiRoomsAndTimeSlots,
        updateSelectedStatusOfSlot,
        showModal,
        isModalOpen,
        onCancel,
        onSubmit,
        activeDateValue,
        getSelectedRoomsAndTimeSlots,
        errors,
    } = props

    // button availability checks
    let allSlotsSelected = true
    let slotsPatternSelected = true
    let anySlotsAvailable = false
    let canSelectPattern = false
    let anySlotSelected = false
    let dateInThePast = false

    let selectedSlotsCount = 0

    // checks for button availability
    /** @type {RoomAndTimeSlotTypes.RoomAndTimeSlots[]} */
    for (let x = 0; x < uiRoomsAndTimeSlots.length; x += 1) {
        /** @type {RoomAndTimeSlotTypes.RoomAndTimeSlots} */
        const uiRoomAndTimeSlots = uiRoomsAndTimeSlots[x]
        const { bookableHours, date } = uiRoomAndTimeSlots

        // get date of slot in milliseconds
        const inputDateSelectedSlots = dayjs.utc(date)

        // check if slot has passed (dirty fix)
        dateInThePast = todayDate.diff(inputDateSelectedSlots) > 0

        for (let i = 0; i < uiRoomAndTimeSlots.uiSlots.length; i += 1) {
            // /** @type {RoomAndTimeSlotTypes.UiSlot} */
            const uiSlot = uiRoomAndTimeSlots.uiSlots[i]

            const slotPassed = slotHasPassed(uiSlot.slot, date)

            if (slotPassed === true) {
                continue
            }

            // Don't continue loop if all checks are false
            // if (!allSlotsSelected && !slotsPatternSelected) {
            //     break
            // }

            // check if any slots are selected
            if (uiSlot.selected === true) {
                anySlotSelected = true
                selectedSlotsCount += 1
            }

            // check if slot is outside of bookable hours
            if (
                uiSlot.slot.roomStart >= bookableHours.end
                || uiSlot.slot.roomStart < bookableHours.start
            ) {
                // If it is, ignore slot
                continue
            }

            // if slot not available, continue loop
            // if (uiSlot.slot.status !== STATUS_AVAILABLE) {
            //     continue
            // }

            if (uiSlot.slot.status === STATUS_AVAILABLE) {
                anySlotsAvailable = true

                // check if all slots are selected
                if (allSlotsSelected === true && uiSlot.selected === false) {
                    allSlotsSelected = false
                }

                // check if pattern slots are selected
                if ((i + 1) % 4 === 0) {
                    if (uiSlot.selected === false) {
                        slotsPatternSelected = false
                    }

                    canSelectPattern = true
                }
            }
        }
    }

    if (!anySlotsAvailable) {
        allSlotsSelected = false
    }

    if (!canSelectPattern) {
        slotsPatternSelected = false
    }

    const slotsOutsideBookableHours = hasSlotsOutsideBookableHours(getSelectedRoomsAndTimeSlots())

    return (
        <div>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gridTemplateRows: 'auto',
                    gap: '40px',
                }}
            >
                {uiRoomsAndTimeSlots.map((roomAndTimeSlots) => (
                    <RoomContainer
                        key={roomAndTimeSlots.roomId}
                        roomAndTimeSlots={roomAndTimeSlots}
                        updateSelectedStatusOfSlot={updateSelectedStatusOfSlot}
                    />
                ))}
            </div>
            <div
                style={{
                    position: 'sticky',
                    bottom: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid lightgrey',
                    background: 'white',
                    gap: '10px',
                    alignItems: 'center',
                    marginTop: '60px',
                    paddingBottom: '20px',
                    paddingTop: '20px',
                    width: '60%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <div
                    style={{
                        marginBottom: '10px',
                    }}
                >
                    Options for all rooms. Current selected slots: <b>{selectedSlotsCount}</b>
                </div>
                <div
                    style={{
                        display: 'flex',
                        gap: '15px',
                    }}
                >

                    {allSlotsSelected === true ? (
                        <Button
                            type="primary"
                            onClick={
                                () => selectAllSlots(
                                    uiRoomsAndTimeSlots,
                                    false,
                                    updateSelectedStatusOfSlot,
                                )
                            }
                            disabled={dateInThePast}
                        >
                            Unselect all
                        </Button>
                    ) : (
                        <Button
                            type="primary"
                            onClick={
                                () => selectAllSlots(
                                    uiRoomsAndTimeSlots,
                                    true,
                                    updateSelectedStatusOfSlot,
                                )
                            }
                            disabled={dateInThePast || anySlotsAvailable === false}
                        >
                            Select all
                        </Button>
                    )}

                    {slotsPatternSelected === true ? (
                        <Button
                            type="primary"
                            onClick={
                                () => selectAllPatternSlots(
                                    uiRoomsAndTimeSlots,
                                    false,
                                    updateSelectedStatusOfSlot,
                                )
                            }
                            disabled={dateInThePast}
                        >
                            Unselect pattern
                        </Button>
                    ) : (
                        <Button
                            type="primary"
                            onClick={
                                () => selectAllPatternSlots(
                                    uiRoomsAndTimeSlots,
                                    true,
                                    updateSelectedStatusOfSlot,
                                )
                            }
                            disabled={dateInThePast || canSelectPattern === false}
                        >
                            Select pattern
                        </Button>
                    )}

                    <Button type="primary" onClick={showModal} disabled={!anySlotSelected}>
                        Update slot(s)
                    </Button>

                </div>
            </div>
            <UpdateSlotsModal
                isModalOpen={isModalOpen}
                onSubmit={onSubmit}
                onCancel={onCancel}
                activeDateValue={activeDateValue}
                selectedRoomsAndTimeSlots={getSelectedRoomsAndTimeSlots()}
                hasSlotsOutsideBookableHours={slotsOutsideBookableHours}
                errors={errors}
            />
        </div>
    )
}

/**
 * @typedef {Object} RoomContainerProps
 * @property {RoomAndTimeSlotTypes.RoomAndTimeSlots} roomAndTimeSlots
 * @property {useLocalStateOfSlotsType.updateSelectedStatusOfSlot} updateSelectedStatusOfSlot
*/

/**
 * @param {RoomContainerProps} props
 */
function RoomContainer(props) {
    const { updateSelectedStatusOfSlot, roomAndTimeSlots } = props
    const {
        roomName, uiSlots, roomId, date, bookableHours,
    } = roomAndTimeSlots

    // button availability checks
    let allSlotsSelected = true
    let slotsPatternSelected = true
    let anySlotsAvailable = false
    let canSelectPattern = false

    for (let i = 0; i < uiSlots.length; i += 1) {
        const uiSlot = uiSlots[i]

        const slotPassed = slotHasPassed(uiSlot.slot, date)

        if (slotPassed === true) {
            continue
        }

        // check if slot is outside of bookable hours
        if (
            uiSlot.slot.roomStart >= bookableHours.end
            || uiSlot.slot.roomStart < bookableHours.start
        ) {
            // If it is, ignore slot
            continue
        }

        if (uiSlot.slot.status === STATUS_AVAILABLE) {
            anySlotsAvailable = true

            // check if all slots are selected
            if (allSlotsSelected === true && uiSlot.selected === false) {
                allSlotsSelected = false
            }

            // check if pattern slots are selected
            if ((i + 1) % 4 === 0) {
                if (uiSlot.selected === false) {
                    slotsPatternSelected = false
                }

                canSelectPattern = true
            }
        }
    }

    // if no slot is available, set allSlotsSelected to false
    if (!anySlotsAvailable) {
        allSlotsSelected = false
    }

    // if you can't select pattern, set slotsPatternSelected to false
    if (!canSelectPattern) {
        slotsPatternSelected = false
    }

    // get date of slot in milliseconds
    const inputDateSelectedSlots = dayjs.utc(date)
    // check if slot has passed
    const dateInThePast = todayDate.diff(inputDateSelectedSlots) > 0

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid lightgrey',
                gap: '10px',
                alignItems: 'center',
                paddingBottom: '20px',
                paddingTop: '20px',
            }}
        >
            <div
                style={{
                    marginBottom: '15px',
                    padding: '3px',
                }}
            >
                {roomName}
            </div>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                    gridTemplateRows: 'auto',
                    gap: '25px',
                    justifyItems: 'center',
                }}
            >
                {
                    uiSlots.map((uiSlot) => (
                        <TimeSlot
                            key={uiSlot.slot.roomStart}
                            timeSlot={uiSlot}
                            roomId={roomId}
                            bookableHours={bookableHours}
                            date={date}
                            updateSelectedStatusOfSlot={updateSelectedStatusOfSlot}
                        />
                    ))
                }
            </div>
            <div
                style={{
                    display: 'flex',
                    marginTop: '30px',
                    gap: '15px',
                    width: '100%',
                    justifyContent: 'end',
                    paddingRight: '15px',
                }}
            >
                {allSlotsSelected === true ? (
                    <Button
                        type="primary"
                        onClick={
                            () => selectAllSlotsInRoom(
                                roomAndTimeSlots,
                                false,
                                updateSelectedStatusOfSlot,
                            )
                        }
                        disabled={dateInThePast}
                    >
                        Unselect all
                    </Button>
                ) : (
                    <Button
                        type="primary"
                        onClick={
                            () => selectAllSlotsInRoom(
                                roomAndTimeSlots,
                                true,
                                updateSelectedStatusOfSlot,
                            )
                        }
                        disabled={dateInThePast || anySlotsAvailable === false}
                    >
                        Select all
                    </Button>
                )}

                {slotsPatternSelected === true ? (
                    <Button
                        type="primary"
                        onClick={
                            () => selectPatternSlotsInRoom(
                                roomAndTimeSlots,
                                false,
                                updateSelectedStatusOfSlot,
                            )
                        }
                        disabled={dateInThePast}
                    >
                        Unselect pattern
                    </Button>
                ) : (
                    <Button
                        type="primary"
                        onClick={
                            () => selectPatternSlotsInRoom(
                                roomAndTimeSlots,
                                true,
                                updateSelectedStatusOfSlot,
                            )
                        }
                        disabled={dateInThePast || canSelectPattern === false}
                    >
                        Select pattern
                    </Button>
                )}

            </div>
        </div>
    )
}

/**
 * @typedef {Object} TimeSlotProps
 * @property {RoomAndTimeSlotTypes.UiSlot} timeSlot
 * @property {useLocalStateOfSlotsType.updateSelectedStatusOfSlot} updateSelectedStatusOfSlot
 * @property {number} roomId
 * @property {string} date date string
 * @property {RoomAndTimeSlotTypes.BookableHours} bookableHours
*/

/**
 * @param {TimeSlotProps} props
 */
function TimeSlot(props) {
    const { slot, selected } = props.timeSlot
    const {
        roomId, date, bookableHours, updateSelectedStatusOfSlot,
    } = props

    const {
        roomStart, status, comment, createdAt, createdBy,
    } = slot

    // Create array with object that contains the roomId and the slot to update
    const slotToSelect = [{ roomId, date, uiSlots: [{ slot, selected }] }]

    // check if slot has passed
    const dateHasPassed = slotHasPassed(slot, date)

    // Update the selected status of the slot
    const setSelectStatusOfSlot = () => {
        // check if slot has passed & if slot has a clickable status
        if (!dateHasPassed && clickableStatusses.indexOf(status) !== -1) {
            if (selected === true) {
                updateSelectedStatusOfSlot(slotToSelect, false, false)
            } else {
                updateSelectedStatusOfSlot(slotToSelect, true, false)
            }
        }
    }

    // styling of slot
    const slotColor = selected ? 'rgb(35, 154, 152)' : statusColor[status]
    const slotBorderColor = selected ? 'rgb(35, 154, 152)' : 'rgb(232, 232, 232)'
    const slotTextColor = selected || status !== STATUS_AVAILABLE ? 'white' : 'black'

    const toolTipTitle = () => {
        let hasWarning = false

        const splittedComment = typeof comment === 'string' ? comment.split('|') : ['/']

        if (status === STATUS_AVAILABLE) {
            if (slot.roomStart >= bookableHours.end || slot.roomStart < bookableHours.start) {
                hasWarning = true
            }
        }

        // const title = (
        //     <>
        //         <p className={blockedAt === null ? 'noMargin' : ''}>Blocked: {finalComment}</p>
        //         {blockedAt !== null && (
        //             <span>
        //                 On <strong>{moment.parseZone(blockedAt).format(APP_DATE_FORMAT)}</strong>
        //             </span>
        //         )}
        //         {blockedBy !== null && (
        //             <>
        //                 <br />
        //                 <span>
        //                     By <strong>{blockedBy}</strong>
        //                 </span>
        //             </>
        //         )}
        //     </>
        // )

        return (
            <div>
                <div><strong>Status:</strong> {status}</div>

                {typeof comment !== 'undefined' && comment !== '' && (
                    <>
                        <br />
                        <div>{splittedComment.map((part) => {
                            const kvp = part.split(':')
                            if (kvp.length === 1)
                                return <div key={part}><strong>{part}</strong></div>

                            const key = kvp[0]
                            let value = kvp[1]
                            for (let i = 2, length = kvp.length; i < length; i++) {
                                // eslint-disable-next-line prefer-template
                                value += ':' + kvp[i]
                            }
                            return (
                                <div key={part}>
                                    <strong>{key}</strong>: {value}
                                </div>
                            )
                        })}
                        </div>
                    </>
                )}

                {hasWarning === true && (
                    <>
                        <br />
                        <div>WARNING: Slot is outside of bookable hours.</div>
                    </>
                )}

                {typeof createdBy !== 'undefined' && createdBy !== null && (
                    <>
                        <br />
                        <div>
                            <div><strong>Created by:</strong> {createdBy}</div>
                            <div><strong>On:</strong> {dayjs.utc(createdAt).format(APP_DATE_FORMAT)}</div>
                        </div>
                    </>
                )}

                {dateHasPassed === true && (
                    <>
                        <br />
                        <div>Slot has passed.</div>
                    </>
                )}
            </div>
        )
    }

    return (
        <Tooltip title={toolTipTitle()}>
            <button
                type="button"
                onClick={setSelectStatusOfSlot}
                style={{
                    color: slotTextColor,
                    height: '30px',
                    borderRadius: '5px',
                    border: '1px solid',
                    borderColor: slotBorderColor,
                    paddingLeft: '4px',
                    paddingRight: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    background: slotColor,
                    cursor: 'pointer',
                }}
            >
                {roomStart}
            </button>
        </Tooltip>
    )
}

export default TimeSlotsPage

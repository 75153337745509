import { Component } from 'react'
import { mapArrayKeysToObjectHelper } from 'util/validation'

/**
 * @typedef WithFormStateChildProps
 * @template FormValues
 * @property {boolean} isLoading
 * @property {boolean} isSubmitted
 * @property {Object} errors
 * @property {FormValues} values A javascript object containing the current form values
 * @property {(e: any) => void} handleChange Updates form value by using native input event
 * @property {(key: string, value: any) => void} handleChangeAsValue Updates form value by specific form values key and value
 * @property {(valuesToAdd: Object) => void} mergeValues Adds/overwrites form values based on the passed object
 * @property {(e: any) => void} handleSubmit Submit handler. Can be attached to an html submit button
 * @property {() => void} handleCancel Resets the form and calls onCancel callback of component props
 * @property {Object} state The internal form state
 * @property {(newState: Object) => void} setState Updates the internal form state
 */

/**
 * @typedef WithFormStateProps
 * @template FormValues
 * @property {Object} initialState
 * @property {FormValues} initialValues A javascript object with the initial form values
 * @property {Object} initialErrors
 * @property {((values: Object | undefined) => boolean) | undefined} isValidForm
 * @property {(values: Object) => Object} getFormErrors
 * @property {((values: Object) => Object) | undefined} onSendRequest
 * @property {(response: Object) => void} onRequestSuccess
 * @property {((values: Object) => Object) | undefined} transformValuesToRequestBody
 * @property {((state: Object) => void) | undefined} onInvalidFormWasSubmitted
 * @property {() => void} onCancel
 * @property {(props: WithFormStateChildProps) => (React.Element|React.Element[]|null)} children
 */


/**
 * @typedef State
 * @template FormValues
 * @property {boolean} isLoading
 * @property {boolean} isSubmitted
 * @property {FormValues} values
 * @property {Object} errors
 */

/**
 * @template FormValues
 * @param {WithFormStateProps<FormValues>} props
 * @returns {State<FormValues>}
 */
function propsToInitialState(props) {
    const {
        initialState,
        initialValues,
        initialErrors,
    } = props
    return ({
        isLoading: false,
        isSubmitted: false,
        values: initialValues,
        errors: typeof initialErrors !== 'undefined'
            ? initialErrors
            : Object
                .keys(initialValues || {})
                .reduce((acc, curr) => {
                    acc[curr] = null
                    return acc
                }, {}),
        ...initialState,
    })
}

/**
 * @class WithFormState
 * @extends {React.Component<WithFormStateProps, State>}
 */
export class WithFormState extends Component {
    /**
     * @param {WithFormStateProps} props
     */
    constructor(props) {
        super(props)

        this.isPristine = true
        this.state = propsToInitialState(props)

        this.publicSetState = (...args) => this.setState(...args)

        this.resetForm = this.resetForm.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeAsValue = this.handleChangeAsValue.bind(this)
        this.mergeValues = this.mergeValues.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
    }

    getFormErrors(state) {
        const { getFormErrors } = this.props
        return typeof getFormErrors === 'function' ? getFormErrors(state.values) : true
    }

    isValidForm() {
        const { isValidForm } = this.props
        const { values } = this.state
        return typeof isValidForm === 'function' ? isValidForm(values) : true
    }

    setInitialState() {
        const { initialValues, initialErrors } = this.props
        this.isPristine = true
        this.setState({
            isLoading: false,
            isSubmitted: false,
            values: initialValues,
            errors: typeof initialErrors !== 'undefined'
                ? initialErrors
                : Object
                    .keys(initialValues || {})
                    .reduce((acc, curr) => {
                        acc[curr] = null
                        return acc
                    }, {}),
        })
    }

    resetForm() {
        this.setInitialState()
    }

    updateErrorState(errors = {}) {
        this.setState((state) => ({
            errors: {
                ...state.errors,
                ...this.getFormErrors(state),
                ...errors,
            },
        }))
    }

    // eslint-disable-next-line react/sort-comp
    handleChangeAsValue(name, value) {
        this.setState((state) => ({
            values: {
                ...state.values,
                [name]: value,
            },
        }))
        if (!this.isPristine) {
            this.updateErrorState()
        }
    }

    /**
     * @param {Object} values
     */
    mergeValues(values) {
        this.setState((state) => ({
            values: {
                ...state.values,
                ...values,
            },
        }))
    }

    handleChange(e) {
        const {
            name, type, value, checked, files,
        } = e.target
        let finalValue = value
        if (type === 'checkbox') {
            finalValue = checked
        } else if (type === 'file') {
            [finalValue] = files
        }
        this.setState((state) => ({
            values: {
                ...state.values,
                [name]: finalValue,
            },
        }))
        if (!this.isPristine) {
            this.updateErrorState()
        }
    }

    handleSubmit(e) {
        e.preventDefault()
        const valid = this.isValidForm()
        if (!valid) {
            this.invalidFormWasSubmitted()
        } else {
            this.validFormWasSubmitted()
        }
    }

    handleCancel() {
        const { onCancel } = this.props
        this.resetForm()
        onCancel()
    }

    invalidFormWasSubmitted() {
        const { onInvalidFormWasSubmitted } = this.props
        this.isPristine = false
        this.updateErrorState()
        if (typeof onInvalidFormWasSubmitted === 'function') {
            onInvalidFormWasSubmitted(this.getFormErrors(this.state))
        }
    }

    async validFormWasSubmitted() {
        const {
            onSendRequest = () => {},
            onRequestSuccess = () => {},
            onRequestFailed = () => {},
            transformValuesToRequestBody = (values) => values,
        } = this.props
        const { values } = this.state
        this.updateErrorState()
        this.setState({ isLoading: true })
        const action = await onSendRequest(transformValuesToRequestBody(values))
        // console.log('action:', action)
        this.setState({ isLoading: false })
        if (action.type.includes('SUCCESS')) {
            this.setInitialState()
            onRequestSuccess(action.response)
        } else {
            onRequestFailed(action.error)
            this.updateErrorState(mapArrayKeysToObjectHelper(action.error.errors))
        }
    }

    /**
     * @return {React.Element|React.Element[]|null}
     */
    render() {
        /** @type {Readonly<WithFormStateProps>} */
        const props = this.props
        return props.children({
            isLoading: this.state.isLoading,
            isSubmitted: this.state.isSubmitted,
            errors: this.state.errors,
            values: this.state.values,
            handleChange: this.handleChange,
            handleChangeAsValue: this.handleChangeAsValue,
            mergeValues: this.mergeValues,
            handleSubmit: this.handleSubmit,
            handleCancel: this.handleCancel,
            state: this.state,
            setState: this.publicSetState,
        })
    }
}

import { Component } from 'react'
import omit from 'lodash.omit'
import fastCompare from 'react-fast-compare'

const PROPS_TO_OMIT = [
    'didMount',
    'onPropsHaveChanged',
    'willUnmount',
    'children',
]

class Mount extends Component {
    componentDidMount() {
        const { didMount } = this.props
        if (didMount)
            didMount()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { onPropsHaveChanged } = this.props
        if (onPropsHaveChanged) {
            const propsToCheck = omit(this.props, PROPS_TO_OMIT)
            const prevPropsToCheck = omit(prevProps, PROPS_TO_OMIT)
            if (!fastCompare(propsToCheck, prevPropsToCheck))
                onPropsHaveChanged(prevProps, prevState, snapshot)
        }
    }

    componentWillUnmount() {
        const { willUnmount } = this.props
        if (willUnmount)
            willUnmount()
    }

    render() {
        const { children } = this.props
        return children || null
    }
}

export default Mount

import React from 'react'
import WithRoles from 'components/pages/settings/users/WithRoles'
import Mount from 'components/util/Mount'
import { Select } from 'antd'

const PAGE_SIZE = 15
const { Option } = Select

function RolesSelectWithRedux({
    value,
    placeholder = 'Select a role',
    onChange,
}) {
    return (
        <WithRoles paginationKey="select" limit={PAGE_SIZE}>
            {({ isFetching, rolesForCurrentPage, fetchRoles }) => (
                <Mount
                    didMount={() => {
                        fetchRoles()
                    }}
                >
                    <Select
                        disabled={isFetching}
                        onChange={onChange}
                        value={isFetching
                            ? 'Loading roles...'
                            : value}
                        placeholder={isFetching
                            ? 'Loading roles...'
                            : placeholder}
                    >
                        {
                            rolesForCurrentPage.map((role) => (
                                <Option
                                    key={role.id}
                                    value={role.id}
                                >
                                    {role.label}
                                </Option>
                            ))
                        }
                    </Select>
                </Mount>
            )}
        </WithRoles>
    )
}

export default RolesSelectWithRedux

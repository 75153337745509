import React from 'react'
import CreateCouponTimeFrameModal from './CreateCouponTimeFrameModal'
import CreateCouponTimeFrameModalState from './CreateCouponTimeFrameModalState'
import CreateCouponTimeFrameFormState from '../../forms/create-time-frame/CreateCouponTimeFrameFormState'

function CreateCouponTimeFrameModalWithState() {
    return (
        <CreateCouponTimeFrameModalState>
            {({
                isOpen,
                closeModal,
                coupon,
            }) => (
                <CreateCouponTimeFrameFormState
                    onCancel={closeModal}
                    onCreateSuccess={closeModal}
                    coupon={coupon}
                >
                    {({
                        values,
                        errors,
                        isLoading,
                        mergeValues,
                        handleChange,
                        handleChangeAsValue,
                        handleCancel,
                        handleSubmit,
                    }) => (
                        <CreateCouponTimeFrameModal
                            isOpen={isOpen}
                            onClose={closeModal}
                            values={values}
                            errors={errors}
                            isLoading={isLoading}
                            mergeValues={mergeValues}
                            onChange={handleChange}
                            onChangeAsValue={handleChangeAsValue}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                        />
                    )}
                </CreateCouponTimeFrameFormState>
            )}
        </CreateCouponTimeFrameModalState>
    )
}

export default CreateCouponTimeFrameModalWithState

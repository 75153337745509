import React from 'react'
import * as theme from 'styles/theme'
import { Tabs } from 'antd'

/**
 * @component
 * @param {Object} props
 * @param {(tab: string) => boolean=} props.hasErrors
 * @param {(props: {tab: string}) => JSX.Element} props.children
 * @param {string[]} props.tabs
 */
export function AppTabs(props) {
    let tabItems = []
    if (Array.isArray(props.tabs)) {
        for (let i = 0, length = props.tabs.length; i < length; i++) {
            const tab = props.tabs[i]
            const tabHasError = typeof props.hasErrors === "function" && props.hasErrors(tab)
            tabItems.push({
                key: tab,
                label: (
                    <span
                        style={{
                            textTransform: 'uppercase',
                            color: tabHasError && theme.colorAlert,
                        }}
                    >
                        {tab}
                    </span>
                ),
                children: props.children({ tab: tab }),
            })
        }
    }

    return (
        <Tabs
            size="small"
            items={tabItems}
        />
    )
}

import React from 'react'
import * as theme from 'styles/theme'
import { Select } from 'antd'
import Rooms from 'components/pages/settings/venue/Rooms'
import { useActiveFilters } from 'components/hooks/useActiveFilters'

const PAGE_SIZE = 100

function RoomsSelect({
    paginationKey,
    placeholder = 'Select a room',
    onChange,
}) {
    const { roomId, handleSetActiveRoom } = useActiveFilters()

    return (
        <Rooms paginationKey={paginationKey} limit={PAGE_SIZE}>
            {({
                isFetching,
                rooms,
            }) => (
                <Select
                    disabled={isFetching}
                    onChange={(id) => {
                        handleSetActiveRoom(id)
                        onChange()
                    }}
                    placeholder={placeholder}
                    style={{ width: 150, marginLeft: theme.spacing }}
                    value={roomId}
                >
                    <Select.Option
                        key="all"
                        value={null}
                    >
                        All rooms
                    </Select.Option>
                    {rooms.map((room) => (
                        <Select.Option
                            key={room.id}
                            value={room.id}
                        >
                            {room.name}
                        </Select.Option>
                    ))}
                </Select>
            )}
        </Rooms>
    )
}

export default RoomsSelect

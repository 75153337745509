import React, {useMemo} from 'react'
import './VoucherDetails.css'
import dayjs, {Dayjs, isDayjs} from 'dayjs'
import {APP_DATE_FORMAT, APP_DATE_TIME_FORMAT} from '../../../../../util/constants'
import {DownloadOutlined} from "@ant-design/icons"
import {Button} from "antd"
import useDownload from "../../../../hooks/useDownload"

/**
 * @param {Object} props
 * @param {Object} props.voucher
 * @param {Object[]} props.voucherIssueReasons
 * @param {Object[]} props.legalEntities
 * @param {Object[]} props.venues
 * @constructor
 */
export function VoucherDetails(props) {
    const voucher = props.voucher
    const coupon = voucher.coupon
    const creator = voucher.creator
    const issuedReason = voucher.issuedReason
    const issuedInVenue = voucher.issuedInVenue

    const downloadArgs = useMemo(() => {
        return {
            endpoint: `/files/vouchers/${voucher.id}/download`,
            saveAs: `VCH_${coupon.code}.pdf`,
        }
    }, [coupon.code, voucher.id])
    const download = useDownload(downloadArgs)

    // console.log('Voucher:', props.voucher)

    let issuedByText = '-'
    if (typeof creator !== 'undefined' && creator !== null) {
        issuedByText = creator.firstName
        if (typeof creator.lastName === 'string' && creator.lastName.length > 0) {
            issuedByText += ' ' + creator.lastName
        }
    }
    let expiresAtText = '-'
    if (typeof coupon.expiresAt === 'string' && coupon.expiresAt.length > 0) {
        expiresAtText = dayjs.utc(coupon.expiresAt).format(APP_DATE_FORMAT)
    }
    let issuedReasonText = '-'
    if (typeof issuedReason !== 'undefined' && issuedReason !== null) {
        issuedReasonText = issuedReason.name
    }
    let issuedInVenueText = '-'
    if (typeof issuedInVenue !== 'undefined' && issuedInVenue !== null) {
        issuedInVenueText = issuedInVenue.name
    }
    let dealNumberText = '-'
    if (typeof voucher.dealNumber === 'string' && voucher.dealNumber.length > 0) {
        dealNumberText = voucher.dealNumber
    }
    let associatedOrderNumberText = '-'
    if (typeof voucher.associatedOrderNumber === 'string' && voucher.associatedOrderNumber.length > 0) {
        associatedOrderNumberText = voucher.associatedOrderNumber
    }

    let usedAtText = 'Not used yet'
    if (typeof coupon.usedAt === 'string' && coupon.usedAt.length > 0) {
        const parsed = dayjs.utc(coupon.usedAt)
        if (isDayjs(parsed) && parsed.isValid()) {
            usedAtText =  parsed.format(APP_DATE_TIME_FORMAT)
        }
    }

    let canBeUsedInText = '-'
    for (let i = 0, length = props.legalEntities.length; i < length; i++) {
        const legalEntity = props.legalEntities[i]
        if (legalEntity.id === coupon.legalEntityId) {
            canBeUsedInText = legalEntity.country.name
            break
        }
    }
    if (typeof coupon.venueId === 'number') {
        const venueId = coupon.venueId
        for (let i = 0, length = props.venues.length; i < length; i++) {
            const venue = props.venues[i]
            if (venue.id === venueId) {
                if (canBeUsedInText === '-') {
                    canBeUsedInText = venue.name
                } else {
                    canBeUsedInText += ', only in venue: ' + venue.name
                }
                break
            }
        }
    } else if (canBeUsedInText !== '-') {
        canBeUsedInText += ', all venues'
    }

    let comment
    if (typeof props.voucher.comment === 'string' && props.voucher.comment.length > 0) {
        comment = props.voucher.comment
    } else {
        comment = 'No comment given'
    }

    return (
        <div className="voucher-details-container">
            <div className="voucher-details-column">
                <div className="voucher-details-line">
                    <span className="voucher-details-line-key">Issued by:</span>
                    <span>{issuedByText}</span>
                </div>
                <div className="voucher-details-line">
                    <span className="voucher-details-line-key">Issued on:</span>
                    <span>{dayjs.utc(props.voucher.createdAt).format(APP_DATE_FORMAT)}</span>
                </div>
                <div className="voucher-details-line">
                    <span className="voucher-details-line-key">Expires on:</span>
                    <span>{expiresAtText}</span>
                </div>
                <div className="voucher-details-line">
                    <span className="voucher-details-line-key">Issued in venue:</span>
                    <span>{issuedInVenueText}</span>
                </div>
                <div className="voucher-details-line">
                    <span className="voucher-details-line-key">Issued reason:</span>
                    <span>{issuedReasonText}</span>
                </div>
            </div>
            <div className="voucher-details-column">
                <div className="voucher-details-line">
                    <span className="voucher-details-line-key">Can be used in:</span>
                    <span>{canBeUsedInText}</span>
                </div>
                <div className="voucher-details-line">
                    <span className="voucher-details-line-key">Used on:</span>
                    <span>{usedAtText}</span>
                </div>
                <div className="voucher-details-line">
                    <span className="voucher-details-line-key">Deal number:</span>
                    <span>{dealNumberText}</span>
                </div>
                <div className="voucher-details-line">
                    <span className="voucher-details-line-key">Associated order number:</span>
                    <span>{associatedOrderNumberText}</span>
                </div>
            </div>

            <div className="voucher-details-divider" />

            <div className="voucher-details-comment">
                <div className="voucher-details-column">
                    <span className="voucher-details-line-key">Comment:</span>
                    <span className="voucher-details-comment-content" dangerouslySetInnerHTML={{__html: comment}}/>
                </div>
            </div>

            <div className="voucher-details-download-btn-container">
                <Button
                    icon={<DownloadOutlined />}
                    onClick={download.startDownload}
                    loading={download.isDownloading}
                >
                    Download PDF
                </Button>
            </div>
        </div>
    )
}

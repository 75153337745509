import React from 'react'
import { Button } from 'antd'
import { ROLES } from 'util/constants'
import HasPermissions from 'components/pages/authorized/HasPermissions'
import CreatePriceAdjustmentModalState from '../modals/create-price-adjustment/CreatePriceAdjustmentModalState'
import './PriceAdjustmentsTableHeader.css'

function PriceAdjustmentsTableHeader({ venueGame }) {
    return (
        <HasPermissions roles={[ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.VENUE_MANAGER]}>
            <CreatePriceAdjustmentModalState venueGame={venueGame}>
                {({ openModal }) => (
                    <div className="priceAdjustmentsTableHeader">
                        <h3>Price adjustments</h3>
                        <Button onClick={openModal}>
                            Add adjustment
                        </Button>
                    </div>
                )}
            </CreatePriceAdjustmentModalState>
        </HasPermissions>
    )
}

export default PriceAdjustmentsTableHeader

/* eslint-disable max-len */
import React, {useCallback, useEffect, useMemo, useState} from "react"
import {Form, Radio, Switch} from "antd"
import classNames from "classnames"
import {
    bookerFormItemsHasErrors,
    timeSlotItemsHasErrors,
    playersGameItemsHasErrors,
    companyFormItemsHasErrors,
    pricingFormItemsHasErrors,
} from "util/validation/booking"
import Loading from "components/util/Loading"
import "./EditBookingForm.css"
import { formatCurrency } from "../../../../../util/currency"
import {BookingPlayersGameItems} from "../items/BookingPlayersGameItems"
import {BookingTimeSlotItems} from "../items/BookingTimeSlotItems"
import {BookingBookerFormItems} from "../items/BookingBookerFormItems"
import {BookingPricingFormItems} from "../items/BookingPricingFormItems"
import {BookingCommentFormItem} from "../items/BookingCommentFormItem"
import {BookingFormItem} from "../items/BookingFormItem"
import {useGameVenuesByVenueIdAPI} from "../../../../hooks/gameVenues/useGameVenuesByActiveVenue"
import { useActiveFilters } from "../../../../hooks/useActiveFilters"
import {useBookingPricingAPI} from "../../../../hooks/bookingPricing/useBookingPricingAPI"
import {useBookingDealTypes} from "../../hooks/useBookingDealTypes"
import {useAppState} from "../../../../hooks/useAppState"
import dayjs from "dayjs"
import {buildBookingStartDateTimeRequestString} from "../../../../../util/transformers/booking"
import {BookingCompanyFormItems} from "../items/BookingCompanyFormItems"
import {BookingUpsellingItems} from "../items/BookingUpsellingItems"
import {CheckOutlined, CloseOutlined} from "@ant-design/icons"

/**
 * @component
 * @param {Object} props
 * @param {boolean} props.isLoading
 * @param {CreateOrEditBookingFormValues} props.values
 * @param {{ [key in keyof CreateOrEditBookingFormValues]: string[]}} props.errors
 * @param {(valuestomerge: {[key in keyof CreateOrEditBookingFormValues]: CreateOrEditBookingFormValues[key]}) => void} props.mergeValues
 * @param {(e: any) => void} props.onChange
 * @param {(key: keyof CreateOrEditBookingFormValues, value: CreateOrEditBookingFormValues[key]) => void } props.onChangeAsValue
 * @param {string} props.priceSystem
 * @param {(requestBody: Object) => void} props.triggerPriceUpdate
 * @param {UseFetchPriceStateReturnObject} props.priceHandle
 * @returns {JSX.Element}
 */
export function EditBookingForm(props) {
    const appState = useAppState()
    const activeFilters = useActiveFilters()
    const legalEntityId = activeFilters.legalEntityId
    const venueId = activeFilters.venueId
    const gameVenuesAPI = useGameVenuesByVenueIdAPI()
    const [gameVenues, setGameVenues] = useState(/** @type {GameVenueResource[]} */ [])
    const bookingPricingAPI = useBookingPricingAPI()
    const [bookingPriceResponseBody, setBookingPriceResponseBody] = useState(/** @type {BookingPricingAPIResponseBody|null} */ null)
    const { fetchState, bookingDealTypes } = useBookingDealTypes()

    /** @type {(gameVenues: GameVenueResource[]|null, error: string|null) => void} */
    const getGameVenuesAPICallback = useCallback((gameVenues, error) => {
        if (error) {
            throw new Error(error)
        } else {
            setGameVenues(gameVenues)
        }
    }, [])
    useEffect(() => {
        if (typeof activeFilters.venueId !== "number")
            return

        gameVenuesAPI.getGameVenuesByVenueId(activeFilters.venueId, getGameVenuesAPICallback)
    }, [activeFilters.venueId, gameVenuesAPI.getGameVenuesByVenueId, getGameVenuesAPICallback])

    /** @type {(responseBody: BookingPricingAPIResponseBody | null, error: string | null) => void} */
    const getBookingPriceCallback = useCallback((responseBody, error) => {
        if (error) {
            // So that we get a sentry log...
            throw new Error(error)
        } else {
            setBookingPriceResponseBody(responseBody)
        }
    }, [])
    useEffect(() => {
        if (!dayjs.isDayjs(props.values.bookingStartDate) || !dayjs.isDayjs(props.values.bookingStartTime))
            return

        const bookingStartDateTime = buildBookingStartDateTimeRequestString(props.values.bookingStartDate, props.values.bookingStartTime)
        bookingPricingAPI.getBookingPricing({
            bookingId: props.values.bookingId,
            venueId: venueId,
            gameId: props.values.gameId,
            playerAmount: props.values.playerAmount,
            isForcedPrivate: props.values.isForcedPrivate,
            bookingStartDateTime: bookingStartDateTime,
            isCompanyBooking: props.values.isCompanyBooking,
            socialDealFixedAmount: props.values.socialDealFixedAmount,
            socialDealDiscountPercentage: props.values.socialDealDiscountPercentage,
            addOpenBar: props.values.openBarChecked,
            addDrinks: props.values.drinksChecked,
            addNachos: props.values.nachosChecked,
            addDrinkTokens: props.values.drinkTokensChecked,
        }, getBookingPriceCallback)

    }, [
        venueId,
        props.values.bookingId,
        props.values.gameId,
        props.values.playerAmount,
        props.values.bookingStartDate,
        props.values.bookingStartTime,
        props.values.isForcedPrivate,
        props.values.socialDealFixedAmount,
        props.values.socialDealDiscountPercentage,
        props.values.openBarChecked,
        props.values.drinksChecked,
        props.values.nachosChecked,
        props.values.drinkTokensChecked,
        props.values.isCompanyBooking,
        bookingPricingAPI.getBookingPricing,
    ])

    /** @type {GameVenueResource|undefined} */
    const selectedGameVenue = useMemo(() => {
        for (let i = 0, length = gameVenues.length; i < length; i++) {
            const gameVenue = gameVenues[i]
            if (gameVenue.gameId === props.values.gameId)
                return gameVenue
        }

        return undefined
    }, [props.values.gameId, gameVenues])

    let gameMinPlayerAmount = 0
    let gameDurationInMinutes = 0
    if (typeof selectedGameVenue !== "undefined") {
        gameMinPlayerAmount = selectedGameVenue.minPlayers
        gameDurationInMinutes = selectedGameVenue.game.duration
    }

    let headerNumber = 0

    return (
        <Form labelWrap={true}>
            <div className="edit-booking-form-container">
                <Form.Item>
                    <h3 className={classNames("requiredSection", { hasError: playersGameItemsHasErrors(props.errors) })}>
                        {++headerNumber}. VR Experience & Player Amount
                    </h3>
                    <BookingPlayersGameItems
                        values={props.values}
                        errors={props.errors}
                        onChange={props.onChange}
                        onChangeAsValue={props.onChangeAsValue}
                        onChangeGame={(newGameId, newGameVenue) => {
                            let introductionDuration = 0
                            if (typeof newGameVenue !== "undefined" && newGameVenue !== null)
                                introductionDuration = newGameVenue.introductionDuration

                            let newPlayerAmount = props.values.playerAmount
                            if (props.values.playerAmount < newGameVenue.minPlayers)
                                newPlayerAmount = newGameVenue.minPlayers

                            props.mergeValues({
                                gameId: newGameId,
                                introductionDuration: introductionDuration,
                                playerAmount: newPlayerAmount,
                                roomId: null,
                            })
                        }}
                        onChangePlayer={(playerAmount) => props.onChangeAsValue("playerAmount", playerAmount)}
                        priceSystem={props.priceSystem}
                        gameVenues={gameVenues}
                    />
                </Form.Item>

                <Form.Item>
                    <h3
                        key="date_and_time"
                        className={
                            classNames("requiredSection", { hasError: timeSlotItemsHasErrors(props.errors) })
                        }
                    >
                        {++headerNumber}. Date & Time
                    </h3>
                    <BookingTimeSlotItems
                        minuteStep={gameDurationInMinutes}
                        values={props.values}
                        errors={props.errors}
                        onChangeRoom={(roomId) => {
                            props.mergeValues({
                                roomId: roomId,
                                bookingStartTime: null,
                            })
                        }}
                        onChangeDate={(newDate) => {
                            props.mergeValues({
                                bookingStartDate: newDate,
                                bookingStartTime: null,
                            })
                        }}
                        onChangeAsValue={props.onChangeAsValue}
                        onChange={props.onChange}
                        isLoading={props.isLoading}
                        gameVenues={gameVenues}
                    />
                </Form.Item>

                <Form.Item>
                    <h3
                        key="booker_details"
                        className={
                            classNames("requiredSection", { hasError: bookerFormItemsHasErrors(props.errors) })
                        }
                    >
                        {++headerNumber}. Booker Details
                    </h3>
                    <BookingBookerFormItems
                        values={props.values}
                        errors={props.errors}
                        onChange={props.onChange}
                        onChangeAsValue={props.onChangeAsValue}
                    />
                </Form.Item>

                <Form.Item>
                    <h3
                        key="b2c_or_b2b"
                        className={
                            classNames({
                                hasError: companyFormItemsHasErrors(props.errors),
                                requiredSection: props.values.isCompanyBooking,
                            })
                        }
                    >
                        {++headerNumber}. B2C or B2B?
                    </h3>
                    <BookingCompanyFormItems
                        values={props.values}
                        errors={props.errors}
                        onChange={props.onChange}
                        onChangeAsValue={props.onChangeAsValue}
                        countries={appState.countries}
                    />
                </Form.Item>

                <Form.Item>
                    <h3 key="upgrades">
                        {++headerNumber}. Upgrades
                    </h3>
                    <BookingUpsellingItems
                        isloading={props.isLoading}
                        values={props.values}
                        errors={props.errors}
                        mergeValues={props.mergeValues}
                        onChange={props.onChange}
                        onChangeAsValue={props.onChangeAsValue}
                    />
                </Form.Item>

                <Form.Item>
                    <h3
                        key="pricing"
                        className={
                            classNames({
                                hasError: pricingFormItemsHasErrors(props.errors),
                                requiredSection: props.values.isSocialDeal,
                            })
                        }
                    >
                        {++headerNumber}. Pricing
                    </h3>
                    <BookingFormItem>
                        <Radio.Group
                            name="isSocialDeal"
                            value={props.values.isSocialDeal ? "deal" : "normal"}
                            disabled={true}
                            onChange={(event) => {
                                const val = event.target.value
                                if (val === "normal") {
                                    props.mergeValues({
                                        isSocialDeal: false,
                                        socialDealFixedAmount: null,
                                        socialDealDiscountPercentage: null,
                                        cateringChecked: false,
                                        infoCatering: null,
                                        meetingRoomChecked: false,
                                        infoMeetingRoom: null,
                                        barForFaitChecked: false,
                                        infoBarForFait: null,
                                        bookingDealType: null,
                                    })
                                } else {
                                    props.mergeValues({
                                        isSocialDeal: true,
                                        drinksChecked: false,
                                        openBarChecked: false,
                                        nachosChecked: false,
                                        drinkTokensChecked: false,
                                    })
                                }
                            }}
                        >
                            <Radio.Button value={"normal"}>
                                Normal pricing
                            </Radio.Button>
                            <Radio.Button value={"deal"}>
                                Deal
                            </Radio.Button>
                        </Radio.Group>
                    </BookingFormItem>
                    <BookingPricingFormItems
                        values={props.values}
                        errors={props.errors}
                        onChange={props.onChange}
                        onChangeAsValue={props.onChangeAsValue}
                        mergeValues={props.mergeValues}
                        bookingPriceResponseBody={bookingPriceResponseBody}
                        bookingDealTypes={bookingDealTypes}
                    />
                </Form.Item>

                <Form.Item>
                    <h3 key="comment">{++headerNumber}. Comment</h3>
                    <BookingCommentFormItem
                        values={props.values}
                        errors={props.errors}
                        onChange={props.onChange}
                    />
                </Form.Item>

                <Form.Item>
                    <h3 key="meta">{++headerNumber}. Notifications</h3>
                    <BookingFormItem
                        label={"Send confirmation email?"}
                        error={props.errors.drinkTokensChecked}
                    >
                        <Switch
                            size="small"
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={props.values.sendConfirmationEmail}
                            onChange={(checked) => props.onChangeAsValue("sendConfirmationEmail", checked)}
                        />
                    </BookingFormItem>
                </Form.Item>
            </div>
        </Form>
    )
}

import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import { toPriceAdjustmentApiRequest } from 'util/transformers/priceAdjustment'
import { isValidPriceAdjustment } from 'util/validation/priceAdjustment'
import { getPriceAdjustmentErrors } from 'util/validation/priceAdjustmentErrors'
import CreatePriceAdjustmentRequest from './CreatePriceAdjustmentRequest'

function CreatePriceAdjustmentFormState({
    venueGame,
    onCancel,
    onCreateSuccess,
    children,
    priceSystem,
}) {
    return (
        <CreatePriceAdjustmentRequest game={venueGame} paginationKey="venueGamesOverview">
            {({
                createPriceAdjustment,
                createPriceAdjustmentSuccess,
                createPriceAdjustmentFailed,
                fetchVenueGames,
            }) => {
                if (venueGame === null || typeof venueGame === 'undefined') {
                    return null
                }
                return (
                    <WithFormState
                        key={venueGame}
                        initialValues={{
                            adjustmentType: 'wholeDay',
                            from: null,
                            until: null,
                            totalPrice: venueGame.baseTotalPrice,
                            playerPrice: venueGame.basePlayerPrice,
                        }}
                        isValidForm={(values) => isValidPriceAdjustment(values, priceSystem)}
                        getFormErrors={(values) => getPriceAdjustmentErrors(values, priceSystem)}
                        transformValuesToRequestBody={toPriceAdjustmentApiRequest}
                        onCancel={onCancel}
                        onSendRequest={(values) => createPriceAdjustment(values)}
                        onRequestSuccess={() => {
                            fetchVenueGames()
                            createPriceAdjustmentSuccess()
                            onCreateSuccess()
                        }}
                        onRequestFailed={createPriceAdjustmentFailed}
                    >
                        {children}
                    </WithFormState>
                )
            }}
        </CreatePriceAdjustmentRequest>
    )
}

export default CreatePriceAdjustmentFormState

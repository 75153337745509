/* eslint-disable max-len */
/* eslint-disable prefer-spread */
import React from 'react'
import Mount from 'components/util/Mount'
import PaginationTableBottomBar from 'components/util/table/PaginationTableBottomBar'
import {
    fromToday,
    tillToday,
    tillFuture,
    getStartOfDay,
    getEndOfDay,
} from 'util/index'
import dayjs from 'dayjs'
import ActiveBookingInfo from './ActiveBookingInfo'
import BookingsRequest from '../requests/BookingsRequest'
import {
    BookingsTableTopBar,
    ALL,
    DAY,
    FUTURE,
    PAST,
} from './BookingsTableTopBar'
import {BookingsTable} from "./BookingsTable"

const PAGE_SIZE = 15

/**
 * @component
 * @param {Object} props
 * @param {string} props.paginationKey
 * @returns {Element}
 * @constructor
 */
export function BookingsTableWithBookings(props) {
    return (
        <BookingsRequest
            paginationKey={props.paginationKey}
            limit={PAGE_SIZE}
        >
            {({
                isFetching,
                currentPage,
                total,
                params,
                bookingsForCurrentPage,
                fetchBookings,
            }) => {
                // console.log('bookingsForCurrentPage', bookingsForCurrentPage)
                let activeDateFilter = ALL
                let activeDateValue = null
                const fetchFutureBookings = () => {
                    fetchBookings({
                        from: fromToday,
                        till: tillFuture,
                    })
                }
                const fetchPastBookings = () => {
                    fetchBookings({
                        till: tillToday,
                        sort: 'start.desc',
                    })
                }
                const fetchFilteredBookings = (date = new Date()) => {
                    fetchBookings({
                        from: getStartOfDay(date),
                        till: getEndOfDay(date),
                    })
                }
                const searchBookings = (search) => {
                    if (search === '') {
                        fetchBookings()
                    } else {
                        fetchBookings({ ...params, search })
                    }
                }

                const { from, till } = params
                if (till === tillToday) {
                    activeDateFilter = PAST
                } else if (typeof from === 'undefined' || typeof till === 'undefined') {
                    activeDateFilter = ALL
                } else if (from === fromToday && till === tillFuture) {
                    activeDateFilter = FUTURE
                } else {
                    activeDateValue = dayjs.utc(from)
                    activeDateFilter = DAY
                }

                // To add number to each booking
                const offset = (currentPage - 1) * PAGE_SIZE

                const numberedBookingsForCurrentPage = Array.apply(null, Array(bookingsForCurrentPage.length)).map((_, i) => {
                    const entryNumber = offset + i + 1

                    return { ...bookingsForCurrentPage[i], entryNumber: entryNumber }
                })

                return (
                    <Mount
                        didMount={fetchFutureBookings}
                        onPropsHaveChanged={fetchFutureBookings}
                    >
                        <ActiveBookingInfo />
                        <BookingsTableTopBar
                            activeDateValue={activeDateValue}
                            activeDateFilter={activeDateFilter}
                            searchValue={params.search}
                            filterIsActive={typeof params.day !== 'undefined' || typeof params.search !== 'undefined'}
                            onSelectAllBookings={fetchBookings}
                            onSelectFutureBookings={fetchFutureBookings}
                            onSelectPastBookings={fetchPastBookings}
                            onSelectDate={(date) => {
                                if (date === null) {
                                    fetchFutureBookings()
                                } else {
                                    fetchFilteredBookings(date)
                                }
                            }}
                            onSearch={searchBookings}
                            onClearFilters={() => fetchBookings()}
                        />
                        <BookingsTable
                            bookings={numberedBookingsForCurrentPage}
                            isFetching={isFetching}
                            onSort={(sort) => {
                                if (sort !== null) {
                                    fetchBookings({ ...params, sort })
                                } else {
                                    fetchBookings({ ...params })
                                }
                            }}
                        />
                        <PaginationTableBottomBar
                            currentPage={currentPage}
                            total={total}
                            pageSize={params.limit || PAGE_SIZE}
                            onChangePage={(page) => fetchBookings({
                                ...params,
                                page,
                            })}
                        />
                    </Mount>
                )
            }}
        </BookingsRequest>
    )
}

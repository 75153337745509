import { createValidationErrorObject } from './index'
import * as validation from './room'

export const getNameError = createValidationErrorObject(
    validation.isValidName,
    'name',
    'Please fill in a valid name.',
)

export const getSeatsError = createValidationErrorObject(
    validation.isValidSeats,
    'seats',
    'Please fill in a valid number of available seats.',
)

export const getSlotDurationError = createValidationErrorObject(
    validation.isValidSlotDuration,
    'slotDuration',
    'Please fill in a valid slot duration.',
)

export const getRoomConfigFolderError = createValidationErrorObject(
    validation.isValidRoomConfigFolder,
    'roomConfigFolder',
    'Please provide a valid URL.',
)

export const getRoomErrors = (values) => ({
    ...getNameError(values.name),
    ...getSeatsError(values.seats),
    ...getSlotDurationError(values.slotDuration),
})

export const getEditRoomErrors = (values) => ({
    ...getNameError(values.name),
    ...getSeatsError(values.seats),
    ...getRoomConfigFolderError(values.roomConfigFolder),
})

import React, { useState } from 'react'
import { Button, Input } from 'antd'
import * as theme from 'styles/theme'
import { MODAL_IDS, ROLES } from 'util/constants'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import HasPermissions from 'components/pages/authorized/HasPermissions'

const { Search } = Input

export const ACTIVE_FILTER = 'active'
export const INACTIVE_FILTER = 'in_active'

function CouponsBatchTableTopBar({
    onSearchCoupons,
    searchValue,
}) {
    const [searchFilter, setSearchFilter] = useState(searchValue)
    return (
        <div style={{ marginBottom: theme.spacing, display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <HasPermissions roles={[ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER]}>
                    <WithActiveModal modalId={MODAL_IDS.CREATE_COUPON_BATCH}>
                        {({ openModal }) => (
                            <Button
                                type="primary"
                                onClick={openModal}
                                style={{ marginRight: `${theme.spacing}px` }}
                            >
                                New coupon batch
                            </Button>
                        )}
                    </WithActiveModal>
                    <WithActiveModal modalId={MODAL_IDS.MARK_USED_COUPON_BATCH}>
                        {({ openModal }) => (
                            <Button
                                type="primary"
                                onClick={openModal}
                                style={{ marginRight: `${theme.spacing}px` }}
                            >
                                Mark batch coupons as used
                            </Button>
                        )}
                    </WithActiveModal>
                </HasPermissions>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ width: 250, marginLeft: theme.spacing }}>
                    <Search
                        value={searchFilter}
                        placeholder="Type to search coupons..."
                        onChange={((e) => setSearchFilter(e.target.value))}
                        onSearch={onSearchCoupons}
                    />
                </div>
            </div>
        </div>
    )
}

export default CouponsBatchTableTopBar

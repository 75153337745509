import { useEffect } from 'react'
import { useNavigate } from 'react-router'

export function NotFoundRedirectRoute({ redirectTo, hardNavigate }) {
    const navigate = useNavigate()

    useEffect(() => {
        if (hardNavigate) {
            window.location.pathname = redirectTo
        } else {
            navigate(redirectTo, { replace: true })
        }
    }, [hardNavigate, navigate, redirectTo])

    return null
}

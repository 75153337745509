import React from 'react'
import { TopBar } from 'components/layout/TopBar'
import { Main } from  'components/layout/Main'
import VenueSettingsContainer from 'components/layout/sidebar/VenueSettingsContainer'
import DiscountBannersTableWithState from './table/DiscountBannersTableWithState'
import CreateDiscountBannerModalWithState from './modals/create/CreateDiscountBannerModalWithState'
import DeleteDiscountBannerModalWithState from './modals/delete/DeleteDiscountBannerModalWithState'
import EditDiscountBannerModalWithState from './modals/edit/EditDiscountBannerModalWithState'

function DiscountBannersPage() {
    return (
        <>
            <TopBar activeMenuItem="venueSettings" />
            <Main>
                <VenueSettingsContainer activeMenuItem="discountBanners">
                    <DiscountBannersTableWithState paginationKey="overview" />
                    <CreateDiscountBannerModalWithState />
                    <DeleteDiscountBannerModalWithState />
                    <EditDiscountBannerModalWithState />
                </VenueSettingsContainer>
            </Main>
        </>
    )
}

export default DiscountBannersPage

import React from 'react'
import { message } from 'antd'
import { WithFormState } from 'components/util/forms/WithFormState'
import CreateGameRequest from 'components/pages/settings/games/forms/create/CreateGameRequest'
import { isValidGame, formHasLocaleErrors } from 'util/validation/game'
import { getGameErrors } from 'util/validation/gameErrors'
import { getEntityFromResponse } from 'util/index'
import { toGameApiRequest } from 'util/transformers/game'
import { useLegalEntity } from 'components/hooks/useLegalEntity'

function CreateGameFormState({
    onCancel,
    onSaveSuccess,
    children,
}) {
    const legalEntity = useLegalEntity()
    if (typeof legalEntity === 'undefined')
        throw new Error(`Legal Entity is undefined! Comp: ${CreateGameFormState.name}`)

    return (
        <CreateGameRequest paginationKey="overview">
            {({
                createGame,
                fetchGames,
                createGameSuccess,
                createGameFailed,
            }) => (
                <WithFormState
                    initialValues={{
                        bodyEn: '',
                        bodyNl: '',
                        bodyFr: '',
                        duration: 30,
                        mediaItem: null,
                        maxPlayers: 8,
                        minPlayers: 2,
                        readMoreLinkEn: '',
                        readMoreLinkNl: '',
                        readMoreLinkFr: '',
                        titleEn: '',
                        titleNl: '',
                        titleFr: '',
                        enableTeams: false,
                        numberOfTeams: null,
                        public: true,
                        videoUrl: '',
                        extraConditionsLinkNl: '',
                        extraConditionsLinkEn: '',
                        extraConditionsLinkFr: '',
                        code: '',
                        minAge: 12,
                    }}
                    isValidForm={(values) => isValidGame(values, legalEntity.priceSystem)}
                    getFormErrors={(values) => getGameErrors(values, legalEntity.priceSystem)}
                    transformValuesToRequestBody={toGameApiRequest}
                    onSendRequest={createGame}
                    onCancel={onCancel}
                    onRequestSuccess={(response) => {
                        const game = getEntityFromResponse(response, 'games')
                        fetchGames()
                        createGameSuccess(game)
                        onSaveSuccess(game)
                    }}
                    onRequestFailed={createGameFailed}
                    onInvalidFormWasSubmitted={(errors) => {
                        if (formHasLocaleErrors(errors)) {
                            message.error('Please fix translation errors.')
                        }
                    }}
                >
                    {children}
                </WithFormState>
            )}
        </CreateGameRequest>
    )
}

export default CreateGameFormState

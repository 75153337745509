import React from "react"
import { ConfirmInputModal } from "components/util/modals/ConfirmInputModal"
import {DeleteBookingFormState} from "../../forms/delete/DeleteBookingFormState"

/**
 * @component
 * @param {Object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.onClose
 * @param {BookingResource} props.booking
 * @return {React.Element|React.Element[]|null}
 */
export function DeleteBookingModal(props) {
    if (props.booking === null || typeof props.booking === "undefined") {
        return null
    }

    const bookerFullName = props.booking.bookerFirstName + " " + props.booking.bookerLastName

    return (
        <DeleteBookingFormState
            onDeleteSuccess={props.onClose}
            onCancel={props.onClose}
            booking={props.booking}
        >
            {(formChildProps) => {
                return (
                    <ConfirmInputModal
                        safeWord={bookerFullName}
                        title={`Are you sure you want to delete the booking of ${bookerFullName}?`}
                        isOpen={props.isOpen}
                        onConfirm={formChildProps.handleSubmit}
                        onCancel={formChildProps.handleCancel}
                        confirmText="Delete this booking"
                        confirmIsDanger={true}
                        cancelText="Cancel"
                        isLoading={formChildProps.isLoading}
                        close={props.onClose}
                    >
                        <p>
                            Removing a booking cannot be undone.
                            {" "}
                            <br />
                            Please type the full name of the person who booked to confirm.
                        </p>
                    </ConfirmInputModal>
                )
            }}
        </DeleteBookingFormState>
    )
}

import { createValidationErrorObject } from './index'
import * as validation from './equipmentSet'

export const getNameError = createValidationErrorObject(
    validation.isValidName,
    'name',
    'Please fill in a valid name.',
)

export const getUidError = createValidationErrorObject(
    validation.isValidUid,
    'uid',
    'Please fill in a valid uid.',
)

export const getEquipmentSetErrors = ({
    name,
    uid,
}) => ({
    ...getNameError(name),
    ...getUidError(uid),
})

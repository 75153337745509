import React from 'react'
import { deleteGiftCard, fetchGiftCardsExtendCurrentParams } from 'store/actions/giftCards'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    deleteGiftCard: (giftCard) => dispatch(deleteGiftCard(giftCard.id)),
    deleteGiftCardSuccess: (giftCard) => {
        notification.success({
            message: 'Gift card deleted!',
            description: (
                <span>
                    Gift card for &nbsp;
                    <strong>
                        {giftCard.firstName}
                        {' '}
                        {giftCard.lastName}
                    </strong>
                    &nbsp;
                    is succesfully deleted.
                </span>
            ),
        })
    },
    deleteGiftCardFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchGiftCards: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchGiftCardsExtendCurrentParams(paginationKey))
    },
})

const DeleteGiftCardRequest = withReduxState(null, mapDispatchToProps)

export default DeleteGiftCardRequest

import React from 'react'
import { Tabs } from 'antd'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import WithMe from 'components/pages/authorized/WithMe'
import { MODAL_IDS } from 'util/constants'
import VenueGameOverview from './VenueGameOverview'
import CreateVenueGameButton from '../forms/CreateVenueGameButton'

// const { TabPane } = Tabs

function buildItems(venueGames, paginationKey) {
    if (!Array.isArray(venueGames))
        return []

    const items = []

    for (let i = 0, length = venueGames.length; i < length; i++) {
        const venueGame = venueGames[i]
        items.push({
            key: venueGame.id.toString(),
            label: venueGame.title,
            children: <VenueGameOverview venueGame={venueGame} paginationKey={paginationKey} />,
        })
    }

    return items
}

function GamesTabs({
    activeTabKey,
    venueGames,
    onChangeTab,
    paginationKey,
}) {
    return (
        <WithMe>
            {({ isAdmin, isLegalEntityManager, isVenueManager }) => (
                <WithActiveModal modalId={MODAL_IDS.DELETE_VENUE_GAME}>
                    {({ openModalWithEntity }) => (
                        <Tabs
                            activeKey={activeTabKey}
                            onChange={onChangeTab}
                            tabBarExtraContent={<CreateVenueGameButton />}
                            type={isAdmin || isLegalEntityManager || isVenueManager ? 'editable-card' : 'card'}
                            // animated
                            hideAdd={true}
                            onEdit={async (targetKey, action) => {
                                if (action === 'remove') {
                                    openModalWithEntity(parseInt(targetKey, 10))
                                }
                            }}
                            items={buildItems(venueGames, paginationKey)}
                        >
                            {/* {venueGames.map((venueGame) => ( */}
                            {/*     <TabPane */}
                            {/*         key={venueGame.id} */}
                            {/*         tab={venueGame.title} */}
                            {/*     > */}
                            {/*         <VenueGameOverview venueGame={venueGame} paginationKey={paginationKey} /> */}
                            {/*     </TabPane> */}
                            {/* ))} */}
                        </Tabs>
                    )}
                </WithActiveModal>
            )}
        </WithMe>
    )
}

export default GamesTabs

import React from 'react'
import DeleteRoomFormState
    from 'components/pages/settings/rooms/forms/delete/DeleteRoomFormState'
import { ConfirmInputModal } from 'components/util/modals/ConfirmInputModal'
import Entity from 'components/util/Entity'

function DeleteRoomModal({
    isOpen,
    entity,
    onClose,
    onDeleteSuccess,
}) {
    return (
        <Entity entityKey="rooms" id={entity}>
            {(room) => (
                <DeleteRoomFormState
                    onDeleteSuccess={(roomId) => {
                        onClose()
                        onDeleteSuccess(roomId)
                    }}
                    onCancel={onClose}
                    values={room}
                >
                    {({
                        isLoading,
                        handleSubmit,
                        handleCancel,
                    }) => (
                        <ConfirmInputModal
                            safeWord={room.name}
                            title={`Are you sure you want to delete ${room.name}?`}
                            isOpen={isOpen}
                            onConfirm={handleSubmit}
                            onCancel={handleCancel}
                            confirmText={`Delete ${room.name}`}
                            confirmType="primary"
                            confirmIsDanger={true}
                            cancelText="Cancel"
                            isLoading={isLoading}
                            close={onClose}
                        >
                            <p>
                                Removing a room cannot be undone.
                                {' '}
                                <br />
                                Please type the exact name of the room to confirm.
                            </p>
                        </ConfirmInputModal>
                    )}
                </DeleteRoomFormState>
            )}
        </Entity>
    )
}

export default DeleteRoomModal

import React from 'react'
import { Modal } from 'antd'
import { ModalFooter } from 'components/util/modals/ModalFooter'
import CreateVenueForm from 'components/pages/settings/venues/forms/create/CreateVenueForm'
import { formHasErrorsForLocale } from 'util/validation/venue'
import { capitalize } from 'util/index'
import MultiLangVenueForm from '../../forms/MultiLangVenueForm'
import {AppTabs} from "../../../../../util/AppTabs"
import {LANGUAGE_CODES} from "../../../../../../util/constants"

function CreateVenueModal({
    isOpen,
    onClose,
    values,
    errors,
    isLoading,
    onChange,
    onChangeAsValue,
    onCancel,
    onSubmit,
}) {
    return (
        <Modal
            title="Create new venue"
            width={600}
            open={isOpen}
            onOk={onSubmit}
            onCancel={onClose}
            closable={!isLoading}
            maskClosable={!isLoading}
            footer={(
                <ModalFooter
                    isLoading={isLoading}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                />
            )}
        >
            {isOpen && (
                <>
                    <AppTabs
                        tabs={LANGUAGE_CODES}
                        hasErrors={(language) => formHasErrorsForLocale(errors, language)}
                    >
                        {({ tab }) => (
                            <MultiLangVenueForm
                                language={capitalize(tab)}
                                isLoading={isLoading}
                                values={values}
                                errors={errors}
                                onChange={onChange}
                                onChangeAsValue={onChangeAsValue}
                            />
                        )}
                    </AppTabs>
                    <CreateVenueForm
                        isLoading={isLoading}
                        values={values}
                        errors={errors}
                        onChange={onChange}
                        onChangeAsValue={onChangeAsValue}
                        onCreateSucces={onClose}
                    />
                </>
            )}
        </Modal>
    )
}

export default CreateVenueModal

import { useSelector, useDispatch } from 'react-redux'
import { useCallback, useState, useMemo } from 'react'
import { fetchVoucherIssueReasons } from '../../store/actions/voucherIssueReasons'

/**
 * @typedef {Object} PaginationParams
 * @property {number | undefined} page Current page to fetch
 * @property {number | undefined} limit How many items to fetch per page
 * @property {string | undefined } sort Order of the items. E.g. "created_at.desc"
 */

/**
 * @typedef {Object} ReturnObject
 * @property {boolean} initialFetchStarted Has the first fetch already occurred?
 * @property {boolean} isFetching Is fetching data?
 * @property {number} currentPage Current page
 * @property {number} perPage Amount of records per page
 * @property {number} totalPages Total amount of pages
 * @property {PaginationParams} paginationParams Currently used pagination params
 * @property {Array} voucherIssueReasonsForCurrentPage All voucher issue reasons for the current page
 * @property {(paginationParams: PaginationParams) => void} fetchVoucherIssueReasons
 */

const DEFAULT_SORT = 'created_at.desc'
const DEFAULT_PAGE_SIZE = 2000
const DEFAULT_PAGE = 1

// const entityKey = 'voucherIssueReasons'

/**
 * @param paginationKey e.g. 'overview'
 * @returns ReturnObject
 */
export function usePaginatedVoucherIssueReasons(paginationKey) {
    const pagination = useSelector(state => state.pagination.voucherIssueReasons[paginationKey])
    const voucherIssueReasons = useSelector(state => state.entities.voucherIssueReasons)
    // const voucherIssueReasonsForCurrentPage = useSelector((state) => getEntitiesForCurrentPage(state, entityKey, paginationKey))
    const dispatch = useDispatch()
    const [initialFetchStarted, setInitialFetchStarted] = useState(false)

    const voucherIssueReasonsForCurrentPage = useMemo(() => {
        if (typeof voucherIssueReasons !== 'object')
            return []
        if (typeof pagination?.params?.page !== 'number')
            return []
        if (pagination.isFetching)
            return []

        const idsInPage = pagination.pages[pagination.params.page]
        const length = idsInPage.length
        // eslint-disable-next-line prefer-spread
        const entriesInPage = Array.apply(null, Array(length)).map(() => null)
        for (let i = 0; i < length; i++) {
            const id = idsInPage[i]
            entriesInPage[i] = voucherIssueReasons[id]
        }
        return entriesInPage
    }, [pagination, voucherIssueReasons])

    /**
     * @param {PaginationParams} paginationParams
     */
    const localFetchVoucherIssueReasons = useCallback((paginationParams) => {
        setInitialFetchStarted(true)

        const finalParams = {}
        if (typeof paginationParams.page === 'number')
            finalParams.page = paginationParams.page
        else
            finalParams.page = DEFAULT_PAGE

        if (typeof paginationParams.limit === 'number')
            finalParams.limit = paginationParams.limit
        else
            finalParams.limit = DEFAULT_PAGE_SIZE

        if (typeof paginationParams.sort === 'string' && paginationParams.sort.length > 0)
            finalParams.sort = paginationParams.sort
        else
            finalParams.sort = DEFAULT_SORT

        dispatch(fetchVoucherIssueReasons(paginationKey, finalParams))
    }, [paginationKey, dispatch])

    /** @type {ReturnObject} */
    const result = useMemo(() => {
        if (typeof pagination !== 'object') {
            return {
                initialFetchStarted: initialFetchStarted,
                isFetching: false,
                fetchVoucherIssueReasons: localFetchVoucherIssueReasons,

                currentPage: DEFAULT_PAGE,
                totalPages: 1,
                perPage: DEFAULT_PAGE_SIZE,
                paginationParams: {
                    page: DEFAULT_PAGE,
                    limit: DEFAULT_PAGE_SIZE,
                    sort: DEFAULT_SORT,
                },

                voucherIssueReasonsForCurrentPage: voucherIssueReasonsForCurrentPage,
            }
        } else {
            return {
                initialFetchStarted: initialFetchStarted,
                isFetching: pagination.isFetching,
                fetchVoucherIssueReasons: localFetchVoucherIssueReasons,

                currentPage: pagination.params.page,
                totalPages: pagination.total,
                perPage: pagination.params.limit,
                paginationParams: pagination.params,

                voucherIssueReasonsForCurrentPage: voucherIssueReasonsForCurrentPage,
            }
        }
    }, [pagination, localFetchVoucherIssueReasons, initialFetchStarted, voucherIssueReasonsForCurrentPage])

    return result
}

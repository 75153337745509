import React from 'react'
import { Pagination } from 'antd'
import './PaginationTableBottomBar.css'

/**
 * @param {Object} props
 * @param {number} props.currentPage
 * @param {number} props.pageSize
 * @param {number} props.total
 * @param {(page: number, pageSize: number) => void} props.onChangePage
 * @returns {React.JSX.Element}
 * @constructor
 */
function PaginationTableBottomBar(props) {
    return (
        <div className="bottomBar">
            <Pagination
                current={props.currentPage}
                total={props.total}
                pageSize={props.pageSize}
                onChange={props.onChangePage}
            />
        </div>
    )
}

export default PaginationTableBottomBar

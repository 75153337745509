import { isValid, isRequired, isValidEmail } from './index'

export const isValidFirstName = isValid

export const isValidPersonEmail = (email) => (
    isRequired(email)
    && isValidEmail(email)
)

export const isValidLastName = isValid

export const isValidRole = isValid

export const isValidVenues = isRequired

export const isValidLegalEntities = isRequired

export const isValidUser = ({
    firstName,
    lastName,
    email,
    role,
    venues,
    legalEntities,
}) => (
    isValidFirstName(firstName)
    && isValidLastName(lastName)
    && isValidPersonEmail(email)
    && isValidRole(role)
    && isValidVenues(venues)
    && isValidLegalEntities(legalEntities)
)

export const isValidEditUser = (values) => (
    isValidFirstName(values.firstName)
    && isValidLastName(values.lastName)
    && isValidPersonEmail(values.email)
    && isValidRole(values.role)
    && isValidVenues(values.venues)
    && isValidLegalEntities(values.legalEntities)
)

export const getVenueIdsError = (errors) => (
    errors[Object.keys(errors).find((errorKey) => errorKey.includes('venueIds'))]
)

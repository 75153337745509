import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
    createVoucher,
    refreshPaginatedVouchers,
} from '../../../../../../store/actions/vouchers'
import { WithFormState } from '../../../../../util/forms/WithFormState'
import { toVoucherApiPostRequestBody } from '../../../../../../util/transformers/voucher'
import { notification } from 'antd'
import { getEntityFromResponse } from '../../../../../../util/index'
import dayjs from 'dayjs'

function createVoucherSuccess(voucher) {
    notification.success({
        message: 'voucher added!',
        description: (
            <span>
                voucher with code <strong>{voucher.coupon.code}</strong> was successfully created.
            </span>
        ),
    })
}

function createVoucherFailed(error) {
    notification.error({
        message: 'Failed to create voucher.',
        description: `${error.message}`,
    })
}

/**
 * @param {Object} props
 * @param {() => void} props.cancel
 * @param {(voucher: Object) => void} props.onCreateVoucherSuccess
 * @param {React.ReactElement | React.ReactElement[] | undefined} props.children
 * @param {number} props.activeLegalEntityId
 * @constructor
 */
export function CreateVoucherFormState(props) {
    const dispatch = useDispatch()
    const createVoucherCallback = useCallback(async (values) => {
        return dispatch(createVoucher(values))
    }, [dispatch])

    const onCreateVoucherSuccess = useCallback((response) => {
        // console.log(response)
        const voucher = getEntityFromResponse(response, 'vouchers')
        createVoucherSuccess(voucher)
        props.onCreateVoucherSuccess(voucher)
        dispatch(refreshPaginatedVouchers('overview', {page: 1}))
    }, [dispatch, props.onCreateVoucherSuccess])

    const initialValues = useMemo(() => {
        return {
            legalEntityId: typeof props.activeLegalEntityId === 'number' ? props.activeLegalEntityId : null,
            amount: 37.99,
            issuedReasonId: null,
            issuedInVenueId: null,
            expiryDate: dayjs().add(1, 'year').endOf('day'),
            comment: null,
            associatedOrderNumber: null,
            dealNumber: null,
        }
    }, [props.activeLegalEntityId])

    return (
        <WithFormState
            initialValues={initialValues}
            transformValuesToRequestBody={toVoucherApiPostRequestBody}
            onSendRequest={createVoucherCallback}
            onCancel={props.cancel}
            onRequestFailed={createVoucherFailed}
            onRequestSuccess={onCreateVoucherSuccess}
        >
            {props.children}
        </WithFormState>
    )
}

import React from 'react'
import CreateVenueFormState from 'components/pages/settings/venues/forms/create/CreateVenueFormState'
import CreateVenueModal from './CreateVenueModal'
import CreateVenueModalState from './CreateVenueModalState'

function CreateVenueModalWithState() {
    return (
        <CreateVenueModalState>
            {({ isOpen, closeModal }) => (
                <CreateVenueFormState
                    onCancel={closeModal}
                    onSaveSuccess={closeModal}
                >
                    {({
                        values,
                        errors,
                        isLoading,
                        handleChange,
                        handleChangeAsValue,
                        handleCancel,
                        handleSubmit,
                    }) => (
                        <CreateVenueModal
                            isOpen={isOpen}
                            onClose={closeModal}
                            values={values}
                            errors={errors}
                            isLoading={isLoading}
                            onChange={handleChange}
                            onChangeAsValue={handleChangeAsValue}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                        />
                    )}
                </CreateVenueFormState>
            )}
        </CreateVenueModalState>
    )
}

export default CreateVenueModalWithState

import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
    CalculatorOutlined,
    GiftOutlined,
    ShopOutlined,
    TeamOutlined,
    WalletOutlined,
    BankOutlined,
} from '@ant-design/icons'
import { Menu } from 'antd'
import { ROLES } from 'util/constants'
import './GlobalSettingsSideBarMenu.css'

function GlobalSettingsSideBarMenu({
    userRole,
    activeMenuItem,
}) {
    const menuItemsGroup = {
        type: 'group',
        label: (<h3>Settings</h3>),
        key: 'thePark',
        children: [],
    }
    const menuItems = [menuItemsGroup]
    const roleSlug = userRole.slug
    const adminOrLe = [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.FRANCHISE]

    if (adminOrLe.indexOf(roleSlug) !== -1) {
        menuItemsGroup.children.push({
            label: (
                <Link to="/settings/vouchers">
                    <WalletOutlined />
                    {' '}
                    Vouchers
                </Link>
            ),
            key: 'vouchers',
        })
        menuItemsGroup.children.push({
            label: (
                <Link to="/settings/coupons">
                    <WalletOutlined />
                    {' '}
                    Coupons
                </Link>
            ),
            key: 'coupons',
        })

        menuItemsGroup.children.push({
            label: (
                <Link to="/settings/coupons/batch">
                    <WalletOutlined />
                    {' '}
                    Coupons batch
                </Link>
            ),
            key: 'couponsBatch',
        })

        menuItemsGroup.children.push({
            label: (
                <Link to="/settings/gift-cards">
                    <GiftOutlined />
                    {' '}
                    Gift cards
                </Link>
            ),
            key: 'giftCards',
        })
    }

    if (roleSlug === ROLES.ADMIN) {
        menuItemsGroup.children.push({
            label: (
                <Link to="/settings/users">
                    <TeamOutlined />
                    {' '}
                    Users
                </Link>
            ),
            key: 'users',
        })

        menuItemsGroup.children.push({
            label: (
                <Link to="/settings/games">
                    <CalculatorOutlined />
                    {' '}
                    Games
                </Link>
            ),
            key: 'games',
        })

        menuItemsGroup.children.push({
            label: (
                <Link to="/settings/venues">
                    <ShopOutlined />
                    {' '}
                    Venues
                </Link>
            ),
            key: 'venues',
        })

        menuItemsGroup.children.push({
            label: (
                <Link to="/settings/legal-entities">
                    <BankOutlined />
                    {' '}
                    Legal Entities
                </Link>
            ),
            key: 'legalEntities',
        })

        menuItemsGroup.children.push({
            label: (
                <Link to="/settings/exports">
                    <BankOutlined />
                    {' '}
                    Exports
                </Link>
            ),
            key: 'exports',
        })
    }

    if (roleSlug === ROLES.FRANCHISE) {
        menuItemsGroup.children.push({
            label: (
                <Link to="/settings/coupons">
                    <WalletOutlined />
                    {' '}
                    Coupons
                </Link>
            ),
            key: 'coupons',
        })
        menuItemsGroup.children.push({
            label: (
                <Link to="/settings/exports">
                    <BankOutlined />
                    {' '}
                    Exports
                </Link>
            ),
            key: 'exports',
        })
    }

    return (
        <div className="settingsSideBarMenu">
            <div className="adminThePark">
                <Menu
                    mode="vertical"
                    selectedKeys={[activeMenuItem]}
                    style={{ borderRight: 0 }}
                    items={menuItems}
                />
            </div>
        </div>
    )
}

export default GlobalSettingsSideBarMenu

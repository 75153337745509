import React from 'react'
import { useSelector } from 'react-redux'
import { WithFormState } from 'components/util/forms/WithFormState'
import { getEntityFromResponse } from 'util/index'
import { isValidEditForm } from 'util/validation/booking'
import { getEditBookingErrors } from 'util/validation/bookingErrors'
import { toBookingApiRequest, mapBookingToValues } from 'util/transformers/booking'
import EditBookingRequest from '../../requests/EditBookingRequest'

/**
 * @component
 * @param {Object} props
 * @param {BookingResource} props.booking
 * @param {() => void} props.onCancel
 * @param {(updatedBooking: BookingResource) => void} props.onEditSuccess
 * @param {(props: WithFormStateChildProps) => React.Element|React.Element[]|null=} props.children
 * @returns {React.JSX.Element|null}
 */
export function EditBookingFormState(props) {
    const activeFilters = useSelector(state => state.activeFilters)

    if (props.booking === undefined) {
        return null
    }

    return (
        // gets the editBooking redux methods
        <EditBookingRequest>
            {({
                editBooking,
                editBookingSuccess,
                editBookingFailed,
            }) => (
                // creates the initial redux values states
                <WithFormState
                    key={props.booking.id}
                    initialValues={mapBookingToValues(props.booking)}
                    isValidForm={isValidEditForm}
                    getFormErrors={getEditBookingErrors}
                    transformValuesToRequestBody={toBookingApiRequest}
                    onSendRequest={(values) => editBooking(props.booking.id, { ...values, legalEntityId: activeFilters.legalEntityId})}
                    onCancel={props.onCancel}
                    onRequestSuccess={(response) => {
                        const updatedBooking = getEntityFromResponse(response, 'bookings')
                        editBookingSuccess(updatedBooking)
                        props.onEditSuccess(updatedBooking)
                    }}
                    onRequestFailed={editBookingFailed}
                >
                    {props.children}
                </WithFormState>
            )}
        </EditBookingRequest>
    )
}

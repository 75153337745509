export function toCreateGiftCardApiRequest(values) {
    const requestBody = {
        firstName: values.firstName,
        lastName: values.lastName,
        recipientName: values.recipientName,
        message: values.message,
        email: values.email,

        amount: values.amount,

        sendEmail: values.sendEmail,
        isCompany: values.isCompany,
        legalEntityId: values.legalEntityId,
        purchaseOrderNumber: values.purchaseOrderNumber,
    }

    return requestBody
}

export function toEditGiftCardApiRequest(values) {
    const requestBody = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        resendEmail: values.resendEmail,
        purchaseOrderNumber: values.purchaseOrderNumber,
    }

    return requestBody
}

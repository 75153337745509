import { useState } from 'react'
import { notification } from 'antd'
import callApi from 'store/middleware/callApi'
import store from 'store/reduxStore'
import { setGameSessionActive, setGameSessionInactive } from 'store/actions/gameSessions'
import { METHODS } from 'store/middleware/api'

function ToggleActiveGameSessionRequest({
    venueId,
    gameSessionRoomId,
    children,
}) {
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState([])

    // eslint-disable-next-line consistent-return
    const toggleActiveStatus = async (gameSessionId, activeInRoomId, checked) => {
        setIsLoading(true)
        const endPoint = `/venues/${venueId}/rooms/${activeInRoomId}/game-sessions/${gameSessionId}/toggle-active`
        try {
            await callApi(METHODS.PATCH, endPoint)
            setIsLoading(false)
            if (checked) {
                return store.dispatch(setGameSessionActive(gameSessionId, gameSessionRoomId))
            }
            return store.dispatch(setGameSessionInactive(gameSessionId))
        } catch (e) {
            setIsLoading(false)
            setErrors(e)
            notification.error({
                message: 'Something went wrong',
                description: e.message,
            })
        }
    }

    return (
        children({
            toggleActiveStatus,
            isLoading,
            errors,
        })
    )
}

export default ToggleActiveGameSessionRequest

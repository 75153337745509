import React from 'react'
import { Select } from 'antd'
import timezones from 'util/timezones'

function TimezoneSelect({
    value,
    onChange,
}) {
    return (
        <Select
            onChange={onChange}
            placeholder=""
            value={value}
        >
            {timezones.map((timezone) => (
                <Select.Option
                    key={timezone}
                    value={timezone}
                >
                    {timezone}
                </Select.Option>
            ))}
        </Select>
    )
}

export default TimezoneSelect

import React from 'react'
import { createCoupon, fetchCouponsExtendCurrentParams } from 'store/actions/coupons'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    createCoupon: async (values) => dispatch(createCoupon(values)),
    createCouponSuccess: (coupon) => {
        notification.success({
            message: 'Coupon code added!',
            description: (
                <span>
                    A new coupon&nbsp;
                    <strong>{coupon && coupon.code}</strong>
                    &nbsp;
                    was successfully created.
                </span>
            ),
        })
    },
    createCouponFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchCoupons: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchCouponsExtendCurrentParams(paginationKey))
    },
})

const CreateCouponRequest = withReduxState(null, mapDispatchToProps)

export default CreateCouponRequest

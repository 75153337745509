import React, { useMemo } from 'react'
import {
    DatePicker, TimePicker, Checkbox, Select,
    Alert,
} from 'antd'
import ExtraInfoLabel from 'components/util/forms/ExtraInfoLabel'
import { getDisabledMinutes } from 'util/index'
import { roomModes, APP_DATE_FORMAT } from 'util/constants'
import { useVenueGameRoomAvailability } from 'components/hooks/useVenueGameRoomAvailability'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import {BookingFormItem} from "./BookingFormItem"
import {RoomsSelect} from "../../../settings/games/selects/RoomsSelect"

/**
 * @param {object} bookableHours
 * @param {dayjs.Dayjs} selectedStartTime
 * @param {dayjs.Dayjs} date
 * @returns {*|boolean}
 */
function isOutsideBookableHours(bookableHours, selectedStartTime, date) {
    // console.log('selected start time:', selectedStartTime)
    if (typeof selectedStartTime === 'undefined' || selectedStartTime === null)
        return false

    const selectedDay = date.format('dddd').toLowerCase()
    // Start and end hours of room on weekday. E.g. { "10:00", "22:00" }
    const { start, end } = bookableHours[selectedDay]
    // console.log('start and end:', { start, end })
    // const startHour = dayjs.utc(start, APP_TIME_FORMAT)
    // const endHour = dayjs.utc(end, APP_TIME_FORMAT)
    // console.log('start and end hour:', { startHour, endHour })
    const startTime = selectedStartTime.format('HH:mm')
    return startTime < start || startTime > end

    // return selectedStartTime.isBefore(startHour) || selectedStartTime.isAfter(endHour)
}

const startOfCurrentDay = dayjs.utc().startOf("day")

/**
 * @param {dayjs.Dayjs|undefined|null} current
 * @returns {boolean}
 */
function checkIfDisabledDate(current) {
    // Can not select days before today
    return dayjs.isDayjs(current) && current.isBefore(startOfCurrentDay)
}


/**
 * @component
 * @param {object} props
 * @param {CreateOrEditBookingFormValues} props.values
 * @param {{ [Key in keyof CreateOrEditBookingFormValues]: string[]}} props.errors
 * @param {(newBookingStartDate: dayjs.Dayjs|null|undefined) => void } props.onChangeDate
 * @param {(newRoomId: number) => void} props.onChangeRoom
 * @param {(key: keyof CreateOrEditBookingFormValues, value: CreateOrEditBookingFormValues[Key]) => void } props.onChangeAsValue
 * @param {(e: any) => void} props.onChange
 * @param {boolean} props.isLoading
 * @param {number} props.minuteStep
 * @param {GameVenueResource[]} props.gameVenues
 * @returns {JSX.Element}
 */
export function BookingTimeSlotItems(props) {
    const activeFilters = useSelector((state) => state.activeFilters)
    const venueId = activeFilters.venueId
    const [availabilityData, availabilityErrors] = useVenueGameRoomAvailability({
        venueId: venueId,
        gameId: props.values.gameId,
        roomId: props.values.roomId,
        date: props.values.bookingStartDate,
        roomStart: props.values.bookingStartTime,
        roomMode: props.values.roomMode,
        addBuffersBefore: props.values.addBuffersBefore,
        addBuffersAfter: props.values.addBuffersAfter,
        playerAmount: props.values.playerAmount,
        // This can be undefined!
        bookingId: props.values.bookingId,
    })

    const hasSelectedGame = typeof props.values.gameId === "number"

    const selectedGameVenue = useMemo(() => {
        if (!hasSelectedGame)
            return undefined
        if (!Array.isArray(props.gameVenues))
            return undefined

        for (let i = 0, length = props.gameVenues.length; i < length; i++) {
            const gameVenue = props.gameVenues[i]
            if (gameVenue.gameId === props.values.gameId)
                return gameVenue
        }

        return undefined
    }, [hasSelectedGame, props.gameVenues, props.values.gameId])

    // Fetches available rooms from venue game data, if exists in redux state
    /** @type {RoomResource[]} */
    const availableGameRooms = useMemo(() => {
        if (!hasSelectedGame)
            return []

        for (let i = 0, length = props.gameVenues.length; i < length; i++) {
            const gameVenue =  props.gameVenues[i]
            if (gameVenue.gameId !== props.values.gameId)
                continue
            if (!Array.isArray(gameVenue.rooms))
                return []

            return gameVenue.rooms
        }

        return []
    }, [props.gameVenues, props.values.gameId, hasSelectedGame])

    // Fetches the selected room from available game rooms if not undefined
    /** @type {RoomResource|undefined} */
    const selectedRoom = useMemo(() => {
        return availableGameRooms.find(gameRoom => gameRoom.id === props.values.roomId)
    }, [availableGameRooms, props.values.roomId])

    /** @type {number[]} Gets all minutes (int[]) that should not be available as selectable options
     * in the time picker.
     * @see https://ant.design/components/time-picker#rangedisabledtime
     * */
    const disabledMinutes = useMemo(() => {
        if (typeof selectedRoom === 'undefined')
            return undefined

        const result = getDisabledMinutes(selectedRoom.startsAt, props.minuteStep)
        return result
    }, [selectedRoom, props.minuteStep])
    // console.log('values.roomStart:', values.roomStart)

    let showFormItems = typeof props.values.gameId === "number" &&
        typeof selectedGameVenue !== "undefined" &&
        typeof props.values.playerAmount === "number"
    let minPlayerAmountReached = true
    if (showFormItems && selectedGameVenue.minPlayers > props.values.playerAmount) {
        minPlayerAmountReached = false
        showFormItems = false
    }

    if (!showFormItems) {
        if (!minPlayerAmountReached) {
            return (
                <BookingFormItem>
                    <Alert
                        message="Not enough players"
                        description={`Minimum amount of players for ${selectedGameVenue.game.title} is ${selectedGameVenue.minPlayers}`}
                        type="warning"
                    />
                </BookingFormItem>
            )
        } else {
            return (
                <BookingFormItem>
                    <Alert
                        message="Select a vr experience"
                        description="Please select a vr experience first"
                        type="info"
                    />
                </BookingFormItem>
            )
        }
    }

    return (
        <>
            {
                typeof availabilityErrors !== 'undefined'
                && (
                    <BookingFormItem>
                        {
                            Object.values(availabilityErrors).map((v, i) => <Alert key={i} message={v} type="error" />)
                        }
                    </BookingFormItem>
                )
            }

            {
                typeof availabilityData !== 'undefined'
                && (
                    <BookingFormItem>
                        <Alert
                            message="Available!"
                            description={(
                                <>
                                    <span>Start time: {availabilityData.startTime}</span>
                                    <br />
                                    <span>End time: {availabilityData.endTime}</span>
                                    <br />
                                    <span>Amount of sessions: {availabilityData.amountOfSessions}</span>
                                </>
                            )}
                            type="info"
                        />
                    </BookingFormItem>
                )
            }

            <BookingFormItem
                isRequired
                label="Room"
                error={props.errors.roomId}
            >
                <RoomsSelect
                    roomdId={props.values.roomId}
                    rooms={availableGameRooms}
                    disabled={props.isLoading}
                    onChange={props.onChangeRoom}
                />
            </BookingFormItem>
            <BookingFormItem
                isRequired
                label="Date"
                error={props.errors.bookingStartDate}
            >
                <DatePicker
                    disabledDate={checkIfDisabledDate}
                    format={APP_DATE_FORMAT}
                    placeholder="Select day"
                    value={props.values.bookingStartDate}
                    onChange={props.onChangeDate}
                />
            </BookingFormItem>

            <BookingFormItem
                isRequired
                label="Arrival at"
                error={props.errors.roomStart}
                extra={(
                    <>
                        <ExtraInfoLabel
                            message="This is the time the guests will arrive at The Park."
                        />
                        {/* eslint-disable-next-line react/no-unstable-nested-components */}
                        {(() => {
                            if (typeof selectedRoom !== 'undefined'
                                && dayjs.isDayjs(props.values.bookingStartDate)
                                && dayjs.isDayjs(props.values.bookingStartTime)
                                && isOutsideBookableHours(selectedRoom.bookableHours, props.values.bookingStartTime, props.values.bookingStartDate)
                            ) {
                                return (
                                    <ExtraInfoLabel
                                        message="The selected time slot is outside the bookable hours"
                                        type="warning"
                                    />
                                )
                            }
                            return null
                        })()}
                    </>
                )}
            >
                <TimePicker
                    disabled={typeof selectedRoom === "undefined" || !dayjs.isDayjs(props.values.bookingStartDate)}
                    format="HH:mm"
                    placeholder="Select time"
                    disabledTime={() => ({
                        disabledMinutes: () => disabledMinutes,
                    })}
                    hideDisabledOptions
                    value={props.values.bookingStartTime}
                    onChange={(time) => {
                        const minute = time.minute
                        // Check if it's not a disables minute -> can happen when manually typing input
                        if (disabledMinutes.indexOf(minute) !== -1) {
                            props.onChangeAsValue('bookingStartTime', props.values.bookingStartTime)
                        } else {
                            props.onChangeAsValue('bookingStartTime', time)
                        }
                    }}
                    onSelect={(time) => {
                        const minute = time.minute
                        // Check if it's not a disables minute -> can happen when manually typing input
                        if (disabledMinutes.indexOf(minute) !== -1) {
                            props.onChangeAsValue('bookingStartTime', props.values.bookingStartTime)
                        } else {
                            props.onChangeAsValue('bookingStartTime', time)
                        }
                    }}
                />
            </BookingFormItem>

            <BookingFormItem
                label="Game session distribution mode"
                extra={<ExtraInfoLabel message="Defines how (multiple) game session(s) will be distributed over venue room(s)." />}
            >
                <Select
                    value={props.values.roomMode}
                    onChange={(value) => props.onChangeAsValue('roomMode', value)}
                >
                    {
                        Object.values(roomModes).map((roomMode) => (
                            <Select.Option
                                key={roomMode.value}
                                value={roomMode.value}
                            >
                                {roomMode.text}
                            </Select.Option>
                        ))
                    }
                </Select>
            </BookingFormItem>

            <BookingFormItem
                label="Add buffers before?"
                extra={<ExtraInfoLabel message="If selected, creates buffers before earliest timeslot(s) of booking" />}
            >
                <Checkbox
                    name="addBuffersBefore"
                    checked={props.values.addBuffersBefore}
                    onChange={props.onChange}
                />
            </BookingFormItem>

            <BookingFormItem
                label="Add buffers after?"
                extra={<ExtraInfoLabel message="If selected, creates buffers after latest timeslot(s) of booking" />}
            >
                <Checkbox
                    name="addBuffersAfter"
                    checked={props.values.addBuffersAfter}
                    onChange={props.onChange}
                />
            </BookingFormItem>
        </>
    )
}

import React from 'react'
import CreateBookingModal from './CreateBookingModal'
import { useLegalEntity } from '../../../../hooks/useLegalEntity'
import {CreateBookingModalState} from "./CreateBookingModalState"
import {CreateBookingFormState} from "../../forms/create/CreateBookingFormState"

export function CreateBookingModalWithState() {
    const legalEntity = useLegalEntity()
    if (typeof legalEntity === 'undefined')
        throw new Error(`Legal Entity is undefined! Comp: ${CreateBookingModalWithState.name}`)
    return (
        <CreateBookingModalState>
            {({ isOpen, closeModal }) => {
                if (!isOpen)
                    return null

                return (
                    <CreateBookingFormState onCancel={closeModal} onCreateSuccess={closeModal}>
                        {(formProps) => (
                            <CreateBookingModal
                                isOpen={isOpen}
                                onClose={closeModal}
                                values={formProps.values}
                                errors={formProps.errors}
                                isLoading={formProps.isLoading}
                                onChange={formProps.handleChange}
                                onChangeAsValue={formProps.handleChangeAsValue}
                                onCancel={formProps.handleCancel}
                                onSubmit={formProps.handleSubmit}
                                mergeValues={formProps.mergeValues}
                                priceSystem={legalEntity.priceSystem}
                            />
                        )}
                    </CreateBookingFormState>

                )
            }}
        </CreateBookingModalState>
    )
}

import React from 'react'
import { getEntityFromResponse } from 'util/index'
import { WithFormState } from 'components/util/forms/WithFormState'
import DeleteGameRequest from './DeleteGameRequest'

function DeleteGameFormState({
    onCancel,
    onDeleteSuccess,
    values,
    children,
}) {
    return (
        <DeleteGameRequest paginationKey="overview">
            {({
                deleteGame,
                fetchGames,
                deleteGameSuccess,
                deleteGameFailed,
            }) => (
                <WithFormState
                    transformValuesToRequestBody={() => values}
                    onSendRequest={() => deleteGame(values)}
                    onCancel={onCancel}
                    onRequestSuccess={(response) => {
                        const game = getEntityFromResponse(response, 'games')
                        fetchGames()
                        deleteGameSuccess(game)
                        onDeleteSuccess(game)
                    }}
                    onRequestFailed={deleteGameFailed}
                >
                    {children}
                </WithFormState>
            )}
        </DeleteGameRequest>
    )
}

export default DeleteGameFormState

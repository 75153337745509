import React from 'react'
import EditPriceAdjustmentFormState from
    'components/pages/settings/venue-games/forms/edit-price-adjustment/EditPriceAdjustmentFormState'
import { useLegalEntity } from 'components/hooks/useLegalEntity'
import EditPriceAdjustmentModal from './EditPriceAdjustmentModal'
import EditPriceAdjustmentModalState from './EditPriceAdjustmentModalState'

function EditPriceAdjustmentModalWithState() {
    const legalEntity = useLegalEntity()
    if (typeof legalEntity === 'undefined')
        throw new Error(`Legal Entity is undefined! Comp: ${EditPriceAdjustmentModalWithState.name}`)

    return (
        <EditPriceAdjustmentModalState>
            {({
                isOpen,
                closeModal,
                priceAdjustment,
            }) => (
                <EditPriceAdjustmentFormState
                    onEditSuccess={closeModal}
                    onCancel={closeModal}
                    priceAdjustment={priceAdjustment}
                    priceSystem={legalEntity.priceSystem}
                >
                    {({
                        values,
                        errors,
                        isLoading,
                        mergeValues,
                        handleChange,
                        handleChangeAsValue,
                        handleCancel,
                        handleSubmit,
                    }) => (
                        <EditPriceAdjustmentModal
                            isOpen={isOpen}
                            onClose={closeModal}
                            priceAdjustment={values}
                            errors={errors}
                            isLoading={isLoading}
                            onChange={handleChange}
                            onChangeAsValue={handleChangeAsValue}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                            mergeValues={mergeValues}
                            priceSystem={legalEntity.priceSystem}
                        />
                    )}
                </EditPriceAdjustmentFormState>
            )}
        </EditPriceAdjustmentModalState>
    )
}
export default EditPriceAdjustmentModalWithState

import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import { MODAL_IDS } from 'util/constants'

function EditGameModalState({ children, user }) {
    return (
        <WithActiveModal
            modalId={MODAL_IDS.EDIT_USER}
            entity={user}
            entityKey="user"
        >
            {children}
        </WithActiveModal>
    )
}

export default EditGameModalState

// eslint-disable-next-line import/no-cycle
import { getPaginationKey } from 'store/reducers/pagination/bookingsPaginationReducer'
import { CALL_API, METHODS } from '../middleware/api'
import {
    bookingSchema,
    bookingSchemaArray,
} from '../schemas/index'
import { REMOVE_PAGINATION } from './index'

export const BOOKING_REQUEST = 'BOOKING_REQUEST'
export const BOOKING_SUCCESS = 'BOOKING_SUCCESS'
export const BOOKING_FAILURE = 'BOOKING_FAILURE'

export const BOOKINGS_REQUEST = 'BOOKINGS_REQUEST'
export const BOOKINGS_SUCCESS = 'BOOKINGS_SUCCESS'
export const BOOKINGS_FAILURE = 'BOOKINGS_FAILURE'

export const SET_BOOKING_ACTIVE = 'SET_BOOKING_ACTIVE'
export const SET_BOOKING_INACTIVE = 'SET_BOOKING_INACTIVE'

export const fetchBookings = (componentName, venueId, params) => ({
    componentName,
    venueId,
    [CALL_API]: {
        types: [
            BOOKINGS_REQUEST,
            BOOKINGS_SUCCESS,
            BOOKINGS_FAILURE,
        ],
        endpoint: `/venues/${venueId}/bookings`,
        schema: bookingSchemaArray,
        params,
    },
})

export const fetchBookingsForActiveVenue = (componentName, params) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch(fetchBookings(componentName, venueId, params))
}

export const fetchBookingsExtendCurrentParams = (paginationKey, params) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    const bookingPagination = getState().pagination.bookings[getPaginationKey(paginationKey, venueId)]
    const newParams = {
        ...bookingPagination.params,
        ...params,
    }
    return dispatch(fetchBookings(paginationKey, venueId, newParams))
}

export const createBooking = (venueId, body) => ({
    [CALL_API]: {
        method: METHODS.POST,
        types: [
            BOOKING_REQUEST,
            BOOKING_SUCCESS,
            BOOKING_FAILURE,
        ],
        endpoint: `/venues/${venueId}/bookings`,
        schema: bookingSchema,
        body,
    },
})

export const createBookingForActiveVenue = (body) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch(createBooking(venueId, body))
}

export const editBooking = (venueId, bookingId, body) => ({
    [CALL_API]: {
        method: METHODS.PUT,
        types: [
            BOOKING_REQUEST,
            BOOKING_SUCCESS,
            BOOKING_FAILURE,
        ],
        endpoint: `/venues/${venueId}/bookings/${bookingId}`,
        schema: bookingSchema,
        body,
    },
})
export const fetchAuditBooking = (venueId, bookingId, body) => ({
    [CALL_API]: {
        method: METHODS.GET,
        types: [
            BOOKING_REQUEST,
            BOOKING_SUCCESS,
            BOOKING_FAILURE,
        ],
        endpoint: `/venues/${venueId}/bookings/${bookingId}/audit-logs`,
        schema: bookingSchema,
        body,
    },
})

export const editBookingForActiveVenue = (bookingId, body) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch(editBooking(venueId, bookingId, body))
}

export const fetchAuditBookingForActiveVenue = (bookingId, body) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch(fetchAuditBooking(venueId, bookingId, body))
}

export const setBookingActive = (bookingId) => ({
    type: SET_BOOKING_ACTIVE,
    bookingId,
})

export const setBookingInactive = (bookingId) => ({
    type: SET_BOOKING_INACTIVE,
    bookingId,
})

export const removeBookingsFromState = (componentName) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch({
        type: REMOVE_PAGINATION,
        fullPaginationKey: getPaginationKey(componentName, venueId),
        entity: 'bookings',
    })
}

export const deleteBooking = (venueId, bookingId) => ({
    [CALL_API]: {
        method: METHODS.DELETE,
        types: [
            BOOKING_REQUEST,
            BOOKING_SUCCESS,
            BOOKING_FAILURE,
        ],
        endpoint: `/venues/${venueId}/bookings/${bookingId}`,
        schema: bookingSchema,
        entityId: bookingId,
    },
})

export const deleteBookingForActiveVenue = (bookingId) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch(deleteBooking(venueId, bookingId))
}

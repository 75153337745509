import React from 'react'
import Mount from 'components/util/Mount'
import PaginationTableBottomBar from 'components/util/table/PaginationTableBottomBar'
import {
    fromToday,
    tillFuture,
    getStartOfDay,
    getEndOfDay,
} from 'util/index'
import dayjs from 'dayjs'
import ActiveGameSessionInfo from './ActiveGameSessionInfo'
import GameSessionsRequest from '../requests/GameSessionsRequest'
import GameSessionsTable from './GameSessionsTable'
import GameSessionsTableTopBar, {
    ALL,
    DAY,
    FUTURE,
    SEARCH_BAR_PAGINATION_KEY,
} from './GameSessionsTableTopBar'
import { BACKEND_DATE_TIME_FORMAT } from '../../../../util/constants'

const PAGE_SIZE = 25

function GameSessionsTableWithSessions({ paginationKey }) {
    return (
        <GameSessionsRequest
            paginationKey={paginationKey}
            limit={PAGE_SIZE}
        >
            {({
                isFetching,
                currentPage,
                total,
                params,
                gameSessionsForCurrentPage,
                fetchGameSessions,
                removeSearchBookings,
            }) => {
                let activeDateFilter = ALL
                let activeDateValue = null
                const filterFromParams = (...filters) => (
                    Object.keys(params)
                        .filter((key) => !filters.includes(key))
                        .reduce((acc, curr) => {
                            acc[curr] = params[curr]
                            return acc
                        }, {})
                )
                const fetchFilteredSessions = (date = new Date()) => {
                    fetchGameSessions({
                        from: getStartOfDay(date),
                        till: getEndOfDay(date),
                    })
                }
                const fetchFutureSessions = () => {
                    fetchGameSessions({
                        from: fromToday,
                        till: undefined,
                        // till: tillFuture,
                    })
                }
                const searchSessionsForBooking = (bookingId) => {
                    if (typeof bookingId === 'undefined') {
                        fetchGameSessions({ ...filterFromParams('bookingId', 'page') })
                        removeSearchBookings(SEARCH_BAR_PAGINATION_KEY)
                    } else {
                        fetchGameSessions({ ...filterFromParams('page'), bookingId })
                    }
                }
                const { from, till } = params
                // console.log('Params:', params)
                if (typeof from === 'undefined' && typeof till === 'undefined' && from !== null && till !== null) {
                    activeDateFilter = ALL
                } else if (from === fromToday.format(BACKEND_DATE_TIME_FORMAT) && (typeof till === 'undefined' || till === null)) {
                    activeDateFilter = FUTURE
                } else {
                    activeDateValue = dayjs.utc(from)
                    activeDateFilter = DAY
                }
                return (
                    <Mount didMount={() => fetchFilteredSessions()}>
                        <ActiveGameSessionInfo />
                        <GameSessionsTableTopBar
                            activeDateValue={activeDateValue}
                            activeDateFilter={activeDateFilter}
                            searchValue={params.bookingId}
                            onSelectAllGameSessions={fetchGameSessions}
                            onSelectFutureGameSessions={fetchFutureSessions}
                            onSelectDate={(date) => {
                                if (date === null) {
                                    fetchFutureSessions()
                                } else {
                                    fetchFilteredSessions(date)
                                }
                            }}
                            onSearch={searchSessionsForBooking}
                            onRemoveSearchBookings={() => {
                                removeSearchBookings(SEARCH_BAR_PAGINATION_KEY)
                            }}
                        />
                        <GameSessionsTable
                            gameSessions={gameSessionsForCurrentPage}
                            isFetching={isFetching}
                            onSort={(sort) => {
                                if (sort !== null) {
                                    fetchGameSessions({ ...params, sort })
                                } else {
                                    fetchGameSessions({ ...params })
                                }
                            }}
                        />
                        <PaginationTableBottomBar
                            currentPage={currentPage}
                            total={total}
                            pageSize={params.limit || PAGE_SIZE}
                            onChangePage={(page) => fetchGameSessions({
                                ...params,
                                page,
                            })}
                        />
                    </Mount>
                )
            }}
        </GameSessionsRequest>
    )
}

export default GameSessionsTableWithSessions

/* eslint-disable nonblock-statement-body-position */
/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-tabs */
/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react'
import callApi from 'store/middleware/callApi'
import dayjs from 'dayjs'
import { APP_DATE_FORMAT, APP_TIME_FORMAT } from '../../util/constants'

// Params
// venueId				int			Id of the venue
// gameId				int			Id of the game
// roomId				int			Id of the room
// date					moment		Date to check for
// roomStart			moment		Start time of experience to check for
// roomMode				string		String value that represents what the game session distribution over rooms should be
// addBuffersBefore		boolean		Should buffers be added before the first session(s)?
// addBuffersAfter		boolean		Should buffers be added after the last session(s)?
// playerAmount			int			Amount of players
// bookingId			int			Id of previous booking. Used to ignore game sessions of this booking in availability check when updating

function paramsValid(params) {
    if (params.venueId === undefined || params.venueId === null) return false
    if (params.gameId === undefined || params.gameId === null) return false
    if (params.roomId === undefined || params.roomId === null) return false
    if (params.date === undefined || params.date === null) return false
    if (params.roomStart === undefined || params.roomStart === null) return false
    if (params.roomMode === undefined || params.roomMode === null) return false
    if (params.addBuffersBefore === undefined || params.addBuffersBefore === null) return false
    if (params.addBuffersAfter === undefined || params.addBuffersAfter === null) return false
    if (params.playerAmount === undefined || params.playerAmount === null || params.playerAmount === 0) return false

    return true
}

/** Executes api call to check for game/room availability in venue with given parameters */
export function useVenueGameRoomAvailability(params) {
    const [state, setState] = useState({
        availabilityData: undefined,
        errors: undefined,
    })
    useEffect(() => {
        const valid = paramsValid(params)
        if (!valid) {
            if (state.availabilityData !== undefined) {
                setState({
                    ...state,
                    availabilityData: undefined,
                    errors: undefined,
                })
            }

            // eslint-disable-next-line no-useless-return
            return
        }

        async function fetchAvailability() {
            const dateString = params.date.format(APP_DATE_FORMAT)
            const timeString = params.roomStart.format(APP_TIME_FORMAT)
            const finalDate = `${dateString} ${timeString}`
            const urlParams = {
                date: finalDate,
                playerAmount: params.playerAmount,
                isPrivate: true,
                addBuffersBefore: params.addBuffersBefore,
                addBuffersAfter: params.addBuffersAfter,
                roomMode: params.roomMode,
            }

            if (params.bookingId !== undefined) {
                urlParams.bookingId = params.bookingId
            }

            try {
                const endpoint = `/venues/${params.venueId}/availability/rooms/${params.roomId}/games/${params.gameId}`
                const result = await callApi('GET', endpoint, urlParams)
                setState({
                    availabilityData: {
                        amountOfSessions: result.amountOfSessions,
                        startTime: result.startTime,
                        endTime: result.endTime,
                        status: result.status,
                        sessions: result.sessions,
                    },
                    errors: undefined,
                })
            } catch (e) {
                setState({
                    ...state,
                    availabilityData: undefined,
                    errors: e.errors,
                })
            }
        }

        fetchAvailability()
    }, [
        params.venueId,
        params.roomId,
        params.gameId,
        params.date,
        params.roomStart,
        params.roomMode,
        params.playerAmount,
        params.addBuffersAfter,
        params.addBuffersBefore,
    ])

    return [state.availabilityData, state.errors]
}

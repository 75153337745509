import React from 'react'
import { createUser, fetchUsersExtendCurrentParams } from 'store/actions/users'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    createUser: async (values) => dispatch(createUser(values)),
    createUserSuccess: (user) => {
        notification.success({
            message: 'User added!',
            description: (
                <span>
                    A new user&nbsp;
                    <strong>{user && `${user.firstName} ${user.lastName}`}</strong>
                    &nbsp;
                    was successfully created.
                </span>
            ),
        })
    },
    createUserFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchUsers: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchUsersExtendCurrentParams(paginationKey))
    },
})

const CreateUserRequest = withReduxState(null, mapDispatchToProps)

export default CreateUserRequest

import { notification } from 'antd'
import { editBookingForActiveVenue } from 'store/actions/bookings'
import withReduxState from 'components/hoc/withReduxState'

const mapDispatchToProps = (dispatch) => ({
    editBooking: (bookingId, body) => dispatch(editBookingForActiveVenue(bookingId, body)),
    editBookingSuccess: (/** BookingResource */ booking) => {
        notification.success({
            message: 'Booking edited!',
            description: `Booking for ${booking.bookerEmail} was successfully edited.`,
        })
    },
    editBookingFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
})

const EditBookingRequest = withReduxState(null, mapDispatchToProps)

export default EditBookingRequest

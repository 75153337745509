import createPaginationReducer from '../hor/createPaginationReducer'
import {
    DISCOUNT_BANNERS_REQUEST,
    DISCOUNT_BANNERS_SUCCESS,
    DISCOUNT_BANNERS_FAILURE,
} from '../../actions/discountBanners'

const discountBannersPaginationReducer = createPaginationReducer({
    mapActionToKey: (action) => action.viewName,
    types: [
        DISCOUNT_BANNERS_REQUEST,
        DISCOUNT_BANNERS_SUCCESS,
        DISCOUNT_BANNERS_FAILURE,
    ],
})

export default discountBannersPaginationReducer

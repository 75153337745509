import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import EditUserRequest from 'components/pages/settings/users/forms/edit/EditUserRequest'
import { isValidEditUser } from 'util/validation/user'
import { getEditUserErrors } from 'util/validation/userErrors'
import { getEntityFromResponse } from 'util/index'
import { toUserApiRequest, mapUserToValues } from 'util/transformers/user'

function EditUserFormState({
    onCancel,
    onEditSuccess,
    children,
    user,
}) {
    return (
        <EditUserRequest paginationKey="overview" user={user}>
            {({
                fetchUsers,
                editUser,
                editUserSuccess,
                editUserFailed,
            }) => {
                if (user === null || typeof user === 'undefined') {
                    return null
                }
                return (
                    <WithFormState
                        key={user.id}
                        initialValues={mapUserToValues(user)}
                        isValidForm={isValidEditUser}
                        getFormErrors={getEditUserErrors}
                        transformValuesToRequestBody={toUserApiRequest}
                        onSendRequest={(values) => editUser(values)}
                        onCancel={onCancel}
                        onRequestSuccess={(response) => {
                            const updatedUser = getEntityFromResponse(response, 'users')
                            fetchUsers()
                            editUserSuccess(updatedUser)
                            onEditSuccess(updatedUser)
                        }}
                        onRequestFailed={editUserFailed}
                    >
                        {children}
                    </WithFormState>
                )
            }}
        </EditUserRequest>
    )
}

export default EditUserFormState

import React, { useState, useCallback } from 'react'
import { PoweroffOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Popover, Button } from 'antd'
import WithMe from 'components/pages/authorized/WithMe'
import { useAppState } from 'components/hooks/useAppState'
import useApi, { METHODS } from 'components/hooks/useApi'
import { useNavigate } from 'react-router'
import { useActiveFilters } from '../hooks/useActiveFilters'

function TopBarUserMenu() {
    const [isLoggingOut, setIsLoggingOut] = useState(false)
    const { logout } = useAppState()
    const [requestLogout] = useApi('/logout', { method: METHODS.POST })
    const { handleSetActiveVenue } = useActiveFilters()
    const navigate = useNavigate()

    const onLogoutClick = useCallback(async () => {
        setIsLoggingOut(true)
        await requestLogout()
        logout()
        handleSetActiveVenue(null)

        navigate('/')
    }, [handleSetActiveVenue, logout, navigate, requestLogout])

    return (
        <WithMe>
            {({ firstName, lastName, role }) => (
                <Popover
                    title={`${firstName} ${lastName}`}
                    trigger="click"
                    content={(
                        <div>
                            <p>{role.label}</p>
                            <Button
                                icon={<PoweroffOutlined />}
                                loading={isLoggingOut}
                                onClick={onLogoutClick}
                            >
                                Logout
                            </Button>
                        </div>
                    )}
                    overlayClassName="fixed"
                    placement="bottom"
                >
                    <Avatar icon={<UserOutlined />} className="cursor-pointer" />
                </Popover>
            )}
        </WithMe>
    )
}

export default TopBarUserMenu

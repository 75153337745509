import {useCallback, useMemo} from "react"
import callApi from "../../../store/middleware/callApi"
import {isValidString} from "../../../util/string-utils"
import {removeNullOrUndefinedValuesFromObject} from "../../../util/object-utils"

/**
 * @typedef {Object} BookingPricingAPIResponseBody
 * @property {v1BookingPriceOutputDataResource} newBookingPriceOutputData
 * @property {v1BookingPriceOutputDataResource|null=} originalBookingPriceOutputData
 * @property {number|null=} amountAlreadyPaid
 */

/**
 * @typedef {Object} BookingPricingAPIRequestQueryParams
 * @property {number=} bookingId Pass in case of updating an existing booking
 * @property {number} venueId Id of the venue
 * @property {number} gameId Id of the game
 * @property {number} playerAmount Number of players
 * @property {boolean} isForcedPrivate Is the booking private by request of the customer?
 * @property {string} bookingStartDateTime Start date and time of the booking
 *
 * Company details
 * @property {boolean} isCompanyBooking Is the booking made by a company?
 *
 * Discounts
 * @property {number=} socialDealFixedAmount Fixed amount booking cost
 * @property {number=} socialDealDiscountPercentage Fixed percentage discount applied to booking
 *
 * Upselling
 * @property {boolean=} addOpenBar Add open bar to the booking
 * @property {boolean=} addDrinks Add drinks to the booking
 * @property {boolean=} addNachos Add nachos to the booking
 * @property {boolean=} addDrinkTokens Add drink tokens to the booking
 */

/**
 * @typedef {(pricingResponseBody: BookingPricingAPIResponseBody | null, errorMessage: string | null) => void} GetBookingPricingCallback
 */


/**
 * @typedef {Object} UseBookingPricingAPIReturnObj
 * @property {(queryParams: BookingPricingAPIRequestQueryParams, callback: GetBookingPricingCallback) => Promise<void>} getBookingPricing
 */

/**
 * @returns {UseBookingPricingAPIReturnObj}
 */
export function useBookingPricingAPI() {
    /**
     * @type {(function(queryParams: BookingPricingAPIRequestQueryParams, callback: GetBookingPricingCallback): Promise<void>)|*}
     */
    const getBookingPricing = useCallback(async (queryParams, callback) => {
        const endpoint = "/booking-pricing"
        const bodyValid = validatePricingRequestQueryParams(queryParams)
        if (!bodyValid)
            return

        const finalQueryParams= removeNullOrUndefinedValuesFromObject(queryParams)

        try {
            const response = await callApi(
                "GET",
                endpoint,
                finalQueryParams,
            )
            callback(response.data, null)
        } catch (error) {
            console.error(error)
            callback(null, error)
        }
    }, [])

    const returnObj = useMemo(() => {
        return {
            getBookingPricing: getBookingPricing,
        }
    }, [getBookingPricing])

    return returnObj
}

/**
 * @param {BookingPricingAPIRequestQueryParams} queryParams
 * @returns {boolean}
 */
function validatePricingRequestQueryParams(queryParams) {
    if (typeof queryParams.gameId !== "number")
        return false
    if (typeof queryParams.venueId !== "number")
        return false
    if (typeof queryParams.playerAmount !== "number")
        return false
    if (!isValidString(queryParams.bookingStartDateTime))
        return false

    return true
}
import React from 'react'
import { Modal } from 'antd'
import { ModalFooter } from 'components/util/modals/ModalFooter'
import CreatePriceAdjustmentForm from '../../forms/create-price-adjustment/CreatePriceAdjustmentForm'

function CreatePriceAdjustmentModal({
    isOpen,
    onClose,
    values,
    errors,
    isLoading,
    mergeValues,
    onChangeAsValue,
    onCancel,
    onSubmit,
    priceSystem,
}) {
    return (
        <Modal
            title="New price adjustment for this game"
            open={isOpen}
            onOk={onSubmit}
            onCancel={onClose}
            closable={!isLoading}
            maskClosable={!isLoading}
            footer={(
                <ModalFooter
                    isLoading={isLoading}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                />
            )}
        >
            {isOpen
      && (
          <CreatePriceAdjustmentForm
              isLoading={isLoading}
              values={values}
              errors={errors}
              mergeValues={mergeValues}
              onChangeAsValue={onChangeAsValue}
              //   priceSystem={priceSystem}
          />
      )}
        </Modal>
    )
}
export default CreatePriceAdjustmentModal

import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import CreateEquipmentSetRequest
    from 'components/pages/settings/equipment-sets/forms/create/CreateEquipmentSetRequest'
import { getEntityFromResponse } from 'util/index'
import { isValidEquipmentSet } from 'util/validation/equipmentSet'
import { getEquipmentSetErrors } from 'util/validation/equipmentSetErrors'
import { toEquipmentSetApiRequest } from 'util/transformers/equipmentSet'

function CreateEquipmentSetFormState({
    onCancel,
    onCreateSuccess,
    children,
}) {
    return (
        <CreateEquipmentSetRequest paginationKey="overview">
            {({
                createEquipmentSet,
                createEquipmentSetSuccess,
                createEquipmentSetFailed,
                fetchEquipmentSets,
            }) => (
                <WithFormState
                    initialValues={{
                        name: '',
                        uid: '',
                    }}
                    isValidForm={isValidEquipmentSet}
                    getFormErrors={getEquipmentSetErrors}
                    transformValuesToRequestBody={toEquipmentSetApiRequest}
                    onSendRequest={(values) => createEquipmentSet(values)}
                    onCancel={onCancel}
                    onRequestSuccess={(response) => {
                        const equipmentSet = getEntityFromResponse(response, 'equipmentSets')
                        fetchEquipmentSets()
                        createEquipmentSetSuccess(equipmentSet)
                        onCreateSuccess(equipmentSet)
                    }}
                    onRequestFailed={createEquipmentSetFailed}
                >
                    {children}
                </WithFormState>
            )}
        </CreateEquipmentSetRequest>
    )
}

export default CreateEquipmentSetFormState

import React from 'react'
import './GameSessionPlayerEntry.css'
import {Button, Input} from "antd"
import {DeleteOutlined} from "@ant-design/icons"
import {MAX_NICKNAME_LENGTH} from "../../../../util/constants"

/**
 * @component
 * @param props
 * @param {number} props.index
 * @param {GameSessionPlayersState} props.gsState
 * @param {GameSessionPlayerOrNull} props.gamePlayer
 * @param {boolean} props.isDisabled
 * @returns {JSX.Element}
 */
export function GameSessionPlayerEntry(props) {
    function updatePlayer(e) {
        /** @type string */
        const nickname= e.target.value

        if (nickname.length === 0) {
            props.gsState.removeGamePlayer(props.index)
        } else {
            props.gsState.updateGamePlayerNickname(props.index, nickname)
        }
    }

    const nickNameValid = props.gamePlayer !== null && props.gamePlayer.nickname.length > 1
    const isCustomerProfile = props.gamePlayer !== null && typeof props.gamePlayer.customerProfileId === "number"
    const btnDisabled = !nickNameValid || props.isDisabled || props.isProcessingRequest
    let buttonClassName = "ant-btn-no-border"
    if (btnDisabled)
        buttonClassName += " gsp-gray-btn-disabled"
    else
        buttonClassName += " gsp-btn-active"

    /** @type {React.CSSProperties | undefined} */
    let iconStyles = undefined
    if (!btnDisabled) {
        iconStyles = {color: "white"}
    }

    let numberClassName = "txta-right gsp-entry-number"
    if (nickNameValid)
        numberClassName += " gsp-entry-number-active"

    return (
        <>
            <div className={numberClassName}>
                #{props.index + 1}
            </div>
            <Input
                disabled={isCustomerProfile || props.isDisabled}
                placeholder="Free slot. Enter player name (min 2 chaacters)"
                value={props.gamePlayer?.nickname}
                status={props.gamePlayer !== null && nickNameValid === false && "error"}
                maxLength={MAX_NICKNAME_LENGTH}
                onChange={updatePlayer}
            />
            <Button
                // ghost={true}
                type="primary"
                // danger={true}
                // shape="circle"
                tabIndex={-1}
                className={buttonClassName}
                icon={<DeleteOutlined style={iconStyles}/>}
                disabled={btnDisabled}
                // style={{ color: theme.colorSecondary, borderColor: theme.colorSecondary }}
                onClick={() => props.gsState.removeGamePlayer(props.index)}
            />
        </>
    )
}

import React from 'react'
import { Modal } from 'antd'
import { ModalFooter } from 'components/util/modals/ModalFooter'
import CouponBatchTimeFrameForm from '../../forms/create-batch-timeframes/CouponBatchTimeFrameForm'
import CreateCouponBatchTimeFrameFormState from '../../forms/create-batch-timeframes/CreateCouponBatchTimeFrameFormState'

function CreateCouponBatchTimeFrameModalWithState({
    isOpen,
    onClose,
    couponBatch,
}) {
    return (
        <CreateCouponBatchTimeFrameFormState
            onCancel={onClose}
            onCreateSuccess={onClose}
            couponBatch={couponBatch}
        >
            {({
                values,
                errors,
                isLoading,
                mergeValues,
                handleChangeAsValue,
                handleCancel,
                handleSubmit,
            }) => (
                <Modal
                    title="New valid timeframe for this coupon batch"
                    open={isOpen}
                    onOk={handleSubmit}
                    onCancel={onClose}
                    closable={!isLoading}
                    maskClosable={!isLoading}
                    footer={(
                        <ModalFooter
                            isLoading={isLoading}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                        />
                    )}
                >
                    {isOpen && (
                        <CouponBatchTimeFrameForm
                            isLoading={isLoading}
                            values={values}
                            errors={errors}
                            mergeValues={mergeValues}
                            onChangeAsValue={handleChangeAsValue}
                        />
                    )}
                </Modal>
            )}
        </CreateCouponBatchTimeFrameFormState>
    )
}

export default CreateCouponBatchTimeFrameModalWithState

import React from 'react'
import { connect } from 'react-redux'

export default function withReduxState(mapStateToProps, mapDispatchToProps, mergeProps) {
    function WithReduxState({
        children,
        ...props
    }) {
        return (
            <>
                {children(props)}
            </>
        )
    }
    const ConnectedWithReduxState = connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps,
    )(WithReduxState)

    ConnectedWithReduxState.displayName = 'WithReduxState'

    return ConnectedWithReduxState
}

import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import CreateCouponRequest from 'components/pages/settings/coupons/forms/create/CreateCouponRequest'
import { isValidCoupon, getCouponErrors } from 'util/validation/coupons'
import { getEntityFromResponse } from 'util/index'
import { toCouponApiRequest } from 'util/transformers/coupon'

function CreateCouponFormState({
    onCancel,
    onSaveSuccess,
    children,
}) {
    return (
        <CreateCouponRequest paginationKey="overview">
            {({
                createCoupon,
                fetchCoupons,
                createCouponSuccess,
                createCouponFailed,
            }) => (
                <WithFormState
                    initialValues={{
                        amount: null,
                        type: 'single',
                        amountType: 'isAmount',
                        code: null,
                        minPlayerAmount: null,
                        expiresAt: null,
                        lastPossiblePlayDate: null,
                        description: '',
                        legalEntity: null,
                        venue: null,
                        isAllowedForGiftcards: false,
                    }}
                    isValidForm={isValidCoupon}
                    getFormErrors={getCouponErrors}
                    transformValuesToRequestBody={toCouponApiRequest}
                    onSendRequest={createCoupon}
                    onCancel={onCancel}
                    onRequestSuccess={(response) => {
                        const coupon = getEntityFromResponse(response, 'coupons')
                        fetchCoupons()
                        createCouponSuccess(coupon)
                        onSaveSuccess(coupon)
                    }}
                    onRequestFailed={createCouponFailed}
                >
                    {children}
                </WithFormState>
            )}
        </CreateCouponRequest>
    )
}

export default CreateCouponFormState

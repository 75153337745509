import React from 'react'
import { getEntityFromResponse } from 'util/index'
import { WithFormState } from 'components/util/forms/WithFormState'
import DeleteDiscountBannerRequest from './DeleteDiscountBannerRequest'

function DeleteDiscountBannerFormState({
    onCancel,
    onDeleteSuccess,
    values,
    children,
}) {
    return (
        <DeleteDiscountBannerRequest paginationKey="overview">
            {({
                deleteDiscountBanner,
                deleteDiscountBannerSuccess,
                deleteDiscountBannerFailed,
            }) => (
                <WithFormState
                    transformValuesToRequestBody={() => values}
                    onSendRequest={() => deleteDiscountBanner(values)}
                    onCancel={onCancel}
                    onRequestSuccess={(response) => {
                        const discountBanner = getEntityFromResponse(response, 'discountBanners')
                        deleteDiscountBannerSuccess(discountBanner)
                        onDeleteSuccess(discountBanner)
                    }}
                    onRequestFailed={deleteDiscountBannerFailed}
                >
                    {children}
                </WithFormState>
            )}
        </DeleteDiscountBannerRequest>
    )
}

export default DeleteDiscountBannerFormState

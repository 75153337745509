import React from 'react'
import {
    Form,
    Radio,
    Input,
    InputNumber,
    DatePicker,
    Checkbox,
} from 'antd'
import ExtraInfoLabel from 'components/util/forms/ExtraInfoLabel'
import VenuesSelectWithRedux from 'components/pages/settings/coupons/forms/VenueSelect'
import useCurrencySymbol from 'components/hooks/useCurrencySymbol'
import dayjs from 'dayjs'
import CouponFormItem from '../create/CouponFormItem'
//
// const { Option } = Select

/**
 * @param {Object} props
 * @param {Object} props.values
 * @param {Object} props.errors
 * @param {(event: any) => void} props.onChange
 * @param {(key: string, newValue: string) => void } props.onChangeAsValue
 * @param {Object} props.legalEntity
 * @returns {React.JSX.Element}
 * @constructor
 */
function EditCouponForm(props) {
    const currencySymbol = useCurrencySymbol(props.legalEntity)

    return (
        <Form>
            {
                props.values.type === 'multiple' && (
                    <CouponFormItem
                        label="Usage Limit"
                        error={props.errors.usageLimit}
                        extra={(
                            <ExtraInfoLabel
                                message="If you provide a limit the coupon can only be used for that amount."
                            />
                        )}
                    >
                        <InputNumber
                            name="usageLimit"
                            value={props.values.usageLimit}
                            onChange={(value) => props.onChangeAsValue('usageLimit', value)}
                            min={2}
                            step={1}
                        />
                    </CouponFormItem>
                )
            }
            <CouponFormItem
                label="Code"
                error={props.errors.code}
                extra={(typeof props.values.code !== 'string' || props.values.code.length === 0) && (
                    <ExtraInfoLabel
                        message="If no code is provided, a random code will be generated."
                    />
                )}
            >
                <Input
                    disabled
                    className="txt-upper"
                    name="code"
                    value={props.values.code}
                />
            </CouponFormItem>
            <CouponFormItem>
                <Checkbox
                    name="isAllowedForGiftcards"
                    checked={props.values.isAllowedForGiftcards}
                    onChange={props.onChange}
                >
                    Can also be used for gift cards
                </Checkbox>
            </CouponFormItem>
            <CouponFormItem
                label="Available in"
                error={props.errors.legalEntity || props.errors.venue}
                required
            >
                <VenuesSelectWithRedux
                    venue={props.values.venue}
                    onVenueChange={(value) => props.onChangeAsValue('venue', value)}
                    legalEntity={props.values.legalEntity}
                    onLegalEntityChange={(value) => props.onChangeAsValue('legalEntity', value)}
                />
            </CouponFormItem>
            <CouponFormItem
                label="Amount type"
            >
                <Radio.Group
                    disabled
                    name="amountType"
                    value={props.values.amountType}
                >
                    <Radio.Button value="isAmount">
                        {currencySymbol}
                    </Radio.Button>
                    <Radio.Button value="isPercentage">
                        &#37;
                    </Radio.Button>
                </Radio.Group>
            </CouponFormItem>
            <CouponFormItem
                label="Amount"
                error={props.errors.amount}
                required
            >
                {
                    props.values.amountType === 'isAmount' ? (
                        <InputNumber
                            disabled
                            name="amount"
                            value={props.values.amount}
                            min={0}
                            formatter={(value) => `${currencySymbol}${value}`}
                            parser={(value) => value.replace(currencySymbol, '').replace(',', '.')}
                        />
                    ) : (
                        <InputNumber
                            disabled
                            name="amount"
                            value={props.values.amount}
                            min={0}
                            max={100}
                            step={1}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value.replace('%', '').replace(',', '.')}
                        />
                    )
                }
            </CouponFormItem>
            <CouponFormItem
                label="Min. player amount"
            >
                <InputNumber
                    name="minPlayerAmount"
                    value={props.values.minPlayerAmount}
                    onChange={(value) => props.onChangeAsValue('minPlayerAmount', value)}
                    min={1}
                    max={9999}
                    precision={0}
                    step={1}
                />
            </CouponFormItem>
            <CouponFormItem
                label="Coupon expires"
                error={props.errors.expiresAt}
                extra={!props.values.expiresAt
                    && (
                        <ExtraInfoLabel
                            message="If no date is provided, the code will be permanently available."
                        />
                    )}
            >
                <DatePicker
                    name="expiresAt"
                    format="DD/MM/YYYY"
                    value={props.values.expiresAt}
                    disabledDate={(current) => current && current < dayjs.utc().endOf('day')}
                    onChange={(value) => props.onChangeAsValue('expiresAt', value)}
                    className="full-width"
                />
            </CouponFormItem>
            <CouponFormItem
                label="Last possible play date"
                extra={!props.values.lastPossiblePlayDate
                    && (
                        <ExtraInfoLabel
                            message="If no date is provided, the customer can book their play session on any future date"
                        />
                    )}
            >
                <DatePicker
                    name="lastPossiblePlayDate"
                    format="DD/MM/YYYY"
                    value={props.values.lastPossiblePlayDate}
                    disabledDate={(current) => current && current < dayjs.utc().endOf('day')}
                    onChange={(value) => props.onChangeAsValue('lastPossiblePlayDate', value)}
                    className="full-width"
                />
            </CouponFormItem>
            <CouponFormItem
                label="Description"
                error={props.errors.description}
            >
                <Input.TextArea
                    rows="4"
                    name="description"
                    value={props.values.description}
                    onChange={props.onChange}
                />
            </CouponFormItem>
        </Form>
    )
}

export default EditCouponForm

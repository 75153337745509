import React from 'react'
import { Input } from 'antd'
import ConfirmModal from 'components/util/modals/ConfirmModal'
import {WithFormState} from "../forms/WithFormState"

const INITIAL_VALUES = {
    safeWord: '',
}

export function ConfirmInputModal({
    safeWord,
    children,
    onCancel,
    ...props
}) {
    return (
        <WithFormState
            initialValues={INITIAL_VALUES}
        >
            {({
                values,
                handleChange,
                mergeValues,
            }) => (
                <ConfirmModal
                    confirmDisabled={values.safeWord !== safeWord}
                    onCancel={() => {
                        onCancel()
                        mergeValues(INITIAL_VALUES)
                    }}
                    {...props}
                >
                    {children}
                    <Input
                        name="safeWord"
                        value={values.safeWord}
                        onChange={handleChange}
                    />
                </ConfirmModal>
            )}
        </WithFormState>
    )
}

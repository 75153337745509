import {
    ME_REQUEST,
    ME_SUCCESS,
    ME_FAILURE,
} from '../actions/me'

// eslint-disable-next-line default-param-last
const meReducer = (state = null, action) => {
    const { type } = action
    switch (type) {
        case ME_REQUEST: {
            return null
        }
        case ME_SUCCESS: {
            const { users } = action.response.entities
            const firstUser = users[Object.keys(users)[0]]
            return firstUser
        }
        case ME_FAILURE: {
            return null
        }
        default: {
            return state
        }
    }
}

export default meReducer

import React from 'react'
import { createRoomForActiveVenue, fetchRoomsExtendCurrentParams } from 'store/actions/rooms'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    createRoom: async (values) => dispatch(createRoomForActiveVenue(values)),
    createRoomSuccess: (room) => {
        notification.success({
            message: 'Room added!',
            description: (
                <span>
                    A new room
                    {' '}
                    <strong>{room && room.name}</strong>
                    &nbsp;
                    was successfully created.
                </span>
            ),
        })
    },
    createRoomFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchRooms: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchRoomsExtendCurrentParams(paginationKey))
    },
})

const CreateRoomRequest = withReduxState(null, mapDispatchToProps)

export default CreateRoomRequest

import React from 'react'
import { Form } from 'antd'

function FormItem({
    children,
    label,
    error,
    extra,
    isRequired,
    style = {},
}) {
    return (
        <Form.Item
            className="no-margin"
            label={label}
            hasFeedback
            validateStatus={error ? 'error' : ''}
            help={error}
            extra={extra}
            required={isRequired}
            style={style}
        >
            {children}
        </Form.Item>
    )
}

export default FormItem

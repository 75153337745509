import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import { MODAL_IDS } from 'util/constants'

function ToggleActiveGameSessionModalState({
    gameSession,
    children,
}) {
    return (
        <WithActiveModal
            modalId={MODAL_IDS.TOGGLE_ACTIVE_GAME_SESSION}
            entity={gameSession}
            entityKey="gameSession"
        >
            {children}
        </WithActiveModal>
    )
}

export default ToggleActiveGameSessionModalState

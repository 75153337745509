import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import { MODAL_IDS } from 'util/constants'

/**
 * @component
 * @param {Object} props
 * @param {BookingResource} props.booking
 * @param {(childProps: WithActiveModalChildFunctionProps<BookingResource>) => JSX.Element} props.children
 * @returns {JSX.Element}
 */
export function EditBookingModalState(props) {
    return (
        <WithActiveModal
            modalId={MODAL_IDS.EDIT_BOOKING}
            entity={props.booking}
            entityKey="booking"
        >
            {props.children}
        </WithActiveModal>
    )
}

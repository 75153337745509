import React from "react"
import {BookingFormItem} from "./BookingFormItem"
import {Input, Switch} from "antd"
import {CheckOutlined, CloseOutlined} from "@ant-design/icons"

/**
 * @param {Object} props
 * @param {boolean} props.isloading
 * @param {CreateOrEditBookingFormValues} props.values
 * @param {{ [key in keyof CreateOrEditBookingFormValues]: string[]}} props.errors
 * @param {(valuesToMerge: {[key in keyof CreateOrEditBookingFormValues]: CreateOrEditBookingFormValues[key]}) => void} props.mergeValues
 * @param {(e: any) => void} props.onChange
 * @param {(key: keyof CreateOrEditBookingFormValues, value: CreateOrEditBookingFormValues[key]) => void } props.onChangeAsValue
 * @return {JSX.Element}
 */
export function BookingUpsellingItems(props) {
    if (props.values.isSocialDeal) {
        return (
            <div>
                <BookingFormItem
                    label="Add catering"
                    error={props.errors.infoCatering}
                    extra={props.values.cateringChecked && (
                        <Input
                            name="infoCatering"
                            value={props.values.infoCatering}
                            onChange={props.onChange}
                            placeholder="Provide extra info if needed"
                        />
                    )}
                >
                    <Switch
                        size="small"
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={props.values.cateringChecked}
                        onChange={(value) => props.onChangeAsValue('cateringChecked', value)}
                    />
                </BookingFormItem>
                <BookingFormItem
                    label="Add open bar"
                    error={props.errors.infoBarForFait}
                    extra={props.values.barForFaitChecked && (
                        <Input
                            name="infoBarForFait"
                            value={props.values.infoBarForFait}
                            onChange={props.onChange}
                            placeholder="Provide extra info if needed"
                        />
                    )}
                >
                    <Switch
                        size="small"
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={props.values.barForFaitChecked}
                        onChange={(value) => props.onChangeAsValue('barForFaitChecked', value)}
                    />
                </BookingFormItem>
                <BookingFormItem
                    label="Add meeting room"
                    error={props.errors.infoMeetingRoom}
                    extra={props.values.meetingRoomChecked && (
                        <Input
                            name="infoMeetingRoom"
                            value={props.values.infoMeetingRoom}
                            onChange={props.onChange}
                            placeholder="Provide extra info if needed"
                        />
                    )}
                >
                    <Switch
                        size="small"
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={props.values.meetingRoomChecked}
                        onChange={(value) => props.onChangeAsValue('meetingRoomChecked', value)}
                    />
                </BookingFormItem>
            </div>
        )
    } else {
        return (
            <div>
                <BookingFormItem
                    label={"Add 2 drinks pp"}
                    error={props.errors.drinksChecked}
                >
                    <Switch
                        size="small"
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={props.values.drinksChecked}
                        onChange={(checked) => props.onChangeAsValue("drinksChecked", checked)}
                    />
                </BookingFormItem>
                <BookingFormItem
                    label={"Add 1 hour open bar"}
                    error={props.errors.openBarChecked}
                >
                    <Switch
                        size="small"
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={props.values.openBarChecked}
                        onChange={(checked) => props.onChangeAsValue("openBarChecked", checked)}
                    />
                </BookingFormItem>
                <BookingFormItem
                    label={"Add nachos"}
                    error={props.errors.nachosChecked}
                >
                    <Switch
                        size="small"
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={props.values.nachosChecked}
                        onChange={(checked) => props.onChangeAsValue("nachosChecked", checked)}
                    />
                </BookingFormItem>
                <BookingFormItem
                    label={"Add two drink tokens pp"}
                    error={props.errors.drinkTokensChecked}
                >
                    <Switch
                        size="small"
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={props.values.drinkTokensChecked}
                        onChange={(checked) => props.onChangeAsValue("drinkTokensChecked", checked)}
                    />
                </BookingFormItem>
            </div>
        )
    }
}
import React, { useState } from 'react'
import { Modal, Form } from 'antd'
import { ModalFooter } from 'components/util/modals/ModalFooter'
import CreateGameFormState from 'components/pages/settings/games/forms/create/CreateGameFormState'
import GameForm from 'components/pages/settings/games/forms/GameForm'
import MultiLangCreateGameForm from 'components/pages/settings/games/forms/create/MultiLangCreateGameForm'
import { formHasErrorsForLocale } from 'util/validation/game'
import {AppTabs} from 'components/util/AppTabs'
import { capitalize } from 'util/index'
import * as theme from 'styles/theme'
import { useAppState } from 'components/hooks/useAppState'
import { useLegalEntity } from 'components/hooks/useLegalEntity'
import PriceForm from '../../forms/PriceForm'
import {LANGUAGE_CODES} from "../../../../../../util/constants"

function CreateGameModal({
    isOpen,
    onClose,
}) {
    const [isUploadingImage, setIsUploadingImage] = useState(false)
    const { currencies } = useAppState()
    const legalEntity = useLegalEntity()
    if (typeof legalEntity === 'undefined')
        throw new Error(`Legal Entity is undefined! Comp: ${CreateGameModal.name}`)

    return (
        <CreateGameFormState
            onSaveSuccess={onClose}
            onCancel={onClose}
        >
            {({
                values,
                errors,
                isLoading,
                handleChange,
                handleSubmit,
                handleCancel,
                handleChangeAsValue,
            }) => (
                <Modal
                    title="Create new game"
                    width={600}
                    open={isOpen}
                    onCancel={handleCancel}
                    onOk={handleSubmit}
                    closable={!(isLoading || isUploadingImage)}
                    maskClosable={!(isLoading || isUploadingImage)}
                    footer={(
                        <ModalFooter
                            isLoading={isLoading || isUploadingImage}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                            submitText="Create"
                        />
                    )}
                >
                    {isOpen && (
                        <>
                            <div style={{ marginBottom: theme.spacingLarge }}>
                                <AppTabs
                                    tabs={LANGUAGE_CODES}
                                    hasErrors={(language) => formHasErrorsForLocale(errors, language)}
                                >
                                    {({ tab }) => (
                                        <MultiLangCreateGameForm
                                            language={capitalize(tab)}
                                            isLoading={isLoading}
                                            values={values}
                                            errors={errors}
                                            onChange={handleChange}
                                        />
                                    )}
                                </AppTabs>
                            </div>

                            <div style={{ marginBottom: theme.spacingLarge }}>
                                <Form.Item
                                    help={errors.price}
                                    validateStatus={errors.price ? 'error' : ''}
                                >
                                    <AppTabs tabs={Object.keys(currencies)}>
                                        {({ tab }) => (
                                            <PriceForm
                                                values={values}
                                                errors={errors}
                                                onChangeAsValue={handleChangeAsValue}
                                                currency={currencies[tab]}
                                                priceSystem={legalEntity.priceSystem}
                                            />
                                        )}
                                    </AppTabs>
                                </Form.Item>
                            </div>

                            <GameForm
                                isLoading={isLoading}
                                values={values}
                                errors={errors}
                                onChange={handleChange}
                                onChangeAsValue={handleChangeAsValue}
                                isUploadingImage={setIsUploadingImage}
                                isCreateForm
                            />
                        </>
                    )}
                </Modal>
            )}
        </CreateGameFormState>
    )
}

export default CreateGameModal

import React from 'react'
import { useLegalEntity } from 'components/hooks/useLegalEntity'
import CreatePriceAdjustmentModal from './CreatePriceAdjustmentModal'
import CreatePriceAdjustmentModalState from './CreatePriceAdjustmentModalState'
import CreatePriceAdjustmentFormState from '../../forms/create-price-adjustment/CreatePriceAdjustmentFormState'

function CreatePriceAdjustmentModalWithState() {
    const legalEntity = useLegalEntity()
    if (typeof legalEntity === 'undefined')
        throw new Error(`Legal Entity is undefined! Comp: ${CreatePriceAdjustmentModalWithState.name}`)

    return (
        <CreatePriceAdjustmentModalState>
            {({
                isOpen,
                closeModal,
                venueGame,
            }) => (
                <CreatePriceAdjustmentFormState
                    onCancel={closeModal}
                    onCreateSuccess={closeModal}
                    venueGame={venueGame}
                    priceSystem={legalEntity.priceSystem}
                >
                    {({
                        values,
                        errors,
                        isLoading,
                        mergeValues,
                        handleChange,
                        handleChangeAsValue,
                        handleCancel,
                        handleSubmit,
                    }) => (
                        <CreatePriceAdjustmentModal
                            isOpen={isOpen}
                            onClose={closeModal}
                            values={values}
                            errors={errors}
                            isLoading={isLoading}
                            mergeValues={mergeValues}
                            onChange={handleChange}
                            onChangeAsValue={handleChangeAsValue}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                            priceSystem={legalEntity.priceSystem}
                        />
                    )}
                </CreatePriceAdjustmentFormState>
            )}
        </CreatePriceAdjustmentModalState>
    )
}

export default CreatePriceAdjustmentModalWithState

import { createValidationErrorObject } from './index'
import * as validation from './giftCard'

export const getFirstNameError = createValidationErrorObject(
    validation.isValidFirstName,
    'firstName',
    'Please fill in a valid first name.',
)

export const getLastNameError = createValidationErrorObject(
    validation.isValidLastName,
    'lastName',
    'Please fill in a valid last name.',
)

export const getPersonEmailError = createValidationErrorObject(
    validation.isValidPersonEmail,
    'email',
    'Please fill in a valid email.',
)

export const getRecipientError = createValidationErrorObject(
    validation.isValidRecipient,
    'recipientName',
    'Please fill in a valid recipient name.',
)

export const getAmountError = createValidationErrorObject(
    validation.isValidAmount,
    'amount',
    'Please fill in a valid amount.',
)

export const getGiftCardErrors = (values) => ({
    ...getFirstNameError(values.firstName),
    ...getLastNameError(values.lastName),
    ...getPersonEmailError(values.email),
    ...getRecipientError(values.recipientName),
    ...getAmountError(values.amount),
})

export const getEditGiftCardFormErrors = (values) => ({
    ...getFirstNameError(values.firstName),
    ...getLastNameError(values.lastName),
    ...getPersonEmailError(values.email),
})

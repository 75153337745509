import {
    EXTRA_INFORMATOIN_KEYS, roomModes, APP_DATE_FORMAT, APP_TIME_FORMAT,
} from 'util/constants'
import dayjs from 'dayjs'
import {isValidString} from "../string-utils"

/**
 * @typedef {Object} BookingCreateOrUpdateRequestBody
 *
 * Booking data
 * @property {number} legalEntityId
 * @property {number} gameId
 * @property {string} gameLanguageCode
 * @property {number} roomId
 * @property {number} playerAmount
 * @property {string} bookingStartDateTime
 * @property {boolean} isForcedPrivate
 * @property {string} roomMode
 * @property {boolean} addBuffersBefore
 * @property {boolean} addBuffersAfter
 * @property {string|null} bookingDealType
 *
 * Upselling
 * @property {boolean|null|undefined} openBarChecked
 * @property {boolean|null|undefined} drinksChecked
 * @property {boolean|null|undefined} nachosChecked
 * @property {boolean|null|undefined} drinkTokensChecked
 *
 * Other extra info items like catering, meeting room etc
 * @property {Object[]} extraInformation
 *
 * Discounts
 * @property {string|null|undefined} couponCode
 * @property {number|null|undefined} socialDealFixedAmount
 * @property {number|null|undefined} socialDealDiscountPercentage
 *
 * Booker info
 * @property {string} bookerFirstName
 * @property {string} bookerLastName
 * @property {string} bookerEmail
 * @property {string|null|undefined} bookerPhoneNumber
 * @property {string} bookerLanguageCode
 *
 * Company data
 * @property {boolean} isCompanyBooking
 * @property {string|null|undefined} companyName
 * @property {string|null|undefined} companyCountryCode
 * @property {boolean|null|undefined} companyHasVatNumber
 * @property {string|null|undefined} companyVatNumber
 * @property {string|null|undefined} companyAddress
 * @property {string|null|undefined} companyZipCode
 * @property {string|null|undefined} companyCity
 * @property {string|null|undefined} purchaseOrderNumber
 *
 * Financial
 * @property {boolean|null|undefined} alreadyPaid
 *
 * Meta
 * @property {string|null|undefined} comment
 * @property {boolean} sendConfirmationEmail
 */

/**
 * @param {Object[]|null=} extraInformation
 * @param {string} type
 * @returns {Object|undefined}
 */
export function getExtraInformationOfType(extraInformation, type) {
    if (!Array.isArray(extraInformation))
        return undefined

    for (let i = 0, length = extraInformation.length; i < length; i++) {
        const extraInfoItem = extraInformation[i]
        if (extraInfoItem.type === type)
            return extraInfoItem
    }

    return undefined
}

/**
 * Combines the date and roomStart dayjs instance into a single datetime string that the backend expects
 * @param {dayjs.Dayjs} bookingStartDate
 * @param {dayjs.Dayjs} bookingStartTime
 * @returns {string}
 */
export function buildBookingStartDateTimeRequestString(bookingStartDate, bookingStartTime) {
    // NOTE: Both date and roomStart are dayjs instances!
    // Get selected date: E.g. "2022-05-11"
    const dateString = bookingStartDate.format(APP_DATE_FORMAT)
    // Uncomment to add the introduction duration in minutes. Left this here, because otherwise I wouldn't know
    // that introductionDuration is part of the values object... Nor how to add minutes in MomentJS
    // const timeString = moment(values.roomStart).add(values.introductionDuration, 'minutes').format(APP_TIME_FORMAT)
    // Get selected time: E.g. "10:00"
    const timeString = bookingStartTime.format(APP_TIME_FORMAT)
    // Build datetime string from the two and convert to actual datetime
    // E.g. final date = "2022-05-11 10:00"
    const finalDate = `${dateString} ${timeString}`
    return finalDate
    // Remove the timezone from the end. E.g. "2022-05-11 10:00"
    // return getDateWithoutTimeZone(finalDate)
}

/**
 * @param {CreateOrEditBookingFormValues} values
 * @return {BookingCreateOrUpdateRequestBody}
 */
export function toBookingApiRequest(values) {
    /** @type {Object[]} */
    const extraInformation = []
    if (values.cateringChecked) {
        extraInformation.push({
            type: EXTRA_INFORMATOIN_KEYS.catering,
            info: values.infoCatering,
        })
    }

    if (values.meetingRoomChecked) {
        extraInformation.push({
            type: EXTRA_INFORMATOIN_KEYS.meetingRoom,
            info: values.infoMeetingRoom,
        })
    }

    if (values.barForFaitChecked) {
        extraInformation.push({
            type: EXTRA_INFORMATOIN_KEYS.barForFait,
            info: values.infoBarForFait,
        })
    }

    const bookingStartDateTime = buildBookingStartDateTimeRequestString(
        values.bookingStartDate,
        values.bookingStartTime,
    )
    /** @type {BookingCreateOrUpdateRequestBody} */
    const requestBody = {
        // Booking data
        legalEntityId: values.legalEntityId,
        gameId: values.gameId,
        gameLanguageCode: values.gameLanguageCode,
        roomId: values.roomId,
        playerAmount: values.playerAmount,
        bookingStartDateTime: bookingStartDateTime,
        isForcedPrivate: values.isForcedPrivate,
        roomMode: values.roomMode,
        addBuffersBefore: values.addBuffersBefore,
        addBuffersAfter: values.addBuffersAfter,
        bookingDealType: values.bookingDealType,

        // Upselling
        openBarChecked: values.openBarChecked,
        drinksChecked: values.drinksChecked,
        nachosChecked: values.nachosChecked,
        drinkTokensChecked: values.drinkTokensChecked,

        // Other extra info items like catering, meeting room etc
        extraInformation: extraInformation,

        // Discounts
        couponCode: values.couponCode,
        socialDealFixedAmount: values.socialDealFixedAmount,
        socialDealDiscountPercentage: values.socialDealDiscountPercentage,

        // Booker info
        bookerFirstName: values.bookerFirstName,
        bookerLastName: values.bookerLastName,
        bookerEmail: values.bookerEmail,
        bookerPhoneNumber: values.bookerPhoneNumber,
        bookerLanguageCode: values.bookerLanguageCode,

        // Company data
        isCompanyBooking: values.isCompanyBooking,
        companyName: values.companyName,
        companyCountryCode: values.companyCountryCode,
        companyHasVatNumber: values.companyHasVatNumber,
        companyVatNumber: values.companyVatNumber,
        companyAddress: values.companyAddress,
        companyZipCode: values.companyZipCode,
        companyCity: values.companyCity,
        purchaseOrderNumber: values.purchaseOrderNumber,

        // Financial
        alreadyPaid: values.markAsAlreadyPaid,

        // Meta
        comment: values.comment,
        sendConfirmationEmail: values.sendConfirmationEmail,
    }

    return requestBody
}

/**
 * @param {BookingResource }booking
 * @returns {CreateOrEditBookingFormValues}
 */
export function mapBookingToValues(booking) {
    /** @type {number|null} */
    let socialDealFixedAmount = null
    /** @type {number|null} */
    let socialDealDiscountPercentage = null
    if (Array.isArray(booking.orderPriceUpdates) && booking.orderPriceUpdates.length > 0) {
        /** @type {OrderPriceUpdateResource} */
        const lastPriceUpdate = booking.orderPriceUpdates[0]
        socialDealFixedAmount = lastPriceUpdate.socialDealFixedAmount
        socialDealDiscountPercentage = lastPriceUpdate.socialDealDiscountPercentage
    } else {
        socialDealFixedAmount = booking.socialDealFixedAmount
        socialDealDiscountPercentage = booking.socialDealDiscountPercentage
    }

    // Public (or online) upselling
    const oneHourOpenBarInfo = getExtraInformationOfType(booking.extraInformation, EXTRA_INFORMATOIN_KEYS.food)
    const drinksInfo = getExtraInformationOfType(booking.extraInformation, EXTRA_INFORMATOIN_KEYS.drinks)
    const drinkTokensInfo = getExtraInformationOfType(booking.extraInformation, EXTRA_INFORMATOIN_KEYS.drinkTokens)
    const nachosInfo = getExtraInformationOfType(booking.extraInformation, EXTRA_INFORMATOIN_KEYS.nachos)

    // Private (or offline) upselling
    const cateringInfo = getExtraInformationOfType(booking.extraInformation, EXTRA_INFORMATOIN_KEYS.catering)
    const meetingRoomInfo = getExtraInformationOfType(booking.extraInformation, EXTRA_INFORMATOIN_KEYS.meetingRoom)
    const barForFaitInfo = getExtraInformationOfType(booking.extraInformation, EXTRA_INFORMATOIN_KEYS.barForFait)

    /** @type {dayjs.Dayjs|null} */
    let bookingStart = null
    if (isValidString(booking.start)) {
        bookingStart = dayjs.utc(booking.start)
    }

    /**
     * @type {CreateOrEditBookingFormValues}
     */
    let data = {
        // Booking data
        bookingId: booking.id,
        playerAmount: booking.playerAmount,
        gameId: typeof booking.gameId === "number" ? booking.gameId : null,
        gameLanguageCode: booking.gameLanguageCode,
        roomId: booking.roomId,
        isPrivate: booking.isPrivate,
        isForcedPrivate: booking.privateByRequest,
        bookingStartDate: bookingStart,
        bookingStartTime: bookingStart,
        introductionDuration: booking.introduction?.duration,
        addBuffersAfter: false,
        addBuffersBefore: false,
        roomMode: roomModes.all.value,
        bookingDealType: booking.bookingDealType?.code || null,

        // Booker data
        bookerFirstName: booking.bookerFirstName,
        bookerLastName: booking.bookerLastName,
        bookerEmail: booking.bookerEmail,
        bookerPhoneNumber: booking.bookerPhoneNumber,
        bookerLanguageCode: booking.bookerLanguageCode,

        // Public (or online) upselling
        drinksChecked: typeof drinksInfo !== "undefined",
        openBarChecked: typeof oneHourOpenBarInfo !== "undefined",
        nachosChecked: typeof nachosInfo !== "undefined",
        drinkTokensChecked: typeof drinkTokensInfo !== "undefined",

        // Private (or offline) upselling
        cateringChecked: typeof cateringInfo !== "undefined",
        infoCatering: isValidString(cateringInfo?.info) ? cateringInfo.info : '',
        meetingRoomChecked: typeof meetingRoomInfo !== "undefined",
        infoMeetingRoom: isValidString(meetingRoomInfo?.info) ? meetingRoomInfo.info : '',
        barForFaitChecked: typeof barForFaitInfo !== "undefined",
        infoBarForFait: isValidString(barForFaitInfo?.info) ? barForFaitInfo.info : '',

        couponCode: null,
        isSocialDeal: booking.isSocialDeal,
        socialDealFixedAmount: socialDealFixedAmount,
        socialDealDiscountPercentage: socialDealDiscountPercentage,

        // Meta data
        comment: booking.comment,
        sendConfirmationEmail: false,
        markAsAlreadyPaid: false,
    }

    // Company data
    data.isCompanyBooking = booking.isCompanyBooking
    data.companyName = booking.companyName
    data.purchaseOrderNumber = booking.purchaseOrderNumber
    if (booking.isCompanyBooking) {
        // eslint-disable-next-line prefer-destructuring
        const companyDetails = booking.companyDetails
        if (typeof companyDetails !== 'undefined' && companyDetails !== null) {
            data.companyCountryCode = companyDetails.companyCountryCode
            data.companyHasVatNumber = isValidString(companyDetails.vatNumber)
            data.companyVatNumber = companyDetails.vatNumber
            data.companyAddress = companyDetails.address
            data.companyZipCode = companyDetails.zipCode
            data.companyCity = companyDetails.city
            data.companyCountryCode = companyDetails.country
        }
    } else {
        data.companyHasVatNumber = true
    }

    return data
}

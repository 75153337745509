// eslint-disable-next-line import/no-cycle
import { getPaginationKey } from 'store/reducers/pagination/venueGamesPaginationReducer'
import { CALL_API, METHODS } from '../middleware/api'
import { venueGamesSchemaArray, venueGamesSchema } from '../schemas/venueGames'

export const VENUE_GAMES_REQUEST = 'VENUE_GAMES_REQUEST'
export const VENUE_GAMES_SUCCESS = 'VENUE_GAMES_SUCCESS'
export const VENUE_GAMES_FAILURE = 'VENUE_GAMES_FAILURE'

export const VENUE_GAME_REQUEST = 'VENUE_GAME_REQUEST'
export const VENUE_GAME_SUCCESS = 'VENUE_GAME_SUCCESS'
export const VENUE_GAME_FAILURE = 'VENUE_GAME_FAILURE'

export const fetchGamesForActiveVenue = (componentName, params) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch({
        componentName,
        venueId,
        [CALL_API]: {
            types: [
                VENUE_GAMES_REQUEST,
                VENUE_GAMES_SUCCESS,
                VENUE_GAMES_FAILURE,
            ],
            endpoint: `/venues/${venueId}/games`,
            schema: venueGamesSchemaArray,
            params,
        },
    })
}

export const fetchVenueGamesExtendCurrentParams = (paginationKey, params) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    const venueGamesPagination = getState().pagination.venueGames[getPaginationKey(paginationKey, venueId)]
    const newParams = {
        ...venueGamesPagination.params,
        ...params,
    }
    return dispatch(fetchGamesForActiveVenue(paginationKey, newParams))
}

export const createVenueGame = (body) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch({
        [CALL_API]: {
            method: METHODS.POST,
            types: [
                VENUE_GAME_REQUEST,
                VENUE_GAME_SUCCESS,
                VENUE_GAME_FAILURE,
            ],
            endpoint: `/venues/${venueId}/games`,
            schema: venueGamesSchema,
            body,
        },
    })
}

export const editVenueGame = (venueGameId, body) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch({
        [CALL_API]: {
            method: METHODS.PUT,
            types: [
                VENUE_GAME_REQUEST,
                VENUE_GAME_SUCCESS,
                VENUE_GAME_FAILURE,
            ],
            endpoint: `/venues/${venueId}/games/${venueGameId}`,
            schema: venueGamesSchema,
            body,
        },
    })
}

export const deleteVenueGame = (venueGameId) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch({
        [CALL_API]: {
            method: METHODS.DELETE,
            types: [
                VENUE_GAME_REQUEST,
                VENUE_GAME_SUCCESS,
                VENUE_GAME_FAILURE,
            ],
            endpoint: `/venues/${venueId}/games/${venueGameId}`,
            schema: venueGamesSchema,
            entityId: venueGameId,
        },
    })
}

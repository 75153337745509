import { combineReducers } from 'redux'
import reduceReducers from 'reduce-reducers'
import entitiesResponseReducer from './entitiesResponseReducer'
import activeFiltersReducer from './activeFiltersReducer'
import activeModalsReducer from './activeModalsReducer'
import meReducer from './meReducer'
import bookingsPaginationReducer from './pagination/bookingsPaginationReducer'
import couponsPaginationReducer from './pagination/couponsPaginationReducer'
import couponBatchesPaginationReducer from './pagination/couponBatchesPaginationReducer'
import usersPaginationReducer from './pagination/usersPaginationReducer'
import rolesPaginationReducer from './pagination/rolesPaginationReducer'
import gamesPaginationReducer from './pagination/gamesPaginationReducer'
import availabilitiesPaginationReducer from './pagination/availabilitiesPaginationReducer'
import bookingsReducer from './entities/bookingsReducer'
import couponsReducer from './entities/couponsReducer'
import usersReducer from './entities/usersReducer'
import couponBatchesReducer from './entities/couponBatchesReducer'
import venuesPaginationReducer from './pagination/venuesPaginationReducer'
import roomsPaginationReducer from './pagination/roomsPaginationReducer'
import activeBookingStatusReducer from './features/activeBookingStatusReducer'
import venueGamesPaginationReducer from './pagination/venueGamesPaginationReducer'
import venueGamesReducer from './entities/venueGamesReducer'
import gameSessionsPaginationReducer from './pagination/gameSessionsPaginationReducer'
import gameSessionsReducer from './entities/gameSessionsReducer'
import activeGameSessionStatusReducer from './features/activeGameSessionStatusReducer'
import equipmentSetsPaginationReducer from './pagination/equipmentSetsPaginationReducer'
import equipmentSetsReducer from './entities/equipmentSetsReducer'
import activeEntitiesReducer from './activeEntitiesReducer'
import venuesReducer from './entities/venuesReducer'
import rolesReducer from './entities/rolesReducer'
import removeStateReducer from './features/removeStateReducer'
import giftCardsPaginationReducer from './pagination/giftCardsPaginationReducer'
import giftCardsReducer from './entities/giftCardsReducer'
import discountBannersPaginationReducer from './pagination/discountBannersPaginationReducer'
import discountBannerReducer from './entities/discountBannerReducer'
import activeDiscountBannerReducer from './features/activeDiscountBannerReducer'
import legalEntitiesReducer from './entities/legalEntitiesReducer'
import legalEntitiesPaginationReducer from './pagination/legalEntitiesPaginationReducer'
import { vouchersPaginationReducer } from './pagination/vouchersPaginationReducer'
import {
    voucherIssueReasonsPaginationReducer,
} from './pagination/voucherIssueReasonsPaginationReducer'
import { vouchersReducer } from './entities/vouchersReducer'
import { voucherIssueReasonsReducer } from './entities/voucherIssueReasonsReducer'

const paginationReducer = combineReducers({
    availabilities: availabilitiesPaginationReducer,
    bookings: bookingsPaginationReducer,
    coupons: couponsPaginationReducer,
    couponBatches: couponBatchesPaginationReducer,
    discountBanners: discountBannersPaginationReducer,
    equipmentSets: equipmentSetsPaginationReducer,
    games: gamesPaginationReducer,
    gameSessions: gameSessionsPaginationReducer,
    giftCards: giftCardsPaginationReducer,
    legalEntities: legalEntitiesPaginationReducer,
    roles: rolesPaginationReducer,
    rooms: roomsPaginationReducer,
    users: usersPaginationReducer,
    venues: venuesPaginationReducer,
    venueGames: venueGamesPaginationReducer,
    vouchers: vouchersPaginationReducer,
    voucherIssueReasons: voucherIssueReasonsPaginationReducer,
})

const noOpReducer = (state = {}) => state

const entitiesReducer = combineReducers({
    availabilities: noOpReducer,
    bookings: bookingsReducer,
    bookingRegisteredPlayers: noOpReducer,
    couponBatches: couponBatchesReducer,
    coupons: couponsReducer,
    couponTimeFrames: noOpReducer,
    discountBanners: discountBannerReducer,
    equipmentSets: equipmentSetsReducer,
    games: noOpReducer,
    gameSessions: gameSessionsReducer,
    giftCards: giftCardsReducer,
    legalEntities: legalEntitiesReducer,
    priceAdjustments: noOpReducer,
    priceUpdate: noOpReducer,
    rooms: noOpReducer,
    roles: rolesReducer,
    timeSlots: noOpReducer,
    users: usersReducer,
    venueGames: venueGamesReducer,
    venues: venuesReducer,
    vouchers: vouchersReducer,
    voucherIssueReasons: voucherIssueReasonsReducer,
})

const stateReducer = combineReducers({
    me: meReducer,
    pagination: paginationReducer,
    entities: reduceReducers(
        entitiesResponseReducer,
        entitiesReducer,
    ),
    activeFilters: activeFiltersReducer,
    activeModal: activeModalsReducer,
    activeEntities: activeEntitiesReducer,
})

const featuresReducers = [
    activeBookingStatusReducer,
    activeGameSessionStatusReducer,
    activeDiscountBannerReducer,
    removeStateReducer,
]

const rootReducer = reduceReducers(
    stateReducer,
    ...featuresReducers,
)

export default rootReducer

import React from 'react'
import EditGiftCardFormState from 'components/pages/settings/gift-cards/forms/edit/EditGiftCardFormState'
import EditGiftCardModalState from './EditGiftCardModalState'
import EditGiftCardModal from './EditGiftCardModal'

function EditGiftCardModalWithState() {
    return (
        <EditGiftCardModalState>
            {({
                isOpen,
                closeModal,
                giftCard,
            }) => (
                <EditGiftCardFormState
                    onEditSuccess={closeModal}
                    onCancel={closeModal}
                    giftCard={giftCard}
                >
                    {({
                        values,
                        errors,
                        isLoading,
                        handleChange,
                        handleChangeAsValue,
                        handleCancel,
                        handleSubmit,
                    }) => (
                        <EditGiftCardModal
                            isOpen={isOpen}
                            onClose={closeModal}
                            giftCard={values}
                            errors={errors}
                            isLoading={isLoading}
                            onChange={handleChange}
                            onChangeAsValue={handleChangeAsValue}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                        />
                    )}
                </EditGiftCardFormState>
            )}
        </EditGiftCardModalState>
    )
}

export default EditGiftCardModalWithState

import React from "react"
import { Select } from "antd"
import {isValidString} from "../../../../../util/string-utils"

/**
 * @component
 * @param {Object} props
 * @param {RoomResource[]} props.rooms
 * @param {number} props.roomdId The currently selected room id
 * @param {string|undefined=} props.placeholder
 * @param {(e: any) => void} props.onChange
 * @param {boolean} props.disabled
 * @returns {JSX.Element}
 */
export function RoomsSelect(props) {
    const roomsLength = props.rooms.length
    let finalPlaceHolder
    let finalValue
    if (roomsLength === 0) {
        finalPlaceHolder = "Loading rooms"
        finalValue = null
    } else {
        finalPlaceHolder = isValidString(props.placeholder) ? props.placeholder : "Select a room"
        finalValue = props.roomdId
    }

    return (
        <Select
            value={finalValue}
            onChange={props.onChange}
            disabled={props.disabled || roomsLength === 0}
            placeholder={finalPlaceHolder}
        >
            {
                props.rooms.map((room) => (
                    <Select.Option
                        key={room.id}
                        value={room.id}
                    >
                        {room.name}
                    </Select.Option>
                ))
            }
        </Select>
    )
}

import React from 'react'
import { DeleteModal } from '../../../../util/modals/DeleteModal'
import { DeleteVoucherFormState } from '../forms/delete/DeleteVoucherFormState'

/**
 * @param {Object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.onClose
 * @param {Object} props.voucher
 * @returns {React.JSX.Element}
 * @constructor
 */
export function DeleteVoucherModal(props) {
    if (typeof props.voucher === 'undefined' || props.voucher === null) {
        return null
    }

    // console.log('DeleteVoucherModal props:', props)

    return (
        <DeleteVoucherFormState
            isOpen={props.isOpen}
            onCancel={props.onClose}
            onDeleteVoucherSuccess={props.onClose}
            voucher={props.voucher}
        >

            {({
                isLoading,
                handleSubmit,
                handleCancel,
            }) => (
                <DeleteModal
                    title={'Delete voucher'}
                    isOpen={props.isOpen}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    entityName={` voucher with code ${props.voucher.coupon.code}`}
                    isLoading={isLoading}
                    closable={!isLoading}
                    maskClosable={!isLoading}
                />
            )}
        </DeleteVoucherFormState>
    )
}

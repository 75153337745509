import React, { useEffect } from 'react'
import useFormState from 'use-form-state'
import useUnauthorizedState from 'components/hooks/useUnauthorizedState'
import useApi, { METHODS } from 'components/hooks/useApi'
import { message } from 'antd'
import handleApiErrorResponse from 'util/errorResponse'
import ForgotPassword from './ForgotPassword'

function ForgotPasswordWithState() {
    const {
        email,
        setEmail,
    } = useUnauthorizedState()
    const {
        values,
        errors,
        handleNativeChange,
        updateErrors,
    } = useFormState({
        email,
    })
    const [
        requestReset, { loading },
    ] = useApi('/password/email', { method: METHODS.POST })

    // Update global unauthorizedState
    useEffect(() => {
        setEmail(values.email)
    }, [setEmail, values.email])

    const onSubmit = async () => {
        try {
            updateErrors({})
            const { status } = await requestReset(null, { email: values.email })
            message.success(status)
        } catch (error) {
            handleApiErrorResponse(error, updateErrors)
        }
    }

    return (
        <ForgotPassword
            values={values}
            errors={errors}
            handleNativeChange={handleNativeChange}
            onSubmit={onSubmit}
            loading={loading}
        />
    )
}

export default ForgotPasswordWithState

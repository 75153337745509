import React from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Table, Button } from 'antd'
import { firstBy } from 'thenby'
import { capitalize, whereId } from 'util/index'
import {
    MODAL_IDS,
    PLAYER_AND_TOTAL,
    WEEK_DAYS,
    APP_TIME_FORMAT,
} from 'util/constants'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import EditPriceAdjustmentModalState from
    'components/pages/settings/venue-games/modals/edit-price-adjustment/EditPriceAdjustmentModalState'
import dayjs from 'dayjs'
import PriceAdjustmentsTableHeader from './PriceAdjustmentsTableHeader'

const sortedByDay = (priceAdjustments) => (
    typeof priceAdjustments !== 'undefined'
        ? priceAdjustments.sort(firstBy('day').thenBy('from').thenBy('until'))
        : []
)

function PriceAdjustmentsTable({
    venueGame,
    priceSystem,
    isAdmin,
    isLegalEntityManager,
    isVenueManager,
}) {
    const columns = [{
        title: 'Day',
        dataIndex: 'day',
        render: (day) => capitalize(WEEK_DAYS.find(whereId(day)).name),
    }, {
        title: 'From',
        dataIndex: 'from',
        render: (from) => dayjs.utc(from, 'HH:mm').format(APP_TIME_FORMAT),
    }, {
        title: 'Until',
        dataIndex: 'until',
        render: (until) => dayjs.utc(until, 'HH:mm').format(APP_TIME_FORMAT),
    }, {
        title: 'Player price',
        dataIndex: 'playerPriceFormatted',
    }, {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (text, record) => (
            <>
                <EditPriceAdjustmentModalState priceAdjustment={record}>
                    {({ openModal }) => (
                        <Button
                            ghost
                            icon={<EditOutlined />}
                            type="primary"
                            shape="circle"
                            size="small"
                            className="ant-btn-no-border"
                            disabled={!isAdmin && !isLegalEntityManager && !isVenueManager}
                            onClick={openModal}
                        />
                    )}
                </EditPriceAdjustmentModalState>
                <WithActiveModal
                    modalId={MODAL_IDS.DELETE_PRICE_ADJUSTMENT}
                    entity={record}
                >
                    {({ openModal }) => (
                        <Button
                            ghost
                            icon={<DeleteOutlined />}
                            type="danger"
                            shape="circle"
                            size="small"
                            className="ant-btn-no-border"
                            disabled={!isAdmin && !isLegalEntityManager && !isVenueManager}
                            onClick={openModal}
                        />
                    )}
                </WithActiveModal>
            </>
        ),
    }]

    if (priceSystem === PLAYER_AND_TOTAL) {
        columns.splice(4, 0, {
            title: 'Total price',
            dataIndex: 'totalPriceFormatted',
        })
    }

    return (
        <Table
            rowKey="id"
            dataSource={sortedByDay(venueGame.priceAdjustments)}
            columns={columns}
            pagination={false}
            /* eslint-disable-next-line react/no-unstable-nested-components */
            title={() => <PriceAdjustmentsTableHeader venueGame={venueGame} />}
        />
    )
}

export default PriceAdjustmentsTable

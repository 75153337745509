import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'

const ID = 'CreateGiftCardModal'

function CreateGiftCardModalState({ children }) {
    return <WithActiveModal modalId={ID}>{children}</WithActiveModal>
}

export default CreateGiftCardModalState

import React from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { firstBy } from 'thenby'
import { capitalize, whereId } from 'util/index'
import { WEEK_DAYS, MODAL_IDS, APP_TIME_FORMAT } from 'util/constants'
import WithMe from 'components/pages/authorized/WithMe'
import useModal from 'components/hooks/useModal'
import dayjs from 'dayjs'
import CouponDetailItem from '../../coupons/details/CouponDetailItem'

const sortedByDay = (timeFrames) => (
    typeof timeFrames !== 'undefined'
        ? timeFrames.sort(firstBy('day').thenBy('from').thenBy('until'))
        : []
)

const getMappedDataSource = (couponBatch) => {
    const { couponTimeFrames, batchUuid } = couponBatch
    return sortedByDay(couponTimeFrames)
        .map((sortedTimeFrame) => ({
            ...sortedTimeFrame,
            batchUuid,
        }))
}

function CouponBatchTimeFramesList({
    couponBatch,
}) {
    const timeFrames = getMappedDataSource(couponBatch)
    const editModal = useModal(MODAL_IDS.EDIT_COUPON_BATCH_TIME_FRAME)
    const deleteModal = useModal(MODAL_IDS.DELETE_COUPON_BATCH_TIME_FRAME)

    return (
        <ul>
            {timeFrames.map((timeFrame) => {
                const label = `${capitalize(WEEK_DAYS.find(whereId(timeFrame.day)).name)}:`
                const from = dayjs.utc(timeFrame.from, 'HH:mm').format(APP_TIME_FORMAT)
                const until = dayjs.utc(timeFrame.until, 'HH:mm').format(APP_TIME_FORMAT)
                const itemText = `${from} - ${until}`

                return (
                    <CouponDetailItem
                        key={timeFrame.id}
                        label={label}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {itemText}
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <WithMe>
                                    {({ isAdmin, isLegalEntityManager }) => (
                                        <>
                                            <Button
                                                ghost
                                                icon={<EditOutlined />}
                                                type="primary"
                                                shape="circle"
                                                size="small"
                                                className="ant-btn-no-border"
                                                disabled={!isAdmin && !isLegalEntityManager}
                                                onClick={() => editModal.openModalWithEntity(timeFrame)}
                                            />
                                            <Button
                                                ghost
                                                icon={<DeleteOutlined />}
                                                type="danger"
                                                shape="circle"
                                                size="small"
                                                className="ant-btn-no-border"
                                                disabled={!isAdmin && !isLegalEntityManager}
                                                onClick={() => deleteModal.openModalWithEntity(timeFrame)}
                                            />
                                        </>
                                    )}
                                </WithMe>
                            </div>
                        </div>
                    </CouponDetailItem>
                )
            })}
        </ul>
    )
}

export default CouponBatchTimeFramesList

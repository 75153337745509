/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-lone-blocks */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import {
    Modal, Form, Input,
} from 'antd'
import './bookingRegisteredPlayerModal.css'
import FormItem from 'components/util/forms/FormItem'
import { ModalFooter } from '../../../util/modals/ModalFooter'

/**
 * @typedef {Object} EditBookingRegisteredPlayerModalProps
 * @property {boolean} isModalOpen
 * @property {() => void} onCancel
 * @property {(firstName: string, lastName: string, email: string, nickname: string) => void} onSubmit
 * @property {{bookingCustomerProfileId: number, firstName: string, lastName: string, email: string, nickname: string, hasAccount: boolean}} playerDetails
 * @definition Modal to edit a registered player from a booking
*/

/**
 * @param {EditBookingRegisteredPlayerModalProps} props
 */

function EditBookingRegisteredPlayerModal(props) {
    const {
        isModalOpen,
        onCancel,
        onSubmit,
        playerDetails,
    } = props

    // Set player details
    const [bookingCustomerProfileId, setBookingCustomerProfileId] = useState('')
    const [playerFirstName, setPlayerFirstName] = useState('')
    const [playerLastName, setPlayerLastName] = useState('')
    const [playerEmail, setPlayerEmail] = useState('')
    const [playerNickname, setPlayerNickname] = useState('')
    const [playerHasAccount, setPlayerHasAccount] = useState('')
    const [isHandlingRequest, setIsHandlingRequest] = useState(false)

    useEffect(() => {
        // Populate the player details
        setBookingCustomerProfileId(playerDetails.bookingCustomerProfileId)
        setPlayerFirstName(playerDetails.firstName)
        setPlayerLastName(playerDetails.lastName)
        setPlayerEmail(playerDetails.email)
        setPlayerNickname(playerDetails.nickname)
        setPlayerHasAccount(playerDetails.hasAccount)
    }, [playerDetails])

    // Set error messages
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        nickname: '',
        general: '',
    })

    const onOkClick = async () => {
        if (playerDetails.hasAccount) {
            onCancel()
        } else {
            setIsHandlingRequest(true)

            // wait for the onSubmit function to finish
            const onSubmitReturn = await onSubmit(
                bookingCustomerProfileId,
                playerFirstName,
                playerLastName,
                playerEmail,
                playerNickname,
                playerHasAccount,
            )

            // if the onSubmit function returns an error, set the error messages
            if (typeof onSubmitReturn !== 'undefined') {
                if (typeof onSubmitReturn.errors !== 'undefined') {
                    setErrors(onSubmitReturn.errors)
                }

                if (onSubmitReturn.code === 'email_already_registered') {
                    setErrors({ email: 'This email is already registered.' })
                }
            }

            setIsHandlingRequest(false)
        }
    }

    const onCancelClick = () => {
        onCancel()
    }

    function onNickNameChange(e) {
        let newValue = ''

        for (let i = 0; i < e.target.value.length; i++) {
            const char = e.target.value[i]
            // decimal character code for 😀, first emoji, check https://www.tamasoft.co.jp/en/general-info/unicode-decimal.html
            const utf16FirstEmojiCharCode = 55357
            // decimal character code for 𝟿, last emoji, check https://www.tamasoft.co.jp/en/general-info/unicode-decimal.html
            // const utf16LastEmojiCharCode = 65039
            // decimal character code for current char
            const utf16CharCode = char.charCodeAt(0)

            if (utf16CharCode < utf16FirstEmojiCharCode) {
                newValue += e.target.value[i]
            }
        }

        setPlayerNickname(newValue)
    }

    return (

        <Modal
            title="Edit a registered player."
            open={isModalOpen}
            okText="Register"
            onOk={onOkClick}
            onCancel={onCancelClick}
            okButtonProps={{ disabled: playerHasAccount || isHandlingRequest }}
            cancelButtonProps={{ disabled: isHandlingRequest }}
            closable={!isHandlingRequest}
            maskClosable={!isHandlingRequest}
            footer={(
                <ModalFooter
                    isLoading={isHandlingRequest}
                    onCancel={onCancelClick}
                    onSubmit={onOkClick}
                />
            )}
        >
            <Form
                className="booking-register-player-form"
                disabled={isHandlingRequest}
            >
                <h3>Edit the details of this current player.</h3>
                {
                    playerHasAccount && (
                        <p className="error">This player has an account, therefore you cannot edit this player.</p>
                    )
                }
                <div className="input-wrapper">
                    <label htmlFor="playerFirstName">First name</label>
                    <FormItem
                        wrapperCol={{}}
                        error={errors.firstName}
                        // disabled={playerDetails.hasAccount}
                    >
                        <Input
                            type="text"
                            value={playerFirstName}
                            onChange={(e) => setPlayerFirstName(e.target.value)}
                            id="playerFirstName"
                            className="input"
                            disabled={playerHasAccount}
                        />
                    </FormItem>
                </div>
                <div className="input-wrapper">
                    <label htmlFor="playerLastName">Last name</label>
                    <FormItem
                        wrapperCol={{}}
                        error={errors.lastName}
                    >
                        <Input
                            type="text"
                            value={playerLastName}
                            onChange={(e) => setPlayerLastName(e.target.value)}
                            id="playerLastName"
                            className="input"
                            disabled={playerHasAccount}
                        />
                    </FormItem>
                </div>
                <div className="input-wrapper">
                    <label htmlFor="playerEmail">Email</label>
                    <FormItem
                        wrapperCol={{}}
                        error={errors.email}
                    >
                        <Input
                            type="text"
                            value={playerEmail}
                            onChange={(e) => setPlayerEmail(e.target.value)}
                            id="playerEmail"
                            className="input"
                            disabled={playerHasAccount}
                        />
                    </FormItem>
                </div>
                <div className="input-wrapper">
                    <label htmlFor="playerNickname">Nickname</label>
                    <FormItem
                        wrapperCol={{}}
                        error={errors.nickname}
                    >
                        <Input
                            type="text"
                            value={playerNickname}
                            onChange={onNickNameChange}
                            id="playerNickname"
                            className="input"
                            disabled={playerHasAccount}
                        />
                    </FormItem>
                </div>
            </Form>
        </Modal>
    )
}

export default EditBookingRegisteredPlayerModal

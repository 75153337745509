import {useCallback, useState} from 'react'
import { callApiWithOptions } from 'store/middleware/callApi'
import { notification } from 'antd'
import FileSaver from 'file-saver'

/**
 * @typedef ReturnObj
 * @property {boolean} isDownloading
 * @property {() => Promise<void>} startDownload
 * @property {(options: {endpoint: string, saveAs: string}) => Promise<void>} startDownloadWithOptions
 */

/**
 * @param {Object|undefined} args
 * @param {string} args.endpoint
 * @param {string} args.saveAs
 * @returns ReturnObj
 */
function useDownload(args) {
    const [isDownloading, setIsDownloading] = useState(false)

    const download = useCallback(async (endpoint, saveAs) => {
        setIsDownloading(true)
        try {
            const response = await callApiWithOptions(endpoint, { useJson: false })
            const blob = await response.blob()
            FileSaver.saveAs(blob, saveAs)
        } catch (error) {
            notification.error({
                message: 'Something went wrong',
                description: error.message,
            })
        }
        setIsDownloading(false)
    }, [])

    // async function download(options) {
    //     setIsDownloading(true)
    //     try {
    //         const response = await callApiWithOptions(options.endpoint, { useJson: false })
    //         const blob = await response.blob()
    //         FileSaver.saveAs(blob, options.saveAs)
    //     } catch (error) {
    //         notification.error({
    //             message: 'Something went wrong',
    //             description: error.message,
    //         })
    //     }
    //     setIsDownloading(false)
    // }

    const startDownload = useCallback(async () => {
        await download(args.endpoint, args.saveAs)
    }, [args.endpoint, args.saveAs, download])

    const startDownloadWithOptions = useCallback(async (options) => {
        await download(options.endpoint, options.saveAs)
    }, [download])

    return {
        startDownload,
        startDownloadWithOptions,
        isDownloading,
    }
}

export default useDownload

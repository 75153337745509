import { PER_PLAYER } from 'util/constants'
import { isRequired } from './index'

export function isValidGameId(gameId) {
    return isRequired(gameId) && typeof gameId === 'number'
}

export function isValidBasePlayerPrice(basePlayerPrice) {
    return isRequired(basePlayerPrice) && typeof basePlayerPrice === 'number'
}

export function isValidBaseTotalPrice(baseTotalPrice) {
    return isRequired(baseTotalPrice) && typeof baseTotalPrice === 'number'
}

export function isValidVenueGame(values) {
    const validGameId = isValidGameId(values.gameId)
    if (!validGameId)
        return false

    const validBasePlayerPrice = isValidBasePlayerPrice(values.basePlayerPrice)
    if (!validBasePlayerPrice)
        return false

    return true
}

export function isValidEditVenueGame(values) {
    if (!isValidBasePlayerPrice(values.basePlayerPrice))
        return false

    return true
}

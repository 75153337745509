import dayjs from 'dayjs'

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const hrefRegex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
const colorRegex = /^#[0-9A-F]{6}$/i

/**
 * @param value
 * @return {boolean}
 */
export function isRequired(value) {
    return typeof value !== 'undefined' &&
       value !== null &&
       value !== '' &&
       value.length !== 0
}

export const isValidEmail = (value) => emailRegex.test(value)

export const isValidHref = (value) => hrefRegex.test(value)

export const isValidColor = (value) => colorRegex.test(value)

export const isValid = (data) => typeof data !== 'undefined' && data !== null

export function getErrMsg(cond, key, msg) {
    if (cond)
        return { [key]: msg }
    else
        return { [key]: null }
}

export const mapArrayKeysToObjectHelper = (obj) => (Object.keys(obj)
    .reduce((acc, curr) => {
        [acc[curr]] = obj[curr]
        return acc
    }, {}))

export const createValidationErrorObject = (validator, key, message) => (...value) => (!validator(...value)
    ? { [key]: message }
    : { [key]: null })

export function isBefore(start, end) {
    return dayjs.utc(start).isBefore(end)
}

export function isAfter(end, start) {
    return dayjs.utc(end).isAfter(start)
}

/**
 * @deprecated I think?
 * @param {any[]} values values to validate
 * @param {(field: any) => boolean} validationFn validation function
 * @return boolean True if everything in array is valid, false if one or more is not
 */
export function validateArray(values, validationFn) {
    if (!Array.isArray(values))
        return false

    for (let i = 0, length = values.length; i < length; i++) {
        const valueIsValid = validationFn(values[i])
        if (!valueIsValid)
            return false
    }

    return true
}

/**
 * @param {string} value
 * @return {boolean}
 */
export function isAlphaNumeric(value) {
    const result = value.match(/(^([A-Z_]+)(\d+)?$)/u)
    return result !== null
}

/**
 * @param {any} val
 * @return {boolean}
 */
export function isVat100Percentage(val) {
    return typeof val === "number" && val >= 0.0 && val <= 100.0
}

export function isPositiveNumber(val) {
    return typeof val === "number" && val > 0.0
}

export function isPositiveNumberOrZero(val) {
    return typeof val === "number" && val >= 0.0
}
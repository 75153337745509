import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import { mapDiscountBannerToValues, toDiscountBannerApiRequest } from 'util/transformers/discountBanner'
import { isValidEditForm } from 'util/validation/discountBanner'
import { getEditDiscountBannerFormErrors } from 'util/validation/discountBannerErrors'
import { getEntityFromResponse } from 'util/index'
import EditDiscountBannerRequest from './EditDiscountBannerRequest'

function EditDiscountBannerFormState({
    discountBanner,
    onCancel,
    onEditSuccess,
    children,
}) {
    return (
        <EditDiscountBannerRequest paginationKey="overview" discountBanner={discountBanner}>
            {({
                fetchDiscountBanners,
                editDiscountBanner,
                editDiscountBannerSuccess,
                editDiscountBannerFailed,
            }) => {
                if (discountBanner === null || typeof discountBanner === 'undefined') {
                    return null
                }
                return (
                    <WithFormState
                        key={discountBanner.id}
                        initialValues={discountBanner && mapDiscountBannerToValues(discountBanner)}
                        isValidForm={isValidEditForm}
                        getFormErrors={getEditDiscountBannerFormErrors}
                        transformValuesToRequestBody={toDiscountBannerApiRequest}
                        onSendRequest={(values) => editDiscountBanner(values)}
                        onCancel={onCancel}
                        onRequestSuccess={(response) => {
                            const updatedDiscountBanner = getEntityFromResponse(response, 'discountBanners')
                            fetchDiscountBanners()
                            editDiscountBannerSuccess(updatedDiscountBanner)
                            onEditSuccess(updatedDiscountBanner)
                        }}
                        onRequestFailed={editDiscountBannerFailed}
                    >
                        {children}
                    </WithFormState>
                )
            }}
        </EditDiscountBannerRequest>
    )
}

export default EditDiscountBannerFormState

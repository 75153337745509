import React from "react"
import { getEntityFromResponse } from "util/index"
import { PricingRequest } from "../../requests/PricingRequest"
import { useLegalEntity } from "../../../../hooks/useLegalEntity"
import {EditBookingModal} from "./EditBookingModal"
import {useFetchPriceState} from "../../../../hooks/useFetchPriceState"
import {EditBookingFormState} from "../../forms/edit/EditBookingFormState"
import {EditBookingModalState} from "./EditBookingModalState"

/**
 * @component
 * @param {Object} props
 * @returns {JSX.Element}
 */
export function EditBookingModalWithState() {
    const priceHandle = useFetchPriceState()
    const legalEntity = useLegalEntity()
    if (typeof legalEntity === "undefined")
        throw new Error(`Legal Entity is undefined! Comp: ${EditBookingModalWithState.name}`)

    return (
        <EditBookingModalState>
            {(modalChildProps) => {
                // console.log("modalChildProps", modalChildProps)

                if (typeof modalChildProps.booking === "undefined" || modalChildProps.booking === null)
                    return null

                return (
                    <EditBookingFormState
                        onEditSuccess={modalChildProps.closeModal}
                        onCancel={modalChildProps.closeModal}
                        booking={modalChildProps.booking}
                    >
                        {(formChildProps) => {
                            // console.log("formChildProps", formChildProps)
                            return (
                                <PricingRequest>
                                    {({
                                        fetchPriceUpdate,
                                    }) => (
                                        <EditBookingModal
                                            isOpen={modalChildProps.isOpen}
                                            onClose={modalChildProps.closeModal}
                                            values={formChildProps.values}
                                            errors={formChildProps.errors}
                                            isLoading={formChildProps.isLoading}
                                            onChange={formChildProps.handleChange}
                                            onChangeAsValue={formChildProps.handleChangeAsValue}
                                            onCancel={formChildProps.handleCancel}
                                            onSubmit={formChildProps.handleSubmit}
                                            mergeValues={formChildProps.mergeValues}
                                            triggerPriceUpdate={async (body) => {
                                                priceHandle.setPriceRequest()
                                                try {
                                                    const { response } = await fetchPriceUpdate(formChildProps.booking.id, body)
                                                    const data = getEntityFromResponse(response, "priceUpdate")
                                                    priceHandle.setPriceRequestSuccess(data)
                                                } catch (error) {
                                                    console.error(error)
                                                    priceHandle.setPriceRequestFailed()
                                                }
                                            }}
                                            priceHandle={priceHandle}
                                            priceSystem={legalEntity.priceSystem}
                                        />
                                    )}
                                </PricingRequest>
                            )
                        }}
                    </EditBookingFormState>
                )
            }}
        </EditBookingModalState>
    )
}

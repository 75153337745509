import { isRequired, isValidHref } from './index'

export function isValidName(name) {
    return isRequired(name)
}

export function isValidSeats(seats) {
    return isRequired(seats) && seats > 0
}

export function isValidSlotDuration(duration) {
    return isRequired(duration) && duration > 0
}

/**
 * @param {string} roomConfigFolder
 * @return {boolean}
 */
export function isValidRoomConfigFolder(roomConfigFolder) {
    if (typeof roomConfigFolder !== 'string' || roomConfigFolder.length === 0)
        return false

    return isValidHref(roomConfigFolder)
}

export function isValidRoom(values) {
    return isValidName(values.name)
    && isValidSeats(values.seats)
    && isValidSlotDuration(values.slotDuration)
}

export function isValidEditRoom(values) {
    return isValidName(values.name)
    && isValidSeats(values.seats)
    // && isValidRoomConfigFolder(values.roomConfigFolder)
}

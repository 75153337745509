/* eslint-disable react/no-unused-state,max-len */
import React from 'react'
import { connect } from 'react-redux'
import AppStateProvider from 'components/providers/AppStateProvider'
import * as storage from 'util/storage'
import { callApiWithOptions, loadAndValidateTokens } from 'store/middleware/callApi'
import { fetchLegalEntities as fetchLegalEntitiesAction } from 'store/actions/legalEntities'
import { removeScopedSentryUser } from 'util/sentry'
import { getCurrencyDisplayName, getLocaleCurrencySymbol } from 'util/currency'
import App from './App'
import AppErrorBoundary from './AppErrorBoundary'
import EnvironmentBanner from '../../EnvironmentBanner'

const PAGE_SIZE = 15

/**
 * @typedef {Object} Currency
 * @property {string} currencyCode E.g. 'EUR'
 * @property {string} currency Readable currency name E.g. 'Euro'
 * @property {string } currencySymbol Readable currency symbol E.g. '€'
 */

/**
 * @typedef {Object} AppState
 * @property {boolean} isAuthenticated
 * @property {boolean} isAvailable
 * @property {boolean} isLoading
 * @property {CountryResource[]} countries
 * @property {Currency[]} currencies
 * @property {() => Promise<boolean>} login
 * @property {() => void} logout
 */

class AppWithState extends React.Component {
    constructor(props) {
        super(props)
        this.login = this.login.bind(this)
        this.logout = this.logout.bind(this)

        this.state = {
            isAuthenticated: false,
            isLoading: true,
            isAvailable: true,
            countries: [],
            currencies: [],
            login: this.login,
            logout: this.logout,
        }
    }

    async componentDidMount() {
        this.setState({
            countries: [],
            isLoading: true,
        })

        try {
            // Check app health
            const { data: { isAvailable } } = await callApiWithOptions('/health/operations', { isInternal: false })
            this.setState({ isAvailable })

            if (isAvailable)
                await this.loadTokens()
        } catch (error) {
            this.setState({ isAvailable: false })
        }

        this.setState({ isLoading: false })
    }

    async getInitialData(isAuthenticated) {
        if (!isAuthenticated)
            return false

        this.setState({ isLoading: true })
        try {
            const { data: countries } = await callApiWithOptions('/countries', { isInternal: false })
            // const { data: currencies } = await callApiWithOptions('/currencies', { isInternal: false })
            const currencies = await callApiWithOptions('/currencies', { isInternal: false })

            await this.props.fetchLegalEntities()
            // const countriesMapped = Object.fromEntries(
            //     Object.entries(countries).map(([k, v]) => [k[0].toUpperCase() + k.slice(1), v]),
            // )

            const currenciesMapped = currencies.reduce((currenciesAcc, currency) => {
                const newCurrencies = { ...currenciesAcc }
                newCurrencies[currency] = {
                    currencyCode: currency,
                    currency: getCurrencyDisplayName(currency, 'en-US'),
                    currencySymbol: getLocaleCurrencySymbol(currency, 'en-US'),
                }
                return newCurrencies
            }, {})

            this.setState({
                countries,
                currencies: currenciesMapped,
            })
        } catch (error) {
            this.setState({ isAvailable: false })
        }
        this.setState({ isLoading: false })
        return true
    }

    async loadTokens() {
        const { accessToken } = await loadAndValidateTokens()
        const isAuthenticated = accessToken !== null
        this.setState({ isAuthenticated })
        const result = await this.getInitialData(isAuthenticated)
        return result
    }

    async login(accessToken, refreshToken) {
        await storage.setAccessToken(accessToken)
        await storage.setRefreshToken(refreshToken)
        const isAuthenticated = accessToken !== null
        this.setState({ isAuthenticated })
        const result = await this.getInitialData(isAuthenticated)
        return result
    }

    async logout() {
        await storage.removeAccessToken()
        await storage.removeRefreshToken()
        this.setState({ isAuthenticated: false })
        removeScopedSentryUser()
    }

    render() {
        return (
            <AppStateProvider value={this.state}>
                <AppErrorBoundary>
                    <EnvironmentBanner env={process.env.REACT_APP_ENV} colors={{ local: '#0F0' }} />
                    <App />
                </AppErrorBoundary>
            </AppStateProvider>
        )
    }
}
function mapDispatchToProps(dispatch) {
    return {
        fetchLegalEntities: () => {
            dispatch(fetchLegalEntitiesAction('overview', {
                page: 1,
                limit: PAGE_SIZE,
            }))
        },
    }
}

export default connect(null, mapDispatchToProps)(AppWithState)

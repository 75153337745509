import React from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { Table, Button } from 'antd'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import WithMe from 'components/pages/authorized/WithMe'
import { MODAL_IDS } from 'util/constants'
import EquipmentSetRoom from './EquipmentSetRoom'

const columns = [{
    title: 'UID',
    dataIndex: 'uid',
}, {
    title: 'Name',
    dataIndex: 'name',
}, {
    title: 'RoomResource',
    dataIndex: 'roomId',
    render: (roomId) => (
        <EquipmentSetRoom roomId={roomId}>
            {({ room }) => (
                !room.isFetching && room.name
            )}
        </EquipmentSetRoom>
    ),
}, {
    title: 'Action',
    key: 'action',
    align: 'center',
    render: (text, record) => (
        <WithMe>
            {({ isReadOnly }) => (
                <WithActiveModal
                    modalId={MODAL_IDS.DELETE_EQUIPMENT_SET}
                    entity={record}
                >
                    {({ openModal }) => (
                        <Button
                            ghost
                            icon={<DeleteOutlined />}
                            type="danger"
                            shape="circle"
                            size="small"
                            className="ant-btn-no-border"
                            disabled={isReadOnly}
                            onClick={openModal}
                        />
                    )}
                </WithActiveModal>
            )}
        </WithMe>
    ),
}]

function EquipmentSetsTable({
    equipmentSets,
    isFetching,
}) {
    return (
        <Table
            rowKey="id"
            columns={columns}
            loading={isFetching}
            dataSource={equipmentSets}
            pagination={false}
        />
    )
}

export default EquipmentSetsTable

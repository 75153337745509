import dotProp from 'dot-prop-immutable'
import { SET_DISCOUNT_BANNER_ACTIVE, SET_DISCOUNT_BANNER_INACTIVE } from '../../actions/discountBanners'

const activeDiscountBannerReducer = (state, action) => {
    const { type, discountBannerId } = action
    switch (type) {
        case SET_DISCOUNT_BANNER_ACTIVE: {
            return dotProp.set(state, 'entities.discountBanners', (discountBanners) => Object
                .keys(discountBanners)
                .map((id) => discountBanners[id])
                .map((discountBanner) => ({
                    ...discountBanner,
                    active: discountBanner.id === discountBannerId,
                }))
                .reduce((acc, curr) => {
                    acc[curr.id] = curr
                    return acc
                }, {}))
        }
        case SET_DISCOUNT_BANNER_INACTIVE: {
            return dotProp.set(state, `entities.discountBanners.${discountBannerId}.active`, false)
        }
        default:
            return state
    }
}

export default activeDiscountBannerReducer

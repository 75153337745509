import React from 'react'
import { deletePriceAdjustment } from 'store/actions/priceAdjustments'
import { fetchVenueGamesExtendCurrentParams } from 'store/actions/venueGames'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    deletePriceAdjustment: async (priceAdjustment) => dispatch(deletePriceAdjustment(priceAdjustment.gameId, priceAdjustment.id)),
    deletePriceAdjustmentSuccess: () => {
        notification.success({
            message: 'Price adjustment deleted!',
            description: (
                <span>
                    The selected price adjustment is succesfully deleted.
                </span>
            ),
        })
    },
    deletePriceAdjustmentFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchVenueGames: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchVenueGamesExtendCurrentParams(paginationKey))
    },
})

const DeletePriceAdjustmentRequest = withReduxState(null, mapDispatchToProps)

export default DeletePriceAdjustmentRequest

import React, { useEffect, useState } from 'react'
import {
    Input, Modal, Table, Tooltip, notification,
} from 'antd'
import { ModalFooter } from 'components/util/modals/ModalFooter'
import { callApiWithOptions, METHODS } from 'store/middleware/callApi'
import { InfoCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import useCouponBatches from '../../hooks/useCouponBatches'
import { ACTIVE_FILTER } from '../../details/CouponsBatchTableTopBar'
import { APP_DATE_FORMAT } from '../../../../../../util/constants'

const { Search } = Input

const columns = [
    {
        title: 'Code',
        width: 200,
        dataIndex: 'code',
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        width: 100,
        render: (text, { isPercentage }) => (isPercentage ? `${(text * 100).toFixed(0)}%` : text),
    }, {
        title: 'Expires',
        dataIndex: 'expiresAt',
        width: 110,
        render: (expiresAt) => expiresAt && dayjs.utc(expiresAt).format(APP_DATE_FORMAT),
    },
]

function MarkCouponBatchUsedModal({ isOpen, onClose }) {
    const {
        isFetching,
        currentPage,
        total,
        params,
        couponsForCurrentPage,
        fetchCouponBatches,
    } = useCouponBatches()

    const [searchFilter, setSearchFilter] = useState('')
    const [coupons, setCoupons] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [disableUpdate, setDisableUpdate] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])

    useEffect(() => {
        // Set the udpate button to disable if the coupons being marked are expired.
        if (selectedRowKeys.length > 0) {
            // gets the only element that should be in the array.
            const selectedItem = selectedRowKeys[0]
            const coupon = coupons.find((c) => c.id === selectedItem)
            if (coupon) {
                setDisableUpdate(dayjs.utc().isAfter(dayjs.utc(coupon.expiresAt)))
            }
        }
    }, [coupons, selectedRowKeys])

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
            disabled: dayjs.utc().isAfter(dayjs.utc(record.expiresAt)),
        }),
    }

    const fetchCouponCodes = async () => {
        if (searchFilter.length <= 0) return

        setIsLoading(true)
        const response = await callApiWithOptions(`/coupons-batch/coupons?searchByCode=${searchFilter}`)
        setIsLoading(false)
        if (response) {
            const dataCoupons = response.data

            const selectedCoupons = dataCoupons.map((coupon) => (coupon.usedAt != null ? coupon.id : null))

            setCoupons(dataCoupons)
            setSelectedRowKeys(selectedCoupons)
        }
    }

    const clearData = () => {
        setCoupons([])
        setSearchFilter('')
    }

    const handleSubmit = async () => {
        try {
            if (searchFilter.length > 0) {
                let payload = {}
                if (selectedRowKeys.length === 0) {
                    payload = {
                        reverted: coupons.map((coupon) => coupon.id),
                        markUsed: [],
                    }
                } else {
                    const revertedCoupons = coupons.filter((coupon) => !selectedRowKeys.includes(coupon.id))
                    payload = {
                        reverted: revertedCoupons.map((coupon) => coupon.id),
                        markUsed: selectedRowKeys,
                    }
                }

                // TODO: Check to ensure no request get sent for expired coupons to the api.

                const response = await callApiWithOptions('/coupons-batch/coupons', { method: METHODS.PUT, body: payload })
                if (response) {
                    clearData()
                    onClose()
                    notification.success({
                        message: 'Coupon codes updated!',
                        description: 'Coupon codes were successfully updated.',
                    })
                    fetchCouponBatches({ ...params, status: ACTIVE_FILTER })
                }
            }
        } catch (error) {
            notification.error({
                message: 'Something went wrong',
                description: `${error.message}`,
            })
        }
    }

    return (
        <Modal
            width={900}
            title={(
                <p>
                    Mark batch coupons as used
                    <span style={{ marginLeft: '20px' }}>
                        <Tooltip placement="right" title="To mark a coupon as used, search for the code, select the checkbox and then press submit.">
                            <InfoCircleOutlined />
                        </Tooltip>
                    </span>

                </p>
            )}
            open={isOpen}
            onCancel={() => {
                clearData()
                onClose()
            }}
            footer={(
                <ModalFooter
                    submitText="Update"
                    onCancel={() => {
                        clearData()
                        onClose()
                    }}
                    disableSubmit={disableUpdate}
                    onSubmit={handleSubmit}
                />
            )}
        >
            {isOpen && (
                <>
                    <Search
                        value={searchFilter}
                        placeholder="Type to search  codes..."
                        onChange={((e) => setSearchFilter(e.target.value))}
                        onSearch={() => {
                            fetchCouponCodes()
                        }}
                    />
                    <br />
                    <br />
                    <br />

                    <Table
                        rowSelection={rowSelection}
                        rowKey="id"
                        columns={columns}
                        loading={isLoading}
                        dataSource={coupons}
                        pagination={false}
                    />
                </>
            )}
        </Modal>
    )
}

export default MarkCouponBatchUsedModal

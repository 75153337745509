import createEntityReducer from '../hor/createEntityReducer'
import {
    EQUIPMENT_SET_REQUEST,
    EQUIPMENT_SET_SUCCESS,
    EQUIPMENT_SET_FAILURE,
} from '../../actions/equipmentSets'

const equipmentSetsReducer = createEntityReducer({
    types: [
        EQUIPMENT_SET_REQUEST,
        EQUIPMENT_SET_SUCCESS,
        EQUIPMENT_SET_FAILURE,
    ],
})

export default equipmentSetsReducer

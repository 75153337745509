import React from 'react'
import {
    Form, Col, Row, Radio, TimePicker,
} from 'antd'
import FormItem from 'components/util/forms/FormItem'
import WeekdaySelect from 'components/pages/settings/venue-games/forms/WeekdaySelect'
import { APP_TIME_FORMAT } from '../../../../../../util/constants'

function CouponTimeFrameForm({
    values,
    errors,
    mergeValues,
    onChangeAsValue,
}) {
    return (
        <Form>
            <FormItem
                label="Day of the week"
                error={errors.day}
                required
            >
                <WeekdaySelect
                    value={values.day}
                    onChange={(day) => onChangeAsValue('day', day)}
                />
            </FormItem>
            <FormItem
                label="Type"
                error={errors.isWholeDay}
            >
                <Radio.Group
                    name="couponTimeFrameType"
                    value={values.isWholeDay}
                    onChange={(e) => {
                        mergeValues({
                            isWholeDay: e.target.value,
                            from: null,
                            until: null,
                        })
                    }}
                >
                    <Radio.Button value={1}>
                        Whole day
                    </Radio.Button>
                    <Radio.Button value={0}>
                        Timeframe
                    </Radio.Button>
                </Radio.Group>
            </FormItem>
            {!values.isWholeDay && (
                <Row gutter={8}>
                    <Col xs={{ span: 12 }} sm={{ span: 8, offset: 8 }}>
                        <FormItem
                            label="From"
                            error={errors.from}
                            required
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <TimePicker
                                style={{ width: '100%' }}
                                value={values.from}
                                allowClear={false}
                                minuteStep={15}
                                hideDisabledOptions
                                format={APP_TIME_FORMAT}
                                onSelect={(from) => onChangeAsValue('from', from)}
                            />
                        </FormItem>
                    </Col>
                    <Col xs={12} sm={8}>
                        <FormItem
                            label="Until"
                            error={errors.until}
                            required
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <TimePicker
                                style={{ width: '100%' }}
                                value={values.until}
                                placeholder="Select time"
                                allowClear={false}
                                minuteStep={15}
                                hideDisabledOptions
                                format={APP_TIME_FORMAT}
                                onSelect={(until) => onChangeAsValue('until', until)}
                            />
                        </FormItem>
                    </Col>
                </Row>
            )}
        </Form>
    )
}

export default CouponTimeFrameForm

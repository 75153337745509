import dotProp from 'dot-prop-immutable'
import {
    SET_BOOKING_ACTIVE,
    SET_BOOKING_INACTIVE,
} from '../actions/bookings'
import {
    SET_GAME_SESSION_ACTIVE,
    SET_GAME_SESSION_INACTIVE,
} from '../actions/gameSessions'
import {
    SET_DISCOUNT_BANNER_ACTIVE,
    SET_DISCOUNT_BANNER_INACTIVE,
} from '../actions/discountBanners'

export const defaultActiveEntitiesState = {
    bookings: [],
    gameSessions: [],
    discountBanners: [],
}

// eslint-disable-next-line default-param-last
const activeEntitiesReducer = (state = defaultActiveEntitiesState, action) => {
    const {
        type, bookingId, gameSessionId, discountBannerId,
    } = action
    switch (type) {
        case SET_BOOKING_ACTIVE:
            return dotProp.set(state, 'bookings', (bookings) => [...new Set([...bookings, bookingId])])
        case SET_BOOKING_INACTIVE:
            return dotProp.set(state, 'bookings', (bookings) => bookings.filter((id) => id !== bookingId))
        case SET_GAME_SESSION_ACTIVE:
            return dotProp.set(state, 'gameSessions', (gameSessions) => [...new Set([...gameSessions, gameSessionId])])
        case SET_GAME_SESSION_INACTIVE:
            return dotProp.set(state, 'gameSessions', (sessions) => sessions.filter((id) => id !== gameSessionId))
        case SET_DISCOUNT_BANNER_ACTIVE:
            return dotProp.set(state, 'discountBanners', (discountBanners) => (
                [...new Set([...discountBanners, discountBannerId])]
            ))
        case SET_DISCOUNT_BANNER_INACTIVE:
            return dotProp.set(state, 'discountBanners', (discountBanners) => (
                discountBanners.filter((id) => id !== discountBannerId)
            ))
        default:
            return state
    }
}

export default activeEntitiesReducer

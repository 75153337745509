import React from 'react'
import { Modal } from 'antd'
import { ModalFooter } from 'components/util/modals/ModalFooter'
import {CreateBookingForm} from "../../forms/create/CreateBookingForm"

/**
 * @component
 * @param {Object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.onClose
 * @param {CreateOrEditBookingFormValues} props.values
 * @param {{ [key in keyof CreateOrEditBookingFormValues]: string[]}} props.errors
 * @param {boolean} props.isLoading
 * @param {(valuesToMerge: Object) => void} props.mergeValues
 * @param {(e: any) => void} props.onChange
 * @param {(key: keyof CreateOrEditBookingFormValues, value: CreateOrEditBookingFormValues[key]) => void } props.onChangeAsValue
 * @param {() => void} props.onCancel
 * @param {(e: any) => void } props.onSubmit
 * @param {string} props.priceSystem
 * @return {React.Element|React.Element[]|null}
 */
function CreateBookingModal(props) {
    return (
        <Modal
            title="Create new booking"
            width={700}
            open={props.isOpen}
            onOk={props.onSubmit}
            onCancel={props.onClose}
            closable={!props.isLoading}
            maskClosable={!props.isLoading}
            footer={(
                <ModalFooter
                    isLoading={props.isLoading}
                    onCancel={props.onCancel}
                    onSubmit={props.onSubmit}
                />
            )}
        >
            {
                props.isOpen && (
                    <CreateBookingForm
                        isLoading={props.isLoading}
                        values={props.values}
                        errors={props.errors}
                        mergeValues={props.mergeValues}
                        onChange={props.onChange}
                        onChangeAsValue={props.onChangeAsValue}
                        onCreateSuccess={props.onClose}
                        priceSystem={props.priceSystem}
                    />
                )
            }
        </Modal>
    )
}

export default CreateBookingModal

import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import './ExtraInfoLabel.css'

/**
 * @param {Object} props
 * @param {string} props.message Message to display
 * @param {'danger' | 'warning' | '' | undefined} props.type Type of message
 * @returns {React.JSX.Element}
 * @constructor
 */
function ExtraInfoLabel(props) {
    const danger = props.type === 'danger'
    const warning = props.type === 'warning'
    return (
        <div className={classNames('extraInfoLabel', { danger, warning })}>
            <InfoCircleOutlined />
            <span> {props.message}</span>
        </div>
    )
}

export default ExtraInfoLabel

import React from 'react'
import { Form, DatePicker, TimePicker } from 'antd'
import Entity from 'components/util/Entity'
import FormItem from 'components/util/forms/FormItem'
import ExtraInfoLabel from 'components/util/forms/ExtraInfoLabel'
import { valueIsSet, getDisabledMinutes } from 'util/index'
import dayjs from 'dayjs'
import { APP_TIME_FORMAT } from '../../../../../util/constants'
import RoomsSelect from "../../table/RoomsSelect"
import {GamesSelect} from "../../../settings/games/selects/GamesSelect"

function CreateGameSessionForm({
    isLoading,
    values = {},
    errors = {},
    mergeValues,
    onChangeAsValue,
}) {
    const gameIsSelected = (game) => valueIsSet(game)
    const roomIsSelected = (room) => valueIsSet(room)
    const dateIsSelected = (date) => valueIsSet(date)
    const isOutsideBookableHours = (bookableHours, roomStart) => {
        const selectedDay = dayjs.utc(values.date).format('dddd').toLowerCase()
        const { start, end } = bookableHours[selectedDay]
        const startHour = dayjs.utc(start, APP_TIME_FORMAT)
        const endHour = dayjs.utc(end, APP_TIME_FORMAT)
        const selectedHour = roomStart && dayjs.utc(roomStart, APP_TIME_FORMAT)
        return selectedHour !== null
      && (selectedHour.isBefore(startHour) || selectedHour.isAfter(endHour))
    }
    return (
        <Form>
            <Entity entityKey="venueGames" id={values.game}>
                {({ duration }) => (
                    <>
                        <FormItem
                            isRequired
                            label="Game"
                            error={errors.game}
                            extra={!values.game && (
                                <ExtraInfoLabel
                                    message="You must choose a game first in order to proceed."
                                />
                            )}
                        >
                            <GamesSelect
                                value={values.game}
                                onChange={(game) => {
                                    mergeValues({
                                        game,
                                        room: undefined, // We set room undefined to show placeholder
                                        date: null,
                                    })
                                }}
                            />
                        </FormItem>
                        <FormItem
                            isRequired
                            label="Room"
                            error={errors.room}
                        >
                            <RoomsSelect
                                gameId={values.game}
                                disabled={!gameIsSelected(values.game) || isLoading}
                                value={values.room}
                                onChange={(room) => {
                                    mergeValues({
                                        room,
                                        date: null,
                                        roomStart: null,
                                    })
                                }}
                            />
                        </FormItem>
                        <FormItem
                            isRequired
                            label="Date"
                            error={errors.date}
                        >
                            <DatePicker
                                disabled={!gameIsSelected(values.game)}
                                disabledDate={(current) => current && current < dayjs.utc().startOf('day')}
                                format="DD-MM-YYYY"
                                placeholder="Select day"
                                value={values.date}
                                onChange={(date) => {
                                    mergeValues({
                                        date,
                                        roomStart: null,
                                    })
                                }}
                            />
                        </FormItem>
                        <Entity entityKey="rooms" id={values.room}>
                            {({ bookableHours, startsAt }) => (
                                <FormItem
                                    isRequired
                                    label="Game starts at"
                                    error={errors.roomStart}
                                    extra={(
                                        <>
                                            {(!roomIsSelected(values.room) || !dateIsSelected(values.date)) && (
                                                <ExtraInfoLabel
                                                    message="You must choose a date first in order to proceed."
                                                />
                                            )}
                                            {
                                                roomIsSelected(values.room)
                                                && dateIsSelected(values.date)
                                                && isOutsideBookableHours(bookableHours, values.roomStart) && (
                                                    <ExtraInfoLabel
                                                        message="The selected time slot is outside the bookable hours"
                                                        type="warning"
                                                    />
                                                )
                                            }
                                        </>
                                    )}
                                >
                                    <TimePicker
                                        disabled={
                                            !roomIsSelected(values.room)
                                            || !dateIsSelected(values.date)
                                        }
                                        format="HH:mm"
                                        placeholder="Select time"
                                        disabledMinutes={() => getDisabledMinutes(startsAt, duration)}
                                        hideDisabledOptions
                                        value={values.roomStart}
                                        onSelect={(value) => onChangeAsValue('roomStart', value)}
                                    />
                                </FormItem>
                            )}
                        </Entity>
                    </>
                )}
            </Entity>
        </Form>
    )
}

export default CreateGameSessionForm

import React from 'react'
import { createGiftCard, fetchGiftCardsExtendCurrentParams } from 'store/actions/giftCards'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    createGiftCard: (values) => dispatch(createGiftCard(values)),
    createGiftCardSuccess: (giftCard) => {
        notification.success({
            message: 'Gift card added!',
            description: (
                <span>
                    A new gift card for&nbsp;
                    <strong>
                        {giftCard.firstName}
                        {' '}
                        {giftCard.lastName}
                    </strong>
                    &nbsp;
                    was successfully created.
                </span>
            ),
        })
    },
    createGiftCardFailed: (error) => {
        notification.error({
            message: 'Something went wrong!',
            description: `${error.message}`,
        })
    },
    fetchGiftCards: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchGiftCardsExtendCurrentParams(paginationKey))
    },
})

const CreateGiftCardRequest = withReduxState(null, mapDispatchToProps)

export default CreateGiftCardRequest

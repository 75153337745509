export function toUserApiRequest(values) {
    return {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        roleId: values.role,
        venueIds: values.venues,
        legalEntityIds: values.legalEntities,
        defaultVenueId: values.defaultVenue,
    }
}

export function mapUserToValues(values) {
    return {
        ...values,
        legalEntities: values.legalEntities.map(({ id }) => id),
        defaultVenue: values.defaultVenue !== null ? values.defaultVenue.id : null,
    }
}

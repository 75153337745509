import { isRequired, isValidEmail } from './index'

export function isValidFirstName(firstName) {
    return isRequired(firstName)
}

export function isValidLastName(lastName) {
    return isRequired(lastName)
}

export function isValidPersonEmail(email) {
    return isRequired(email) && isValidEmail(email)
}

export function isValidRecipient(recipientName) {
    return isRequired(recipientName)
}

export function isValidAmount(amount) {
    return isRequired(amount)
        && typeof amount === 'number'
        && amount > 0
}

export function isValidGiftCard(values) {
    return isValidFirstName(values.firstName)
        && isValidLastName(values.lastName)
        && isValidPersonEmail(values.email)
        && isValidRecipient(values.recipientName)
        && isValidAmount(values.amount)
}

export function isValidEditForm(values) {
    return isValidFirstName(values.firstName)
        && isValidLastName(values.lastName)
        && isValidPersonEmail(values.email)
}

import { useSelector } from 'react-redux'
import {useMemo} from "react"

/**
 * @returns {LegalEntityResource|undefined}
 */
export function useLegalEntity() {
    const activeFilters = useSelector(state => state.activeFilters)
    const legalEntities = useSelector(state => state.entities.legalEntities)

    const activeLegalEntity = useMemo(() => {
        const activeLegalEntityId = activeFilters.legalEntityId
        return legalEntities[activeLegalEntityId]
    }, [activeFilters.legalEntityId, legalEntities])

    return activeLegalEntity
}

/* eslint-disable max-len */
import React, { useState } from 'react'
import {
    ContentState, convertFromHTML, convertToRaw, EditorState,
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import sanitizeHtml from 'sanitize-html'
import classes from './CommentFormItem.module.css'
import {BookingFormItem} from "./BookingFormItem"

// See 'toolbar' section: https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
const richTextEditorToolBarOptions = {
    options: ['inline', 'list'],
    inline: {
        options: ['bold', 'italic'],
        inDropDown: false,
    },
    list: {
        inDropdown: false,
        options: ['unordered', 'ordered'],
    },
}

function transformNullComments(comments) {
    if (comments === null || comments === undefined) {
        return ''
    }
    return comments
}

/**
 * @component
 * @param {Object} props
 * @param {CreateOrEditBookingFormValues} props.values
 * @param {{ [Key in keyof CreateOrEditBookingFormValues]: string[]}} props.errors
 * @param {(e: any) => void} props.onChange
 * @returns {JSX.Element}
 */
export function BookingCommentFormItem({ values, errors, onChange }) {
    const [state, setState] = useState({
        editorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(
                    transformNullComments(values.comment),
                ),
            ),
        ),
    })

    const onEditorStateChange = (editorState) => {
        const value = sanitizeHtml(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        const customTag = {
            target: {
                name: 'comment', type: '', value, checked: false, files: false,
            },
        }
        onChange(customTag)
        setState({
            editorState,
        })
    }

    const { editorState } = state
    return (
        <BookingFormItem label="Comment" error={errors.comment}>
            <Editor
                name="comment"
                toolbar={richTextEditorToolBarOptions}
                editorState={editorState}
                wrapperClassName="wrapperClassName"
                editorClassName={classes.editor}
                onEditorStateChange={onEditorStateChange}
            />
        </BookingFormItem>
    )
}

import React from "react"
import {
    EuroOutlined, LockFilled, HomeOutlined,
} from "@ant-design/icons"
import { Table, Tag, Tooltip } from "antd"
import * as theme from "styles/theme"
import { sorterToSortParam } from "util/index"
import { getExtraInformationOfType } from "util/transformers/booking"
import WithMe from "components/pages/authorized/WithMe"
import {
    EXTRA_INFORMATOIN_KEYS,
    APP_DATE_FORMAT,
    SHORT_DAY_OF_WEEK_DAY_MONTH_YEAR_FORMAT,
} from "util/constants"
import dayjs from "dayjs"
import ActivateBookingSwitch from "./ActivateBookingSwitch"
import food from "../../../../img/cutlery.png"
import drinks from "../../../../img/drinks.png"
import company from "../../../../img/company.png"
import giftbox from "../../../../img/giftbox.png"
import {isValidString} from "../../../../util/string-utils"
import {BookingDetails} from "../details/BookingDetails"
import {OutstandingAmountModalState} from "../modals/outstandingAmount/OutstandingAmountModalState"
import {formatCurrency} from "../../../../util/currency"

const columns = [
    {
        title: "#",
        dataIndex: "entryNumber",
        key: "entryNumber",
        width: 0,
        align: "center",
        current: 1,
        render: (entryNumber) => entryNumber,
    },
    {
        title: "",
        dataIndex: "isInternal",
        width: 0,
        align: "center",
        render: (isInternal, booking) => {
            const needsMeetingRoom = typeof getExtraInformationOfType(booking.extraInformation, "meeting_room") !== "undefined"
            if (needsMeetingRoom) {
                let title = "This booking requires a meeting room."
                if (isInternal)
                    title = "This is an internal booking that requires a meeting room."

                return (
                    <Tooltip title={title}>
                        <HomeOutlined
                            style={{
                                color: isInternal ? theme.colorSecondary : theme.colorGreyDarker,
                            }}
                        />
                    </Tooltip>
                )
            }
            if (isInternal) {
                return (
                    <Tooltip title="This is an internal booking">
                        <span style={{
                            display: "block",
                            width: 10,
                            height: 10,
                            backgroundColor: theme.colorSecondary,
                            borderRadius: "50%",
                        }}
                        />
                    </Tooltip>
                )
            }
            return null
        },
    },
    {
        title: "Active",
        dataIndex: "isActive",
        width: 60,
        render: (text, booking) => (
            <ActivateBookingSwitch
                bookingId={booking.id}
                isActive={booking.isActive}
            />
        ),
    },
    {
        title: "Name",
        dataIndex: "bookerFirstName",
        render: (bookerFirstName, booking) => {
            if (isValidString(booking.bookerLastName))
                return `${bookerFirstName} ${booking.bookerLastName}`
            else
                return bookerFirstName
        },
    },
    {
        title: "Comp.",
        dataIndex: "companyName",
        width: 60,
        align: "center",
        render: (companyName) => companyName && (
            <Tooltip
                title={companyName}
            >
                <img src={company} alt="company" style={{ width: "35", height: "35px" }} />
            </Tooltip>
        ),
    },
    {
        title: "Day",
        dataIndex: "start",
        key: "start",
        sorter: true,
        width: 100,
        render: (text) => dayjs.utc(text).format(SHORT_DAY_OF_WEEK_DAY_MONTH_YEAR_FORMAT),
    }, {
        title: "From",
        dataIndex: "start",
        key: "from",
        width: 0,
        render: (text) => dayjs.utc(text).format("H:mm"),
    },
    {
        title: "To",
        dataIndex: "end",
        width: 0,
        render: (text) => dayjs.utc(text).format("H:mm"),
    }, {
        title: "GameResource",
        dataIndex: "gameTitle",
        width: 100,
    },
    {
        title: "Players",
        dataIndex: "playerAmount",
        align: "center",
        width: 90,
        render: (text, booking) => {
            // if (typeof booking.legalEntity === "undefined") {
            //     console.log("Booking without legal entity:", booking)
            // }

            // const localeCode = buildLocaleByLegalEntityIdAndLanguageCode(booking.legalEntity.id, booking.language)
            const linkToPlayerRegistration = `bookings/${booking.orderNumber}/player-registration`

            return (
                <Tooltip title="Go to player registration">
                    <a
                        // href={`${process.env.REACT_APP_CCW_URL}/${localeCode}/vr-booking/${booking.orderNumber}/qrcode-register-link`}
                        href={linkToPlayerRegistration}
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                        rel="noreferrer noopener"
                        target="_blank"
                    >
                        <Tag color={theme.colorSecondary}>{text}</Tag>
                    </a>
                </Tooltip>
            )
        },
    },
    {
        title: "Priv.",
        dataIndex: "isPrivate",
        align: "center",
        width: 60,
        render: (isPrivate) => isPrivate && (
            <Tooltip title="Is a private booking.">
                <LockFilled style={{ color: theme.colorSecondary, fontSize: "20px" }} />
            </Tooltip>
        ),
    },
    {
        title: "Booked on",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: true,
        align: "center",
        width: 130,
        render: (text) => {
            // console.log("text:", text)
            return dayjs.utc(text).format(APP_DATE_FORMAT)
        },
    },
    {
        title: "Extra",
        dataIndex: "comment",
        align: "center",
        width: 20,
        render: (text, /** @type BookingResource */ booking) => {
            const cateringInfo = getExtraInformationOfType(booking.extraInformation, EXTRA_INFORMATOIN_KEYS.catering)
            const barForfaitInfo = getExtraInformationOfType(booking.extraInformation, EXTRA_INFORMATOIN_KEYS.barForFait)

            // addon products
            const openBarInfo = getExtraInformationOfType(booking.extraInformation, EXTRA_INFORMATOIN_KEYS.food) // food is open bar here. See constants.js for documentation
            const drinksInfo = getExtraInformationOfType(booking.extraInformation, EXTRA_INFORMATOIN_KEYS.drinks)
            const nachosInfo = getExtraInformationOfType(booking.extraInformation, EXTRA_INFORMATOIN_KEYS.nachos)
            const twoDrinkTokensInfo = getExtraInformationOfType(booking.extraInformation, EXTRA_INFORMATOIN_KEYS.drinkTokens)

            const currencyCode = booking.currencyCode

            return (
                <div style={{ display: "flex", alignItems: "center", lineHeight: 1 }}>
                    { typeof booking.outstandingAmount === "number" && booking.outstandingAmount > 0.0 && (
                        <OutstandingAmountModalState booking={booking}>
                            {({ openModal }) => (
                                <WithMe>
                                    {({ isReadOnly }) => (
                                        <Tooltip title={`Outstanding amount: ${formatCurrency(booking.outstandingAmount, currencyCode)}`}>
                                            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                                            <span
                                                role="img"
                                                aria-label={`Outstanding amount: ${formatCurrency(booking.outstandingAmount, currencyCode)}`}
                                                onClick={() => {
                                                    if (!isReadOnly)
                                                        openModal()
                                                }}
                                                style={{ fontSize: 18, color: theme.colorAlert, cursor: isReadOnly ? "inherit" : "pointer" }}
                                            >
                                                <EuroOutlined />
                                            </span>
                                        </Tooltip>
                                    )}
                                </WithMe>
                            )}
                        </OutstandingAmountModalState>
                    )}
                    { text && (
                        <Tooltip title={<span dangerouslySetInnerHTML={{ __html: booking.comment }} />} style={{ userSelect: "none" }}>
                            <span role="img" aria-label={text} style={{ cursor: "default" }}>❗</span>
                        </Tooltip>
                    )}

                    {
                        typeof cateringInfo !== "undefined" && (
                            <Tooltip title={cateringInfo.info || "Requires catering."}>
                                <img src={food} alt="drinks" style={{ width: "20px", height: "20px" }} />
                            </Tooltip>
                        )
                    }


                    {
                        typeof barForfaitInfo !== "undefined" && (
                            <Tooltip title={barForfaitInfo.info || "Has open bar."}>
                                <img src={drinks} alt="open bar" style={{ width: "20px", height: "20px" }} />
                            </Tooltip>
                        )
                    }

                    {
                        typeof drinksInfo !== "undefined" && (
                            <Tooltip title={drinksInfo.info || "Has 2 drinks."}>
                                <img src={drinks} alt="2 drinks" style={{ width: "20px", height: "20px" }} />
                            </Tooltip>
                        )
                    }

                    {
                        typeof openBarInfo !== "undefined" && (
                            <Tooltip title={openBarInfo.info || " 1h open bar."}>
                                <img src={drinks} alt="drinks" style={{ width: "20px", height: "20px" }} />
                            </Tooltip>
                        )
                    }

                    {
                        typeof nachosInfo !== "undefined" && (
                            <Tooltip title={nachosInfo.info || "Has nachos."}>
                                <img src={food} alt="Nachos" style={{ width: "20px", height: "20px" }} />
                            </Tooltip>
                        )
                    }

                    {
                        typeof twoDrinkTokensInfo !== "undefined" && (
                            <Tooltip title={twoDrinkTokensInfo.info || "Has 2 drink tokens pp."}>
                                <img src={drinks} alt="2 drink tokens pp" style={{ width: "20px", height: "20px" }} />
                            </Tooltip>
                        )
                    }

                    {
                        booking.couponCode !== null && (
                            <Tooltip title={booking.couponCode}>
                                <img src={giftbox} alt="coupon code" style={{ width: "20px", height: "20px" }} />
                            </Tooltip>
                        )
                    }
                </div>
            )
        },
    }]

/**
 * @param {BookingResource & {entryNumber: number}} booking
 * @returns {Element}
 */
function expandedRowRender(booking) {
    return <BookingDetails booking={booking} />
}


/**
 * @component
 * @param {Object} props
 * @param {BookingResource & {entryNumber: number}[]} props.bookings
 * @param {boolean} props.isFetching
 * @param {(sortArg?: string|null) => void} props.onSort
 * @returns {JSX.Element}
 */
export function BookingsTable(props) {
    return (
        <Table
            rowKey="id"
            className="smallerCells"
            rowClassName="cursor-pointer"
            loading={props.isFetching}
            dataSource={props.bookings}
            columns={columns}
            pagination={false}
            expandable={{
                expandedRowRender,
                // expandIconColumnIndex:7
                expandRowByClick: true,
                expandIcon: () => null,
                expandIconColumnIndex: -1,
            }}
            // expandedRowRender={(booking) => (<BookingDetails booking={booking} />)}
            onChange={(pagination, filters, sorter) => {
                if (typeof sorter.columnKey !== "undefined")
                    props.onSort(sorterToSortParam(sorter))
                else
                    props.onSort(null)
            }}
        />
    )
}

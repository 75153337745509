import React from 'react'
import DeleteGameSessionFormState
    from 'components/pages/gameSessions/forms/delete/DeleteGameSessionFormState'
import { ConfirmInputModal } from 'components/util/modals/ConfirmInputModal'
import dayjs from 'dayjs'
import { APP_TIME_FORMAT, APP_MONTH_DAY_FORMAT } from '../../../../../util/constants'

const getEntityName = (entity) => (
    `${dayjs.utc(entity).format(APP_MONTH_DAY_FORMAT)} from
    ${dayjs.utc(entity.start).format(APP_TIME_FORMAT)} till
    ${dayjs.utc(entity.end).format(APP_TIME_FORMAT)}`
)

function DeleteGameSessionModal({ isOpen, entity, onClose }) {
    return (
        <DeleteGameSessionFormState
            onDeleteSuccess={onClose}
            onCancel={onClose}
            values={entity}
        >
            {({
                isLoading,
                handleSubmit,
                handleCancel,
            }) => {
                if (entity === null || typeof entity === 'undefined') {
                    return null
                }
                return (
                    <ConfirmInputModal
                        safeWord={entity.game.title}
                        title={`Are you sure you want to delete the session on ${getEntityName(entity)}?`}
                        isOpen={isOpen}
                        onConfirm={handleSubmit}
                        onCancel={handleCancel}
                        confirmText="Delete this game session"
                        confirmType="primary"
                        confirmIsDanger={true}
                        cancelText="Cancel"
                        isLoading={isLoading}
                        close={onClose}
                    >
                        <p>
                            Removing a game session cannot be undone.
                            {' '}
                            <br />
                            Please type the exact name of the game (
                            <strong>{entity.game.title}</strong>
                            ) to confirm.
                        </p>
                    </ConfirmInputModal>
                )
            }}
        </DeleteGameSessionFormState>
    )
}

export default DeleteGameSessionModal

import { useEffect, useState } from 'react'
import callApi from 'store/middleware/callApi'
import { METHODS } from 'store/middleware/api'

/**
 *
 * @param {string} bookingOrderNumber
 * @returns {(Object)} data
 * @returns {(Object)} errors
 * @returns {string} fetchStatus
 * @returns {(firstNameToPost: string, lastNameToPost: string, emailToPost: string, nicknameToPost: string) => void} postBookingRegisteredPlayer
 * @returns {(bookingCustomerProfileID: number, firstNameToPost: string, lastNameToPost: string, emailToPost: string, nicknameToPost: string) => void} patchBookingRegisteredPlayer
 * @returns {(bookingCustomerProfileID: number) => void} deleteBookingRegisteredPlayer
 * @returns {() => void} increasePlayerAmountBooking
 * @definition Hook to handle the registration of players to a booking
 */

const useBookingPlayerRegistration = (bookingOrderNumber) => {
    const [fetchStatus, setFetchStatus] = useState('idle')
    const [errors, setErrors] = useState(undefined)
    const [data, setData] = useState([])

    const fetchBookingRegisteredPlayers = async () => {
        setFetchStatus('fetching')

        try {
            const result = await callApi(
                METHODS.GET,
                `/bookings/${bookingOrderNumber}`,
            )

            setData(result.data)
            setErrors(undefined)
            setFetchStatus('idle')
        } catch (error) {
            setErrors(error)
            setFetchStatus('error')
        }
    }

    /**
     * @property {string} firstNameToPost
     * @property {string} lastNameToPost
     * @property {string} emailToPost
     * @property {string} nicknameToPost
     * @definition Function to register a player to a booking
    */
    const postBookingRegisteredPlayer = async (
        firstNameToPost,
        lastNameToPost,
        emailToPost,
        nicknameToPost,
    ) => {
        const requestBody = {
            firstName: firstNameToPost,
            lastName: lastNameToPost,
            email: emailToPost,
            nickname: nicknameToPost,
        }

        try {
            await callApi(
                METHODS.POST,
                `/bookings/${bookingOrderNumber}/register-profile`,
                undefined,
                undefined,
                requestBody,
            )

            setErrors(undefined)
            fetchBookingRegisteredPlayers()
        } catch (error) {
            setErrors(error)
            return new Promise((resolve, reject) => {
                reject(error)
            })
        }

        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
            resolve('All data has been submitted successfully!')
        })
    }

    /**
     * @property {number} bookingCustomerProfileID
     * @property {string} firstNameToPost
     * @property {string} lastNameToPost
     * @property {string} emailToPost
     * @property {string} nicknameToPost
     * @definition Function to edit a registered player from a booking
    */
    const patchBookingRegisteredPlayer = async (
        bookingCustomerProfileID,
        firstNameToPost,
        lastNameToPost,
        emailToPost,
        nicknameToPost,
    ) => {
        const requestBody = {
            firstName: firstNameToPost,
            lastName: lastNameToPost,
            email: emailToPost,
            nickname: nicknameToPost,
            countryId: 17,
            preferredLocaleCode: 'be',
        }

        try {
            await callApi(
                METHODS.PATCH,
                `/bookings/${bookingOrderNumber}/registered-profiles/${bookingCustomerProfileID}`,
                undefined,
                undefined,
                requestBody,
            )

            setErrors(undefined)
            fetchBookingRegisteredPlayers()
        } catch (error) {
            setErrors(error)
            return new Promise((resolve, reject) => {
                reject(error)
            })
        }

        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
            resolve('All data has been submitted successfully!')
        })
    }

    /**
     * @property {number} bookingCustomerProfileID
     * @definition Function to delete a registered player from a booking
    */
    const deleteBookingRegisteredPlayer = async (
        bookingCustomerProfileID,
    ) => {
        try {
            await callApi(
                METHODS.DELETE,
                `/bookings/${bookingOrderNumber}/registered-profiles/${bookingCustomerProfileID}`,
                undefined,
                undefined,
                undefined,
            )

            setErrors(undefined)
            fetchBookingRegisteredPlayers()
        } catch (error) {
            setErrors(error)
            return new Promise((resolve, reject) => {
                reject(error)
            })
        }

        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
            resolve('All data has been submitted successfully!')
        })
    }

    // const increasePlayerAmountBooking = async () => {
    //     try {
    //         await callApi(
    //             METHODS.POST,
    //             `/bookings/${bookingOrderNumber}/increase-players`,
    //             undefined,
    //             undefined,
    //             undefined,
    //         )

    //         setErrors(undefined)
    //         fetchBookingRegisteredPlayers()
    //     } catch (error) {
    //         setErrors(error)
    //         return new Promise((resolve, reject) => {
    //             reject(error)
    //         })
    //     }

    //     // eslint-disable-next-line no-unused-vars
    //     return new Promise((resolve, reject) => {
    //         resolve('All data has been submitted successfully!')
    //     })
    // }

    useEffect(() => {
        fetchBookingRegisteredPlayers()
    }, [bookingOrderNumber])

    return {
        errors,
        data,
        fetchStatus,
        postBookingRegisteredPlayer,
        patchBookingRegisteredPlayer,
        deleteBookingRegisteredPlayer,
        // increasePlayerAmountBooking,
    }
}

export default useBookingPlayerRegistration

import React from 'react'
import { deleteRoom, fetchRoomsExtendCurrentParams } from 'store/actions/rooms'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    deleteRoom: async (room) => dispatch(deleteRoom(room.id)),
    deleteRoomSuccess: () => {
        notification.success({
            message: 'Room removed!',
            description: (
                <span>
                    The room was succesfully removed for this venue.
                </span>
            ),
        })
    },
    deleteRoomFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchRooms: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchRoomsExtendCurrentParams(paginationKey))
    },
})

const DeleteRoomRequest = withReduxState(null, mapDispatchToProps)

export default DeleteRoomRequest

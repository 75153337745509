import dayjs from 'dayjs'
import { STATUS_AVAILABLE } from './TSConstants'

const now = dayjs.utc()

/**
 *
 * @param {Object} timeSlot time slot
 * @param {string} date date of the time slot
 * @returns {boolean} returns if slot has passed
 */
export const slotHasPassed = (timeSlot, date) => {
    const { roomStart } = timeSlot
    const dateTimeOfSlot = dayjs.utc(`${date.split('T')[0]}T${roomStart}`)
    return now > dateTimeOfSlot
}

/**
 *
 * @param {Object} roomAndTimeSlots object with 1 room and all its time slots
 * @returns new object with only slots between bookable hours that have passed
 */
export const getRoomAndTimeSlotsBetweenBookableHours = (roomAndTimeSlots) => {
    const {
        uiSlots, bookableHours, date, ...remainingProps
    } = roomAndTimeSlots

    // filter slots between bookable hours
    const slotsBetweenBookableHours = uiSlots
        .filter((uiSlot) => uiSlot.slot.roomStart < bookableHours.end
        && uiSlot.slot.roomStart >= bookableHours.start
        && !slotHasPassed(uiSlot.slot, date))

    // make new roomAndTimeSlots object with only slots between bookable hours
    const roomAndSlotsBetweenBookableHours = {
        uiSlots: slotsBetweenBookableHours,
        ...remainingProps,
    }

    return roomAndSlotsBetweenBookableHours
}

/**
 *
 * @param {Array} roomsAndTimeSlots array of room and timeslots objects
 * @returns array of roomAndTimeSlots objects with only slots between bookable hours
 */
export const getRoomsAndTimeSlotsBetweenBookableHours = (roomsAndTimeSlots) => {
    // array that holds all rooms and slots between bookable hours
    const roomsAndTimeSlotsBetweenBookableHours = []

    // get room for each room in roomsAndTimeSlots
    roomsAndTimeSlots.forEach((roomAndTimeSlots) => {
        // get object roomAndTimeSlot object between bookable hours
        const roomAndTimeSlotsBetweenBookableHours = getRoomAndTimeSlotsBetweenBookableHours(
            roomAndTimeSlots,
        )
        roomsAndTimeSlotsBetweenBookableHours.push(roomAndTimeSlotsBetweenBookableHours)
    })

    return roomsAndTimeSlotsBetweenBookableHours
}

/**
 *
 * @param {Object} roomAndTimeSlots object with 1 room and all its time slots
 * @returns new object with only slots between bookable hours and pattern slots
 */

export const getRoomAndPatternSlotsBetweenBookableHours = (roomAndTimeSlots) => {
    const {
        uiSlots, bookableHours, date, ...remainingProps
    } = roomAndTimeSlots

    // array that holds all slots in pattern
    const patternSlots = []

    for (let i = 0; i < uiSlots.length; i += 1) {
        const uiSlot = uiSlots[i]

        // push every fourth slot to patternSlots that are available and between bookable hours
        if (
            uiSlot.slot.roomStart < bookableHours.end
            && uiSlot.slot.roomStart >= bookableHours.start
            && !slotHasPassed(uiSlot.slot, date)
        ) {
            if (uiSlot.slot.status === STATUS_AVAILABLE) {
                // push every fourth slot to patternSlots
                if ((i + 1) % 4 === 0) {
                    patternSlots.push(uiSlot)
                }
            }
        }
    }

    // make new roomAndTimeSlots object with only slots between bookable hours and pattern slots
    const roomAndSlotsPatternBetweenBookableHours = {
        bookableHours,
        uiSlots: patternSlots,
        ...remainingProps,
    }

    return roomAndSlotsPatternBetweenBookableHours
}

/**
 *
 * @param {Array} roomsAndTimeSlots array of room and timeslots objects
 * @returns array with roomsAndTimeSlots object with only slots between bookable hours and pattern slots
 */
export const getRoomsAndTimeSlotsPatternBetweenBookableHours = (roomsAndTimeSlots) => {
    // array that holds all rooms and pattern slots between bookable hours
    const roomsAndPatternTimeSlotsBetweenBookableHours = []

    // get room for each room in roomsAndTimeSlots
    roomsAndTimeSlots.forEach((roomAndTimeSlots) => {
        // get object roomAndTimeSlot object with pattern between bookable hours
        const roomAndPatternTimeSlotsBetweenBookableHours = getRoomAndPatternSlotsBetweenBookableHours(
            roomAndTimeSlots,
        )
        roomsAndPatternTimeSlotsBetweenBookableHours.push(roomAndPatternTimeSlotsBetweenBookableHours)
    })

    return roomsAndPatternTimeSlotsBetweenBookableHours
}

/**
 * Selects all pattern slots in room
 * @param {Object} roomAndTimeSlots object with 1 room and all its time slots
 * @param {boolean} selectOption to set selected status of slots to true or false
 * @param {Callback} updateSelectedStatusOfSlot function to update selected status of given slots
 */
export const selectPatternSlotsInRoom = (
    roomAndTimeSlots,
    selectOption,
    updateSelectedStatusOfSlot,
) => {
    // get copy object of roomAndTimeSlots with pattern slots
    const roomAndSlotsPatternBetweenBookableHours = getRoomAndPatternSlotsBetweenBookableHours(
        roomAndTimeSlots,
    )

    if (selectOption === true) {
        updateSelectedStatusOfSlot([roomAndSlotsPatternBetweenBookableHours], true, true)
    } else {
        updateSelectedStatusOfSlot([roomAndSlotsPatternBetweenBookableHours], false, false)
    }
}

/**
 * Selects all slots in room
 * @param {Array} roomAndTimeSlots array of room and timeslots object
 * @param {boolean} selectOption to set selected status of slots to true or false
 * @param {Callback} updateSelectedStatusOfSlot function to update selected status of given slots
 */
export const selectAllSlotsInRoom = (
    roomAndTimeSlots,
    selectOption,
    updateSelectedStatusOfSlot,
) => {
    // get copy object of roomAndTimeSlots with slots between bookable hours
    const roomAndTimeSlotsBetweenBookableHours = getRoomAndTimeSlotsBetweenBookableHours(
        roomAndTimeSlots,
    )

    if (selectOption === true) {
        updateSelectedStatusOfSlot([roomAndTimeSlotsBetweenBookableHours], true, true)
    } else {
        updateSelectedStatusOfSlot([roomAndTimeSlots], false, false)
    }
}

/**
 * Selects all pattern slots in multiple rooms
 * @param {Array} roomsAndTimeSlots array of room and timeslots object
 * @param {boolean} selectOption to set selected status of slots to true or false
 * @param {Callback} updateSelectedStatusOfSlot function to update selected status of given slots
 */
export const selectAllPatternSlots = (
    roomsAndTimeSlots,
    selectOption,
    updateSelectedStatusOfSlot,
) => {
    const roomsAndPatternTimeSlots = getRoomsAndTimeSlotsPatternBetweenBookableHours(
        roomsAndTimeSlots,
    )

    if (selectOption === true) {
        updateSelectedStatusOfSlot(roomsAndPatternTimeSlots, true, true)
    } else {
        updateSelectedStatusOfSlot(roomsAndPatternTimeSlots, false, false)
    }
}

/**
 * Selects all pattern slots in multiple rooms
 * @param {Array} roomsAndTimeSlots array of room and timeslots object
 * @param {boolean} selectOption to set selected status of slots to true or false
 * @param {Callback} updateSelectedStatusOfSlot function to update selected status of given slots
 */
export const selectAllSlots = (
    roomsAndTimeSlots,
    selectOption,
    updateSelectedStatusOfSlot,
) => {
    const roomsAndTimeSlotsBetweenBookableHours = getRoomsAndTimeSlotsBetweenBookableHours(
        roomsAndTimeSlots,
    )

    if (selectOption === true) {
        updateSelectedStatusOfSlot(roomsAndTimeSlotsBetweenBookableHours, true, true)
    } else {
        updateSelectedStatusOfSlot(roomsAndTimeSlotsBetweenBookableHours, false, false)
    }
}

// check if roomsAndTimeSlots has slots outside bookable hours
export const hasSlotsOutsideBookableHours = (roomsAndTimeSlots) => {
    let slotsOutsideBookableHours = false

    roomsAndTimeSlots.forEach((roomAndTimeSlots) => {
        const { uiSlots, bookableHours } = roomAndTimeSlots

        uiSlots.forEach((uiSlot) => {
            const { slot } = uiSlot

            if (slot.roomStart < bookableHours.start || slot.roomStart >= bookableHours.end) {
                slotsOutsideBookableHours = true
            }
        })
    })

    return slotsOutsideBookableHours
}

import createEntityReducer from '../hor/createEntityReducer'
import { VOUCHER_REQUEST, VOUCHER_SUCCESS, VOUCHER_FAILURE } from '../../actions/vouchers'

export const vouchersReducer = createEntityReducer({
    types: [
        VOUCHER_REQUEST,
        VOUCHER_SUCCESS,
        VOUCHER_FAILURE,
    ],
})

/**
 * @param {Object} obj
 */
export function removeNullOrUndefinedValuesFromObject(obj) {
    if (typeof obj !== "object" || obj === null)
        return {}

    const newObj = {}
    const keys = Object.keys(obj)
    for (let i = 0, length = keys.length; i < length; i++) {
        const key = keys[i]
        const value = obj[key]
        if (typeof value === "undefined" || value === null)
            continue

        newObj[key] = value
    }

    return newObj
}
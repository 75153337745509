import { message } from 'antd'
import { mapArrayKeysToObjectHelper } from './validation/index'

const handleApiErrorResponse = (error, updateErrorsCallBack, useArray) => {
    if (typeof error !== 'undefined') {
        if (typeof error.errors !== 'undefined') {
            const { errors } = error
            if (useArray) {
                const mappedError = Object.keys(errors).reduce((acc, curr) => {
                    const newObj = errors[curr].map((errMessage) => ({
                        path: curr,
                        message: errMessage,
                    }))
                    return [...acc, ...newObj]
                }, [])
                updateErrorsCallBack(mappedError)
            } else {
                updateErrorsCallBack(mapArrayKeysToObjectHelper(errors))
            }
        } else if (typeof error.error !== 'undefined') {
            message.error(error.error)
        } else {
            message.error(error.message)
        }
    }
}

export default handleApiErrorResponse

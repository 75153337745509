import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import './Loading.css'

function Loading({
    size = 32,
    containerHeight = 350,
    className,
}) {
    return (
        <div className={classNames('container', className)}>
            <LoadingOutlined style={{ fontSize: size }} />
        </div>
    )
}

export default Loading

import React from 'react'
import {
    Form, Card, Input, Button,
} from 'antd'
import FormItem from 'components/util/forms/FormItem'
import { useNavigate } from 'react-router'

function Login({
    values,
    errors,
    handleNativeChange,
    onSubmit,
    loading,
}) {
    const navigate = useNavigate()
    return (
        <Card title="Login" style={{ width: 400 }}>
            <Form onFinish={onSubmit} className="login-form">
                <FormItem
                    error={errors.email}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="E-Mail"
                >
                    <Input
                        placeholder="E-Mail"
                        name="email"
                        value={values.email}
                        onChange={handleNativeChange}
                        autoComplete="email"
                    />
                </FormItem>

                <FormItem
                    error={errors.password}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Password"
                >
                    <Input
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleNativeChange}
                        autoComplete="current-password"
                    />
                </FormItem>

                <Button
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                >
                    Login
                </Button>
                <Button
                    type="link"
                    onClick={() => navigate('/forgot-password')}
                >
                    Forgot Your Password?
                </Button>
            </Form>
        </Card>
    )
}

export default Login

import createEntityReducer from '../hor/createEntityReducer'
import {
    GIFT_CARD_REQUEST,
    GIFT_CARD_SUCCESS,
    GIFT_CARD_FAILURE,
} from '../../actions/giftCards'

const giftCardsReducer = createEntityReducer({
    types: [
        GIFT_CARD_REQUEST,
        GIFT_CARD_SUCCESS,
        GIFT_CARD_FAILURE,
    ],
})

export default giftCardsReducer

import React from 'react'
import Logo from 'components/util/Logo'
import './AppLoading.css'

function AppLoading() {
    return (
        <div className="appLoading">
            <Logo />
        </div>
    )
}

export default AppLoading

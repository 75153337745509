import React, { useCallback } from 'react'
import useFormState from 'use-form-state'
import useApi, { METHODS } from 'components/hooks/useApi'
import { useAppState } from 'components/hooks/useAppState'
import handleApiErrorResponse from 'util/errorResponse'
import { useNavigate } from 'react-router'
import Login from './Login'

function LoginWithState() {
    const appState = useAppState()
    const {
        values,
        errors,
        handleNativeChange,
        updateErrors,
    } = useFormState({
        email: '',
        password: '',
    })
    const [authenticate, { loading }] = useApi('/login', { method: METHODS.POST })
    const navigate = useNavigate()

    const onSubmit = useCallback(async () => {
        try {
            updateErrors({})
            const response = await authenticate({ email: values.email, password: values.password })
            const { accessToken, refreshToken } = response
            const success = await appState.login(accessToken, refreshToken)
            if (!success)
                updateErrors({ password: 'Something went wrong.' })

            navigate('/admin')
        } catch (error) {
            if (error.status === 401) {
                updateErrors({ password: 'Your credentials did not match our system. Please try again.' })
            } else {
                handleApiErrorResponse(error, updateErrors)
            }
        }
    }, [updateErrors, authenticate, values.email, values.password, appState, navigate])

    return (
        <Login
            values={values}
            errors={errors}
            handleNativeChange={handleNativeChange}
            onSubmit={onSubmit}
            loading={loading}
        />
    )
}

export default LoginWithState

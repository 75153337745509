import React, { useState } from 'react'
import { Modal, Form } from 'antd'
import { ModalFooter } from 'components/util/modals/ModalFooter'
import GameForm from 'components/pages/settings/games/forms/GameForm'
import { formHasErrorsForLocale } from 'util/validation/game'
import MultiLangEditGameForm from 'components/pages/settings/games/forms/edit/MultiLangEditGameForm'
import {AppTabs} from 'components/util/AppTabs'
import { capitalize } from 'util/index'
import * as theme from 'styles/theme'
import { useAppState } from 'components/hooks/useAppState'
import PriceForm from '../../forms/PriceForm'
import {LANGUAGE_CODES} from "../../../../../../util/constants"

function EditGameModal({
    isOpen,
    onClose,
    game,
    errors,
    isLoading,
    onChange,
    onChangeAsValue,
    onCancel,
    onSubmit,
    legalEntity,
}) {
    const [isUploadingImage, setIsUploadingImage] = useState(false)
    const { currencies } = useAppState()

    return (
        <Modal
            title={`Edit game ${game && game.title}`}
            width={600}
            open={isOpen}
            onCancel={onClose}
            onOk={onSubmit}
            closable={!(isLoading || isUploadingImage)}
            maskClosable={!(isLoading || isUploadingImage)}
            footer={(
                <ModalFooter
                    isLoading={isLoading || isUploadingImage}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    submitText="Edit"
                />
            )}
        >
            {isOpen && (
                <>
                    <div style={{ marginBottom: theme.spacingLarge }}>
                        <AppTabs
                            tabs={LANGUAGE_CODES}
                            hasErrors={(language) => formHasErrorsForLocale(errors, language)}
                        >
                            {({ tab }) => (
                                <MultiLangEditGameForm
                                    language={capitalize(tab)}
                                    isLoading={isLoading}
                                    values={game}
                                    errors={errors}
                                    onChange={onChange}
                                    onChangeAsValue={onChangeAsValue}
                                />
                            )}
                        </AppTabs>
                    </div>
                    <div style={{ marginBottom: theme.spacingLarge }}>
                        <Form.Item
                            help={errors.price}
                            validateStatus={errors.price ? 'error' : ''}
                        >
                            <AppTabs tabs={Object.keys(currencies)}>
                                {({ tab }) => (
                                    <PriceForm
                                        values={game}
                                        errors={errors}
                                        onChangeAsValue={onChangeAsValue}
                                        currency={currencies[tab]}
                                        priceSystem={legalEntity.priceSystem}
                                    />
                                )}
                            </AppTabs>
                        </Form.Item>
                    </div>
                    <GameForm
                        isLoading={isLoading}
                        values={game}
                        errors={errors}
                        onChange={onChange}
                        onChangeAsValue={onChangeAsValue}
                        isUploadingImage={setIsUploadingImage}
                        legalEntity={legalEntity}
                    />
                </>
            )}
        </Modal>
    )
}

export default EditGameModal

import React, { useState } from 'react'
import {
    Form,
    Radio,
    Input,
    InputNumber,
    DatePicker,
    Checkbox,
    Select,
} from 'antd'

import ExtraInfoLabel from 'components/util/forms/ExtraInfoLabel'
// import { REASON_CODE } from 'util/constants'
import useCurrencySymbol from 'components/hooks/useCurrencySymbol'
import dayjs from 'dayjs'
import VenuesSelectWithRedux from '../VenueSelect'
import CouponFormItem from './CouponFormItem'

// const { Option } = Select

/**
 * @param {Object} props
 * @param {Object} props.values Create coupon form values
 * @param {Object} props.errors Coupon form errors
 * @param {(value: any) => void} props.onChange Change by event
 * @param {(key: string, value: any) => void} props.onChangeAsValue Change by specific key and value
 * @param {Object} props.legalEntity
 * @returns {React.JSX.Element}
 * @constructor
 */
function CreateCouponForm(props) {
    const currencySymbol = useCurrencySymbol(props.legalEntity)

    return (
        <Form>
            <CouponFormItem
                label="Coupon type"
            >
                <Radio.Group
                    name="type"
                    value={props.values.type}
                    onChange={props.onChange}
                >
                    <Radio.Button value="single">
                        Single use
                    </Radio.Button>
                    <Radio.Button value="multiple">
                        Multiple use
                    </Radio.Button>
                </Radio.Group>
            </CouponFormItem>
            {props.values.type === 'multiple' && (
                <CouponFormItem
                    label="Usage Limit"
                    error={props.errors.usageLimit}
                    extra={(
                        <ExtraInfoLabel
                            message="If you provide a limit the coupon can only be used for that amount."
                        />
                    )}
                >
                    <InputNumber
                        name="usageLimit"
                        value={props.values.usageLimit}
                        onChange={(value) => props.onChangeAsValue('usageLimit', value)}
                        min={2}
                        step={1}
                    />
                </CouponFormItem>
            )}
            <CouponFormItem
                label="Code"
                error={props.errors.code}
                extra={(typeof props.values.code !== 'string' || props.values.code.length === 0)
                    && (
                        <ExtraInfoLabel
                            message="If no code is provided, a random code will be generated."
                        />
                    )}
            >
                <Input
                    className="txt-upper"
                    name="code"
                    value={props.values.code}
                    onChange={props.onChange}
                />
            </CouponFormItem>
            <CouponFormItem>
                <Checkbox
                    name="isAllowedForGiftcards"
                    checked={props.values.isAllowedForGiftcards}
                    onChange={props.onChange}
                >
                    Can also be used for gift cards
                </Checkbox>
            </CouponFormItem>
            {/* <CouponFormItem */}
            {/*     label="Reason Issued" */}
            {/* > */}
            {/*     <Select */}
            {/*         placeholder="Please select reason for voucher creation" */}
            {/*         onChange={(value) => { */}
            {/*             if (value === REASON_CODE.other) { */}
            {/*                 setDisplayAdditionalReason(true) */}
            {/*             } else { */}
            {/*                 setDisplayAdditionalReason(false) */}
            {/*             } */}
            {/*             onChangeAsValue('reason', value) */}
            {/*         }} */}
            {/*         value={values.reason} */}
            {/*     > */}
            {/*         {reasons.map(({ code, name }) => ( */}
            {/*             <Option */}
            {/*                 key={code} */}
            {/*                 value={code} */}
            {/*             > */}
            {/*                 {name} */}
            {/*             </Option> */}
            {/*         ))} */}
            {/*     </Select> */}
            {/* </CouponFormItem> */}
            {/* {displayAdditionalReason */}
            {/*     && ( */}
            {/*         <CouponFormItem */}
            {/*             error={errors.otherReason} */}
            {/*             required */}
            {/*             extra={values.reason */}
            {/*             && ( */}
            {/*                 <ExtraInfoLabel */}
            {/*                     message="Additional details about the Other reason." */}
            {/*                 /> */}
            {/*             )} */}
            {/*         > */}
            {/*             <Input */}
            {/*                 name="otherReason" */}
            {/*                 value={values.otherReason} */}
            {/*                 onChange={onChange} */}
            {/*             /> */}
            {/*         </CouponFormItem> */}
            {/*     )} */}
            {/* <CouponFormItem */}
            {/*     label="Issued in Venue" */}
            {/* > */}
            {/*     <Select */}
            {/*         showSearch */}
            {/*         placeholder="Please select venue" */}
            {/*         onChange={(value) => { */}
            {/*             onChangeAsValue('issuedInVenueId', value) */}
            {/*         }} */}
            {/*         value={values.issuedInVenueId} */}
            {/*     > */}
            {/*         {venues.map(({ id, name }) => ( */}
            {/*             <Option */}
            {/*                 key={id} */}
            {/*                 value={id} */}
            {/*             > */}
            {/*                 {name} */}
            {/*             </Option> */}
            {/*         ))} */}
            {/*     </Select> */}
            {/* </CouponFormItem> */}
            <CouponFormItem
                label="Available in"
                error={props.errors.legalEntity || props.errors.venue}
                required
            >
                <VenuesSelectWithRedux
                    venue={props.values.venue}
                    onVenueChange={(value) => props.onChangeAsValue('venue', value)}
                    legalEntity={props.values.legalEntity}
                    onLegalEntityChange={(value) => props.onChangeAsValue('legalEntity', value)}
                />
            </CouponFormItem>
            <CouponFormItem
                label="Amount type"
            >
                <Radio.Group
                    name="amountType"
                    value={props.values.amountType}
                    onChange={props.onChange}
                >
                    <Radio.Button value="isAmount">
                        {currencySymbol}
                    </Radio.Button>
                    <Radio.Button value="isPercentage">
                        &#37;
                    </Radio.Button>
                </Radio.Group>
            </CouponFormItem>
            <CouponFormItem
                label="Amount"
                error={props.errors.amount}
                required
            >
                {props.values.amountType === 'isAmount'
                    ? (
                        <InputNumber
                            name="amount"
                            value={props.values.amount}
                            onChange={(value) => props.onChangeAsValue('amount', value)}
                            min={0}
                            formatter={(value) => `${currencySymbol}${value}`}
                            parser={(value) => value.replace(currencySymbol, '').replace(',', '.')}
                        />
                    )
                    : (
                        <InputNumber
                            name="amount"
                            value={props.values.amount}
                            onChange={(value) => props.onChangeAsValue('amount', value)}
                            min={0}
                            max={100}
                            step={1}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value.replace('%', '').replace(',', '.')}
                        />
                    )}
            </CouponFormItem>
            <CouponFormItem
                label="Min. player amount"
            >
                <InputNumber
                    name="minPlayerAmount"
                    value={props.values.minPlayerAmount}
                    onChange={(value) => {
                        if (value === null) {
                            props.onChangeAsValue('minPlayerAmount', null)
                        } else {
                            props.onChangeAsValue('minPlayerAmount', value)
                        }
                    }}
                    min={2}
                    max={9999}
                    precision={0}
                    step={1}
                    defaultValue={null}
                />
            </CouponFormItem>
            <CouponFormItem
                label="Coupon expires"
                error={props.errors.expiresAt}
                extra={!props.values.expiresAt
                    && (
                        <ExtraInfoLabel
                            message="If no date is provided, the code will be permanently available."
                        />
                    )}
            >
                <DatePicker
                    name="expiresAt"
                    format="DD/MM/YYYY"
                    value={props.values.expiresAt}
                    disabledDate={(current) => current && current < dayjs.utc().endOf('day')}
                    onChange={(value) => props.onChangeAsValue('expiresAt', value)}
                    className="full-width"
                />
            </CouponFormItem>
            <CouponFormItem
                label="Last possible play date"
                extra={!props.values.lastPossiblePlayDate
                    && (
                        <ExtraInfoLabel
                            message="If no date is provided, the customer can book their play session on any future date"
                        />
                    )}
            >
                <DatePicker
                    name="lastPossiblePlayDate"
                    format="DD/MM/YYYY"
                    value={props.values.lastPossiblePlayDate}
                    disabledDate={(current) => current && current < dayjs.utc().endOf('day')}
                    onChange={(value) => props.onChangeAsValue('lastPossiblePlayDate', value)}
                    className="full-width"
                />
            </CouponFormItem>
            <CouponFormItem
                label="Description"
                error={props.errors.description}
            >
                <Input.TextArea
                    rows="4"
                    name="description"
                    value={props.values.description}
                    onChange={props.onChange}
                />
            </CouponFormItem>
        </Form>
    )
}

export default CreateCouponForm

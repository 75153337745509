import React from 'react'
import { TopBar } from 'components/layout/TopBar'
import { Main } from  'components/layout/Main'
import VenueSettingsContainer from 'components/layout/sidebar/VenueSettingsContainer'
import Overview from './overview/RoomsOverview'
import CreateRoomModalWithState from './modals/create/CreateRoomModalWithState'

function RoomsPage() {
    return (
        <>
            <TopBar activeMenuItem="venueSettings" />
            <Main>
                <VenueSettingsContainer activeMenuItem="rooms">
                    <Overview paginationKey="roomsOverview" />
                    <CreateRoomModalWithState />
                </VenueSettingsContainer>
            </Main>
        </>
    )
}

export default RoomsPage

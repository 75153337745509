export const toDiscountBannerApiRequest = (values) => ({
    title: {
        en: values.titleEn,
        nl: values.titleNl,
        fr: values.titleFr,
    },
    sub_title: {
        en: values.subTitleEn,
        nl: values.subTitleNl,
        fr: values.subTitleFr,
    },
    blocks: values.blocks.map((block) => ({
        title: {
            en: block.titleEn,
            nl: block.titleNl,
            fr: block.titleFr,
        },
        discount: {
            en: block.discountEn,
            nl: block.discountNl,
            fr: block.discountFr,
        },
        color: block.color,
    })),
})

export const mapDiscountBannerToValues = (discountBanner) => ({
    ...discountBanner,
    titleEn: discountBanner.title.en,
    titleNl: discountBanner.title.nl,
    titleFr: discountBanner.title.fr,
    subTitleEn: discountBanner.subTitle.en,
    subTitleNl: discountBanner.subTitle.nl,
    subTitleFr: discountBanner.subTitle.fr,
    blocks: discountBanner.blocks.map((block, id) => ({
        id,
        titleEn: block.title.en,
        titleNl: block.title.nl,
        titleFr: block.title.fr,
        discountEn: block.discount.en,
        discountNl: block.discount.nl,
        discountFr: block.discount.fr,
        color: block.color,
    })),
})

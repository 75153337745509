import { useMemo } from 'react'
import { getLocaleCurrencySymbol } from 'util/currency'

/**
 * @param {Object} legalEntity
 * @returns {string}
 */
function useCurrencySymbol(legalEntity) {
    let currencySymbol = ''
    currencySymbol = useMemo(() => {
        if (legalEntity && legalEntity.country)
            return getLocaleCurrencySymbol(legalEntity.country.currencyCode, 'en-US')

        return ''
    }, [legalEntity])

    return currencySymbol
}

export default useCurrencySymbol

// eslint-disable-next-line import/no-cycle
import { getPaginationKey } from 'store/reducers/pagination/roomsPaginationReducer'
import { CALL_API, METHODS } from '../middleware/api'
import { roomSchema, roomSchemaArray } from '../schemas/rooms'

export const ROOMS_REQUEST = 'ROOMS_REQUEST'
export const ROOMS_SUCCESS = 'ROOMS_SUCCESS'
export const ROOMS_FAILURE = 'ROOMS_FAILURE'

export const ROOM_REQUEST = 'ROOM_REQUEST'
export const ROOM_SUCCESS = 'ROOM_SUCCESS'
export const ROOM_FAILURE = 'ROOM_FAILURE'

export const fetchRooms = (componentName, venueId, params) => ({
    componentName,
    venueId,
    [CALL_API]: {
        types: [
            ROOMS_REQUEST,
            ROOMS_SUCCESS,
            ROOMS_FAILURE,
        ],
        endpoint: `/venues/${venueId}/rooms`,
        schema: roomSchemaArray,
        params,
    },
})

export const fetchRoomsForActiveVenue = (componentName, params) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch(fetchRooms(componentName, venueId, params))
}

export const fetchRoomsExtendCurrentParams = (paginationKey, params) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    const roomPagination = getState().pagination.rooms[getPaginationKey(paginationKey, venueId)]
    const newParams = {
        ...roomPagination.params,
        ...params,
    }
    return dispatch(fetchRooms(paginationKey, venueId, newParams))
}

export const createRoom = (venueId, body) => ({
    [CALL_API]: {
        method: METHODS.POST,
        types: [
            ROOM_REQUEST,
            ROOM_SUCCESS,
            ROOM_FAILURE,
        ],
        endpoint: `/venues/${venueId}/rooms`,
        schema: roomSchema,
        body,
    },
})

export const createRoomForActiveVenue = (body) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch(createRoom(venueId, body))
}

export const editRoom = (venueId, roomId, body) => ({
    [CALL_API]: {
        method: METHODS.PUT,
        types: [
            ROOM_REQUEST,
            ROOM_SUCCESS,
            ROOM_FAILURE,
        ],
        endpoint: `/venues/${venueId}/rooms/${roomId}`,
        schema: roomSchema,
        entityId: roomId,
        body,
    },
})

export const editRoomForActiveVenue = (roomId, body) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch(editRoom(venueId, roomId, body))
}

export const deleteRoom = (roomId) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch({
        [CALL_API]: {
            method: METHODS.DELETE,
            types: [
                ROOM_REQUEST,
                ROOM_SUCCESS,
                ROOM_FAILURE,
            ],
            endpoint: `/venues/${venueId}/rooms/${roomId}`,
            schema: roomSchema,
            entityId: roomId,
        },
    })
}

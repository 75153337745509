import { useSelector } from 'react-redux'
import { getPagination, getEntitiesByPagination, getEntitiesForCurrentPage } from 'store/util'

const useVenues = ({
    paginationKey,
}) => {
    const pagination = useSelector((state) => getPagination(state, 'roles', paginationKey))
    const venues = useSelector((state) => getEntitiesByPagination(state, 'venues', paginationKey))
    const venuesForCurrentPage = useSelector((state) => getEntitiesForCurrentPage(state, 'venues', paginationKey))

    return ({
        isFetching: pagination.isFetching,
        currentPage: pagination.params.page,
        total: pagination.total,
        params: pagination.params,
        venues,
        venuesForCurrentPage,
    })
}

export default useVenues

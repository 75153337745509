import { CALL_API, METHODS } from '../middleware/api'
import { userSchemaArray, userSchema } from '../schemas/users'

export const USERS_REQUEST = 'USERS_REQUEST'
export const USERS_SUCCESS = 'USERS_SUCCESS'
export const USERS_FAILURE = 'USERS_FAILURE'

export const USER_REQUEST = 'USER_REQUEST'
export const USER_SUCCESS = 'USER_SUCCESS'
export const USER_FAILURE = 'USER_FAILURE'

export const fetchUsers = (viewName, params) => ({
    viewName,
    [CALL_API]: {
        types: [
            USERS_REQUEST,
            USERS_SUCCESS,
            USERS_FAILURE,
        ],
        endpoint: '/users',
        schema: userSchemaArray,
        params,
    },
})

export const fetchUsersExtendCurrentParams = (paginationKey, params) => (dispatch, getState) => {
    const userPagination = getState().pagination.users[paginationKey]
    const newParams = {
        ...userPagination.params,
        ...params,
    }
    return dispatch(fetchUsers(paginationKey, newParams))
}

export const createUser = (body) => ({
    [CALL_API]: {
        method: METHODS.POST,
        types: [
            USER_REQUEST,
            USER_SUCCESS,
            USER_FAILURE,
        ],
        endpoint: '/users',
        schema: userSchema,
        body,
    },
})

export const deleteUser = (userId) => ({
    [CALL_API]: {
        method: METHODS.DELETE,
        types: [
            USER_REQUEST,
            USER_SUCCESS,
            USER_FAILURE,
        ],
        endpoint: `/users/${userId}`,
        schema: userSchema,
        entityId: userId,
    },
})

export const editUser = (userId, body) => ({
    [CALL_API]: {
        method: METHODS.PUT,
        types: [
            USER_REQUEST,
            USER_SUCCESS,
            USER_FAILURE,
        ],
        endpoint: `/users/${userId}`,
        schema: userSchema,
        entityId: userId,
        body,
    },
})

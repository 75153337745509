import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import { toCouponTimeFrameApiRequest } from 'util/transformers/coupon'
import { isValidCouponTimeFrame } from 'util/validation/coupon'
import { getCouponTimeFrameErrors } from 'util/validation/couponErrors'
import CreateCouponTimeFrameRequest from './CreateCouponTimeFrameRequest'

function CreateCouponTimeFrameFormState({
    coupon,
    onCancel,
    onCreateSuccess,
    children,
}) {
    return (
        <CreateCouponTimeFrameRequest coupon={coupon} paginationKey="overview">
            {({
                createCouponTimeFrame,
                createCouponTimeFrameSuccess,
                createCouponTimeFrameFailed,
                fetchCoupons,
            }) => {
                if (coupon === null || typeof coupon === 'undefined') {
                    return null
                }
                return (
                    <WithFormState
                        key={coupon}
                        initialValues={{
                            isWholeDay: true,
                            from: null,
                            until: null,
                        }}
                        isValidForm={isValidCouponTimeFrame}
                        getFormErrors={getCouponTimeFrameErrors}
                        transformValuesToRequestBody={toCouponTimeFrameApiRequest}
                        onCancel={onCancel}
                        onSendRequest={(values) => createCouponTimeFrame(values)}
                        onRequestSuccess={() => {
                            fetchCoupons()
                            createCouponTimeFrameSuccess()
                            onCreateSuccess()
                        }}
                        onRequestFailed={createCouponTimeFrameFailed}
                    >
                        {children}
                    </WithFormState>
                )
            }}
        </CreateCouponTimeFrameRequest>
    )
}

export default CreateCouponTimeFrameFormState

import React from 'react'
import DeleteGameFormState from 'components/pages/settings/games/forms/delete/DeleteGameFormState'
import { ConfirmInputModal } from 'components/util/modals/ConfirmInputModal'

function DeleteGameModal({ isOpen, entity, onClose }) {
    return (
        <DeleteGameFormState
            onDeleteSuccess={onClose}
            onCancel={onClose}
            values={entity}
        >
            {({
                isLoading,
                handleSubmit,
                handleCancel,
            }) => {
                if (entity === null || typeof entity === 'undefined') {
                    return null
                }
                return (
                    <ConfirmInputModal
                        safeWord={entity.title}
                        title={`Are you sure you want to delete ${entity.title}?`}
                        isOpen={isOpen}
                        onConfirm={handleSubmit}
                        onCancel={handleCancel}
                        confirmText={`Delete ${entity.title}`}
                        confirmType="primary"
                        confirmIsDanger={true}
                        cancelText="Cancel"
                        isLoading={isLoading}
                        close={onClose}
                    >
                        <p>
                            Removing a game cannot be undone.
                            {' '}
                            <br />
                            Please type the exact name of the game to confirm.
                        </p>
                    </ConfirmInputModal>
                )
            }}
        </DeleteGameFormState>
    )
}

export default DeleteGameModal

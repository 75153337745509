import React, { useState } from 'react'
import AppLoading from 'components/app/AppLoading'
import Mount from 'components/util/Mount'
import WithActiveFilter from 'components/layout/filters/WithActiveFilters'
import callApi from 'store/middleware/callApi'
import { METHODS } from 'store/middleware/api'

export const OPERATIONS_ID = 'f9a05726-b0da-4001-81d8-77d1c28348bf'
export const OCCUPANCY_ID = 'e92e17ea-4def-4704-8bfe-aa2c1411e4a9'
export const NPSSURVEY_ID = '7ce51fb2-0486-4fa6-a416-fc37dfca50ef'

function WithCumulApiKey({
    children,
}) {
    const [isFetching, setIsFetching] = useState(true)
    const [token, setToken] = useState(null)

    return (
        <WithActiveFilter>
            {({ activeVenueId }) => (
                <Mount
                    didMount={async () => {
                        const endpoint = `/venues/${activeVenueId}/analytics-token`
                        setIsFetching(true)
                        const response = await callApi(METHODS.GET, endpoint)
                        setToken(response.data)
                        setIsFetching(false)
                    }}
                >
                    {(() => {
                        if (isFetching) {
                            return <AppLoading />
                        }

                        if (typeof children === 'function') {
                            return children(token)
                        }

                        return children
                    })()}
                </Mount>
            )}
        </WithActiveFilter>
    )
}

export default WithCumulApiKey

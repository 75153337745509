export const defaultEntitiesState = {
    availabilities: {},
    bookings: {},
    coupons: {},
    couponBatches: {},
    equipmentSets: {},
    games: {},
    gameSessions: {},
    giftCards: {},
    priceAdjustments: {},
    rooms: {},
    roles: {},
    users: {},
    venues: {},
    priceUpdate: {},
}

// eslint-disable-next-line default-param-last
const entitiesResponseReducer = (state = defaultEntitiesState, action) => {
    if (action.response && action.response.entities) {
        const newState = { ...state }
        const { entities } = action.response

        Object.keys(entities).forEach((entityKey) => {
            newState[entityKey] = { ...newState[entityKey], ...entities[entityKey] }
        })
        return newState
    }
    return state
}

export default entitiesResponseReducer

import React from 'react'
import { Modal } from 'antd'
import { ModalFooter } from 'components/util/modals/ModalFooter'
import CreateUserForm from 'components/pages/settings/users/forms/create/CreateUserForm'
import CreateUserFormState from 'components/pages/settings/users/forms/create/CreateUserFormState'

function CreateUserModal({ isOpen, onClose }) {
    return (
        <CreateUserFormState
            onSaveSuccess={onClose}
            onCancel={onClose}
        >
            {({
                values,
                errors,
                isLoading,
                isSubmitted,
                handleChange,
                handleSubmit,
                handleCancel,
                handleChangeAsValue,
                mergeValues,
            }) => (
                <Modal
                    title="Invite new user"
                    open={isOpen}
                    onCancel={handleCancel}
                    onOk={handleSubmit}
                    closable={!isLoading}
                    maskClosable={!isLoading}
                    footer={(
                        <ModalFooter
                            submitText="Invite"
                            isLoading={isLoading}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                        />
                    )}
                >
                    {isOpen && (
                        <CreateUserForm
                            isLoading={isLoading}
                            isSubmitted={isSubmitted}
                            values={values}
                            errors={errors}
                            onChange={handleChange}
                            onChangeAsValue={handleChangeAsValue}
                            mergeValues={mergeValues}
                        />
                    )}
                </Modal>
            )}
        </CreateUserFormState>
    )
}

export default CreateUserModal

import { Component } from 'react'
import { notification } from 'antd'
import callApi from 'store/middleware/callApi'
import store from 'store/reduxStore'
import { setBookingActive, setBookingInactive } from 'store/actions/bookings'
import { METHODS } from 'store/middleware/api'

class ToggleActiveBookingRequest extends Component {
    constructor(props) {
        super(props)
        this.state = { isLoading: false, errors: [] }
        this.toggleActiveStatus = this.toggleActiveStatus.bind(this)
    }

    async toggleActiveStatus(bookingId, value) {
        this.setState({ isLoading: true })
        const { venueId } = this.props
        const endPoint = `/venues/${venueId}/bookings/${bookingId}/toggle-active`
        try {
            await callApi(METHODS.PATCH, endPoint)
            if (value) {
                store.dispatch(setBookingActive(bookingId))
            } else {
                store.dispatch(setBookingInactive(bookingId))
            }
            this.setState({ isLoading: false })
        } catch (e) {
            this.setState({ isLoading: false, errors: e })
            notification.error({
                message: 'Something went wrong',
                description: `${e.message}`,
            })
        }
    }

    render() {
        const { isLoading, errors } = this.state
        // eslint-disable-next-line react/destructuring-assignment
        return this.props.children({
            toggleActiveStatus: this.toggleActiveStatus,
            isLoading,
            errors,
        })
    }
}

export default ToggleActiveBookingRequest

import { getPagination, getEntitiesByPagination, getEntitiesForCurrentPage } from 'store/util'
import { fetchVenues } from 'store/actions/venues'
import withReduxState from 'components/hoc/withReduxState'

const mapStateToProps = (state, ownProps) => {
    const pagination = getPagination(state, 'venues', ownProps.paginationKey)
    return ({
        isFetching: pagination.isFetching,
        currentPage: pagination.params.page,
        total: pagination.total,
        params: pagination.params,
        venues: getEntitiesByPagination(state, 'venues', ownProps.paginationKey),
        venuesForCurrentPage: getEntitiesForCurrentPage(state, 'venues', ownProps.paginationKey),
    })
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const {
        paginationKey,
        limit,
    } = ownProps
    const page = 1
    return ({
        fetchVenues: (params) => {
            const finalParams = {
                page,
                limit,
                ...params,
            }
            dispatch(fetchVenues(paginationKey, finalParams))
        },
    })
}

const WithVenues = withReduxState(mapStateToProps, mapDispatchToProps)

export default WithVenues

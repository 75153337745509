import { useDispatch, useSelector } from 'react-redux'
import { getPagination, getEntitiesByPagination, getEntitiesForCurrentPage } from 'store/util/index'
import { fetchCouponBatches } from 'store/actions/coupons'

export default function useCouponBatches(paginationKey = 'overview', limit = 50) {
    const dispatch = useDispatch()
    const state = useSelector((stateSelector) => stateSelector)
    const pagination = getPagination(state, 'couponBatches', paginationKey)

    const page = 1
    const sort = 'created_at.desc'
    const status = 'active'

    return ({
        isFetching: pagination.isFetching,
        currentPage: pagination.params.page,
        total: pagination.total,
        params: pagination.params,
        coupons: getEntitiesByPagination(state, 'couponBatches', paginationKey),
        couponsForCurrentPage: getEntitiesForCurrentPage(state, 'couponBatches', paginationKey),
        fetchCouponBatches: (params) => {
            const finalParams = {
                page,
                limit,
                status,
                sort,
                ...params,
            }
            dispatch(fetchCouponBatches(paginationKey, finalParams))
        },
    })
}

import React from 'react'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import {
    deleteVoucher,
    refreshPaginatedVouchers,
} from '../../../../../../store/actions/vouchers'
import { WithFormState } from '../../../../../util/forms/WithFormState'
import { notification } from 'antd'

/**
 * @param {Object} props
 * @param {() => void} props.onCancel
 * @param {React.ReactElement | React.ReactElement[] | undefined} props.children
 * @param {Object} props.voucher
 * @param {() => void} props.onDeleteVoucherSuccess
 * @constructor
 * @returns {React.JSX.Element}
 */
export function DeleteVoucherFormState(props) {
    const dispatch = useDispatch()

    // console.log('DeleteVoucherFormState props:', props)

    const deleteVoucherCallback = useCallback(async (values) => {
        return dispatch(deleteVoucher(values.voucherId))
    }, [dispatch])

    function onDeleteVoucherSuccessCallback(response) {
        notification.success({
            message: 'voucher added!',
            description: (
                <span>
                    voucher with code <strong>{props.voucher.coupon.code}</strong> was successfully created.
                </span>
            ),
        })

        dispatch(refreshPaginatedVouchers('overview'))
        props.onDeleteVoucherSuccess()
    }

    const onDeleteVoucherFailed = useCallback((error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    }, [])

    return (
        <WithFormState
            initialValues={{ voucherId: props.voucher.id}}
            onSendRequest={deleteVoucherCallback}
            onCancel={props.onCancel}
            onRequestFailed={onDeleteVoucherFailed}
            onRequestSuccess={onDeleteVoucherSuccessCallback}
        >
            {props.children}
        </WithFormState>
    )
}

import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { ROLES } from 'util/constants'
import HasPermissions from 'components/pages/authorized/HasPermissions'
import CreateVenueGameModalState from '../modals/create/CreateVenueGameModalState'

function CreateVenueGameButton() {
    return (
        <HasPermissions roles={[ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.VENUE_MANAGER]}>
            <CreateVenueGameModalState>
                {({ openModal }) => (
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<PlusOutlined />}
                        onClick={openModal}
                    />
                )}
            </CreateVenueGameModalState>
        </HasPermissions>
    )
}

export default CreateVenueGameButton

import React from 'react'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'
import { fetchUsersExtendCurrentParams, editUser } from 'store/actions/users'

const mapDispatchToProps = (dispatch, ownProps) => ({
    editUser: async (values) => dispatch(editUser(ownProps.user.id, values)),
    editUserSuccess: (user) => {
        notification.success({
            message: ' for user edited!',
            description: (
                <span>
                    User
                    <br />
                    <strong>
                        {user.firstName}
                        {' '}
                        {user.lastName}
                    </strong>
                    <br />
                    was successfully edited.
                </span>
            ),
        })
    },
    editUserFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchUsers: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchUsersExtendCurrentParams(paginationKey))
    },
})

const EditUserRequest = withReduxState(null, mapDispatchToProps)

export default EditUserRequest

import { isRequired } from './index'

export const isValidName = (name) => isRequired(name)

export const isValidUid = (uid) => isRequired(uid)

export const isValidEquipmentSet = ({
    name,
    uid,
}) => (
    isValidName(name)
  && isValidUid(uid)
)

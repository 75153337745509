import dayjs from 'dayjs'
import { BACKEND_DATE_FORMAT, BACKEND_TIME_FORMAT } from '../constants'

function toGameSessionApiRequest(values) {
    const date = dayjs.utc(values.date)
    const roomStart = dayjs.utc(values.roomStart)
    const startString = `${date.format(BACKEND_DATE_FORMAT)} ${roomStart.format(BACKEND_TIME_FORMAT)}`

    return {
        game: values.game,
        room: values.room,
        start: startString,
    }
}

export default toGameSessionApiRequest

import React from 'react'
import { fetchVenueGamesExtendCurrentParams } from 'store/actions/venueGames'
import { createPriceAdjustment } from 'store/actions/priceAdjustments'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    createPriceAdjustment: async (values) => dispatch(createPriceAdjustment(ownProps.game.id, values)),
    createPriceAdjustmentSuccess: () => {
        notification.success({
            message: 'Price adjustment added!',
            description: (
                <span>
                    The price adjustment was successfully added to the game!
                </span>
            ),
        })
    },
    createPriceAdjustmentFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchVenueGames: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchVenueGamesExtendCurrentParams(paginationKey))
    },
})

const CreatePriceAdjustmentRequest = withReduxState(null, mapDispatchToProps)

export default CreatePriceAdjustmentRequest

import React, {useCallback} from "react"
import "./GameSessionBookingCustomerProfilesEntry.css"
import {Button, Checkbox} from "antd"

/**
 * @component
 * @param {Object} props
 * @param {GameSessionPlayersState} props.gsState
 * @param {BookingAndCustomerProfilesOption} props.bookingEntry
 * @param {boolean} props.isProcessingRequest
 // * @param {(profileIds: number[], select: boolean) => void} props.changePlayersSelectedStatus
 */
export function GameSessionBookingAndCustomerProfilesEntry(props) {
    const hasProfiles = props.bookingEntry.customerProfileOptions.length !== 0
    let allProfilesAreAssigned = true
    if (props.gsState.emptyGamePlayerSpotsCount !== 0) {
        for (let i = 0, length = props.bookingEntry.customerProfileOptions.length; i < length; i++) {
            const profile = props.bookingEntry.customerProfileOptions[i]
            if (profile.canSelect) {
                allProfilesAreAssigned = false
                break
            }
        }
    }
    let selectedProfileCount = 0
    let selectableProfileCount = 0
    if (!allProfilesAreAssigned) {
        for (let i = 0, length = props.bookingEntry.customerProfileOptions.length; i < length; i++) {
            const profile = props.bookingEntry.customerProfileOptions[i]
            if (!profile.canSelect) {
                continue
            }
            // console.log("Profile:", profile)
            if (profile.isSelected) {
                selectedProfileCount++
            } else {
                selectableProfileCount++
            }
        }
    }
    // console.log("All profiles are assigned", allProfilesAreAssigned)
    // console.log("Selected profile count:", selectedProfileCount)
    // console.log("Selectable profile count:", selectableProfileCount)


    /** @type {(profileId: number, select: boolean) => void} */
    const updateSingleProfileIdSelectionStatus = useCallback((profileId, select) => {
        props.gsState.updateSelectedProfileIds([profileId], select)
    }, [props.gsState])

    function selectAll() {
        /** @type number[] */
        const profileIds = []
        for (let i = 0, length = props.bookingEntry.customerProfileOptions.length; i < length; i++) {
            const option = props.bookingEntry.customerProfileOptions[i]
            if (option.canSelect && !option.isSelected) {
                profileIds.push(option.customerProfileId)
            }
        }

        props.gsState.updateSelectedProfileIds(profileIds, true)
    }

    function deselectAll() {
        /** @type number[] */
        const profileIds = []
        for (let i = 0, length = props.bookingEntry.customerProfileOptions.length; i < length; i++) {
            const option = props.bookingEntry.customerProfileOptions[i]
            if (option.canSelect && option.isSelected) {
                profileIds.push(option.customerProfileId)
            }
        }

        props.gsState.updateSelectedProfileIds(profileIds, false)
    }


    return (
        <div id={props.bookingEntry.bookingId.toString()} className="gsbcpe-root">
            <p className="gsbcpe-booking-title">
                Booking of <b>{props.bookingEntry.bookerName}</b> | Booked for <b>{props.bookingEntry.bookedPlayerAmount}</b> | Registered <b>{props.bookingEntry.registeredPlayerAmount}</b>
            </p>
            <div className="gsbcpe-profiles-container">
                {
                    hasProfiles ? (

                        props.bookingEntry.customerProfileOptions.map((po) => (
                            <ProfileEntry
                                key={po.customerProfileId}
                                profileEntry={po}
                                updateSelectStatus={updateSingleProfileIdSelectionStatus}
                                isProcessingRequest={props.isProcessingRequest}
                            />
                        ))
                    ) : (
                        <p>No registered customers</p>
                    )
                }
            </div>
            {
                hasProfiles && (
                    <div className="gsbcpe-booking-buttons-container">
                        <Button
                            type="primary"
                            onClick={selectAll}
                            disabled={allProfilesAreAssigned || selectableProfileCount === 0 || props.isProcessingRequest}
                        >
                            Select all
                        </Button>
                        <Button
                            type="primary"
                            onClick={deselectAll}
                            disabled={allProfilesAreAssigned || selectedProfileCount === 0 || props.isProcessingRequest}
                        >
                            Deselect all
                        </Button>
                    </div>
                )
            }
        </div>
    )
}

/**
 * @component
 * @param {Object} props
 * @param {BookingCustomerProfileOption} props.profileEntry
 * @param {(profileId: number, select: boolean) => void} props.updateSelectStatus
 * @param {boolean} props.isProcessingRequest
 */
function ProfileEntry(props) {
    return (
        <Checkbox
            disabled={props.profileEntry.canSelect === false || props.isProcessingRequest}
            checked={props.profileEntry.isSelected}
            onChange={(e) => props.updateSelectStatus(props.profileEntry.customerProfileId, e.target.checked)}
        >
            {props.profileEntry.nickname} <span className="gsbcpe-fullname">{typeof props.profileEntry.fullName === "string" && `(${props.profileEntry.fullName})`}</span>
        </Checkbox>
    )
}
import withReduxState from 'components/hoc/withReduxState'
import { getPaginationKey } from 'store/reducers/pagination/venueGamesPaginationReducer'
import { fetchGamesForActiveVenue } from 'store/actions/venueGames'
import { getEntitiesByPagination, getPagination, getEntitiesForCurrentPage } from 'store/util/index'

const mapStateToProps = (state, ownProps) => {
    const { venueId } = state.activeFilters
    const { paginationKey } = ownProps
    const fullPaginationKey = getPaginationKey(
        paginationKey,
        venueId,
    )
    const pagination = getPagination(state, 'venueGames', fullPaginationKey) || { isFetching: true }
    return ({
        isFetching: pagination.isFetching,
        currentPage: pagination.params.page,
        total: pagination.total,
        params: pagination.params,
        games: getEntitiesByPagination(state, 'venueGames', fullPaginationKey),
        gamesForCurrentPage: getEntitiesForCurrentPage(state, 'venueGames', fullPaginationKey),
    })
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const {
        paginationKey,
        limit,
    } = ownProps
    const page = 1
    return ({
        fetchGames: (params) => {
            const finalParams = {
                page,
                limit,
                ...params,
            }
            return dispatch(fetchGamesForActiveVenue(paginationKey, finalParams))
        },
    })
}

const WithGames = withReduxState(mapStateToProps, mapDispatchToProps)

export default WithGames

import React from 'react'
import { createVenueGame, fetchVenueGamesExtendCurrentParams } from 'store/actions/venueGames'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    createVenueGame: async (values) => dispatch(createVenueGame(values)),
    createVenueGameSuccess: (venueGame) => {
        notification.success({
            message: 'Game added!',
            description: (
                <span>
                    The game
                    {' '}
                    <strong>{venueGame && venueGame.title}</strong>
                    &nbsp;
                    was successfully added to this venue.
                </span>
            ),
        })
    },
    createVenueGameFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchVenueGames: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchVenueGamesExtendCurrentParams(paginationKey))
    },
})

const CreateVenueGameRequest = withReduxState(null, mapDispatchToProps)

export default CreateVenueGameRequest

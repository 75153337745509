import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import { MODAL_IDS } from 'util/constants'

function EditCouponTimeFrameModalState({
    timeFrame,
    children,
}) {
    return (
        <WithActiveModal
            modalId={MODAL_IDS.EDIT_COUPON_TIME_FRAME}
            entity={timeFrame}
            entityKey="couponTimeFrame"
        >
            {children}
        </WithActiveModal>
    )
}

export default EditCouponTimeFrameModalState

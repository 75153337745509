import React from 'react'
import WithActiveFilters from 'components/layout/filters/WithActiveFilters'
import './Main.css'

/**
 * @component
 * @param {Object} props
 * @param {React.ReactNode|React.ReactNode[]|null} props.children
 * @return {JSX.Element}
 */
export function Main(props) {
    return (
        <main className="main">
            <WithActiveFilters>
                {({ activeVenueId }) => {
                    if (activeVenueId === null) {
                        return null
                    }
                    return (
                        <div key={activeVenueId} className="mainContainer max-width-container">
                            {props.children}
                        </div>
                    )
                }}
            </WithActiveFilters>
        </main>
    )
}

import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import { MODAL_IDS } from 'util/constants'
import DeleteDiscountBannerModal from './DeleteDiscountBannerModal'

function DeleteDiscountBannerModalWithRedux() {
    return (
        <WithActiveModal modalId={MODAL_IDS.DELETE_DISCOUNT_BANNER}>
            {({
                isOpen,
                entity,
                closeModal,
            }) => (
                <DeleteDiscountBannerModal
                    isOpen={isOpen}
                    onClose={closeModal}
                    entity={entity}
                />
            )}
        </WithActiveModal>
    )
}
export default DeleteDiscountBannerModalWithRedux

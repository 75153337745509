import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import useCurrentUser from './useCurrentUser'

/**
 * @typedef {Object} Role
 * @property {string} slug
 * @property {string} label
 * @property {string} createdAt
 * @property {string} updatedAt
 */

/**
 * Returns the current user role if it has been fetched
 * @returns {Role|null}
 */
export function useUserRole() {
    const currentUser = useCurrentUser()
    const rolesObject = useSelector(state => state.entities.roles)

    /** @type {Role|undefined} */
    const userRole = useMemo(() => {
        if (!currentUser.hasFetchedUser)
            return undefined
        if (typeof rolesObject === 'undefined' || rolesObject === null)
            return undefined

        const roleId = currentUser.user.role
        const allRoles = Object.keys(rolesObject).map((roleKey) => rolesObject[roleKey])

        return allRoles.find(role => role.id === roleId)
    }, [currentUser, rolesObject])

    if (currentUser.hasFetchedUser === false || typeof userRole === 'undefined') {
        return null
    }

    return userRole
}

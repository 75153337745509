import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import { MODAL_IDS } from 'util/constants'
import CreateUserModal from './CreateUserModal'

function CreateUserModalWithRedux() {
    return (
        <WithActiveModal modalId={MODAL_IDS.CREATE_USER}>
            {({
                isOpen,
                closeModal,
            }) => (
                <CreateUserModal
                    isOpen={isOpen}
                    onClose={closeModal}
                />
            )}
        </WithActiveModal>
    )
}
export default CreateUserModalWithRedux

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLegalEntity } from 'components/hooks/useLegalEntity'
import CreateGiftCardModal from './CreateGiftCardModal'
import CreateGiftCardModalState from './CreateGiftCardModalState'
import CreateGiftCardFormState from '../../forms/create/CreateGiftCardFormState'

function CreateGiftCardModalWithState() {
    const legalEntity = useLegalEntity()
    if (typeof legalEntity === 'undefined')
        throw new Error(`Legal Entity is undefined! Comp: ${CreateGiftCardModalWithState.name}`)
    const legalEntities = useSelector(({ entities }) => entities.legalEntities)

    // I don't know why legal entities returns as an object, so yea...
    const legalEntitiesArray = useMemo(() => {
        if (typeof legalEntities === 'object') {
            return Object.values(legalEntities)
        }

        return undefined
    }, [legalEntities])

    return (
        <CreateGiftCardModalState>
            {({ isOpen, closeModal }) => (
                <CreateGiftCardFormState
                    onCancel={closeModal}
                    onSaveSuccess={closeModal}
                    legalEntities={legalEntities}
                    activeLegalEntity={legalEntity}
                >
                    {({
                        values,
                        errors,
                        isLoading,
                        handleChange,
                        handleChangeAsValue,
                        handleCancel,
                        handleSubmit,
                    }) => (
                        <CreateGiftCardModal
                            isOpen={isOpen}
                            onClose={closeModal}
                            values={values}
                            errors={errors}
                            isLoading={isLoading}
                            onChange={handleChange}
                            onChangeAsValue={handleChangeAsValue}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                            activeLegalEntity={legalEntity}
                            legalEntities={legalEntitiesArray}
                        />
                    )}
                </CreateGiftCardFormState>
            )}
        </CreateGiftCardModalState>
    )
}
export default CreateGiftCardModalWithState

import React from 'react'
import CreateRoomButton from '../forms/CreateRoomButton'
import './NoRooms.css'

function NoRooms() {
    return (
        <div className="full-height">
            <div className="flex justify-content-space-between no-rooms-top-bar">
                <h3>Rooms</h3>
                <CreateRoomButton />
            </div>
            <div className="noRoomsContainer">
                <p>Start by adding rooms for this venue</p>
            </div>
        </div>
    )
}

export default NoRooms

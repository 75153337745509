import React from "react"
import {InputNumber, Radio, Alert, Switch} from "antd"
import Loading from "components/util/Loading"
import { PriceInput } from "components/util/forms/PriceInput"
import "./PricingFormItems.css"
import { formatCurrency } from "../../../../../util/currency"
import {BookingFormItem} from "./BookingFormItem"
import {BookingDetailItem} from "../../details/BookingDetailItem"
import BookingDealTypeSelect from "../../../../util/BookingDealTypeSelect"
import {CheckOutlined, CloseOutlined} from "@ant-design/icons"

/**
 * @component
 * @param {Object} props
 * @param {CreateOrEditBookingFormValues} props.values
 * @param {{ [Key in keyof CreateOrEditBookingFormValues]: string[]}} props.errors
 * @param {(key: keyof CreateOrEditBookingFormValues, value: CreateOrEditBookingFormValues[Key]) => void } props.onChangeAsValue
 * @param {(valuesToMerge: {[Key in keyof CreateOrEditBookingFormValues]: CreateOrEditBookingFormValues[Key]}) => void} props.mergeValues
 * @param {BookingPricingAPIResponseBody=} props.bookingPriceResponseBody
 * @param {BookingDealTypeResource[]} props.bookingDealTypes
 * @returns {JSX.Element}
 */
export function BookingPricingFormItems(props) {
    // console.log("Price:", price)
    if (props.values.isSocialDeal) {
        let socialDealTypeValue = null
        if (typeof props.values.socialDealFixedAmount === "number")
            socialDealTypeValue = "fixed_price"
        else if (typeof props.values.socialDealDiscountPercentage === "number")
            socialDealTypeValue = "discount_percentage"
        else
            socialDealTypeValue = "fixed_price"

        return (
            <div>
                {/*<BookingFormItem>*/}
                {/*    <Radio.Group*/}
                {/*        name="isSocialDeal"*/}
                {/*        value={socialDealTypeValue}*/}
                {/*        onChange={(e) => {*/}
                {/*            const val = e.target.value*/}

                {/*            props.mergeValues({*/}
                {/*                socialDealFixedAmount: val === "fixed_price" ? 0.0 : null,*/}
                {/*                socialDealDiscountPercentage: val === "discount_percentage" ? 0.0 : null,*/}
                {/*            })*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <Radio.Button value={"fixed_price"}>*/}
                {/*            Fixed price*/}
                {/*        </Radio.Button>*/}
                {/*        <Radio.Button value={"discount_percentage"}>*/}
                {/*            Discount Percentage*/}
                {/*        </Radio.Button>*/}
                {/*    </Radio.Group>*/}
                {/*</BookingFormItem>*/}

                {
                    socialDealTypeValue === "fixed_price" && (
                        <BookingFormItem
                            error={props.errors.socialDealFixedAmount}
                            label="Price incl VAT"
                            isRequired
                        >
                            <PriceInput
                                value={props.values.socialDealFixedAmount}
                                onChange={(value) => props.onChangeAsValue("socialDealFixedAmount", value)}
                            />
                            <p className="pricing-form-items-foot-note">
                                The total price of the booking including VAT without commissions.
                            </p>
                        </BookingFormItem>
                    )
                }

                {/*{*/}
                {/*    socialDealTypeValue === "discount_percentage" && (*/}
                {/*        <BookingFormItem*/}
                {/*            error={props.errors.socialDealDiscountPercentage}*/}
                {/*            label="Discount"*/}
                {/*            isRequired*/}
                {/*        >*/}
                {/*            <InputNumber*/}
                {/*                className="full-width"*/}
                {/*                value={props.values.socialDealDiscountPercentage}*/}
                {/*                min={0}*/}
                {/*                max={100}*/}
                {/*                formatter={(value) => `${value}%`}*/}
                {/*                parser={(value) => value.replace("%", "")}*/}
                {/*                onChange={(value) => props.onChangeAsValue("socialDealDiscountPercentage", value)}*/}
                {/*            />*/}
                {/*        </BookingFormItem>*/}
                {/*    )*/}
                {/*}*/}

                {
                    props.values.socialDealFixedAmount !== null && props.values.socialDealFixedAmount > 0.0 && (
                        <BookingFormItem
                            label="Customer has already paid?"
                        >
                            <Switch
                                size="small"
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={props.values.markAsAlreadyPaid}
                                onChange={(checked) => props.onChangeAsValue("markAsAlreadyPaid", checked)}
                            />
                        </BookingFormItem>
                    )
                }

                <BookingFormItem
                    label="Deal Type"
                >
                    <BookingDealTypeSelect
                        bookingDealTypes={props.bookingDealTypes}
                        value={props.values.bookingDealType}
                        onChange={(value) => props.onChangeAsValue("bookingDealType", value)}
                    />
                </BookingFormItem>
            </div>
        )
    } else if (typeof props.bookingPriceResponseBody !== "undefined" && props.bookingPriceResponseBody !== null) {
        const {
            amountAlreadyPaid,
            newBookingPriceOutputData,
            originalBookingPriceOutputData,
        } = props.bookingPriceResponseBody

        if (typeof newBookingPriceOutputData === "undefined") {
            return null
        }

        // console.log("amountAlreadyPaid:", amountAlreadyPaid)
        // console.log("newBookingPriceOutputData:", newBookingPriceOutputData)
        // console.log("originalBookingPriceOutputData:", originalBookingPriceOutputData)

        let finalAmountAlreadyPaid = null
        if (typeof amountAlreadyPaid === "number") {
            finalAmountAlreadyPaid = amountAlreadyPaid

            // Margin of error
            if (Math.abs(finalAmountAlreadyPaid - newBookingPriceOutputData.totalPriceWithVat) < 0.05)
                finalAmountAlreadyPaid = newBookingPriceOutputData.totalPriceWithVat
        }

        let outstandingAmount = null
        if (typeof originalBookingPriceOutputData !== "undefined" &&
            typeof originalBookingPriceOutputData.outstandingAmount === "number") {
            // console.log("originalBookingPriceOutputData.outstandingAmount:", originalBookingPriceOutputData.outstandingAmount)
            outstandingAmount = originalBookingPriceOutputData.outstandingAmount

            if (typeof finalAmountAlreadyPaid === "number") {
                finalAmountAlreadyPaid = Math.round((finalAmountAlreadyPaid - outstandingAmount) * 100) / 100
                // console.log("finalAmountAlreadyPaid:", finalAmountAlreadyPaid)

                // Margin of error
                if (Math.abs(finalAmountAlreadyPaid - newBookingPriceOutputData.totalPriceWithVat) < 0.05)
                    finalAmountAlreadyPaid = newBookingPriceOutputData.totalPriceWithVat
            }
        }

        let differenceIncludingVat = null
        if (typeof finalAmountAlreadyPaid === "number") {
            differenceIncludingVat = Math.round((newBookingPriceOutputData.totalPriceWithVat - finalAmountAlreadyPaid) * 100) / 100
            // console.log("differenceIncludingVat:", differenceIncludingVat)
        }

        const newOrOldBookingHasDrinks = newBookingPriceOutputData.drinksAdded === true || (
            typeof originalBookingPriceOutputData !== "undefined" && originalBookingPriceOutputData.drinksAdded === true
        )
        const newOrOldBookingHasOpenBar = newBookingPriceOutputData.openBarAdded === true || (
            typeof originalBookingPriceOutputData !== "undefined" && originalBookingPriceOutputData.openBarAdded === true
        )
        const newOrOldBookingHasNachos = newBookingPriceOutputData.nachosAdded === true || (
            typeof originalBookingPriceOutputData !== "undefined" && originalBookingPriceOutputData.nachosAdded === true
        )
        const newOrOldBookingHasTwoDrinkTokens = newBookingPriceOutputData.twoDrinkTokensAdded === true || (
            typeof originalBookingPriceOutputData !== "undefined" && originalBookingPriceOutputData.twoDrinkTokensAdded === true
        )
        const newOrOldBookingHasUpselling = newOrOldBookingHasDrinks || newOrOldBookingHasOpenBar || newOrOldBookingHasNachos || newOrOldBookingHasTwoDrinkTokens


        let showMarkAsPaidOption = false
        if (differenceIncludingVat !== null) {
            showMarkAsPaidOption = differenceIncludingVat > 0.0
        } else {
            showMarkAsPaidOption = newBookingPriceOutputData.totalPriceWithVat > 0.0
        }

        if (props.values.markAsAlreadyPaid === true) {
            finalAmountAlreadyPaid = newBookingPriceOutputData.totalPriceWithVat
            differenceIncludingVat = 0.0
        }

        const currencyCode = newBookingPriceOutputData.currencyCode

        return (
            <>
                {
                    newBookingPriceOutputData.ticketsTotalPriceWithVat > 0.0 && newBookingPriceOutputData.fullPricePlayerAmount > 0 && (
                        <BookingDetailItem label="VR Experience Tickets">
                            <div className="pricing-form-items-entry">
                                <span>{formatCurrency(newBookingPriceOutputData.ticketsUnitPriceWithVat, currencyCode)}</span>
                                <span className="txta-center">{newBookingPriceOutputData.fullPricePlayerAmount}x</span>
                                <span className="txta-right">{formatCurrency(newBookingPriceOutputData.ticketsTotalPriceWithVat, currencyCode)}</span>
                            </div>
                        </BookingDetailItem>
                    )
                }

                {
                    newBookingPriceOutputData.hasDiscountedTickets && (
                        <BookingDetailItem label="Discounted VR Experience Tickets">
                            <div className="pricing-form-items-entry">
                                <span>{formatCurrency(newBookingPriceOutputData.discountedTicketsUnitPriceWithVat, currencyCode)}</span>
                                <span className="txta-center">{newBookingPriceOutputData.discountedPlayerAmount}x</span>
                                <span className="txta-right">{formatCurrency(newBookingPriceOutputData.discountedTicketsTotalPriceWithVat, currencyCode)}</span>
                            </div>
                        </BookingDetailItem>
                    )
                }

                {
                    newBookingPriceOutputData.isPrivate === true &&
                    newBookingPriceOutputData.isForcedPrivate === true && (
                        <BookingDetailItem label="Private booking tickets">
                            <div className="pricing-form-items-entry">
                                <span>{formatCurrency(newBookingPriceOutputData.unitPriceForPrivateBookingWithVat, currencyCode)}</span>
                                <span className="txta-center">{newBookingPriceOutputData.remainingSeatsBeforePrivateByDefault}x</span>
                                <span className="txta-right">{formatCurrency(newBookingPriceOutputData.totalPriceForPrivateBookingWithVat, currencyCode)}</span>
                            </div>
                        </BookingDetailItem>
                    )
                }

                {
                    newBookingPriceOutputData.drinksAdded === true && (
                        <BookingDetailItem label="2 Drinks">
                            <div className="pricing-form-items-entry">
                                <span>{formatCurrency(newBookingPriceOutputData.drinksUnitPriceWithVat, currencyCode)}</span>
                                <span className="txta-center">{newBookingPriceOutputData.playerAmount}x</span>
                                <span className="txta-right">{formatCurrency(newBookingPriceOutputData.drinksTotalPriceWithVat, currencyCode)}</span>
                            </div>
                        </BookingDetailItem>
                    )
                }

                {
                    newBookingPriceOutputData.openBarAdded === true && (
                        <BookingDetailItem label="1h Open Bar">
                            <div className="pricing-form-items-entry">
                                <span>{formatCurrency(newBookingPriceOutputData.openBarUnitPriceWithVat, currencyCode)}</span>
                                <span className="txta-center">{newBookingPriceOutputData.playerAmount}x</span>
                                <span className="txta-right">{formatCurrency(newBookingPriceOutputData.openBarTotalPriceWithVat, currencyCode)}</span>
                            </div>
                        </BookingDetailItem>
                    )
                }

                {
                    newBookingPriceOutputData.nachosAdded === true && (
                        <BookingDetailItem label="Nachos">
                            <div className="pricing-form-items-entry">
                                <span>{formatCurrency(newBookingPriceOutputData.nachosUnitPriceWithVat, currencyCode)}</span>
                                <span className="txta-center">{newBookingPriceOutputData.playerAmount}x</span>
                                <span className="txta-right">{formatCurrency(newBookingPriceOutputData.nachosTotalPriceWithVat, currencyCode)}</span>
                            </div>
                        </BookingDetailItem>
                    )
                }

                {
                    newBookingPriceOutputData.twoDrinkTokensAdded === true && (
                        <BookingDetailItem label="Two drink tokens pp">
                            <div className="pricing-form-items-entry">
                                <span>{formatCurrency(newBookingPriceOutputData.twoDrinkTokensUnitPriceWithVat, currencyCode)}</span>
                                <span className="txta-center">{newBookingPriceOutputData.playerAmount}x</span>
                                <span className="txta-right">{formatCurrency(newBookingPriceOutputData.twoDrinkTokensTotalPriceWithVat, currencyCode)}</span>
                            </div>
                        </BookingDetailItem>
                    )
                }

                <div className="pricing-form-items-spacer" />

                <BookingDetailItem label="Total incl. VAT">
                    <div className="pricing-form-items-entry">
                        <span />
                        <span />
                        <span className="txta-right">{formatCurrency(newBookingPriceOutputData.totalPriceWithVat, currencyCode)}</span>
                    </div>
                </BookingDetailItem>
                {
                    typeof finalAmountAlreadyPaid === "number" && (
                        <BookingDetailItem label="Amount already paid">
                            <div className="pricing-form-items-entry">
                                <span />
                                <span />
                                <span className="txta-right">{finalAmountAlreadyPaid > 0.0 ? formatCurrency(-finalAmountAlreadyPaid, currencyCode) : formatCurrency(finalAmountAlreadyPaid, currencyCode)}</span>
                            </div>
                        </BookingDetailItem>
                    )
                }

                {
                    typeof differenceIncludingVat === "number" && (
                        <BookingDetailItem label="New balance incl. VAT">
                            <div className="pricing-form-items-entry">
                                <span />
                                <span />
                                <span className="txta-right">{formatCurrency(differenceIncludingVat, currencyCode)}</span>
                            </div>
                        </BookingDetailItem>
                    )
                }
                {
                    showMarkAsPaidOption && (
                        <div className="margin-top">
                            <BookingFormItem
                                label="Customer has already paid?"
                            >
                                <Switch
                                    size="small"
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={props.values.markAsAlreadyPaid}
                                    onChange={(checked) => props.onChangeAsValue("markAsAlreadyPaid", checked)}
                                />
                            </BookingFormItem>
                        </div>
                    )
                }

                {
                    typeof differenceIncludingVat === "number" && differenceIncludingVat < 0.0 &&
                    newOrOldBookingHasUpselling === false && (
                        <BookingDetailItem>
                            <div className="pricing-form-voucher-alert-wrapper">
                                <Alert
                                    message="Voucher required"
                                    description={`Customer is owed a voucher for ${formatCurrency(Math.abs(differenceIncludingVat), currencyCode)}`}
                                    type="info"
                                />
                            </div>
                        </BookingDetailItem>
                    )
                }

                {
                    typeof differenceIncludingVat === "number" && differenceIncludingVat < 0.0 &&
                    newOrOldBookingHasUpselling === true && (
                        <BookingDetailItem>
                            <div className="pricing-form-voucher-alert-wrapper">
                                <Alert
                                    message="Voucher potentially required"
                                    description="Customer is potentially owed a voucher, but can't automatically determine value because the booking includes upgrades."
                                    type="info"
                                />
                            </div>
                        </BookingDetailItem>
                    )
                }

                {
                    newBookingPriceOutputData.canBeMadePrivate && newBookingPriceOutputData.isForcedPrivate === false && (
                        <div className="margin-top">
                            <Alert
                                message={`This booking can be made private for an additional ${formatCurrency(newBookingPriceOutputData.totalPriceForPrivateBookingWithVat, currencyCode)}`}
                            />
                        </div>
                    )
                }
                {
                    newBookingPriceOutputData.canBeMadePrivate && (
                        <div className="margin-top">
                            <BookingFormItem
                                label="Make booking private?"
                            >
                                <Switch
                                    size="small"
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={props.values.isForcedPrivate}
                                    onChange={(checked) => props.onChangeAsValue("isForcedPrivate", checked)}
                                />
                            </BookingFormItem>
                        </div>
                    )
                }
            </>
        )
    } else {
        return (
            <BookingFormItem>
                <Alert
                    message="Please fill in required booking details first"
                    description="Pricing will appear once done"
                />
            </BookingFormItem>
        )
    }
}

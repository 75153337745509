/* eslint-disable object-shorthand */
import React from 'react'
import { Provider } from 'react-redux'
import reduxStore from 'store/reduxStore'
import { ConfigProvider } from 'antd'
import {
    colorSecondary,
    colorInfo,
    colorSuccess,
    colorWarning,
    defaultFontFamily,
    defaultBackgroundColor,
    defaultBorderRadius,
    defaultBorderColor,
    colorPrimaryLight,
    colorAlert,
} from 'styles/theme'
import AppWithState from './AppWithState'

// Customizing ant design theme: https://ant.design/docs/react/customize-theme

function AppWithProviders() {
    return (
        <Provider store={reduxStore}>
            <ConfigProvider
                theme={{
                    token: {
                        colorBgBase: defaultBackgroundColor,
                        // colorText: defaultFontColor,

                        colorPrimary: colorSecondary,
                        borderRadius: defaultBorderRadius,
                        colorBorder: defaultBorderColor,
                        colorInfo: colorInfo,
                        colorSuccess: colorSuccess,
                        colorWarning: colorWarning,
                        colorError: colorAlert,

                        fontFamily: defaultFontFamily,

                        colorLink: colorSecondary,
                    },
                    components: {
                        Menu: {
                            itemActiveBg: colorPrimaryLight,
                            itemHoverBg: 'transparent',
                            itemHoverColor: colorSecondary,
                            itemSelectedBg: colorPrimaryLight,
                        },
                        Table: {
                            rowHoverBg: colorPrimaryLight,
                        },
                        DatePicker: {
                            cellHoverBg: colorPrimaryLight,
                            controlItemBgActive: colorPrimaryLight,
                        },
                        Select: {
                            optionActiveBg: colorPrimaryLight,
                            optionSelectedBg: colorPrimaryLight,
                        },
                    },
                }}
            >
                <AppWithState />
            </ConfigProvider>
        </Provider>
    )
}

export default AppWithProviders

import * as Sentry from '@sentry/react'

export const scopeSentryUser = (user) => {
    const {
        id,
        email,
        username,
    } = user
    Sentry.configureScope((scope) => {
        scope.setUser({ id, email, username })
    })
}

export const removeScopedSentryUser = () => {
    Sentry.configureScope((scope) => {
        scope.setUser({})
    })
}

export default function initializeSentry() {
    const {
        REACT_APP_ENV,
        REACT_APP_SENTRY_DSN,
    } = process.env

    if (REACT_APP_ENV !== 'development') {
        Sentry.init({
            dsn: REACT_APP_SENTRY_DSN,
            environment: REACT_APP_ENV,
            traceSampleRate: 1.0,
        })
    }
}

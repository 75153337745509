import React from 'react'
import { fetchCouponsExtendCurrentParams, createCouponTimeFrame } from 'store/actions/coupons'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    createCouponTimeFrame: (values) => dispatch(createCouponTimeFrame(ownProps.coupon.id, values)),
    createCouponTimeFrameSuccess: () => {
        notification.success({
            message: 'Timeframe added!',
            description: (
                <span>
                    The timeframe was successfully added to the coupon!
                </span>
            ),
        })
    },
    createCouponTimeFrameFailed: ({ message }) => {
        notification.error({
            message: 'Something went wrong',
            description: message,
        })
    },
    fetchCoupons: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchCouponsExtendCurrentParams(paginationKey))
    },
})

const CreateCouponTimeFrameRequest = withReduxState(null, mapDispatchToProps)

export default CreateCouponTimeFrameRequest

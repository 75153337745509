import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
    AppstoreOutlined,
    CalculatorOutlined,
    HomeOutlined,
} from '@ant-design/icons'
import { Menu } from 'antd'
import './VenueSettingsSideBarMenu.css'

// const { ItemGroup } = Menu

function VenueSettingsSideBarMenu({ activeMenuItem }) {
    const items = useMemo(() => {
        // https://ant.design/components/menu/#MenuItemGroupType
        const menuItemsGroup = [{
            type: 'group',
            label: (<h3>Venue Settings</h3>),
            key: 'selectedVenue',
            children: [
                {
                    label: (
                        <Link to="/settings/rooms">
                            <HomeOutlined />
                            {' '}
                            Rooms
                        </Link>
                    ),
                    key: 'rooms',
                },
                {
                    label: (
                        <Link to="/settings/venue-games">
                            <CalculatorOutlined />
                            {' '}
                            Games
                        </Link>
                    ),
                    key: 'venueGames',
                },
                {
                    label: (
                        <Link to="/settings/time-slots">
                            <AppstoreOutlined />
                            {' '}
                            Time slots
                        </Link>
                    ),
                    key: 'timeSlots',
                },

                // KEPT THESE HERE IF WE EVER NEED THEM AGAIN
                // {
                //     label: (
                //         <Link to="/settings/equipment-sets">
                //             <SkinOutlined />
                //             {' '}
                //             Equipment sets
                //         </Link>
                //     ),
                //     key: 'equipmentSets',
                // },
                // {
                //     label: (
                //         <Link to="/settings/discount-banners">
                //             <EuroOutlined />
                //             {' '}
                //             Discount banner
                //         </Link>
                //     ),
                //     key: 'discountBanners',
                // },
            ],
        }]

        return menuItemsGroup
    }, [])

    return (
        <div className="settingsSideBarMenu">
            <Menu
                mode="vertical"
                selectedKeys={[activeMenuItem]}
                style={{ borderRight: 0 }}
                items={items}
            />
        </div>
    )
}

export default VenueSettingsSideBarMenu

import React from 'react'
import DeleteGiftCardFormState from 'components/pages/settings/gift-cards/forms/delete/DeleteGiftCardFormState'
import { DeleteModal } from 'components/util/modals/DeleteModal'

function DeleteGiftCardModal({ isOpen, entity, onClose }) {
    return (
        <DeleteGiftCardFormState
            onDeleteSuccess={onClose}
            onCancel={onClose}
            values={entity}
        >
            {({
                isLoading,
                handleSubmit,
                handleCancel,
            }) => (
                <DeleteModal
                    title="Delete this gift card"
                    isOpen={isOpen}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    entityName={entity && entity.couponCode}
                    isLoading={isLoading}
                    closable={!isLoading}
                    maskClosable={!isLoading}
                />
            )}
        </DeleteGiftCardFormState>
    )
}

export default DeleteGiftCardModal

import createPaginationReducer from '../hor/createPaginationReducer'
import {
    VOUCHER_ISSUE_REASONS_REQUEST,
    VOUCHER_ISSUE_REASONS_SUCCESS, VOUCHER_ISSUE_REASONS_FAILURE,
} from '../../actions/voucherIssueReasons'

export const voucherIssueReasonsPaginationReducer = createPaginationReducer({
    mapActionToKey: (action) => action.viewName,
    types: [
        VOUCHER_ISSUE_REASONS_REQUEST,
        VOUCHER_ISSUE_REASONS_SUCCESS,
        VOUCHER_ISSUE_REASONS_FAILURE,
    ],
})

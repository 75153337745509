import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import { getEntityFromResponse } from 'util/index'
import { mapCouponToValues, toCouponApiRequest } from 'util/transformers/coupon'
import { getCouponErrors, isValidCoupon } from 'util/validation/coupons'
import EditCouponRequest from './EditCouponRequest'

function EditCouponModalFormState({
    onCancel,
    onEditSuccess = () => { },
    children,
    coupon,
}) {
    if (coupon === null || typeof coupon === 'undefined') {
        return null
    }

    return (
        <EditCouponRequest paginationKey="overview">
            {({
                editCouponFailed,
                fetchCoupons,
                editCouponSuccess,
                editCoupon,
            }) => (

                <WithFormState
                    key={coupon && coupon.id}
                    initialValues={{
                        ...mapCouponToValues(coupon),
                    }}
                    isValidForm={isValidCoupon}
                    getFormErrors={getCouponErrors}
                    transformValuesToRequestBody={toCouponApiRequest}
                    onSendRequest={(values) => editCoupon(coupon.id, values)}
                    onCancel={onCancel}
                    onRequestSuccess={(response) => {
                        const responseCoupon = getEntityFromResponse(response, 'coupons')
                        fetchCoupons()
                        editCouponSuccess(responseCoupon)
                        onEditSuccess(responseCoupon)
                    }}
                    onRequestFailed={editCouponFailed}
                >
                    {children}
                </WithFormState>
            )}
        </EditCouponRequest>
    )
}

export default EditCouponModalFormState

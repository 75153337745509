import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPagination, getEntitiesByPagination } from 'store/util/index'
import { fetchLegalEntities } from 'store/actions/legalEntities'
import {LegalEntitiesPage} from "./LegalEntitiesPage"

const PAGE_SIZE = 15

function LegalEntitiesPageWithState() {
    const dispatch = useDispatch()
    const { isFetching } = useSelector((state) => getPagination(state, 'legalEntities', 'overview'))
    const legalEntities = useSelector((state) => getEntitiesByPagination(state, 'legalEntities', 'overview'))

    const fetchData = useCallback(() => {
        dispatch(fetchLegalEntities('overview', {
            page: 1,
            limit: PAGE_SIZE,
        }))
    }, [dispatch])

    return (
        <LegalEntitiesPage
            isFetching={isFetching}
            legalEntities={legalEntities}
            reFetch={fetchData}
        />
    )
}

export default LegalEntitiesPageWithState

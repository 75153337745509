import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import { MODAL_IDS } from 'util/constants'

function EditDiscountBannerModalState({ discountBanner, children }) {
    return (
        <WithActiveModal
            modalId={MODAL_IDS.EDIT_DISCOUNT_BANNER}
            entity={discountBanner}
            entityKey="discountBanner"
        >
            {children}
        </WithActiveModal>
    )
}

export default EditDiscountBannerModalState

import React from 'react'
import Me from 'components/pages/authorized/WithMe'
import EditRoomFormWithState from 'components/pages/settings/rooms/forms/edit/EditRoomFormWithState'
import './RoomOverview.css'

function RoomOverview({
    room,
    games,
    equipmentSets,
    assignedSets,
    onChangeEquipmentSet,
}) {
    return (
        <Me>
            {({ isReadOnly }) => (
                <div className="room-overview-container">
                    <EditRoomFormWithState
                        room={room}
                        games={games}
                        equipmentSets={equipmentSets}
                        disabled={isReadOnly}
                        assignedSets={assignedSets}
                        onChangeEquipmentSet={onChangeEquipmentSet}
                    />
                </div>
            )}
        </Me>
    )
}

export default RoomOverview

import createEntityReducer from '../hor/createEntityReducer'
import {
    VENUE_GAMES_REQUEST,
    VENUE_GAMES_SUCCESS,
    VENUE_GAMES_FAILURE,
} from '../../actions/venueGames'

const venueGamesReducer = createEntityReducer({
    types: [
        VENUE_GAMES_REQUEST,
        VENUE_GAMES_SUCCESS,
        VENUE_GAMES_FAILURE,
    ],
})

export default venueGamesReducer

/* eslint-disable import/prefer-default-export */

export function negativeOrPositiveWithCurrency(value, currency) {
    let convertedValue
    let sign
    if (value === "-" || value === "") {
        convertedValue = ""
        sign = value
    } else {
        sign = value < 0 ? "-" : ""
        convertedValue = !Number.isNaN(value) ? Math.abs(value) : ""
    }
    return `${sign}${currency}${convertedValue}`
}

/**
 * @param {number} value
 * @param {string} currencyCode
 * @param {string} locale
 * @returns {number|string}
 */
export function formatCurrency(value, currencyCode, locale = "en-US") {
    if (typeof value !== "number")
        return ""

    try {
        const formattedPrice = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: currencyCode,
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
            useGrouping: false,
        }).format(value)

        return formattedPrice
    } catch (error) {
        console.error("Error formatting price:", error)
        return value
    }
}

/**
* Returns the currency display name for locale.
* @param {string} currencyCode
* @param {string} locale
* @returns string
*/
export function getCurrencyDisplayName(currencyCode, locale = "en") {
    try {
        const displayNames = new Intl.DisplayNames([locale], { type: "currency" })
        return displayNames.of(currencyCode)
    } catch (error) {
        console.error("Error getting currency display name:", error)
        return ""
    }
}

/**
     * Returns the currency symbol for locale.
     * https://stackoverflow.com/questions/50650503/get-the-currency-symbol-for-a-locale#answer-53749034
     * @param {string} localeCode - The locale code.
     * @param {string} currencyCode - The currency code.
     * @return string
     */
export function getLocaleCurrencySymbol(currencyCode, localeCode) {
    return (0).toLocaleString(
        localeCode,
        {
            style: "currency",
            currency: currencyCode,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        },
    )
        .replace(/\d/g, "")
        .trim()
}

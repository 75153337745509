import React from 'react'
import { useLegalEntity } from 'components/hooks/useLegalEntity'
import CreateVenueGameModal from './CreateVenueGameModal'
import CreateVenueGameModalState from './CreateVenueGameModalState'
import CreateVenueGameFormState from '../../forms/create/CreateVenueGameFormState'

function CreateVenueGameModalWithState({ onCreateSuccess }) {
    const legalEntity = useLegalEntity()
    if (typeof legalEntity === 'undefined')
        throw new Error(`Legal Entity is undefined! Comp: ${CreateVenueGameModalWithState.name}`)

    return (
        <CreateVenueGameModalState>
            {({ isOpen, closeModal }) => (
                <CreateVenueGameFormState
                    onCancel={closeModal}
                    onCreateSuccess={(venueGame) => {
                        closeModal()
                        onCreateSuccess(venueGame)
                    }}
                >
                    {({
                        values,
                        errors,
                        isLoading,
                        mergeValues,
                        handleChange,
                        handleChangeAsValue,
                        handleCancel,
                        handleSubmit,
                    }) => {
                        return (
                            <CreateVenueGameModal
                                isOpen={isOpen}
                                onClose={closeModal}
                                values={values}
                                errors={errors}
                                isLoading={isLoading}
                                onChange={handleChange}
                                onChangeAsValue={handleChangeAsValue}
                                onCancel={handleCancel}
                                onSubmit={handleSubmit}
                                mergeValues={mergeValues}
                                legalEntity={legalEntity}
                            />
                        )
                    }}
                </CreateVenueGameFormState>
            )}
        </CreateVenueGameModalState>
    )
}
export default CreateVenueGameModalWithState

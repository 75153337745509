import React from 'react'
import {
    Form, Input, InputNumber, Row, Col, Button,
} from 'antd'
import FormItem from 'components/util/forms/FormItem'
import BookableHoursInput from '../form-items/BookableHoursInput'
import MultiGamesSelect from '../form-items/MultiGamesSelect'
// import EquipmentSetsPositions from '../form-items/equipment-sets/EquipmentSetsPositions'

const labelCol = { span: 24 }
const wrapperCol = { span: 24 }

function EditRoomForm({
    room,
    games,
    equipmentSets,
    isLoading,
    values,
    errors,
    disabled,
    onChange,
    onChangeAsValue,
    onSubmit,
    assignedSets,
    onChangeEquipmentSet,
}) {
    return (
        <Form className="margin-bottom">
            <Row gutter={24}>
                <Col lg={12}>
                    <h3>General info</h3>
                    <FormItem
                        label="Name"
                        error={errors.name}
                        required
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                    >
                        <Input
                            name="name"
                            value={values.name}
                            disabled={disabled}
                            onChange={onChange}
                        />
                    </FormItem>
                    <FormItem
                        label="Seats"
                        error={errors.seats}
                        required
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                    >
                        <InputNumber
                            className="full-width"
                            name="seats"
                            value={values.seats}
                            disabled={disabled}
                            onChange={(value) => {
                            // Check prevents rendering too many equipment set slots
                                const seats = value <= 12 ? value : 12
                                onChangeAsValue('seats', seats)
                            }}
                            min={1}
                            max={12}
                        />
                    </FormItem>
                    {/* <FormItem */}
                    {/*     label="Game server name" */}
                    {/*     error={errors.gameServerName} */}
                    {/*     labelCol={labelCol} */}
                    {/*     wrapperCol={wrapperCol} */}
                    {/* > */}
                    {/*     <Input */}
                    {/*         name="gameServerName" */}
                    {/*         value={values.gameServerName} */}
                    {/*         disabled={disabled} */}
                    {/*         onChange={onChange} */}
                    {/*     /> */}
                    {/* </FormItem> */}
                    {/* <FormItem */}
                    {/*     label="Room config folder" */}
                    {/*     error={errors.roomConfigFolder} */}
                    {/*     labelCol={labelCol} */}
                    {/*     wrapperCol={wrapperCol} */}
                    {/* > */}
                    {/*     <Input */}
                    {/*         name="roomConfigFolder" */}
                    {/*         value={values.roomConfigFolder} */}
                    {/*         placeholder="e.g. https://theparkplayground.com/" */}
                    {/*         disabled={disabled} */}
                    {/*         onChange={onChange} */}
                    {/*     /> */}
                    {/* </FormItem> */}
                    <FormItem
                        label="Games"
                        error={errors.games}
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                    >
                        <MultiGamesSelect
                            games={games}
                            value={values.games}
                            disabled={disabled}
                            onChange={(value) => onChangeAsValue('games', value)}
                        />
                    </FormItem>
                    <h3>Bookable hours</h3>
                    <BookableHoursInput
                        values={values}
                        disabled={disabled}
                        onChangeAsValue={onChangeAsValue}
                    />
                    <br />
                    <Button
                        type="primary"
                        className="fullWidth"
                        onClick={onSubmit}
                        disabled={disabled}
                        loading={isLoading}
                    >
                        Save
                    </Button>
                </Col>
                {/* <Col lg={12}> */}
                {/* <h3>Equipment sets</h3> */}
                {/* <EquipmentSetsPositions */}
                {/*     seats={values.seats} */}
                {/*     roomEquipmentSets={values.equipmentSets} */}
                {/*     equipmentSets={equipmentSets} */}
                {/*     room={room} */}
                {/*     assignedSets={assignedSets} */}
                {/*     disabled={disabled} */}
                {/*     onChange={(value) => onChangeAsValue('equipmentSets', value)} */}
                {/*     onChangeEquipmentSet={onChangeEquipmentSet} */}
                {/* /> */}
                {/* </Col> */}
            </Row>
        </Form>
    )
}

export default EditRoomForm

import React from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { firstBy } from 'thenby'
import { capitalize, whereId } from 'util/index'
import { WEEK_DAYS, APP_TIME_FORMAT } from 'util/constants'
import WithMe from 'components/pages/authorized/WithMe'
import DeleteCouponTimeFrameModalState from 'components/pages/settings/coupons/modals/delete-time-frame/DeleteCouponTimeFrameModalState'
import EditCouponTimeFrameModalState from 'components/pages/settings/coupons/modals/edit-time-frame/EditCouponTimeFrameModalState'
import dayjs from 'dayjs'
import CouponDetailItem from './CouponDetailItem'
import './CouponTimeFramesList.css'

const sortedByDay = (timeFrames) => (
    typeof timeFrames !== 'undefined'
        ? timeFrames.sort(firstBy('day').thenBy('from').thenBy('until'))
        : []
)

const getMappedDataSource = (coupon) => {
    const { couponTimeFrames, id } = coupon
    return sortedByDay(couponTimeFrames)
        .map((sortedTimeFrame) => ({
            ...sortedTimeFrame,
            couponId: id,
        }))
}

function CouponTimeFramesList({
    coupon,
}) {
    const timeFrames = getMappedDataSource(coupon)
    return (
        <ul>
            {timeFrames.map((timeFrame) => {
                const label = `${capitalize(WEEK_DAYS.find(whereId(timeFrame.day)).name)}:`
                const from = dayjs.utc(timeFrame.from, 'HH:mm').format(APP_TIME_FORMAT)
                const until = dayjs.utc(timeFrame.until, 'HH:mm').format(APP_TIME_FORMAT)
                const itemText = `${from} - ${until}`
                return (
                    <CouponDetailItem
                        key={timeFrame.id}
                        label={label}
                    >
                        <div className="detailItem">
                            {itemText}
                            <div className="detailActions">
                                <WithMe>
                                    {({ isAdmin, isLegalEntityManager }) => (
                                        <>
                                            <EditCouponTimeFrameModalState timeFrame={timeFrame}>
                                                {({ openModal }) => (
                                                    <Button
                                                        ghost
                                                        icon={<EditOutlined />}
                                                        type="primary"
                                                        shape="circle"
                                                        size="small"
                                                        className="ant-btn-no-border"
                                                        disabled={!isAdmin && !isLegalEntityManager}
                                                        onClick={openModal}
                                                    />
                                                )}
                                            </EditCouponTimeFrameModalState>
                                            <DeleteCouponTimeFrameModalState timeFrame={timeFrame}>
                                                {({ openModal }) => (
                                                    <Button
                                                        ghost
                                                        icon={<DeleteOutlined />}
                                                        type="danger"
                                                        shape="circle"
                                                        size="small"
                                                        className="ant-btn-no-border"
                                                        disabled={!isAdmin && !isLegalEntityManager}
                                                        onClick={openModal}
                                                    />
                                                )}
                                            </DeleteCouponTimeFrameModalState>
                                        </>
                                    )}
                                </WithMe>
                            </div>
                        </div>
                    </CouponDetailItem>
                )
            })}
        </ul>
    )
}

export default CouponTimeFramesList

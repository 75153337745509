import {
    SET_ACTIVE_VENUE,
    SET_ACTIVE_ROOM,
    SET_ACTIVE_LEGAL_ENTITY,
} from '../actions/filters'

export const defaultFilterState = {
    venueId: null,
    roomId: null,
    legalEntityId: null,
}

// eslint-disable-next-line default-param-last
const activeFiltersReducer = (state = defaultFilterState, action) => {
    const {
        type, venueId, roomId, legalEntityId,
    } = action
    switch (type) {
        case SET_ACTIVE_VENUE:
            return {
                ...state,
                venueId,
                roomId: null,
            }
        case SET_ACTIVE_ROOM:
            return {
                ...state,
                roomId,
            }
        case SET_ACTIVE_LEGAL_ENTITY:
            return {
                ...state,
                legalEntityId,
            }
        default:
            return state
    }
}

export default activeFiltersReducer

import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import { MODAL_IDS } from 'util/constants'
import {AuditBookingModal} from "./AuditBookingModal"

/**
 * @component
 */
export function AuditBookingModalWithRedux() {
    return (
        <WithActiveModal modalId={MODAL_IDS.AUDIT_BOOKING}>
            {({
                isOpen,
                entity,
                activeVenueId,
                closeModal,
            }) => (
                <AuditBookingModal
                    activeVenueId={activeVenueId}
                    isOpen={isOpen}
                    onClose={closeModal}
                    entity={entity}
                />
            )}
        </WithActiveModal>
    )
}

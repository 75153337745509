import React from 'react'
import { Form } from 'antd'

function FormItem({
    children,
    label,
    error,
    extra,
    required,
    style = {},
    labelCol = {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol = {
        xs: { span: 24 },
        sm: { span: 16 },
    },
    tooltip,
}) {
    return (
        <Form.Item
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            label={label}
            hasFeedback
            validateStatus={error ? 'error' : ''}
            help={error}
            extra={extra}
            required={required}
            style={style}
            tooltip={tooltip}
        >
            {children}
        </Form.Item>
    )
}

export default FormItem

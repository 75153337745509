import fetch from 'isomorphic-fetch'
import { normalize } from 'normalizr'
import { camelizeKeys, decamelizeKeys, decamelize } from 'humps'
import { delay } from 'util/index'
import * as storage from 'util/storage'
import { jwtDecode } from 'jwt-decode'

export const METHODS = {
    POST: 'POST',
    GET: 'GET',
    PATCH: 'PATCH',
    PUT: 'PUT',
    DELETE: 'DELETE',
}

export function createParamsString(params) {
    const keys = Object.keys(params)
    const urlSafeParams = keys.map((key) => `${encodeURIComponent(decamelize(key))}=${encodeURIComponent(params[key])}`)
    return urlSafeParams.join('&')
}

const credentials = 'same-origin'
const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
}

export function ignoreUppercase(key, convert) {
    const regex = /^[A-Z0-9_]+$/
    const match = regex.test(key)
    if (match) return key
    return convert(key)
}

export function transformRequestBody(body) {
    const decamelizedBody = decamelizeKeys(body, ignoreUppercase)
    return JSON.stringify(decamelizedBody)
}

export function transformResponseBody(json, schema) {
    const decamelizedBody = camelizeKeys(json, ignoreUppercase)
    if (typeof schema === 'undefined')
        return decamelizedBody

    const { data, meta } = decamelizedBody
    const normalizedData = normalize(data, schema)
    return {
        ...normalizedData,
        meta,
    }
}

export function transformResponseErrorBody(json) {
    return camelizeKeys(json, ignoreUppercase)
}

export function isTokenExpired(token) {
    const decodedToken = jwtDecode(token)
    const tokenExpiresAt = decodedToken.exp * 1000
    return tokenExpiresAt < new Date().getTime()
}

export async function loadAndValidateTokens() {
    let accessToken = await storage.getAccessToken()
    let refreshToken = await storage.getRefreshToken()
    const tokensAreSet = accessToken !== null && refreshToken !== null

    if (tokensAreSet) {
        if (isTokenExpired(refreshToken)) {
            await storage.removeAccessToken()
            await storage.removeRefreshToken()
            accessToken = null
            refreshToken = null
        } else if (isTokenExpired(accessToken)) {
            try {
                const {
                    accessToken: newAccessToken,
                    refreshToken: newRefreshToken,
                    // eslint-disable-next-line no-use-before-define
                } = await callApi(
                    METHODS.POST,
                    '/refresh',
                    undefined,
                    undefined,
                    { refreshToken },
                    undefined,
                    true,
                )
                if (newAccessToken !== null) {
                    await storage.setAccessToken(newAccessToken)
                    await storage.setAccessToken(newRefreshToken)
                }
            } catch (error) {
                return {
                    refreshToken: null,
                    accessToken: null,
                }
            }
        }
    }
    return {
        refreshToken,
        accessToken,
    }
}

export default async function callApi(
    method,
    endpoint,
    params,
    schema,
    body,
    minRequestTime = 0,
    isInternal = true,
    useJson = true,
) {
    const accessToken = await storage.getAccessToken()
    if (accessToken !== null)
        headers.authorization = `Bearer ${accessToken}`

    const transformedBody = transformRequestBody(body)
    const options = {
        method,
        headers,
        credentials,
        body: transformedBody,
    }
    const API_ROOT = `${process.env.REACT_APP_API}/api`
    let fullUrl = `${API_ROOT}${isInternal ? '/internal' : '/v1'}${endpoint}`

    if (params !== null && typeof params !== 'undefined')
        fullUrl = `${fullUrl}?${createParamsString(params)}`

    const response = await fetch(fullUrl, options)
    await delay(minRequestTime)

    // Unauthorized
    if (response.status === 401) {
        const { refreshToken } = await loadAndValidateTokens()

        if (refreshToken === null)
            return Promise.reject(response)

        return callApi(
            method,
            endpoint,
            params,
            schema,
            body,
            minRequestTime,
            isInternal,
        )
    }
    if (response.status === 204) { // No response body
        if (!response.ok)
            return Promise.reject(response)

        return Promise.resolve(response)
    }

    if (useJson) {
        const json = await response.json()
        if (!response.ok)
            return Promise.reject(transformResponseErrorBody(json))

        return transformResponseBody(json, schema)
    }

    if (!response.ok)
        return Promise.reject(response)

    return response
}

export const callApiWithOptions = (
    endpoint,
    {
        method = METHODS.GET,
        params,
        schema,
        body,
        minRequestTime,
        isInternal,
        useJson,
    } = {},
) => (
    callApi(
        method,
        endpoint,
        params,
        schema,
        body,
        minRequestTime,
        isInternal,
        useJson,
    )
)

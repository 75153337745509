import { CALL_API, METHODS } from '../middleware/api'
import { giftCardSchema, giftCardSchemaArray } from '../schemas/giftCards'

export const GIFT_CARDS_REQUEST = 'GIFT_CARDS_REQUEST'
export const GIFT_CARDS_SUCCESS = 'GIFT_CARDS_SUCCESS'
export const GIFT_CARDS_FAILURE = 'GIFT_CARDS_FAILURE'

export const GIFT_CARD_REQUEST = 'GIFT_CARD_REQUEST'
export const GIFT_CARD_SUCCESS = 'GIFT_CARD_SUCCESS'
export const GIFT_CARD_FAILURE = 'GIFT_CARD_FAILURE'

export const fetchGiftCards = (viewName, params) => ({
    viewName,
    [CALL_API]: {
        types: [
            GIFT_CARDS_REQUEST,
            GIFT_CARDS_SUCCESS,
            GIFT_CARDS_FAILURE,
        ],
        endpoint: '/gift-cards',
        schema: giftCardSchemaArray,
        params,
    },
})

export const fetchGiftCardsExtendCurrentParams = (paginationKey, params) => (dispatch, getState) => {
    const giftCardPagination = getState().pagination.giftCards[paginationKey]
    const newParams = {
        ...giftCardPagination.params,
        ...params,
    }
    return dispatch(fetchGiftCards(paginationKey, newParams))
}

export const createGiftCard = (body) => ({
    [CALL_API]: {
        method: METHODS.POST,
        types: [
            GIFT_CARD_REQUEST,
            GIFT_CARD_SUCCESS,
            GIFT_CARD_FAILURE,
        ],
        endpoint: '/gift-cards',
        schema: giftCardSchema,
        body,
    },
})

export const deleteGiftCard = (giftCardId) => ({
    [CALL_API]: {
        method: METHODS.DELETE,
        types: [
            GIFT_CARD_REQUEST,
            GIFT_CARD_SUCCESS,
            GIFT_CARD_FAILURE,
        ],
        endpoint: `/gift-cards/${giftCardId}`,
        schema: giftCardSchema,
        entityId: giftCardId,
    },
})

export const editGiftCard = (giftCardId, body) => ({
    [CALL_API]: {
        method: METHODS.PUT,
        types: [
            GIFT_CARD_REQUEST,
            GIFT_CARD_SUCCESS,
            GIFT_CARD_FAILURE,
        ],
        endpoint: `/gift-cards/${giftCardId}`,
        schema: giftCardSchema,
        body,
    },
})

import { openModal, closeModal } from 'store/actions/modals'
import { useDispatch, useSelector } from 'react-redux'

/**
 * @typedef {Object} ReturnObject
 * @property {boolean} isOpen Should the modal be open?
 * @property {() => void} openModal Opens the modal
 * @property {(newEntity: Object) => void} openModalWithEntity Opens the modal with a model reference
 * @property {() => void} closeModal Closes the modal
 * @property {Object | undefined} entity The model. E.g. a venue, legal entity, coupon,...
 */

/**
 * @param {string} modalId
 * @param {Object=} options
 * @param {Object} options.entity Entity object
 * @param {string} options.entityKey Store key
 * @returns {ReturnObject}
 */
export default function useModal(modalId, options) {
    const dispatch = useDispatch()
    const activeModal = useSelector((state) => state.activeModal)
    const isOpen = activeModal.activeModalId === modalId

    let entity = null
    let entityKey = 'entity'
    if (typeof options !== 'undefined') {
        if (typeof options.entityKey === 'string' && options.entityKey.length > 0) {
            entityKey = options.entityKey
        }
        if (typeof options.entity !== 'undefined' && options.entity !== null) {
            entity = options.entity
        }
    }

    return {
        isOpen,
        openModal: () => dispatch(openModal(modalId, entity)),
        openModalWithEntity: (newEntity) => dispatch(openModal(modalId, newEntity)),
        closeModal: () => dispatch(closeModal()),
        [entityKey]: activeModal.entity,
    }
}

import React from 'react'
import { Modal } from 'antd'
import { ModalFooter } from 'components/util/modals/ModalFooter'
import CreateCouponBatchForm from '../../forms/create/CreateCouponBatchForm'
import CreateCouponBatchFormState from '../../forms/create/CreateCouponBatchFormState'

function CreateCouponBatchModal({
    isOpen,
    onClose,
    activeLegalEntity,
    legalEntities,
}) {
    return (
        <CreateCouponBatchFormState
            onSaveSuccess={onClose}
            onCancel={onClose}
        >
            {({
                values,
                errors,
                isLoading,
                isSubmitted,
                handleChange,
                handleSubmit,
                handleCancel,
                handleChangeAsValue,
            }) => (
                <Modal
                    title="Create new coupon batch"
                    open={isOpen}
                    onCancel={handleCancel}
                    onOk={handleSubmit}
                    closable={!isLoading}
                    maskClosable={!isLoading}
                    footer={(
                        <ModalFooter
                            isLoading={isLoading}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                        />
                    )}
                >
                    {isOpen && (
                        <CreateCouponBatchForm
                            isLoading={isLoading}
                            isSubmitted={isSubmitted}
                            values={values}
                            errors={errors}
                            onChange={handleChange}
                            onChangeAsValue={handleChangeAsValue}
                            legalEntity={legalEntities[values.legalEntity] || activeLegalEntity}
                        />
                    )}
                </Modal>
            )}
        </CreateCouponBatchFormState>
    )
}

export default CreateCouponBatchModal

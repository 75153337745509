import React from 'react'
import Venues from 'components/pages/settings/users/Venues'
import Mount from 'components/util/Mount'
import { Select } from 'antd'

const PAGE_SIZE = 15
const { Option } = Select

function VenuesSelectWithRedux({
    value,
    placeholder = 'Please select',
    onChange,
}) {
    return (
        <Venues paginationKey="overview" limit={PAGE_SIZE}>
            {({ isFetching, venuesForCurrentPage, fetchVenues }) => (
                <Mount
                    didMount={() => {
                        fetchVenues()
                    }}
                >
                    <Select
                        mode="multiple"
                        disabled={isFetching}
                        onChange={onChange}
                        value={isFetching
                            ? 'Loading venues...'
                            : value}
                        placeholder={isFetching
                            ? 'Loading venues...'
                            : placeholder}
                        filterOption={(input, option) => (
                            option.props.children.toLowerCase().includes(input.toLowerCase())
                        )}
                    >
                        {
                            venuesForCurrentPage.map((venue) => (
                                <Option
                                    key={venue.id}
                                    value={venue.id}
                                >
                                    {venue.name}
                                </Option>
                            ))
                        }
                    </Select>
                </Mount>
            )}
        </Venues>
    )
}

export default VenuesSelectWithRedux

import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import { toEditGiftCardApiRequest } from 'util/transformers/giftCard'
import { isValidEditForm } from 'util/validation/giftCard'
import { getEditGiftCardFormErrors } from 'util/validation/giftCardErrors'
import { getEntityFromResponse } from 'util/index'
import EditGiftCardRequest from './EditGiftCardRequest'

function EditCardFormState({
    giftCard,
    onCancel,
    onEditSuccess,
    children,
}) {
    return (
        <EditGiftCardRequest paginationKey="overview" giftCard={giftCard}>
            {({
                fetchGiftCards,
                editGiftCard,
                editGiftCardSuccess,
                editGiftCardFailed,
            }) => {
                if (giftCard === null || typeof giftCard === 'undefined') {
                    return null
                }
                return (
                    <WithFormState
                        key={giftCard.id}
                        initialValues={{
                            firstName: giftCard.firstName,
                            lastName: giftCard.lastName,
                            email: giftCard.email,
                            resendEmail: false,
                            purchaseOrderNumber: typeof giftCard.order !== 'undefined'
                            && giftCard.order !== null
                            && giftCard.order.purchaseOrderNumber !== null
                                ? giftCard.order.purchaseOrderNumber : undefined,
                        }}
                        isValidForm={isValidEditForm}
                        getFormErrors={getEditGiftCardFormErrors}
                        transformValuesToRequestBody={toEditGiftCardApiRequest}
                        onSendRequest={(values) => editGiftCard(values)}
                        onCancel={onCancel}
                        onRequestSuccess={(response) => {
                            const updatedGiftCard = getEntityFromResponse(response, 'giftCards')
                            fetchGiftCards()
                            editGiftCardSuccess(updatedGiftCard)
                            onEditSuccess(updatedGiftCard)
                        }}
                        onRequestFailed={editGiftCardFailed}
                    >
                        {children}
                    </WithFormState>
                )
            }}
        </EditGiftCardRequest>
    )
}

export default EditCardFormState

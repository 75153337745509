import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import EditVenueGameRequest from 'components/pages/settings/venue-games/forms/edit/EditVenueGameRequest'
import { isValidEditVenueGame } from 'util/validation/venueGame'
import { getEditVenueGameErrors } from 'util/validation/venueGameErrors'
import { getEntityFromResponse } from 'util/index'
import { toEditVenueGameApiRequest, mapVenueGameToValues } from 'util/transformers/venueGame'
import { useLegalEntity } from 'components/hooks/useLegalEntity'

function EditVenueGameFormState({
    venueGame,
    paginationKey,
    children,
}) {
    return (
        <EditVenueGameRequest venueGame={venueGame} paginationKey={paginationKey}>
            {({
                fetchVenueGames,
                editVenueGame,
                editVenueGameSuccess,
                editVenueGameFailed,
            }) => (
                <WithFormState
                    key={venueGame.id}
                    initialValues={venueGame && mapVenueGameToValues(venueGame)}
                    isValidForm={(values) => isValidEditVenueGame(values)}
                    getFormErrors={getEditVenueGameErrors}
                    transformValuesToRequestBody={toEditVenueGameApiRequest}
                    onSendRequest={(values) => editVenueGame(values)}
                    onRequestSuccess={(response) => {
                        const updatedVenueGame = getEntityFromResponse(response, 'venueGames')
                        fetchVenueGames()
                        editVenueGameSuccess(updatedVenueGame)
                    }}
                    onRequestFailed={editVenueGameFailed}
                >
                    {children}
                </WithFormState>
            )}
        </EditVenueGameRequest>
    )
}

export default EditVenueGameFormState

import createEntityReducer from '../hor/createEntityReducer'
import {
    GAME_SESSION_REQUEST,
    GAME_SESSION_SUCCESS,
    GAME_SESSION_FAILURE,
} from '../../actions/gameSessions'

const gameSessionsReducer = createEntityReducer({
    types: [
        GAME_SESSION_REQUEST,
        GAME_SESSION_SUCCESS,
        GAME_SESSION_FAILURE,
    ],
})

export default gameSessionsReducer

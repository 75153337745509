import React, { useState } from 'react'
import {
    Form, Row, Col, Button, InputNumber, DatePicker, Checkbox,
} from 'antd'
import FormItem from 'components/util/forms/FormItem'
import { PriceInput } from 'components/util/forms/PriceInput'
import { BACKEND_DATE_TIME_FORMAT } from 'util/constants'
import dayjs from 'dayjs'
import { colorSecondary } from '../../../../../../styles/theme'

const labelCol = {
    span: 24,
}
const wrapperCol = {
    span: 24,
}

function parseDate(date) {
    if (typeof date === 'undefined' || date === null)
        return undefined
    else
        return dayjs(date)
}
function parseNumber(num) {
    if (typeof num === 'undefined' || num === null || Number.isNaN(num))
        return undefined
    else
        return num
}

function EditVenueGameForm({
    values,
    errors,
    disabled,
    onChangeAsValue,
    onSubmit,
    priceSystem,
}) {
    const globalGameMinAge = values.game.minAge
    const [minAgeOverwritten, setMinAgeOverwritten] = useState(values.minAge !== null)

    return (
        <Form>
            <h3>General info</h3>
            <Row>
                <Col lg={6}>
                    <FormItem
                        label="Player price"
                        error={errors.basePlayerPrice}
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                        required
                    >
                        <PriceInput
                            name="basePlayerPrice"
                            value={values.basePlayerPrice}
                            disabled={disabled}
                            onChange={(value) => onChangeAsValue('basePlayerPrice', value)}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FormItem
                        label="Min. number of players"
                        error={errors.minPlayers}
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                        required
                    >
                        <InputNumber
                            name="minPlayers"
                            value={values.minPlayers}
                            onChange={(value) => onChangeAsValue('minPlayers', value)}
                            min={0}
                            step={1}
                        />
                    </FormItem>
                </Col>
                <Col lg={6}>
                    <FormItem
                        label="Max. number of players"
                        error={errors.maxPlayers}
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                        required
                    >
                        <InputNumber
                            name="maxPlayers"
                            value={values.maxPlayers}
                            onChange={(value) => onChangeAsValue('maxPlayers', value)}
                            min={1}
                            step={1}
                        />
                    </FormItem>
                </Col>
            </Row>

            <Row>
                <Col lg={6}>
                    <FormItem
                        label="Available from"
                        error={errors.baseAvailableFrom}
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                    >
                        <DatePicker
                            name="base_available_from"
                            value={parseDate(values.baseAvailableFrom)}
                            onChange={(value) => {
                                if (typeof value === 'undefined' || value === null || value === '') {
                                    onChangeAsValue('baseAvailableFrom', null)
                                } else {
                                    const newDate = dayjs(value).startOf('day')
                                    const formatted = newDate.format(BACKEND_DATE_TIME_FORMAT)
                                    onChangeAsValue('baseAvailableFrom', formatted)
                                }
                            }}
                            min={1}
                            step={1}
                        />
                    </FormItem>
                </Col>
                <Col lg={6}>
                    <FormItem
                        label="Available until"
                        error={errors.baseAvailableTo}
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                    >
                        <DatePicker
                            name="base_available_to"
                            value={parseDate(values.baseAvailableTo)}
                            onChange={(value) => {
                                if (typeof value === 'undefined' || value === null || value === '') {
                                    onChangeAsValue('baseAvailableTo', null)
                                } else {
                                    const newDate = dayjs(value).endOf('day')
                                    const formatted = newDate.format(BACKEND_DATE_TIME_FORMAT)
                                    onChangeAsValue('baseAvailableTo', formatted)
                                }
                            }}
                            min={1}
                            step={1}
                        />
                    </FormItem>
                </Col>
            </Row>

            <Row>
                <Col lg={12}>
                    <Checkbox
                        name="overwrite_min_age"
                        checked={minAgeOverwritten}
                        onChange={(value) => {
                            const checked = value.target.checked
                            if (!checked)
                                onChangeAsValue('minAge', null)
                            setMinAgeOverwritten(checked)
                        }}
                    >
                        Overwrite the global required minimum age of <b style={{ color: colorSecondary }}>{globalGameMinAge}</b>?
                    </Checkbox>
                </Col>
            </Row>

            <Row>
                <Col lg={6}>
                    <FormItem
                        label="Minimum required age"
                        error={errors.minAge}
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}
                    >
                        <InputNumber
                            name="min_age"
                            value={parseNumber(values.minAge)}
                            onChange={(value) => {
                                // console.log('Value change:', value)
                                // let finalValue = value
                                // if (finalValue === 0)
                                //     finalValue = null
                                onChangeAsValue('minAge', value)
                            }}
                            defaultValue={null}
                            min={null}
                            step={1}
                            disabled={minAgeOverwritten === false}
                            required={minAgeOverwritten !== false}
                        />
                    </FormItem>
                </Col>
            </Row>

            <Button
                type="primary"
                disabled={disabled}
                onClick={onSubmit}
            >
                Save
            </Button>
        </Form>
    )
}

export default EditVenueGameForm

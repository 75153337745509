import React from 'react'
import { Modal, Tabs } from 'antd'
import { ModalFooter } from 'components/util/modals/ModalFooter'
import CreateDiscountBannerForm from 'components/pages/settings/discount-banners/forms/create/CreateDiscountBannerForm'
import {
    dutchFormHasErrors,
    englishFormHasErrors,
    frenchFormHasErrors,
} from 'util/validation/discountBanner'
import * as theme from 'styles/theme'
import { LANGUAGES } from 'util/constants'

function CreateDiscountBannerModal({
    isOpen,
    onClose,
    values,
    errors,
    isLoading,
    onChange,
    onChangeAsValue,
    onCancel,
    onSubmit,
    addBlock,
}) {
    return (
        <Modal
            title="Create new discount banner"
            open={isOpen}
            onOk={onSubmit}
            onCancel={onClose}
            closable={!isLoading}
            maskClosable={!isLoading}
            footer={(
                <ModalFooter
                    isLoading={isLoading}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                />
            )}
        >
            {isOpen && (
                <Tabs size="small">
                    <Tabs.TabPane
                        tab={(
                            <span
                                style={{
                                    textTransform: 'uppercase',
                                    color: dutchFormHasErrors(values, errors) && theme.colorAlert,
                                }}
                            >
                                {LANGUAGES.nl}
                            </span>
                        )}
                        key={LANGUAGES.nl}
                    >
                        <CreateDiscountBannerForm
                            language={LANGUAGES.nl}
                            isLoading={isLoading}
                            values={values}
                            errors={errors}
                            onChange={onChange}
                            onChangeAsValue={onChangeAsValue}
                            onCreateSucces={onClose}
                            addBlock={addBlock}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={(
                            <span
                                style={{
                                    textTransform: 'uppercase',
                                    color: englishFormHasErrors(values, errors) && theme.colorAlert,
                                }}
                            >
                                {LANGUAGES.en}
                            </span>
                        )}
                        key={LANGUAGES.en}
                    >
                        <CreateDiscountBannerForm
                            language={LANGUAGES.en}
                            isLoading={isLoading}
                            values={values}
                            errors={errors}
                            onChange={onChange}
                            onChangeAsValue={onChangeAsValue}
                            onCreateSucces={onClose}
                            addBlock={addBlock}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={(
                            <span
                                style={{
                                    textTransform: 'uppercase',
                                    color: frenchFormHasErrors(values, errors) && theme.colorAlert,
                                }}
                            >
                                {LANGUAGES.fr}
                            </span>
                        )}
                        key={LANGUAGES.fr}
                    >
                        <CreateDiscountBannerForm
                            language={LANGUAGES.fr}
                            isLoading={isLoading}
                            values={values}
                            errors={errors}
                            onChange={onChange}
                            onChangeAsValue={onChangeAsValue}
                            onCreateSucces={onClose}
                            addBlock={addBlock}
                        />
                    </Tabs.TabPane>
                </Tabs>
            )}
        </Modal>
    )
}

export default CreateDiscountBannerModal

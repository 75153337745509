import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import { MODAL_IDS } from 'util/constants'

/**
 * @component
 * @param {Object} props
 * @param {BookingResource} props.booking
 * @param {React.Element|React.Element[]|null} props.children
 * @returns {JSX.Element}
 */
export function OutstandingAmountModalState(props) {
    return (
        <WithActiveModal
            modalId={MODAL_IDS.OUTSTANDING_AMOUNT_MODAL}
            entity={props.booking}
            entityKey="booking"
        >
            {props.children}
        </WithActiveModal>
    )
}

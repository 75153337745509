import { openModal, closeModal } from 'store/actions/modals'
import withReduxState from 'components/hoc/withReduxState'

/**
 * @typedef {Object} WithActiveModalChildFunctionProps
 * @template T
 * @property {number=} activeVenueId
 * @property {boolean} isOpen
 * @property {T} entity
 * @property {() => void} openModal
 * @property {(entity: T) => void} openModalWithEntity
 * @property {() => void} closeModal
 */

/**
 * @param state
 * @param ownProps
 * @returns {{isOpen: boolean, activeVenueId}}
 */
function mapStateToProps(state, ownProps){
    return {
        activeVenueId: state.activeFilters.venueId,
        isOpen: state.activeModal.activeModalId === ownProps.modalId,
        [ownProps.entityKey || 'entity']: state.activeModal.entity,
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        openModal: () => {
            dispatch(openModal(ownProps.modalId, ownProps.entity))
        },
        openModalWithEntity: (entity) => {
            dispatch(openModal(ownProps.modalId, entity))
        },
        closeModal: () => {
            dispatch(closeModal())
        },
    }
}

/**
 * @component
 * @param {Object} props
 * @param {string} props.modalId
 * @param {string=} props.entityKey
 * @param {(childProps: WithActiveModalChildFunctionProps<any>) => JSX.Element} props.children
 * @returns {JSX.Element}
 */
export const WithActiveModal = withReduxState(mapStateToProps, mapDispatchToProps)

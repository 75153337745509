import React from 'react'
import {
    Button,
    Radio,
    Input,
    DatePicker,
} from 'antd'
import RoomsSelect from './RoomsSelect'
import SearchBooking from './SearchBooking'
import './GameSessionsTableTopBar.css'

const { Group } = Input

export const SEARCH_BAR_PAGINATION_KEY = 'searchBookings'
export const ALL = 'all'
export const DAY = 'day'
export const FUTURE = 'future'

function GameSessionsTableTopBar({
    activeDateValue,
    activeDateFilter,
    searchValue,
    onSelectAllGameSessions,
    onSelectFutureGameSessions,
    onSelectDate,
    onRemoveSearchBookings,
    onSearch,
}) {
    return (
        <div className="flex justify-content-space-between margin-bottom">
            <div className="leftItems">
                {/* <CreateGameSessionModalState>
                {({ openModal }) => (
                    <HasPermissions roles={[ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.VENUE_MANAGER, ROLES.HOST]}>
                        <Button
                            type="primary"
                            onClick={openModal}
                        >
                            New game session
                        </Button>
                    </HasPermissions>
                )}
            </CreateGameSessionModalState> */}
            </div>
            <div className="rightItems">
                <div className="filterButtons">
                    <Group compact>
                        <DatePicker
                            value={activeDateValue}
                            onChange={onSelectDate}
                            format="DD-MM-YYYY"
                        />
                        <Radio.Group
                            value={activeDateFilter}
                            onChange={(e) => {
                                const { value } = e.target
                                switch (value) {
                                    case ALL:
                                        onSelectAllGameSessions()
                                        break
                                    case FUTURE:
                                        onSelectFutureGameSessions()
                                        break
                                    case DAY:
                                        break
                                    default:
                                        onSelectAllGameSessions()
                                }
                                onRemoveSearchBookings()
                            }}
                        >
                            <Radio.Button value={FUTURE}>Future</Radio.Button>
                            <Radio.Button value={ALL}>All</Radio.Button>
                        </Radio.Group>
                    </Group>
                </div>
                <RoomsSelect
                    paginationKey="select"
                    onChange={() => {
                        onRemoveSearchBookings()
                        onSearch()
                    }}
                />
                <div className="searchContainer">
                    <SearchBooking
                        paginationKey={SEARCH_BAR_PAGINATION_KEY}
                        activeDateFilter={activeDateFilter}
                        onChange={onSearch}
                        bookingId={searchValue}
                    />
                </div>
            </div>
        </div>
    )
}

GameSessionsTableTopBar.SEARCH_BAR_PAGINATION_KEY = SEARCH_BAR_PAGINATION_KEY

export default GameSessionsTableTopBar

import React from 'react'
import EditRoomForm from './EditRoomForm'
import EditRoomFormState from './EditRoomFormState'

function EditRoomFormWithState({
    room,
    games,
    equipmentSets,
    disabled,
    assignedSets,
    onChangeEquipmentSet,
}) {
    return (
        <EditRoomFormState room={room}>
            {({
                values,
                errors,
                isLoading,
                handleChange,
                handleChangeAsValue,
                handleSubmit,
            }) => (
                <EditRoomForm
                    room={room}
                    games={games}
                    isLoading={isLoading}
                    equipmentSets={equipmentSets}
                    values={values}
                    errors={errors}
                    disabled={disabled}
                    onChange={handleChange}
                    onChangeAsValue={handleChangeAsValue}
                    onSubmit={handleSubmit}
                    assignedSets={assignedSets}
                    onChangeEquipmentSet={onChangeEquipmentSet}
                />
            )}
        </EditRoomFormState>
    )
}

export default EditRoomFormWithState

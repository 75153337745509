import React from 'react'
import { createVenue, fetchVenuesExtendCurrentParams } from 'store/actions/venues'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    createVenue: (values) => dispatch(createVenue(values)),
    createVenueSuccess: (venue) => {
        notification.success({
            message: 'Venue added!',
            description: (
                <span>
                    A new venue&nbsp;
                    <strong>{venue.name}</strong>
                    &nbsp;
                    was successfully created.
                </span>
            ),
        })
    },
    createVenueFailed: ({ message }) => {
        notification.error({
            message: 'Something went wrong!',
            description: message,
        })
    },
    fetchVenues: () => {
        const { paginationKey } = ownProps
        return dispatch(fetchVenuesExtendCurrentParams(paginationKey))
    },
})

const CreateVenueRequest = withReduxState(null, mapDispatchToProps)

export default CreateVenueRequest

import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import { MODAL_IDS } from 'util/constants'

function EditGiftCardModalState({ giftCard, children }) {
    return (
        <WithActiveModal
            modalId={MODAL_IDS.EDIT_GIFT_CARD}
            entity={giftCard}
            entityKey="giftCard"
        >
            {children}
        </WithActiveModal>
    )
}

export default EditGiftCardModalState

import createEntityReducer from '../hor/createEntityReducer'
import {
    ROLE_REQUEST,
    ROLE_SUCCESS,
    ROLE_FAILURE,
} from '../../actions/roles'

const rolesReducer = createEntityReducer({
    types: [
        ROLE_REQUEST,
        ROLE_SUCCESS,
        ROLE_FAILURE,
    ],
})

export default rolesReducer

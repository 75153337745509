import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import { getEntityFromResponse } from 'util/index'
import { isValidPriceAdjustment } from 'util/validation/priceAdjustment'
import { getPriceAdjustmentErrors } from 'util/validation/priceAdjustmentErrors'
import { toPriceAdjustmentApiRequest, mapPriceAdjustmentToValues } from 'util/transformers/priceAdjustment'
import EditPriceAdjustmentRequest from './EditPriceAdjustmentRequest'

function EditPriceAdjustmentFormState({
    onCancel,
    onEditSuccess = () => {},
    children,
    priceAdjustment,
    priceSystem,
}) {
    return (
        <EditPriceAdjustmentRequest paginationKey="venueGamesOverview" priceAdjustment={priceAdjustment}>
            {({
                fetchVenueGames,
                editPriceAdjustment,
                editPriceAdjustmentSuccess,
                editPriceAdjustmentFailed,
            }) => {
                if (priceAdjustment === null || typeof priceAdjustment === 'undefined') {
                    return null
                }
                return (
                    <WithFormState
                        key={priceAdjustment && priceAdjustment.id}
                        initialValues={{
                            ...mapPriceAdjustmentToValues(priceAdjustment),
                            adjustmentType: 'timeFrame',
                        }}
                        isValidForm={(values) => isValidPriceAdjustment(values, priceSystem)}
                        getFormErrors={(values) => getPriceAdjustmentErrors(values, priceSystem)}
                        transformValuesToRequestBody={toPriceAdjustmentApiRequest}
                        onSendRequest={(values) => editPriceAdjustment(priceAdjustment.id, values)}
                        onCancel={onCancel}
                        onRequestSuccess={(response) => {
                            fetchVenueGames()
                            const updatedPriceAdjustment = getEntityFromResponse(response, 'priceAdjustments')
                            editPriceAdjustmentSuccess(updatedPriceAdjustment)
                            onEditSuccess(updatedPriceAdjustment)
                        }}
                        onRequestFailed={editPriceAdjustmentFailed}
                    >
                        {children}
                    </WithFormState>
                )
            }}
        </EditPriceAdjustmentRequest>
    )
}

export default EditPriceAdjustmentFormState

import React from 'react'
import { getEntityFromResponse } from 'util/index'
import { WithFormState } from 'components/util/forms/WithFormState'
import DeleteUserRequest from './DeleteUserRequest'

function DeleteUserFormState({
    onCancel,
    onDeleteSuccess,
    values,
    children,
}) {
    return (
        <DeleteUserRequest paginationKey="overview">
            {({
                deleteUser,
                fetchUsers,
                deleteUserSuccess,
                deleteUserFailed,
            }) => (
                <WithFormState
                    transformValuesToRequestBody={() => values}
                    onSendRequest={() => deleteUser(values)}
                    onCancel={onCancel}
                    onRequestSuccess={(response) => {
                        const user = getEntityFromResponse(response, 'users')
                        fetchUsers()
                        deleteUserSuccess(user)
                        onDeleteSuccess(user)
                    }}
                    onRequestFailed={deleteUserFailed}
                >
                    {children}
                </WithFormState>
            )}
        </DeleteUserRequest>
    )
}

export default DeleteUserFormState

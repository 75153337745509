import withReduxState from 'components/hoc/withReduxState'

const mapStateToProps = (state) => ({
    activeRoomId: state.activeFilters.roomId,
    activeVenueId: state.activeFilters.venueId,
    legalEntityId: state.activeFilters.legalEntityId,
})

const WithActiveFilter = withReduxState(mapStateToProps)

export default WithActiveFilter

import React, { useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import LoginWithState from 'components/pages/login/LoginWithState'
import ForgotPasswordWithState from 'components/pages/forgotPassword/ForgotPasswordWithState'
import ResetPasswordWithState from 'components/pages/resetPassword/ResetPasswordWithState'
import Unauthorized from 'components/layout/Unauthorized'
import UnauthorizedStateProvider from 'components/providers/UnauthorizedStateProvider'
import { NotFoundRedirectRoute } from '../util/NotFoundRedirectRoute'

function UnauthorizedRoutes() {
    const [email, setEmail] = useState('')

    return (
        <UnauthorizedStateProvider value={{ email, setEmail }}>
            <Unauthorized>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" index={true} element={<LoginWithState />} />
                        <Route path="/forgot-password" element={<ForgotPasswordWithState />} />
                        <Route path="/reset-password/:token/:email" element={<ResetPasswordWithState />} />
                        <Route path="*" element={<NotFoundRedirectRoute redirectTo="/" />} />
                    </Routes>
                </BrowserRouter>
            </Unauthorized>
        </UnauthorizedStateProvider>
    )
}

export default UnauthorizedRoutes

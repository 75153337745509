import React from 'react'
import './CouponDetailItem.css'

function CouponDetailItem({
    label,
    children,
}) {
    return (
        <li className="couponDetailItem">
            <span className="label">{label}</span>
            <span className="value">
                {children}
            </span>
        </li>
    )
}

export default CouponDetailItem

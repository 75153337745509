import React from 'react'
import { Select } from 'antd'
import { useSelector } from 'react-redux'
import { whereId } from 'util/index'

const { Option } = Select

function VenuesSelectWithRedux({
    legalEntity,
    onLegalEntityChange,
    venue,
    onVenueChange,
}) {
    const { legalEntities } = useSelector(({ me }) => me || {})
    const selectedLegalEntity = legalEntities.find(whereId(legalEntity))

    return (
        <>
            <Select
                onChange={(value) => {
                    onLegalEntityChange(value)
                    onVenueChange('all_venues')
                }}
                value={legalEntity}
                placeholder="Please select legal entity"
                filterOption={(input, option) => (
                    option.props.children.toLowerCase().includes(input.toLowerCase())
                )}
            >
                {
                    legalEntities.map(le => {
                        return (
                            <Option
                                key={le.id}
                                value={le.id}
                            >
                                {`${le.country.code} - ${le.name}`}
                            </Option>
                        )
                    })
                }
            </Select>

            {typeof selectedLegalEntity !== 'undefined' && (
                <div className="margin-top">
                    <Select
                        onChange={onVenueChange}
                        value={venue}
                        placeholder="Please select venue"
                        filterOption={(input, option) => (
                            option.props.children.toLowerCase().includes(input.toLowerCase())
                        )}
                    >
                        <Option value="all_venues">
                            All venues
                        </Option>
                        {selectedLegalEntity.venues.map(({ id, name }) => (
                            <Option
                                key={id}
                                value={id}
                            >
                                {name}
                            </Option>
                        ))}
                    </Select>
                </div>
            )}
        </>
    )
}

export default VenuesSelectWithRedux

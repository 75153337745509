import union from 'lodash.union'

export const defaultPaginationState = {
    isFetching: false,
    ids: [],
    pages: {},
    params: {},
    total: 0,
    totalPages: 0,
}

const createPaginationReducer = ({ types, mapActionToKey }) => {
    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error('Expected types to be an array of three elements.')
    }
    if (!types.every((type) => typeof type === 'string')) {
        throw new Error('Expected types to be strings.')
    }
    if (typeof mapActionToKey !== 'function') {
        throw new Error('Expected mapActionToKey to be a function.')
    }

    const [requestType, successType, failureType] = types

    // eslint-disable-next-line default-param-last
    const updatePagination = (state = defaultPaginationState, action) => {
        const { type, params } = action
        switch (type) {
            case requestType:
                return {
                    ...state,
                    isFetching: true,
                    params,
                    pages: {
                        ...state.pages,
                        [params.page]: [],
                    },
                }
            case successType:
                return {
                    ...state,
                    isFetching: false,
                    ids: union(state.ids, action.response.result),
                    total: action.response.meta
                        ? action.response.meta.total
                        : action.response.result.length,
                    pages: {
                        ...state.pages,
                        [params.page]: action.response.result,
                    },
                }
            case failureType:
                return {
                    ...state,
                    isFetching: false,
                }
            default:
                return state
        }
    }

    // eslint-disable-next-line default-param-last
    return (state = {}, action) => {
    // Update pagination by key
        switch (action.type) {
            case requestType:
            case successType:
            case failureType: {
                const key = mapActionToKey(action)
                if (typeof key !== 'string') {
                    throw new Error('Expected key to be a string.')
                }
                return {
                    ...state,
                    [key]: updatePagination(state[key], action),
                }
            }
            default:
                return state
        }
    }
}

export default createPaginationReducer

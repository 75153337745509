import React from 'react'
import { useDispatch } from 'react-redux'
import { notification } from 'antd'
import {
    createCouponBatch,
    fetchCouponBatchesExtendCurrentParams,
    deleteCouponBatch,
    editCouponBatch,
} from 'store/actions/coupons'

export default function useCouponBatch(paginationKey) {
    const dispatch = useDispatch()

    return ({
        createCouponBatch: async (values) => (dispatch(createCouponBatch(values))),
        createCouponBatchSuccess: (couponBatch) => {
            notification.success({
                message: 'Coupon codes added!',
                description: `${couponBatch && couponBatch.batchAmount} codes are successfully created.`,
            })
        },
        createCouponBatchFailed: (error) => {
            notification.error({
                message: 'Something went wrong',
                description: `${error.message}`,
            })
        },
        fetchCouponBatches: async () => (
            dispatch(fetchCouponBatchesExtendCurrentParams(paginationKey))
        ),
        deleteCouponBatch: (couponBatch) => dispatch(deleteCouponBatch(couponBatch.batchUuid)),
        deleteCouponBatchSuccess: () => {
            notification.success({
                message: 'Coupon batch deleted!',
                description: (
                    <span> Coupon batch is succesfully deleted.</span>
                ),
            })
        },
        deleteCouponBatchFailed: (error) => {
            notification.error({
                message: 'Something went wrong',
                description: `${error.message}`,
            })
        },
        editCouponBatch: (couponBatchId, body) => (
            dispatch(editCouponBatch(couponBatchId, body))
        ),
        editCouponBatchSuccess: () => {
            notification.success({
                message: 'Coupon Batch edited!',
                description: 'The coupon batch was successfully edited.',
            })
        },
        editCouponBatchFailed: ({ message }) => {
            notification.error({
                message: 'Something went wrong',
                description: message,
            })
        },
    })
}

import React from 'react'
import { TopBar } from 'components/layout/TopBar'
import { Main } from  'components/layout/Main'
import VenueSettingsContainer from 'components/layout/sidebar/VenueSettingsContainer'
import EquipmentSetsTableWithEquipmentSets from './table/EquipmentSetsTableWithEquipmentSets'
import CreateEquipmentSetModalWithState from './modals/create/CreateEquipmentSetModalWithState'
import DeleteEquipmentSetModalWithState from './modals/delete/DeleteEquipmentSetModalWithRedux'

function EquipmentSetsPage() {
    return (
        <>
            <TopBar activeMenuItem="venueSettings" />
            <Main>
                <VenueSettingsContainer activeMenuItem="equipmentSets">
                    <EquipmentSetsTableWithEquipmentSets paginationKey="overview" />
                </VenueSettingsContainer>
                <CreateEquipmentSetModalWithState />
                <DeleteEquipmentSetModalWithState />
            </Main>
        </>
    )
}

export default EquipmentSetsPage

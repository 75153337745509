import { getEntities } from 'store/util/index'
import withReduxState from 'components/hoc/withReduxState'

const mapStateToProps = (state, ownProps) => ({
    room: getEntities(state, 'rooms')[ownProps.roomId] || { isFetching: true },
})

const EquipmentSetRoom = withReduxState(mapStateToProps, null)

export default EquipmentSetRoom

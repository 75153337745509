import { PER_PLAYER } from 'util/constants'
import {
    isRequired, isBefore, isAfter,
} from './index'

export function isValidWeekDay(day) {
    return isRequired(day)
}

export function isValidPlayerPrice(playerPrice) {
    return isRequired(playerPrice) && typeof playerPrice === 'number'
}

export function isValidTotalPrice(totalPrice) {
    return isRequired(totalPrice) && typeof totalPrice === 'number'
}

export function isValidStartTime(adjustmentType, from) {
    return adjustmentType === 'timeFrame' ? isRequired(from) : true
}

export function isValidEndTime(adjustmentType, until) {
    return adjustmentType === 'timeFrame' ? isRequired(until) : true
}

export function startIsBeforeEnd(adjustmentType, from, until) {
    return adjustmentType === 'timeFrame' ? isBefore(from, until) : true
}

export function endIsAfterstart(adjustmentType, from, until) {
    return adjustmentType === 'timeFrame' ? isAfter(until, from) : true
}

export function isValidPriceAdjustment({
    day,
    playerPrice,
    totalPrice,
    adjustmentType,
    from,
    until,
}, priceSystem) {
    return isValidWeekDay(day)
        && isValidPlayerPrice(playerPrice)
        && startIsBeforeEnd(adjustmentType, from, until)
        && endIsAfterstart(adjustmentType, from, until)
        && isValidStartTime(adjustmentType, from)
        && isValidEndTime(adjustmentType, until)
        && (
            priceSystem === PER_PLAYER
            || isValidTotalPrice(totalPrice)
        )
}

import React from 'react'
import Mount from 'components/util/Mount'
import PaginationTableBottomBar from 'components/util/table/PaginationTableBottomBar'
import Rooms from 'components/pages/settings/venue/Rooms'
import EquipmentSets from './EquipmentSets'
import EquipmentSetsTable from './EquipmentSetsTable'
import EquipmentSetsTableTopBar from './EquipmentSetsTableTopBar'

const PAGE_SIZE = 50

function EquipmentSetsTableWithEquipmentSets({ paginationKey }) {
    return (
        <Rooms paginationKey={paginationKey} limit={PAGE_SIZE}>
            {(rooms) => (
                <EquipmentSets paginationKey={paginationKey} limit={PAGE_SIZE}>
                    {({
                        isFetching,
                        currentPage,
                        total,
                        params,
                        equipmentSetsForCurrentPage,
                        fetchEquipmentSets,
                    }) => (
                        <Mount
                            didMount={() => {
                                rooms.fetchRooms()
                                fetchEquipmentSets()
                            }}
                        >
                            <EquipmentSetsTableTopBar />
                            <EquipmentSetsTable
                                equipmentSets={equipmentSetsForCurrentPage}
                                isFetching={rooms.isFetching || isFetching}
                            />
                            <PaginationTableBottomBar
                                currentPage={currentPage}
                                total={total}
                                pageSize={params.limit || PAGE_SIZE}
                                onChangePage={(page) => fetchEquipmentSets({ ...params, page })}
                            />
                        </Mount>
                    )}
                </EquipmentSets>
            )}
        </Rooms>
    )
}

export default EquipmentSetsTableWithEquipmentSets

import { notification } from 'antd'
import { editPriceAdjustment } from 'store/actions/priceAdjustments'
import { fetchVenueGamesExtendCurrentParams } from 'store/actions/venueGames'
import withReduxState from 'components/hoc/withReduxState'

const mapDispatchToProps = (dispatch, ownProps) => ({
    editPriceAdjustment: (priceAdjustmentId, body) => dispatch(editPriceAdjustment(ownProps.priceAdjustment.gameId, priceAdjustmentId, body)),
    editPriceAdjustmentSuccess: () => {
        notification.success({
            message: 'Price adjustment edited!',
            description: 'PriceAdjustment was successfully edited.',
        })
    },
    editPriceAdjustmentFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchVenueGames: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchVenueGamesExtendCurrentParams(paginationKey))
    },
})

const EditPriceAdjustmentRequest = withReduxState(null, mapDispatchToProps)

export default EditPriceAdjustmentRequest

import React from 'react'
import { getEntityFromResponse } from 'util/index'
import { WithFormState } from 'components/util/forms/WithFormState'
import DeleteGameSessionRequest from '../../requests/DeleteGameSessionRequest'

function DeleteGameSessionFormState({
    onCancel,
    onDeleteSuccess,
    values,
    children,
}) {
    return (
        <DeleteGameSessionRequest paginationKey="overview">
            {({
                deleteGameSession,
                fetchGameSessions,
                deleteGameSessionSuccess,
                deleteGameSessionFailed,
            }) => (
                <WithFormState
                    transformValuesToRequestBody={() => values}
                    onSendRequest={() => deleteGameSession(values)}
                    onCancel={onCancel}
                    onRequestSuccess={(response) => {
                        const gameSession = getEntityFromResponse(response, 'gameSessions')
                        fetchGameSessions()
                        deleteGameSessionSuccess(gameSession)
                        onDeleteSuccess(gameSession)
                    }}
                    onRequestFailed={deleteGameSessionFailed}
                >
                    {children}
                </WithFormState>
            )}
        </DeleteGameSessionRequest>
    )
}

export default DeleteGameSessionFormState

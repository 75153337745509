import { CALL_API } from '../middleware/api'
import availabilitySchema from '../schemas/availabilities'

export const AVAILABILITIES_REQUEST = 'AVAILABILITIES_REQUEST'
export const AVAILABILITIES_SUCCESS = 'AVAILABILITIES_SUCCESS'
export const AVAILABILITIES_FAILURE = 'AVAILABILITIES_FAILURE'

export const fetchAvailabilities = (componentName, venueId, gameId, params) => ({
    componentName,
    venueId,
    gameId,
    params,
    [CALL_API]: {
        types: [
            AVAILABILITIES_REQUEST,
            AVAILABILITIES_SUCCESS,
            AVAILABILITIES_FAILURE,
        ],
        endpoint: `/venues/${venueId}/games/${gameId}/availability`,
        schema: [availabilitySchema],
        params,
    },
})

export const fetchAvailabilitiesForActiveVenue = (componentName, gameId, params) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch(fetchAvailabilities(componentName, venueId, gameId, params))
}

import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import { MODAL_IDS } from 'util/constants'
import DeletePriceAdjustmentModal from './DeletePriceAdjustmentModal'

function DeletePriceAdjustmentModalWithRedux() {
    return (
        <WithActiveModal modalId={MODAL_IDS.DELETE_PRICE_ADJUSTMENT}>
            {({
                isOpen,
                entity,
                closeModal,
            }) => (
                <DeletePriceAdjustmentModal
                    isOpen={isOpen}
                    onClose={closeModal}
                    entity={entity}
                />
            )}
        </WithActiveModal>
    )
}

export default DeletePriceAdjustmentModalWithRedux

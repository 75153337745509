import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import { isValidCouponBatch, getCouponBatchErrors } from 'util/validation/coupons'
import { getEntityFromResponse } from 'util/index'
import useCouponBatch from '../../hooks/useCouponBatch'
import { toBatchApiRequest } from '../../../../../../util/transformers/couponBatch'

function CreateCouponBatchFormState({
    onCancel,
    onSaveSuccess,
    children,
}) {
    const {
        createCouponBatch,
        fetchCouponBatches,
        createCouponBatchSuccess,
        createCouponBatchFailed,
    } = useCouponBatch('overview')

    return (
        <WithFormState
            initialValues={{
                prefix: '',
                code: null,
                batchAmount: null,
                amount: null,
                amountType: 'isAmount',
                minPlayerAmount: null,
                expiresAt: null,
                lastPossiblePlayDate: null,
                description: '',
                legalEntity: null,
                venue: null,
                isAllowedForGiftcards: false,
            }}
            isValidForm={isValidCouponBatch}
            getFormErrors={getCouponBatchErrors}
            transformValuesToRequestBody={toBatchApiRequest}
            onSendRequest={createCouponBatch}
            onCancel={onCancel}
            onRequestSuccess={(response) => {
                const batch = getEntityFromResponse(response, 'couponBatches')
                fetchCouponBatches()
                createCouponBatchSuccess(batch)
                onSaveSuccess(batch)
            }}
            onRequestFailed={createCouponBatchFailed}
        >
            {children}
        </WithFormState>
    )
}

export default CreateCouponBatchFormState

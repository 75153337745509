import React from 'react'
import { editGame, fetchGamesExtendCurrentParams } from 'store/actions/games'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    editGame: async (gameId, values) => dispatch(editGame(gameId, values)),
    editGameSuccess: (game) => {
        notification.success({
            message: 'Game edited!',
            description: (
                <span>
                    The game&nbsp;
                    <strong>{game && game.title}</strong>
                    &nbsp;
                    was successfully edited.
                </span>
            ),
        })
    },
    editGameFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchGames: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchGamesExtendCurrentParams(paginationKey))
    },
})

const EditGameRequest = withReduxState(null, mapDispatchToProps)

export default EditGameRequest

// TODO: make sure booking returns the country code instead. This is not scalable!!

/**
 * @param {number} legalEntityId
 * @param {string} language ISO language code. E.g. "en"
 * @returns string
 */
export function buildLocaleByLegalEntityIdAndLanguageCode(legalEntityId, language) {
    switch (legalEntityId) {
        case 1:
            return `${language}-be`
        case 2:
            return `${language}-nl`
        case 3:
            return `${language}-gb`
        case 4:
            return `${language}-au`
        case 5:
            return `${language}-de`
        case 6:
            return `${language}-pt`
        default:
            return 'en-be'
    }
}

import React from 'react'
import EditDiscountBannerFormState from 'components/pages/settings/discount-banners/forms/edit/EditDiscountBannerFormState'
import { EMPTY_DISCOUNT_BLOCK } from 'components/pages/settings/discount-banners/forms/create/CreateDiscountBannerFormState'
import EditDiscountBannerModalState from './EditDiscountBannerModalState'
import EditDiscountBannerModal from './EditDiscountBannerModal'

function EditDiscountBannerModalWithState() {
    return (
        <EditDiscountBannerModalState>
            {({
                isOpen,
                closeModal,
                discountBanner,
            }) => (
                <EditDiscountBannerFormState
                    onEditSuccess={closeModal}
                    onCancel={closeModal}
                    discountBanner={discountBanner}
                >
                    {({
                        values,
                        errors,
                        isLoading,
                        handleChange,
                        handleChangeAsValue,
                        handleCancel,
                        handleSubmit,
                    }) => (
                        <EditDiscountBannerModal
                            isOpen={isOpen}
                            onClose={closeModal}
                            discountBanner={values}
                            values={values}
                            errors={errors}
                            isLoading={isLoading}
                            onChange={handleChange}
                            onChangeAsValue={handleChangeAsValue}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                            addBlock={() => handleChangeAsValue('blocks', [
                                ...values.blocks,
                                {
                                    id: values.blocks.length,
                                    ...EMPTY_DISCOUNT_BLOCK,
                                },
                            ])}
                        />
                    )}
                </EditDiscountBannerFormState>
            )}
        </EditDiscountBannerModalState>
    )
}

export default EditDiscountBannerModalWithState

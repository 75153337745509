/* eslint-disable max-len */
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
    BarChartOutlined,
    BarsOutlined,
    RocketOutlined,
    SettingOutlined,
    EnvironmentOutlined,
} from '@ant-design/icons'
import { Menu } from 'antd'
import { ROLES } from 'util/constants'

// https://ant.design/components/menu/#When-To-Use

/**
 * @component
 * @param {Object} props
 * @param {Role} props.userRole
 * @param {string|undefined} props.activeMenuItem
 * @return {React.Element}
 */
function TopBarMenu(props) {
    const items = useMemo(() => {
        const menuItems = []
        const roleSlug = props.userRole.slug

        menuItems.push({
            label: (
                <Link to="/">
                    <BarsOutlined />
                    {' '}
                    Bookings
                </Link>
            ),
            key: 'bookings',
        })

        menuItems.push({
            label: (
                <Link to="/game-sessions">
                    <RocketOutlined />
                    {' '}
                    Game sessions
                </Link>
            ),
            key: 'gameSessions',
        })

        if ([ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.VENUE_MANAGER].indexOf(roleSlug) !== -1) {
            menuItems.push({
                label: (
                    <Link to="/settings/rooms">
                        <EnvironmentOutlined />
                        {' '}
                        Venue
                    </Link>
                ),
                key: 'venueSettings',
            })

            menuItems.push({
                label: (
                    <Link to="/analytics/sales">
                        <BarChartOutlined />
                        {' '}
                        Analytics
                    </Link>
                ),
                key: 'analytics',
            })
        }

        if ([ROLES.FRANCHISE].indexOf(roleSlug) !== -1) {
            menuItems.push({
                label: (
                    <Link to="/settings/rooms">
                        <EnvironmentOutlined />
                        {' '}
                        Venue
                    </Link>
                ),
                key: 'venueSettings',
            })
        }

        if ([ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.FRANCHISE].indexOf(roleSlug) !== -1) {
            let path
            switch (roleSlug) {
                case ROLES.FRANCHISE: {
                    path = '/settings/exports'
                    break
                }
                default: {
                    path = '/settings/coupons'
                }
            }

            menuItems.push({
                label: (
                    <Link to={path}>
                        <SettingOutlined />
                        {' '}
                        Settings
                    </Link>
                ),
                key: 'globalSettings',
            })
        }

        return menuItems
    }, [props.userRole.slug])

    return (
        <Menu
            style={{ borderBottom: 'none' }}
            selectedKeys={[props.activeMenuItem]}
            mode="horizontal"
            items={items}
        />
    )
}

export default TopBarMenu

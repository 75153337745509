/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-lone-blocks */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react'
import {
    Modal,
    QRCode,
} from 'antd'
import './bookingRegisteredPlayerModal.css'
import { toCanvas } from 'qrcode'

/**
 * @typedef {Object} QrCodeModalProps
 * @property {boolean} isModalOpen
 * @property {() => void} onCancel
 * @property {string} playerRegistrationLink
 * @property {React.MutableRefObject} canvasRef
 * @definition Modal to display a qr code
 *
*/

/**
 * @param {QrCodeModalProps} props
 */

function QrCodeModal(props) {
    const {
        isModalOpen,
        onCancel,
        playerRegistrationLink,
    } = props

    const newCanvasRef = useRef(null)

    const onCancelClick = () => {
        onCancel()
    }

    useEffect(() => {
        if (newCanvasRef.current !== null)
            toCanvas(newCanvasRef.current, playerRegistrationLink)
    }, [newCanvasRef, playerRegistrationLink])

    return (
        <Modal
            title="Qr code"
            open={isModalOpen}
            onCancel={onCancelClick}
            cancelText="Close"
            width={600}
            okButtonProps={{ style: { display: 'none' } }}
        >
            <div className="qr-code-modal-container">
                <canvas
                    ref={newCanvasRef}
                    className="modal-qr-code-canvas"
                />
            </div>
        </Modal>
    )
}

export default QrCodeModal

import { notification } from 'antd'
import { createGameSessionForActiveVenue, fetchGameSessions } from 'store/actions/gameSessions'
import withReduxState from 'components/hoc/withReduxState'

const mapDispatchToProps = (dispatch, ownProps) => ({
    createGameSession: (body) => dispatch(createGameSessionForActiveVenue(body)),
    createGameSessionSuccess: () => {
        notification.success({
            message: 'Game session created!',
            description: 'Game session was successfully created.',
        })
    },
    createGameSessionFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchGameSessions: () => {
        const { paginationKey } = ownProps
        return dispatch(fetchGameSessions(paginationKey))
    },
})

const CreateGameSessionRequest = withReduxState(null, mapDispatchToProps)

export default CreateGameSessionRequest

import dotProp from 'dot-prop-immutable'
import { REMOVE_PAGINATION } from '../../actions'

const removeStateReducer = (state, action) => {
    const { entity, type } = action
    switch (type) {
        case REMOVE_PAGINATION: {
            const { fullPaginationKey } = action
            return dotProp.delete(state, `pagination.${entity}.${fullPaginationKey}`)
        }
        default:
            return state
    }
}

export default removeStateReducer

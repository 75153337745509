import { useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { objToArray } from 'util/index'

function ChildIfPermitted({
    roles,
    redirectTo,
    children,
}) {
    const navigate = useNavigate()
    // User data
    const me = useSelector((state) => state.me)
    // Object of roles
    const entityRoles = useSelector((state) => state.entities.roles)
    const permitted = useMemo(() => {
        // If no auth roles were passed -> just return a route
        if (roles === undefined || roles === '*' || !(Array.isArray(roles))) {
            return true
        }

        // Because entity roles is an object, instead of an array...
        const entityRolesArr = objToArray(entityRoles)

        // Find detailed user role
        const roleId = me.role
        const userRole = entityRolesArr.find((r) => r.id === roleId)
        // This should never happen..
        if (typeof userRole === 'undefined') {
            throw new Error('User role not found..')
        }

        // Check if the user role slug is in array of allowed roles
        return roles.indexOf(userRole.slug) !== -1
    }, [me, entityRoles, roles])

    useEffect(() => {
        if (permitted) return

        if (typeof redirectTo === 'string' && redirectTo.length !== 0) {
            navigate(redirectTo, { replace: true })
        } else {
            navigate('/', { replace: true })
        }
    }, [navigate, permitted, redirectTo])

    if (permitted) {
        return children
    } else {
        return null
    }
}

export default ChildIfPermitted

import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import { MODAL_IDS } from 'util/constants'

function CreateVenueGameModalState({ children }) {
    return (
        <WithActiveModal modalId={MODAL_IDS.CREATE_VENUE_GAME}>
            {children}
        </WithActiveModal>
    )
}

export default CreateVenueGameModalState

import React, { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppState } from '../hooks/useAppState'
import './Logo.css'

function Logo() {
    const { isAuthenticated } = useAppState()
    const { legalEntityId } = useSelector(({ activeFilters }) => activeFilters)
    const legalEntities = useSelector(({ entities }) => entities.legalEntities)
    // const [image, setImage] = useState(null)

    const image = useMemo(() => {
        if (!Array.isArray(legalEntities) || legalEntityId === null)
            return null

        const legalEntity = legalEntities.find(le => le.id === legalEntityId)
        if (typeof legalEntity === 'undefined')
            return null

        const financialConfig = legalEntity.financialConfig
        // console.log('Financial config:', financialConfig)

        if (!Array.isArray(financialConfig.mediaItems) || financialConfig.mediaItems.length === 0)
            return null
        // console.log('Media items:', financialConfig.mediaItems)

        return financialConfig.mediaItems[0].urls.medium
    }, [legalEntities, legalEntityId])

    if (!isAuthenticated || image === null || typeof image === 'undefined') {
        return (
            <svg width="100%" style={{ maxWidth: '144px' }} viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
                {/* eslint-disable-next-line react/no-unknown-property */}
                <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="144" height="144">
                    <path d="M0 71.6554V0H143.211V71.6554V143.311H0V71.6554Z" fill="white" />
                </mask>
                <g mask="url(#mask0)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M143.211 71.6538C143.211 111.23 111.153 143.311 71.604 143.311C32.058 143.311 0 111.23 0 71.6538C0 32.0814 32.058 -0.000488281 71.604 -0.000488281C111.153 -0.000488281 143.211 32.0814 143.211 71.6538Z" fill="#239A98" />
                </g>
                <path fillRule="evenodd" clipRule="evenodd" d="M21.8349 58.5101V48.3853H19.8477C18.7974 48.3853 18 47.6819 18 46.7065C18 45.7573 18.7974 45.0647 19.8477 45.0647H27.6327C28.6857 45.0647 29.4813 45.7573 29.4813 46.7065C29.4813 47.6819 28.6857 48.3853 27.6327 48.3853H25.5888V58.5101C25.5888 59.4719 24.759 60.1996 23.7051 60.1996C22.6548 60.1996 21.8349 59.4719 21.8349 58.5101Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M30.4052 58.5101V46.7308C30.4052 45.7798 31.2242 45.0647 32.2754 45.0647C33.3392 45.0647 34.1581 45.7798 34.1581 46.7308V50.626H37.6934V46.7308C37.6934 45.7798 38.5124 45.0647 39.5744 45.0647C40.6283 45.0647 41.4464 45.7798 41.4464 46.7308V58.5101C41.4464 59.4719 40.6283 60.1996 39.5744 60.1996C38.5241 60.1996 37.6934 59.4719 37.6934 58.5101V53.9574H34.1581V58.5101C34.1581 59.4719 33.3266 60.1996 32.2754 60.1996C31.2242 60.1996 30.4052 59.4719 30.4052 58.5101Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M43.3373 58.5321V46.7069C43.3373 45.7576 44.132 45.0651 45.194 45.0651H50.6021C51.6641 45.0651 52.3211 45.7576 52.3211 46.7069C52.3211 47.6823 51.6515 48.3857 50.6021 48.3857H47.0903V50.7426H50.2628C51.3302 50.7426 51.9863 51.4469 51.9863 52.397C51.9863 53.3589 51.3302 54.074 50.2628 54.074H47.0903V56.8776H50.9945C52.0565 56.8776 52.7135 57.5711 52.7135 58.5321C52.7135 59.4939 52.0565 60.2 50.9945 60.2H45.194C44.132 60.2 43.3373 59.4939 43.3373 58.5321Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M29.4669 77.7101C31.2381 77.7101 32.2722 76.4817 32.2722 74.2202C32.2722 72.2578 31.0995 71.0861 29.4669 71.0861H26.8533V77.7101H29.4669ZM18 94.6103V67.298C18 65.0609 19.9332 63.3713 22.4388 63.3713H29.4669C36.0855 63.3713 41.2614 67.9501 41.2614 74.2202C41.2614 81.0073 36.549 85.423 29.4669 85.423H26.8533V94.6103C26.8533 96.8447 24.9174 98.5352 22.4388 98.5352C19.9332 98.5352 18 96.8447 18 94.6103Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M54.0755 78.1175C54.8405 80.4627 55.6577 82.9439 56.5001 85.3143C57.3452 82.9979 58.1903 80.4627 58.9526 78.1175H54.0755ZM71.3753 67.7873C71.3753 68.3871 71.2655 68.823 71.0198 69.5868C70.3124 71.8203 64.6712 89.2671 61.2404 96.2181C60.4511 97.8257 58.7357 98.5336 56.447 98.5336C54.1583 98.5336 52.55 97.7987 51.7607 96.2181C48.6017 89.839 42.3071 70.7846 41.9822 69.5868C41.7356 68.823 41.6267 68.3871 41.6267 67.7873C41.6267 65.334 43.5887 63.3716 45.9593 63.3716C48.0284 63.3716 49.5548 64.3794 50.3459 66.7237C50.6987 67.7594 51.0533 69.014 51.461 70.4036H61.5392C61.9496 69.014 62.3042 67.7594 62.6561 66.7237C63.4481 64.3794 64.97 63.3716 67.0427 63.3716C69.4142 63.3716 71.3753 65.334 71.3753 67.7873Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M84.9418 76.945C86.848 76.945 87.7462 75.9102 87.7462 73.7009C87.7462 72.1203 86.6842 71.0855 84.9418 71.0855H81.8638V76.945H84.9418ZM73.0105 94.6097V67.2975C73.0105 65.0613 74.9428 63.3717 77.4214 63.3717H84.9418C92.5963 63.3717 96.7624 67.8145 96.7624 73.8378C96.7624 77.4367 95.2369 80.0809 91.8034 81.9074C93.8743 83.7618 95.101 86.5132 96.6805 90.8208C97.2259 92.2663 97.4977 93.3282 97.4977 94.1189C97.4977 96.4362 95.6455 98.5338 93.166 98.5338C91.2058 98.5338 89.4346 97.471 88.8343 95.4815C86.3035 87.0328 84.7222 84.6606 82.6522 84.6606H81.8638V94.6097C81.8638 96.8442 79.9 98.5338 77.4214 98.5338C74.9428 98.5338 73.0105 96.8442 73.0105 94.6097Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M99.225 94.61V67.242C99.225 65.0607 101.185 63.3711 103.638 63.3711C106.142 63.3711 108.077 65.0336 108.077 67.242V73.2392L115.296 65.5506C116.794 63.97 117.939 63.3711 119.519 63.3711C121.916 63.3711 123.551 65.3336 123.551 67.514C123.551 68.6325 123.116 69.7502 121.916 70.921L112.598 79.8903C115.621 82.3166 119.954 85.8596 124.124 91.4471C124.695 92.2108 125.238 93.0294 125.238 94.393C125.238 96.6004 123.521 98.534 121.1 98.534C119.301 98.534 118.183 97.9892 117.149 96.7085C114.697 93.6815 110.528 88.8578 108.077 86.5945V94.61C108.077 96.8445 106.115 98.534 103.638 98.534C101.157 98.534 99.225 96.8445 99.225 94.61Z" fill="white" />
            </svg>
        )
    }

    return <div className="custom-logo" style={{ backgroundImage: `url(${image})` }} />
}

export default Logo

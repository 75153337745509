import React from 'react'
import { ConfirmInputModal } from 'components/util/modals/ConfirmInputModal'
import { WEEK_DAYS } from 'util/constants'
import { whereId } from 'util/index'
import DeleteCouponBatchTimeFrameFormState from '../../forms/delete-batch-timeframes/DeleteCouponBatchTimeFrameFormState'

const getDay = (key) => WEEK_DAYS.find(whereId(key))

function DeleteCouponBatchTimeFrameModal({
    isOpen,
    onClose,
    couponTimeFrame,
}) {
    if (couponTimeFrame === null || typeof couponTimeFrame === 'undefined') {
        return null
    }
    const day = getDay(couponTimeFrame.day) || {}
    return (
        <DeleteCouponBatchTimeFrameFormState
            onDeleteSuccess={onClose}
            onCancel={onClose}
            values={couponTimeFrame}
        >
            {({
                isLoading,
                handleSubmit,
                handleCancel,
            }) => (
                <ConfirmInputModal
                    safeWord={day.name || ''}
                    title={`Are you sure you want to delete the timeframe for ${day.name}?`}
                    isOpen={isOpen}
                    onConfirm={handleSubmit}
                    onCancel={handleCancel}
                    confirmText="Delete timeframe"
                    confirmType="primary"
                    confirmIsDanger={true}
                    cancelText="Cancel"
                    isLoading={isLoading}
                    close={onClose}
                >
                    <p>
                        Removing a timeframe cannot be undone.
                        {' '}
                        <br />
                        Please type the exact name of the day to confirm.
                    </p>
                </ConfirmInputModal>
            )}
        </DeleteCouponBatchTimeFrameFormState>
    )
}

export default DeleteCouponBatchTimeFrameModal

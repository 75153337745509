import React from 'react'
import DeleteCouponTimeFrameFormState
    from 'components/pages/settings/coupons/forms/delete-time-frame/DeleteCouponTimeFrameFormState'
import DeleteCouponTimeFrameModal from './DeleteCouponTimeFrameModal'
import DeleteCouponTimeFrameModalState from './DeleteCouponTimeFrameModalState'

function DeleteCouponTimeFrameModalWithState() {
    return (
        <DeleteCouponTimeFrameModalState>
            {({
                isOpen,
                closeModal,
                entity,
            }) => (
                <DeleteCouponTimeFrameFormState
                    onDeleteSuccess={closeModal}
                    onCancel={closeModal}
                    values={entity}
                >
                    {({
                        isLoading,
                        handleSubmit,
                        handleCancel,
                    }) => (
                        <DeleteCouponTimeFrameModal
                            isOpen={isOpen}
                            onClose={closeModal}
                            isLoading={isLoading}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                            entity={entity}
                        />
                    )}
                </DeleteCouponTimeFrameFormState>
            )}
        </DeleteCouponTimeFrameModalState>
    )
}
export default DeleteCouponTimeFrameModalWithState

import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import useCouponBatchTimeFrame from '../../hooks/useCouponBatchTimeFrame'

function DeleteCouponBatchTimeFrameFormState({
    onDeleteSuccess,
    onCancel,
    values,
    children,
    couponTimeFrame,
}) {
    const {
        fetchCouponBatches,
        deleteCouponTimeFrame,
        deleteCouponTimeFrameSuccess,
        deleteCouponTimeFrameFailed,
    } = useCouponBatchTimeFrame('overview', { couponTimeFrame })

    return (
        <WithFormState
            transformValuesToRequestBody={() => values}
            onSendRequest={() => deleteCouponTimeFrame(values)}
            onCancel={onCancel}
            onRequestSuccess={() => {
                fetchCouponBatches()
                deleteCouponTimeFrameSuccess()
                onDeleteSuccess()
            }}
            onRequestFailed={deleteCouponTimeFrameFailed}
        >
            {children}
        </WithFormState>
    )
}

export default DeleteCouponBatchTimeFrameFormState

import createPaginationReducer from '../hor/createPaginationReducer'
import {
    COUPONS_REQUEST,
    COUPONS_SUCCESS,
    COUPONS_FAILURE,
} from '../../actions/coupons'

const couponsPaginationReducer = createPaginationReducer({
    mapActionToKey: (action) => action.viewName,
    types: [
        COUPONS_REQUEST,
        COUPONS_SUCCESS,
        COUPONS_FAILURE,
    ],
})

export default couponsPaginationReducer

import React from 'react'
import * as theme from 'styles/theme'
import { FrownOutlined } from '@ant-design/icons'
import './NotAuthorized.css'

function NotAuthorized() {
    return (
        <div className="notAuthorizedSection">
            <FrownOutlined style={{ fontSize: theme.fontSizeHuge }} />
            <h2 className="margin-top">
                You are not allowed to access this page.
            </h2>
        </div>
    )
}

export default NotAuthorized

import React from 'react'
import { Button } from 'antd'
import { ROLES } from 'util/constants'
import HasPermissions from 'components/pages/authorized/HasPermissions'
import CreateCouponTimeFrameModalState from '../modals/create-time-frame/CreateCouponTimeFrameModalState'
import './CreateCouponTimeFramesButton.css'

function CreateCouponTimeFramesButton({
    coupon,
}) {
    return (
        <HasPermissions roles={[ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER]}>
            <CreateCouponTimeFrameModalState coupon={coupon}>
                {({ openModal }) => (
                    <div className="createTimeFrame">
                        <Button onClick={openModal}>
                            Add timeframe
                        </Button>
                    </div>
                )}
            </CreateCouponTimeFrameModalState>
        </HasPermissions>
    )
}

export default CreateCouponTimeFramesButton

import React from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Table, Button } from 'antd'
import { MODAL_IDS, APP_DATE_FORMAT } from 'util/constants'
import { sorterToSortParam } from 'util/index'
import GiftCardDetails from 'components/pages/settings/gift-cards/details/GiftCardDetails'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import WithMe from 'components/pages/authorized/WithMe'
import dayjs from 'dayjs'

const columns = [
    {
        title: 'Code',
        key: 'code',
        render: (text, { couponCode }) => <strong>{couponCode}</strong>,
    },
    {
        title: 'Name',
        key: 'name',
        align: 'center',
        render: (text, { firstName, lastName }) => `${firstName} ${lastName}`,
    },
    {
        title: 'Amount',
        key: 'amount',
        align: 'center',
        width: 100,
        render: (text, { price }) => price,
    },
    {
        title: 'Expires',
        dataIndex: 'expiresAt',
        width: 110,
        render: (expiresAt) => dayjs.utc(expiresAt).format(APP_DATE_FORMAT),
    },
    {
        title: 'Action',
        key: 'action',
        align: 'center',
        width: 80,
        render: (text, record) => (
            <WithMe>
                {({ isAdmin, isLegalEntityManager }) => (
                    <>
                        <WithActiveModal
                            modalId={MODAL_IDS.EDIT_GIFT_CARD}
                            entity={record}
                        >
                            {({ openModal }) => (
                                <Button
                                    ghost
                                    icon={<EditOutlined />}
                                    type="primary"
                                    shape="circle"
                                    size="small"
                                    className="ant-btn-no-border"
                                    disabled={!isAdmin && !isLegalEntityManager}
                                    onClick={openModal}
                                />
                            )}
                        </WithActiveModal>
                        <WithActiveModal
                            modalId={MODAL_IDS.DELETE_GIFT_CARD}
                            entity={record}
                        >
                            {({ openModal }) => (
                                <Button
                                    ghost
                                    icon={<DeleteOutlined />}
                                    danger={true}
                                    shape="circle"
                                    size="small"
                                    className="ant-btn-no-border"
                                    disabled={!isAdmin && !isLegalEntityManager}
                                    onClick={openModal}
                                />
                            )}
                        </WithActiveModal>
                    </>
                )}
            </WithMe>
        ),
    },
]

function expandedRowRender(giftCard) {
    return <GiftCardDetails giftCard={giftCard} />
}

function GiftCardsTable({
    giftCards,
    isFetching,
    onSort,
}) {
    return (
        <Table
            rowKey="id"
            rowClassName="cursor-pointer"
            columns={columns}
            loading={isFetching}
            dataSource={giftCards}
            pagination={false}
            expandable={{
                expandedRowRender: expandedRowRender,
                expandRowByClick: true,
                expandIcon: () => null,
                expandIconColumnIndex: -1,
            }}
            onChange={(pagination, filters, sorter) => {
                if (typeof sorter.columnKey !== 'undefined') {
                    onSort(sorterToSortParam(sorter))
                } else {
                    onSort(null)
                }
            }}
        />
    )
}

export default GiftCardsTable

import React from 'react'
import { useAppState } from 'components/hooks/useAppState'
import AppRouter from './AppRouter'
import AppLoading from './AppLoading'
import AppUnavailable from './AppUnavailable'

function App() {
    const appState = useAppState()

    if (appState.isLoading)
        return <AppLoading />

    if (!appState.isAvailable)
        return <AppUnavailable />

    return <AppRouter />
}

export default App

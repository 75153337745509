import React, {useMemo} from "react"
import { WithFormState } from "components/util/forms/WithFormState"
import { isValidateOutstandingAmount } from "util/validation/outstandingAmount"
import { getOutstandingAmountErrors } from "util/validation/outstandingAmountErrors"
import { getEntityFromResponse } from "util/index"
import { PricingRequest } from "../../requests/PricingRequest"

/**
 * @typedef {Object} OutstandingAmountFormValues
 * @property {number|null=} outstandingAmount
 */

/**
 * @param {Object} props
 * @param {() => void} props.onCancel
 * @param {Function} props.onEditSuccess
 * @param {BookingResource} props.booking
 * @param {(props: WithFormStateChildProps) => React.Element|React.Element[]|null=} props.children
 * @return {JSX.Element}
 */
export function OutstandingAmountFormState(props) {
    if (typeof props.booking === "undefined" || props.booking === null)
        return null

    return (
        <PricingRequest>
            {({
                updatePrice,
                updatePriceSuccess,
                updatePriceFailed,
            }) => (
                <WithFormState
                    key={props.booking.id}
                    initialValues={{
                        outstandingAmount: props.booking.outstandingAmount,
                    }}
                    isValidForm={(values) => {
                        const isValid = isValidateOutstandingAmount(values)
                        console.log("isValid", isValid)
                        return isValid
                    }}
                    getFormErrors={getOutstandingAmountErrors}
                    onCancel={props.onCancel}
                    onSendRequest={(values) => updatePrice(props.booking.id, values)}
                    onRequestSuccess={(response) => {
                        const updatedBooking = getEntityFromResponse(response, "bookings")
                        updatePriceSuccess(updatedBooking)
                        props.onEditSuccess()
                    }}
                    onRequestFailed={updatePriceFailed}
                >
                    {props.children}
                </WithFormState>
            )}
        </PricingRequest>
    )
}

import React from 'react'
import {
    Form, Card, Input, Button,
} from 'antd'
import FormItem from 'components/util/forms/FormItem'

function ResetPassword({
    values,
    errors,
    handleNativeChange,
    onSubmit,
    loading,
}) {
    return (
        <Card title="Reset Password" style={{ width: 400 }}>
            <Form onFinish={onSubmit} className="login-form">
                <FormItem
                    error={errors.email}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="E-Mail"
                >
                    <Input
                        placeholder="E-Mail"
                        name="email"
                        value={values.email}
                        onChange={handleNativeChange}
                        autoComplete="email"
                    />
                </FormItem>

                <FormItem
                    error={errors.password}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Password"
                >
                    <Input
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleNativeChange}
                        autoComplete="current-password"
                    />
                </FormItem>

                <FormItem
                    error={errors.passwordConfirmation}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Confirm password"
                >
                    <Input
                        placeholder="Confirm password"
                        type="password"
                        name="passwordConfirmation"
                        value={values.passwordConfirmation}
                        onChange={handleNativeChange}
                        autoComplete="confirm-password"
                    />
                </FormItem>

                <Button
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                >
                    Reset Password
                </Button>
            </Form>
        </Card>
    )
}

export default ResetPassword

import React, { useEffect } from 'react'
import useFormState from 'use-form-state'
import { message } from 'antd'
import useUnauthorizedState from 'components/hooks/useUnauthorizedState'
import { useParams, useNavigate } from 'react-router'
import useApi, { METHODS } from 'components/hooks/useApi'
import handleApiErrorResponse from 'util/errorResponse'
import ResetPassword from './ResetPassword'

function ResetPasswordWithState() {
    const { setEmail } = useUnauthorizedState()
    const params = useParams()
    const navigate = useNavigate()

    const {
        values,
        errors,
        handleNativeChange,
        updateErrors,
    } = useFormState({
        email: params.email,
        password: '',
        passwordConfirmation: '',
    })
    const {
        email,
        password,
        passwordConfirmation,
    } = values
    const [
        resetPassword, { loading },
    ] = useApi('/password/token', { method: METHODS.POST })

    // Update global unauthorizedState
    useEffect(() => {
        setEmail(email)
    }, [setEmail, email])

    const onSubmit = async () => {
        try {
            updateErrors({})
            const { status } = await resetPassword(null, {
                token: params.token,
                email,
                password,
                passwordConfirmation,
            })

            message.success(status)
            navigate('/')
        } catch (error) {
            handleApiErrorResponse(error, updateErrors)
        }
    }

    return (
        <ResetPassword
            values={values}
            errors={errors}
            handleNativeChange={handleNativeChange}
            onSubmit={onSubmit}
            loading={loading}
        />
    )
}

export default ResetPasswordWithState

import React from 'react'
import { Select } from 'antd'

const { Option } = Select

function MultiGamesSelect({
    games,
    value,
    disabled,
    onChange,
}) {
    return (
        <Select
            mode="multiple"
            onChange={onChange}
            placeholder="Select games"
            value={value}
            disabled={disabled}
            filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
        >
            {games.map((game) => (
                <Option
                    key={game.id}
                    value={game.id}
                >
                    {game.title}
                </Option>
            ))}
        </Select>
    )
}

export default MultiGamesSelect

import React from 'react'
import EditCouponTimeFrameFormState from
    'components/pages/settings/coupons/forms/edit-time-frame/EditCouponTimeFrameFormState'
import EditCouponTimeFrameModal from './EditCouponTimeFrameModal'
import EditCouponTimeFrameModalState from './EditCouponTimeFrameModalState'

function EditCouponTimeFrameModalWithState() {
    return (
        <EditCouponTimeFrameModalState>
            {({
                isOpen,
                closeModal,
                couponTimeFrame,
            }) => (
                <EditCouponTimeFrameFormState
                    onEditSuccess={closeModal}
                    onCancel={closeModal}
                    couponTimeFrame={couponTimeFrame}
                >
                    {({
                        values,
                        errors,
                        isLoading,
                        mergeValues,
                        handleChange,
                        handleChangeAsValue,
                        handleCancel,
                        handleSubmit,
                    }) => (
                        <EditCouponTimeFrameModal
                            isOpen={isOpen}
                            onClose={closeModal}
                            couponTimeFrame={values}
                            errors={errors}
                            isLoading={isLoading}
                            onChange={handleChange}
                            onChangeAsValue={handleChangeAsValue}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                            mergeValues={mergeValues}
                        />
                    )}
                </EditCouponTimeFrameFormState>
            )}
        </EditCouponTimeFrameModalState>
    )
}
export default EditCouponTimeFrameModalWithState

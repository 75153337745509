import React from 'react'
import { Modal } from 'antd'
import { ModalFooter } from 'components/util/modals/ModalFooter'
import EditPriceAdjustmentForm from
    'components/pages/settings/venue-games/forms/edit-price-adjustment/EditPriceAdjustmentForm'

function EditPriceAdjustmentModal({
    isOpen,
    onClose,
    priceAdjustment,
    errors,
    isLoading,
    mergeValues,
    onChange,
    onChangeAsValue,
    onCancel,
    onSubmit,
    priceSystem,
}) {
    return (
        <Modal
            title="Edit price adjustment"
            open={isOpen}
            onOk={onSubmit}
            onCancel={onClose}
            closable={!isLoading}
            maskClosable={!isLoading}
            footer={(
                <ModalFooter
                    isLoading={isLoading}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                />
            )}
        >
            {isOpen
      && (
          <EditPriceAdjustmentForm
              isLoading={isLoading}
              values={priceAdjustment}
              errors={errors}
              mergeValues={mergeValues}
              onChange={onChange}
              onChangeAsValue={onChangeAsValue}
              priceSystem={priceSystem}
          />
      )}
        </Modal>
    )
}

export default EditPriceAdjustmentModal

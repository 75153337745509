import dotProp from 'dot-prop-immutable'
import { SET_BOOKING_ACTIVE, SET_BOOKING_INACTIVE } from '../../actions/bookings'

const activeBookingStatusReducer = (state, action) => {
    const { type, bookingId } = action
    switch (type) {
        case SET_BOOKING_ACTIVE: {
            return dotProp.set(state, 'entities.bookings', (bookings) => Object
                .keys(bookings)
                .map((id) => bookings[id])
                .map((booking) => ({
                    ...booking,
                    isActive: booking.id === bookingId,
                }))
                .reduce((acc, curr) => {
                    acc[curr.id] = curr
                    return acc
                }, {}))
        }
        case SET_BOOKING_INACTIVE: {
            return dotProp.set(state, `entities.bookings.${bookingId}.isActive`, false)
        }
        default:
            return state
    }
}

export default activeBookingStatusReducer

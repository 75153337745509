import React from 'react'
import { deleteVenueGame, fetchVenueGamesExtendCurrentParams } from 'store/actions/venueGames'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    deleteVenueGame: async (venueGame) => dispatch(deleteVenueGame(venueGame.id)),
    deleteVenueGameSuccess: () => {
        notification.success({
            message: 'Game removed!',
            description: (
                <span>
                    The game was succesfully removed for this venue.
                </span>
            ),
        })
    },
    deleteVenueGameFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchVenueGames: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchVenueGamesExtendCurrentParams(paginationKey))
    },
})

const DeleteVenueGameRequest = withReduxState(null, mapDispatchToProps)

export default DeleteVenueGameRequest

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-lone-blocks */
/* eslint-disable max-len */
import React, { useState } from 'react'
import {
    Modal,
} from 'antd'
import './bookingRegisteredPlayerModal.css'
import FormItem from 'components/util/forms/FormItem'

/**
 * @typedef {Object} DeleteBookingRegisteredPlayerModalProps
 * @property {boolean} isModalOpen
 * @property {() => void} onCancel
 * @property {(firstName: string, lastName: string, email: string, nickname: string) => void} onSubmit
 * @property {{firstName: string, lastName: string}} playerDetails
 * @definition Modal to delete a registered player from a booking
*/

/**
 * @param {DeleteBookingRegisteredPlayerModalProps} props
 */

function DeleteBookingRegisteredPlayerModal(props) {
    const {
        isModalOpen,
        onCancel,
        onSubmit,
        playerDetails,
    } = props

    const [errors, setErrors] = useState({
        general: '',
    })

    const onOkClick = async () => {
        // wait for the onSubmit function to finish
        const onSubmitReturn = await onSubmit()

        // if the onSubmit function returns an error, set the error messages
        if (typeof onSubmitReturn !== 'undefined') {
            if (typeof onSubmitReturn.errors !== 'undefined') {
                setErrors(onSubmitReturn.errors)
            }
        }
    }

    const onCancelClick = () => {
        onCancel()
    }

    return (
        <Modal
            title="Delete a registered player."
            open={isModalOpen}
            okText="Delete"
            onOk={onOkClick}
            onCancel={onCancelClick}
        >
            <div>
                Are you sure you want to delete <b>{playerDetails.firstName} { playerDetails.lastName}</b> from this booking?
            </div>
            {
                errors.general !== '' ? (
                    <FormItem
                        error={errors.general}
                    />
                ) : null
            }
        </Modal>
    )
}

export default DeleteBookingRegisteredPlayerModal

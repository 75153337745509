import createEntityReducer from '../hor/createEntityReducer'
import {
    COUPON_BATCH_REQUEST,
    COUPON_BATCH_SUCCESS,
    COUPON_BATCH_FAILURE,
} from '../../actions/coupons'

const couponBatchesReducer = createEntityReducer({
    types: [
        COUPON_BATCH_REQUEST,
        COUPON_BATCH_SUCCESS,
        COUPON_BATCH_FAILURE,
    ],
})

export default couponBatchesReducer

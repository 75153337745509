import { isRequired } from './index'

export function isValidGame(game) {
    return isRequired(game) && typeof game === 'number'
}

export function isValidRoom(room) {
    return isRequired(room)
}

export function isValidDate(date) {
    return isRequired(date)
}

export function isValidStart(roomStart) {
    return isRequired(roomStart)
}

/**
 * @param game
 * @param room
 * @param date
 * @param roomStart
 * @return {boolean}
 */
export function isValidGameSession({
    game,
    room,
    date,
    roomStart,
}) {
    return isValidDate(date)
        && isValidGame(game)
        && isValidRoom(room)
        && isValidStart(roomStart)
}

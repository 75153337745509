import React from 'react'
import { Form, Input } from 'antd'
import { capitalize } from 'util/index'
import FormItem from 'components/util/forms/FormItem'
import BlockTable from '../BlockTable'

function EditDiscountBannerForm({
    language,
    values,
    errors,
    onChange,
    onChangeAsValue,
    addBlock,
}) {
    const lang = capitalize(language)
    return (
        <Form>
            <FormItem
                label="Title"
                error={errors[`title${lang}`]}
                required
            >
                <Input
                    name={`title${lang}`}
                    value={values[`title${lang}`]}
                    onChange={onChange}
                    placeholder="Title"
                />
            </FormItem>
            <FormItem
                label="Subtitle"
                error={errors[`subTitle${lang}`]}
                required
            >
                <Input
                    name={`subTitle${lang}`}
                    value={values[`subTitle${lang}`]}
                    onChange={onChange}
                    placeholder="Subtitle"
                />
            </FormItem>

            <BlockTable
                lang={lang}
                onChangeAsValue={onChangeAsValue}
                values={values}
                errors={errors}
                addBlock={addBlock}
            />
        </Form>
    )
}

export default EditDiscountBannerForm

export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

export const openModal = (activeModalId, entity) => ({
    type: OPEN_MODAL,
    activeModalId,
    entity,
})

export const closeModal = () => ({
    type: CLOSE_MODAL,
})

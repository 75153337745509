import createEntityReducer from '../hor/createEntityReducer'
import {
    USER_REQUEST,
    USER_SUCCESS,
    USER_FAILURE,
} from '../../actions/users'

const usersReducer = createEntityReducer({
    types: [
        USER_REQUEST,
        USER_SUCCESS,
        USER_FAILURE,
    ],
})

export default usersReducer

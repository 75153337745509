import React from 'react'
import { Modal } from 'antd'
import { ModalFooter } from 'components/util/modals/ModalFooter'
import { CreateVoucherFormState } from '../forms/create/CreateVoucherFormState'
import { CreateVoucherForm } from '../forms/create/CreateVoucherForm'

/**
 * @param {Object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.closeModal
 * @param {number} props.activeLegalEntityId
 * @param {Object[]} props.legalEntities
 * @param {Object[]} props.venues
 * @param {Object[]} props.voucherIssueReasons
 * @returns {React.JSX.Element}
 * @constructor
 */
export function CreateVoucherModal(props) {
    if (!props.isOpen)
        return null

    return (
        <CreateVoucherFormState
            activeLegalEntityId={props.activeLegalEntityId}
            onCreateVoucherSuccess={() => props.closeModal()}
            cancel={props.closeModal}
        >
            {({
                values,
                errors,
                isLoading,
                isSubmitted,
                handleChange,
                handleSubmit,
                handleCancel,
                handleChangeAsValue,
                mergeValues,
            }) => (
                <Modal
                    title="Create new voucher"
                    open={props.isOpen}
                    onCancel={handleCancel}
                    onOk={handleSubmit}
                    closable={!isLoading}
                    maskClosable={!isLoading}
                    footer={(
                        <ModalFooter
                            isLoading={isLoading}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                        />
                    )}
                >
                    <CreateVoucherForm
                        isLoading={isLoading}
                        isSubmitted={isSubmitted}
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onChangeAsValue={handleChangeAsValue}
                        mergeValues={mergeValues}
                        legalEntities={props.legalEntities}
                        venues={props.venues}
                        voucherIssueReasons={props.voucherIssueReasons}
                    />
                </Modal>
            )}
        </CreateVoucherFormState>
    )
}

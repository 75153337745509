import React from 'react'
import { Form, InputNumber } from 'antd'
import Entity from 'components/util/Entity'
import FormItem from 'components/util/forms/FormItem'
import { PriceInput } from 'components/util/forms/PriceInput'
import { PLAYER_AND_TOTAL } from 'util/constants'
import GamesSelect from '../GamesSelect'

function CreateVenueGameForm({
    values,
    errors,
    mergeValues,
    onChangeAsValue,
    legalEntity,
}) {
    return (
        <Form>
            <Entity entityKey="games" id={values.gameId}>
                {({ refreshEntity }) => (
                    <>
                        <FormItem
                            label="Game"
                            error={errors.gameId}
                            required
                            className="full-width"
                        >
                            <GamesSelect
                                value={values.gameId}
                                onChange={(gameId) => {
                                    const {
                                        totalPrice,
                                        playerPrice,
                                        minPlayers,
                                        maxPlayers,
                                    } = refreshEntity(gameId)
                                    const basePlayerPrice = legalEntity.priceSystem === PLAYER_AND_TOTAL
                                        ? playerPrice[legalEntity.currencyCode]
                                        : null
                                    mergeValues({
                                        gameId,
                                        minPlayers,
                                        maxPlayers,
                                        baseTotalPrice: totalPrice[legalEntity.currencyCode],
                                        basePlayerPrice,
                                    })
                                }}
                            />
                        </FormItem>
                        <FormItem
                            label="Player price"
                            error={errors.basePlayerPrice}
                            required
                        >
                            <PriceInput
                                name="basePlayerPrice"
                                value={values.basePlayerPrice}
                                disabled={typeof values.gameId === 'undefined'}
                                onChange={(value) => onChangeAsValue('basePlayerPrice', value)}
                            />
                        </FormItem>
                        {legalEntity.priceSystem === PLAYER_AND_TOTAL && (
                            <FormItem
                                label="Total price"
                                error={errors.baseTotalPrice}
                                required
                            >
                                <PriceInput
                                    name="baseTotalPrice"
                                    value={values.baseTotalPrice}
                                    disabled={typeof values.gameId === 'undefined'}
                                    onChange={(value) => onChangeAsValue('baseTotalPrice', value)}
                                />
                            </FormItem>
                        )}
                        <FormItem
                            label="Min. players"
                            error={errors.minPlayers}
                            required
                        >
                            <InputNumber
                                name="minPlayers"
                                value={values.minPlayers}
                                onChange={(value) => onChangeAsValue('minPlayers', value)}
                                disabled={typeof values.gameId === 'undefined'}
                                min={0}
                                step={1}
                            />
                        </FormItem>
                        <FormItem
                            label="Max. players"
                            error={errors.maxPlayers}
                            required
                        >
                            <InputNumber
                                name="maxPlayers"
                                value={values.maxPlayers}
                                onChange={(value) => onChangeAsValue('maxPlayers', value)}
                                disabled={typeof values.gameId === 'undefined'}
                                min={1}
                                step={1}
                            />
                        </FormItem>
                    </>
                )}
            </Entity>
        </Form>
    )
}

export default CreateVenueGameForm

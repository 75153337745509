import createPaginationReducer from '../hor/createPaginationReducer'
import {
    COUPON_BATCHES_REQUEST,
    COUPON_BATCHES_SUCCESS,
    COUPON_BATCHES_FAILURE,
} from '../../actions/coupons'

const couponBatchesPaginationReducer = createPaginationReducer({
    mapActionToKey: (action) => action.viewName,
    types: [
        COUPON_BATCHES_REQUEST,
        COUPON_BATCHES_SUCCESS,
        COUPON_BATCHES_FAILURE,
    ],
})

export default couponBatchesPaginationReducer

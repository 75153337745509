/**
 * Returns if value is a valid string
 * @param {string|undefined|null=} str
 * @return {boolean}
 */
export function isValidString(str) {
    return typeof str === "string" && str.length > 0
}

/**
 * @param {string} str
 * @return {string}
 */
export function capitalizeFirstChar(str) {
    return str[0].toUpperCase() + str.slice(1)
}
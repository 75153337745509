import React from 'react'
import { deleteCoupon, fetchCouponsExtendCurrentParams } from 'store/actions/coupons'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    deleteCoupon: (coupon) => dispatch(deleteCoupon(coupon.id)),
    deleteCouponSuccess: (coupon) => {
        notification.success({
            message: 'Coupon code deleted!',
            description: (
                <span>
                    Coupon&nbsp;
                    <strong>{coupon && coupon.code}</strong>
                    &nbsp;
                    is succesfully deleted.
                </span>
            ),
        })
    },
    deleteCouponFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchCoupons: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchCouponsExtendCurrentParams(paginationKey))
    },
})

const DeleteCouponRequest = withReduxState(null, mapDispatchToProps)

export default DeleteCouponRequest

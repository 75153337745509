import React, { useState } from 'react'
import { getEntitiesFromResponse, whereIsNotId } from 'util/index'
import Rooms from 'components/pages/settings/venue/Rooms'
import EquipmentSets from 'components/pages/settings/equipment-sets/table/EquipmentSets'
import Mount from 'components/util/Mount'
import Loading from 'components/util/Loading'
import GamesForVenue from 'components/pages/settings/games/GamesForActiveVenue'
import DeleteRoomModalWithRedux from 'components/pages/settings/rooms/modals/delete/DeleteRoomModalWithRedux'
import CreateRoomModalWithState from 'components/pages/settings/rooms/modals/create/CreateRoomModalWithState'
import RoomsTabsWithState from './RoomsTabsWithState'
import NoRooms from './NoRooms'

const PAGE_SIZE = 100

function RoomsOverview({
    paginationKey,
}) {
    const [activeTabKey, setActiveTabKey] = useState('')

    return (
        <>
            <GamesForVenue paginationKey={paginationKey} limit={PAGE_SIZE}>
                {(games) => (
                    <Rooms paginationKey={paginationKey} limit={PAGE_SIZE}>
                        {(rooms) => (
                            <EquipmentSets paginationKey={paginationKey} limit={PAGE_SIZE}>
                                {(equipmentSets) => {
                                    if (rooms.isFetching) {
                                        return (
                                            <Mount didMount={rooms.fetchRooms}>
                                                <Loading />
                                            </Mount>
                                        )
                                    }

                                    return (
                                        <Mount
                                            didMount={async () => {
                                                const [
                                                    roomsSuccessAction,
                                                ] = await Promise.all([
                                                    rooms.fetchRooms(),
                                                    equipmentSets.fetchEquipmentSets(),
                                                    games.fetchGames(),
                                                ])
                                                const [firstRoom] = getEntitiesFromResponse(roomsSuccessAction.response, 'rooms')
                                                if (typeof firstRoom !== 'undefined') {
                                                    setActiveTabKey(`${firstRoom.id}`)
                                                }
                                            }}
                                        >
                                            {
                                                rooms.roomsForCurrentPage.length <= 0 ? (
                                                    <NoRooms />
                                                ) : (
                                                    <>
                                                        <RoomsTabsWithState
                                                            activeTabKey={activeTabKey}
                                                            rooms={rooms.roomsForCurrentPage}
                                                            games={games.gamesForCurrentPage}
                                                            equipmentSets={equipmentSets.equipmentSetsForCurrentPage}
                                                            onChangeTab={setActiveTabKey}
                                                        />
                                                        <DeleteRoomModalWithRedux
                                                            onDeleteSuccess={(roomId) => {
                                                                const activeRoom = rooms.roomsForCurrentPage.find(whereIsNotId(roomId))
                                                                const activeRoomId = typeof activeRoom !== 'undefined' ? activeRoom.id : null
                                                                setActiveTabKey(`${activeRoomId}`)
                                                            }}
                                                        />
                                                    </>
                                                )
                                            }
                                        </Mount>
                                    )
                                }}
                            </EquipmentSets>
                        )}
                    </Rooms>
                )}
            </GamesForVenue>
            <CreateRoomModalWithState
                onCreateSuccess={({ id }) => setActiveTabKey(`${id}`)}
            />
        </>
    )
}

export default RoomsOverview

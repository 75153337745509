import React from 'react'
import DeleteUserFormState from 'components/pages/settings/users/forms/delete/DeleteUserFormState'
import { ConfirmInputModal } from 'components/util/modals/ConfirmInputModal'

function DeleteUserModal({ isOpen, entity, onClose }) {
    return (
        <DeleteUserFormState
            onDeleteSuccess={onClose}
            onCancel={onClose}
            values={entity}
        >
            {({
                isLoading,
                handleSubmit,
                handleCancel,
            }) => {
                if (entity === null || typeof entity === 'undefined') {
                    return null
                }
                return (
                    <ConfirmInputModal
                        safeWord={entity.firstName}
                        title={`Are you sure you want to delete ${entity.firstName}?`}
                        isOpen={isOpen}
                        onConfirm={handleSubmit}
                        onCancel={handleCancel}
                        confirmText={`Delete ${entity.firstName}`}
                        confirmType="primary"
                        confirmIsDanger={true}
                        cancelText="Cancel"
                        isLoading={isLoading}
                        close={onClose}
                    >
                        <p>
                            Removing a user cannot be undone.
                            {' '}
                            <br />
                            Please type the exact name of the user to confirm.
                        </p>
                    </ConfirmInputModal>
                )
            }}
        </DeleteUserFormState>
    )
}

export default DeleteUserModal

import React from 'react'
import EditVenueFormState from 'components/pages/settings/venues/forms/edit/EditVenueFormState'
import EditVenueModalState from './EditVenueModalState'
import EditVenueModal from './EditVenueModal'

function EditVenueModalWithState() {
    return (
        <EditVenueModalState>
            {({
                isOpen,
                closeModal,
                venue,
            }) => (
                <EditVenueFormState
                    onEditSuccess={closeModal}
                    onCancel={closeModal}
                    venue={venue}
                >
                    {({
                        values,
                        errors,
                        isLoading,
                        handleChange,
                        handleChangeAsValue,
                        handleCancel,
                        handleSubmit,
                    }) => (
                        <EditVenueModal
                            isOpen={isOpen}
                            onClose={closeModal}
                            venue={values}
                            errors={errors}
                            isLoading={isLoading}
                            onChange={handleChange}
                            onChangeAsValue={handleChangeAsValue}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                        />
                    )}
                </EditVenueFormState>
            )}
        </EditVenueModalState>
    )
}

export default EditVenueModalWithState

import React from 'react'
import ReactDom from 'react-dom'
import CopyClipboard from 'clipboard'
import { Tooltip } from 'antd'

class Clipboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hasCopied: false,
        }
    }

    componentDidMount() {
        const { text, onCopy } = this.props
        // eslint-disable-next-line react/no-find-dom-node
        const target = ReactDom.findDOMNode(this)
        this.clipboard = new CopyClipboard(target, { text: () => text })
        this.clipboard.on('success', (e) => {
            if (typeof onCopy === 'function') {
                onCopy(e.text)
            }
            this.setState({
                hasCopied: true,
            })
            setTimeout(() => {
                this.setState({
                    hasCopied: false,
                })
            }, 2000)
        })
    }

    componentWillUnmount() {
        this.clipboard.destroy()
    }

    render() {
        const { children, tooltip } = this.props
        const { hasCopied } = this.state
        return (
            <Tooltip placement="top" title={hasCopied ? 'Copied!' : tooltip}>
                <span style={{ cursor: 'pointer' }}>{children}</span>
            </Tooltip>
        )
    }
}

export default Clipboard

import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import { MODAL_IDS } from 'util/constants'

function EditPriceAdjustmentModalState({ priceAdjustment, children }) {
    return (
        <WithActiveModal
            modalId={MODAL_IDS.EDIT_PRICE_ADJUSTMENT}
            entity={priceAdjustment}
            entityKey="priceAdjustment"
        >
            {children}
        </WithActiveModal>
    )
}

export default EditPriceAdjustmentModalState

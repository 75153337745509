import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import { isValidGiftCard } from 'util/validation/giftCard'
import { getGiftCardErrors } from 'util/validation/giftCardErrors'
import { getEntityFromResponse } from 'util/index'
import { toCreateGiftCardApiRequest } from 'util/transformers/giftCard'
import CreateGiftCardRequest from './CreateGiftCardRequest'

function CreateGiftCardFormState({
    onCancel,
    onSaveSuccess,
    children,
    activeLegalEntity,
}) {
    return (
        <CreateGiftCardRequest paginationKey="overview">
            {({
                createGiftCard,
                fetchGiftCards,
                createGiftCardSuccess,
                createGiftCardFailed,
            }) => (
                <WithFormState
                    initialValues={{
                        amount: 0,
                        email: '',
                        firstName: '',
                        lastName: '',
                        recipientName: '',
                        message: '',
                        isCompany: false,
                        sendEmail: false,
                        legalEntityId: activeLegalEntity.id,
                        purchase_order_number: undefined,
                    }}
                    isValidForm={isValidGiftCard}
                    getFormErrors={getGiftCardErrors}
                    transformValuesToRequestBody={toCreateGiftCardApiRequest}
                    onSendRequest={createGiftCard}
                    onCancel={onCancel}
                    onRequestSuccess={(response) => {
                        const giftCard = getEntityFromResponse(response, 'giftCards')
                        fetchGiftCards()
                        createGiftCardSuccess(giftCard)
                        onSaveSuccess(giftCard)
                    }}
                    onRequestFailed={createGiftCardFailed}
                >
                    {children}
                </WithFormState>
            )}
        </CreateGiftCardRequest>
    )
}

export default CreateGiftCardFormState

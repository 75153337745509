// eslint-disable-next-line import/no-cycle
import { getPaginationKey } from 'store/reducers/pagination/gameSessionsPaginationReducer'
import { CALL_API, METHODS } from '../middleware/api'
import {
    gameSessionSchema,
    gameSessionSchemaArray,
} from '../schemas/gameSessions'

export const GAME_SESSIONS_REQUEST = 'GAME_SESSIONS_REQUEST'
export const GAME_SESSIONS_SUCCESS = 'GAME_SESSIONS_SUCCESS'
export const GAME_SESSIONS_FAILURE = 'GAME_SESSIONS_FAILURE'

export const GAME_SESSION_REQUEST = 'GAME_SESSION_REQUEST'
export const GAME_SESSION_SUCCESS = 'GAME_SESSION_SUCCESS'
export const GAME_SESSION_FAILURE = 'GAME_SESSION_FAILURE'

export const SET_GAME_SESSION_ACTIVE = 'SET_GAME_SESSION_ACTIVE_SUCCESS'
export const SET_GAME_SESSION_INACTIVE = 'SET_GAME_SESSION_INACTIVE_SUCCESS'

export const fetchGameSessions = (componentName, venueId, roomId, params) => {
    let endpoint
    if (roomId === null) {
        endpoint = `/venues/${venueId}/game-sessions`
    } else {
        endpoint = `/venues/${venueId}/rooms/${roomId}/game-sessions`
    }
    return ({
        componentName,
        venueId,
        roomId,
        [CALL_API]: {
            types: [
                GAME_SESSIONS_REQUEST,
                GAME_SESSIONS_SUCCESS,
                GAME_SESSIONS_FAILURE,
            ],
            endpoint,
            schema: gameSessionSchemaArray,
            params,
        },
    })
}

export const fetchGameSessionsForActiveVenue = (componentName, params) => (dispatch, getState) => {
    const { venueId, roomId } = getState().activeFilters
    return dispatch(fetchGameSessions(componentName, venueId, roomId, params))
}

export const fetchGameSessionsExtendCurrentParams = (paginationKey, params) => (dispatch, getState) => {
    const { venueId, roomId } = getState().activeFilters
    const gameSessionPagination = getState().pagination.gameSessions[getPaginationKey(paginationKey, venueId)]
    const newParams = {
        ...gameSessionPagination.params,
        ...params,
    }
    return dispatch(fetchGameSessions(paginationKey, venueId, roomId, newParams))
}

export const fetchGameSession = (venueId, gameSessionId) => ({
    [CALL_API]: {
        types: [
            GAME_SESSION_REQUEST,
            GAME_SESSION_SUCCESS,
            GAME_SESSION_FAILURE,
        ],
        endpoint: `/venues/${venueId}/game-sessions/${gameSessionId}`,
        schema: gameSessionSchema,
        entityId: gameSessionId,
    },
})

export const fetchGameSessionForActiveVenue = (gameSessionId) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch(fetchGameSession(venueId, gameSessionId))
}

export const createGameSession = (venueId, body) => ({
    [CALL_API]: {
        method: METHODS.POST,
        types: [
            GAME_SESSION_REQUEST,
            GAME_SESSION_SUCCESS,
            GAME_SESSION_FAILURE,
        ],
        endpoint: `/venues/${venueId}/game-sessions`,
        schema: gameSessionSchema,
        body,
    },
})

export const createGameSessionForActiveVenue = (body) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch(createGameSession(venueId, body))
}

export const editGameSession = (gameSessionId, body) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch({
        [CALL_API]: {
            method: METHODS.PUT,
            types: [
                GAME_SESSION_REQUEST,
                GAME_SESSION_SUCCESS,
                GAME_SESSION_FAILURE,
            ],
            endpoint: `/venues/${venueId}/game-sessions/${gameSessionId}?XDEBUG_SESSION_START=PHPSTORM`,
            schema: gameSessionSchema,
            entityId: gameSessionId,
            body,
        },
    })
}

export const setGameSessionActive = (gameSessionId, roomId) => ({
    type: SET_GAME_SESSION_ACTIVE,
    gameSessionId,
    roomId,
})

export const setGameSessionInactive = (gameSessionId) => ({
    type: SET_GAME_SESSION_INACTIVE,
    gameSessionId,
})

export const deleteGameSession = (venueId, gameSessionId) => ({
    [CALL_API]: {
        method: METHODS.DELETE,
        types: [
            GAME_SESSION_REQUEST,
            GAME_SESSION_SUCCESS,
            GAME_SESSION_FAILURE,
        ],
        endpoint: `/venues/${venueId}/game-sessions/${gameSessionId}`,
        schema: gameSessionSchema,
        entityId: gameSessionId,
    },
})

export const deleteGameSessionForActiveVenue = (gameSessionId) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch(deleteGameSession(venueId, gameSessionId))
}

import React from 'react'
import { getEntityFromResponse } from 'util/index'
import { WithFormState } from 'components/util/forms/WithFormState'
import DeleteVenueGameRequest from './DeleteVenueGameRequest'

function DeleteVenueGameFormState({
    onDeleteSuccess,
    onCancel,
    values,
    children,
}) {
    return (
        <DeleteVenueGameRequest paginationKey="venueGamesOverview">
            {({
                deleteVenueGame,
                fetchVenueGames,
                deleteVenueGameSuccess,
                deleteVenueGameFailed,
            }) => (
                <WithFormState
                    transformValuesToRequestBody={() => values}
                    onSendRequest={() => deleteVenueGame(values)}
                    onCancel={onCancel}
                    onRequestSuccess={(response) => {
                        const venueGame = getEntityFromResponse(response, 'venueGames')
                        const venueGameId = venueGame.id
                        fetchVenueGames()
                        deleteVenueGameSuccess()
                        onDeleteSuccess(venueGameId)
                    }}
                    onRequestFailed={deleteVenueGameFailed}
                >
                    {children}
                </WithFormState>
            )}
        </DeleteVenueGameRequest>
    )
}

export default DeleteVenueGameFormState

import React from 'react'
import { Modal } from 'antd'
import { ModalFooter } from 'components/util/modals/ModalFooter'
import EditGiftCardForm from 'components/pages/settings/gift-cards/forms/edit/EditGiftCardForm'

function EditGiftCardModal({
    isOpen,
    onClose,
    giftCard,
    errors,
    isLoading,
    onChange,
    onChangeAsValue,
    onCancel,
    onSubmit,
}) {
    return (
        <Modal
            title="Edit gift card"
            open={isOpen}
            onOk={onSubmit}
            onCancel={onClose}
            closable={!isLoading}
            maskClosable={!isLoading}
            footer={(
                <ModalFooter
                    isLoading={isLoading}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                />
            )}
        >
            {isOpen
      && (
          <EditGiftCardForm
              isLoading={isLoading}
              values={giftCard}
              errors={errors}
              onChange={onChange}
              onChangeAsValue={onChangeAsValue}
          />
      )}
        </Modal>
    )
}

export default EditGiftCardModal

import createEntityReducer from '../hor/createEntityReducer'
import {
    DISCOUNT_BANNER_REQUEST,
    DISCOUNT_BANNER_SUCCESS,
    DISCOUNT_BANNER_FAILURE,
} from '../../actions/discountBanners'

const discountBannerReducer = createEntityReducer({
    types: [
        DISCOUNT_BANNER_REQUEST,
        DISCOUNT_BANNER_SUCCESS,
        DISCOUNT_BANNER_FAILURE,
    ],
})

export default discountBannerReducer

import { roleSchemaArray } from '../schemas/roles'
import { CALL_API } from '../middleware/api'

export const ROLES_REQUEST = 'ROLES_REQUEST'
export const ROLES_SUCCESS = 'ROLES_SUCCESS'
export const ROLES_FAILURE = 'ROLES_FAILURE'

export const ROLE_REQUEST = 'ROLE_REQUEST'
export const ROLE_SUCCESS = 'ROLE_SUCCESS'
export const ROLE_FAILURE = 'ROLE_FAILURE'

export const fetchRoles = (viewName, params) => ({
    viewName,
    [CALL_API]: {
        types: [
            ROLES_REQUEST,
            ROLES_SUCCESS,
            ROLES_FAILURE,
        ],
        endpoint: '/roles',
        schema: roleSchemaArray,
        params,
    },
})

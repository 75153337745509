import React from 'react'
import EditGameFormState from 'components/pages/settings/games/forms/edit/EditGameFormState'
import { useLegalEntity } from 'components/hooks/useLegalEntity'
import EditGameModal from './EditGameModal'
import EditGameModalState from './EditGameModalState'

function EditGameModalWithState() {
    const legalEntity = useLegalEntity()
    if (typeof legalEntity === 'undefined')
        throw new Error(`Legal Entity is undefined! Comp: ${EditGameModalWithState.name}`)

    return (
        <EditGameModalState>
            {({
                isOpen,
                closeModal,
                game,
            }) => (
                <EditGameFormState
                    onEditSuccess={closeModal}
                    onCancel={closeModal}
                    game={game}
                >
                    {({
                        values,
                        errors,
                        isLoading,
                        handleChange,
                        handleChangeAsValue,
                        handleCancel,
                        handleSubmit,
                    }) => (
                        <EditGameModal
                            isOpen={isOpen}
                            onClose={closeModal}
                            game={values}
                            errors={errors}
                            isLoading={isLoading}
                            onChange={handleChange}
                            onChangeAsValue={handleChangeAsValue}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                            legalEntity={legalEntity}
                        />
                    )}
                </EditGameFormState>
            )}
        </EditGameModalState>
    )
}
export default EditGameModalWithState

import React, {useMemo} from 'react'
import { EditOutlined } from '@ant-design/icons'
import { Card, Col, Button } from 'antd'
import { useAppState } from 'components/hooks/useAppState'
import WithMe from 'components/pages/authorized/WithMe'
import useModal from 'components/hooks/useModal'
import { MODAL_IDS } from 'util/constants'
import './LegalEntityCard.css'

const { Meta } = Card

/**
 * @component
 * @param {Object} props
 * @param {LegalEntityResource} props.legalEntity
 */
export function LegalEntityCard(props) {
    const financialConfig = props.legalEntity.financialConfig
    const { countries, currencies } = useAppState()
    const modalOptions = { entity: props.legalEntity, entityKey: 'legalEntity' }
    const { openModal: openEditModal } = useModal(MODAL_IDS.EDIT_LEGAL_ENTITY, modalOptions)

    const countryName = useMemo(() => {
        const country = countries.find(c => c.code === props.legalEntity.country.code)
        if (typeof country !== "undefined")
            return country.name
        else
            return ''
    }, [countries, props.legalEntity.country.code])

    let currency = ''
    // console.log('currencies:', currencies)
    if (typeof currencies === 'object') {
        const currencyCode = props.legalEntity.country.currencyCode
        if (Object.prototype.hasOwnProperty.call(currencies, currencyCode)) {
            // Get the user-friendly currency name
            currency = currencies[currencyCode].currency
        } else {
            // Use the currency ISO code
            currency = currencyCode
        }
    }

    return (
        <Col style={{ padding: 8 }} md={12} lg={8}>
            <WithMe>
                {({ isAdmin, venues }) => (
                    <Card
                        title={props.legalEntity.country.code + " - " + props.legalEntity.name}
                        cover={(
                            <img
                                className="coverImage"
                                alt={props.legalEntity.title}
                                src={financialConfig.mediaItems[0] && financialConfig.mediaItems[0].urls.medium}
                            />
                        )}
                        extra={(
                            <Button
                                ghost
                                icon={<EditOutlined />}
                                type="primary"
                                shape="circle"
                                size="small"
                                className="ant-btn-no-border"
                                disabled={isAdmin !== true}
                                onClick={openEditModal}
                                style={{ width: '100%' }}
                            />
                        )}
                    >
                        <Meta
                            description={(
                                <div className="le-card-container">
                                    <div className="le-card-item">
                                        <span><strong>Currency:</strong></span>
                                        <span className="txta-right">{currency}</span>
                                    </div>
                                    <div className="le-card-item">
                                        <span><strong>Country:</strong></span>
                                        <span className="txta-right">{countryName}</span>
                                    </div>
                                </div>
                            )}
                        />
                    </Card>
                )}
            </WithMe>
        </Col>
    )
}

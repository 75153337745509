import { decamelize } from 'humps'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

// https://day.js.org/docs/en/plugin/utc
// https://day.js.org/docs/en/plugin/loading-into-nodejs
// Needs to be called, to use .utc to parse dates
dayjs.extend(utc)

export const getStartOfDay = (date) => dayjs.utc(date).startOf('day')
export const getEndOfDay = (date) => dayjs.utc(date).endOf('day')
export const fromToday = dayjs.utc().startOf('day')
export const tillToday = dayjs.utc().endOf('day')
export const tillFuture = dayjs.utc('2999-01-01').endOf('day')

export const delay = (time, value) => new Promise((resolve) => {
    setTimeout(resolve, time, value)
})

export const getEntityFromResponse = (response, entityKey) => response.entities[entityKey][response.result]

export const getEntitiesFromResponse = (response, entityKey) => response.result.map((id) => response.entities[entityKey][id])

export const sorterToSortParam = ({ columnKey, order }) => `${decamelize(columnKey)}.${order === 'descend' ? 'desc' : 'asc'}`

export const capitalize = (value) => value.charAt(0).toUpperCase() + value.slice(1)

export const flatten = (next, array) => [...next, ...array]

export const valueIsSet = (value) => typeof value !== 'undefined' && value !== null && value !== ''

export const objToArray = (obj) => Object.keys(obj).map((k) => obj[k])

/**
 * @param {number} startsAt E.g. 0
 * @param {number} step E.g. 30
 * @returns {number[]}
 */
export function getDisabledMinutes(startsAt, step) {
    const disabledMinutes = []
    let targetStep = startsAt
    for (let i = 0; i < 60; i++) {
        if (i === targetStep) {
            targetStep += step
            continue
        }
        disabledMinutes.push(i)
    }
    return disabledMinutes
}

export const where = (...keys) => (...values) => (obj) => keys.every((key) => (
    key.includes('!')
        ? !values.includes(obj[key.replace('!', '')])
        : values.includes(obj[key])
))
export const whereIsNot = (key) => (value) => (obj) => obj[key] !== value
export const whereId = where('id')
export const whereIsNotId = whereIsNot('id')

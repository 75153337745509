import { deleteUser, fetchUsersExtendCurrentParams } from 'store/actions/users'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    deleteUser: (user) => dispatch(deleteUser(user.id)),
    deleteUserSuccess: (user) => {
        notification.success({
            message: 'User deleted!',
            description: `User ${user.firstName} ${user.lastName} is successfully deleted.`,
        })
    },
    deleteUserFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchUsers: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchUsersExtendCurrentParams(paginationKey))
    },
})

const DeleteUserRequest = withReduxState(null, mapDispatchToProps)

export default DeleteUserRequest

import { useLegalEntity } from 'components/hooks/useLegalEntity'
import React from 'react'
import EditVenueGameForm from './EditVenueGameForm'
import EditVenueGameFormState from './EditVenueGameFormState'

function EditVenueGameFormWithState({
    venueGame,
    paginationKey,
    disabled,
}) {
    const legalEntity = useLegalEntity()
    if (typeof legalEntity === 'undefined')
        throw new Error(`Legal Entity is undefined! Comp: ${EditVenueGameFormWithState.name}`)

    return (
        <EditVenueGameFormState venueGame={venueGame} paginationKey={paginationKey}>
            {({
                values,
                errors,
                handleChange,
                handleChangeAsValue,
                handleSubmit,
            }) => (
                <EditVenueGameForm
                    venueGame={venueGame}
                    values={values}
                    errors={errors}
                    disabled={disabled}
                    onChange={handleChange}
                    onChangeAsValue={handleChangeAsValue}
                    onSubmit={handleSubmit}
                    priceSystem={legalEntity.priceSystem}
                />
            )}
        </EditVenueGameFormState>
    )
}

export default EditVenueGameFormWithState

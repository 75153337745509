import React from 'react'

function SidebarContent({ children }) {
    return (
        <div className="sidebarContent">
            {children}
        </div>
    )
}

export default SidebarContent

import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'

const ID = 'CreateEquipmentSetModal'

function CreateEquipmentSetModalState({ children }) {
    return <WithActiveModal modalId={ID}>{children}</WithActiveModal>
}

export default CreateEquipmentSetModalState

import createPaginationReducer from '../hor/createPaginationReducer'
import {
    LEGAL_ENTITIES_REQUEST,
    LEGAL_ENTITIES_SUCCESS,
    LEGAL_ENTITIES_FAILURE,
} from '../../actions/legalEntities'

const legalEntitiesPaginationReducer = createPaginationReducer({
    mapActionToKey: (action) => action.viewName,
    types: [
        LEGAL_ENTITIES_REQUEST,
        LEGAL_ENTITIES_SUCCESS,
        LEGAL_ENTITIES_FAILURE,
    ],
})

export default legalEntitiesPaginationReducer

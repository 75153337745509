import createPaginationReducer from '../hor/createPaginationReducer'
import {
    EQUIPMENT_SETS_REQUEST,
    EQUIPMENT_SETS_SUCCESS,
    EQUIPMENT_SETS_FAILURE,
} from '../../actions/equipmentSets'

export const getPaginationKey = (componentName, venueId) => `${componentName}(venues/${venueId})`

const equipmentSetsPaginationReducer = createPaginationReducer({
    mapActionToKey: ({ componentName, venueId }) => getPaginationKey(componentName, venueId),
    types: [
        EQUIPMENT_SETS_REQUEST,
        EQUIPMENT_SETS_SUCCESS,
        EQUIPMENT_SETS_FAILURE,
    ],
})

export default equipmentSetsPaginationReducer

import React from 'react'
import { Modal } from 'antd'
import { ModalFooter } from 'components/util/modals/ModalFooter'
import ToggleActiveGameSessionForm from '../../forms/toggle-active/ToggleActiveGameSessionForm'

function ToggleActiveGameSessionModal({
    isOpen,
    onClose,
    gameSession,
    errors,
    isLoading,
    mergeValues,
    onChangeAsValue,
    onCancel,
    onSubmit,
}) {
    return (
        <Modal
            title="Choose a room"
            open={isOpen}
            onOk={onSubmit}
            onCancel={onClose}
            closable={!isLoading}
            maskClosable={!isLoading}
            footer={(
                <ModalFooter
                    isLoading={isLoading}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    submitText="Activate"
                />
            )}
        >
            {
                isOpen && (
                    <ToggleActiveGameSessionForm
                        isLoading={isLoading}
                        values={gameSession}
                        errors={errors}
                        mergeValues={mergeValues}
                        onChangeAsValue={onChangeAsValue}
                        onCreateSuccess={onClose}
                    />
                )
            }
        </Modal>
    )
}

export default ToggleActiveGameSessionModal

import React from "react"
import { Modal } from "antd"
import { ModalFooter } from "components/util/modals/ModalFooter"
import {OutstandingAmountForm} from "../../forms/outstandingAmount/OutstandingAmountForm"

/**
 * @component
 * @param {Object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.onClose
 * @param {OutstandingAmountFormValues} props.values
 * @param {{ [key in keyof CreateOrEditBookingFormValues]: string[]}} props.errors
 * @param {boolean} props.isLoading
 * @param {(key: keyof CreateOrEditBookingFormValues, value: CreateOrEditBookingFormValues[key]) => void } props.onChangeAsValue
 * @param {() => void} props.onCancel
 * @param {(e: any) => void } props.onSubmit
 * @return {React.Element|React.Element[]|null}
 */
export function OutstandingAmountModal(props) {
    return (
        <Modal
            title="Change outstanding amount"
            width={400}
            open={props.isOpen}
            onOk={props.onSubmit}
            onCancel={props.onClose}
            closable={props.isLoading === false}
            maskClosable={props.isLoading === false}
            footer={(
                <ModalFooter
                    isLoading={props.isLoading}
                    onCancel={props.onCancel}
                    onSubmit={props.onSubmit}
                />
            )}
        >
            {
                props.isOpen && (
                    <OutstandingAmountForm
                        isLoading={props.isLoading}
                        values={props.values}
                        errors={props.errors}
                        onChangeAsValue={props.onChangeAsValue}
                    />
                )
            }
        </Modal>
    )
}

import {useCallback, useMemo} from "react"
import callApi from "../../../store/middleware/callApi"

/**
 * @typedef {(gameVenues: GameVenueResource[]| null, errorMessage: string | null) => void} GetGameVenuesByVenueIdCallback
 */

/**
 * @typedef {Object} UseGameVenuesByVenueIdAPIReturnObj
 * @property {(venueId: number, callBack: GetGameVenuesByVenueIdCallback) => Promise<void>} getGameVenuesByVenueId
 */

/**
 * @returns {UseGameVenuesByVenueIdAPIReturnObj}
 */
export function useGameVenuesByVenueIdAPI() {
    /**
     * @type {(function(venueId: number, callback: GetGameVenuesByVenueIdCallback): Promise<void>)|*}
     */
    const getGameVenuesByVenueId = useCallback(async (venueId, callback) => {
        const endpoint = `/venues/${venueId}/games`
        const params = {
            page: 1,
            limit: 100,
        }
        try {
            const response = await callApi(
                "GET",
                endpoint,
                params,
            )
            callback(response.data, null)
        } catch (error) {
            console.error(error)
        }
    }, [])

    const returnObj = useMemo(() => {
        return {
            getGameVenuesByVenueId: getGameVenuesByVenueId,
        }
    }, [getGameVenuesByVenueId])

    return returnObj
}
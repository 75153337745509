import React, { useState } from 'react'
import Mount from 'components/util/Mount'
import PaginationTableBottomBar from 'components/util/table/PaginationTableBottomBar'
import GiftCardsTableTopBar, { ACTIVE_FILTER } from './GiftCardsTableTopBar'
import GiftCardsTable from './GiftCardsTable'
import GiftCards from './GiftCards'

const PAGE_SIZE = 10

function GiftCardsTableWithGiftCards({
    paginationKey,
}) {
    const [activeStatusFilter, setActiveStatus] = useState(ACTIVE_FILTER)
    return (
        <GiftCards
            paginationKey={paginationKey}
            limit={PAGE_SIZE}
        >
            {({
                isFetching,
                currentPage,
                total,
                params,
                giftCardsForCurrentPage,
                fetchGiftCards,
            }) => (
                <Mount
                    activeStatusFilter={activeStatusFilter}
                    onPropsHaveChanged={() => {
                        fetchGiftCards({ ...params, status: activeStatusFilter })
                    }}
                    didMount={() => {
                        fetchGiftCards({ ...params, status: activeStatusFilter })
                    }}
                >
                    <GiftCardsTableTopBar
                        activeStatusFilter={activeStatusFilter}
                        onFilterGiftCards={setActiveStatus}
                        onSearchGiftCards={(search) => {
                            if (search === '') {
                                fetchGiftCards()
                            } else {
                                fetchGiftCards({ ...params, search })
                            }
                        }}
                        searchValue={params.search}
                    />
                    <GiftCardsTable
                        giftCards={giftCardsForCurrentPage}
                        isFetching={isFetching}
                        onSort={(sort) => {
                            if (sort !== null) {
                                fetchGiftCards({ ...params, sort })
                            } else {
                                fetchGiftCards({ ...params })
                            }
                        }}
                    />
                    <PaginationTableBottomBar
                        currentPage={currentPage}
                        total={total}
                        pageSize={params.limit || PAGE_SIZE}
                        onChangePage={(page) => fetchGiftCards({ ...params, page })}
                    />
                </Mount>
            )}
        </GiftCards>
    )
}

export default GiftCardsTableWithGiftCards

/**
 * Rounds number to given amount of decimals
 * @param {number} val Number value to round
 * @param {number} decimals How many decimals places should remain. E.g. 2
 * @returns {number} rounded value.
 */
export function roundNumber(val, decimals) {
    const roundedDecimals = Math.round(decimals)
    if (roundedDecimals < 0 || roundedDecimals === 0)
        return Math.round(val)

    const multiplier = Math.pow(10, roundedDecimals)
    const rounded = Math.round(val * multiplier)
    return rounded / multiplier
}

import React from 'react'
import { getEntityFromResponse } from 'util/index'
import { WithFormState } from 'components/util/forms/WithFormState'
import DeleteGiftCardRequest from './DeleteGiftCardRequest'

function DeleteGiftCardFormState({
    onCancel,
    onDeleteSuccess,
    values,
    children,
}) {
    return (
        <DeleteGiftCardRequest paginationKey="overview">
            {({
                deleteGiftCard,
                fetchGiftCards,
                deleteGiftCardSuccess,
                deleteGiftCardFailed,
            }) => (
                <WithFormState
                    transformValuesToRequestBody={() => values}
                    onSendRequest={() => deleteGiftCard(values)}
                    onCancel={onCancel}
                    onRequestSuccess={(response) => {
                        const giftCard = getEntityFromResponse(response, 'giftCards')
                        fetchGiftCards()
                        deleteGiftCardSuccess(giftCard)
                        onDeleteSuccess(giftCard)
                    }}
                    onRequestFailed={deleteGiftCardFailed}
                >
                    {children}
                </WithFormState>
            )}
        </DeleteGiftCardRequest>
    )
}

export default DeleteGiftCardFormState

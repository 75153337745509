import React from 'react'
import { Modal } from 'antd'
import { ModalFooter } from 'components/util/modals/ModalFooter'
import EditCouponForm from '../../forms/edit/EditCouponForm'
import EditCouponModalFormState from '../../forms/edit/EditCouponFormState'

/**
 * @param {Object} props
 * @param {Object} props.coupon
 * @param {Object[]} props.legalEntities
 * @param {Object} props.activeLegalEntity
 * @param {boolean} props.isOpen
 * @param {() => void} props.onClose
 * @returns {React.JSX.Element}
 * @constructor
 */
function EditCouponModal(props) {
    return (
        <EditCouponModalFormState
            onEditSuccess={props.onClose}
            onCancel={props.onClose}
            coupon={props.coupon}
        >
            {({
                values,
                errors,
                isLoading,
                mergeValues,
                handleChange,
                handleChangeAsValue,
                handleCancel,
                handleSubmit,
            }) => (
                <Modal
                    title="Edit Coupon"
                    open={props.isOpen}
                    onOk={handleSubmit}
                    onCancel={props.onClose}
                    closable={!isLoading}
                    maskClosable={!isLoading}
                    footer={(
                        <ModalFooter
                            isLoading={isLoading}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                        />
                    )}
                >
                    {
                        props.isOpen && (
                            <EditCouponForm
                                isLoading={isLoading}
                                values={values}
                                errors={errors}
                                onChange={handleChange}
                                onChangeAsValue={handleChangeAsValue}
                                legalEntity={props.legalEntities[values.legalEntity] || props.activeLegalEntity}
                            />
                        )
                    }
                </Modal>
            )}
        </EditCouponModalFormState>
    )
}

export default EditCouponModal

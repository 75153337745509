import React from 'react'
import { message } from 'antd'
import { WithFormState } from 'components/util/forms/WithFormState'
import EditGameRequest from 'components/pages/settings/games/forms/edit/EditGameRequest'
import { isValidEditGame, formHasLocaleErrors } from 'util/validation/game'
import { getEditGameErrors } from 'util/validation/gameErrors'
import { getEntityFromResponse } from 'util/index'
import { toGameApiRequest, mapGameToValues } from 'util/transformers/game'
import { useLegalEntity } from 'components/hooks/useLegalEntity'

function EditGameFormState({
    onCancel,
    onEditSuccess,
    children,
    game,
}) {
    const legalEntity = useLegalEntity()
    if (typeof legalEntity === 'undefined')
        throw new Error(`Legal Entity is undefined! Comp: ${EditGameFormState.name}`)

    // console.log('priceSystem', priceSystem)
    // console.log('game', game)

    return (
        <EditGameRequest paginationKey="overview">
            {({
                fetchGames,
                editGame,
                editGameSuccess,
                editGameFailed,
            }) => (
                <WithFormState
                    key={game && game.id}
                    initialValues={game && mapGameToValues(game)}
                    isValidForm={(values) => isValidEditGame(values, legalEntity.priceSystem)}
                    getFormErrors={(values) => getEditGameErrors(values, legalEntity.priceSystem)}
                    transformValuesToRequestBody={toGameApiRequest}
                    onSendRequest={(values) => editGame(game.id, values)}
                    onCancel={onCancel}
                    onRequestSuccess={(response) => {
                        const updatedGame = getEntityFromResponse(response, 'games')
                        fetchGames()
                        editGameSuccess(updatedGame)
                        onEditSuccess(updatedGame)
                    }}
                    onRequestFailed={editGameFailed}
                    onInvalidFormWasSubmitted={(errors) => {
                        if (formHasLocaleErrors(errors)) {
                            message.error('Please fix translation errors.')
                        }
                    }}
                >
                    {children}
                </WithFormState>
            )}
        </EditGameRequest>
    )
}

export default EditGameFormState

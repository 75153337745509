import React from 'react'
import { Select } from 'antd'
import { capitalize } from 'util/index'
import { WEEK_DAYS } from 'util/constants'

function WeekdaySelect({
    value,
    placeholder = 'Select a weekday',
    onChange,
    disabled = false,
}) {
    return (
        <Select
            value={value}
            onChange={onChange}
            disabled={disabled}
            placeholder={placeholder}
        >
            {WEEK_DAYS.map((day) => (
                <Select.Option
                    key={day.id}
                    value={day.id}
                >
                    {capitalize(day.name)}
                </Select.Option>
            ))}
        </Select>
    )
}

export default WeekdaySelect

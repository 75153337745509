import { getEntitiesByPagination, getPagination, getEntitiesForCurrentPage } from "store/util/index"
import { getPaginationKey } from "store/reducers/pagination/bookingsPaginationReducer"
import { fetchBookingsForActiveVenue } from "store/actions/bookings"
import withReduxState from "components/hoc/withReduxState"

const mapStateToProps = (state, ownProps) => {
    const { venueId } = state.activeFilters
    const { paginationKey } = ownProps
    const fullPaginationKey = getPaginationKey(
        paginationKey,
        venueId,
    )
    const pagination = getPagination(state, "bookings", fullPaginationKey)
    return ({
        isFetching: pagination.isFetching,
        currentPage: pagination.params.page,
        total: pagination.total,
        params: pagination.params,
        bookings: getEntitiesByPagination(state, "bookings", fullPaginationKey),
        bookingsForCurrentPage: getEntitiesForCurrentPage(state, "bookings", fullPaginationKey),
    })
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const {
        paginationKey,
        limit,
    } = ownProps
    const page = 1
    const sort = "start.asc"
    const status = "payment_successful,confirmed"
    return ({
        fetchBookings: (params) => {
            const finalParams = {
                page,
                limit,
                status,
                sort,
                ...params,
            }
            return dispatch(fetchBookingsForActiveVenue(paginationKey, finalParams))
        },
    })
}

const WithBookings = withReduxState(mapStateToProps, mapDispatchToProps)

export default WithBookings

import React from 'react'
import { deleteGame, fetchGamesExtendCurrentParams } from 'store/actions/games'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    deleteGame: (game) => dispatch(deleteGame(game.id)),
    deleteGameSuccess: (game) => {
        notification.success({
            message: 'Game deleted!',
            description: (
                <span>
                    Game&nbsp;
                    <strong>{game.title}</strong>
                    &nbsp;
                    is succesfully deleted.
                </span>
            ),
        })
    },
    deleteGameFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchGames: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchGamesExtendCurrentParams(paginationKey))
    },
})

const DeleteGameRequest = withReduxState(null, mapDispatchToProps)

export default DeleteGameRequest

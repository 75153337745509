import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import CreateRoomRequest from 'components/pages/settings/rooms/forms/create/CreateRoomRequest'
import { getEntityFromResponse } from 'util/index'
import { toRoomApiRequest } from 'util/transformers/room'
import { getRoomErrors } from 'util/validation/roomErrors'
import { isValidRoom } from 'util/validation/room'

function CreateRoomFormState({
    onCancel,
    onCreateSuccess,
    children,
}) {
    return (
        <CreateRoomRequest paginationKey="roomsOverview">
            {({
                createRoom,
                createRoomSuccess,
                createRoomFailed,
                fetchRooms,
            }) => (
                <WithFormState
                    initialValues={{
                        name: '',
                        seats: '',
                        slotDuration: '',
                    }}
                    isValidForm={isValidRoom}
                    getFormErrors={getRoomErrors}
                    transformValuesToRequestBody={toRoomApiRequest}
                    onSendRequest={(values) => createRoom(values)}
                    onCancel={onCancel}
                    onRequestSuccess={(response) => {
                        const room = getEntityFromResponse(response, 'rooms')
                        fetchRooms()
                        createRoomSuccess(room)
                        onCreateSuccess(room)
                    }}
                    onRequestFailed={createRoomFailed}
                >
                    {children}
                </WithFormState>
            )}
        </CreateRoomRequest>
    )
}

export default CreateRoomFormState

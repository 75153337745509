import {isPositiveNumber, isPositiveNumberOrZero, isRequired, isValidEmail} from './index'
import {isValidString} from "../string-utils"

/**
 * @param {number} gameId
 * @return {boolean}
 */
export function isValidGameId(gameId) {
    return isPositiveNumber(gameId)
}

/**
 * @param playerAmount
 * @return {boolean}
 */
export function isValidPlayerAmount(playerAmount) {
    return isRequired(playerAmount) && typeof playerAmount === 'number' && playerAmount >= 2
}

export function isValidDate(date) {
    return isRequired(date)
}

export function isValidRoomStart(roomStart) {
    return isRequired(roomStart)
}

export function isValidRoomId(roomId) {
    return isPositiveNumber(roomId)
}

export function isValidCompanyName(isCompanyBooking, companyName) {
    return isCompanyBooking ? isValidString(companyName) : true
}

export function isValidAddress(isCompanyBooking, address) {
    return isCompanyBooking ? isValidString(address) : true
}

export function isValidZipCode(isCompanyBooking, zipCode) {
    return isCompanyBooking ? isValidString(zipCode) : true
}

export function isValidCity(isCompanyBooking, city) {
    return isCompanyBooking ? isValidString(city) : true
}

export function isValidVatNumber(isCompanyBooking, hasVatNumber, vatNumber) {
    if (isCompanyBooking) {
        return hasVatNumber ? isValidString(vatNumber) : true
    }
    return true
}

/**
 * @param isSocialDeal
 * @param fixedAmount
 * @param discountPercentage
 * @return {boolean}
 */
export function isValidSocialDeal(isSocialDeal, fixedAmount, discountPercentage) {
    if (isSocialDeal) {
        return isPositiveNumberOrZero(fixedAmount) || isPositiveNumber(discountPercentage)
    }
    return true
}

export function playersGameItemsHasErrors(errors) {
    if (typeof errors.gameId !== 'undefined' && errors.gameId !== null)
        return true

    if (typeof errors.playerAmount !== 'undefined' && errors.playerAmount !== null)
        return true

    return false
}

export function timeSlotItemsHasErrors(errors) {
    if (Array.isArray(errors.roomId))
        return true

    if (Array.isArray(errors.bookingStartTime))
        return true

    if (Array.isArray(errors.bookingStartDate))
        return true

    return false
}

export function bookerFormItemsHasErrors(errors) {
    if (Array.isArray(errors.bookerFirstName))
        return true
    if (Array.isArray(errors.bookerLastName))
        return true
    if (Array.isArray(errors.bookerEmail))
        return true
    if (Array.isArray(errors.bookerLanguageCode))
        return true
    if (Array.isArray(errors.bookerPhoneNumber))
        return true

    return false
}

export function companyFormItemsHasErrors(errors) {
    if (Array.isArray(errors.companyName))
        return true
    if (Array.isArray(errors.companyCountryCode))
        return true
    if (Array.isArray(errors.companyAddress))
        return true
    if (Array.isArray(errors.companyZipCode))
        return true
    if (Array.isArray(errors.companyCity))
        return true
    if (Array.isArray(errors.companyVatNumber))
        return true

    return false
}

export function pricingFormItemsHasErrors(errors) {
    if (Array.isArray(errors.socialDealFixedAmount))
        return true
    if (Array.isArray(errors.socialDealDiscountPercentage))

        return false
}

/**
 * @param {CreateOrEditBookingFormValues} values
 * @return {boolean}
 */
export function isValidCreateBookingForm(values) {
    const isValid = isValidGameId(values.gameId)
        && isValidPlayerAmount(values.playerAmount)
        && isValidString(values.gameLanguageCode)
        && isValidString(values.bookerFirstName)
        && isValidString(values.bookerLastName)
        && isValidString(values.bookerEmail) && isValidEmail(values.bookerEmail)
        && isValidString(values.bookerLanguageCode)
        && isValidDate(values.bookingStartDate)
        && isValidDate(values.bookingStartTime)
        && isValidRoomId(values.roomId)
        && isValidCompanyName(values.isCompanyBooking, values.companyName)
        && isValidAddress(values.isCompanyBooking, values.companyAddress)
        && isValidZipCode(values.isCompanyBooking, values.companyZipCode)
        && isValidCity(values.isCompanyBooking, values.companyCity)
        && isValidVatNumber(values.isCompanyBooking, values.companyHasVatNumber, values.companyVatNumber)
        && isValidSocialDeal(values.isSocialDeal, values.socialDealFixedAmount, values.socialDealDiscountPercentage)

    // console.log("Is valid create booking form: ", isValid)
    return isValid
}

/**
 * @param {CreateOrEditBookingFormValues} values
 * @return {boolean}
 */
export function isValidEditForm(values) {
    return isValidString(values.bookerEmail)
        && isValidString(values.bookerFirstName)
        && isValidString(values.bookerLastName)
        && isValidString(values.bookerLanguageCode)
        && isValidCompanyName(values.isCompanyBooking, values.companyName)
        && isValidAddress(values.isCompanyBooking, values.companyAddress)
        && isValidZipCode(values.isCompanyBooking, values.companyZipCode)
        && isValidCity(values.isCompanyBooking, values.companyCity)
        && isValidVatNumber(values.isCompanyBooking, values.companyHasVatNumber, values.companyCity)
        && isValidSocialDeal(values.isSocialDeal, values.socialDealFixedAmount, values.socialDealDiscountPercentage)
}

import React, { useMemo } from 'react'
import {
    Form, Input, Checkbox, Select,
} from 'antd'
import FormItem from 'components/util/forms/FormItem'
import { PriceInput } from 'components/util/forms/PriceInput'
import ExtraInfoLabel from 'components/util/forms/ExtraInfoLabel'

const { Option } = Select

function CreateGiftCardForm({
    values,
    errors,
    onChange,
    onChangeAsValue,
    activeLegalEntity,
    legalEntities,
}) {
    const selectedLegalEntity = useMemo(() => {
        if (Array.isArray(legalEntities)) {
            const le = legalEntities.find((l) => l.id === values.legalEntityId)
            return le
        }

        if (typeof activeLegalEntity !== 'undefined' && activeLegalEntity !== null) {
            return activeLegalEntity
        }

        return undefined
    }, [legalEntities, activeLegalEntity, values.legalEntityId])

    return (
        <Form>
            <FormItem
                label="First name"
                required
                error={errors.firstName}
            >
                <Input
                    name="firstName"
                    value={values.firstName}
                    placeholder="e.g. John"
                    onChange={onChange}
                />
            </FormItem>
            <FormItem
                label="Last name"
                required
                error={errors.lastName}
            >
                <Input
                    name="lastName"
                    value={values.lastName}
                    placeholder="e.g. Doe"
                    onChange={onChange}
                />
            </FormItem>
            <FormItem
                label="Email"
                required
                error={errors.email}
            >
                <Input
                    name="email"
                    value={values.email}
                    placeholder="e.g. john@doe.com"
                    onChange={onChange}
                />
            </FormItem>
            {
                Array.isArray(legalEntities)
                && (
                    <FormItem
                        label="Available in"
                        required
                        extra={<ExtraInfoLabel message="The country in which the gift card can be used." />}
                    >
                        <Select
                            onChange={(id) => onChangeAsValue('legalEntityId', id)}
                            value={selectedLegalEntity ? selectedLegalEntity.id : 0}
                            placeholder="Please select legal entity"
                        >
                            {
                                legalEntities.map((le) => (
                                    <Option
                                        key={le.id}
                                        value={le.id}
                                    >
                                        {`${le.country.code} - ${le.name}`}
                                    </Option>
                                ))
                            }
                        </Select>
                    </FormItem>
                )
            }
            {
                typeof selectedLegalEntity !== 'undefined'
                && (
                    <FormItem
                        label="Amount"
                        error={errors.amount}
                        required
                    >
                        <PriceInput
                            name="amount"
                            value={values.amount}
                            onChange={(value) => onChangeAsValue('amount', value)}
                            currency={selectedLegalEntity.currency}
                        />
                    </FormItem>
                )
            }
            <FormItem
                label="Recipient's name"
                required
                error={errors.recipientName}
            >
                <Input
                    name="recipientName"
                    value={values.recipientName}
                    placeholder="e.g. Alice"
                    onChange={onChange}
                />
            </FormItem>
            <FormItem
                label="Personal message"
                error={errors.message}
            >
                <Input.TextArea
                    rows="4"
                    name="message"
                    value={values.message}
                    onChange={onChange}
                />
            </FormItem>
            <FormItem
                label="Purchase Order Number"
                extra={<ExtraInfoLabel message="Optional purchase order number." />}
                error={errors.purchaseOrderNumber}
            >
                <Input
                    name="purchaseOrderNumber"
                    value={values.purchaseOrderNumber}
                    onChange={onChange}
                    maxLength={64}
                />
            </FormItem>
            <FormItem
                error={errors.sendEmail}
                wrapperCol={{
                    xs: { span: 24 },
                    sm: { span: 16, offset: 8 },
                }}
            >
                <Checkbox
                    name="sendEmail"
                    checked={values.sendEmail}
                    onChange={onChange}
                >
                    Send email
                </Checkbox>
            </FormItem>
        </Form>
    )
}

export default CreateGiftCardForm

import React from 'react'
import CreateRoomModal from './CreateRoomModal'
import CreateRoomModalState from './CreateRoomModalState'
import CreateRoomFormState from '../../forms/create/CreateRoomFormState'

function CreateRoomModalWithState({ onCreateSuccess }) {
    return (
        <CreateRoomModalState>
            {({ isOpen, closeModal }) => (
                <CreateRoomFormState
                    onCancel={closeModal}
                    onCreateSuccess={(room) => {
                        closeModal()
                        if (typeof onCreateSuccess === 'function') {
                            onCreateSuccess(room)
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        isLoading,
                        handleChange,
                        handleChangeAsValue,
                        handleCancel,
                        handleSubmit,
                    }) => (
                        <CreateRoomModal
                            isOpen={isOpen}
                            onClose={closeModal}
                            values={values}
                            errors={errors}
                            isLoading={isLoading}
                            onChange={handleChange}
                            onChangeAsValue={handleChangeAsValue}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                        />
                    )}
                </CreateRoomFormState>
            )}
        </CreateRoomModalState>
    )
}
export default CreateRoomModalWithState

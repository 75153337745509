import { createValidationErrorObject } from "./index"
import * as validation from "./booking"
import {isValidString} from "../string-utils"

export const getGameError = createValidationErrorObject(
    validation.isValidGameId,
    "gameId",
    "Please select a game.",
)

export const getPlayerAmountError = createValidationErrorObject(
    validation.isValidPlayerAmount,
    "playerAmount",
    "A booking must have at least 2 player.",
)

export const getBookingGameLanguageError = createValidationErrorObject(
    isValidString,
    "gameLanguageCode",
    "Please select a game language.",
)

export const getBookerFirstNameError = createValidationErrorObject(
    isValidString,
    "bookerFirstName",
    "Please fill in a valid first name.",
)

export const getBookerLastNameError = createValidationErrorObject(
    isValidString,
    "bookerLastName",
    "Please fill in a valid last name.",
)

export const getBookerEmailError = createValidationErrorObject(
    isValidString,
    "bookerEmail",
    "Please fill in a valid email.",
)

export const getBookerLanguageError = createValidationErrorObject(
    isValidString,
    "bookerLanguageCode",
    "Please select a language.",
)

export const getBookingStartDateError = createValidationErrorObject(
    validation.isValidDate,
    "bookingStartDate",
    "Please choose a valid date.",
)

export const getBookingStartTimeError = createValidationErrorObject(
    validation.isValidRoomStart,
    "bookingStartTime",
    "Please choose a valid timeslot.",
)

export const getRoomError = createValidationErrorObject(
    validation.isValidRoomId,
    "roomId",
    "Please choose a valid room.",
)

export const getCompanyNameError = createValidationErrorObject(
    validation.isValidCompanyName,
    "companyName",
    "Please fill in a valid company name.",
)

export const getCompanyCountryError = createValidationErrorObject(
    validation.isValidCompanyName,
    "companyCountryCode",
    "Please fill in a country.",
)

export const getCompanyAddressError = createValidationErrorObject(
    validation.isValidAddress,
    "companyAddress",
    "Please fill in a valid address.",
)

export const getCompanyZipCodeError = createValidationErrorObject(
    validation.isValidZipCode,
    "companyZipCode",
    "Please fill in a valid zip code.",
)

export const getCompanyVatNumberError = createValidationErrorObject(
    validation.isValidVatNumber,
    "companyVatNumber",
    "Please fill in a valid Game VAT number.",
)

export const getCompanyCityError = createValidationErrorObject(
    validation.isValidCity,
    "companyCity",
    "Please fill in a valid city.",
)

export const getSocialDealError = createValidationErrorObject(
    validation.isValidSocialDeal,
    "isSocialDeal",
    "Please fill in a valid deal.",
)

/**
 * @param {CreateOrEditBookingFormValues} values
 * @returns {{ [key in keyof CreateOrEditBookingFormValues]: string[]}}
 */
export function getBookingErrors(values) {
    return {
        ...getGameError(values.gameId),
        ...getPlayerAmountError(values.playerAmount),
        ...getBookingGameLanguageError(values.gameLanguageCode),
        ...getBookerFirstNameError(values.bookerFirstName),
        ...getBookerLastNameError(values.bookerLastName),
        ...getBookerEmailError(values.bookerEmail),
        ...getBookerLanguageError(values.bookerLanguageCode),
        ...getBookingStartDateError(values.bookingStartDate),
        ...getBookingStartTimeError(values.bookingStartTime),
        ...getRoomError(values.roomId),
        ...getCompanyNameError(values.isCompanyBooking, values.companyName),
        ...getCompanyCountryError(values.isCompanyBooking, values.companyCountryCode),
        ...getCompanyAddressError(values.isCompanyBooking, values.companyAddress),
        ...getCompanyZipCodeError(values.isCompanyBooking, values.companyZipCode),
        ...getCompanyCityError(values.isCompanyBooking, values.companyCity),
        ...getCompanyVatNumberError(values.isCompanyBooking, values.companyHasVatNumber, values.companyVatNumber),
        ...getSocialDealError(values.isSocialDeal, values.socialDealFixedAmount, values.socialDealDiscountPercentage),
    }
}

/**
 * @param {CreateOrEditBookingFormValues} values
 * @returns {{ [key in keyof CreateOrEditBookingFormValues]: string[]}}
 */
export function getEditBookingErrors(values) {
    return {
        ...getGameError(values.gameId),
        ...getPlayerAmountError(values.playerAmount),
        ...getBookingGameLanguageError(values.gameLanguageCode),
        ...getBookerFirstNameError(values.bookerFirstName),
        ...getBookerLastNameError(values.bookerLastName),
        ...getBookerEmailError(values.bookerEmail),
        ...getBookerLanguageError(values.bookerLanguageCode),
        ...getBookingStartDateError(values.bookingStartDate),
        ...getBookingStartTimeError(values.bookingStartTime),
        ...getRoomError(values.roomId),
        ...getCompanyNameError(values.isCompanyBooking, values.companyName),
        ...getCompanyCountryError(values.isCompanyBooking, values.companyCountryCode),
        ...getCompanyAddressError(values.isCompanyBooking, values.companyAddress),
        ...getCompanyZipCodeError(values.isCompanyBooking, values.companyZipCode),
        ...getCompanyCityError(values.isCompanyBooking, values.companyCity),
        ...getCompanyVatNumberError(values.isCompanyBooking, values.companyHasVatNumber, values.companyVatNumber),
        ...getSocialDealError(values.isSocialDeal, values.socialDealFixedAmount, values.socialDealDiscountPercentage),
    }
}

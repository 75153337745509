import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { editVoucher, refreshPaginatedVouchers } from '../../../../../../store/actions/vouchers'
import { getEntityFromResponse } from '../../../../../../util/index'
import { notification } from 'antd'
import { WithFormState } from '../../../../../util/forms/WithFormState'
import { toVoucherApiPatchRequestBody } from '../../../../../../util/transformers/voucher'
import dayjs from 'dayjs'

const defaultExpiryDate = dayjs().add(1, 'year').endOf('day')

/**
 * @param {Object} props
 * @param {() => void} props.onCancel
 * @param {(voucher: Object) => void} props.onEditVoucherSuccess
 * @param {Object} props.voucher
 * @param {React.ReactElement | React.ReactElement[] | undefined} props.children
 * @constructor
 */
export function EditVoucherFormState(props) {
    const dispatch = useDispatch()

    const editVoucherCallback = useCallback(async (values) => {
        return dispatch(editVoucher(props.voucher.id, values))
    }, [dispatch, props.voucher.id])

    function onEditVoucherSuccess(response) {
        // console.log(response)
        const voucher = getEntityFromResponse(response, 'vouchers')
        notification.success({
            message: 'voucher added!',
            description: (
                <span>
                    voucher with code <strong>{voucher.coupon.code}</strong> updated successfully.
                </span>
            ),
        })
        props.onEditVoucherSuccess(voucher)
        dispatch(refreshPaginatedVouchers('overview'))
    }

    const onEditVoucherFailed = useCallback((error) => {
        notification.error({
            message: 'Failed to update voucher.',
            description: `${error.message}`,
        })
    }, [])

    console.log('Voucher:', props.voucher)

    const initialValues = useMemo(() => {
        const vals = {
            // Required
            legalEntityId: props.voucher.coupon.legalEntityId,
            amount: props.voucher.coupon.amount,
            issuedInVenueId: props.voucher.issuedVenueId,

            // Optional
            comment: null,
            issuedReasonId: null,
            associatedOrderNumber: null,
            dealNumber: null,
        }

        if (typeof props.voucher.expiresAt === 'string' && props.voucher.expiresAt.length > 0) {
            vals.expiryDate = dayjs(props.voucher.expiresAt)
        } else {
            vals.expiryDate = defaultExpiryDate
        }

        if (typeof props.voucher.comment === 'string' && props.voucher.comment.length > 0) {
            vals.comment = props.voucher.comment
        }
        if (typeof props.voucher.issuedReasonId === 'number' && props.voucher.issuedReasonId > 0) {
            vals.issuedReasonId = props.voucher.issuedReasonId
        }
        if (typeof props.voucher.associatedOrderNumber === 'string' && props.voucher.associatedOrderNumber.length > 0) {
            vals.associatedOrderNumber = props.voucher.associatedOrderNumber
        }
        if (typeof props.voucher.dealNumber === 'string' && props.voucher.dealNumber.length > 0) {
            vals.dealNumber = props.voucher.dealNumber
        }

        return vals
    }, [props.voucher])

    return (
        <WithFormState
            initialValues={initialValues}
            transformValuesToRequestBody={toVoucherApiPatchRequestBody}
            onSendRequest={editVoucherCallback}
            onCancel={props.onCancel}
            onRequestFailed={onEditVoucherFailed}
            onRequestSuccess={onEditVoucherSuccess}
        >
            {props.children}
        </WithFormState>
    )
}

import { createValidationErrorObject } from './index'
import * as validation from './gameSession'

export const getGameError = createValidationErrorObject(
    validation.isValidGame,
    'game',
    'Please select a game.',
)

export const getDateError = createValidationErrorObject(
    validation.isValidDate,
    'date',
    'Please choose a valid date.',
)

export const getStartError = createValidationErrorObject(
    validation.isValidStart,
    'roomStart',
    'Please choose a valid roomStart hour.',
)

export const getRoomError = createValidationErrorObject(
    validation.isValidRoom,
    'room',
    'Please choose a valid room.',
)

export const getGameSessionErrors = ({
    game,
    date,
    roomStart,
    room,
}) => ({
    ...getGameError(game),
    ...getDateError(date),
    ...getStartError(roomStart),
    ...getRoomError(room),
})

import React, { useMemo } from 'react'
import './EnvironmentBanner.css'

function EnvironmentBanner({ env, colors }) {
    const color = useMemo(() => {
        // Override colors if present
        const mapped = {
            staging: '#FFC82C',
            development: '#0F0',
            ...colors,
        }

        return mapped[env]
    }, [colors, env])

    if (typeof color === 'undefined' || color === null)
        return null

    return <div className="environment-banner" style={{ backgroundColor: color }} />
}

export default EnvironmentBanner

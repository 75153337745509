import React from 'react'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Tooltip, Switch } from 'antd'
import ActiveFilters from 'components/layout/filters/WithActiveFilters'
import WithMe from 'components/pages/authorized/WithMe'
import ToggleActiveGameSessionModalState from '../modals/toggle-active/ToggleActiveGameSessionModalState'
import ToggleActiveGameSessionRequest from '../requests/ToggleActiveGameSessionRequest'

function ActivateGameSessionSwitch({
    gameSession,
}) {
    // const {
    //     id: gameSessionId,
    //     room: { id: roomId },
    //     isActive,
    //     activeInRoom,
    // } = gameSession
    return (
        <WithMe>
            {({ isReadOnly }) => (
                <ToggleActiveGameSessionModalState gameSession={gameSession}>
                    {({ openModal }) => (
                        <ActiveFilters>
                            {({ activeVenueId }) => (
                                <ToggleActiveGameSessionRequest
                                    venueId={activeVenueId}
                                    roomId={gameSession.room.id}
                                >
                                    {({ toggleActiveStatus, isLoading }) => {
                                        let tooltipTitle = 'Set game session active'
                                        if (gameSession.isActive &&
                                            typeof gameSession.activeInRoom !== 'undefined' &&
                                            gameSession.activeInRoom !== null
                                        ) {
                                            tooltipTitle = `This game session is currently active in ${gameSession.activeInRoom.name}`
                                        }
                                        return (
                                            <Tooltip
                                                placement="bottom"
                                                title={(
                                                    <p className="txta-center no-margin">{tooltipTitle}</p>
                                                )}
                                            >
                                                <Switch
                                                    loading={isLoading}
                                                    disabled={isReadOnly}
                                                    size="small"
                                                    checkedChildren={<CheckOutlined />}
                                                    unCheckedChildren={<CloseOutlined />}
                                                    onChange={async (checked) => {
                                                        if (checked) {
                                                            openModal()
                                                        } else {
                                                            // console.log('game sessions:', gameSession)
                                                            await toggleActiveStatus(
                                                                gameSession.id,
                                                                gameSession.activeInRoom.id,
                                                                false,
                                                            )
                                                        }
                                                    }}
                                                    checked={gameSession.isActive}
                                                />
                                            </Tooltip>
                                        )
                                    }}
                                </ToggleActiveGameSessionRequest>
                            )}
                        </ActiveFilters>
                    )}
                </ToggleActiveGameSessionModalState>
            )}
        </WithMe>
    )
}
export default ActivateGameSessionSwitch

/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable object-shorthand */
import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { selectableStatusses, clickableStatusses } from './TSConstants'
// eslint-disable-next-line no-unused-vars
import * as RoomAndTimeSlotTypes from './TimeSlotTypes'
import { APP_DATE_FORMAT } from '../../../../util/constants'

/** Make a copy of the original values with an extra property 'selected' for each slot. */

function transformRoomsAndTimeSlots(apiRoomsAndTimeSlots) {
    const result = apiRoomsAndTimeSlots.map((roomAndTimeSlots) => {
        const { timeSlots, ...remainingProps } = roomAndTimeSlots

        const transformedRoomAndTimeSlots = {
            ...remainingProps,
            uiSlots: roomAndTimeSlots.timeSlots.map((slot) => ({
                slot: slot,
                selected: false,
            })),
        }

        return transformedRoomAndTimeSlots
    })

    return result
}

/** transform selected rooms and timeslots object to request object format */
function transformRoomsAndTimeSlotsRequest(selectedRoomsAndTimeSlots, date) {
    const result = selectedRoomsAndTimeSlots.map((roomAndTimeSlots) => {
        const { uiSlots, roomId } = roomAndTimeSlots

        const transformedRoomAndTimeSlots = {
            roomId,
            // E.G. 2020-12-31T19:30
            slots: uiSlots.map((slot) => `${dayjs.utc(date).format(APP_DATE_FORMAT)}T${slot.slot.roomStart}`),
        }

        return transformedRoomAndTimeSlots
    })

    return result
}

/**
 * @callback updateSelectedStatusOfSlot
 * @param {RoomAndTimeSlotTypes.RoomAndTimeSlots[]} roomsAndTimeSlotsToChangeToSelected
 * @param {boolean} select
 * @param {boolean} notClickable
 * @returns {void}
*/

/**
 * @callback getSelectedRoomsAndTimeSlots
 * @returns {RoomAndTimeSlotTypes.RoomAndTimeSlots[]}
*/

export const useLocalStateOfSlots = (apiRoomsAndTimeSlots) => {
    /** @type {RoomAndTimeSlotTypes.RoomAndTimeSlots} */
    const [uiRoomsAndTimeSlots, setUiRoomsAndTimeSlots] = useState(transformRoomsAndTimeSlots(apiRoomsAndTimeSlots))

    useEffect(() => {
        const transformedRoomsAndTimeSlots = transformRoomsAndTimeSlots(apiRoomsAndTimeSlots)
        setUiRoomsAndTimeSlots(transformedRoomsAndTimeSlots)
    }, [apiRoomsAndTimeSlots])

    /**
     *
     * @param {RoomAndTimeSlotTypes.RoomAndTimeSlots[]} roomsAndTimeSlotsToChangeToSelected array with roomAndTimeSlots object to search for
     * @param {boolean} select sets wheter slot is to be set to selected or not: true => select slots, false => deselect slots
     * @param {boolean} notClickable see if slot is not clickable => if so, do not change status
     */
    const updateSelectedStatusOfSlot = (roomsAndTimeSlotsToChangeToSelected, select, notClickable) => {
        // Need to check if status of slot is booked or buffered => if so, do not change status
        // step 1 create copy of uiRoomsAndTimeSlots
        const copyUiRoomsAndTimeSlots = uiRoomsAndTimeSlots.map((roomAndTimeSlots) => {
            const { uiSlots, ...remainingProps } = roomAndTimeSlots
            const copiedSlots = uiSlots.map((slot) => ({ ...slot }))
            return { ...remainingProps, uiSlots: copiedSlots }
        })

        const todayDate = dayjs.utc().startOf('day')

        // step 2 find requested slots to select and set selected to true
        for (let i = 0; i < roomsAndTimeSlotsToChangeToSelected.length; i++) {
            // room object with slots
            // get roomId and uiSlots of specific room

            const { roomId: roomIdFromSelected, uiSlots: uiSlotsFromSelected, date } = roomsAndTimeSlotsToChangeToSelected[i]
            const inputDateSelectedSlots = dayjs.utc(date)
            // const inputDateSelectedSlots = moment(new Date(date).getTime())

            // check if slot has passed
            const dateDifferenceTodayAndSelected = todayDate.diff(inputDateSelectedSlots)

            if (dateDifferenceTodayAndSelected > 0) {
                break
            } else {
                // search 'roomsAndTimeSlotsToSelect' for an object with same roomId
                // as the current roomId in the for loop of the copy array (copyUiRoomsAndTimeSlots)

                /** @type {RoomAndTimeSlotTypes.RoomAndTimeSlots} */
                const foundRoomAndTimeSlots = copyUiRoomsAndTimeSlots.find((roomToFind) => roomToFind.roomId === roomIdFromSelected)

                // if object is found => changes must be made in this room
                if (typeof foundRoomAndTimeSlots !== 'undefined') {
                // get slots to update E.g {slot:{}, selected: false} => {slot:{}, selected: true}
                /**
                 * @param slotsToUpdate
                 *
                 */
                    const { uiSlots: uiSlotsFromCopy } = foundRoomAndTimeSlots

                    // loop over slots
                    for (let y = 0; y < uiSlotsFromSelected.length; y++) {
                    // if current slot of copy (copyUiRoomsAndTimeSlots) is inside selected slots
                    // change selected of that slot to 'true' (in the copy)

                        const uiSlotFromSelected = uiSlotsFromSelected[y]

                        const { slot } = uiSlotFromSelected

                        // searches the item in the uiSlotsFromCopy array that have the same roomStart
                        const foundSlotInCopy = uiSlotsFromCopy.find((uiSlotFromCopy) => uiSlotFromCopy.slot.roomStart === slot.roomStart)

                        if (typeof foundSlotInCopy !== 'undefined') {
                        // change selected to true
                            if (notClickable) {
                                if (selectableStatusses.indexOf(foundSlotInCopy.slot.status) !== -1) {
                                    foundSlotInCopy.selected = select
                                }
                            } else if (clickableStatusses.indexOf(foundSlotInCopy.slot.status) !== -1) {
                                foundSlotInCopy.selected = select
                            }
                        }
                    }
                }
            }
        }

        setUiRoomsAndTimeSlots(copyUiRoomsAndTimeSlots)
    }

    const getSelectedRoomsAndTimeSlots = () => {
        const result = uiRoomsAndTimeSlots.map((roomAndTimeSlots) => {
            const { uiSlots, ...remainingProps } = roomAndTimeSlots
            const selectedSlots = uiSlots.filter((slot) => slot.selected === true)

            return {
                ...remainingProps,
                uiSlots: selectedSlots,
            }
        })
        return result
    }

    /**
     * @param {dayjs.Dayjs} date
     * @returns E.G. [{roomId: 1, slots: ['2020-12-31T19:30']}, {roomId: 2, slots: ['2020-12-31T19:30']}, {roomId: 2, slots: ['2020-12-31T19:30']}]
     */
    const getRoomsAndSlotsRequestFormat = (date) => {
        const result = transformRoomsAndTimeSlotsRequest(getSelectedRoomsAndTimeSlots(), date)
        return result
    }

    return {
        updateSelectedStatusOfSlot,
        /** @type {RoomAndTimeSlotTypes.RoomAndTimeSlots[]} */
        uiRoomsAndTimeSlots,
        getSelectedRoomsAndTimeSlots,
        getRoomsAndSlotsRequestFormat,
    }
}

export default {}

import React from 'react'
import { deleteEquipmentSet, fetchEquipmentSetsExtendCurrentParams } from 'store/actions/equipmentSets'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    deleteEquipmentSet: async (equipmentSet) => dispatch(deleteEquipmentSet(equipmentSet.id)),
    deleteEquipmentSetSuccess: (equipmentSet) => {
        notification.success({
            message: 'Equipment set deleted!',
            description: (
                <span>
                    Equipment set&nbsp;
                    <strong>{equipmentSet && equipmentSet.name}</strong>
                    &nbsp;
                    is succesfully deleted.
                </span>
            ),
        })
    },
    deleteEquipmentSetFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchEquipmentSets: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchEquipmentSetsExtendCurrentParams(paginationKey))
    },
})

const DeleteEquipmentSetRequest = withReduxState(null, mapDispatchToProps)

export default DeleteEquipmentSetRequest

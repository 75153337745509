import { PLAYER_AND_TOTAL } from 'util/constants'
import { createValidationErrorObject } from './index'
import * as validation from './priceAdjustment'

export const getWeekDayError = createValidationErrorObject(
    validation.isValidWeekDay,
    'day',
    'Please select a weekday.',
)

export const getPlayerPriceError = createValidationErrorObject(
    validation.isValidPlayerPrice,
    'playerPrice',
    'Please fill in a valid amount.',
)

export const getTotalPriceError = createValidationErrorObject(
    validation.isValidTotalPrice,
    'totalPrice',
    'Please fill in a valid amount.',
)

export const getStartTimeError = createValidationErrorObject(
    validation.isValidStartTime,
    'from',
    'Please provide a valid start time.',
)

export const getEndTimeError = createValidationErrorObject(
    validation.isValidStartTime,
    'until',
    'Please provide a valid end time.',
)

export const getStartIsNotbeforeEndError = createValidationErrorObject(
    validation.startIsBeforeEnd,
    'from',
    'Start time must be before end time.',
)

export const getEndIsNotAfterStartError = createValidationErrorObject(
    validation.endIsAfterstart,
    'until',
    'End time must be after start time.',
)

export const getPriceAdjustmentErrors = (values, priceSystem) => {
    const errors = {
        ...getWeekDayError(values.day),
        ...getPlayerPriceError(values.playerPrice),
        ...getStartTimeError(values.adjustmentType, values.from, values.until),
        ...getEndTimeError(values.adjustmentType, values.from, values.until),
        ...getStartIsNotbeforeEndError(values.adjustmentType, values.from, values.until),
        ...getEndIsNotAfterStartError(values.adjustmentType, values.from, values.until),
    }

    if (priceSystem === PLAYER_AND_TOTAL) {
        errors.push(getTotalPriceError(values.totalPrice))
    }
    return errors
}

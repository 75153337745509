import { CALL_API, METHODS } from '../middleware/api'
import { legalEntitySchema, legalEntitySchemaArray } from '../schemas/legalEntities'

export const LEGAL_ENTITY_REQUEST = 'LEGAL_ENTITY_REQUEST'
export const LEGAL_ENTITY_SUCCESS = 'LEGAL_ENTITY_SUCCESS'
export const LEGAL_ENTITY_FAILURE = 'LEGAL_ENTITY_FAILURE'

export const LEGAL_ENTITIES_REQUEST = 'LEGAL_ENTITIES_REQUEST'
export const LEGAL_ENTITIES_SUCCESS = 'LEGAL_ENTITIES_SUCCESS'
export const LEGAL_ENTITIES_FAILURE = 'LEGAL_ENTITIES_FAILURE'

export const ADD_LEGAL_ENTITY_TO_ROOM = 'ADD_LEGAL_ENTITY_TO_ROOM'
export const REMOVE_LEGAL_ENTITY_FROM_ROOM = 'REMOVE_LEGAL_ENTITY_FROM_ROOM'

export const fetchLegalEntities = (viewName, params) => ({
    viewName,
    [CALL_API]: {
        types: [
            LEGAL_ENTITIES_REQUEST,
            LEGAL_ENTITIES_SUCCESS,
            LEGAL_ENTITIES_FAILURE,
        ],
        endpoint: '/legal-entities',
        schema: legalEntitySchemaArray,
        params,
    },
})

export const fetchLegalEntitiesExtendCurrentParams = (paginationKey, params) => (dispatch, getState) => {
    const legalEntityPagination = getState().pagination.legalEntities[paginationKey]
    const newParams = {
        ...legalEntityPagination.params,
        ...params,
    }
    return dispatch(fetchLegalEntities(paginationKey, newParams))
}

export const createLegalEntity = (body) => ({
    [CALL_API]: {
        method: METHODS.POST,
        types: [
            LEGAL_ENTITY_REQUEST,
            LEGAL_ENTITY_SUCCESS,
            LEGAL_ENTITY_FAILURE,
        ],
        endpoint: '/legal-entities?XDEBUG_SESSION_START=PHPSTORM',
        schema: legalEntitySchema,
        body,
    },
})

export const editLegalEntity = (legalEntityId, body) => ({
    [CALL_API]: {
        method: METHODS.PUT,
        types: [
            LEGAL_ENTITY_REQUEST,
            LEGAL_ENTITY_SUCCESS,
            LEGAL_ENTITY_FAILURE,
        ],
        endpoint: `/legal-entities/${legalEntityId}`,
        schema: legalEntitySchema,
        body,
    },
})

export const deleteLegalEntity = (legalEntityId) => ({
    [CALL_API]: {
        method: METHODS.DELETE,
        types: [
            LEGAL_ENTITY_REQUEST,
            LEGAL_ENTITY_SUCCESS,
            LEGAL_ENTITY_FAILURE,
        ],
        endpoint: `/legal-entities/${legalEntityId}`,
        schema: legalEntitySchema,
        entityId: legalEntityId,
    },
})

import createPaginationReducer from '../hor/createPaginationReducer'
import { VOUCHERS_REQUEST, VOUCHERS_SUCCESS, VOUCHERS_FAILURE } from '../../actions/vouchers'

export const vouchersPaginationReducer = createPaginationReducer({
    mapActionToKey: (action) => action.viewName,
    types: [
        VOUCHERS_REQUEST,
        VOUCHERS_SUCCESS,
        VOUCHERS_FAILURE,
    ],
})

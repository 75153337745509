import createPaginationReducer from '../hor/createPaginationReducer'
import {
    ROLES_REQUEST,
    ROLES_SUCCESS,
    ROLES_FAILURE,
} from '../../actions/roles'

const rolesPaginationReducer = createPaginationReducer({
    mapActionToKey: (action) => action.viewName,
    types: [
        ROLES_REQUEST,
        ROLES_SUCCESS,
        ROLES_FAILURE,
    ],
})

export default rolesPaginationReducer

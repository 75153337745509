import { notification } from "antd"
import withReduxState from "components/hoc/withReduxState"
import { fetchPriceUpdate, updatePrice } from "store/actions/priceUpdate"

const mapDispatchToProps = (dispatch) => ({
    fetchPriceUpdate: (booking, body) => dispatch(fetchPriceUpdate(booking, body)),
    updatePrice: (booking, body) => dispatch(updatePrice(booking, body)),
    updatePriceSuccess: (booking) => {
        notification.success({
            message: "Updated outstanding amount!",
            description: `Outstanding amount for ${booking.personEmail} was successfully updated.`,
        })
    },
    updatePriceFailed: (error) => {
        notification.error({
            message: "Something went wrong",
            description: `${error.message}`,
        })
    },
})

export const PricingRequest = withReduxState(null, mapDispatchToProps)

import createPaginationReducer from '../hor/createPaginationReducer'
import {
    GIFT_CARDS_REQUEST,
    GIFT_CARDS_SUCCESS,
    GIFT_CARDS_FAILURE,
} from '../../actions/giftCards'

const giftCardsPaginationReducer = createPaginationReducer({
    mapActionToKey: (action) => action.viewName,
    types: [
        GIFT_CARDS_REQUEST,
        GIFT_CARDS_SUCCESS,
        GIFT_CARDS_FAILURE,
    ],
})

export default giftCardsPaginationReducer

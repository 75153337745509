import React, {useState, useEffect, useCallback} from 'react'
import { notification } from 'antd'
import { createFormValidation } from 'use-form-state'
import { useDispatch } from 'react-redux'
import useFormState from 'components/hooks/useDynamicLanguageFormState'
import { MODAL_IDS } from 'util/constants'
import useModal from 'components/hooks/useModal'
import { useAppState } from 'components/hooks/useAppState'
import { editLegalEntity } from 'store/actions/legalEntities'
import handleApiErrorResponse from 'util/errorResponse'
import { toLegalEntityApiRequest, mapLegalEntityToValues } from 'util/transformers/legalEntity'
import {legalEntityFormLocalizedKeyHasError, legalEntityFormValidation} from 'util/validation/legalEntities'
import {EditLegalEntityModal} from "./EditLegalEntityModal"

/**
 * @component
 * @param {Object} props
 * @param {() => void} props.onComplete
 */
export function EditLegalEntityModalWithState(props) {
    const dispatch = useDispatch()
    const {
        isOpen,
        closeModal,
        /** @type {LegalEntityResource} */
        entity,
    } = useModal(MODAL_IDS.EDIT_LEGAL_ENTITY)

    const [isUploadingImage, setIsUploadingImage] = useState(false)

    const isLoading = false
    const { currencies, countries } = useAppState()

    const formState = useFormState({}, {
        validation: createFormValidation(legalEntityFormValidation),
    })

    async function handleSubmit() {
        if (formState.validate()) {
            try {
                const response = await dispatch(
                    editLegalEntity(entity.id, toLegalEntityApiRequest(formState.values)),
                )
                if (response.error) {
                    handleApiErrorResponse(response.error, formState.updateErrors, true)
                } else {
                    notification.success({
                        message: 'Legal entity changed!',
                        description: (
                            <span>
                                Legal entity&nbsp;
                                <strong>{formState.values.nameEn}</strong>
                                &nbsp;
                                was successfully changed.
                            </span>
                        ),
                    })
                    formState.resetForm()
                    closeModal()
                    props.onComplete()
                }
            } catch (error) {
                notification.error({
                    message: 'Something went wrong',
                    description: `${error.message}`,
                })
            }
        }
    }

    // useEffect(() => {
    //     resetForm()
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isOpen])

    useEffect(() => {
        if (entity !== null && typeof entity !== 'undefined') {
            formState.setValues(mapLegalEntityToValues(entity))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entity])

    /**
     * @param {string} locale
     * @returns {boolean}
     */
    function hasLocaleError(locale) {
        const hasError = legalEntityFormLocalizedKeyHasError(locale, formState.hasError)
        return hasError
    }

    return (
        <EditLegalEntityModal
            isOpen={isOpen}
            onClose={closeModal}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            isUploadingImage={isUploadingImage}
            setIsUploadingImage={setIsUploadingImage}
            currencies={currencies}
            countries={countries}
            formState={formState}
            hasLocaleError={hasLocaleError}
            originalLegalEntity={entity}
        />
    )
}

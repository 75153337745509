import React from 'react'
import useModal from 'components/hooks/useModal'
import { MODAL_IDS } from 'util/constants'
import CreateGameModal from './CreateGameModal'

function CreateGameModalWithRedux() {
    const { isOpen, closeModal } = useModal(MODAL_IDS.CREATE_GAME)

    return (
        <CreateGameModal
            isOpen={isOpen}
            onClose={closeModal}
        />
    )
}
export default CreateGameModalWithRedux

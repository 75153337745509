import React from 'react'
import { Form, Input } from 'antd'
import FormItem from 'components/util/forms/FormItem'
import LegalEntitySelect from 'components/util/LegalEntitySelect'
import { getVenueIdsError } from 'util/validation/user'
import RolesSelectWithRedux from '../RolesSelectWithRedux'
import VenuesSelectWithRedux from '../VenuesSelectWithRedux'
import DefaultVenueSelectWithState from '../DefaultVenueSelectWithState'

function EditUserForm({
    values,
    errors,
    onChange,
    onChangeAsValue,
}) {
    return (
        <Form>
            <FormItem
                label="First name"
                error={errors.firstName}
                required
            >
                <Input
                    name="firstName"
                    value={values.firstName}
                    onChange={onChange}
                />
            </FormItem>
            <FormItem
                label="Last name"
                error={errors.lastName}
                required
            >
                <Input
                    name="lastName"
                    value={values.lastName}
                    onChange={onChange}
                />
            </FormItem>
            <FormItem
                label="Email"
                error={errors.email}
                required
            >
                <Input
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={onChange}
                />
            </FormItem>
            <FormItem
                label="Role"
                error={errors.role}
                required
            >
                <RolesSelectWithRedux
                    value={values.role}
                    onChange={(value) => onChangeAsValue('role', value)}
                />
            </FormItem>
            <FormItem
                label="Legal Entities"
                error={errors.legalEntities || getVenueIdsError(errors)}
                required
            >
                <LegalEntitySelect
                    mode="multiple"
                    value={values.legalEntities}
                    onChange={(value) => onChangeAsValue('legalEntities', value)}
                />
            </FormItem>
            <FormItem
                label="Assign venue(s)"
                error={errors.venues}
                required
            >
                <VenuesSelectWithRedux
                    value={values.venues}
                    onChange={(value) => onChangeAsValue('venues', value)}
                />
            </FormItem>
            <FormItem
                label="Default venue"
                error={errors.defaultVenue}
            >
                <DefaultVenueSelectWithState
                    selectedVenues={values.venues}
                    value={values.defaultVenue}
                    onChange={(value) => onChangeAsValue('defaultVenue', value)}
                />
            </FormItem>
        </Form>
    )
}

export default EditUserForm

import React from 'react'
import EditVenueGameFormWithState from 'components/pages/settings/venue-games/forms/edit/EditVenueGameFormWithState'
import PriceAdjustmentsTable from 'components/pages/settings/venue-games/tables/PriceAdjustmentsTable'
import useCurrentUser from 'components/hooks/useCurrentUser'
import { useLegalEntity } from 'components/hooks/useLegalEntity'
import * as theme from 'styles/theme'

export default function VenueGameOverview({
    venueGame,
    paginationKey,
}) {
    const {
        isReadOnly,
        isAdmin,
        isLegalEntityManager,
        isVenueManager,
    } = useCurrentUser()
    const legalEntity = useLegalEntity()
    if (typeof legalEntity === 'undefined')
        throw new Error(`Legal Entity is undefined! Comp: ${VenueGameOverview.name}`)

    return (
        <div style={{ padding: theme.spacing }}>
            <EditVenueGameFormWithState
                venueGame={venueGame}
                disabled={isReadOnly}
                paginationKey={paginationKey}
            />
            <PriceAdjustmentsTable
                venueGame={venueGame}
                priceSystem={legalEntity.priceSystem}
                isAdmin={isAdmin}
                isLegalEntityManager={isLegalEntityManager}
                isVenueManager={isVenueManager}
            />
        </div>
    )
}

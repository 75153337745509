import createEntityReducer from '../hor/createEntityReducer'
import {
    COUPON_REQUEST,
    COUPON_SUCCESS,
    COUPON_FAILURE,
} from '../../actions/coupons'

const couponsReducer = createEntityReducer({
    types: [
        COUPON_REQUEST,
        COUPON_SUCCESS,
        COUPON_FAILURE,
    ],
})

export default couponsReducer

import React from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Table, Button, Tag } from 'antd'
import { MODAL_IDS } from 'util/constants'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import Entity from 'components/util/Entity'
import WithMe from 'components/pages/authorized/WithMe'
import EditUserModalState from 'components/pages/settings/users/modals/edit/EditUserModalState'

const isLoggedInUser = (user, id) => user.id === id
const userIsNotLoggedInAdminUser = (user, id, isAdmin) => !isLoggedInUser(user, id) && !isAdmin

const columns = [
    {
        title: 'Name',
        key: 'name',
        render: (text, record) => `${record.firstName} ${record.lastName}`,
        width: 200,
    },
    {
        title: 'Email',
        dataIndex: 'email',
        width: 220,
    },
    {
        title: 'Role',
        dataIndex: 'role',
        render: (text) => (
            <Entity entityKey="roles" id={text}>
                {({ label }) => (
                    `${label}`
                )}
            </Entity>
        ),
        width: 125,
    },
    {
        title: 'Default Venues',
        dataIndex: 'venues',
        render: (text, record) => {
            if (record.defaultVenue !== null && typeof record.defaultVenue !== 'undefined') {
                return (
                    <Entity entityKey="venues" id={record.defaultVenue.id}>
                        {({ name }) => (
                            <Tag>{name}</Tag>
                        )}
                    </Entity>
                )
            } else {
                return null
            }
        },
    },
    {
        title: 'Action',
        key: 'action',
        align: 'right',
        width: 80,
        render: (text, user) => (
            <WithMe>
                {({
                    id,
                    isAdmin,
                    isLegalEntityManager,
                }) => (
                    <>
                        <EditUserModalState user={user}>
                            {({ openModal }) => (
                                <Button
                                    ghost
                                    icon={<EditOutlined />}
                                    type="primary"
                                    shape="circle"
                                    size="small"
                                    className="ant-btn-no-border"
                                    // disabled={
                                    //     isLoggedInUser(user, id)
                                    //     || userIsNotLoggedInAdminUser(user, id, isAdmin || isLegalEntityManager)
                                    // }
                                    disabled={
                                        userIsNotLoggedInAdminUser(user, id, isAdmin || isLegalEntityManager)
                                    }
                                    onClick={openModal}
                                />
                            )}
                        </EditUserModalState>
                        <WithActiveModal
                            modalId={MODAL_IDS.DELETE_USER}
                            entity={user}
                        >
                            {({ openModal }) => (
                                <Button
                                    ghost
                                    icon={<DeleteOutlined />}
                                    danger={true}
                                    shape="circle"
                                    size="small"
                                    className="ant-btn-no-border"
                                    disabled={
                                        isLoggedInUser(user, id)
                                        || (!isLoggedInUser(user, id) && !isAdmin && !isLegalEntityManager)
                                    }
                                    onClick={openModal}
                                />
                            )}
                        </WithActiveModal>
                    </>
                )}
            </WithMe>
        ),
    },
]

function expandedRowRender(users) {
    return (
        <>
            <p>All Venues attached: </p>
            <div>
                {users && users.venues && users.venues.map((id) => (
                    <Entity key={id} entityKey="venues" id={id}>
                        {({ name }) => (
                            <Tag>{name}</Tag>
                        )}
                    </Entity>
                ))}
            </div>
        </>
    )
}

function UsersTable({
    users,
    isFetching,
}) {
    return (
        <Table
            rowKey="id"
            columns={columns}
            expandable={{
                expandedRowRender: expandedRowRender,
                expandRowByClick: true,
                expandIcon: () => null,
            }}
            loading={isFetching}
            dataSource={users}
            pagination={false}
        />
    )
}

export default UsersTable

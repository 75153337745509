import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import { toVenueApiRequest, mapVenueToValues } from 'util/transformers/venue'
import { isValidVenue } from 'util/validation/venue'
import { getVenueErrors } from 'util/validation/venueErrors'
import { getEntityFromResponse } from 'util/index'
import EditVenueRequest from './EditVenueRequest'

function EditVenueFormState({
    venue,
    onCancel,
    onEditSuccess,
    children,
}) {
    return (
        <EditVenueRequest paginationKey="overview" venue={venue}>
            {({
                fetchVenues,
                editVenue,
                editVenueSuccess,
                editVenueFailed,
            }) => {
                if (venue === null || typeof venue === 'undefined') {
                    return null
                }
                return (
                    <WithFormState
                        key={venue.id}
                        initialValues={{
                            ...mapVenueToValues(venue),
                            openingDate: undefined,
                            closingDate: undefined,
                        }}
                        isValidForm={isValidVenue}
                        getFormErrors={getVenueErrors}
                        transformValuesToRequestBody={toVenueApiRequest}
                        onSendRequest={(values) => editVenue(values)}
                        onCancel={onCancel}
                        onRequestSuccess={(response) => {
                            const updatedVenue = getEntityFromResponse(response, 'venues')
                            fetchVenues()
                            editVenueSuccess(updatedVenue)
                            onEditSuccess(updatedVenue)
                        }}
                        onRequestFailed={editVenueFailed}
                    >
                        {children}
                    </WithFormState>
                )
            }}
        </EditVenueRequest>
    )
}

export default EditVenueFormState

import React from 'react'
import { TopBar } from 'components/layout/TopBar'
import AnalyticsContainer from 'components/layout/sidebar/AnalyticsContainer'
import SidebarContent from 'components/layout/sidebar/SidebarContent'
import loader from './loader'
import WithCumulApiKey from './WithCumulApiKey'
import {Main} from "../../layout/Main"

function CumulDashboard({
    dashboardId,
    activeMenuItem,
}) {
    return (
        <>
            <TopBar activeMenuItem="analytics" />
            <Main>
                <AnalyticsContainer activeMenuItem={activeMenuItem}>
                    <SidebarContent className="content">
                        <WithCumulApiKey>
                            {({ key, token }) => {
                                const container = '#container'
                                window.Cumulio.removeDashboard({
                                    container,
                                })

                                window.Cumulio.addDashboard({
                                    dashboardId,
                                    container,
                                    key,
                                    token,
                                    loader,
                                })
                            }}
                        </WithCumulApiKey>
                        <div id="container" />
                    </SidebarContent>
                </AnalyticsContainer>
            </Main>
        </>
    )
}

export default CumulDashboard

import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import EditRoomRequest from 'components/pages/settings/rooms/forms/edit/EditRoomRequest'
import { isValidEditRoom } from 'util/validation/room'
import { getEditRoomErrors } from 'util/validation/roomErrors'
import { getEntityFromResponse } from 'util/index'
import { toEditRoomApiRequest, mapRoomToValues } from 'util/transformers/room'

function EditRoomFormState({
    room,
    children,
}) {
    return (
        <EditRoomRequest paginationKey="roomsOverview" room={room}>
            {({
                equipmentSets,
                fetchRooms,
                editRoom,
                editRoomSuccess,
                editRoomFailed,
            }) => (
                <WithFormState
                    key={room.id}
                    initialValues={{
                        ...mapRoomToValues(room),
                        equipmentSets,
                    }}
                    isValidForm={isValidEditRoom}
                    getFormErrors={getEditRoomErrors}
                    transformValuesToRequestBody={toEditRoomApiRequest}
                    onSendRequest={(values) => editRoom(values)}
                    onRequestSuccess={(response) => {
                        const updatedRoom = getEntityFromResponse(response, 'rooms')
                        fetchRooms()
                        editRoomSuccess(updatedRoom)
                    }}
                    onRequestFailed={editRoomFailed}
                >
                    {children}
                </WithFormState>
            )}
        </EditRoomRequest>
    )
}

export default EditRoomFormState

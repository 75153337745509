import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import CreateUserRequest from 'components/pages/settings/users/forms/create/CreateUserRequest'
import { isValidUser } from 'util/validation/user'
import { getUserErrors } from 'util/validation/userErrors'
import { getEntityFromResponse } from 'util/index'
import { toUserApiRequest } from 'util/transformers/user'

function CreateUserFormState({
    onCancel,
    onSaveSuccess,
    children,
}) {
    return (
        <CreateUserRequest paginationKey="overview">
            {({
                createUser,
                fetchUsers,
                createUserSuccess,
                createUserFailed,
            }) => (
                <WithFormState
                    initialValues={{
                        firstName: null,
                        lastName: null,
                        email: null,
                        venues: [],
                        legalEntities: [],
                    }}
                    isValidForm={isValidUser}
                    getFormErrors={getUserErrors}
                    transformValuesToRequestBody={toUserApiRequest}
                    onSendRequest={createUser}
                    onCancel={onCancel}
                    onRequestSuccess={(response) => {
                        const user = getEntityFromResponse(response, 'users')
                        fetchUsers()
                        createUserSuccess(user)
                        onSaveSuccess(user)
                    }}
                    onRequestFailed={createUserFailed}
                >
                    {children}
                </WithFormState>
            )}
        </CreateUserRequest>
    )
}

export default CreateUserFormState

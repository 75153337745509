import { schema } from 'normalizr'

export const couponSchema = new schema.Entity('coupons', {})
export const couponSchemaArray = [couponSchema]

export const couponBatchSchema = new schema.Entity('couponBatches', {}, { idAttribute: 'batchUuid' })
export const couponBatchSchemaArray = [couponBatchSchema]

export const couponTimeFrameSchema = new schema.Entity('couponTimeFrames', {})
export const couponTimeFrameSchemaArray = [couponTimeFrameSchema]

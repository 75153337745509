import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import { MODAL_IDS } from 'util/constants'
import DeleteGameSessionModal from './DeleteGameSessionModal'

function DeleteGameSessionModalWithRedux() {
    return (
        <WithActiveModal modalId={MODAL_IDS.DELETE_GAME_SESSION}>
            {({
                isOpen,
                entity,
                closeModal,
            }) => (
                <DeleteGameSessionModal
                    isOpen={isOpen}
                    onClose={closeModal}
                    entity={entity}
                />
            )}
        </WithActiveModal>
    )
}

export default DeleteGameSessionModalWithRedux

import React from 'react'
import {
    EditOutlined,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
} from '@ant-design/icons'
import { Card, Col } from 'antd'
import * as theme from 'styles/theme'
import { ROLES } from 'util/constants'
import HasPermissions from 'components/pages/authorized/HasPermissions'
import EditVenueModalState from 'components/pages/settings/venues/modals/edit/EditVenueModalState'
import './VenueCard.css'

function VenueCard({
    venue,
}) {
    return (
        <Col style={{ padding: 8 }} md={12} lg={8}>
            <Card
                title={venue.name}
                extra={(
                    <HasPermissions roles={[ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER]}>
                        <EditVenueModalState venue={venue}>
                            {({ openModal }) => (
                                <EditOutlined
                                    onClick={openModal}
                                    style={{
                                        color: theme.colorGreyDarker,
                                        cursor: 'pointer',
                                    }}
                                />
                            )}
                        </EditVenueModalState>
                    </HasPermissions>
                )}
                bodyStyle={{
                    minHeight: '180px',
                    height: '100%',
                }}
            >
                <div className="venueContent">
                    <div className="venueItem">
                        <span><strong>Cost center:</strong></span>
                        <span className="txta-right">{venue.costCenterCode}</span>
                    </div>
                    <div className="venueItem">
                        <span><strong>Introduction:</strong></span>
                        <span className="txta-right">
                            {venue.introduction && venue.introduction.duration} minutes
                        </span>
                    </div>
                    <div className="venueItem">
                        <span><strong>Is public:</strong></span>
                        <span className="txta-right">
                            {venue.public ? (
                                <CheckCircleTwoTone twoToneColor={theme.colorSuccess} />
                            ) : (
                                <CloseCircleTwoTone twoToneColor={theme.colorAlert} />
                            )}
                        </span>
                    </div>
                    <div className="venueItem margin-bottom">
                        <span><strong>Code:</strong></span>
                        <span className="txta-right">
                            {venue.code}
                        </span>
                    </div>
                    <span>{venue.address}</span>
                </div>
            </Card>
        </Col>
    )
}

export default VenueCard

import createPaginationReducer from '../hor/createPaginationReducer'
import {
    VENUES_REQUEST,
    VENUES_SUCCESS,
    VENUES_FAILURE,
} from '../../actions/venues'

const venuesPaginationReducer = createPaginationReducer({
    mapActionToKey: (action) => action.viewName,
    types: [
        VENUES_REQUEST,
        VENUES_SUCCESS,
        VENUES_FAILURE,
    ],
})

export default venuesPaginationReducer

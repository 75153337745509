import React, { useState } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import {
    Input,
    Button,
    Radio,
    Tooltip,
    DatePicker,
} from 'antd'
import { ROLES } from 'util/constants'
import HasPermissions from '../../authorized/HasPermissions'
import './BookingsTableTopBar.css'
import {CreateBookingModalState} from "../modals/create/CreateBookingModalState"

const { Search, Group } = Input

export const ALL = 'all'
export const DAY = 'day'
export const PAST = 'past'
export const FUTURE = 'future'

export function BookingsTableTopBar({
    activeDateValue,
    activeDateFilter,
    searchValue,
    filterIsActive,
    onSelectAllBookings,
    onSelectPastBookings,
    onSelectFutureBookings,
    onSelectDate,
    onSearch,
    onClearFilters,
}) {
    const [searchFilter, setSearchFilter] = useState(searchValue)
    return (
        <div className="flex justify-content-space-between margin-bottom">
            <div className="leftItems">
                <CreateBookingModalState>
                    {({ openModal }) => (
                        <HasPermissions roles={[ROLES.FRANCHISE, ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.VENUE_MANAGER, ROLES.HOST]}>
                            <Button
                                type="primary"
                                onClick={openModal}
                            >
                                New Booking
                            </Button>
                        </HasPermissions>
                    )}
                </CreateBookingModalState>
            </div>
            <div className="rightItems">
                <div className="filterButtons">
                    <Group compact>
                        <DatePicker
                            value={activeDateValue}
                            onChange={onSelectDate}
                        />
                        <Radio.Group
                            value={activeDateFilter}
                            onChange={(e) => {
                                const { value } = e.target
                                switch (value) {
                                    case ALL:
                                        onSelectAllBookings()
                                        break
                                    case PAST:
                                        onSelectPastBookings()
                                        break
                                    case FUTURE:
                                        onSelectFutureBookings()
                                        break
                                    case DAY:
                                        break
                                    default:
                                        onSelectAllBookings()
                                }
                            }}
                        >
                            <Radio.Button value={PAST}>Past</Radio.Button>
                            <Radio.Button value={FUTURE}>Future</Radio.Button>
                            <Radio.Button value={ALL}>All</Radio.Button>
                        </Radio.Group>
                    </Group>
                </div>
                <div className="searchContainer">
                    <Search
                        value={searchFilter}
                        placeholder="Type to search bookings..."
                        onChange={(e) => setSearchFilter(e.target.value)}
                        onSearch={onSearch}
                    />
                </div>
                {filterIsActive && (
                    <div className="clearContainer">
                        <Tooltip title="Clear all filters">
                            <Button
                                type="danger"
                                icon={<CloseOutlined />}
                                onClick={onClearFilters}
                            />
                        </Tooltip>
                    </div>
                )}
            </div>
        </div>
    )
}

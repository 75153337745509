import React from "react"
import { Form } from "antd"
import FormItem from "components/util/forms/FormItem"
import { PriceInput } from "components/util/forms/PriceInput"
import "./OutstandingAmountForm.css"

/**
 * @component
 * @param {Object} props
 * @param {OutstandingAmountFormValues} props.values
 * @param {{ [key in keyof OutstandingAmountFormValues]: string[]}} props.errors
 * @param {(key: keyof OutstandingAmountFormValues, value: OutstandingAmountFormValues[key]) => void } props.onChangeAsValue
 * @returns {JSX.Element}
 */
export function OutstandingAmountForm(props) {
    return (
        <div className="outstandingAmountForm">
            <Form>
                <FormItem
                    isRequired
                    label="Outstanding amount"
                    error={props.errors.outstandingAmount}
                    labelCol={null}
                    wrapperCol={null}
                >
                    <PriceInput
                        min={-Infinity}
                        value={props.values.outstandingAmount}
                        onChange={(value) => props.onChangeAsValue("outstandingAmount", value)}
                    />
                </FormItem>
            </Form>
        </div>
    )
}

import { isRequired, isBefore, isAfter } from './index'

export const isValidWeekDay = (day) => (
    isRequired(day)
)

export const isValidStartTime = (isWholeDay, from) => (
    isWholeDay ? true : isRequired(from)
)

export const isValidEndTime = (isWholeDay, until) => (
    isWholeDay ? true : isRequired(until)
)

export const startIsBeforeEnd = (isWholeDay, from, until) => (
    isWholeDay ? true : isBefore(from, until)
)

export const endIsAfterstart = (isWholeDay, from, until) => (
    isWholeDay ? true : isAfter(until, from)
)

export const isValidCouponTimeFrame = (values) => (
    isValidWeekDay(values.day)
  && isValidStartTime(values.isWholeDay, values.from)
  && isValidEndTime(values.isWholeDay, values.until)
  && startIsBeforeEnd(values.isWholeDay, values.from, values.until)
  && endIsAfterstart(values.isWholeDay, values.from, values.until)
)

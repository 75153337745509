import React from 'react'
import { TopBar } from 'components/layout/TopBar'
import { Main } from  'components/layout/Main'
import GlobalSettingsContainer from 'components/layout/sidebar/GlobalSettingsContainer'
import HasPermissions from 'components/pages/authorized/HasPermissions'
import NotAuthorized from 'components/pages/authorized/NotAuthorized'
import { MODAL_IDS, ROLES } from 'util/constants'
import useModal from 'components/hooks/useModal'
import { useLegalEntity } from 'components/hooks/useLegalEntity'
import { useSelector } from 'react-redux'
import CouponsTableWithCoupons from './table/CouponsTableWithCoupons'
import CreateCouponTimeFrameModalWithState from './modals/create-time-frame/CreateCouponTimeFrameModalWithState'
import EditCouponTimeFrameModalWithState from './modals/edit-time-frame/EditCouponTimeFrameModalWithState'
import DeleteCouponTimeFrameModalWithState from './modals/delete-time-frame/DeleteCouponTimeFrameModalWithState'
import EditCouponModal from './modals/edit/EditCouponModal'
// import { usePaginatedVenues } from '../../../hooks/usePaginatedVenues'
import { useUserRole } from '../../../hooks/useCurrentUserRole'
import CreateCouponModal from './modals/create/CreateCouponModal'
import DeleteCouponModal from './modals/delete/DeleteCouponModal'

function CouponsPage() {
    const createCouponModal = useModal(MODAL_IDS.CREATE_COUPON)
    const editCouponModal = useModal(MODAL_IDS.EDIT_COUPON)
    const deleteCouponModal = useModal(MODAL_IDS.DELETE_COUPON)
    const activeLegalEntity = useLegalEntity()
    if (typeof activeLegalEntity === 'undefined')
        throw new Error(`Legal Entity is undefined! Comp: ${CouponsPage.name}`)
    const legalEntities = useSelector(state => state.entities.legalEntities)

    const userRole = useUserRole()

    return (
        <>
            <TopBar activeMenuItem="globalSettings" />
            <Main>
                <GlobalSettingsContainer activeMenuItem="coupons">
                    <HasPermissions
                        roles={[ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.READ_ONLY, ROLES.FRANCHISE]}
                        renderNotAuthorized={() => <NotAuthorized />}
                    >
                        <CouponsTableWithCoupons
                            currentUserRoleSlug={userRole !== null ? userRole.slug : ''}
                            openEditModal={editCouponModal.openModalWithEntity}
                            openDeleteModal={deleteCouponModal.openModalWithEntity}
                        />
                    </HasPermissions>
                    <CreateCouponModal
                        activeLegalEntity={activeLegalEntity}
                        legalEntities={legalEntities}
                        isOpen={createCouponModal.isOpen}
                        onClose={createCouponModal.closeModal}
                    />

                    <EditCouponModal
                        isOpen={editCouponModal.isOpen}
                        onClose={editCouponModal.closeModal}
                        legalEntities={legalEntities}
                        activeLegalEntity={activeLegalEntity}
                        coupon={editCouponModal.entity}
                    />
                    <DeleteCouponModal
                        isOpen={deleteCouponModal.isOpen}
                        onClose={deleteCouponModal.closeModal}
                        coupon={deleteCouponModal.entity}
                    />
                    <CreateCouponTimeFrameModalWithState />
                    <EditCouponTimeFrameModalWithState />
                    <DeleteCouponTimeFrameModalWithState />
                </GlobalSettingsContainer>
            </Main>
        </>
    )
}

export default CouponsPage

import React from 'react'
import Mount from 'components/util/Mount'
import DiscountBannersTableWithRedux from './DiscountBannersTableWithRedux'
import DiscountBannersTable from './DiscountBannersTable'
import DiscountBannersTopBar from './DiscountBannersTopBar'

const PAGE_SIZE = 50

function DiscountBannersTableWithState({
    paginationKey,
}) {
    return (
        <DiscountBannersTableWithRedux
            paginationKey={paginationKey}
            limit={PAGE_SIZE}
        >
            {({
                isFetching,
                params,
                discountBannersForCurrentPage,
                fetchDiscountBanners,
            }) => (
                <Mount
                    didMount={async () => {
                        fetchDiscountBanners(params)
                    }}
                >
                    <DiscountBannersTopBar />
                    <DiscountBannersTable
                        discountBanners={discountBannersForCurrentPage}
                        isFetching={isFetching}
                    />
                </Mount>
            )}
        </DiscountBannersTableWithRedux>
    )
}

export default DiscountBannersTableWithState

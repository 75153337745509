import { notification } from "antd"
import { createBookingForActiveVenue, fetchBookingsExtendCurrentParams } from "store/actions/bookings"
import withReduxState from "components/hoc/withReduxState"

const mapDispatchToProps = (dispatch, ownProps) => ({
    createBooking: (body) => dispatch(createBookingForActiveVenue(body)),
    createBookingSuccess: (/** BookingResource */ booking) => {
        notification.success({
            message: "Booking created!",
            description: `Booking for ${booking.bookerEmail} was successfully created.`,
        })
    },
    createBookingFailed: (error) => {
        notification.error({
            message: "Something went wrong",
            description: `${error.message}`,
        })
    },
    fetchBookings: () => {
        const { paginationKey } = ownProps
        return dispatch(fetchBookingsExtendCurrentParams(paginationKey))
    },
})

const CreateBookingRequest = withReduxState(null, mapDispatchToProps)

export default CreateBookingRequest

import React from 'react'
import {
    createCouponBatchTimeFrame,
    fetchCouponBatchesExtendCurrentParams,
    editCouponBatchTimeFrame,
    deleteCouponTimeFrame,
} from 'store/actions/coupons'
import { notification } from 'antd'
import { useDispatch } from 'react-redux'

export default function useCouponBatchTimeFrame(paginationKey, { couponBatch, couponTimeFrame }) {
    const dispatch = useDispatch()

    return ({
        createCouponBatchTimeFrame: (values) => (
            dispatch(createCouponBatchTimeFrame(couponBatch.batchUuid, values))
        ),
        createCouponBatchTimeFrameSuccess: () => {
            notification.success({
                message: 'Timeframe added!',
                description: (
                    <span>
                        The timeframe was successfully added to the coupon batch!
                    </span>
                ),
            })
        },
        createCouponBatchTimeFrameFailed: ({ message }) => {
            notification.error({
                message: 'Something went wrong',
                description: message,
            })
        },
        editCouponBatchTimeFrame: (couponTimeFrameId, body) => (
            dispatch(editCouponBatchTimeFrame(couponTimeFrame.batchUuid, couponTimeFrameId, body))
        ),
        editCouponBatchTimeFrameSuccess: () => {
            notification.success({
                message: 'Timeframe edited!',
                description: 'The timeframe for the coupon batch was successfully edited.',
            })
        },
        editCouponBatchTimeFrameFailed: ({ message }) => {
            notification.error({
                message: 'Something went wrong',
                description: message,
            })
        },
        deleteCouponTimeFrame: (timeFrame) => (
            dispatch(deleteCouponTimeFrame(timeFrame.id))
        ),
        deleteCouponTimeFrameSuccess: () => {
            notification.success({
                message: 'Timeframe deleted!',
                description: (
                    <span>
                        The selected time frame is succesfully deleted.
                    </span>
                ),
            })
        },
        deleteCouponTimeFrameFailed: ({ message }) => {
            notification.error({
                message: 'Something went wrong',
                description: message,
            })
        },
        fetchCouponBatches: async () => (
            dispatch(fetchCouponBatchesExtendCurrentParams(paginationKey))
        ),
    })
}

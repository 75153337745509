import { getPagination, getEntitiesByPagination, getEntitiesForCurrentPage } from 'store/util'
import { fetchRoles } from 'store/actions/roles'
import withReduxState from 'components/hoc/withReduxState'

const mapStateToProps = (state, ownProps) => {
    const pagination = getPagination(state, 'roles', ownProps.paginationKey)
    return ({
        isFetching: pagination.isFetching,
        currentPage: pagination.params.page,
        total: pagination.total,
        params: pagination.params,
        roles: getEntitiesByPagination(state, 'roles', ownProps.paginationKey),
        rolesForCurrentPage: getEntitiesForCurrentPage(state, 'roles', ownProps.paginationKey),
    })
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const {
        paginationKey,
        limit,
    } = ownProps
    const page = 1
    return ({
        fetchRoles: (params) => {
            const finalParams = {
                page,
                limit,
                ...params,
            }
            dispatch(fetchRoles(paginationKey, finalParams))
        },
    })
}

const WithRoles = withReduxState(mapStateToProps, mapDispatchToProps)

export default WithRoles

import React from 'react'
import { TopBar } from 'components/layout/TopBar'
import { Main } from  'components/layout/Main'
import Loading from 'components/util/Loading'
import Mount from 'components/util/Mount'
import Rooms from 'components/pages/settings/venue/Rooms'
import CreateGameSessionModalWithState from './modals/create/CreateGameSessionModalWithState'
import DeleteGameSessionModalWithRedux from './modals/delete/DeleteGameSessionModalWithRedux'
import ToggleActiveGameSessionModalWithState from './modals/toggle-active/ToggleActiveGameSessionModalWithState'
import GameSessionsTableWithData from './table/GameSessionsTableWithData'

const PAGE_SIZE = 100

function GameSessionsPage() {
    return (
        <>
            <TopBar activeMenuItem="gameSessions" />
            <Main>
                <Rooms paginationKey="select" limit={PAGE_SIZE}>
                    {({ isFetching, fetchRooms }) => (
                        <Mount didMount={fetchRooms}>
                            {isFetching
                                ? <Loading />
                                : <GameSessionsTableWithData paginationKey="overview" />}
                        </Mount>
                    )}
                </Rooms>
            </Main>
            <CreateGameSessionModalWithState />
            <DeleteGameSessionModalWithRedux />
            <ToggleActiveGameSessionModalWithState />
        </>
    )
}

export default GameSessionsPage

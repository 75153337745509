import withReduxState from 'components/hoc/withReduxState'
import { fetchGames } from 'store/actions/games'
import { getEntitiesByPagination, getPagination, getEntitiesForCurrentPage } from 'store/util/index'

const mapStateToProps = (state, ownProps) => {
    const pagination = getPagination(state, 'games', ownProps.paginationKey)
    return ({
        isFetching: pagination.isFetching,
        currentPage: pagination.params.page,
        total: pagination.total,
        params: pagination.params,
        games: getEntitiesByPagination(state, 'games', ownProps.paginationKey),
        gamesForCurrentPage: getEntitiesForCurrentPage(state, 'games', ownProps.paginationKey),
    })
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const {
        paginationKey,
        limit,
    } = ownProps
    const page = 1
    return ({
        fetchGames: (params) => {
            const finalParams = {
                page,
                limit,
                ...params,
            }
            return dispatch(fetchGames(paginationKey, finalParams))
        },
    })
}

const WithGames = withReduxState(mapStateToProps, mapDispatchToProps)

export default WithGames

import { CALL_API, METHODS } from '../middleware/api'
import {
    couponBatchSchema,
    couponSchema,
    couponSchemaArray,
    couponTimeFrameSchema,
    couponBatchSchemaArray,
} from '../schemas/coupons'

export const COUPON_REQUEST = 'COUPON_REQUEST'
export const COUPON_SUCCESS = 'COUPON_SUCCESS'
export const COUPON_FAILURE = 'COUPON_FAILURE'

export const COUPONS_REQUEST = 'COUPONS_REQUEST'
export const COUPONS_SUCCESS = 'COUPONS_SUCCESS'
export const COUPONS_FAILURE = 'COUPONS_FAILURE'

export const COUPON_BATCH_REQUEST = 'COUPON_BATCH_REQUEST'
export const COUPON_BATCH_SUCCESS = 'COUPON_BATCH_SUCCESS'
export const COUPON_BATCH_FAILURE = 'COUPON_BATCH_FAILURE'

export const COUPON_BATCHES_REQUEST = 'COUPON_BATCHES_REQUEST'
export const COUPON_BATCHES_SUCCESS = 'COUPON_BATCHES_SUCCESS'
export const COUPON_BATCHES_FAILURE = 'COUPON_BATCHES_FAILURE'

export function fetchCoupons(viewName, params) {
    return {
        viewName,
        [CALL_API]: {
            types: [
                COUPONS_REQUEST,
                COUPONS_SUCCESS,
                COUPONS_FAILURE,
            ],
            endpoint: '/coupons',
            schema: couponSchemaArray,
            params,
        },
    }
}

export const fetchCouponsExtendCurrentParams = (paginationKey, params) => (dispatch, getState) => {
    const couponPagination = getState().pagination.coupons[paginationKey]
    const newParams = {
        ...couponPagination.params,
        ...params,
    }
    return dispatch(fetchCoupons(paginationKey, newParams))
}

export function fetchCouponBatches(viewName, params) {
    return {
        viewName,
        [CALL_API]: {
            types: [
                COUPON_BATCHES_REQUEST,
                COUPON_BATCHES_SUCCESS,
                COUPON_BATCHES_FAILURE,
            ],
            endpoint: '/coupons-batch',
            schema: couponBatchSchemaArray,
            params,
        },
    }
}

export function fetchCouponBatchesExtendCurrentParams() {
    return (paginationKey, params) => (dispatch, getState) => {
        const couponBatchPagination = getState().pagination.couponBatches[paginationKey]
        const newParams = {
            ...couponBatchPagination.params,
            ...params,
        }
        return dispatch(fetchCouponBatches(paginationKey, newParams))
    }
}

export function createCoupon(body) {
    return {
        [CALL_API]: {
            method: METHODS.POST,
            types: [
                COUPON_REQUEST,
                COUPON_SUCCESS,
                COUPON_FAILURE,
            ],
            endpoint: '/coupons',
            schema: couponSchema,
            body,
        },
    }
}

export function editCoupon(couponId, body) {
    return {
        [CALL_API]: {
            method: METHODS.PUT,
            types: [
                COUPON_REQUEST,
                COUPON_SUCCESS,
                COUPON_FAILURE,
            ],
            endpoint: `/coupons/${couponId}`,
            schema: couponSchema,
            body,
        },
    }
}

export function createCouponBatch(body) {
    return {
        [CALL_API]: {
            method: METHODS.POST,
            types: [
                COUPON_BATCH_REQUEST,
                COUPON_BATCH_SUCCESS,
                COUPON_BATCH_FAILURE,
            ],
            endpoint: '/coupons-batch',
            schema: couponBatchSchema,
            body,
        },
    }
}

export function deleteCoupon(couponId) {
    return {
        [CALL_API]: {
            method: METHODS.DELETE,
            types: [
                COUPON_REQUEST,
                COUPON_SUCCESS,
                COUPON_FAILURE,
            ],
            endpoint: `/coupons/${couponId}`,
            schema: couponSchema,
            entityId: couponId,
        },
    }
}

export function deleteCouponBatch(batchId) {
    return {
        [CALL_API]: {
            method: METHODS.DELETE,
            types: [
                COUPON_BATCH_REQUEST,
                COUPON_BATCH_SUCCESS,
                COUPON_BATCH_FAILURE,
            ],
            endpoint: `/coupons-batch/${batchId}`,
            schema: couponBatchSchema,
            entityId: batchId,
        },
    }
}

export function createCouponTimeFrame(couponId, body) {
    return {
        [CALL_API]: {
            method: METHODS.POST,
            types: [
                COUPON_REQUEST,
                COUPON_SUCCESS,
                COUPON_FAILURE,
            ],
            endpoint: `/coupons/${couponId}/time-frames`,
            schema: couponTimeFrameSchema,
            body,
        },
    }
}

export function createCouponBatchTimeFrame(couponId, body) {
    return {
        [CALL_API]: {
            method: METHODS.POST,
            types: [
                COUPON_BATCH_REQUEST,
                COUPON_BATCH_SUCCESS,
                COUPON_BATCH_FAILURE,
            ],
            endpoint: `/coupons-batch/${couponId}/time-frames`,
            schema: couponTimeFrameSchema,
            body,
        },
    }
}

export function deleteCouponTimeFrame(timeFrameId) {
    return {
        [CALL_API]: {
            method: METHODS.DELETE,
            types: [
                COUPON_REQUEST,
                COUPON_SUCCESS,
                COUPON_FAILURE,
            ],
            endpoint: `/coupons/time-frames/${timeFrameId}`,
            schema: couponTimeFrameSchema,
            entityId: timeFrameId,
        },
    }
}

export function editCouponTimeFrame(couponId, timeFrameId, body) {
    return {
        [CALL_API]: {
            method: METHODS.PUT,
            types: [
                COUPON_REQUEST,
                COUPON_SUCCESS,
                COUPON_FAILURE,
            ],
            endpoint: `/coupons/${couponId}/time-frames/${timeFrameId}`,
            schema: couponTimeFrameSchema,
            body,
        },
    }
}

export function editCouponBatch(batchId, body) {
    return {
        [CALL_API]: {
            method: METHODS.PUT,
            types: [
                COUPON_BATCH_REQUEST,
                COUPON_BATCH_SUCCESS,
                COUPON_BATCH_FAILURE,
            ],
            endpoint: `/coupons-batch/${batchId}`,
            schema: couponBatchSchema,
            body,
        },
    }
}

export function editCouponBatchTimeFrame(batchId, timeFrameId, body) {
    return {
        [CALL_API]: {
            method: METHODS.PUT,
            types: [
                COUPON_BATCH_REQUEST,
                COUPON_BATCH_SUCCESS,
                COUPON_BATCH_FAILURE,
            ],
            endpoint: `/coupons-batch/${batchId}/time-frames/${timeFrameId}`,
            schema: couponTimeFrameSchema,
            body,
        },
    }
}

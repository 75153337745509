import React from 'react'
import { Form, Input, InputNumber } from 'antd'
import FormItem from 'components/util/forms/FormItem'

function CreateRoomForm({
    values,
    errors,
    onChange,
    onChangeAsValue,
}) {
    return (
        <Form>
            <FormItem
                label="Name"
                error={errors.name}
                required
            >
                <Input
                    name="name"
                    value={values.name}
                    onChange={onChange}
                />
            </FormItem>
            <FormItem
                label="Seats"
                error={errors.seats}
                required
            >
                <InputNumber
                    name="seats"
                    value={values.seats}
                    onChange={(value) => onChangeAsValue('seats', value)}
                    min={1}
                    max={12}
                />
            </FormItem>
            <FormItem
                label="Slot duration"
                error={errors.slotDuration}
                required
            >
                <InputNumber
                    name="slotDuration"
                    value={values.slotDuration}
                    onChange={(value) => onChangeAsValue('slotDuration', value)}
                    min={1}
                    step={1}
                />
            </FormItem>
        </Form>
    )
}

export default CreateRoomForm

import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveVenue, setActiveRoom, setActiveLegalEntity } from 'store/actions/filters'
import { useSearchParams } from 'react-router-dom'

export function useActiveFilters() {
    const dispatch = useDispatch()
    const stateActiveFilters = useSelector(state => state.activeFilters)
    const [searchParams, setSearchParams] = useSearchParams()

    const setVenueIdSearchParam = useCallback((newVenueId) => {
        if (typeof newVenueId !== 'number')
            return

        setSearchParams({ venueId: newVenueId.toString() })
    }, [setSearchParams])

    const handleSetActiveVenue = useCallback((newVenueId) => {
        dispatch(setActiveVenue(newVenueId))
        setVenueIdSearchParam(newVenueId)
    }, [dispatch, setVenueIdSearchParam])

    const handleSetActiveRoom = useCallback((roomId) => {
        dispatch(setActiveRoom(roomId))
    }, [dispatch])
    const handleSetActiveLegalEntity = useCallback((legalEntityId) => {
        dispatch(setActiveLegalEntity(legalEntityId))
    }, [dispatch])

    return {
        ...stateActiveFilters,
        venueId: stateActiveFilters.venueId,
        handleSetActiveRoom: handleSetActiveRoom,
        handleSetActiveVenue: handleSetActiveVenue,
        handleSetActiveLegalEntity: handleSetActiveLegalEntity,
    }
}

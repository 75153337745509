import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import './AnalyticsSideBarMenu.css'

function AnalyticsSideBarMenu({ activeMenuItem }) {
    const items = useMemo(() => [
        {
            label: (
                <Link to="/analytics/sales">
                    Sales
                </Link>
            ),
            key: 'sales',
        },
        {
            label: (
                <Link to="/analytics/marketing">
                    Marketing
                </Link>
            ),
            key: 'marketing',
        },
        {
            label: (
                <Link to="/analytics/venue-operations">
                    Venue Operations
                </Link>
            ),
            key: 'venueOperations',
        },
        {
            label: (
                <Link to="/analytics/venue-occupation">
                    Venue Occupation
                </Link>
            ),
            key: 'venueOccupation',
        },
    ], [])

    return (
        <div className="analyticsSideBarMenu">
            <Menu
                mode="vertical"
                selectedKeys={[activeMenuItem]}
                style={{ borderRight: 0 }}
                items={items}
            />
        </div>
    )
}

export default AnalyticsSideBarMenu

import { useState, useEffect } from 'react'
import * as storage from 'util/storage'

const getProps = async () => {
    const headers = []
    const accessToken = await storage.getAccessToken()
    headers.authorization = `Bearer ${accessToken}`

    return {
        accept: 'image/*',
        action: `${process.env.REACT_APP_API}/api/internal/upload`,
        headers,
        name: 'file',
    }
}

const useUploadProps = () => {
    const [uploadProps, setUploadProps] = useState(null)
    const loadUploadProps = async () => {
        const newProps = await getProps()
        setUploadProps(newProps)
    }

    useEffect(() => {
        loadUploadProps()
    }, [])

    return uploadProps
}

export default useUploadProps

import { CALL_API } from '../middleware/api'
import { userSchema } from '../schemas/users'

export const ME_REQUEST = 'ME_REQUEST'
export const ME_SUCCESS = 'ME_SUCCESS'
export const ME_FAILURE = 'ME_FAILURE'

export const fetchMe = () => ({
    [CALL_API]: {
        types: [
            ME_REQUEST,
            ME_SUCCESS,
            ME_FAILURE,
        ],
        endpoint: '/me',
        schema: userSchema,
        minRequestTime: 1200,
    },
})

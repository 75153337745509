import React from 'react'
import { EditOutlined, DownloadOutlined } from '@ant-design/icons'
import { Table, Button } from 'antd'
import { sorterToSortParam } from 'util/index'
import WithMe from 'components/pages/authorized/WithMe'
import dayjs from 'dayjs'
import CouponBatchDetails from './CouponBatchDetails'
import { APP_DATE_FORMAT } from '../../../../../util/constants'

function columns({
    onDelete,
    onDownload,
    isDownloading,
    onEdit,
}) {
    return [
        {
            title: 'Amount',
            dataIndex: 'amount',
            width: 100,
            render: (text, { isPercentage }) => {
                if (isPercentage) {
                    const readable = (text * 100).toFixed(0)
                    return `${readable}%`
                } else {
                    return text
                }
            },
        },
        {
            title: 'Description',
            width: 200,
            dataIndex: 'description',
        },
        {
            title: 'Expires',
            dataIndex: 'expiresAt',
            width: 110,
            render: (expiresAt) => expiresAt && dayjs.utc(expiresAt).format(APP_DATE_FORMAT),
        },
        {
            title: 'Used',
            dataIndex: 'couponCount',
            width: 110,
            render: (couponCount, { usedCouponCount }) => `${usedCouponCount}/${couponCount}`,
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: 80,
            render: (text, record) => (
                <WithMe>
                    {({ isAdmin, isLegalEntityManager }) => (
                        <>
                            {/* <Button
                            ghost
                            icon={<DeleteOutlined />}
                            type="danger"
                            shape="circle"
                            size="small"
                            className="ant-btn-no-border"
                            disabled={!isAdmin && !isLegalEntityManager}
                            onClick={() => onDelete(record)}
                        /> */}

                            {dayjs.utc().isAfter(dayjs.utc(record.expiresAt)) === false && (
                                <Button
                                    ghost
                                    icon={<EditOutlined />}
                                    type="primary"
                                    shape="circle"
                                    size="small"
                                    className="ant-btn-no-border"
                                    disabled={!isAdmin && !isLegalEntityManager}
                                    onClick={() => onEdit.openModalWithEntity(record)}
                                />
                            )}
                            <Button
                                ghost
                                icon={<DownloadOutlined />}
                                type="primary"
                                shape="circle"
                                size="small"
                                className="ant-btn-no-border"
                                disabled={isDownloading || (!isAdmin && !isLegalEntityManager)}
                                onClick={() => onDownload({
                                    endpoint: `/coupons-batch/export/${record.batchUuid}`,
                                    saveAs: `${record.batchUuid}.xlsx`,
                                })}
                            />
                        </>
                    )}
                </WithMe>
            ),
        },
    ]
}

function expandedRowRender(couponBatch) {
    return <CouponBatchDetails couponBatch={couponBatch} />
}

function CouponsBatchTable({
    coupons,
    isFetching,
    onSort,
    onDelete,
    onDownload,
    isDownloading,
    onEdit,
}) {
    return (
        <Table
            rowKey="batchUuid"
            rowClassName="cursor-pointer"
            columns={columns({
                onDelete, onDownload, isDownloading, onEdit,
            })}
            loading={isFetching}
            dataSource={coupons}
            pagination={false}
            expandable={{
                expandedRowRender: expandedRowRender,
                expandRowByClick: true,
                expandIcon: () => null,
            }}
            onChange={(pagination, filters, sorter) => {
                if (typeof sorter.columnKey !== 'undefined') {
                    onSort(sorterToSortParam(sorter))
                } else {
                    onSort(null)
                }
            }}
        />
    )
}

export default CouponsBatchTable

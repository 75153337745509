import React from 'react'
import SidebarContainer from './SidebarContainer'
import AnalyticsSideBarMenu from './AnalyticsSideBarMenu'

function AnalyticsContainer({
    children,
    activeMenuItem,
}) {
    return (
        <SidebarContainer
            sidebar={<AnalyticsSideBarMenu activeMenuItem={activeMenuItem} />}
        >
            {children}
        </SidebarContainer>
    )
}

export default AnalyticsContainer

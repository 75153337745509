import { PLAYER_AND_TOTAL } from 'util/constants'
import { createValidationErrorObject } from './index'
import * as validation from './venueGame'

export const getGameIdError = createValidationErrorObject(
    validation.isValidGameId,
    'gameId',
    'Please select a game.',
)

export const getBasePlayerPriceError = createValidationErrorObject(
    validation.isValidBasePlayerPrice,
    'basePlayerPrice',
    'Please fill in a valid amount.',
)

export const getBaseTotalPriceError = createValidationErrorObject(
    validation.isValidBaseTotalPrice,
    'baseTotalPrice',
    'Please fill in a valid amount.',
)

export function getVenueGameErrors(values, priceSystem) {
    const errors = {
        ...getGameIdError(values.gameId),
        ...getBasePlayerPriceError(values.basePlayerPrice),
    }
    return errors
}

export const getEditVenueGameErrors = (values, priceSystem) => {
    const errors = {
        ...getBasePlayerPriceError(values.basePlayerPrice),
    }

    if (priceSystem === PLAYER_AND_TOTAL) {
        errors.push(getBaseTotalPriceError(values.baseTotalPrice))
    }
    return errors
}

import React from 'react'
import debounce from 'lodash.debounce'
import { Select, Spin, Tag } from 'antd'
import State from 'components/util/WithState'
import BookingsRequest from 'components/pages/bookings/requests/BookingsRequest'
import { fromToday, tillFuture, tillToday } from 'util/index'
import { useActiveFilters } from 'components/hooks/useActiveFilters'
import dayjs from 'dayjs'
import { APP_TIME_FORMAT, APP_MONTH_DAY_FORMAT } from '../../../../util/constants'
import './SearchBooking.css'

const { Option } = Select
const LIMIT = 100

function SearchBooking({
    onChange,
    bookingId,
    paginationKey,
    activeDateFilter,
}) {
    const getRoomForBooking = (bookings, id) => {
        const chosenBooking = bookings.find((b) => b.id === id)
        const roomForBooking = chosenBooking.room.id
        return roomForBooking
    }
    const { handleSetActiveRoom } = useActiveFilters()

    return (
        <div>
            <BookingsRequest paginationKey={paginationKey} limit={LIMIT}>
                {({ fetchBookings, isFetching, bookingsForCurrentPage }) => (
                    <State
                        key={bookingId}
                        initialState={{ value: bookingId }}
                    >
                        {({ state, setState }) => (
                            <Select
                                allowClear
                                className="full-width"
                                defaultActiveFirstOption={false}
                                filterOption={false}
                                notFoundContent={isFetching && <Spin size="small" />}
                                onChange={(id) => {
                                    if (typeof id !== 'undefined') {
                                        handleSetActiveRoom(
                                            getRoomForBooking(bookingsForCurrentPage, id),
                                        )
                                    }
                                    onChange(id)
                                    setState({
                                        value: id,
                                    })
                                }}
                                onSearch={debounce(async (search) => {
                                    let searchParams = {}
                                    if (activeDateFilter === 'future') {
                                        searchParams = {
                                            from: fromToday,
                                            till: tillFuture,
                                        }
                                    }
                                    if (activeDateFilter === 'today') {
                                        searchParams = {
                                            from: fromToday,
                                            till: tillToday,
                                        }
                                    }
                                    if (search) {
                                        searchParams = { ...searchParams, search }
                                    }

                                    await fetchBookings(searchParams)
                                }, 250)}
                                placeholder="Find by booking"
                                showSearch
                                suffixIcon={null}
                                value={state.value}
                            >
                                {bookingsForCurrentPage.map((option) => (
                                    <Option
                                        value={option.id}
                                        key={option.id}
                                    >
                                        <div className="option">
                                            <span>{option.personFullName}</span>
                                            <span>
                                                {dayjs.utc(option.start).format(APP_TIME_FORMAT)}
                                                -
                                                {dayjs.utc(option.end).format(APP_TIME_FORMAT)}
                                            </span>
                                            <Tag>
                                                {dayjs.utc(option.start).format(APP_MONTH_DAY_FORMAT)}
                                            </Tag>
                                        </div>
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </State>
                )}
            </BookingsRequest>
        </div>
    )
}

export default SearchBooking

import { createValidationErrorObject } from './index'
import * as validation from './user'

export const getFirstNameError = createValidationErrorObject(
    validation.isValidFirstName,
    'firstName',
    'Please provide a valid first name.',
)

export const getLastNameError = createValidationErrorObject(
    validation.isValidLastName,
    'lastName',
    'Please provide a valid last name.',
)

export const getEmailError = createValidationErrorObject(
    validation.isValidPersonEmail,
    'email',
    'Please provide a valid email.',
)

export const getRoleError = createValidationErrorObject(
    validation.isValidRole,
    'role',
    'Please select a valid role.',
)

export const getVenuesError = createValidationErrorObject(
    validation.isValidVenues,
    'venues',
    'Please select venues.',
)

export const getLegalEntitiesError = createValidationErrorObject(
    validation.isValidLegalEntities,
    'legalEntities',
    'Please select legal entities.',
)

export const getUserErrors = (values) => ({
    ...getFirstNameError(values.firstName),
    ...getLastNameError(values.lastName),
    ...getEmailError(values.email),
    ...getRoleError(values.role),
    ...getVenuesError(values.venues),
    ...getLegalEntitiesError(values.legalEntities),
})

export const getEditUserErrors = (values) => ({
    ...getFirstNameError(values.firstName),
    ...getLastNameError(values.lastName),
    ...getEmailError(values.email),
    ...getRoleError(values.role),
    ...getVenuesError(values.venues),
    ...getLegalEntitiesError(values.legalEntities),
})

import React from 'react'
import {OutstandingAmountModalState} from "./OutstandingAmountModalState"
import {OutstandingAmountModal} from "./OutstandingAmountModal"
import {OutstandingAmountFormState} from "../../forms/outstandingAmount/OutstandingAmountFormState"

export function OutstandingAmountModalWithState() {
    return (
        <OutstandingAmountModalState>
            {({
                isOpen,
                closeModal,
                booking,
            }) => (
                <OutstandingAmountFormState
                    onEditSuccess={closeModal}
                    onCancel={closeModal}
                    booking={booking}
                >
                    {(formChildProps) => (
                        <OutstandingAmountModal
                            isOpen={isOpen}
                            onClose={closeModal}
                            booking={booking}
                            values={formChildProps.values}
                            errors={formChildProps.errors}
                            isLoading={formChildProps.isLoading}
                            onChangeAsValue={formChildProps.handleChangeAsValue}
                            onCancel={formChildProps.handleCancel}
                            onSubmit={formChildProps.handleSubmit}
                        />
                    )}
                </OutstandingAmountFormState>
            )}
        </OutstandingAmountModalState>
    )
}

import useFormState from 'use-form-state'
import { LANGUAGES_ARRAY } from 'util/constants'
import { capitalize } from 'util/index'

/**
 * @typedef UseFormFormState Typing of Wappla's own form state hook
 * @property {Object[]} errors
 * @property {(key: string) => string[]} getErrorMessages Get the error messages returned by backend validation if any
 * @property {(errors: Object) => void} updateErrors Function to update the errors in the form
 * @property {(key: string) => boolean} hasError Does the key in the form values object have an error?
 *
 * @property {(keys?: string[]) => boolean} validate Validate the form, optionally define which keys
 * @property {boolean} isValid Is the form valid?
 *
 * @property {boolean} isDirty Has the form changed?
 * @property {boolean} isPristine Is the form unchanged?
 *
 * @property {Object} values The current form values
 * @property {(values: Object) => void} setValues Overwrite current form values
 * @property {(key: string, value: any) => void} handleChange Update value in form by key and value
 * @property {(e: any) => void} handleNativeChange Update value in form by using the native event
 *
 * @property {() => void} resetForm Reset the form
 *
 * @see https://www.npmjs.com/package/use-form-state
 * @see https://github.com/wappla/use-form-state/blob/master/src/useFormState.js
 */

/**
 * @typedef UseDynamicLanguageFormStateForm The additional functions of this hook
 * @property {(name: string, key: string, value: any) => void} onDynamicFieldChange
 * @property {(name: string, key: string) => void} onDynamicFieldRemove
 * @property {(name: string) => void} onDynamicFieldAdd
 */

/**
 * @param initialState
 * @param options
 * @return {UseFormFormState & UseDynamicLanguageFormStateForm}
 */
const useDynamicLanguageFormState = (
    initialState,
    options,
) => {
    /** @type {UseFormFormState} */
    const formState = useFormState(initialState, options)

    function onDynamicFieldChange(name, key, value) {
        const newValues = [...formState.values[name]]
        newValues[key] = value
        formState.handleChange(name, newValues)
    }

    function onDynamicFieldRemove(name, key) {
        const newValues = { ...formState.values }
        LANGUAGES_ARRAY.forEach((lang) => {
            const langCapitalized = capitalize(lang)
            const newFields = [...newValues[`${name}${langCapitalized}`]]
            newFields.splice(key, 1)
            newValues[`${name}${langCapitalized}`] = newFields
        })
        formState.setValues(newValues)
    }

    function onDynamicFieldAdd(name) {
        const newValues = { ...formState.values }
        LANGUAGES_ARRAY.forEach((lang) => {
            const langCapitalized = capitalize(lang)
            const newFields = [...newValues[`${name}${langCapitalized}`], '']
            newValues[`${name}${langCapitalized}`] = newFields
        })
        formState.setValues(newValues)
    }

    return {
        ...formState,
        onDynamicFieldChange,
        onDynamicFieldRemove,
        onDynamicFieldAdd,
    }
}

export default useDynamicLanguageFormState

export function toVenueGameApiRequest(values) {
    return { ...values }
}

export function toEditVenueGameApiRequest(values) {
    // console.log('edit request values:', values)
    const newValues = { ...values }
    // console.log('new values:', values)
    return newValues
}

export function mapVenueGameToValues(values) {
    return { ...values }
}

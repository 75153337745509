import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import { getEntityFromResponse } from 'util/index'
import { isValidCouponTimeFrame } from 'util/validation/coupon'
import { getCouponTimeFrameErrors } from 'util/validation/couponErrors'
import { toCouponTimeFrameApiRequest, mapCouponTimeFrameToValues } from 'util/transformers/coupon'
import useCouponBatchTimeFrame from '../../hooks/useCouponBatchTimeFrame'

function EditCouponBatchTimeFrameFormState({
    onCancel,
    onEditSuccess = () => {},
    children,
    couponTimeFrame,
}) {
    const {
        fetchCouponBatches,
        editCouponBatchTimeFrame,
        editCouponBatchTimeFrameSuccess,
        editCouponBatchTimeFrameFailed,
    } = useCouponBatchTimeFrame('overview', { couponTimeFrame })

    if (couponTimeFrame === null || typeof couponTimeFrame === 'undefined') {
        return null
    }
    return (
        <WithFormState
            key={couponTimeFrame && couponTimeFrame.id}
            initialValues={{
                ...mapCouponTimeFrameToValues(couponTimeFrame),
            }}
            isValidForm={isValidCouponTimeFrame}
            getFormErrors={getCouponTimeFrameErrors}
            transformValuesToRequestBody={toCouponTimeFrameApiRequest}
            onSendRequest={(values) => editCouponBatchTimeFrame(couponTimeFrame.id, values)}
            onCancel={onCancel}
            onRequestSuccess={(response) => {
                fetchCouponBatches()
                const updatedCouponTimeFrame = getEntityFromResponse(response, 'couponTimeFrames')
                editCouponBatchTimeFrameSuccess(updatedCouponTimeFrame)
                onEditSuccess(updatedCouponTimeFrame)
            }}
            onRequestFailed={editCouponBatchTimeFrameFailed}
        >
            {children}
        </WithFormState>
    )
}

export default EditCouponBatchTimeFrameFormState

import React from 'react'
import {
    CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, EditOutlined,
} from '@ant-design/icons'
import { Table, Button, Tooltip } from 'antd'
import * as theme from 'styles/theme'
import { APP_DATE_FORMAT, ROLES } from 'util/constants'
import { sorterToSortParam } from 'util/index'
import CouponDetails from 'components/pages/settings/coupons/details/CouponDetails'
import Clipboard from 'components/util/Clipboard'
import dayjs from 'dayjs'

const renderIsMultiUseColumn = (isMultiUse, { usageLimit }) => {
    if (isMultiUse) {
        return (
            <Tooltip title={usageLimit !== null && `Usage limit: ${usageLimit}`}>
                <CheckCircleOutlined style={{ color: theme.colorPrimary }} />
            </Tooltip>
        )
    }

    return <CloseCircleOutlined style={{ color: theme.colorGrey }} />
}

const allowedEditRoleSlugs = [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.FRANCHISE]
const allowedDeleteRoleSlugs = [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.FRANCHISE]

/**
 * @param {string} currentUserRoleSlug
 * @param openEditModal
 * @param openDeleteModal
 */
function columns(
    currentUserRoleSlug,
    openEditModal,
    openDeleteModal,
) {
    return [
        {
            title: 'Code',
            dataIndex: 'code',
            render: (text) => (
                <Clipboard text={text} tooltip="Copy to clipboard">
                    <strong>{text}</strong>
                </Clipboard>
            ),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            align: 'center',
            render: (text, { isPercentage }) => (isPercentage ? `${(text * 100).toFixed(0)}%` : text),
        },
        {
            title: 'Multi',
            width: 100,
            dataIndex: 'isMultiUse',
            key: 'isMultiUse',
            align: 'center',
            render: renderIsMultiUseColumn,
            sorter: true,
        },
        {
            title: 'Expires',
            dataIndex: 'expiresAt',
            key: 'expiresAt',
            width: 110,
            render: (expiresAt) => expiresAt && dayjs.utc(expiresAt)
                .format(APP_DATE_FORMAT),
            sorter: true,
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: 80,
            render: (text, coupon) => {
                let canEdit = allowedEditRoleSlugs.indexOf(currentUserRoleSlug) !== -1
                let canDelete = allowedDeleteRoleSlugs.indexOf(currentUserRoleSlug) !== -1

                if (typeof coupon.expiresAt === 'string') {
                    const now = dayjs.utc()
                    const expiresAt = dayjs.utc(coupon.expiresAt)
                    const hasExpired = now.isAfter(expiresAt)
                    if (hasExpired) {
                        canDelete = false
                        canEdit = false
                    }
                }

                return (
                    <>
                        <Button
                            ghost
                            icon={<EditOutlined />}
                            type="primary"
                            shape="circle"
                            size="small"
                            className="ant-btn-no-border"
                            disabled={!canEdit}
                            onClick={() => openEditModal(coupon)}
                        />

                        <Button
                            ghost
                            icon={<DeleteOutlined />}
                            danger={true}
                            shape="circle"
                            size="small"
                            className="ant-btn-no-border"
                            disabled={!canDelete}
                            onClick={() => openDeleteModal(coupon)}
                        />
                    </>
                )
            },
        },
    ]
}

function expandedRowRender(coupon) {
    return <CouponDetails coupon={coupon} />
}

/**
 * @param {Object} props
 * @param {Object[]} props.coupons
 * @param {boolean} props.isFetching
 * @param {string} props.currentUserRoleSlug
 * @param {(value: string | null) => void} props.onSort
 * @param {(coupon: Object) => void} props.openEditModal
 * @param {(coupon: Object) => void} props.openDeleteModal
 * @returns {React.JSX.Element}
 * @constructor
 */
function CouponsTable(props) {
    return (
        <Table
            rowKey="id"
            rowClassName="cursor-pointer"
            columns={columns(props.currentUserRoleSlug, props.openEditModal, props.openDeleteModal)}
            loading={props.isFetching}
            dataSource={props.coupons}
            pagination={false}
            expandable={{
                expandedRowRender: expandedRowRender,
                expandRowByClick: true,
                expandIcon: () => null,
                showExpandColumn: false,
            }}
            onChange={(pagination, filters, sorter) => {
                if (typeof sorter.columnKey !== 'undefined') {
                    props.onSort(sorterToSortParam(sorter))
                } else {
                    props.onSort(null)
                }
            }}
        />
    )
}

export default CouponsTable

/**
 * Show in left column. Single player that is loaded to be part of game session
 * @typedef {Object} GameSessionPlayer
 * @property {number} playerNumber
 * @property {string} nickname
 * @property {number | undefined} customerProfileId Optional customer profile id of registered player
 */

/**
 * @typedef {GameSessionPlayer | null} GameSessionPlayerOrNull
 */

/**
 * Shown in right column. A single registered customer profile that is part of a booking
 * @typedef {Object} BookingCustomerProfileOption
 * @property {number} customerProfileId Customer profile id of customer
 * @property {string} nickname Nickname of customer
 * @property {string | undefined} fullName full name of the customer = Full first name + first letter of last name
 * @property {boolean} isSelected Is the profile currently selected?
 * @property {boolean} canSelect Can the profile be selected to be added to the current game session player list?
 */

/**
 * Shown in right column. A single booking with all of its registered customer profile options
 * @typedef {Object} BookingAndCustomerProfilesOption
 * @property {number} bookingId
 * @property {number} bookedPlayerAmount Total player amount that was booked for
 * @property {number} registeredPlayerAmount Amount of players that actually registered to booking
 * @property {string} bookerName Full first name + initials of last name of person that booked this booking
 * @property {BookingCustomerProfileOption[]} customerProfileOptions
 */

/**
 * @typedef {Object} GameSessionTeam
 * @property {number} id Id of the team
 * @property {string} name Name of the team
 * @property {string|undefined} teamAvatarFileName
 * @property {number|undefined} newTeamAvatarImgMediaItemId Id of the media item that represents the newly uploaded team avatar img (if set)
 * @property {string|undefined} teamAvatarImgSrc Source url for the team avatar image (if set)
 * @property {boolean} newImageWasAssigned Keeps track of if a new image was assigned by user or not
 */

import dayjs from "dayjs"
import {APP_DATE_FORMAT, APP_TIME_FORMAT} from "../../../../util/constants"

/**
 * @param {GameSessionResource} gameSessionResource
 * @returns {GameSessionPlayerOrNull[]}
 */
function getGameSessionPlayersFromGameSessionResource(gameSessionResource) {
    const gameSessionCustomerProfiles = gameSessionResource.gameSessionCustomerProfiles

    // Sort by player number
    gameSessionCustomerProfiles.sort((a, b) => {
        return a.playerNumber - b.playerNumber
    })
    const gameSessionCustomerProfilesLength = gameSessionCustomerProfiles.length
    // Overwrite player numbers
    for (let i = 0; i < gameSessionCustomerProfilesLength; i++) {
        gameSessionCustomerProfiles[i].playerNumber = i
    }

    const maxPlayers = getGameMaxGamePlayers(gameSessionResource)
    /** @type GameSessionPlayerOrNull[] */
    const gamePlayers = new Array(maxPlayers).fill(null)
    for (let i = 0; i < maxPlayers; i++) {
        if (i === gameSessionCustomerProfilesLength) {
            break
        }
        const gameSessionCustomerProfile = gameSessionCustomerProfiles[i]
        gamePlayers[i] = {
            playerNumber: i,
            customerProfileId: gameSessionCustomerProfile.customerProfileId,
            nickname: gameSessionCustomerProfile.nickname,
        }
    }

    return gamePlayers
}

/**
 * @param {GameSessionResource} gameSessionResource
 * @returns {BookingResource[]}
 */
function getBookingsWithCorrectProfileNickNames(gameSessionResource) {
    const gameSessionBookings = gameSessionResource.bookings
    // Records from pivot table
    const bookingCustomerProfiles = gameSessionResource.bookingCustomerProfiles
    // console.log("Booking customer profiles:", bookingCustomerProfiles)
    const bookingCustomerProfilesLength = bookingCustomerProfiles.length
    for (let i = 0, length = gameSessionBookings.length; i < length; i++) {
        const booking = gameSessionBookings[i]
        const customerProfiles = booking.customerProfiles
        // console.log("Customer profiles:", customerProfiles)
        if (!Array.isArray(customerProfiles))
            continue

        for (let j = 0, length = customerProfiles.length; j < length; j++) {
            const customerProfile = customerProfiles[j]
            // console.log("Customer profile:", customerProfile)
            for (let k = 0; k < bookingCustomerProfilesLength; k++) {
                const bookingCustomerProfile = bookingCustomerProfiles[k]
                // console.log("Booking customer profile:", bookingCustomerProfile)
                if (bookingCustomerProfile.customerProfileId === customerProfile.id &&
                    typeof bookingCustomerProfile.nickname === "string" &&
                    bookingCustomerProfile.nickname.length > 0
                ) {
                    // Overwrite with pivot record nickname, if valid
                    customerProfile.nickname = bookingCustomerProfile.nickname
                }
            }
        }
    }

    return gameSessionBookings
}

/**
 * Builds the option list to display in the "Registered customers" column of the UI
 * @param {number[]} selectedCustomerProfileIds Currently selected customer profile ids
 * @param {GameSessionPlayerOrNull[]} currentGamePlayers Current assigned game players
 * @param {BookingResource[]} bookings Bookings that belong to game session (resource)
 * @param {number} remainingGamePlayerSpots How many game player spots are remaining for the current session
 * @return {BookingAndCustomerProfilesOption[]} The option list
 */
function bookingCustomerProfilesToOptions(
    selectedCustomerProfileIds,
    currentGamePlayers,
    bookings,
    remainingGamePlayerSpots,
) {
    const allSpotsTaken = remainingGamePlayerSpots < 1

    const bookingsLength = bookings.length
    const gamePlayersLength = currentGamePlayers.length

    /** @type {BookingAndCustomerProfilesOption[]} */
    const bookingsAndCustomerProfiles = new Array(bookingsLength).fill(null)
    for (let i = 0; i < bookingsLength; i++) {
        const booking = bookings[i]
        const customerProfiles = booking.customerProfiles
        const customerProfilesLength = customerProfiles.length

        /** @type {BookingCustomerProfileOption[]} */
        const options = new Array(customerProfilesLength).fill(null)
        for (let j = 0; j < customerProfilesLength; j++) {
            const customerProfile = customerProfiles[j]
            let matchingGamePlayer = null
            for (let k = 0; k < gamePlayersLength; k++) {
                const gamePlayer = currentGamePlayers[k]
                if (gamePlayer === null)
                    continue
                if (gamePlayer.customerProfileId === customerProfile.id) {
                    matchingGamePlayer = gamePlayer
                    break
                }
            }

            const isSelected = matchingGamePlayer !== null ||
                selectedCustomerProfileIds.indexOf(customerProfile.id) !== -1
            const canSelect = allSpotsTaken === false && matchingGamePlayer === null

            let fullName = undefined
            if (typeof customerProfile.firstName === "string" && customerProfile.firstName.length > 0) {
                fullName = customerProfile.firstName
                if (typeof customerProfile.lastName === "string" && customerProfile.lastName.length > 0) {
                    // fullName += " " + customerProfile.lastName[0].toUpperCase() + "."
                    fullName += " " + customerProfile.lastName
                }
            }

            options[j] = {
                customerProfileId: customerProfile.id,
                nickname: customerProfile.nickname,
                fullName: fullName,
                canSelect: canSelect,
                isSelected: isSelected,
            }
        }

        let bookerFullName = booking.bookerCustomerProfile.firstName
        const lastName = booking.bookerCustomerProfile.lastName
        if (typeof lastName === "string" && lastName.length > 0) {
            bookerFullName += " " + lastName[0].toUpperCase() + "."
        }
        bookingsAndCustomerProfiles[i] = {
            bookingId: booking.id,
            bookerName: bookerFullName,
            bookedPlayerAmount: booking.playerAmount,
            registeredPlayerAmount: customerProfilesLength,
            customerProfileOptions: options,
        }
    }

    return bookingsAndCustomerProfiles
}

/**
 * @param {GameSessionResource} gameSessionResource
 * @returns {GameSessionTeam[]}
 */
function teamResourcesToGameSessionTeams(gameSessionResource) {
    /** @type {GameSessionTeam[]} */
    const teams = []

    if (Array.isArray(gameSessionResource.teams)) {
        for (let i = 0, length = gameSessionResource.teams.length; i < length; i++) {
            const team = gameSessionResource.teams[i]
            /** @type {GameSessionTeam} */
            const t = {
                id: team.id,
                name: team.name,
                newImageWasAssigned: false,
            }
            if (Array.isArray(team.mediaItems) && team.mediaItems.length > 0) {
                const firstMediaItem = team.mediaItems[0]
                t.teamAvatarImgSrc = firstMediaItem.urls.original
                t.teamAvatarFileName = firstMediaItem.fileName
            }

            teams.push(t)
        }
    }

    return teams
}

/**
 * Get the maximum amount of players that can be assigned to the current session.
 * Based on room seats, game max players and game session player amount.
 * @param {GameSessionResource} gameSessionResource
 * @returns {number}
 */
function getGameMaxGamePlayers(gameSessionResource) {
    let maxPlayers = gameSessionResource.playerAmount
    if (gameSessionResource.game.maxPlayers > maxPlayers) {
        maxPlayers = gameSessionResource.game.maxPlayers
    }
    if (gameSessionResource.activeInRoom !== null && gameSessionResource.activeInRoom.seats > maxPlayers) {
        maxPlayers = gameSessionResource.activeInRoom.seats
    } else if (gameSessionResource.room.seats > maxPlayers) {
        maxPlayers  = gameSessionResource.room.seats
    }

    return maxPlayers
}

/**
 * @param {GameSessionResource} gameSessionResource
 * @return {string}
 */
function getCorrectRoomName(gameSessionResource) {
    if (gameSessionResource.activeInRoom) {
        return gameSessionResource.activeInRoom.name
    } else {
        return gameSessionResource.room.name
    }
}

/**
 * @typedef {Object} ConstructorArgs
 * @property {GameSessionResource} gameSessionResource
 */

export class GameSessionPlayersState {

    //#region constructor
    /**
     * @param {ConstructorArgs} args
     */
    constructor(args) {
        // Class properties
        /** @private {GameSessionResource} */
        this._gameSessionResource = args.gameSessionResource
        /** @private {string} */
        this._roomName = getCorrectRoomName(args.gameSessionResource)
        /** @private {string} */
        this._gameTitle = args.gameSessionResource.game.title
        /** @private {string} Formatted date of game session */
        this._dateFormatted = dayjs.utc(args.gameSessionResource.start).format(APP_DATE_FORMAT)
        /** @private {string} Formatted start time of game session */
        this._startTimeFormatted = dayjs.utc(args.gameSessionResource.start).format(APP_TIME_FORMAT)
        /** @private {string} Formatted end time of game session */
        this._endTimeFormatted = dayjs.utc(args.gameSessionResource.end).format(APP_TIME_FORMAT)
        /** @private {boolean} */
        this._isDirty = false
        /** @private {boolean} */
        this._isValid = false
        /** @private {number} */
        this._gameMaxPlayers = getGameMaxGamePlayers(args.gameSessionResource)
        const gamePlayers = getGameSessionPlayersFromGameSessionResource(args.gameSessionResource)
        /** @private {GameSessionPlayerOrNull[]} */
        this._originalGamePlayers = gamePlayers
        /** @private {GameSessionPlayerOrNull[]} */
        this._currentGamePlayers = gamePlayers.map(gp => gp === null ? null : {...gp})
        /** @private {GameSessionTeam[]} */
        this._originalTeams = teamResourcesToGameSessionTeams(args.gameSessionResource)
        /** @private {GameSessionTeam[]} */
        this._currentTeams = this._originalTeams.map(team => ({...team}))
        /** @private {BookingResource[]} */
        this._bookings = getBookingsWithCorrectProfileNickNames(args.gameSessionResource)
        /** @private {number} */
        this._emptyGamePlayerSpotsCount = this.calculateEmptyGamePlayerSpotsCount()
        /** @private currently selected registered customer profile ids */
        this._selectedRegisteredProfileIds = []
        /** @private {BookingAndCustomerProfilesOption[]} Array of bookings and their (un)selectable registered customer profiles */
        this._bookingAndCustomerProfileOptions = bookingCustomerProfilesToOptions(
            this._selectedRegisteredProfileIds,
            this._currentGamePlayers,
            this._bookings,
            this._emptyGamePlayerSpotsCount,
        )

        // Listeners
        /** @private {((remainingGamePlayerSpots: number) => void)[]} */
        this._remaingGamePlayerSpotListeners = []
        /** @private {((isDirty: boolean) => void)[]} */
        this._dirtyChangedListeners = []
        /** @private {((isValid: boolean) => void)[]} */
        this._validChangedListeners = []
        /** @private {Map<number, ((gamePlayer: GameSessionPlayerOrNull) => void)[]>} */
        this._gamePlayerChangedListeners  = new Map()
        /** @private {((gamePlayers: GameSessionPlayerOrNull[]) => void)[]} */
        this._gamePlayersChangedListeners = []
        /** @private {((bookingsAndProfileSelectionOptions: BookingAndCustomerProfilesOption[]) => void)[]} */
        this._bookingsAndProfilesOptionsListeners = []
        /** @private {((selectedProfileIds: number[]) => void)[]} */
        this._selectedRegisteredProfileIdsListeners = []
        /** @private {((teams: TeamResource[]) => void)[]} */
        this._teamsChangedListeners = []


        // Method binding
        this.calculateEmptyGamePlayerSpotsCount = this.calculateEmptyGamePlayerSpotsCount.bind(this)
        this.performDirtyCheck = this.performDirtyCheck.bind(this)

        this.updateGamePlayerNickname = this.updateGamePlayerNickname.bind(this)
        this.removeAllGamePlayers = this.removeAllGamePlayers.bind(this)
        this.removeGamePlayer = this.removeGamePlayer.bind(this)

        this.updateSelectedProfileIds = this.updateSelectedProfileIds.bind(this)
        this.addProfilesToGamePlayers = this.addProfilesToGamePlayers.bind(this)
        this.addSelectedProfilesToGamePlayers = this.addSelectedProfilesToGamePlayers.bind(this)
        this.addMaxProfilesToGamePlayers = this.addMaxProfilesToGamePlayers.bind(this)

        this.updateTeamNameByIndex = this.updateTeamNameByIndex.bind(this)
        this.updateTeamImageByIndex = this.updateTeamImageByIndex.bind(this)

        //#region events & listeners
        this.notifyDirtyListeners = this.notifyDirtyListeners.bind(this)
        this.addDirtyStateChangeListener = this.addDirtyStateChangeListener.bind(this)
        this.removeDirtyStateChangeListener = this.removeDirtyStateChangeListener.bind(this)

        this.notifyRemainingGamePlayerSpotListeners = this.notifyRemainingGamePlayerSpotListeners.bind(this)
        this.addRemainingGamePlayerSpotListener = this.addRemainingGamePlayerSpotListener.bind(this)
        this.removeRemainingGamePlayerSpotListener = this.removeRemainingGamePlayerSpotListener.bind(this)

        this.notifyGamePlayersChangedListeners = this.notifyGamePlayersChangedListeners.bind(this)
        this.addGamePlayersChangedListener = this.addGamePlayersChangedListener.bind(this)
        this.removeGamePlayersChangedListener = this.removeGamePlayersChangedListener.bind(this)

        this.notifyGamePlayerChangedListeners = this.notifyGamePlayerChangedListeners.bind(this)
        this.addGamePlayerChangedListener = this.addGamePlayerChangedListener.bind(this)
        this.removeGamePlayerChangedListener = this.removeGamePlayerChangedListener.bind(this)

        this.addBookingAndProfilesOptionsChangeListener = this.addBookingAndProfilesOptionsChangeListener.bind(this)
        this.removeBookingsAndProfilesOptionsChangeListener = this.removeBookingsAndProfilesOptionsChangeListener.bind(this)
        this.notifyBookingsAndProfilesOptionsListeners = this.notifyBookingsAndProfilesOptionsListeners.bind(this)

        this.addTeamsChangeListener = this.addTeamsChangeListener.bind(this)
        this.removeTeamsChangeListener = this.removeTeamsChangeListener.bind(this)
        this.notifyTeamsListeners = this.notifyTeamsListeners.bind(this)
        //#endregion events & listeners

        // Perform initial checks
        this.performDirtyCheck(false)
        this.performIsValidCheck(false)
    }
    //#endregion constructor

    //#region getters
    /**
     * @return {GameSessionResource}
     */
    get gameSessionResource() {
        return this._gameSessionResource
    }

    /**
     * Title of the game being played
     * @return {string}
     */
    get gameTitle() {
        return this._gameTitle
    }

    /**
     * Gets the name of the room the game session is played in
     * @return {string}
     */
    get roomName() {
        return this._roomName
    }

    /**
     * Formatted date of the game session
     * @return {string}
     */
    get formattedDate() {
        return this._dateFormatted
    }

    /**
     * Formatted start time of the game session
     * @return {string}
     */
    get formattedStartTime() {
        return this._startTimeFormatted
    }

    /**
     * Formatted end time of the game session
     * @return {string}
     */
    get formattedEndTime() {
        return this._endTimeFormatted
    }

    /**
     * @return {number}
     */
    get gameMaxPlayers() {
        return this._gameMaxPlayers
    }

    /**
     * @return {number}
     */
    get emptyGamePlayerSpotsCount() {
        return this._emptyGamePlayerSpotsCount
    }

    /**
     * Returns alls currently added "players" (or profiles) that have been added to the game session
     * with NULL entries for all open spots.
     * (The column on left the single game session page)
     * @returns {readonly GameSessionPlayerOrNull[]}
     */
    get currentGamePlayers() {
        return this._currentGamePlayers
    }

    /**
     * Returns the amount of player spots that are filled (or not null)
     * @return {number}
     */
    get filledCurrentGamePlayersSpotsCount() {
        return this._gameMaxPlayers - this._emptyGamePlayerSpotsCount
    }

    /**
     * Returns the bookings and their registered customer profiles as options
     * (The column on the right on the single game session page)
     * @return {BookingAndCustomerProfilesOption[]}
     */
    get bookingAndCustomerProfileSelectionOptions() {
        return this._bookingAndCustomerProfileOptions
    }

    get selectedRegisteredProfilesCount() {
        return this._selectedRegisteredProfileIds
    }

    /**
     * Returns how many customer profiles (across all bookings) are still "selectable"
     * @return {number}
     */
    get selectableRegisteredProfilesCount() {
        // Can't select any, if there are no free spots left!
        if (this._emptyGamePlayerSpotsCount === 0)
            return 0

        let count = 0
        for (let i = 0, length = this._bookingAndCustomerProfileOptions.length; i < length; i++) {
            const bookingOption = this._bookingAndCustomerProfileOptions[i]
            for (let j = 0, jLength = bookingOption.customerProfileOptions.length; j < jLength; j++) {
                const customerProfileOption = bookingOption.customerProfileOptions[j]
                if (customerProfileOption.canSelect)
                    count++
            }
        }
        return count
    }

    /**
     * Returns current game session teams
     * @return {GameSessionTeam[]}
     */
    get currentTeams() {
        return this._currentTeams
    }

    get isDirty() {
        return this._isDirty
    }

    /**
     * @returns {boolean}
     */
    get isValid() {
        return this._isValid
    }

    /**
     * @param {number} playerIndex
     */
    getGamePlayerAtIndex(playerIndex) {
        return this._currentGamePlayers[playerIndex]
    }
    //#endregion getters

    //#region listeners & notifications
    /**
     * @private
     */
    notifyRemainingGamePlayerSpotListeners() {
        for (let i = 0, length = this._remaingGamePlayerSpotListeners.length; i < length; i++) {
            this._remaingGamePlayerSpotListeners[i](this._emptyGamePlayerSpotsCount)
        }
    }

    /**
     * @param {(remainingGamePlayerSpots: number) => void} listener
     */
    addRemainingGamePlayerSpotListener(listener) {
        this._remaingGamePlayerSpotListeners.push(listener)
    }

    /**
     * @param {(remainingGamePlayerSpots: number) => void} listener
     */
    removeRemainingGamePlayerSpotListener(listener) {
        const index = this._remaingGamePlayerSpotListeners.indexOf(listener)
        if (index !== -1)
            this._remaingGamePlayerSpotListeners = this._remaingGamePlayerSpotListeners.splice(index, 1)
    }

    /**
     * @private
     */
    notifyDirtyListeners() {
        for (let i = 0, length = this._dirtyChangedListeners.length; i < length; i++) {
            this._dirtyChangedListeners[i](this._isDirty)
        }
    }

    /**
     * @param {(isDirty: boolean) => void} listener
     */
    addDirtyStateChangeListener(listener) {
        this._dirtyChangedListeners.push(listener)
    }

    /**
     * @param {(isDirty: boolean) => void} listener
     */
    removeDirtyStateChangeListener(listener) {
        const index = this._dirtyChangedListeners.indexOf(listener)
        if (index !== -1) {
            this._dirtyChangedListeners = this._dirtyChangedListeners.splice(index, 1)
        }
    }

    /**
     * @private
     */
    notifyValidListeners() {
        for (let i = 0, length = this._validChangedListeners.length; i < length; i++) {
            this._validChangedListeners[i](this._isValid)
        }
    }

    /**
     * @param {(isValid: boolean) => void} listener
     */
    addValidStateChangeListener(listener) {
        this._validChangedListeners.push(listener)
    }

    /**
     * @param {(isValid: boolean) => void} listener
     */
    removeValidStateChangeListener(listener) {
        const index = this._validChangedListeners.indexOf(listener)
        if (index !== -1) {
            this._validChangedListeners = this._validChangedListeners.splice(index, 1)
        }
    }

    /**
     * @private
     */
    notifyGamePlayersChangedListeners() {
        for (let i = 0, length = this._gamePlayersChangedListeners.length; i < length; i++) {
            this._gamePlayersChangedListeners[i](this._currentGamePlayers)
        }
    }

    /**
     * @param {(gamePlayers: GameSessionPlayerOrNull[]) => void} listener
     */
    addGamePlayersChangedListener(listener) {
        this._gamePlayersChangedListeners.push(listener)
    }

    /**
     * @param {(gamePlayers: GameSessionPlayerOrNull[]) => void} listener
     */
    removeGamePlayersChangedListener(listener) {
        const index = this._gamePlayersChangedListeners.indexOf(listener)
        if (index !== -1) {
            this._gamePlayersChangedListeners = this._gamePlayersChangedListeners.splice(index, 1)
        }
    }

    /**
     * @private
     * @param {number} playerIndex
     */
    notifyGamePlayerChangedListeners(playerIndex) {
        if (!this._gamePlayerChangedListeners.has(playerIndex))
            return

        const listeners = this._gamePlayerChangedListeners.get(playerIndex)
        const gamePlayer = this._currentGamePlayers[playerIndex]
        for (let i = 0, length = listeners.length; i < length; i++) {
            listeners[i](gamePlayer)
        }
    }

    /**
     * @param {number} playerIndex
     * @param {(gamePlayer: GameSessionPlayerOrNull) => void} listener
     */
    addGamePlayerChangedListener(playerIndex, listener) {
        if (this._gamePlayerChangedListeners.has(playerIndex)) {
            this._gamePlayerChangedListeners.get(playerIndex).push(listener)
        } else {
            const listeners = [listener]
            this._gamePlayerChangedListeners.set(playerIndex, listeners)
        }
    }

    removeGamePlayerChangedListener(playerIndex, listener) {
        if (!this._gamePlayerChangedListeners.has(playerIndex))
            return

        let listeners = this._gamePlayerChangedListeners.get(playerIndex)
        const index = listeners.indexOf(listener)
        if (index === -1)
            return

        listeners = listeners.splice(index, 1)
        this._gamePlayerChangedListeners.set(playerIndex, listeners)
    }

    /**
     * @private
     */
    notifyBookingsAndProfilesOptionsListeners() {
        for (let i = 0, length = this._bookingsAndProfilesOptionsListeners.length; i < length; i++) {
            this._bookingsAndProfilesOptionsListeners[i](this._bookingAndCustomerProfileOptions)
        }
    }

    /**
     * @param {(bookingsAndProfilesOptions: BookingAndCustomerProfilesOption[]) => void} listener
     */
    addBookingAndProfilesOptionsChangeListener(listener) {
        this._bookingsAndProfilesOptionsListeners.push(listener)
    }

    /**
     * @param {(bookingsAndProfilesOptions: BookingAndCustomerProfilesOption[]) => void} listener
     */
    removeBookingsAndProfilesOptionsChangeListener(listener) {
        const index = this._bookingsAndProfilesOptionsListeners.indexOf(listener)
        if (index !== -1) {
            this._bookingsAndProfilesOptionsListeners = this._bookingsAndProfilesOptionsListeners.splice(index, 1)
        }
    }

    /**
     * @private
     */
    notifySelectedRegisteredProfileIdsListeners() {
        for (let i = 0, length = this._selectedRegisteredProfileIdsListeners.length; i < length; i++) {
            this._selectedRegisteredProfileIdsListeners[i](this._selectedRegisteredProfileIds)
        }
    }

    /**
     * @param {(selectedRegisteredProfileIds: number[]) => void} listener
     */
    addSelectedRegisteredProfileIdsChangeListener(listener) {
        this._selectedRegisteredProfileIdsListeners.push(listener)
    }

    /**
     * @param {(selectedRegisteredProfileIds: number[]) => void} listener
     */
    removeSelectedRegisteredProfileIdsChangeListener(listener) {
        const index = this._selectedRegisteredProfileIdsListeners.indexOf(listener)
        if (index !== -1) {
            this._selectedRegisteredProfileIdsListeners = this._selectedRegisteredProfileIdsListeners.splice(index, 1)
        }
    }

    /**
     * @private
     */
    notifyTeamsListeners() {
        for (let i = 0, length = this._teamsChangedListeners.length; i < length; i++) {
            this._teamsChangedListeners[i](this._currentTeams)
        }
    }

    /**
     * @param {(teams: TeamResource[]) => void} listener
     */
    addTeamsChangeListener(listener) {
        this._teamsChangedListeners.push(listener)
    }

    /**
     * @param {(teams: TeamResource[]) => void} listener
     */
    removeTeamsChangeListener(listener) {
        const index = this._teamsChangedListeners.indexOf(listener)
        if (index !== -1) {
            this._teamsChangedListeners = this._teamsChangedListeners.splice(index, 1)
        }
    }
    //#endregion listeners & notifications

    //#region private methods
    /**
     * @private
     * Recalculate how many open spots there are in the current game session
     * @return {number}
     */
    calculateEmptyGamePlayerSpotsCount() {
        let emptySpots = 0
        for (let i = 0, length = this._currentGamePlayers.length; i < length; i++) {
            if (this._currentGamePlayers[i] === null)
                emptySpots++
        }
        return emptySpots
    }

    /**
     * @private
     * @param {boolean} notifyListeners
     */
    performDirtyCheck(notifyListeners = true) {
        let isDirty = false
        const length = this._currentGamePlayers.length
        for (let i = 0; i < length; i++) {
            const current = this._currentGamePlayers[i]
            const original = this._originalGamePlayers[i]
            // console.log("Original:", original)
            // console.log("Current:", current)
            if (current === null && original === null) {
                continue
            } else if (current !== null && original !== null) {
                if (current.nickname !== original.nickname) {
                    isDirty = true
                    break
                } else if (current.customerProfileId !== original.customerProfileId) {
                    isDirty = true
                    break
                }
            } else {
                isDirty = true
                break
            }
        }

        // If not already dirty -> also check teams
        if (!isDirty) {
            for (let i = 0, iLength = this._currentTeams.length; i < iLength; i++) {
                const currentTeam = this._currentTeams[i]
                const originalTeam = this._originalTeams[i]
                isDirty = currentTeam.name !== originalTeam.name ||
                    currentTeam.teamAvatarImgSrc !== originalTeam.teamAvatarImgSrc ||
                    currentTeam.id !== originalTeam.id ||
                    currentTeam.newImageWasAssigned !== originalTeam.newImageWasAssigned
                if (isDirty)
                    break
            }
        }


        // console.log("current is dirty:", this.isDirty)
        // console.log("new is dirty:", isDirty)
        if (isDirty !== this._isDirty) {
            this._isDirty = isDirty
            if (notifyListeners)
                this.notifyDirtyListeners()
        }
    }

    /**
     * @private
     * @param {boolean} notifyListeners
     */
    performIsValidCheck(notifyListeners = true) {
        let isValid = true

        for (let i = 0, length = this._currentGamePlayers.length; i < length; i++) {
            const gamePlayer = this._currentGamePlayers[i]
            if (gamePlayer === null)
                continue

            // Don't check for players with customer profiles
            if (typeof gamePlayer.customerProfileId === "number")
                continue

            // console.log("Game player:", gamePlayer)
            if (gamePlayer.nickname.length < 2) {
                isValid = false
                break
            }
        }

        if (isValid) {
            for (let i = 0, length = this._currentTeams.length; i < length; i++) {
                const team = this._currentTeams[i]
                // console.log("Team:", team)
                if (typeof team.name !== "string" || team.name.length < 2) {
                    isValid = false
                    break
                }
            }
        }

        if (isValid !== this._isValid) {
            this._isValid = isValid
            if (notifyListeners)
                this.notifyValidListeners()
        }
    }
    //endregion private methods

    //#region public methods
    /**
     * @param {number} playerIndex
     * @param {string} newNickname
     */
    updateGamePlayerNickname(playerIndex, newNickname) {
        const gamePlayer = this._currentGamePlayers[playerIndex]
        if (gamePlayer === null) {
            this._currentGamePlayers[playerIndex] = {
                playerNumber: playerIndex,
                nickname: newNickname,
            }
        } else {
            if (typeof gamePlayer.customerProfileId === "number")
                throw new Error("Players that are attached to a customer profile should not be updated!!")

            this._currentGamePlayers[playerIndex] = {
                playerNumber: gamePlayer.playerNumber,
                nickname: newNickname,
            }
        }
        this._currentGamePlayers = [...this._currentGamePlayers]
        this._emptyGamePlayerSpotsCount = this.calculateEmptyGamePlayerSpotsCount()
        this.notifyGamePlayersChangedListeners()
        this.notifyGamePlayerChangedListeners(playerIndex)
        this.notifyRemainingGamePlayerSpotListeners()
        this.performDirtyCheck()
        this.performIsValidCheck()
    }

    /**
     * @param {number[]} profileIds
     * @param {boolean} select
     */
    updateSelectedProfileIds(profileIds, select) {
        /** @type {number[]} */
        let newIds
        if (select) {
            // Use set to avoid doubles
            const set = new Set(this._selectedRegisteredProfileIds)
            for (let i = 0, length = profileIds.length; i < length; i++) {
                set.add(profileIds[i])
            }
            newIds = Array.from(set)
        } else {
            newIds = this._selectedRegisteredProfileIds.filter(id => profileIds.indexOf(id) === -1)
        }

        this._selectedRegisteredProfileIds = newIds
        this._bookingAndCustomerProfileOptions = bookingCustomerProfilesToOptions(
            newIds,
            this._currentGamePlayers,
            this._bookings,
            this._emptyGamePlayerSpotsCount,
        )

        // Notify listeners
        this.notifySelectedRegisteredProfileIdsListeners()
        this.notifyBookingsAndProfilesOptionsListeners()
    }

    /**
     * @private Internal method to add a profiles list to the game players
     * @param {BookingCustomerProfileOption[]} profilesToAdd
     */
    addProfilesToGamePlayers(profilesToAdd) {
        const profilesToAddLength = profilesToAdd.length
        if (profilesToAddLength === 0)
            return

        /** @type {number[]} */
        const emptyIndexes = []
        for (let i = 0, length = this._currentGamePlayers.length; i < length; i++) {
            if (this._currentGamePlayers[i] === null) {
                emptyIndexes.push(i)
            }
        }

        for (let i = 0; i < emptyIndexes.length; i++) {
            if (i === profilesToAddLength)
                break

            const emptyIndex = emptyIndexes[i]
            const profile = profilesToAdd[i]
            this._currentGamePlayers[emptyIndex] = {
                playerNumber: emptyIndex,
                customerProfileId: profile.customerProfileId,
                nickname: profile.nickname,
            }
        }

        // Because react can only compare array pointers...
        this._currentGamePlayers = [...this._currentGamePlayers]
        this._selectedRegisteredProfileIds = []
        this._emptyGamePlayerSpotsCount = this.calculateEmptyGamePlayerSpotsCount()
        this._bookingAndCustomerProfileOptions = bookingCustomerProfilesToOptions(
            [],
            this._currentGamePlayers,
            this._bookings,
            this._emptyGamePlayerSpotsCount,
        )
        this.notifyGamePlayersChangedListeners()
        this.notifySelectedRegisteredProfileIdsListeners()
        this.notifyBookingsAndProfilesOptionsListeners()
        this.notifyRemainingGamePlayerSpotListeners()
        this.performDirtyCheck()
        this.performIsValidCheck()
    }

    addSelectedProfilesToGamePlayers() {
        const selectedProfileIdsLength = this._selectedRegisteredProfileIds.length
        if (selectedProfileIdsLength === 0)
            return


        /** @type {BookingCustomerProfileOption[]} */
        const profilesById = []
        for (let i = 0, length = this._bookingAndCustomerProfileOptions.length; i < length; i++) {
            const bookingOption = this._bookingAndCustomerProfileOptions[i]
            const profiles = bookingOption.customerProfileOptions
            for (let j = 0, profilesLength = profiles.length; j < profilesLength; j++) {
                const profile = profiles[j]
                const profileId = profile.customerProfileId
                const shouldAdd = this._selectedRegisteredProfileIds.indexOf(profileId) !== -1
                if (shouldAdd) {
                    profilesById.push(profile)
                }
            }
        }

        this.addProfilesToGamePlayers(profilesById)
    }

    /**
     * Add the maximum amount of selectable profiles to the game session based on remaining player spots
     * and registered booking profiles
     */
    addMaxProfilesToGamePlayers() {
        if (this._emptyGamePlayerSpotsCount === 0)
            return

        const profilesToAdd = []
        let profilesAddedCount = 0
        for (let i = 0, bookingsOptionsLength = this._bookingAndCustomerProfileOptions.length; i < bookingsOptionsLength; i++) {
            const bookingOption = this._bookingAndCustomerProfileOptions[i]
            const customerProfilesLength = bookingOption.customerProfileOptions.length
            for (let j = 0; j < customerProfilesLength; j++) {
                const customerProfileOption = bookingOption.customerProfileOptions[j]
                if (!customerProfileOption.canSelect)
                    continue

                profilesAddedCount++
                profilesToAdd.push(customerProfileOption)

                if (profilesAddedCount === this._emptyGamePlayerSpotsCount)
                    break
            }

            if (profilesAddedCount === this._emptyGamePlayerSpotsCount)
                break
        }

        this.addProfilesToGamePlayers(profilesToAdd)
    }

    removeAllGamePlayers() {
        for (let i = 0, length = this._currentGamePlayers.length; i < length; i++) {
            this._currentGamePlayers[i] = null
        }
        this._currentGamePlayers = [...this.currentGamePlayers]
        this._emptyGamePlayerSpotsCount = this.calculateEmptyGamePlayerSpotsCount()
        this._bookingAndCustomerProfileOptions = bookingCustomerProfilesToOptions(
            this._selectedRegisteredProfileIds,
            this._currentGamePlayers,
            this._bookings,
            this._emptyGamePlayerSpotsCount,
        )
        this.notifyGamePlayersChangedListeners()
        this.notifyBookingsAndProfilesOptionsListeners()
        this.notifyRemainingGamePlayerSpotListeners()
        this.performDirtyCheck()
        this.performIsValidCheck()
    }

    /**
     * @param {number} playerIndex
     */
    removeGamePlayer(playerIndex) {
        this._currentGamePlayers[playerIndex] = null
        this._currentGamePlayers = [...this._currentGamePlayers]
        this._emptyGamePlayerSpotsCount = this.calculateEmptyGamePlayerSpotsCount()
        this._bookingAndCustomerProfileOptions = bookingCustomerProfilesToOptions(
            this._selectedRegisteredProfileIds,
            this._currentGamePlayers,
            this._bookings,
            this._emptyGamePlayerSpotsCount,
        )
        this.notifyGamePlayersChangedListeners()
        this.notifyBookingsAndProfilesOptionsListeners()
        this.notifyRemainingGamePlayerSpotListeners()
        this.performDirtyCheck()
        this.performIsValidCheck()
    }

    /**
     * @param {number} index
     * @param {string} newName
     */
    updateTeamNameByIndex(index, newName) {
        // const teamAtIndex = this._currentTeams[index]
        // this._currentTeams[index] = {
        //     ...teamAtIndex,
        //     name: newName,
        // }
        this._currentTeams[index].name = newName
        // Cause react..
        this._currentTeams = [...this._currentTeams]
        this.notifyTeamsListeners()
        this.performDirtyCheck()
        this.performIsValidCheck()
    }

    /**
     * @param {number} index
     * @param {string} newImgSrc
     * @param {string|undefined} fileName
     */
    updateTeamImageByIndex(index, newImgSrc, fileName) {
        const team = this._currentTeams[index]
        team.teamAvatarImgSrc = newImgSrc
        team.newImageWasAssigned = true
        if (typeof fileName === "string" && fileName.length > 0)
            team.teamAvatarFileName = fileName
        this._currentTeams = [...this._currentTeams]
        this.notifyTeamsListeners()
        this.performDirtyCheck()
        this.performIsValidCheck()
    }
    //#endregion public methods
}
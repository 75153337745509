import React from 'react'
import { ConfirmInputModal } from 'components/util/modals/ConfirmInputModal'
import { WEEK_DAYS } from 'util/constants'
import { whereId } from 'util/index'

const getDay = (key) => WEEK_DAYS.find(whereId(key))

function DeleteCouponTimeFrameModal({
    isOpen,
    onClose,
    isLoading,
    onCancel,
    onSubmit,
    entity,
}) {
    if (entity === null || typeof entity === 'undefined') {
        return null
    }
    const day = getDay(entity.day) || {}
    return (
        <ConfirmInputModal
            safeWord={day.name || ''}
            title={`Are you sure you want to delete the timeframe for ${day.name}?`}
            isOpen={isOpen}
            onConfirm={onSubmit}
            onCancel={onCancel}
            confirmText="Delete timeframe"
            confirmType="primary"
            confirmIsDanger={true}
            cancelText="Cancel"
            isLoading={isLoading}
            close={onClose}
        >
            <p>
                Removing a timeframe cannot be undone.
                {' '}
                <br />
                Please type the exact name of the day to confirm.
            </p>
        </ConfirmInputModal>
    )
}

export default DeleteCouponTimeFrameModal

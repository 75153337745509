import React from 'react'
import DeleteVenueGameFormState
    from 'components/pages/settings/venue-games/forms/delete/DeleteVenueGameFormState'
import { ConfirmInputModal } from 'components/util/modals/ConfirmInputModal'
import Entity from 'components/util/Entity'

function DeleteVenueGameModal({
    isOpen,
    entity,
    onClose,
    onDeleteSuccess,
}) {
    return (
        <Entity entityKey="venueGames" id={entity}>
            {(venueGame) => (
                <DeleteVenueGameFormState
                    onDeleteSuccess={(gameId) => {
                        onClose()
                        onDeleteSuccess(gameId)
                    }}
                    onCancel={onClose}
                    values={venueGame}
                >
                    {({
                        isLoading,
                        handleSubmit,
                        handleCancel,
                    }) => (
                        <ConfirmInputModal
                            safeWord={venueGame.title}
                            title={`Are you sure you want to delete ${venueGame.title}?`}
                            isOpen={isOpen}
                            onConfirm={handleSubmit}
                            onCancel={handleCancel}
                            confirmText={`Delete ${venueGame.title}`}
                            confirmType="primary"
                            confirmIsDanger={true}
                            cancelText="Cancel"
                            isLoading={isLoading}
                            close={onClose}
                        >
                            <p>
                                Removing a game cannot be undone.
                                {' '}
                                <br />
                                Please type the exact name of the game to confirm.
                            </p>
                        </ConfirmInputModal>
                    )}
                </DeleteVenueGameFormState>
            )}
        </Entity>
    )
}

export default DeleteVenueGameModal

import React from 'react'
import {
    Form, Input, Select, Switch, InputNumber, Alert, DatePicker,
} from 'antd'
import FormItem from 'components/util/forms/FormItem'
import ExtraInfoLabel from 'components/util/forms/ExtraInfoLabel'
import LegalEntitySelect from 'components/util/LegalEntitySelect'
import TimezoneSelect from 'components/util/TimezoneSelect'
import dayjs from 'dayjs'
import { APP_DATE_FORMAT } from '../../../../../../util/constants'

function EditVenueForm({
    values, errors, onChange, onChangeAsValue,
}) {
    return (
        <Form>
            <FormItem label="Introduction" error={errors.duration} required>
                <Select name="duration" value={values.duration} onChange={(value) => onChangeAsValue('duration', value)}>
                    <Select.Option value={0}>0 minutes</Select.Option>
                    <Select.Option value={30}>30 minutes</Select.Option>
                    <Select.Option value={60}>60 minutes</Select.Option>
                </Select>
            </FormItem>
            <FormItem label="Min. players" error={errors.minPlayers} required>
                <InputNumber name="minPlayers" value={values.minPlayers} onChange={(value) => onChangeAsValue('minPlayers', value)} min={1} step={1} />
            </FormItem>
            <FormItem
                label="Cost center"
                error={errors.costCenterCode}
                extra={<ExtraInfoLabel message="Please first create a cost center in 'Exact'. Attention: this is case sensitive" />}
            >
                <Input name="costCenterCode" value={values.costCenterCode} onChange={onChange} />
            </FormItem>
            <FormItem label="Legal Entity" error={errors.legalEntity} required>
                <LegalEntitySelect value={values.legalEntity} onChange={(value) => onChangeAsValue('legalEntity', value)} />
            </FormItem>
            <FormItem label="Timezone" required error={errors.timezone}>
                <TimezoneSelect value={values.timezone} onChange={(value) => onChangeAsValue('timezone', value)} />
            </FormItem>
            <FormItem
                label="code"
                error={errors.code}
            >
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>{values.code}</label>
            </FormItem>
            <FormItem required label="Is public?" error={errors.public}>
                <Switch onChange={(value) => onChangeAsValue('public', value)} checked={values.public} />
            </FormItem>
            <FormItem required label="Send promotional content" error={errors.sendPromotionalContent}>
                <Switch
                    onChange={(value) => onChangeAsValue('sendPromotionalContent', value)}
                    checked={values.sendPromotionalContent}
                />
            </FormItem>
            <FormItem required label="Ignore time scheduling" error={errors.ignoreTimeScheduling}>
                <Switch
                    onChange={(value) => onChangeAsValue('ignoreTimeScheduling', value)}
                    checked={values.ignoreTimeScheduling}
                />
            </FormItem>
            <FormItem
                required={true}
                label="Auto online voucher creation?"
                error={errors.ignoreTimeScheduling}
                extra={(
                    <ExtraInfoLabel
                        message="When enabled, will generate vouchers automatically for all online booking edits where the customer is owed money."
                    />
                )}
            >
                <Switch
                    onChange={(value) => onChangeAsValue('autoOnlineVoucherCreation', value)}
                    checked={values.autoOnlineVoucherCreation}
                />
            </FormItem>

            <FormItem label=" ">
                <Alert
                    message="Overwrite opening and closing date"
                    description="Optionally overwrite the availability for the games that have been added to this venue"
                />
            </FormItem>

            <FormItem label="Opening date" error={errors.openingDate}>
                <DatePicker
                    disabledDate={(current) => current && current < dayjs.utc().startOf('day')}
                    format={APP_DATE_FORMAT}
                    placeholder="Select date"
                    value={values.openingDate ? dayjs(values.openingDate) : undefined}
                    // https://ant.design/components/date-picker#datepicker
                    onChange={(date, dateString) => {
                        let formatted
                        if (date === null)
                            formatted = undefined
                        else
                            formatted = date.format(APP_DATE_FORMAT)

                        onChangeAsValue('openingDate', formatted)
                    }}
                />
            </FormItem>
            <FormItem label="Closing date" error={errors.closingDate}>
                <DatePicker
                    disabledDate={(current) => current && current < dayjs.utc().startOf('day')}
                    format={APP_DATE_FORMAT}
                    placeholder="Select date"
                    value={values.closingDate ? dayjs(values.closingDate) : undefined}
                    // https://ant.design/components/date-picker#datepicker
                    onChange={(date, dateString) => {
                        let formatted
                        if (date === null)
                            formatted = undefined
                        else
                            formatted = date.format(APP_DATE_FORMAT)
                        onChangeAsValue('closingDate', formatted)
                    }}
                />
            </FormItem>

        </Form>
    )
}

export default EditVenueForm

import { Component } from 'react'
import { connect } from 'react-redux'
import callApi from 'store/middleware/callApi'
import store from 'store/reduxStore'
import { setBookingActive } from 'store/actions/bookings'
import { METHODS } from 'store/middleware/api'

class ActiveBookingRequest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isFetching: true,
            activeBooking: null,
        }
        this.fetchActiveBooking = this.fetchActiveBooking.bind(this)
    }

    componentDidMount() {
        this.didMounted = true
    }

    componentWillUnmount() {
        this.didMounted = false
    }

    safeSetState(...args) {
        if (this.didMounted) {
            this.setState(...args)
        }
    }

    async fetchActiveBooking() {
        this.setState({ isFetching: true })
        const { venueId } = this.props
        const endpoint = `/venues/${venueId}/bookings/active`
        try {
            const response = await callApi(METHODS.GET, endpoint)
            const activeBooking = response.data
            store.dispatch(setBookingActive(activeBooking.id))
            this.safeSetState({
                isFetching: false,
                activeBooking,
            })
        } catch (e) {
            this.safeSetState({
                isFetching: false,
                activeBooking: null,
            })
        }
    }

    render() {
        const { activeBookingIds } = this.props
        const { isFetching, activeBooking } = this.state
        // eslint-disable-next-line react/destructuring-assignment
        return this.props.children({
            fetchActiveBooking: this.fetchActiveBooking,
            isFetching,
            activeBooking,
            activeBookingIds,
        })
    }
}

const mapStateToProps = (state) => ({
    activeBookingIds: state.activeEntities.bookings,
})

export default connect(mapStateToProps, null)(ActiveBookingRequest)

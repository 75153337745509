import React from 'react'
import { EditVoucherFormState } from '../forms/edit/EditVoucherFormState'
import { ModalFooter } from '../../../../util/modals/ModalFooter'
import { Modal } from 'antd'
import { EditVoucherForm } from '../forms/edit/EditVoucherForm'

/**
 * @param {Object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.closeModal
 * @param {Object} props.voucher
 * @param {Object[]} props.legalEntities
 * @param {Object[]} props.venues
 * @param {Object[]} props.voucherIssueReasons
 * @returns {React.JSX.Element}
 * @constructor
 */
export function EditVoucherModal(props) {
    if (!props.isOpen)
        return null

    return (
        <EditVoucherFormState
            voucher={props.voucher}
            onCancel={props.closeModal}
            onEditVoucherSuccess={() => props.closeModal()}
        >
            {({
                values,
                errors,
                isLoading,
                isSubmitted,
                handleChange,
                handleSubmit,
                handleCancel,
                handleChangeAsValue,
                mergeValues,
            }) => (
                <Modal
                    title="Edit voucher"
                    open={props.isOpen}
                    onCancel={handleCancel}
                    onOk={handleSubmit}
                    closable={!isLoading}
                    maskClosable={!isLoading}
                    footer={(
                        <ModalFooter
                            isLoading={isLoading}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                        />
                    )}
                >
                    <EditVoucherForm
                        isLoading={isLoading}
                        isSubmitted={isSubmitted}
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onChangeAsValue={handleChangeAsValue}
                        mergeValues={mergeValues}
                        legalEntities={props.legalEntities}
                        venues={props.venues}
                        voucherIssueReasons={props.voucherIssueReasons}
                    />
                </Modal>
            )}
        </EditVoucherFormState>
    )
}

import React from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Table, Button } from 'antd'
import { MODAL_IDS } from 'util/constants'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import WithMe from 'components/pages/authorized/WithMe'
import ActivateDiscountSwitch from './ActivateDiscountSwitch'

const columns = [{
    title: 'Active',
    dataIndex: 'isActive',
    render: (text, { id, active }) => (
        <ActivateDiscountSwitch
            discountBannerId={id}
            isActive={active}
        />
    ),
}, {
    title: 'Title',
    dataIndex: ['title', 'en'],
}, {
    title: 'Subtitle',
    dataIndex: ['subTitle', 'en'],
}, {
    title: 'Action',
    key: 'action',
    align: 'center',
    width: 80,
    render: (text, record) => (
        <WithMe>
            {({ isAdmin, isLegalEntityManager, isVenueManager }) => (
                <>
                    <WithActiveModal
                        modalId={MODAL_IDS.EDIT_DISCOUNT_BANNER}
                        entity={record}
                    >
                        {({ openModal }) => (
                            <Button
                                ghost
                                icon={<EditOutlined />}
                                type="primary"
                                shape="circle"
                                size="small"
                                className="ant-btn-no-border"
                                disabled={!isAdmin && !isLegalEntityManager && !isVenueManager}
                                onClick={openModal}
                            />
                        )}
                    </WithActiveModal>
                    <WithActiveModal
                        modalId={MODAL_IDS.DELETE_DISCOUNT_BANNER}
                        entity={record}
                    >
                        {({ openModal }) => (
                            <Button
                                ghost
                                icon={<DeleteOutlined />}
                                type="danger"
                                shape="circle"
                                size="small"
                                className="ant-btn-no-border"
                                disabled={!isAdmin && !isLegalEntityManager && !isVenueManager}
                                onClick={openModal}
                            />
                        )}
                    </WithActiveModal>
                </>
            )}
        </WithMe>
    ),
}]

function DiscountBannersTable({
    discountBanners,
    isFetching,
}) {
    return (
        <Table
            rowKey="id"
            columns={columns}
            loading={isFetching}
            dataSource={discountBanners}
            pagination={false}
        />
    )
}

export default DiscountBannersTable

import React from 'react'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Tooltip, Switch } from 'antd'
import ActiveFilters from 'components/layout/filters/WithActiveFilters'
import WithMe from 'components/pages/authorized/WithMe'
import ToggleDiscountBannerRequest from '../ToggleDiscountBannerRequest'

const ACTIVE_BOOKING_TEXT = 'Set discount inactive'
const INACTIVE_BOOKING_TEXT = 'Set discount active'

function ActivateDiscountSwitch({
    discountBannerId,
    isActive,
}) {
    return (
        <WithMe>
            {({ isReadOnly }) => (
                <ActiveFilters>
                    {({ activeVenueId }) => (
                        <ToggleDiscountBannerRequest venueId={activeVenueId}>
                            {({ toggleActiveStatus, isLoading }) => (
                                <Tooltip
                                    placement="bottom"
                                    title={isActive ? ACTIVE_BOOKING_TEXT : INACTIVE_BOOKING_TEXT}
                                >
                                    <Switch
                                        loading={isLoading}
                                        disabled={isReadOnly}
                                        size="small"
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        onChange={(value) => toggleActiveStatus(discountBannerId, value)}
                                        checked={isActive}
                                    />
                                </Tooltip>
                            )}
                        </ToggleDiscountBannerRequest>
                    )}
                </ActiveFilters>
            )}
        </WithMe>
    )
}

export default ActivateDiscountSwitch

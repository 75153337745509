/* eslint-disable max-len */
import React, { useState } from 'react'
import {
    Modal, Form, Input, Select, Divider, Alert,
} from 'antd'
import FormItem from 'components/util/forms/FormItem'
import dayjs from 'dayjs'
import { assignableStatusses, STATUS_AVAILABLE } from './TSConstants'
// eslint-disable-next-line no-unused-vars
import * as RoomAndTimeSlotTypes from './TimeSlotTypes'
import { APP_DATE_FORMAT } from '../../../../util/constants'

const { Option } = Select

/**
 * @typedef {Object} UpdateSlotsModalProps
 * @property {boolean} isModalOpen
 * @property {() => void} onCancel
 * @property {(comment: string, status: string) => void} onSubmit
 * @property {string} activeDateValue
 * @property {RoomAndTimeSlotTypes.RoomAndTimeSlots[]} selectedRoomsAndTimeSlots
 * @property {(roomsAndTimeSlots: RoomAndTimeSlotTypes.RoomAndTimeSlots[]) => boolean} hasSlotsOutsideBookableHours
 * @property {Object} errors
*/

/**
 * @param {UpdateSlotsModalProps} props
 */

function UpdateSlotsModal(props) {
    const {
        isModalOpen,
        onCancel,
        onSubmit,
        activeDateValue,
        selectedRoomsAndTimeSlots,
        hasSlotsOutsideBookableHours,
        // eslint-disable-next-line no-unused-vars
        errors,
    } = props

    const [commentToSend, setCommentToSend] = useState('')

    const [statusToSend, setStatusToSend] = useState('buffer')

    const [commentError, setCommentError] = useState(undefined)

    const slotIsBetweenBookableHours = (timeSlot, bookableHours) => {
        if (timeSlot.roomStart >= bookableHours.end || timeSlot.roomStart < bookableHours.start) {
            return false
        }
        return true
    }

    const onOkClick = async () => {
        // wait for the onSubmit function to finish
        const onSubmitReturn = await onSubmit(commentToSend, statusToSend)

        // if the onSubmit function returns an error, set the error message
        if (typeof onSubmitReturn !== 'undefined') {
            // using flatmap to flatten the array of arrays into a single array
            if (typeof onSubmitReturn.errors !== 'undefined') {
                const errorValues = Object.values(onSubmitReturn.errors).flatMap((value) => value)

                let errorMessage = ''
                // eslint-disable-next-line no-return-assign
                errorValues.map((value) => errorMessage += `${value} `)

                setCommentError(errorMessage)
            } else {
                setCommentError('Something went wrong.')
            }
        }
    }

    const onCancelClick = () => {
        onCancel()
        setCommentError(false)
    }

    return (
        <Modal
            title="Update timeslot(s)"
            open={isModalOpen}
            okText="Update slots"
            onOk={onOkClick}
            onCancel={onCancelClick}
        >
            <Form>
                {hasSlotsOutsideBookableHours === true && (
                    <div>
                        <Alert
                            message="Note: Your selection includes timeslots that are outside the rooms bookable hours."
                            type="error"
                            style={{ marginBottom: '20px' }}
                        />
                        <Divider />
                    </div>
                )}
                <p>
                    Currently selected time slots on
                    {' '}
                    <strong>{ dayjs.utc(activeDateValue).format(APP_DATE_FORMAT) }</strong>:
                </p>

                {
                    selectedRoomsAndTimeSlots.map((room) => {
                        if (room.uiSlots.length > 0) {
                            return (
                                <div key={room.roomId}>
                                    <p>{room.roomName}:</p>
                                    <ul key={room.roomId}>
                                        {room.uiSlots.map(({ slot }) => {
                                            const slotBetweenBookableHours = slotIsBetweenBookableHours(
                                                slot,
                                                room.bookableHours,
                                            )
                                            return (
                                                <li
                                                    key={slot.roomStart}
                                                    style={{
                                                        display: 'flex',
                                                        gap: '5px',
                                                    }}
                                                >
                                                    <div>From: {slot.roomStart} till {slot.roomEnd}</div>
                                                    {!slotBetweenBookableHours && (
                                                        <div style={{ color: 'red' }}>
                                                            {' '}
                                                            (Outside bookable hours.)
                                                        </div>
                                                    )}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            )
                        }
                        return null
                    })
                }

                <Divider />

                <p>
                    Please select a status.
                </p>

                <Select defaultValue="buffer" onChange={(e) => setStatusToSend(e)}>
                    {assignableStatusses.map((status) => (
                        <Option key={status} value={status}>{status}</Option>
                    ))}
                </Select>

                {statusToSend !== STATUS_AVAILABLE && (
                    <div>
                        <Divider />
                        <p>
                            Please provide a reason why you&apos;re updating it.
                        </p>
                        <FormItem
                            wrapperCol={{
                                xs: { span: 24 },
                                sm: { span: 24 },
                            }}
                            isRequired
                            error={commentError}
                        >
                            <Input.TextArea
                                rows="4"
                                name="comment"
                                value={commentToSend}
                                onChange={(e) => [setCommentError(false), setCommentToSend(e.target.value)]}
                                placeholder="Reason for updating slots."
                            />
                        </FormItem>
                    </div>
                )}

            </Form>
        </Modal>
    )
}

export default UpdateSlotsModal

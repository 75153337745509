import { CALL_API, METHODS } from '../middleware/api'
import { discountBannersSchema, discountBannersSchemaArray } from '../schemas/discountBanners'

export const DISCOUNT_BANNERS_REQUEST = 'DISCOUNT_BANNERS_REQUEST'
export const DISCOUNT_BANNERS_SUCCESS = 'DISCOUNT_BANNERS_SUCCESS'
export const DISCOUNT_BANNERS_FAILURE = 'DISCOUNT_BANNERS_FAILURE'

export const DISCOUNT_BANNER_REQUEST = 'DISCOUNT_BANNER_REQUEST'
export const DISCOUNT_BANNER_SUCCESS = 'DISCOUNT_BANNER_SUCCESS'
export const DISCOUNT_BANNER_FAILURE = 'DISCOUNT_BANNER_FAILURE'

export const SET_DISCOUNT_BANNER_ACTIVE = 'SET_DISCOUNT_BANNER_ACTIVE'
export const SET_DISCOUNT_BANNER_INACTIVE = 'SET_DISCOUNT_BANNER_INACTIVE'

export const fetchDiscountBanners = (viewName, venueId, params = {}) => ({
    viewName,
    venueId,
    [CALL_API]: {
        types: [
            DISCOUNT_BANNERS_REQUEST,
            DISCOUNT_BANNERS_SUCCESS,
            DISCOUNT_BANNERS_FAILURE,
        ],
        endpoint: `/venues/${venueId}/discount-banners`,
        schema: discountBannersSchemaArray,
        params,
    },
})

export const fetchDiscountBannersForActiveVenue = (viewName, params) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch(fetchDiscountBanners(viewName, venueId, params))
}

export const fetchDiscountBannersExtendCurrentParams = (paginationKey, params) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    const pagination = getState().pagination.discountBanners[paginationKey]
    const newParams = {
        ...pagination.params,
        ...params,
    }
    return dispatch(fetchDiscountBanners(paginationKey, venueId, newParams))
}

export const createDiscountBanner = (body) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch({
        [CALL_API]: {
            method: METHODS.POST,
            types: [
                DISCOUNT_BANNER_REQUEST,
                DISCOUNT_BANNER_SUCCESS,
                DISCOUNT_BANNER_FAILURE,
            ],
            endpoint: `/venues/${venueId}/discount-banners`,
            schema: discountBannersSchema,
            body,
        },
    })
}

export const editDiscountBanner = (bannerId, body) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch({
        [CALL_API]: {
            method: METHODS.PUT,
            types: [
                DISCOUNT_BANNER_REQUEST,
                DISCOUNT_BANNER_SUCCESS,
                DISCOUNT_BANNER_FAILURE,
            ],
            endpoint: `/venues/${venueId}/discount-banners/${bannerId}`,
            schema: discountBannersSchema,
            body,
        },
    })
}

export const deleteDiscountBanner = (bannerId) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch({
        [CALL_API]: {
            method: METHODS.DELETE,
            types: [
                DISCOUNT_BANNER_REQUEST,
                DISCOUNT_BANNER_SUCCESS,
                DISCOUNT_BANNER_FAILURE,
            ],
            endpoint: `/venues/${venueId}/discount-banners/${bannerId}`,
            schema: discountBannersSchema,
            entityId: bannerId,
        },
    })
}

export const setDiscountBannerActive = (discountBannerId) => ({
    type: SET_DISCOUNT_BANNER_ACTIVE,
    discountBannerId,
})

export const setDiscountBannerInactive = (discountBannerId) => ({
    type: SET_DISCOUNT_BANNER_INACTIVE,
    discountBannerId,
})

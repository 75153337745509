import { METHODS } from 'store/middleware/api'
import { CALL_API } from '../middleware/api'
import { priceUpdateSchema } from '../schemas/priceUpdate'
import { bookingSchema } from '../schemas/index'

export const FETCH_PRICE_UPDATE_REQUEST = 'FETCH_PRICE_UPDATE_REQUEST'
export const FETCH_PRICE_UPDATE_SUCCESS = 'FETCH_PRICE_UPDATE_SUCCESS'
export const FETCH_PRICE_UPDATE_FAILURE = 'FETCH_PRICE_UPDATE_FAILURE'

export const fetchPriceUpdate = (booking, params) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch({
        [CALL_API]: {
            types: [
                FETCH_PRICE_UPDATE_REQUEST,
                FETCH_PRICE_UPDATE_SUCCESS,
                FETCH_PRICE_UPDATE_FAILURE,
            ],
            endpoint: `/venues/${venueId}/bookings/${booking}/pricing`,
            schema: priceUpdateSchema,
            params,
        },
    })
}

export const UPDATE_PRICE_REQUEST = 'UPDATE_PRICE_REQUEST'
export const UPDATE_PRICE_SUCCESS = 'UPDATE_PRICE_SUCCESS'
export const UPDATE_PRICE_FAILURE = 'UPDATE_PRICE_FAILURE'

export const updatePrice = (booking, body) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch({
        [CALL_API]: {
            method: METHODS.PATCH,
            types: [
                UPDATE_PRICE_REQUEST,
                UPDATE_PRICE_SUCCESS,
                UPDATE_PRICE_FAILURE,
            ],
            endpoint: `/venues/${venueId}/bookings/${booking}/pricing`,
            schema: bookingSchema,
            body,
        },
    })
}

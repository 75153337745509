import React, {useCallback} from "react"
import "./GameSessionTeamEntry.css"
import {Input, notification} from "antd"
import {FileJpgOutlined} from "@ant-design/icons"
import {MAX_UPLOAD_FILE_BYTES, MAX_UPLOAD_FILE_MB} from "../../../../util/constants"

/**
 * @component
 * @param {Object} props
 * @param {GameSessionPlayersState} props.gsState
 * @param {GameSessionTeam} props.team
 * @param {number} props.index
 * @param {boolean} props.isProcessingRequest
 * @return {JSX.Element}
 */
export function GameSessionTeamEntry(props) {
    const containerClassNames = ["gsste-root", "gssp-container-padding"]
    if (props.index % 2 !== 0)
        containerClassNames.push("gssp-left-border")
    const containerClassName = containerClassNames.join(" ")

    const teamNumber = props.index + 1
    const teamNameValid = props.team.name.length > 1

    const onFilePickerChanged = useCallback((e) => {
        // console.log("e:", e)
        const input =  e.target
        // console.log("Input files:", input.files)
        if (input.files.length !== 0) {
            const file = input.files[0]
            // console.log("File size:", file.size)
            // console.log("Max upload bytes:", MAX_UPLOAD_FILE_BYTES)
            if (file.size > MAX_UPLOAD_FILE_BYTES) {
                notification.error({
                    message: "Max file size exceeded",
                    description: `Chosen file exceeds the max upload size of ${MAX_UPLOAD_FILE_MB}MB`,
                })
                return
            }

            // console.log("First file:", file)
            let fileName = `${props.team.id}_team_avatar`
            switch (file.type) {
                case "image/png":
                    fileName += ".png"
                    break
                case "image/jpg":
                    fileName += ".jpg"
                    break
                case "image/jpeg":
                    fileName += ".jpeg"
                    break
                case "image/gif":
                    fileName += ".gif"
                    break
                default:
                    notification.error({
                        message: "Selected file type can only be of type jpg, jpeg, png or gif.",
                    })
                    return
            }

            // Create temporary locally hosted url to selected image
            const fileUrl = URL.createObjectURL(input.files[0])
            props.gsState.updateTeamImageByIndex(props.index, fileUrl, fileName)
        }
    }, [props.team, props.gsState, props.index])

    const onAvatarClick = useCallback(() => {
        if (props.isProcessingRequest)
            return

        // Read: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
        const elm = document.createElement("input")
        elm.id = "team_" + teamNumber
        elm.type = "file"
        elm.accept = "image/png, image/jpeg, image/jpg, image/gif"
        elm.onchange = onFilePickerChanged
        elm.click()
    }, [onFilePickerChanged, props.isProcessingRequest, teamNumber])

    return (
        <div className={containerClassName}>
            <div
                className="gsste-team-avatar-container"
                onClick={onAvatarClick}
            >
                {
                    typeof props.team.teamAvatarImgSrc === "string" && props.team.teamAvatarImgSrc.length > 0 && (
                        <img src={props.team.teamAvatarImgSrc} className={"gsste-team-avatar-img"} alt={`Team avatar of team: ${props.team.name}`}/>
                    )
                }
                <div className="gsste-upload-place-holder">
                    {/*<UploadOutlined className="gsste-upload-icon"/>*/}
                    {/*<FileImageOutlined className="gsste-upload-icon" />*/}
                    <FileJpgOutlined className="gsste-upload-icon"/>
                    <p className="m-0">Select image</p>
                </div>
            </div>
            <div>
                <p className="gsste-team-title">Team {teamNumber}</p>
                <label className="gsste-team-input-label">Team name</label>
                <Input
                    value={props.team.name}
                    status={teamNameValid === false && "error"}
                    max={30}
                    disabled={props.isProcessingRequest}
                    onChange={(e) => props.gsState.updateTeamNameByIndex(props.index, e.target.value)}
                />
            </div>
        </div>
    )
}
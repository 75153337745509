import createPaginationReducer from '../hor/createPaginationReducer'
import {
    AVAILABILITIES_REQUEST,
    AVAILABILITIES_SUCCESS,
    AVAILABILITIES_FAILURE,
} from '../../actions/availabilities'

export const getPaginationKey = (componentName, venueId, gameId, date, playerAmount, isPrivate) => {
    const paramsString = `date=${date}&playerAmount=${playerAmount}&is_private=${isPrivate}`
    const endpointString = `venues/${venueId}/games${gameId}`
    return `${componentName}(${endpointString}?${paramsString})`
}

const availabilitiesPaginationReducer = createPaginationReducer({
    mapActionToKey: ({
        componentName,
        venueId,
        gameId,
        params,
    }) => getPaginationKey(
        componentName,
        venueId,
        gameId,
        params.date,
        params.playerAmount,
        params.isPrivate,
    ),
    types: [
        AVAILABILITIES_REQUEST,
        AVAILABILITIES_SUCCESS,
        AVAILABILITIES_FAILURE,
    ],
})

export default availabilitiesPaginationReducer

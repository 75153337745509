import React from 'react'
import * as theme from 'styles/theme'
import { FrownOutlined } from '@ant-design/icons'
import { Alert, Button } from 'antd'

function AppErrorPage({
    onReportFeedback,
    error,
}) {
    return (
        <div className="errorPageContainer">
            <div className="content">
                <FrownOutlined style={{ fontSize: theme.fontSizeHuge }} />

                <div className="errorPageRow">
                    <h2>
                        Something went wrong, <br />
                        please try again later
                    </h2>
                </div>

                {error && error.message && (
                    <div className="errorPageError">
                        <Alert
                            message={error.message}
                            description={error.stack}
                            type="error"
                        />
                    </div>
                )}

                {typeof onReportFeedback === 'function' && (
                    <div className="errorPageRow">
                        <Button onClick={onReportFeedback}>
                            Report error
                        </Button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default AppErrorPage

import React from 'react'
import { editCoupon, fetchCouponsExtendCurrentParams } from 'store/actions/coupons'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    editCoupon: async (couponId, values) => dispatch(editCoupon(couponId, values)),
    editCouponSuccess: (coupon) => {
        notification.success({
            message: 'Coupon code edited!',
            description: (
                <span>
                    Coupon has been edited.&nbsp;
                    <strong>{coupon && coupon.code}</strong>
                    &nbsp;
                    was successfully edited.
                </span>
            ),
        })
    },
    editCouponFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchCoupons: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchCouponsExtendCurrentParams(paginationKey))
    },
})

const EditCouponRequest = withReduxState(null, mapDispatchToProps)

export default EditCouponRequest

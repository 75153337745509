import React from 'react'
import { editRoomForActiveVenue, fetchRoomsExtendCurrentParams } from 'store/actions/rooms'
import { getEntitiesAsArray } from 'store/util/index'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapStateToProps = (state, ownProps) => {
    const { room } = ownProps
    const equipmentSets = getEntitiesAsArray(state, 'equipmentSets')
    const roomEquipmentSetIds = room.equipmentSets
    const roomEquipmentSets = equipmentSets.filter((set) => roomEquipmentSetIds.includes(set.id))
    return ({
        equipmentSets: roomEquipmentSets,
    })
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    editRoom: async (values) => dispatch(editRoomForActiveVenue(ownProps.room.id, values)),
    editRoomSuccess: (room) => {
        notification.success({
            message: 'Room edited!',
            description: (
                <span>
                    The room&nbsp;
                    <strong>{room && room.name}</strong>
                    &nbsp;
                    was successfully edited.
                </span>
            ),
        })
    },
    editRoomFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchRooms: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchRoomsExtendCurrentParams(paginationKey))
    },
})

const EditRoomRequest = withReduxState(mapStateToProps, mapDispatchToProps)

export default EditRoomRequest

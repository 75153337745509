import { CALL_API, METHODS } from '../middleware/api'
import { priceAdjustmentSchema } from '../schemas/priceAdjustments'

export const PRICE_ADJUSTMENT_REQUEST = 'PRICE_ADJUSTMENT_REQUEST'
export const PRICE_ADJUSTMENT_SUCCESS = 'PRICE_ADJUSTMENT_SUCCESS'
export const PRICE_ADJUSTMENT_FAILURE = 'PRICE_ADJUSTMENT_FAILURE'

export const createPriceAdjustment = (gameId, body) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch({
        [CALL_API]: {
            method: METHODS.POST,
            types: [
                PRICE_ADJUSTMENT_REQUEST,
                PRICE_ADJUSTMENT_SUCCESS,
                PRICE_ADJUSTMENT_FAILURE,
            ],
            endpoint: `/venues/${venueId}/games/${gameId}/price-adjustments`,
            schema: priceAdjustmentSchema,
            body,
        },
    })
}

export const deletePriceAdjustment = (gameId, priceAdjustmentId) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch({
        [CALL_API]: {
            method: METHODS.DELETE,
            types: [
                PRICE_ADJUSTMENT_REQUEST,
                PRICE_ADJUSTMENT_SUCCESS,
                PRICE_ADJUSTMENT_FAILURE,
            ],
            endpoint: `/venues/${venueId}/games/${gameId}/price-adjustments/${priceAdjustmentId}`,
            schema: priceAdjustmentSchema,
            entityId: priceAdjustmentId,
        },
    })
}

export const editPriceAdjustment = (gameId, priceAdjustmentId, body) => (dispatch, getState) => {
    const { venueId } = getState().activeFilters
    return dispatch({
        [CALL_API]: {
            method: METHODS.PUT,
            types: [
                PRICE_ADJUSTMENT_REQUEST,
                PRICE_ADJUSTMENT_SUCCESS,
                PRICE_ADJUSTMENT_FAILURE,
            ],
            endpoint: `/venues/${venueId}/games/${gameId}/price-adjustments/${priceAdjustmentId}`,
            schema: priceAdjustmentSchema,
            body,
        },
    })
}

import { useSelector, useDispatch } from 'react-redux'
import { useCallback, useState, useMemo } from 'react'
import { fetchCoupons } from '../../store/actions/coupons'

/**
 * @typedef {Object} PaginationParams
 * @property {number | undefined} page Current page to fetch
 * @property {number | undefined} limit How many items to fetch per page
 * @property {string | undefined } sort Order of the items. E.g. "created_at.desc"
 * @property {string | undefined} search Search coupons by code or description
 * @property {string | undefined} status Filter coupons by status 'active' or 'in_active'
 */

/**
 * @typedef {Object} ReturnObject
 * @property {boolean} initialFetchStarted Has the first fetch already occurred?
 * @property {boolean} isFetching Is fetching data?
 * @property {number} currentPage Current page
 * @property {number} perPage Amount of records per page
 * @property {number} totalPages Total amount of pages
 * @property {PaginationParams} paginationParams Currently used pagination params
 * @property {Array} couponsForCurrentPage All coupons for the current page
 * @property {(paginationParams: PaginationParams) => void} fetchCoupons
 */

const DEFAULT_SORT = 'created_at.desc'
const DEFAULT_STATUS = 'active'
const DEFAULT_PAGE_SIZE = 10
const DEFAULT_PAGE = 1

/**
 * @param paginationKey e.g. 'overview'
 * @returns ReturnObject
 */
export function usePaginatedCoupons(paginationKey) {
    const pagination = useSelector(state => state.pagination.coupons[paginationKey])
    const coupons = useSelector(state => state.entities.coupons)
    // const couponsForCurrentPage = useSelector((state) => getEntitiesForCurrentPage(state, 'coupons', paginationKey))
    const dispatch = useDispatch()
    const [initialFetchStarted, setInitialFetchStarted] = useState(false)

    const couponsForCurrentPage = useMemo(() => {
        if (typeof coupons !== 'object')
            return []
        if (typeof pagination?.params?.page !== 'number')
            return []
        if (pagination.isFetching)
            return []

        const idsInPage = pagination.pages[pagination.params.page]
        const entriesInPage = []
        for (let i = 0; i < idsInPage.length; i++) {
            const id = idsInPage[i]
            const coupon = coupons[id]

            // Have to do this, because on deletion, the voucher with specified id
            // is 'unset' from the store object, but still exists in the page array...
            // That causes a brief moment where one of the ids in the page result
            // returns undefined. This is a workaround for that.
            // YES, THIS IS FCKING SHITTY CODE, you can thank Wappla for that...
            if (typeof coupon === 'undefined' || coupon === null)
                continue

            entriesInPage.push(coupon)
        }

        return entriesInPage
    }, [pagination, coupons])

    /**
     * @param {PaginationParams} paginationParams
     */
    const localFetchCoupons = useCallback((paginationParams) => {
        setInitialFetchStarted(true)
        const finalParams = {}
        if (typeof paginationParams.page === 'number')
            finalParams.page = paginationParams.page
        else
            finalParams.page = DEFAULT_PAGE

        if (typeof paginationParams.limit === 'number')
            finalParams.limit = paginationParams.limit
        else
            finalParams.limit = DEFAULT_PAGE_SIZE

        if (paginationParams.sort)
            finalParams.sort = paginationParams.sort
        else
            finalParams.sort = DEFAULT_SORT

        if (typeof paginationParams.search === 'string' && paginationParams.search.length > 0)
            finalParams.search = paginationParams.search

        if (typeof paginationParams.status === 'string' && ['active', 'in_active'].includes(paginationParams.status))
            finalParams.status = paginationParams.status
        else
            finalParams.status = DEFAULT_STATUS

        dispatch(fetchCoupons(paginationKey, finalParams))
    }, [paginationKey, dispatch])

    /** @type {ReturnObject} result */
    const result = useMemo(() => {
        if (typeof pagination !== 'object') {
            return {
                initialFetchStarted: initialFetchStarted,
                isFetching: false,
                fetchCoupons: localFetchCoupons,

                currentPage: DEFAULT_PAGE,
                totalPages: 1,
                perPage: DEFAULT_PAGE_SIZE,
                paginationParams: {
                    page: DEFAULT_PAGE,
                    limit: DEFAULT_PAGE_SIZE,
                    sort: DEFAULT_SORT,
                    status: DEFAULT_STATUS,
                },

                couponsForCurrentPage: couponsForCurrentPage,
            }
        } else {
            return {
                initialFetchStarted: initialFetchStarted,
                isFetching: pagination.isFetching,
                fetchCoupons: localFetchCoupons,

                currentPage: pagination.params.page,
                totalPages: pagination.total,
                perPage: pagination.params.limit || DEFAULT_PAGE_SIZE,
                paginationParams: pagination.params,

                couponsForCurrentPage: couponsForCurrentPage,
            }
        }
    }, [initialFetchStarted, localFetchCoupons, pagination, couponsForCurrentPage])

    return result
}

import { PER_PLAYER } from 'util/constants'
import { capitalize } from 'util/index'
import {
    isRequired,
    isValidHref,
    isAlphaNumeric,
} from './index'

export function isValidBody(body) {
    return isRequired(body)
}

export function isValidDuration(duration) {
    return isRequired(duration) && duration > 0
}

export function isValidMinPlayers(minPlayers, maxPlayers) {
    return isRequired(minPlayers)
        && typeof minPlayers === 'number'
        && minPlayers > 0
        && minPlayers < maxPlayers
}

export function isValidMaxPlayers(maxPlayers, minPlayers) {
    return isRequired(maxPlayers)
        && typeof maxPlayers === 'number'
        && maxPlayers >= 2
        && maxPlayers > minPlayers
}

export function isValidPrice(price) {
    return isRequired(price) && price >= 0
}

export function isValidPrices(prices, priceSystem) {
    const isValidPriceReducer = (isValid, [playerPrice, totalPrice]) => {
        if (isValid)
            return true

        if (priceSystem === PER_PLAYER)
            return isValidPrice(playerPrice)

        return isValidPrice(playerPrice) && isValidPrice(totalPrice)
    }

    return prices.reduce(isValidPriceReducer, false)
}

export function isValidReadMoreLink(link) {
    return isRequired(link) && isValidHref(link)
}

export function isValidExtraConditionsLink(link) {
    return link === '' || link === null || isValidHref(link)
}

export function isValidVideoLink(link) {
    // Change if it ever becomes required
    if (typeof link !== 'string' || link.length === 0)
        return true

    return isValidHref(link)
}

export function isValidTitle(title) {
    return isRequired(title)
}

export function isValidMediaItem(mediaItem) {
    return isRequired(mediaItem)
}

export function isValidNumberOfTeams(enabled, numberOfTeams, maxPlayers) {
    if (!enabled)
        return true

    const isValid = isRequired(numberOfTeams)
        && typeof numberOfTeams === 'number'
        && numberOfTeams > 1
        && maxPlayers >= numberOfTeams

    return isValid
}

export function formHasErrorsForLocale(errors, locale) {
    const localeCapitalized = capitalize(locale)

    const body = errors[`body${localeCapitalized}`]
    if (body !== null && typeof body !== 'undefined')
        return true

    const title = errors[`title${localeCapitalized}`]
    if (title !== null && typeof title !== 'undefined')
        return true

    const readMoreLink = errors[`readMoreLink${localeCapitalized}`]
    if (readMoreLink !== null && typeof readMoreLink !== 'undefined')
        return true

    return false
    //
    // return !!(
    //     errors[`body${localeCapitalized}`]
    //     || errors[`title${localeCapitalized}`]
    //     || errors[`readMoreLink${localeCapitalized}`]
    // )
}

export function formHasLocaleErrors(errors) {
    return formHasErrorsForLocale(errors, 'Nl')
        || formHasErrorsForLocale(errors, 'En')
        || formHasErrorsForLocale(errors, 'Fr')
}

export function formHasErrorsForCurrency(errors, currency) {
    const playerPriceError = errors[`playerPrice${currency}`]
    if (typeof playerPriceError !== 'undefined' || playerPriceError !== null)
        return true

    const totalPriceError = errors[`totalPrice${currency}`]
    if (typeof totalPriceError !== 'undefined' || totalPriceError !== null)
        return true

    return false
}

export function isValidCode(code) {
    return isRequired(code) && isAlphaNumeric(code)
}

export function isValidGame({
    bodyEn,
    bodyNl,
    bodyFr,
    duration,
    minPlayers,
    mediaItemId,
    maxPlayers,
    playerPriceEUR,
    playerPriceGBP,
    playerPriceUSD,
    totalPriceEUR,
    totalPriceGBP,
    totalPriceUSD,
    readMoreLinkEn,
    readMoreLinkNl,
    readMoreLinkFr,
    extraConditionsLinkNl,
    extraConditionsLinkEn,
    extraConditionsLinkFr,
    titleEn,
    titleNl,
    titleFr,
    enableTeams,
    numberOfTeams,
    videoUrl,
    code,
}, priceSystem) {
    return isValidBody(bodyEn)
        && isValidBody(bodyNl)
        && isValidBody(bodyFr)
        && isValidDuration(duration)
        && isValidMediaItem(mediaItemId)
        && isValidMinPlayers(minPlayers, maxPlayers)
        && isValidMaxPlayers(maxPlayers, minPlayers)
        && isValidReadMoreLink(readMoreLinkEn)
        && isValidReadMoreLink(readMoreLinkNl)
        && isValidReadMoreLink(readMoreLinkFr)
        && isValidExtraConditionsLink(extraConditionsLinkEn)
        && isValidExtraConditionsLink(extraConditionsLinkNl)
        && isValidExtraConditionsLink(extraConditionsLinkFr)
        && isValidTitle(titleEn)
        && isValidTitle(titleNl)
        && isValidTitle(titleFr)
        && isValidNumberOfTeams(enableTeams, numberOfTeams, maxPlayers)
        && isValidVideoLink(videoUrl)
        && isValidPrices([
            [playerPriceEUR, totalPriceEUR],
            [playerPriceGBP, totalPriceGBP],
            [playerPriceUSD, totalPriceUSD],
        ], priceSystem)
        && isValidCode(code)
}

export function isValidEditGame({
    bodyEn,
    bodyNl,
    bodyFr,
    duration,
    mediaItem,
    minPlayers,
    maxPlayers,
    playerPriceEUR,
    playerPriceGBP,
    playerPriceUSD,
    playerPriceAUD,
    totalPriceEUR,
    totalPriceGBP,
    totalPriceUSD,
    totalPriceAUD,
    readMoreLinkEn,
    readMoreLinkNl,
    readMoreLinkFr,
    extraConditionsLinkNl,
    extraConditionsLinkEn,
    extraConditionsLinkFr,
    titleEn,
    titleNl,
    titleFr,
    enableTeams,
    numberOfTeams,
    videoUrl,
}, priceSystem) {
    return isValidBody(bodyEn)
        && isValidBody(bodyNl)
        && isValidBody(bodyFr)
        && isValidDuration(duration)
        && isValidMediaItem(mediaItem)
        && isValidMinPlayers(minPlayers, maxPlayers)
        && isValidMaxPlayers(maxPlayers, minPlayers)
        && isValidReadMoreLink(readMoreLinkEn)
        && isValidReadMoreLink(readMoreLinkNl)
        && isValidReadMoreLink(readMoreLinkFr)
        && isValidExtraConditionsLink(extraConditionsLinkEn)
        && isValidExtraConditionsLink(extraConditionsLinkNl)
        && isValidExtraConditionsLink(extraConditionsLinkFr)
        && isValidTitle(titleEn)
        && isValidTitle(titleNl)
        && isValidTitle(titleFr)
        && isValidNumberOfTeams(enableTeams, numberOfTeams, maxPlayers)
        && isValidVideoLink(videoUrl)
        && isValidPrices([
            [playerPriceEUR, totalPriceEUR],
            [playerPriceGBP, totalPriceGBP],
            [playerPriceUSD, totalPriceUSD],
            [playerPriceAUD, totalPriceAUD],
        ], priceSystem)
}

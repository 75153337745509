import localForage from 'localforage'

const ACCESS_TOKEN_KEY = 'accessTokenAtelier'
const REFRESH_TOKEN_KEY = 'refreshTokenAtelier'

// In memory cache
/* eslint-disable import/no-mutable-exports */
export let accessToken = null
export let refreshToken = null

export const ready = (...args) => localForage.ready(...args)

export const loadAccessToken = async () => {
    accessToken = await localForage.getItem(ACCESS_TOKEN_KEY)
}

/**
 * @return {Promise<string | null | undefined>}
 */
export const getAccessToken = async () => {
    if (accessToken === null) {
        accessToken = await localForage.getItem(ACCESS_TOKEN_KEY)
    }
    return accessToken
}

export const setAccessToken = (newAccessToken) => {
    accessToken = newAccessToken
    return localForage.setItem(ACCESS_TOKEN_KEY, newAccessToken)
}

export const getRefreshToken = async () => {
    if (refreshToken === null) {
        refreshToken = await localForage.getItem(REFRESH_TOKEN_KEY)
    }
    return refreshToken
}

export const setRefreshToken = (newRefreshToken) => {
    refreshToken = newRefreshToken
    return localForage.setItem(REFRESH_TOKEN_KEY, newRefreshToken)
}

export const removeAccessToken = () => {
    accessToken = null
    return localForage.removeItem(ACCESS_TOKEN_KEY)
}

export const removeRefreshToken = () => {
    refreshToken = null
    return localForage.removeItem(REFRESH_TOKEN_KEY)
}

import dotProp from 'dot-prop-immutable'
import { SET_GAME_SESSION_ACTIVE, SET_GAME_SESSION_INACTIVE } from '../../actions/gameSessions'

const activeGameSessionStatusReducer = (state, action) => {
    const { type, gameSessionId, roomId } = action
    switch (type) {
        case SET_GAME_SESSION_ACTIVE: {
            return dotProp.set(state, 'entities.gameSessions', (gameSessions) => Object
                .keys(gameSessions)
                .map((id) => gameSessions[id])
                .map((gameSession) => {
                    if (gameSession.room.id === roomId) {
                        return ({
                            ...gameSession,
                            isActive: gameSession.id === gameSessionId,
                        })
                    }
                    return gameSession
                })
                .reduce((acc, curr) => {
                    acc[curr.id] = curr
                    return acc
                }, {}))
        }
        case SET_GAME_SESSION_INACTIVE: {
            return dotProp.set(state, `entities.gameSessions.${gameSessionId}.isActive`, false)
        }
        default:
            return state
    }
}

export default activeGameSessionStatusReducer

import React from 'react'
import { Select } from 'antd'
import {isValidString} from "../../../../../util/string-utils"

/**
 * @param {GameVenueResource} gameVenue
 * @return {boolean}
 */
function hasRooms(gameVenue) {
    return Array.isArray(gameVenue.rooms) && gameVenue.rooms.length > 0
}

/**
 * @param {number} gameId
 * @param {GameVenueResource[]} gameVenues
 * @return {*}
 */
function getGameVenueByGameId(gameId, gameVenues) {
    if (!Array.isArray(gameVenues))
        return undefined

    return gameVenues.find(gv => gv.gameId === gameId)
}

/**
 * @param {Object} props
 * @param {number} props.value
 * @param {string=} props.placeholder
 * @param {GameVenueResource[]} props.gameVenues
 * @param {(gameId: number, gameVenue: GameVenueResource|null) => void} props.onChange
 * @param {boolean=} props.disabled
 */
export function GamesSelect(props) {
    const gameVenuesLength = props.gameVenues.length
    let finalPlaceHolder
    let finalValue
    if (gameVenuesLength === 0) {
        finalValue = null
        finalPlaceHolder = "Loading vr experiences"
    } else {
        finalValue = props.value
        finalPlaceHolder = isValidString(props.placeholder) ? props.placeholder : "Select a vr experience"
    }

    return (
        <Select
            value={finalValue}
            onChange={(newGameId) => {
                const gameVenue = getGameVenueByGameId(newGameId, props.gameVenues)
                if (typeof gameVenue === "undefined") {
                    props.onChange(newGameId, null)
                } else {
                    props.onChange(newGameId, gameVenue)
                }
            }}
            disabled={gameVenuesLength === 0 || props.disabled}
            placeholder={finalPlaceHolder}
        >
            {
                props.gameVenues.filter((gameVenue) => hasRooms(gameVenue)).map((/** @type {GameVenueResource} */ gameVenue) => (
                    <Select.Option
                        key={gameVenue.gameId}
                        value={gameVenue.gameId}
                    >
                        {gameVenue.title}
                    </Select.Option>
                ))
            }
        </Select>
    )
}

import { notification } from 'antd'
import { editCouponTimeFrame, fetchCouponsExtendCurrentParams } from 'store/actions/coupons'
import withReduxState from 'components/hoc/withReduxState'

const mapDispatchToProps = (dispatch, ownProps) => ({
    editCouponTimeFrame: (couponTimeFrameId, body) => dispatch(editCouponTimeFrame(ownProps.couponTimeFrame.couponId, couponTimeFrameId, body)),
    editCouponTimeFrameSuccess: () => {
        notification.success({
            message: 'Timeframe edited!',
            description: 'The timeframe for the coupon was successfully edited.',
        })
    },
    editCouponTimeFrameFailed: ({ message }) => {
        notification.error({
            message: 'Something went wrong',
            description: message,
        })
    },
    fetchCoupons: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchCouponsExtendCurrentParams(paginationKey))
    },
})

const EditCouponTimeFrameRequest = withReduxState(null, mapDispatchToProps)

export default EditCouponTimeFrameRequest

import { getPagination, getEntitiesByPagination, getEntitiesForCurrentPage } from 'store/util'
import { fetchGiftCards } from 'store/actions/giftCards'
import withReduxState from 'components/hoc/withReduxState'

const mapStateToProps = (state, ownProps) => {
    const pagination = getPagination(state, 'giftCards', ownProps.paginationKey)
    return ({
        isFetching: pagination.isFetching,
        currentPage: pagination.params.page,
        total: pagination.total,
        params: pagination.params,
        giftCards: getEntitiesByPagination(state, 'giftCards', ownProps.paginationKey),
        giftCardsForCurrentPage: getEntitiesForCurrentPage(state, 'giftCards', ownProps.paginationKey),
    })
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const {
        paginationKey,
        limit,
    } = ownProps
    const page = 1
    const status = 'active'
    const sort = 'created_at.desc'
    return ({
        fetchGiftCards: (params) => {
            const finalParams = {
                page,
                limit,
                status,
                sort,
                ...params,
            }
            dispatch(fetchGiftCards(paginationKey, finalParams))
        },
    })
}

const GiftCards = withReduxState(mapStateToProps, mapDispatchToProps)

export default GiftCards

import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'

const ID = 'CreateBookingModal'

/**
 * @component
 * @param {Object} props
 * @param {React.Element|React.Element[]|null} props.children
 * @return {JSX.Element}
 */
export function CreateBookingModalState(props) {
    return <WithActiveModal modalId={ID}>{props.children}</WithActiveModal>
}

import React from 'react'
import * as theme from 'styles/theme'
import { TimePicker, Input } from 'antd'
import { capitalize, getDisabledMinutes } from 'util/index'
import { WEEK_DAYS, APP_TIME_FORMAT } from 'util/constants'
import FormItem from 'components/util/forms/FormItem'
import dayjs from 'dayjs'
import './BookableHoursInput.css'

const labelCol = { span: 6 }
const wrapperCol = { span: 18 }

function BookableHoursInput({
    values,
    disabled,
    onChangeAsValue,
}) {
    return (
        <div className="bookableHoursFormItem">
            {WEEK_DAYS.map((day) => (
                <FormItem
                    style={{ marginBottom: 0 }}
                    key={day.id}
                    label={capitalize(day.name)}
                    labelCol={labelCol}
                    wrapperCol={wrapperCol}
                >
                    <TimePicker
                        style={{ width: '40%' }}
                        value={dayjs.utc(values[`${day.name}Start`], APP_TIME_FORMAT)}
                        disabled={disabled}
                        disabledTime={() => ({
                            disabledMinutes: () => getDisabledMinutes(values.startsAt, values.slotDuration),
                        })}
                        hideDisabledOptions
                        allowClear={false}
                        format={APP_TIME_FORMAT}
                        onSelect={(start) => onChangeAsValue(`${day.name}Start`, dayjs.utc(start).format(APP_TIME_FORMAT))}
                    />
                    <Input
                        style={{
                            width: '20%',
                            textAlign: 'center',
                            border: 0,
                            pointerEvents: 'none',
                            backgroundColor: theme.colorWhite,
                        }}
                        placeholder="~"
                        disabled
                    />
                    <TimePicker
                        style={{ width: '40%' }}
                        value={dayjs.utc(values[`${day.name}End`], APP_TIME_FORMAT)}
                        disabled={disabled}
                        allowClear={false}
                        format={APP_TIME_FORMAT}
                        onSelect={(end) => onChangeAsValue(`${day.name}End`, dayjs.utc(end).format(APP_TIME_FORMAT))}
                    />
                </FormItem>
            ))}
        </div>
    )
}

export default BookableHoursInput

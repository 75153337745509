import React from 'react'
import WithMe from './WithMe'
import WithActiveFilter from '../../layout/filters/WithActiveFilters'

function HasPermissions({
    roles,
    renderNotAuthorized,
    children,
}) {
    return (
        <WithActiveFilter>
            {({ activeVenueId }) => (
                <WithMe>
                    {({ role, venues }) => {
                        const hasAccessToVenue = venues.find((venue) => venue.id === activeVenueId)
                        const hasCorrectRole = roles.includes(role.slug)
                        const hasPermission = hasAccessToVenue && hasCorrectRole
                        const hasChildAsAFunction = typeof children === 'function'

                        if (hasChildAsAFunction) {
                            return children({ hasPermission })
                        }

                        if (hasAccessToVenue) {
                            if (hasCorrectRole) {
                                return children
                            }
                            if (typeof renderNotAuthorized === 'function') {
                                return renderNotAuthorized()
                            }
                        }
                        return null
                    }}
                </WithMe>
            )}
        </WithActiveFilter>
    )
}
export default HasPermissions

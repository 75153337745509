import { deleteDiscountBanner } from 'store/actions/discountBanners'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch) => ({
    deleteDiscountBanner: (discountBanner) => dispatch(deleteDiscountBanner(discountBanner.id)),
    deleteDiscountBannerSuccess: () => {
        notification.success({
            message: 'Discount banner deleted!',
            description: 'Discount banner is succesfully deleted.',
        })
    },
    deleteDiscountBannerFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
})

const DeleteDiscountBannerRequest = withReduxState(null, mapDispatchToProps)

export default DeleteDiscountBannerRequest

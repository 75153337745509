import React from 'react'
import { Modal } from 'antd'
import { ModalFooter } from './ModalFooter'

export function DeleteModal({
    closable,
    maskClosable,
    title,
    isOpen,
    onSubmit,
    onCancel,
    entityName,
    isLoading,
}) {
    return (
        <Modal
            title={title}
            open={isOpen}
            onOk={onSubmit}
            onCancel={onCancel}
            closable={closable}
            maskClosable={maskClosable}
            footer={(
                <ModalFooter
                    isLoading={isLoading}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    submitText="Delete"
                    submitIsDanger={true}
                    cancelText="Cancel"
                />
            )}
        >
            <p>
                Are you sure you want to delete
                {' '}
                <span style={{ fontWeight: 'bold' }}>{entityName}</span>
                ?
            </p>
        </Modal>
    )
}

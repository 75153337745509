import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import DeletePriceAdjustmentRequest from './DeletePriceAdjustmentRequest'

function DeletePriceAdjustmentFormState({
    onDeleteSuccess,
    onCancel,
    values,
    children,
}) {
    return (
        <DeletePriceAdjustmentRequest paginationKey="venueGamesOverview">
            {({
                deletePriceAdjustment,
                fetchVenueGames,
                deletePriceAdjustmentSuccess,
                deletePriceAdjustmentFailed,
            }) => (
                <WithFormState
                    transformValuesToRequestBody={() => values}
                    onSendRequest={() => deletePriceAdjustment(values)}
                    onCancel={onCancel}
                    onRequestSuccess={() => {
                        fetchVenueGames()
                        deletePriceAdjustmentSuccess()
                        onDeleteSuccess()
                    }}
                    onRequestFailed={deletePriceAdjustmentFailed}
                >
                    {children}
                </WithFormState>
            )}
        </DeletePriceAdjustmentRequest>
    )
}

export default DeletePriceAdjustmentFormState

import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import { MODAL_IDS } from 'util/constants'

function CreateCouponTimeFrameModalState({
    coupon,
    children,
}) {
    return (
        <WithActiveModal
            modalId={MODAL_IDS.CREATE_COUPON_TIME_FRAME}
            entity={coupon}
            entityKey="coupon"
        >
            {children}
        </WithActiveModal>
    )
}

export default CreateCouponTimeFrameModalState

import React from 'react'
import { Form, Input } from 'antd'
import FormItem from 'components/util/forms/FormItem'

function CreateEquipmentSetForm({
    values,
    errors,
    onChange,
}) {
    return (
        <Form>
            <FormItem
                label="Name"
                error={errors.name}
            >
                <Input
                    name="name"
                    value={values.name}
                    onChange={onChange}
                />
            </FormItem>
            <FormItem
                label="Unique identifier"
                error={errors.uid}
            >
                <Input
                    name="uid"
                    value={values.uid}
                    onChange={onChange}
                />
            </FormItem>
        </Form>
    )
}

export default CreateEquipmentSetForm

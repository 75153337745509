import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import CreateGameSessionRequest from 'components/pages/gameSessions/requests/CreateGameSessionRequest'
import { getEntityFromResponse } from 'util/index'
import toGameSessionApiRequest from 'util/transformers/gameSessions'
import { isValidGameSession } from 'util/validation/gameSession'
import { getGameSessionErrors } from 'util/validation/gameSessionErrors'
import { useNavigate } from 'react-router'

function CreateGameSessionFormState({
    onCancel,
    onCreateSuccess = () => {},
    children,
}) {
    const navigate = useNavigate()

    return (
        <CreateGameSessionRequest paginationKey="overview">
            {({
                createGameSession,
                createGameSessionSuccess,
                createGameSessionFailed,
            }) => (
                <WithFormState
                    initialValues={{
                        date: null,
                        start: null,
                    }}
                    isValidForm={isValidGameSession}
                    getFormErrors={getGameSessionErrors}
                    onCancel={onCancel}
                    transformValuesToRequestBody={toGameSessionApiRequest}
                    onSendRequest={(values) => createGameSession(values)}
                    onRequestSuccess={(response) => {
                        const gameSession = getEntityFromResponse(response, 'gameSessions')
                        navigate(`/game-sessions/${gameSession.id}`)
                        createGameSessionSuccess()
                        onCreateSuccess()
                    }}
                    onRequestFailed={createGameSessionFailed}
                >
                    {children}
                </WithFormState>
            )}
        </CreateGameSessionRequest>
    )
}

export default CreateGameSessionFormState

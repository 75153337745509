import React from 'react'
import { Modal } from 'antd'
import { ModalFooter } from 'components/util/modals/ModalFooter'
import {LegalEntityForm} from "../../LegalEntityForm"

/**
 * @component
 * @param {Object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.onClose
 * @param {() => void} props.handleSubmit
 * @param {boolean} props.isLoading
 * @param {boolean} props.isCreateForm
 * @param {boolean} props.isUploadingImage
 * @param {(isUploadIng:boolean) => void} props.setIsUploadingImage
 * @param {string[]} props.currencies
 * @param {CountryResource[]} props.countries
 * @param {UseFormFormState & UseDynamicLanguageFormStateForm} props.formState
 * @param {(locale: string) => boolean} props.hasLocaleError
 */
export function CreateLegalEntityModal(props) {
    const isUploading = props.isLoading || props.isUploadingImage

    return (
        <Modal
            title="Create new legal entity"
            width={600}
            open={props.isOpen}
            onCancel={props.onClose}
            onOk={props.handleSubmit}
            closable={!isUploading}
            maskClosable={!isUploading}
            footer={(
                <ModalFooter
                    isLoading={isUploading}
                    onCancel={props.onClose}
                    onSubmit={props.handleSubmit}
                    submitText="Create"
                />
            )}
        >
            <LegalEntityForm
                isCreateForm={props.isCreateForm}
                setIsUploadingImage={props.setIsUploadingImage}
                currencies={props.currencies}
                countries={props.countries}
                formState={props.formState}
                hasLocaleError={props.hasLocaleError}
            />
        </Modal>
    )
}

import React, { useMemo, useCallback } from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Table, Button } from 'antd'
import Clipboard from 'components/util/Clipboard'
import dayjs, {isDayjs} from 'dayjs'
import { APP_DATE_FORMAT, ROLES } from 'util/constants'
import { formatCurrency } from '../../../../../util/currency'
import { VoucherDetails } from '../details/VoucherDetails'

const allowedEditRoleSlugs = [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.FRANCHISE]
const allowedDeleteRoleSlugs = [ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.FRANCHISE]

/**
 * @param {Object} props
 * @param {Object[]} props.vouchers
 * @param {boolean} props.isFetching
 * @param {string} props.currentUserRoleSlug
 * @param {(value: string | null) => void} props.onSort
 * @param {(coupon: Object) => void} props.openEditModal
 * @param {(coupon: Object) => void} props.openDeleteModal
 * @param {Object[]} props.voucherIssueReasons
 * @param {Object[]} props.legalEntities
 * @param {Object[]} props.venues
 * @returns {React.JSX.Element}
 * @constructor
 */
export function VouchersTable(props) {
    const columns = useMemo(() => {
        return [
            {
                title: 'Code',
                dataIndex: ['coupon', 'code'],
                render: (code) => (
                    <Clipboard text={code} tooltip="Copy to clipboard">
                        <strong>{code}</strong>
                    </Clipboard>
                ),
            },
            {
                title: 'Amount',
                dataIndex: ['coupon', 'amount'],
                align: 'center',
                render: (amount, voucher) => {
                    // READ: Vouchers should never be percentages, but include it anyway, just in case
                    const isPercentage = voucher.coupon.isPercentage
                    if (isPercentage) {
                        return `${(amount * 100).toFixed(2)}%`
                    } else {
                        const currencyCode = voucher.coupon.currencyCode
                        return formatCurrency(amount, currencyCode)
                    }
                },
            },
            {
                title: 'Issued by',
                dataIndex: 'creator',
                align: 'center',
                render: (creator) => {
                    if (typeof creator === 'undefined' || creator === null)
                        return '-'
                    else
                        return creator.firstName
                },
            },
            {
                title: 'Issued on',
                dataIndex: 'createdAt',
                align: 'center',
                width: 110,
                render: (createdAt) => {
                    return dayjs.utc(createdAt).format(APP_DATE_FORMAT)
                },
            },
            {
                title: 'Expires on',
                dataIndex: ['coupon', 'expiresAt'],
                align: 'center',
                width: 110,
                render: (expiresAt) => {
                    if (typeof expiresAt !== 'string' || expiresAt.length === 0) {
                        return '-'
                    } else {
                        const expiryDate = dayjs.utc(expiresAt)
                        return expiryDate.format(APP_DATE_FORMAT)
                    }
                },
            },
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                width: 80,
                render: (val, voucher) => {
                    let canEdit = allowedEditRoleSlugs.indexOf(props.currentUserRoleSlug) !== -1
                    let canDelete = allowedDeleteRoleSlugs.indexOf(props.currentUserRoleSlug) !== -1

                    // TODO: also check if user has access to issued venue
                    const now = dayjs.utc()
                    let editButtonTitle = undefined
                    let deleteButtonTitle = undefined

                    const expiresAt = voucher.coupon.expiresAt
                    if (typeof expiresAt === 'string') {
                        const expiryDate = dayjs.utc(expiresAt)
                        const hasExpired = now.isAfter(expiryDate)
                        if (hasExpired) {
                            editButtonTitle = "Can't edit expired voucher"
                            deleteButtonTitle = "Can't delete expired voucher"
                            canDelete = false
                            canEdit = false
                        }
                    }
                    const usedAt = voucher.coupon.usedAt
                    if (typeof usedAt === 'string' && usedAt.length > 0) {
                        const usedAtDate = dayjs.utc(usedAt)
                        if (isDayjs(usedAtDate) && usedAtDate.isValid()) {
                            if (editButtonTitle === undefined) {
                                editButtonTitle = "Can't edit used voucher"
                            }
                            if (deleteButtonTitle === undefined) {
                                deleteButtonTitle = "Can't delete used voucher"
                            }
                            canDelete = false
                            canEdit = false
                        }
                    }

                    return (
                        <>
                            <Button
                                ghost
                                title={editButtonTitle}
                                icon={<EditOutlined />}
                                type="primary"
                                shape="circle"
                                size="small"
                                className="ant-btn-no-border"
                                disabled={!canEdit}
                                onClick={() => props.openEditModal(voucher)}
                            />

                            <Button
                                ghost
                                title={deleteButtonTitle}
                                icon={<DeleteOutlined />}
                                danger={true}
                                shape="circle"
                                size="small"
                                className="ant-btn-no-border"
                                disabled={!canDelete}
                                onClick={() => props.openDeleteModal(voucher)}
                            />
                        </>
                    )
                },
            },
        ]
    }, [props.openEditModal, props.openEditModal, props.currentUserRoleSlug])

    const expandedRowRender = useCallback((voucher) => {
        return (
            <VoucherDetails
                voucher={voucher}
                voucherIssueReasons={props.voucherIssueReasons}
                legalEntities={props.legalEntities}
                venues={props.venues}
            />
        )
    }, [props.legalEntities, props.venues, props.voucherIssueReasons])

    return (
        <Table
            rowKey="id"
            rowClassName="cursor-pointer"
            dataSource={props.vouchers}
            columns={columns}
            loading={props.isFetching}
            pagination={false}
            expandable={{
                expandedRowRender: expandedRowRender,
                expandRowByClick: true,
                expandIcon: expandIcon,
                showExpandColumn: false,
            }}
        />
    )
}

function expandIcon() {
    return null
}

/* eslint-disable max-len,react/jsx-no-useless-fragment */
import React, {useMemo} from "react"
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    DiffOutlined,
    DownloadOutlined,
    EditOutlined,
} from "@ant-design/icons"
import { Button, Tooltip } from "antd"
import * as theme from "styles/theme"
import {
    ROLES, MODAL_IDS, EXTRA_INFORMATOIN_KEYS, APP_TIME_FORMAT, APP_DATE_FORMAT, LANGUAGES, LANGUAGES_READABLE,
} from "util/constants"
import { WithActiveModal }from "components/util/modals/WithActiveModal"
import HasPermissions from "components/pages/authorized/HasPermissions"
import useDownload from "components/hooks/useDownload"
import { getExtraInformationOfType } from "util/transformers/booking"
import sanitizeHtml from "sanitize-html"
import dayjs from "dayjs"
import "./BookingDetails.css"
import {EditBookingModalState} from "../modals/edit/EditBookingModalState"
import {BookingDetailTitle} from "./BookingDetailTitle"
import {isValidString} from "../../../../util/string-utils"
import {BookingDetailItem} from "./BookingDetailItem"
import {roundNumber} from "../../../../util/math-utils"
import {formatCurrency} from "../../../../util/currency"
import {useAppState} from "../../../hooks/useAppState"

/**
 * @component
 * @param {Object} props
 * @param {BookingResource} props.booking
 * @returns {JSX.Element}
 */
export function BookingDetails(props) {
    const appState = useAppState()
    const countries = appState.countries
    const displayChangeButton = props.booking.createdAt !== props.booking.updatedAt
    const introductionStart = dayjs.utc(props.booking.start)
    let vrExperienceStart = dayjs.utc(props.booking.start)
    const vrExperienceEnd = dayjs.utc(props.booking.end)
    const mCreatedAt = dayjs.utc(props.booking.createdAt)
    const durationInMinutes = vrExperienceEnd.diff(vrExperienceStart, "minutes", true)
    const isLessThanAnHour = durationInMinutes < 60
    const duration = isLessThanAnHour ? durationInMinutes : Math.round(durationInMinutes / 0.6) / 100

    const introduction = props.booking.introduction
    if (typeof introduction !== "undefined" && introduction !== null) {
        vrExperienceStart = vrExperienceStart.add(introduction.duration, "minutes")
    }


    // Private (or offline) upselling options
    const cateringInfo = getExtraInformationOfType(props.booking.extraInformation, EXTRA_INFORMATOIN_KEYS.catering)
    const barForFaitInfo = getExtraInformationOfType(props.booking.extraInformation, EXTRA_INFORMATOIN_KEYS.barForFait)
    const meetingRoomInfo = getExtraInformationOfType(props.booking.extraInformation, EXTRA_INFORMATOIN_KEYS.meetingRoom)

    // Public (or online) upselling options
    const oneHourOpenBarInfo = getExtraInformationOfType(props.booking.extraInformation, EXTRA_INFORMATOIN_KEYS.food)
    const twoDrinksInfo = getExtraInformationOfType(props.booking.extraInformation, EXTRA_INFORMATOIN_KEYS.drinks)
    const drinkTokensInfo = getExtraInformationOfType(props.booking.extraInformation, EXTRA_INFORMATOIN_KEYS.drinkTokens)
    const nachosInfo = getExtraInformationOfType(props.booking.extraInformation, EXTRA_INFORMATOIN_KEYS.nachos)

    const hasInvoice = typeof props.booking.invoice !== "undefined" && props.booking.invoice !== null
    let invoiceAPIEndpoint = ""
    if (props.booking.isCompanyBooking && hasInvoice)
        invoiceAPIEndpoint = `/files/invoices/${props.booking.invoice.id}/download`

    const { startDownload: handleDownloadInvoice, isDownloading } = useDownload({
        endpoint: invoiceAPIEndpoint,
        saveAs: `${props.booking.orderNumber}.pdf`,
    })

    // Pricing
    let vrExperienceTotalWithoutVat = props.booking.totalWithoutVat
    let vrExperienceTotalVat = props.booking.vat
    let vrExperienceTotalWithVat = props.booking.totalWithVat
    let totalUpsellingWithVat = 0
    if (Array.isArray(props.booking.extraInformation)) {
        for (let i = 0, length = props.booking.extraInformation.length; i < length; i++) {
            const extraInfoItem = props.booking.extraInformation[i]
            if (typeof extraInfoItem.totalWithVat === "number") {
                vrExperienceTotalWithVat = roundNumber(vrExperienceTotalWithVat - extraInfoItem.totalWithVat, 2)
                vrExperienceTotalVat = roundNumber(vrExperienceTotalVat - extraInfoItem.vat, 2)
                vrExperienceTotalWithoutVat = roundNumber(vrExperienceTotalWithoutVat - extraInfoItem.totalWithoutVat, 2)
                totalUpsellingWithVat = roundNumber(totalUpsellingWithVat + extraInfoItem.totalWithVat, 2)
            }
        }
    }

    let bookingTotalWithVat = props.booking.totalWithVat
    let reductionAmountText = "/"
    if (typeof props.booking.reductionAmount === "number") {
        const reductionAmount = props.booking.reductionAmount
        if (reductionAmount > 0.0) {
            reductionAmountText = "- " + formatCurrency(reductionAmount, props.booking.currencyCode)
        } else if (reductionAmount < 0.0) {
            reductionAmountText = "+ " + formatCurrency(reductionAmount * -1.0, props.booking.currencyCode)
        }

        bookingTotalWithVat = props.booking.totalWithVatAfterReduction
    }

    const orderPriceUpdates = props.booking.orderPriceUpdates
    /** @type {number|null} bookingTotalWithVatAfterPriceUpdates */
    let bookingTotalWithVatAfterPriceUpdates = null
    if (Array.isArray(orderPriceUpdates) && orderPriceUpdates.length > 0) {
        bookingTotalWithVatAfterPriceUpdates = bookingTotalWithVat
        for (let i = 0, length = orderPriceUpdates.length; i < length; i++) {
            const orderPriceUpdate = orderPriceUpdates[i]
            bookingTotalWithVatAfterPriceUpdates = roundNumber(bookingTotalWithVatAfterPriceUpdates + orderPriceUpdate.difference, 2)
        }

        if (bookingTotalWithVatAfterPriceUpdates < 0.0)
            bookingTotalWithVatAfterPriceUpdates = 0.0
    }

    const bookerHasAccount = typeof props.booking.bookerCustomerProfile !== "undefined" &&
        props.booking.bookerCustomerProfile !== null &&
        typeof props.booking.bookerCustomerProfile.customerProfileAccountId === "number"


    const companyCountryName = useMemo(() => {
        if (!props.booking.isCompanyBooking)
            return undefined
        const companyDetails = props.booking.companyDetails
        if (typeof companyDetails === "undefined" || companyDetails === null || !isValidString(companyDetails.country))
            return undefined

        const countryCode = companyDetails.country.toUpperCase()
        for (let i = 0, length = countries.length; i < length; i++) {
            const country = countries[i]
            if (country.code === countryCode)
                return country.name
        }

        return undefined
    }, [countries, props.booking.isCompanyBooking, props.booking.companyDetails])


    const currencyCode = props.booking.currencyCode

    return (
        <div>
            <div className="flex relative">
                <div className="buttonGroup">
                    <HasPermissions roles={[ROLES.FRANCHISE, ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.VENUE_MANAGER, ROLES.HOST]}>
                        <EditBookingModalState booking={props.booking}>
                            {(childProps) => (
                                <Button
                                    className="margin-bottom"
                                    icon={<EditOutlined />}
                                    type="primary"
                                    onClick={childProps.openModal}
                                >
                                    Edit
                                </Button>
                            )}
                        </EditBookingModalState>
                        <WithActiveModal
                            modalId={MODAL_IDS.DELETE_BOOKING}
                            entity={props.booking}
                        >
                            {(childProps) => (
                                <Button
                                    className="margin-bottom"
                                    icon={<DeleteOutlined />}
                                    type="primary"
                                    danger={true}
                                    onClick={childProps.openModal}
                                    disabled={vrExperienceStart.isBefore()}
                                >
                                    Delete
                                </Button>
                            )}
                        </WithActiveModal>

                        {
                            displayChangeButton && (
                                <WithActiveModal
                                    modalId={MODAL_IDS.AUDIT_BOOKING}
                                    entity={props.booking}
                                >
                                    {({ openModal }) => (
                                        <Button
                                            className="margin-bottom"
                                            icon={<DiffOutlined />}
                                            onClick={openModal}
                                        >
                                            Changes
                                        </Button>
                                    )}
                                </WithActiveModal>
                            )
                        }
                    </HasPermissions>
                </div>
            </div>

            <div className="flex">
                <div className="booking-details-column">
                    <BookingDetailTitle
                        emoji="🗓"
                        text="Booking"
                    />
                    <ul>
                        <BookingDetailItem label="Order number:">
                            {props.booking.orderNumber}
                        </BookingDetailItem>
                        <BookingDetailItem label="Booked by:">
                            {
                                typeof props.booking.creator !== "undefined" && props.booking.creator !== null ? (
                                    `${props.booking.creator.firstName} ${props.booking.creator.lastName}`
                                ) : (
                                    "Tickets website"
                                )
                            }
                        </BookingDetailItem>
                        <BookingDetailItem label="Booked on:">
                            {mCreatedAt.format(APP_DATE_FORMAT)}
                        </BookingDetailItem>
                        <BookingDetailItem label="Booked internally:">
                            {props.booking.isInternal ? "Yes" : "No"}
                        </BookingDetailItem>
                        {
                            props.booking.bookingDealType != null && (
                                <BookingDetailItem label="Deal Type:">
                                    {props.booking.bookingDealType.name}
                                </BookingDetailItem>
                            )
                        }
                        <BookingDetailItem label="Private:">
                            {props.booking.isPrivate ? "Yes" : "No"}
                        </BookingDetailItem>
                        <BookingDetailItem label="Players:">
                            <strong>{props.booking.playerAmount}</strong>
                        </BookingDetailItem>
                        <BookingDetailItem label="VR experience:">
                            <strong>{props.booking.gameTitle}</strong>
                        </BookingDetailItem>
                        <BookingDetailItem label="Room:">
                            <strong>{props.booking.room.name}</strong>
                        </BookingDetailItem>
                        <BookingDetailItem label="VR Experience language:">
                            {LANGUAGES_READABLE[props.booking.gameLanguageCode]}
                        </BookingDetailItem>
                        {
                            props.booking.introduction && (
                                <BookingDetailItem label="Introduction starts at:">
                                    {introductionStart.format(APP_TIME_FORMAT)}
                                </BookingDetailItem>
                            )
                        }
                        <BookingDetailItem label="VR experience starts at:">
                            {vrExperienceStart.format(APP_TIME_FORMAT)}
                        </BookingDetailItem>
                        <BookingDetailItem label="VR experience ends at:">
                            {vrExperienceEnd.format(APP_TIME_FORMAT)}
                        </BookingDetailItem>
                        <BookingDetailItem label="Total duration:">
                            <strong>
                                {duration}
                                {isLessThanAnHour ? "m" : "h"}
                            </strong>
                        </BookingDetailItem>
                    </ul>
                </div>

                <div className="booking-details-column">
                    <BookingDetailTitle
                        emoji="💰"
                        text="Payment"
                    />
                    <ul>
                        <BookingDetailItem label="VR Experience excl VAT:">
                            {formatCurrency(vrExperienceTotalWithoutVat, currencyCode)}
                        </BookingDetailItem>
                        <BookingDetailItem label="VR Experience VAT:">
                            {formatCurrency(vrExperienceTotalVat, currencyCode)}
                        </BookingDetailItem>
                        <BookingDetailItem label="VR Experience incl VAT:">
                            {formatCurrency(vrExperienceTotalWithVat, currencyCode)}
                        </BookingDetailItem>
                        <BookingDetailItem label="Discount coupon code:">
                            {props.booking.couponCode ? props.booking.couponCode : "/"}
                        </BookingDetailItem>
                        <BookingDetailItem label="Reduction:">
                            {reductionAmountText}
                        </BookingDetailItem>
                        {
                            totalUpsellingWithVat > 0.0 && (
                                <BookingDetailItem label="Total upgrades incl VAT:">
                                    {formatCurrency(totalUpsellingWithVat, currencyCode)}
                                </BookingDetailItem>
                            )
                        }
                        <BookingDetailItem label="Booking Total:">
                            <strong>
                                {formatCurrency(bookingTotalWithVat, currencyCode)}
                            </strong>
                        </BookingDetailItem>
                        {
                            typeof bookingTotalWithVatAfterPriceUpdates === "number" && (
                                <BookingDetailItem label="Total after updates:">
                                    <strong>
                                        {formatCurrency(bookingTotalWithVatAfterPriceUpdates, currencyCode)}
                                    </strong>
                                </BookingDetailItem>
                            )
                        }
                        <BookingDetailItem label="Outstanding amount:">
                            <strong>
                                {formatCurrency(props.booking.outstandingAmount || 0.0, currencyCode)}
                            </strong>
                        </BookingDetailItem>
                        <BookingDetailItem label="Payment method:">
                            {props.booking.paymentMethod}
                        </BookingDetailItem>
                    </ul>

                    {
                        Array.isArray(orderPriceUpdates) && orderPriceUpdates.length > 0 && (
                            <>
                                <BookingDetailTitle
                                    emoji="📈"
                                    text="Price updates"
                                />
                                <ul>
                                    {
                                        orderPriceUpdates.map((/** OrderPriceUpdateResource */ opu) => {
                                            let label = dayjs.utc(opu.updatedAt).format(APP_DATE_FORMAT) + " " + dayjs.utc(opu.updatedAt).format(APP_TIME_FORMAT)
                                            if (typeof opu.updatedBy !== "undefined" && opu.updatedBy !== null) {
                                                label += ` by ${opu.updatedBy.firstName} ${opu.updatedBy.lastName}`
                                            } else {
                                                label += " by customer"
                                            }
                                            let formattedDifference
                                            if (opu.difference > 0.0) {
                                                formattedDifference = "+ " + formatCurrency(opu.difference, opu.currencyCode)
                                            } else if (opu.difference < 0.0) {
                                                formattedDifference = "- " + formatCurrency(roundNumber(opu.difference * -1.0, 2), opu.currencyCode)
                                            } else {
                                                formattedDifference = formatCurrency(opu.difference, opu.currencyCode)
                                            }

                                            return (
                                                <BookingDetailItem
                                                    key={opu.updatedAt}
                                                    className="priceUpdate"
                                                    label={label}
                                                >
                                                    <strong>{formattedDifference}</strong>
                                                </BookingDetailItem>
                                            )
                                        })
                                    }
                                </ul>
                            </>
                        )
                    }
                </div>
            </div>

            <div className="flex">
                <div className="booking-details-column">
                    <BookingDetailTitle
                        emoji="👥"
                        text="Booker details"
                    />
                    <ul>
                        <BookingDetailItem label="First name:">
                            {props.booking.bookerFirstName}
                        </BookingDetailItem>
                        <BookingDetailItem label="Last name:">
                            {props.booking.bookerLastName}
                        </BookingDetailItem>
                        <BookingDetailItem label="Email:">
                            {props.booking.bookerEmail}
                        </BookingDetailItem>
                        <BookingDetailItem label="Phone number:">
                            {isValidString(props.booking.bookerPhoneNumber) ? props.booking.bookerPhoneNumber : "/"}
                        </BookingDetailItem>
                        <BookingDetailItem label="Preferred language:">
                            {isValidString(props.booking.bookerCustomerProfile?.languageCode) ? LANGUAGES_READABLE[props.booking.bookerCustomerProfile.languageCode] : "/"}
                        </BookingDetailItem>
                        <BookingDetailItem label="Has park account:">
                            {bookerHasAccount ? "Yes" : "No"}
                        </BookingDetailItem>
                    </ul>
                </div>

                <div className="booking-details-column">
                    <BookingDetailTitle
                        emoji="📚"
                        text="Exact"
                    />
                    <ul>
                        {
                            isValidString(props.booking.exactSyncedAt) ? (
                                <>
                                    <BookingDetailItem label="Status:">
                                        <Tooltip title="This booking is successfully synced with Exact">
                                            <strong>Success</strong>
                                            {" "}
                                            <CheckCircleOutlined style={{ color: theme.colorSecondary }} className="cursor-pointer" />
                                        </Tooltip>
                                    </BookingDetailItem>
                                    <BookingDetailItem label="Synced at:">
                                        {dayjs.utc(props.booking.exactSyncedAt).format(`${APP_DATE_FORMAT} - ${APP_TIME_FORMAT}`)}
                                    </BookingDetailItem>
                                </>
                            ) : (
                                <>
                                    <BookingDetailItem label="Status:">
                                        <Tooltip title="This booking is not synced with Exact">
                                            <strong>
                                                {isValidString(props.booking.exactFailedAt) ? "Failed" : "Not synced yet"}
                                            </strong>
                                            {" "}
                                            <CloseCircleOutlined style={{ color: theme.colorAlert }} className="cursor-pointer" />
                                        </Tooltip>
                                    </BookingDetailItem>
                                    <BookingDetailItem label="Extra info:">
                                        {props.booking.exactFailedMessage || "/"}
                                    </BookingDetailItem>
                                </>
                            )
                        }
                    </ul>
                </div>
            </div>

            <div className="flex">
                <div className="booking-details-column">
                    {
                        Array.isArray(props.booking.customerProfiles) && props.booking.customerProfiles.length > 0 && (
                            <>
                                <BookingDetailTitle
                                    emoji="🎮"
                                    text="Registered Players"
                                />
                                <ul className="playerList">
                                    {
                                        props.booking.customerProfiles.map((profile) => (
                                            <li key={profile.id}>
                                                <strong>{profile.firstName}</strong> ({profile.email})
                                            </li>
                                        ))
                                    }
                                </ul>
                            </>
                        )
                    }
                </div>
                <div className="booking-details-column" />
            </div>

            <div className="flex">
                <div className="booking-details-column">
                    {
                        (props.booking.isCompanyBooking && typeof props.booking.companyDetails !== "undefined" && props.booking.companyDetails !== null) && (
                            <>
                                <BookingDetailTitle
                                    emoji="🏫"
                                    text="Company details"
                                />
                                <ul>
                                    <BookingDetailItem label="Name:">
                                        <strong>{props.booking.companyName}</strong>
                                    </BookingDetailItem>
                                    <BookingDetailItem label="Country:">
                                        {isValidString(companyCountryName) ? companyCountryName : "/"}
                                    </BookingDetailItem>
                                    <BookingDetailItem label="City:">
                                        {props.booking.companyDetails.zipCode}
                                        {" "}
                                        {props.booking.companyDetails.city}
                                    </BookingDetailItem>
                                    <BookingDetailItem label="Address:">
                                        {props.booking.companyDetails.address}
                                    </BookingDetailItem>
                                    <BookingDetailItem label="VAT number:">
                                        {isValidString(props.booking.companyDetails.vatNumber) ? props.booking.companyDetails.vatNumber : "/"}
                                    </BookingDetailItem>
                                    {
                                        typeof props.booking.purchaseOrderNumber !== "undefined"
                                        && props.booking.purchaseOrderNumber !== null
                                        && (
                                            <BookingDetailItem label="PO number">
                                                {props.booking.purchaseOrderNumber}
                                            </BookingDetailItem>
                                        )
                                    }
                                    {
                                        hasInvoice &&
                                        (
                                            <BookingDetailItem>
                                                <Button
                                                    icon={<DownloadOutlined />}
                                                    onClick={handleDownloadInvoice}
                                                    loading={isDownloading}
                                                >
                                                    Invoice
                                                </Button>
                                            </BookingDetailItem>
                                        )
                                    }
                                </ul>
                            </>
                        )
                    }
                </div>

                <div className="booking-details-column">
                    {
                        Array.isArray(props.booking.extraInformation) && props.booking.extraInformation.length > 0 && (
                            <>
                                <BookingDetailTitle
                                    emoji="🥪"
                                    text="Upgrades"
                                />
                                {typeof meetingRoomInfo !== "undefined" && (
                                    <BookingDetailItem label="Meeting room:">
                                        <strong>{meetingRoomInfo.info || "Yes"}</strong>
                                    </BookingDetailItem>
                                )}
                                {typeof cateringInfo !== "undefined" && (
                                    <BookingDetailItem label="Catering:">
                                        <strong>{cateringInfo.info || "Yes"}</strong>
                                    </BookingDetailItem>
                                )}
                                {typeof barForFaitInfo !== "undefined" && (
                                    <BookingDetailItem label="Bar for fait:">
                                        <strong>{barForFaitInfo.info || "Yes"}</strong>
                                    </BookingDetailItem>
                                )}
                                {typeof oneHourOpenBarInfo !== "undefined" && (
                                    <BookingDetailItem label="Open bar:">
                                        <strong>{oneHourOpenBarInfo.info || "Yes"}</strong>
                                    </BookingDetailItem>
                                )}
                                {typeof twoDrinksInfo !== "undefined" && (
                                    <BookingDetailItem label="2 Drinks:">
                                        <strong>{twoDrinksInfo.info || "Yes"}</strong>
                                    </BookingDetailItem>
                                )}
                                {typeof drinkTokensInfo !== "undefined" && (
                                    <BookingDetailItem label="Drink tokens:">
                                        <strong>{drinkTokensInfo.info || "Yes"}</strong>
                                    </BookingDetailItem>
                                )}
                                {typeof nachosInfo !== "undefined" && (
                                    <BookingDetailItem label="Nachos:">
                                        <strong>{nachosInfo.info || "Yes"}</strong>
                                    </BookingDetailItem>
                                )}
                            </>
                        )
                    }
                </div>
            </div>

            <div className="flex">
                <div className="booking-details-column">
                    {isValidString(props.booking.comment) && (
                        <>
                            <BookingDetailTitle
                                emoji="❗"
                                text="Comments"
                            />
                            {/* eslint-disable-next-line react/no-danger */}
                            <div style={{ maxHeight: "200px", overflow: "auto" }} dangerouslySetInnerHTML={{ __html: sanitizeHtml(props.booking.comment) }} />
                        </>
                    )}
                </div>
                <div className="booking-details-column" />
            </div>
        </div>
    )
}
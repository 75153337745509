/* eslint-disable react/no-danger,no-use-before-define */
import React, { useEffect, useState } from "react"
import { Modal, Timeline } from "antd"
import { callApiWithOptions } from "store/middleware/callApi"
import sanitizeHtml from "sanitize-html"
import { EXTRA_INFORMATOIN_KEYS, APP_TIME_FORMAT, APP_DATE_FORMAT } from "util/constants"
import dayjs from "dayjs"

const EMPTY = "empty"

/**
 * Determines if a value is a string object or not.
 *
 * This used to identify when to access inner object properties
 * @param {*} property
 * @returns boolean
 */
const isParsableObject = (property) => {
    let result = false
    try {
        const object = JSON.parse(property)
        if (object != null && typeof object === "object") {
            result = true
        }
    } catch (error) {
    /**
     * No need to log error.
     * An exception is thrown when a invalid json string is parsed for
     * properties that were saved string json objects, which is what was intended here.
     */
    }
    return result
}

function isArrayProp(property) {
    let result = false
    try {
        const isArray = Array.isArray(property)
        if (property != null && isArray && typeof property === "object") {
            result = true
        }
    } catch (error) {
    /**
     * No need to log error.
     * An exception is thrown when a array object for
     * properties that should be saved as array objects, which is what was intended here.
     */
    }
    return result
}
function buildLabel(user, event) {
    if (user) {
        return `${user.firstName} ${user.lastName} ${event}`
    }

    return "A user modified"
}

function createType(type) {
    switch (type) {
        case EXTRA_INFORMATOIN_KEYS.meetingRoom:
            return "Meeting room"
        case EXTRA_INFORMATOIN_KEYS.barForFait:
            return "Bar"
        case EXTRA_INFORMATOIN_KEYS.catering:
            return "Catering"
        case EXTRA_INFORMATOIN_KEYS.food:
            return "Food"
        case EXTRA_INFORMATOIN_KEYS.drinks:
            return "Drinks"
        default:
            return type
    }
}

function buildExtraInformationDisplayStatement(looperValue, oldValues, newValues) {
    const properties = []
    const keys = Object.keys(looperValue)
    for (let i = 0, length = keys.length; i < length; i++) {
        const key = keys[i]

        const oldValue = `${checkExtraInformationProp(oldValues[key])}`
        const newValue = `${checkExtraInformationProp(newValues[key])}`
        let type = `${checkProp(oldValues[key].type)}`
        type = createType(type)
        const element = `${type} was changed from ${oldValue} to ${newValue}`
        properties.push(element)
    }
    return properties
}
function buildExtraInformationDisplay(property, item, properties) {
    if (property === "extraInformation") {
        if (item.oldValues[property].length > 0) {
            const values = buildExtraInformationDisplayStatement(item.oldValues[property], item.oldValues[property], item.newValues[property])
            properties.push(...values)
        } else if (item.newValues[property].length > 0) {
            const values = buildExtraInformationDisplayStatement(item.newValues[property], item.newValues[property], item.oldValues[property])
            properties.push(...values)
        }
    }
}
function buildTimelineItems(activity) {
    if (!Array.isArray(activity)) return []

    const timelineItems = []

    for (let i = 0, length = activity.length; i < length; i++) {
        const item = activity[i]
        const updatedProperties = []
        const label = buildLabel(item.user, item.event)
        const dt = dayjs.utc(item.createdAt)

        // eslint-disable-next-line no-restricted-syntax
        const keys = Object.keys(item.oldValues)
        for (let j = 0, keysLength = keys.length; j < keysLength; j++) {
            const key = keys[j]

            if (isArrayProp(item.oldValues[key])) { // the only array json string is extra_information
                buildExtraInformationDisplay(key, item, updatedProperties)
            } else if (isParsableObject(item.oldValues[key])) {
                const oldInnerPropObject = JSON.parse(item.oldValues[key])
                const newInnerPropObject = JSON.parse(item.newValues[key])

                const innerKeys = Object.keys(oldInnerPropObject)
                for (let k = 0, innerKeysLength = innerKeys.length; k < innerKeysLength; k++) {
                    buildPropertyStatement(newInnerPropObject, oldInnerPropObject, innerKeys[k], updatedProperties)
                }
            } else {
                buildPropertyStatement(item.newValues, item.oldValues, key, updatedProperties)
            }
        }

        if (updatedProperties.length !== 0) {
            timelineItems.push(
                <Timeline.Item key={item.id} label={dt.format(`${APP_DATE_FORMAT} ${APP_TIME_FORMAT}`)}>
                    {label} the following item(s): <br />{" "}
                    {updatedProperties.map((timelineItem, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <p key={index} style={{ margin: "0" }}>
                            {timelineItem}
                        </p>
                    ))}
                </Timeline.Item>,
            )
        }
    }

    return timelineItems
}

/**
 * Determine if a value is null or "" and [] return empty string instead.
 * @param {*} property
 * @returns property value or empty text
 */
function checkProp(property) {
    let result = property
    try {
        const parsedProperty = isParsableObject(property) ? JSON.parse(property) : property
        if (property == null || property === "" || (Array.isArray(parsedProperty) && parsedProperty.length === 0)) {
            result = EMPTY
        }
    } catch (error) {
        console.log(error)
    }

    return result
}

function checkExtraInformationProp(property) {
    let result = property
    try {
        if (property) {
            result = checkProp(property.info)
        } else {
            result = checkProp(property)
        }
    } catch (error) {
        result = EMPTY
        console.log(error)
    }
    return result
}

function isActivePropertyFormatter(property) {
    let result = property

    try {
        result = checkProp(property)
        if (property === 1) {
            result = true
        } else if (property === 0) {
            result = false
        }
    } catch (error) {
        console.log(error)
    }
    return result
}

/**
 *
 * Build activity statements to be displayed.
 *
 * Note: There are scenarios where the app updates default value of null to empty strings.
 * Laravel audit sees this as a new value and returns result regardless.
 * Review how best to santize data before hand.
 */
const buildPropertyStatement = (newValueObject, oldValueObject, property, updatedProperties) => {
    if (newValueObject[property] !== oldValueObject[property]) {
        if (!(checkProp(oldValueObject[property]) === EMPTY && checkProp(newValueObject[property]) === EMPTY)) {
            if (property === "comment") {
                const oldComment = (
                    <span
                        style={{ maxHeight: "100px", overflow: "auto" }}
                        dangerouslySetInnerHTML={{ __html: sanitizeHtml(checkProp(oldValueObject[property])) }}
                    />
                )
                const newComment = (
                    <span
                        style={{ maxHeight: "100px", overflow: "auto" }}
                        dangerouslySetInnerHTML={{ __html: sanitizeHtml(checkProp(newValueObject[property])) }}
                    />
                )

                updatedProperties.push(`${property} was changed from`)
                updatedProperties.push(oldComment)
                updatedProperties.push("to")
                updatedProperties.push(newComment)
            } else if (property === "isActive") {
                const oldValue = isActivePropertyFormatter(oldValueObject[property])
                const newValue = isActivePropertyFormatter(newValueObject[property])
                updatedProperties.push(`${property} was changed from ${oldValue} to ${newValue}`)
            } else {
                updatedProperties.push(`${property} was changed from ${checkProp(oldValueObject[property])} to ${checkProp(newValueObject[property])}`)
            }
        }
    }
}

/**
 * @component
 * @param {Object} props
 * @param {boolean} props.isOpen
 * @param {Object|null} props.entity
 * @param {() => void} props.onClose
 * @param {number} props.activeVenueId
 * @return {React.Element}
 */
export function AuditBookingModal(props) {
    const [mode, setMode] = useState("left")
    const [activity, setActivity] = useState([])
    const [error, setError] = useState(false)

    useEffect(() => {
        async function getData() {
            if (props.entity != null) {
                try {
                    const data = await callApiWithOptions(`/venues/${props.activeVenueId}/bookings/${props.entity.id}/audit-logs`)
                    setActivity(data)
                } catch (err) {
                    console.log(err)
                    setError(true)
                }
            }
        }

        getData()
    }, [props.entity])

    const timelineItems = buildTimelineItems(activity)
    return (
        <Modal width={1200} title="Activity Log" footer={null} open={props.isOpen} onCancel={props.onClose}>
            {!error && activity.length === 0 && <p style={{ textAlign: "center" }}>No data available for this booking.</p>}
            {error ? <p style={{ textAlign: "center" }}>Something went wrong, try again later</p> : <Timeline mode={mode}>{timelineItems}</Timeline>}
        </Modal>
    )
}

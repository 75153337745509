import dayjs from 'dayjs'
import { APP_TIME_FORMAT, BACKEND_DATE_TIME_FORMAT } from '../constants'

function extractCurrencyCode(amount) {
    let result = ''
    try {
        if (amount) {
            result = amount.slice(1)
        }
    } catch (e) {
        console.error(e)
    }

    return result
}

export function toCouponApiRequest(values) {
    return {
        prefix: values.prefix,
        is_percentage: values.amountType === 'isPercentage',
        amount: values.amount,
        usage_limit: values.usageLimit,
        code: values.code,
        description: values.description,
        is_multi_use: values.type === 'multiple',
        min_player_amount: values.minPlayerAmount,
        expires_at: values.expiresAt
            ? dayjs.utc(values.expiresAt).endOf('day').format(BACKEND_DATE_TIME_FORMAT)
            : null,
        last_possible_play_date: values.lastPossiblePlayDate
            ? dayjs.utc(values.lastPossiblePlayDate).endOf('day').format(BACKEND_DATE_TIME_FORMAT)
            : null,
        batch_amount: values.batchAmount,
        legal_entity_id: values.legalEntity,
        venue_id: values.venue,
        // issued_in_venue_id: values.issuedInVenueId,
        // reason_code: values.reason,
        // other_reason: values.otherReason,
        is_allowed_for_giftcards: values.isAllowedForGiftcards,
    }
}

export function mapCouponTimeFrameToValues(couponTimeFrame) {
    return {
        from: dayjs.utc(couponTimeFrame.from, APP_TIME_FORMAT),
        until: dayjs.utc(couponTimeFrame.until, APP_TIME_FORMAT),
        day: couponTimeFrame.day,
        isWholeDay: couponTimeFrame.wholeDay,
    }
}

export function mapCouponToValues(values) {
    return {
        id: values.id,
        prefix: values.prefix,
        isPercentage: values.isPercentage,
        amountType: values.isPercentage ? 'isPercentage' : 'isAmount',
        amount: values.isPercentage ? values.amount * 100 : extractCurrencyCode(values.amount),
        usageLimit: values.usageLimit,
        code: values.code,
        description: values.description,
        isMultiUse: values.isMultiUse,
        type: values.isMultiUse ? 'multiple' : 'single',
        minPlayerAmount: values.minPlayerAmount,
        expiresAt: values.expiresAt ? dayjs.utc(values.expiresAt) : null,
        lastPossiblePlayDate: values.lastPossiblePlayDate ? dayjs.utc(values.lastPossiblePlayDate) : null,
        legalEntity: values.legalEntityId,
        venue: typeof values.venueId === 'number' ? values.venueId : 'all_venues',
        isAllowedForGiftcards: values.isAllowedForGiftcards,
    }
}

export function toCouponTimeFrameApiRequest(values) {
    const {
        from,
        until,
        day,
        isWholeDay,
    } = values
    if (isWholeDay) {
        return {
            isWholeDay,
            day,
        }
    }
    return {
        ...values,
        from: dayjs.utc(from).format(APP_TIME_FORMAT),
        until: dayjs.utc(until).format(APP_TIME_FORMAT),
    }
}

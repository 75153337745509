export function toVenueApiRequest(values) {
    const body = {
        nameTranslations: {
            en: values.nameEn,
            nl: values.nameNl,
            fr: values.nameFr,
            pt: values.namePt,
        },
        addressTranslations: {
            en: values.addressEn,
            nl: values.addressNl,
            fr: values.addressFr,
            pt: values.addressPt,
        },
        legalEntityId: values.legalEntity,
        duration: values.duration,
        minPlayers: values.minPlayers,
        costCenterCode: values.costCenterCode,
        timezone: values.timezone,
        public: values.public,
        ignoreTimeScheduling: values.ignoreTimeScheduling,
        sendPromotionalContent: values.sendPromotionalContent,
        autoOnlineVoucherCreation: values.autoOnlineVoucherCreation,
        code: values.code,
    }

    if (typeof values.addAllPublicGames === 'boolean') {
        body.addAllPublicGames = values.addAllPublicGames
    }
    if (typeof values.addAllNonPublicGames === 'boolean') {
        body.addAllNonPublicGames = values.addAllNonPublicGames
    }

    if (typeof values.openingDate !== 'undefined') {
        body.openingDate = values.openingDate
    }
    if (typeof values.closingDate !== 'undefined') {
        body.closingDate = values.closingDate
    }

    return body
}

export function mapVenueToValues(venue) {
    return {
        public: venue.public,
        ignoreTimeScheduling: venue.ignoreTimeScheduling,
        sendPromotionalContent: venue.sendPromotionalContent,
        autoOnlineVoucherCreation: venue.autoOnlineVoucherCreation,
        nameEn: venue.nameTranslations.en,
        nameNl: venue.nameTranslations.nl,
        nameFr: venue.nameTranslations.fr,
        namePt: venue.nameTranslations.pt,
        addressEn: venue.addressTranslations.en,
        addressNl: venue.addressTranslations.nl,
        addressFr: venue.addressTranslations.fr,
        addressPt: venue.addressTranslations.pt,
        legalEntity: venue.legalEntity.id,
        duration: venue.introduction.duration,
        minPlayers: venue.introduction.minPlayers,
        costCenterCode: venue.costCenterCode,
        timezone: venue.timezone,
        code: venue.code,
    }
}

import { capitalize } from '../index'
import { isRequired, isAlphaNumeric } from './index'

export const isValidName = isRequired
export const isValidAddress = isRequired
export const isValidLegalEntity = isRequired
export const isValidCostCenterCode = isRequired
export const isValidTimezone = isRequired

export function isValidDuration(duration) {
    return isRequired(duration) && typeof duration === 'number'
}

export function isValidMinPlayers(minPlayers) {
    return isRequired(minPlayers)
    && typeof minPlayers === 'number'
    && minPlayers > 0
}

export function isValidCode(code) {
    return isRequired(code) && isAlphaNumeric(code)
}

export function isValidVenue(values) {
    return isValidName(values.nameEn)
        && isValidName(values.nameNl)
        && isValidName(values.nameFr)
        && isValidAddress(values.addressEn)
        && isValidAddress(values.addressNl)
        && isValidAddress(values.addressFr)
        && isValidLegalEntity(values.legalEntity)
        && isValidDuration(values.duration)
        && isValidMinPlayers(values.minPlayers)
        // && isValidCostCenterCode(values.costCenterCode)
        && isValidTimezone(values.timezone)
        && isValidCode(values.code)
}

export function formHasErrorsForLocale(errors, locale) {
    const localeCapitalized = capitalize(locale)

    const nameError = errors[`name${localeCapitalized}`]
    if (typeof nameError !== 'undefined' && nameError !== null)
        return true

    const addressError = errors[`address${localeCapitalized}`]
    if (typeof addressError !== 'undefined' && addressError !== null)
        return true

    return false
}

export function formHasLocaleErrors(errors) {
    return formHasErrorsForLocale(errors, 'Nl')
        || formHasErrorsForLocale(errors, 'En')
        || formHasErrorsForLocale(errors, 'Fr')
}

import React from 'react'
import {
    Form, Input, Row, Col,
} from 'antd'
import FormItem from '../GameFormItem'

function MultiLangEditGameForm({
    language,
    values,
    errors,
    onChange,
}) {
    return (
        <Form layout="vertical">
            <div className="pb-12">
                <FormItem
                    label="Game title"
                    error={errors[`title${language}`]}
                    isRequired
                >
                    <Input
                        name={`title${language}`}
                        value={values[`title${language}`]}
                        onChange={onChange}
                        placeholder="Title of the game"
                    />
                </FormItem>
            </div>

            <div className="pb-12">
                <FormItem
                    label="Read more link"
                    error={errors[`readMoreLink${language}`]}
                    isRequired
                >
                    <Input
                        name={`readMoreLink${language}`}
                        value={values[`readMoreLink${language}`]}
                        onChange={onChange}
                        placeholder="Read more"
                    />
                </FormItem>
            </div>

            <div className="pb-12">
                <FormItem
                    label="Body text"
                    error={errors[`body${language}`]}
                    isRequired
                >
                    <Input.TextArea
                        rows="4"
                        name={`body${language}`}
                        value={values[`body${language}`]}
                        onChange={onChange}
                        placeholder="Short description of the game"
                    />

                </FormItem>
            </div>

            <div className="pb-12">
                <FormItem
                    label="Extra conditions link"
                    error={errors[`extraConditionsLink${language}`]}
                >
                    <Input
                        name={`extraConditionsLink${language}`}
                        value={values[`extraConditionsLink${language}`]}
                        onChange={onChange}
                        placeholder="Extra conditions"
                    />
                </FormItem>
            </div>
        </Form>
    )
}

export default MultiLangEditGameForm

import React from 'react'
import './BookingDetailItem.css'

/**
 * @component
 * @param {Object} props
 * @param {string} props.label
 * @param {React.Element|React.Element[]|string|number|null=} props.children
 * @returns {Element}
 * @constructor
 */
export function BookingDetailItem(props) {
    return (
        <li className="bookingDetailItem">
            <span className="label">{props.label}</span>
            <span className="value">
                {props.children}
            </span>
        </li>
    )
}

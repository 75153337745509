import React from 'react'
import { TopBar } from 'components/layout/TopBar'
import { Main } from "../../layout/Main"
import {AuditBookingModalWithRedux} from "./modals/audit/AuditBookingModalWithRedux"
import {CreateBookingModalWithState} from "./modals/create/CreateBookingModalWithState"
import {EditBookingModalWithState} from "./modals/edit/EditBookingModalWithState"
import {BookingsTableWithBookings} from "./table/BookingsTableWithData"
import {DeleteBookingModalWithRedux} from "./modals/delete/DeleteBookingModalWithRedux"
import {OutstandingAmountModalWithState} from "./modals/outstandingAmount/OutstandingAmountModalWithState"

export function BookingsPage() {
    return (
        <>
            <TopBar activeMenuItem="bookings" />
            <Main>
                <BookingsTableWithBookings paginationKey="overview" />
            </Main>
            <AuditBookingModalWithRedux />
            <CreateBookingModalWithState />
            <EditBookingModalWithState />
            <DeleteBookingModalWithRedux />
            <OutstandingAmountModalWithState />
        </>
    )
}

import { getEntitiesByPagination, getPagination, getEntitiesForCurrentPage } from 'store/util/index'
import { getPaginationKey } from 'store/reducers/pagination/gameSessionsPaginationReducer'
import { fetchGameSessionsForActiveVenue } from 'store/actions/gameSessions'
import { removeBookingsFromState } from 'store/actions/bookings'
import withReduxState from 'components/hoc/withReduxState'
import dayjs from 'dayjs'
import { BACKEND_DATE_TIME_FORMAT } from '../../../../util/constants'

const mapStateToProps = (state, ownProps) => {
    const { venueId, roomId } = state.activeFilters
    const { paginationKey } = ownProps
    const fullPaginationKey = getPaginationKey(
        paginationKey,
        venueId,
        roomId,
    )
    const pagination = getPagination(state, 'gameSessions', fullPaginationKey)
    return ({
        isFetching: pagination.isFetching,
        currentPage: pagination.params.page,
        total: pagination.total,
        params: pagination.params,
        gameSessions: getEntitiesByPagination(state, 'gameSessions', fullPaginationKey),
        gameSessionsForCurrentPage: getEntitiesForCurrentPage(state, 'gameSessions', fullPaginationKey),
    })
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const { paginationKey, limit} = ownProps
    const page = 1
    const sort = 'start.asc'

    return ({
        fetchGameSessions: (params) => {
            const finalParams = {
                page,
                limit,
                sort,
                ...params,
            }
            if (typeof finalParams.from !== 'undefined' && dayjs.isDayjs(finalParams.from))
                finalParams.from = finalParams.from.format(BACKEND_DATE_TIME_FORMAT)
            else
                delete finalParams.from

            if (typeof finalParams.till !== 'undefined' && dayjs.isDayjs(finalParams.till))
                finalParams.till = finalParams.till.format(BACKEND_DATE_TIME_FORMAT)
            else
                delete finalParams.till

            dispatch(fetchGameSessionsForActiveVenue(paginationKey, finalParams))
        },
        removeSearchBookings: (componentName) => dispatch(removeBookingsFromState(componentName)),
    })
}

const GameSessionsRequest = withReduxState(mapStateToProps, mapDispatchToProps)

export default GameSessionsRequest

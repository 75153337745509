import React from 'react'
import { createGame, fetchGamesExtendCurrentParams } from 'store/actions/games'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    createGame: async (values) => dispatch(createGame(values)),
    createGameSuccess: (game) => {
        notification.success({
            message: 'Game added!',
            description: (
                <span>
                    A new game&nbsp;
                    <strong>{game && game.title}</strong>
                    &nbsp;
                    was successfully created.
                </span>
            ),
        })
    },
    createGameFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchGames: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchGamesExtendCurrentParams(paginationKey))
    },
})

const CreateGameRequest = withReduxState(null, mapDispatchToProps)

export default CreateGameRequest

import React from 'react'
import { Row } from 'antd'
import { ROLES } from 'util/constants'
import { TopBar } from 'components/layout/TopBar'
import { Main } from  'components/layout/Main'
import GlobalSettingsContainer from 'components/layout/sidebar/GlobalSettingsContainer'
import SidebarContent from 'components/layout/sidebar/SidebarContent'
import HasPermissions from 'components/pages/authorized/HasPermissions'
import NotAuthorized from 'components/pages/authorized/NotAuthorized'
import VenuesTopBar from 'components/pages/settings/venues/VenuesTopBar'
import VenuesOverview from 'components/pages/settings/venues/VenuesOverview'
import CreateVenueModalWithState from './modals/create/CreateVenueModalWithState'
import EditVenueModalWithState from './modals/edit/EditVenueModalWithState'

function VenuesPage() {
    return (
        <>
            <TopBar activeMenuItem="globalSettings" />
            <Main>
                <GlobalSettingsContainer activeMenuItem="venues">
                    <SidebarContent>
                        <HasPermissions
                            roles={[ROLES.ADMIN, ROLES.LEGAL_ENTITY_MANAGER, ROLES.READ_ONLY, ROLES.FRANCHISE]}
                            renderNotAuthorized={() => <NotAuthorized />}
                        >
                            <VenuesTopBar />
                            <Row>
                                <VenuesOverview paginationKey="overview" />
                            </Row>
                        </HasPermissions>
                    </SidebarContent>
                    <CreateVenueModalWithState />
                    <EditVenueModalWithState />
                </GlobalSettingsContainer>
            </Main>
        </>
    )
}

export default VenuesPage

import { CALL_API, METHODS } from '../middleware/api'
import { gameSchema, gameSchemaArray } from '../schemas/games'

export const GAME_REQUEST = 'GAME_REQUEST'
export const GAME_SUCCESS = 'GAME_SUCCESS'
export const GAME_FAILURE = 'GAME_FAILURE'

export const GAMES_REQUEST = 'GAMES_REQUEST'
export const GAMES_SUCCESS = 'GAMES_SUCCESS'
export const GAMES_FAILURE = 'GAMES_FAILURE'

export const ADD_GAME_TO_ROOM = 'ADD_GAME_TO_ROOM'
export const REMOVE_GAME_FROM_ROOM = 'REMOVE_GAME_FROM_ROOM'

export const fetchGames = (viewName, params) => ({
    viewName,
    [CALL_API]: {
        types: [
            GAMES_REQUEST,
            GAMES_SUCCESS,
            GAMES_FAILURE,
        ],
        endpoint: '/games',
        schema: gameSchemaArray,
        params,
    },
})

export const fetchGamesExtendCurrentParams = (paginationKey, params) => (dispatch, getState) => {
    const gamePagination = getState().pagination.games[paginationKey]
    const newParams = {
        ...gamePagination.params,
        ...params,
    }
    return dispatch(fetchGames(paginationKey, newParams))
}

export const createGame = (body) => ({
    [CALL_API]: {
        method: METHODS.POST,
        types: [
            GAME_REQUEST,
            GAME_SUCCESS,
            GAME_FAILURE,
        ],
        endpoint: '/games',
        schema: gameSchema,
        body,
    },
})

export const editGame = (gameId, body) => ({
    [CALL_API]: {
        method: METHODS.PUT,
        types: [
            GAME_REQUEST,
            GAME_SUCCESS,
            GAME_FAILURE,
        ],
        endpoint: `/games/${gameId}`,
        schema: gameSchema,
        body,
    },
})

export const deleteGame = (gameId) => ({
    [CALL_API]: {
        method: METHODS.DELETE,
        types: [
            GAME_REQUEST,
            GAME_SUCCESS,
            GAME_FAILURE,
        ],
        endpoint: `/games/${gameId}`,
        schema: gameSchema,
        entityId: gameId,
    },
})

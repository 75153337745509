import createPaginationReducer from '../hor/createPaginationReducer'
import {
    GAME_SESSIONS_REQUEST,
    GAME_SESSIONS_SUCCESS,
    GAME_SESSIONS_FAILURE,
} from '../../actions/gameSessions'

export const getPaginationKey = (componentName, venueId, roomId) => {
    if (roomId == null) {
        return `${componentName}(venues/${venueId})`
    }
    return `${componentName}(venues/${venueId}/rooms/${roomId})`
}

const gameSessionsPaginationReducer = createPaginationReducer({
    mapActionToKey: ({ componentName, venueId, roomId }) => getPaginationKey(componentName, venueId, roomId),
    types: [
        GAME_SESSIONS_REQUEST,
        GAME_SESSIONS_SUCCESS,
        GAME_SESSIONS_FAILURE,
    ],
})

export default gameSessionsPaginationReducer

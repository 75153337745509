import React from 'react'
import { Modal } from 'antd'
import { ModalFooter } from './ModalFooter'

function ConfirmModal({
    title,
    isOpen,
    onConfirm,
    onCancel,
    confirmText = 'Yes',
    confirmType = 'primary',
    cancelText = 'No',
    isLoading,
    cancelDisabled,
    confirmDisabled,
    confirmIsDanger = false,
    close,
    children,
}) {
    return (
        <Modal
            title={title}
            open={isOpen}
            closable={!isLoading && !cancelDisabled}
            maskClosable={!isLoading}
            confirmLoading={isLoading}
            onCancel={close}
            footer={(
                <ModalFooter
                    isLoading={isLoading}
                    onCancel={onCancel}
                    onSubmit={onConfirm}
                    submitText={confirmText}
                    submitType={confirmType}
                    submitIsDanger={confirmIsDanger}
                    cancelText={cancelText}
                    disableCancel={cancelDisabled}
                    disableSubmit={confirmDisabled}
                />
            )}
        >
            { children }
        </Modal>
    )
}

export default ConfirmModal

import React from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { Table, Input, Button } from 'antd'
import dotProp from 'dot-prop-immutable'
import FormItem from 'components/util/forms/FormItem'

function BlockTable({
    onChangeAsValue,
    values,
    errors,
    lang,
    addBlock,
}) {
    const columns = [
        {
            title: 'Title',
            dataIndex: `title${lang}`,
            render: (text, record, index) => (
                <FormItem
                    error={errors[`block${index}Title${lang}`]}
                    wrapperCol={null}
                >
                    <Input
                        name={`title${lang}`}
                        value={text}
                        onChange={(e) => (
                            onChangeAsValue('blocks', dotProp.set(values.blocks, `${index}.title${lang}`, e.target.value))
                        )}
                        placeholder="Title"
                    />
                </FormItem>
            ),
        }, {
            title: 'Discount',
            dataIndex: `discount${lang}`,
            render: (text, record, index) => (
                <FormItem
                    error={errors[`block${index}Discount${lang}`]}
                    wrapperCol={null}
                >
                    <Input
                        name={`discount${lang}`}
                        value={text}
                        onChange={(e) => {
                            onChangeAsValue('blocks', dotProp.set(values.blocks, `${index}.discount${lang}`, e.target.value))
                        }}
                        placeholder="Discount"
                    />
                </FormItem>
            ),
        }, {
            title: 'Color',
            dataIndex: 'color',
            render: (text, record, index) => (
                <FormItem
                    error={errors[`block${index}Color`]}
                    wrapperCol={null}
                >
                    <Input
                        name="color"
                        value={text}
                        // formatter={(v) => `#${v}`}
                        onChange={(e) => {
                            onChangeAsValue('blocks', dotProp.set(values.blocks, `${index}.color`, e.target.value))
                        }}
                        placeholder="Color"
                    />
                </FormItem>
            ),
        }, {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: 80,
            render: (text, record, index) => (
                <Button
                    ghost
                    icon={<DeleteOutlined />}
                    type="danger"
                    shape="circle"
                    size="small"
                    className="ant-btn-no-border"
                    onClick={() => onChangeAsValue('blocks', dotProp.delete(values.blocks, index))}
                />
            ),
        },
    ]

    return (
        <div className="blockTable">
            <Table
                rowKey="id"
                columns={columns}
                dataSource={values.blocks}
                pagination={false}
            />
            <div className="footer">
                {errors.blocks && (
                    <div className="errors">
                        {errors.blocks}
                    </div>
                )}
                <Button onClick={addBlock}>
                    Add block
                </Button>
            </div>
        </div>
    )
}

export default BlockTable

import React from 'react'
import { message } from 'antd'
import { WithFormState } from 'components/util/forms/WithFormState'
import { isValidVenue, formHasLocaleErrors } from 'util/validation/venue'
import { getVenueErrors } from 'util/validation/venueErrors'
import { getEntityFromResponse } from 'util/index'
import { toVenueApiRequest } from 'util/transformers/venue'
import CreateVenueRequest from './CreateVenueRequest'

function CreateVenueFormState({
    onCancel,
    onSaveSuccess,
    children,
}) {
    return (
        <CreateVenueRequest paginationKey="overview">
            {({
                createVenue,
                fetchVenues,
                createVenueSuccess,
                createVenueFailed,
            }) => (
                <WithFormState
                    initialValues={{
                        public: true,
                        ignoreTimeScheduling: false,
                        sendPromotionalContent: true,
                        autoOnlineVoucherCreation: true,
                        nameEn: '',
                        nameNl: '',
                        nameFr: '',
                        namePt: '',
                        addressEn: '',
                        addressNl: '',
                        addressFr: '',
                        addressPt: '',
                        duration: 30,
                        minPlayers: 1,
                        costCenterCode: '',
                        legalEntity: null,
                        timezone: '',
                        code: '',
                        addAllPublicGames: true,
                        addAllNonPublicGames: false,
                        openingDate: undefined,
                        closingDate: undefined,
                    }}
                    isValidForm={isValidVenue}
                    getFormErrors={getVenueErrors}
                    transformValuesToRequestBody={toVenueApiRequest}
                    onSendRequest={createVenue}
                    onCancel={onCancel}
                    onRequestSuccess={(response) => {
                        const venue = getEntityFromResponse(response, 'venues')
                        fetchVenues()
                        createVenueSuccess(venue)
                        onSaveSuccess(venue)
                    }}
                    onRequestFailed={createVenueFailed}
                    onInvalidFormWasSubmitted={(errors) => {
                        if (formHasLocaleErrors(errors)) {
                            message.error('Please fix translation errors.')
                        }
                    }}
                >
                    {children}
                </WithFormState>
            )}
        </CreateVenueRequest>
    )
}

export default CreateVenueFormState

import React from 'react'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'
import { fetchVenueGamesExtendCurrentParams, editVenueGame } from 'store/actions/venueGames'

const mapDispatchToProps = (dispatch, ownProps) => ({
    editVenueGame: async (values) => dispatch(editVenueGame(ownProps.venueGame.id, values)),
    editVenueGameSuccess: (venueGame) => {
        notification.success({
            message: 'Game for venue edited!',
            description: (
                <span>
                    The game&nbsp;
                    <strong>{venueGame && venueGame.title}</strong>
                    &nbsp;
                    was successfully edited for this venue.
                </span>
            ),
        })
    },
    editVenueGameFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchVenueGames: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchVenueGamesExtendCurrentParams(paginationKey))
    },
})

const EditVenueGameRequest = withReduxState(null, mapDispatchToProps)

export default EditVenueGameRequest

import React from 'react'
import {
    InputNumber,
    Row,
    Col,
    Form,
} from 'antd'
import { PLAYER_AND_TOTAL } from 'util/constants'
import FormItem from './GameFormItem'

function toNumber(value) {
    if (typeof value === 'number')
        return value

    if (typeof value === 'string')
        return parseFloat(value)

    return null
}

function PriceForm({
    values,
    onChangeAsValue,
    currency,
    priceSystem,
}) {
    const playerPriceKey = `playerPrice${currency.currencyCode}`
    const totalPriceKey = `totalPrice${currency.currencyCode}`

    // Debugging, if you need to..
    // console.log('Currency:', currency)
    // console.log('Keys:', [playerPriceKey, totalPriceKey])
    // console.log('Values:', values)
    // console.log('Player price object:', values.playerPrice)
    // console.log('Player price:', values[playerPriceKey])

    return (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col span={12}>
                    <FormItem
                        label="Price (per player)"
                        isRequired
                    >
                        <InputNumber
                            name={playerPriceKey}
                            value={toNumber(values[playerPriceKey])}
                            onChange={(value) => onChangeAsValue(playerPriceKey, toNumber(value))}
                            min={0}
                            formatter={(value) => `${currency.currencySymbol}${value}`}
                            parser={(value) => value.replace(currency.currencySymbol, '').replace(',', '.')}
                            className="full-width"
                        />
                    </FormItem>
                </Col>
                {priceSystem === PLAYER_AND_TOTAL && (
                    <Col span={12}>
                        <FormItem
                            label="Total price"
                            isRequired
                        >
                            <InputNumber
                                name={totalPriceKey}
                                value={toNumber(values[totalPriceKey])}
                                onChange={(value) => onChangeAsValue(totalPriceKey, toNumber(value))}
                                min={0}
                                formatter={(value) => `${currency.currencySymbol}${value}`}
                                parser={(value) => value.replace(currency.currencySymbol, '').replace(',', '.')}
                                className="full-width"
                            />
                        </FormItem>
                    </Col>
                )}
            </Row>
        </Form>
    )
}

export default PriceForm

import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import { toCouponTimeFrameApiRequest } from 'util/transformers/coupon'
import { isValidCouponTimeFrame } from 'util/validation/coupon'
import { getCouponTimeFrameErrors } from 'util/validation/couponErrors'
import useCouponBatchTimeFrame from '../../hooks/useCouponBatchTimeFrame'

function CreateCouponBatchTimeFrameFormState({
    couponBatch,
    onCancel,
    onCreateSuccess,
    children,
}) {
    const {
        createCouponBatchTimeFrame,
        createCouponBatchTimeFrameSuccess,
        createCouponBatchTimeFrameFailed,
        fetchCouponBatches,
    } = useCouponBatchTimeFrame('overview', { couponBatch })

    if (couponBatch === null || typeof couponBatch === 'undefined') {
        return null
    }
    return (
        <WithFormState
            key={couponBatch}
            initialValues={{
                isWholeDay: true,
                from: null,
                until: null,
            }}
            isValidForm={isValidCouponTimeFrame}
            getFormErrors={getCouponTimeFrameErrors}
            transformValuesToRequestBody={toCouponTimeFrameApiRequest}
            onCancel={onCancel}
            onSendRequest={(values) => createCouponBatchTimeFrame(values)}
            onRequestSuccess={() => {
                fetchCouponBatches()
                createCouponBatchTimeFrameSuccess()
                onCreateSuccess()
            }}
            onRequestFailed={createCouponBatchTimeFrameFailed}
        >
            {children}
        </WithFormState>
    )
}

export default CreateCouponBatchTimeFrameFormState

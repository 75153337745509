import React from 'react'
import { Modal } from 'antd'
import { ModalFooter } from 'components/util/modals/ModalFooter'
import EditUserForm from 'components/pages/settings/users/forms/edit/EditUserForm'

function EditUserModal({
    isOpen,
    onClose,
    user,
    errors,
    isLoading,
    onChange,
    onChangeAsValue,
    onCancel,
    onSubmit,
}) {
    if (user === null || typeof user === 'undefined') {
        return null
    }
    return (
        <Modal
            title={`Edit user ${user.firstName} ${user.lastName}`}
            width={600}
            open={isOpen}
            onCancel={onClose}
            onOk={onSubmit}
            closable={!(isLoading)}
            maskClosable={!(isLoading)}
            footer={(
                <ModalFooter
                    isLoading={isLoading}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    submitText="Edit"
                />
            )}
        >
            {isOpen
      && (
          <EditUserForm
              isLoading={isLoading}
              values={user}
              errors={errors}
              onChange={onChange}
              onChangeAsValue={onChangeAsValue}
          />
      )}
        </Modal>
    )
}

export default EditUserModal

import React, { useState } from 'react'
import Mount from 'components/util/Mount'
import Loading from 'components/util/Loading'
import GamesForVenue from 'components/pages/settings/games/GamesForActiveVenue'
import { getEntitiesFromResponse, whereIsNotId } from 'util/index'
import GamesTabs from './VenueGamesTabs'
import NoVenueGames from './NoVenueGames'
import CreateVenueGameModalWithState from '../modals/create/CreateVenueGameModalWithState'
import DeleteVenueGameModalWithRedux from '../modals/delete/DeleteVenueGameModalWithRedux'

const PAGE_SIZE = 100

function GamesOverview({
    paginationKey,
}) {
    const [activeTabKey, setAactiveTabKey] = useState('')

    return (
        <>
            <GamesForVenue paginationKey={paginationKey} limit={PAGE_SIZE}>
                {({
                    fetchGames,
                    gamesForCurrentPage,
                    isFetching,
                }) => {
                    if (isFetching) {
                        return (
                            <Mount didMount={fetchGames}>
                                <Loading />
                            </Mount>
                        )
                    }
                    return (
                        <Mount
                            didMount={async () => {
                                const venueGamesSuccessAction = await fetchGames()
                                const [firstGame] = getEntitiesFromResponse(venueGamesSuccessAction.response, 'venueGames')
                                if (typeof firstGame !== 'undefined') {
                                    setAactiveTabKey(`${firstGame.id}`)
                                }
                            }}
                        >
                            {gamesForCurrentPage.length <= 0
                                ? <NoVenueGames />
                                : (
                                    <>
                                        <GamesTabs
                                            activeTabKey={activeTabKey}
                                            venueGames={gamesForCurrentPage}
                                            paginationKey={paginationKey}
                                            onChangeTab={setAactiveTabKey}
                                        />
                                        <DeleteVenueGameModalWithRedux
                                            onDeleteSuccess={(gameId) => {
                                                const activeGame = gamesForCurrentPage.find(whereIsNotId(gameId))
                                                const activeGameId = typeof activeGame !== 'undefined' ? activeGame.id : null
                                                setAactiveTabKey(`${activeGameId}`)
                                            }}
                                        />
                                    </>
                                )}
                        </Mount>
                    )
                }}
            </GamesForVenue>
            <CreateVenueGameModalWithState
                onCreateSuccess={({ id }) => setAactiveTabKey(`${id}`)}
            />
        </>
    )
}
export default GamesOverview

import withReduxState from 'components/hoc/withReduxState'
import { getEntities } from 'store/util/index'

const mapStateToProps = (state, ownProps) => {
    const { entityKey, id } = ownProps
    const entity = getEntities(state, entityKey)[id]
    return ({
        ...entity,
        refreshEntity: (gameId) => getEntities(state, entityKey)[gameId],
    })
}

const Entity = withReduxState(mapStateToProps, null)

export default Entity

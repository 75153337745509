import createPaginationReducer from '../hor/createPaginationReducer'
import {
    ROOMS_REQUEST,
    ROOMS_SUCCESS,
    ROOMS_FAILURE,
} from '../../actions/rooms'

export const getPaginationKey = (componentName, venueId) => `${componentName}(venues/${venueId})`

const roomsPaginationReducer = createPaginationReducer({
    mapActionToKey: ({ componentName, venueId }) => getPaginationKey(componentName, venueId),
    types: [
        ROOMS_REQUEST,
        ROOMS_SUCCESS,
        ROOMS_FAILURE,
    ],
})

export default roomsPaginationReducer

import React from "react"
import { Select } from "antd"
import { LANGUAGES_READABLE } from "../../util/constants"
import {isValidString} from "../../util/string-utils"
import {useLegalEntity} from "../hooks/useLegalEntity"

/**
 * @component
 * @param {Object} props
 * @param {string|null|undefined} props.value
 * @param {string|undefined} props.placeholder
 * @param {(e: any) => void} props.onChange
 * @param {boolean|undefined} props.disabled
 * @returns {Element}
 * @constructor
 */
function SupportedLanguageSelect(props) {
    const legalEntity = useLegalEntity()
    if (typeof legalEntity === "undefined")
        throw new Error(`Legal Entity is undefined! Comp: ${SupportedLanguageSelect.name}`)

    const finalPlaceHolder = isValidString(props.placeholder) ? props.placeholder : "Select a language"
    const finalValue = isValidString(props.value) ? props.value : undefined

    return (
        <Select
            value={finalValue}
            onChange={props.onChange}
            disabled={props.disabled}
            placeholder={finalPlaceHolder}
        >
            {
                legalEntity.supportedLanguages.map((/** string */ language) => (
                    <Select.Option
                        key={language}
                        value={language}
                    >
                        {LANGUAGES_READABLE[language]}
                    </Select.Option>
                ))
            }
        </Select>
    )
}

export default SupportedLanguageSelect

import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import { getEntityFromResponse } from 'util/index'
import { getCouponBatchErrors, isValidCouponBatch } from 'util/validation/coupons'
import useCouponBatch from '../../hooks/useCouponBatch'
import {
    mapCouponBatchToValues,
    toBatchApiRequest,
} from '../../../../../../util/transformers/couponBatch'

function EditCouponBatchFormState({
    onCancel,
    onEditSuccess = () => { },
    children,
    couponBatch,
}) {
    const {
        fetchCouponBatches,
        editCouponBatchFailed,
        editCouponBatchSuccess,
        editCouponBatch,
    } = useCouponBatch('overview')

    if (couponBatch === null || typeof couponBatch === 'undefined') {
        return null
    }

    return (
        <WithFormState
            key={couponBatch && couponBatch.id}
            initialValues={{
                ...mapCouponBatchToValues(couponBatch),
            }}
            isValidForm={isValidCouponBatch}
            getFormErrors={getCouponBatchErrors}
            transformValuesToRequestBody={toBatchApiRequest}
            onSendRequest={(values) => editCouponBatch(couponBatch.id, values)}
            onCancel={onCancel}
            onRequestSuccess={(response) => {
                fetchCouponBatches()
                const updatedCouponBatch = getEntityFromResponse(response, 'couponBatches')
                editCouponBatchSuccess(updatedCouponBatch)
                onEditSuccess(updatedCouponBatch)
            }}
            onRequestFailed={editCouponBatchFailed}
        >
            {children}
        </WithFormState>
    )
}

export default EditCouponBatchFormState

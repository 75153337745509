import React from 'react'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Tooltip, Switch } from 'antd'
import ActiveFilters from 'components/layout/filters/WithActiveFilters'
import ToggleActiveBookingRequest from '../requests/ToggleActiveBookingRequest'
import WithMe from '../../authorized/WithMe'

const ACTIVE_BOOKING_TEXT = 'Set booking inactive for registration'
const INACTIVE_BOOKING_TEXT = 'Set booking active for registration'

function ActivateBookingSwitch({
    bookingId,
    isActive,
}) {
    return (
        <WithMe>
            {({ isReadOnly }) => (
                <ActiveFilters>
                    {({ activeVenueId }) => (
                        <ToggleActiveBookingRequest venueId={activeVenueId}>
                            {({ toggleActiveStatus, isLoading }) => (
                                <Tooltip
                                    placement="bottom"
                                    title={isActive ? ACTIVE_BOOKING_TEXT : INACTIVE_BOOKING_TEXT}
                                >
                                    <Switch
                                        loading={isLoading}
                                        disabled={isReadOnly}
                                        size="small"
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        onChange={(value) => toggleActiveStatus(bookingId, value)}
                                        checked={isActive}
                                    />
                                </Tooltip>
                            )}
                        </ToggleActiveBookingRequest>
                    )}
                </ActiveFilters>
            )}
        </WithMe>
    )
}

export default ActivateBookingSwitch

import { useSelector, useDispatch } from 'react-redux'
import { useCallback, useState, useMemo } from 'react'
import { fetchVouchers } from '../../store/actions/vouchers'

/**
 * @typedef {Object} PaginationParams
 * @property {number | undefined} page Current page to fetch
 * @property {number | undefined} limit How many items to fetch per page
 * @property {string | undefined } sort Order of the items. E.g. "created_at.desc"
 * @property {string | undefined} search Search vouchers by code or description
 */

/**
 * @typedef {Object} ReturnObject
 * @property {boolean} initialFetchStarted Has the first fetch already occurred?
 * @property {boolean} isFetching Is fetching data?
 * @property {number} currentPage Current page
 * @property {number} perPage Amount of records per page
 * @property {number} totalPages Total amount of pages
 * @property {PaginationParams} paginationParams Currently used pagination params
 * @property {Array} vouchersForCurrentPage All voucher for the current page
 * @property {(paginationParams: PaginationParams) => void} fetchVouchers
 */

const DEFAULT_SORT = 'created_at.desc'
const DEFAULT_PAGE_SIZE = 10
const DEFAULT_PAGE = 1

/**
 * @param paginationKey e.g. 'overview'
 * @returns ReturnObject
 */
export function usePaginatedVouchers(paginationKey) {
    const pagination = useSelector((state) => state.pagination.vouchers[paginationKey])
    const vouchers = useSelector((state) => state.entities.vouchers)
    const dispatch = useDispatch()
    const [initialFetchStarted, setInitialFetchStarted] = useState(false)

    const vouchersForCurrentPage = useMemo(() => {
        if (typeof vouchers !== 'object')
            return []
        if (typeof pagination?.params?.page !== 'number')
            return []
        if (pagination.isFetching)
            return []

        const idsInPage = pagination.pages[pagination.params.page]
        const entriesInPage = []
        for (let i = 0; i < idsInPage.length; i++) {
            const id = idsInPage[i]
            const voucher = vouchers[id]

            // Have to do this, because on deletion, the voucher with specified id
            // is 'unset' from the store object, but still exists in the page array...
            // That causes a brief moment where one of the ids in the page result
            // returns undefined. This is a workaround for that.
            // YES, THIS IS FCKING SHITTY CODE, you can thank Wappla for that...
            if (typeof voucher === 'undefined' || voucher === null)
                continue

            entriesInPage.push(voucher)
        }

        return entriesInPage
    }, [pagination, vouchers])

    /**
     * @param {PaginationParams} paginationParams
     */
    const localFetchVouchers = useCallback((paginationParams) => {
        setInitialFetchStarted(true)

        const finalParams = {}
        if (typeof paginationParams.page === 'number')
            finalParams.page = paginationParams.page
        else
            finalParams.page = DEFAULT_PAGE

        if (typeof paginationParams.limit === 'number')
            finalParams.limit = paginationParams.limit
        else
            finalParams.limit = DEFAULT_PAGE_SIZE

        if (typeof paginationParams.sort === 'string' && paginationParams.sort.length > 0)
            finalParams.sort = paginationParams.sort
        else
            finalParams.sort = DEFAULT_SORT

        if (typeof paginationParams.search === 'string' && paginationParams.search.length > 0)
            finalParams.search = paginationParams.search

        dispatch(fetchVouchers(paginationKey, finalParams))
    }, [paginationKey, dispatch])

    /** @type ReturnObject result */
    const result = useMemo(() => {
        if (typeof pagination !== 'object') {
            return {
                initialFetchStarted: initialFetchStarted,
                isFetching: false,
                fetchVouchers: localFetchVouchers,

                currentPage: DEFAULT_PAGE,
                totalPages: 1,
                perPage: DEFAULT_PAGE_SIZE,
                paginationParams: {
                    page: DEFAULT_PAGE,
                    limit: DEFAULT_PAGE_SIZE,
                    sort: DEFAULT_SORT,
                },

                vouchersForCurrentPage: vouchersForCurrentPage,
            }
        } else {
            return {
                initialFetchStarted: initialFetchStarted,
                isFetching: pagination.isFetching,
                fetchVouchers: localFetchVouchers,

                currentPage: pagination.params.page,
                totalPages: pagination.total,
                perPage: pagination.params.limit,
                paginationParams: pagination.params,

                vouchersForCurrentPage: vouchersForCurrentPage,
            }
        }
    }, [initialFetchStarted, localFetchVouchers, pagination, vouchersForCurrentPage])

    return result
}

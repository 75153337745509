import dayjs, { Dayjs } from 'dayjs'
import { BACKEND_DATE_FORMAT } from '../constants'

function isValidId(num) {
    return typeof num === 'number' && num > 0
}

function isValidString(str) {
    return typeof str === 'string' && str.length > 0
}

const emptyCommentHtml = "<p></p>"

export function toVoucherApiPostRequestBody(values) {
    const body = {
        // Required
        legal_entity_id: values.legalEntityId,
        issued_reason_id: values.issuedReasonId,
        amount: values.amount,
    }
    if (dayjs.isDayjs(values.expiryDate)) {
        body.expiry_date = values.expiryDate.format(BACKEND_DATE_FORMAT)
    } else if (typeof values.expiryDate === 'string' && values.expiryDate.length > 0) {
        body.expiry_date = values.expiryDate
    }

    // Optional
    if (isValidId(values.issuedInVenueId)) {
        body.issued_in_venue_id = values.issuedInVenueId
    }
    if (isValidString(values.comment) && values.comment !== emptyCommentHtml) {
        body.comment = values.comment
    }
    if (isValidString(values.associatedOrderNumber)) {
        body.associated_order_number = values.associatedOrderNumber
    }
    if (isValidString(values.dealNumber)) {
        body.deal_number = values.dealNumber
    }

    return body
}

export function toVoucherApiPatchRequestBody(values) {
    const body = {
        // Required
        legal_entity_id: values.legalEntityId,
        issued_reason_id: values.issuedReasonId,
        amount: values.amount,
    }
    if (dayjs.isDayjs(values.expiryDate)) {
        body.expiry_date = values.expiryDate.format(BACKEND_DATE_FORMAT)
    } else if (typeof values.expiryDate === 'string' && values.expiryDate.length > 0) {
        body.expiry_date = values.expiryDate
    }

    // Optional
    if (isValidId(values.issuedInVenueId)) {
        body.issued_in_venue_id = values.issuedInVenueId
    } else {
        body.issued_in_venue_id = null
    }
    if (isValidString(values.comment) && values.comment !== emptyCommentHtml) {
        body.comment = values.comment
    } else {
        body.comment = null
    }
    if (isValidString(values.associatedOrderNumber)) {
        body.associated_order_number = values.associatedOrderNumber
    } else {
        body.associated_order_number = null
    }
    if (isValidString(values.dealNumber)) {
        body.deal_number = values.dealNumber
    } else {
        body.deal_number = null
    }

    return body
}

export function mapVoucherToValues(voucherResource) {
    const values = {
        legalEntityId: voucherResource.legal_entity_id,
        issuedReasonId: voucherResource.issued_reason_id,
        amount: voucherResource.amount,
        expiryDate: dayjs(voucherResource.coupon.expires_at),
    }

    if (isValidId(voucherResource.issued_in_venue_id)) {
        values.issuedInVenueId = voucherResource.issued_in_venue_id
    }
    if (isValidString(voucherResource.comment)) {
        values.comment = voucherResource.comment
    }
    if (isValidString(voucherResource.associated_order_number)) {
        values.associatedOrderNumber = voucherResource.associated_order_number
    }
    if (isValidString(voucherResource.deal_number)) {
        values.dealNumber = voucherResource.deal_number
    }

    return values
}

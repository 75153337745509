// 2024-01-21 Joeri: Keep in sync with the global index.css
export const colorPrimaryLight = '#BCFDE9'
export const colorPrimary = '#73FBD3'
export const colorPrimaryDark = '#73FBD3'

export const colorSecondaryLighter = '#D2E9E8'
export const colorSecondaryLight = '#239A98'
export const colorSecondary = '#239A98'
export const colorSecondaryDark = '#239A98'

export const colorInfo = '#1FB6FF'
export const colorAlert = '#FF4949'
export const colorSuccess = '#13CE66'
export const colorWarning = '#FFC82C'

export const colorGreyLight = '#fafafa'
export const colorGrey = '#e8e8e8'
export const colorGreyDarker = '#767676'
export const colorWhite = '#FFFFFF'
export const colorDark = '#1a1a1a'

export const spacingSmallest = 4
export const spacingSmaller = 8
export const spacingSmall = 12
export const spacing = 16
export const spacingLarge = 32
export const spacingLarger = 48
export const spacingLargest = 64
export const spacingHuge = 75

export const borderRadius = 4
export const borderRadiusLarge = 8

export const borderSmall = 1

export const fontFamilySerif = '\'Playfair Display\', serif'
export const fontFamilySansSerif = '\'Roboto\', -apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\', \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\', \'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif'
export const fontFamilyMonospace = 'Menlo, Monaco, Consolas, \'Liberation Mono\', \'Courier New\', monospace'

export const fontSizeSmallest = 10
export const fontSizeSmaller = 12
export const fontSizeSmall = 14
export const fontSize = 16
export const fontSizeLarge = 22
export const fontSizeLarger = 28
export const fontSizeLargest = 36
export const fontSizeHuge = 46

export const fontWeightThin = 100
export const fontWeightLight = 200
export const fontWeightRegular = 400
export const fontWeightMedium = 500
export const fontWeightBold = 600
export const fontWeightBlack = 700

export const defaultFontFamily = fontFamilySansSerif
export const defaultFontWeight = fontWeightLight
export const defaultFontSize = fontSize
export const defaultFontColor = colorGreyDarker
export const defaultLetterSpacing = 0
export const defaultLineHeight = 1.65
export const defaultSpacing = spacing

export const defaultBorderColor = colorGrey
export const defaultBackgroundColor = colorWhite
export const defaultBorderRadius = borderRadius
export const defaultBorder = borderSmall

export const zIndexTopBar = 5

export const maxWidth = 1040

import { CALL_API, METHODS } from '../middleware/api'
import { venueSchema, venueSchemaArray } from '../schemas/venues'

export const VENUES_REQUEST = 'VENUES_REQUEST'
export const VENUES_SUCCESS = 'VENUES_SUCCESS'
export const VENUES_FAILURE = 'VENUES_FAILURE'

export const VENUE_REQUEST = 'VENUE_REQUEST'
export const VENUE_SUCCESS = 'VENUE_SUCCESS'
export const VENUE_FAILURE = 'VENUE_FAILURE'

export const fetchVenues = (viewName, params) => ({
    viewName,
    [CALL_API]: {
        types: [
            VENUES_REQUEST,
            VENUES_SUCCESS,
            VENUES_FAILURE,
        ],
        endpoint: '/venues',
        schema: venueSchemaArray,
        params,
    },
})

export const fetchVenuesExtendCurrentParams = (paginationKey, params) => (dispatch, getState) => {
    const venuePagination = getState().pagination.venues[paginationKey]
    const newParams = {
        ...venuePagination.params,
        ...params,
    }
    return dispatch(fetchVenues(paginationKey, newParams))
}

export const createVenue = (body) => ({
    [CALL_API]: {
        method: METHODS.POST,
        types: [
            VENUE_REQUEST,
            VENUE_SUCCESS,
            VENUE_FAILURE,
        ],
        endpoint: '/venues',
        schema: venueSchema,
        body,
    },
})

export const deleteVenue = (venueId) => ({
    [CALL_API]: {
        method: METHODS.DELETE,
        types: [
            VENUE_REQUEST,
            VENUE_SUCCESS,
            VENUE_FAILURE,
        ],
        endpoint: `/venues/${venueId}`,
        schema: venueSchema,
        entityId: venueId,
    },
})

export const editVenue = (venueId, body) => ({
    [CALL_API]: {
        method: METHODS.PUT,
        types: [
            VENUE_REQUEST,
            VENUE_SUCCESS,
            VENUE_FAILURE,
        ],
        endpoint: `/venues/${venueId}`,
        schema: venueSchema,
        body,
    },
})

import React from 'react'
import { Modal } from 'antd'
import { ModalFooter } from 'components/util/modals/ModalFooter'
import CreateEquipmentSetForm from '../../forms/create/CreateEquipmentSetForm'

function CreateEquipmentSetModal({
    isOpen,
    onClose,
    values,
    errors,
    isLoading,
    onChange,
    onCancel,
    onSubmit,
}) {
    return (
        <Modal
            title="Create new equipment set"
            open={isOpen}
            onOk={onSubmit}
            onCancel={onClose}
            closable={!isLoading}
            maskClosable={!isLoading}
            footer={(
                <ModalFooter
                    isLoading={isLoading}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                />
            )}
        >
            {isOpen
      && (
          <CreateEquipmentSetForm
              isLoading={isLoading}
              values={values}
              errors={errors}
              onChange={onChange}
              onCreateSuccess={onClose}
          />
      )}
        </Modal>
    )
}

export default CreateEquipmentSetModal

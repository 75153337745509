import React from 'react'
import { Modal } from 'antd'
import { ModalFooter } from 'components/util/modals/ModalFooter'
import EditCouponBatchFormState from '../../forms/edit/EditCouponBatchFormState'
import EditCouponBatchForm from '../../forms/edit/EditCouponBatchForm'

function EditCouponBatchModal({
    isOpen,
    onClose,
    couponBatch,
    legalEntities,
    activeLegalEntity,
}) {
    return (
        <EditCouponBatchFormState
            onEditSuccess={onClose}
            onCancel={onClose}
            couponBatch={couponBatch}
        >
            {({
                values,
                errors,
                isLoading,
                mergeValues,
                handleChange,
                handleChangeAsValue,
                handleCancel,
                handleSubmit,
            }) => (
                <Modal
                    title="Edit Coupon Batch"
                    open={isOpen}
                    onOk={handleSubmit}
                    onCancel={onClose}
                    closable={!isLoading}
                    maskClosable={!isLoading}
                    footer={(
                        <ModalFooter
                            isLoading={isLoading}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                        />
                    )}
                >
                    {isOpen && (

                        <EditCouponBatchForm
                            isLoading={isLoading}
                            values={values}
                            errors={errors}
                            onChange={handleChange}
                            onChangeAsValue={handleChangeAsValue}
                            legalEntity={legalEntities[values.legalEntity] || activeLegalEntity}
                        />
                    )}
                </Modal>
            )}
        </EditCouponBatchFormState>
    )
}

export default EditCouponBatchModal

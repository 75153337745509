import React from 'react'
import { WithFormState } from 'components/util/forms/WithFormState'
import { isValidDiscountBanner } from 'util/validation/discountBanner'
import { getDiscountBannerErrors } from 'util/validation/discountBannerErrors'
import { getEntityFromResponse } from 'util/index'
import { toDiscountBannerApiRequest } from 'util/transformers/discountBanner'
import CreateDiscountBannerRequest from './CreateDiscountBannerRequest'

export const EMPTY_DISCOUNT_BLOCK = {
    titleEn: '',
    titleNl: '',
    titleFr: '',
    discountEn: '',
    discountNl: '',
    discountFr: '',
    color: '#79d168',
}

function CreateDiscountBannerBannerFormState({
    onCancel,
    onSaveSuccess,
    children,
}) {
    return (
        <CreateDiscountBannerRequest paginationKey="overview">
            {({
                createDiscountBanner,
                fetchDiscountBanners,
                createDiscountBannerSuccess,
                createDiscountBannerFailed,
            }) => (
                <WithFormState
                    initialValues={{
                        active: false,
                        titleNl: '',
                        titleEn: '',
                        titleFr: '',
                        subtitleNl: '',
                        subtitleEn: '',
                        subtitleFr: '',
                        blocks: [
                            {
                                id: 0,
                                ...EMPTY_DISCOUNT_BLOCK,
                            },
                        ],
                    }}
                    isValidForm={isValidDiscountBanner}
                    getFormErrors={getDiscountBannerErrors}
                    transformValuesToRequestBody={toDiscountBannerApiRequest}
                    onSendRequest={createDiscountBanner}
                    onCancel={onCancel}
                    onRequestSuccess={(response) => {
                        const discountBanner = getEntityFromResponse(response, 'discountBanners')
                        fetchDiscountBanners()
                        createDiscountBannerSuccess(discountBanner)
                        onSaveSuccess(discountBanner)
                    }}
                    onRequestFailed={createDiscountBannerFailed}
                >
                    {children}
                </WithFormState>
            )}
        </CreateDiscountBannerRequest>
    )
}

export default CreateDiscountBannerBannerFormState

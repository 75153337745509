import {
    OPEN_MODAL,
    CLOSE_MODAL,
} from '../actions/modals'

export const defaultModalState = {
    activeModalId: null,
    entity: null,
}

// eslint-disable-next-line default-param-last
const activeModalsReducer = (state = defaultModalState, action) => {
    const { type, activeModalId, entity } = action
    switch (type) {
        case OPEN_MODAL:
            return {
                activeModalId,
                entity,
            }
        case CLOSE_MODAL:
            return defaultModalState
        default:
            return state
    }
}

export default activeModalsReducer

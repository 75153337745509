import { CALL_API } from '../middleware/api'
import { voucherIssueReasonsArray } from '../schemas/voucherIssueReasons'

export const VOUCHER_ISSUE_REASONS_REQUEST = 'VOUCHER_ISSUE_REASONS_REQUEST'
export const VOUCHER_ISSUE_REASONS_SUCCESS = 'VOUCHER_ISSUE_REASONS_SUCCESS'
export const VOUCHER_ISSUE_REASONS_FAILURE = 'VOUCHER_ISSUE_REASONS_FAILURE'

/**
 * @param viewName E.g. 'overview'
 * @param params Search params
 * @returns {Object}
 */
export function fetchVoucherIssueReasons(viewName, params) {
    return {
        viewName,
        [CALL_API]: {
            types: [
                VOUCHER_ISSUE_REASONS_REQUEST,
                VOUCHER_ISSUE_REASONS_SUCCESS,
                VOUCHER_ISSUE_REASONS_FAILURE,
            ],
            endpoint: '/voucher-issue-reasons',
            schema: voucherIssueReasonsArray,
            params,
        },
    }
}

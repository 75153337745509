import React, {useCallback, useEffect, useState} from 'react'
import {Button, notification} from 'antd'
import { TopBar } from 'components/layout/TopBar'
import { useParams } from 'react-router'
import Loading from '../../util/Loading'
import './GameSessionPage.css'
import useCurrentUser from "../../hooks/useCurrentUser"
import {useGameSessionAPI} from "../../hooks/gamesessions/useGameSessionAPI"
import {useSelector} from "react-redux"
import {GameSessionPlayerEntry} from "./single/GameSessionPlayerEntry"
import {GameSessionPlayersState} from "./single/GameSessionPlayersState"
import {
    GameSessionBookingAndCustomerProfilesEntry,
} from "./single/GameSessionBookingCustomerProfilesEntry"
import {GameSessionTeamEntry} from "./single/GameSessionTeamEntry"
import * as storage from 'util/storage'

/**
 * @component
 * @param props
 * @returns {Element}
 * @constructor
 */
export function GameSessionPage(props) {
    const pathParams = useParams()
    const gameSessionId = pathParams.gameSessionId
    const currentUserData = useCurrentUser()
    const isReadOnlyUser = currentUserData.isReadOnly
    const gameSessionAPI = useGameSessionAPI()
    const [isProcessingRequest, setIsProcessingRequest] = useState(false)
    const activeFilters = useSelector(state => state.activeFilters)
    const venueId = activeFilters.venueId
    const [gameSessionPlayersState, setGameSessionPlayersState] = useState(/** @type {GameSessionPlayersState|null} */null)
    const [getGameSessionByIdErrorMessage, setGetGameSessionByIdErrorMessage] = useState(/** @type {string|null} */ null)
    const [isDirty, setIsDirty] = useState(/** @type {boolean} */ false)
    const [isValid, setIsValid] = useState(/** @type {boolean} */ false)
    const [currentGamePlayers, setCurrentGamePlayers] = useState(/** @type GameSessionPlayerOrNull[] */[])
    const [bookingAndProfileOptions, setBookingAndProfileOptions] = useState(/** @type {BookingAndCustomerProfilesOption[]} */ [])
    const [selectedProfileIds, setSelectedProfileIds] = useState(/** @type number[] */ [])
    const [teams, setTeams] = useState(/** @type GameSessionTeam[] */ [])

    if (!currentUserData.hasFetchedUser)
        throw new Error("User data not fetched yet! Check authorized component")

    /** @type {(gameSessionResource: GameSessionResource | null, errorMessage: string | null) => void} */
    const getGameSessionByIdCallback = useCallback((gameSessionResource, errorMessage) => {
        if (errorMessage !== null) {
            setGetGameSessionByIdErrorMessage(errorMessage)
        } else {
            const stateObj = new GameSessionPlayersState({
                gameSessionResource: gameSessionResource,
            })
            setGameSessionPlayersState(stateObj)
            setCurrentGamePlayers(stateObj.currentGamePlayers)
            setBookingAndProfileOptions(stateObj.bookingAndCustomerProfileSelectionOptions)
            setTeams(stateObj.currentTeams)
            setIsDirty(stateObj.isDirty)
            setIsValid(stateObj.isValid)
            setIsProcessingRequest(false)
        }
    }, [])

    /** @type {(gameSessionResource: GameSessionResource | null, errorMessage: string | null) => void} */
    const putGameSessionByIdCallback = useCallback((gameSessionResource, errorMessage) => {
        if (errorMessage !== null) {
            setGetGameSessionByIdErrorMessage(errorMessage)
        } else {
            const stateObj = new GameSessionPlayersState({
                gameSessionResource: gameSessionResource,
            })
            setGameSessionPlayersState(stateObj)
            setCurrentGamePlayers(stateObj.currentGamePlayers)
            setBookingAndProfileOptions(stateObj.bookingAndCustomerProfileSelectionOptions)
            setTeams(stateObj.currentTeams)
            setIsDirty(stateObj.isDirty)
            setIsValid(stateObj.isValid)
            setIsProcessingRequest(false)

            // Show notification to user
            notification.success({
                message: "Succesfully updated game session 🥳",
            })
        }
    }, [])

    useEffect(() => {
        // console.log("Running this")
        gameSessionAPI.getGameSessionById(venueId, gameSessionId, getGameSessionByIdCallback)
        setIsProcessingRequest(true)
    }, [])

    useEffect(() => {
        if (getGameSessionByIdErrorMessage !== null)
            notification.error({
                message: getGameSessionByIdErrorMessage,
            })
    }, [getGameSessionByIdErrorMessage])

    // Change listeners
    useEffect(() => {
        const gsState = gameSessionPlayersState
        gsState?.addDirtyStateChangeListener(setIsDirty)
        return () => {
            gsState?.removeDirtyStateChangeListener(setIsDirty)
        }
    }, [gameSessionPlayersState])
    useEffect(() => {
        const gsState = gameSessionPlayersState
        gsState?.addValidStateChangeListener(setIsValid)
        return () => {
            gsState?.removeValidStateChangeListener(setIsValid)
        }
    }, [gameSessionPlayersState])
    useEffect(() => {
        const gsState = gameSessionPlayersState
        gsState?.addBookingAndProfilesOptionsChangeListener(setBookingAndProfileOptions)
        return () => {
            gsState?.removeBookingsAndProfilesOptionsChangeListener(setBookingAndProfileOptions)
        }
    }, [gameSessionPlayersState])
    useEffect(() => {
        const gsState = gameSessionPlayersState
        gsState?.addSelectedRegisteredProfileIdsChangeListener(setSelectedProfileIds)
        return () => {
            gsState?.removeSelectedRegisteredProfileIdsChangeListener(setSelectedProfileIds)
        }
    }, [gameSessionPlayersState])
    useEffect(() => {
        const gsState = gameSessionPlayersState
        gsState?.addGamePlayersChangedListener(setCurrentGamePlayers)
        return () => {
            gsState?.removeGamePlayersChangedListener(setCurrentGamePlayers)
        }
    }, [gameSessionPlayersState])
    useEffect(() => {
        const gsState = gameSessionPlayersState
        gsState?.addTeamsChangeListener(setTeams)
        return () => {
            gsState?.removeTeamsChangeListener(setTeams)
        }
    }, [gameSessionPlayersState])

    /** @type {(gspState: GameSessionPlayersState) => Promise<void>} */
    const updateGameSession = useCallback(async (gspState) => {
        const teamImagesUploadSuccess = await uploadNewTeamImages(gspState)
        if (!teamImagesUploadSuccess) {
            // TODO: show notification
            console.error("Failed to upload team images")
            return
        }

        const putRequestBody = gameSessionPlayersStateToPutRequestBody(gspState)
        // console.log("Put request body:", putRequestBody)
        gameSessionAPI.putGameSessionById({
            venueId: venueId,
            gameSessionId: gameSessionId,
            requestBody: putRequestBody,
        }, putGameSessionByIdCallback)
        setIsProcessingRequest(true)
    }, [gameSessionAPI, gameSessionId, putGameSessionByIdCallback, venueId])


    if (typeof getGameSessionByIdErrorMessage === "string") {
        return (
            <>
                <TopBar activeMenuItem="gameSessions" />
                <div className="gssp-main max-width-container">
                    <p>Something went wrong while fetching game session...</p>
                </div>
            </>
        )
    }
    if (gameSessionPlayersState === null) {
        return (
            <>
                <TopBar activeMenuItem="gameSessions" />
                <Loading />
            </>
        )
    }

    // Amount of registered profiles are still "selectable" in the right column
    const selectableProfilesCount = gameSessionPlayersState.selectableRegisteredProfilesCount
    // Amount game player spots that are taken
    const filledPlayerSpotsCount = gameSessionPlayersState.filledCurrentGamePlayersSpotsCount

    return (
        <>
            <TopBar activeMenuItem="gameSessions" />
            <div className="gssp-main max-width-container">
                <div className="gssp-header gssp-container-border gssp-container-padding">
                    <div><b>Game</b>: {gameSessionPlayersState.gameTitle}</div>
                    <div><b>Room</b>: {gameSessionPlayersState.roomName}</div>
                    <div><b>Date</b>: {gameSessionPlayersState.formattedDate}</div>
                    <div><b>Start</b>: {gameSessionPlayersState.formattedStartTime}</div>
                    <div><b>End</b>: {gameSessionPlayersState.formattedEndTime}</div>
                </div>

                <div className="gssp-players-customers-container gssp-container-border">
                    <div className="gssp-container-padding gssp-bottom-border gssp-column-title">
                        Assigned players ({filledPlayerSpotsCount}/{gameSessionPlayersState.gameMaxPlayers})
                    </div>
                    <div className="gssp-container-padding gssp-bottom-border gssp-left-border gssp-column-title">
                        Registered customers
                    </div>
                    <div className="gssp-players-container gssp-container-padding overflow-y-auto gssp-bottom-border">
                        {
                            currentGamePlayers.map((gp, index) => (
                                <GameSessionPlayerEntry
                                    key={index}
                                    index={index}
                                    gsState={gameSessionPlayersState}
                                    gamePlayer={gp}
                                    isProcessingRequest={isProcessingRequest}
                                />
                            ))
                        }
                    </div>
                    <div className="gssp-left-border overflow-y-auto gssp-container-padding gssp-bottom-border">
                        {
                            bookingAndProfileOptions.map((bo) => (
                                <GameSessionBookingAndCustomerProfilesEntry
                                    key={bo.bookingId}
                                    bookingEntry={bo}
                                    gsState={gameSessionPlayersState}
                                    isProcessingRequest={isProcessingRequest}
                                />
                            ))
                        }
                    </div>
                    <div className="gssp-buttons-container gssp-container-padding">
                        <Button
                            type="primary"
                            disabled={gameSessionPlayersState.gameMaxPlayers === gameSessionPlayersState.emptyGamePlayerSpotsCount || isProcessingRequest}
                            onClick={gameSessionPlayersState.removeAllGamePlayers}
                        >
                            Unassign all
                        </Button>
                    </div>
                    <div className="gssp-buttons-container gssp-left-border gssp-container-padding">
                        <Button
                            type="primary"
                            disabled={selectedProfileIds.length === 0 || isProcessingRequest}
                            onClick={gameSessionPlayersState.addSelectedProfilesToGamePlayers}
                        >
                            Assign {selectedProfileIds.length} selected player(s)
                        </Button>
                        <Button
                            type="primary"
                            disabled={selectableProfilesCount === 0 || isProcessingRequest}
                            onClick={gameSessionPlayersState.addMaxProfilesToGamePlayers}
                        >
                            Assign max possible
                        </Button>
                    </div>
                </div>

                {
                    teams.length !== 0 && (
                        <div className="gssp-container-border">
                            <div className="gssp-container-padding gssp-bottom-border gssp-column-title">
                                Teams
                            </div>
                            <div className="gssp-teams-container">
                                {
                                    teams.map((team, index) => (
                                        <GameSessionTeamEntry
                                            key={team.id}
                                            index={index}
                                            team={team}
                                            gsState={gameSessionPlayersState}
                                            isProcessingRequest={isProcessingRequest}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    )
                }

                <div className="gssp-save-container gssp-container-border gssp-container-padding">
                    <Button
                        type="primary"
                        disabled={isDirty === false || isValid === false}
                        size={"large"}
                        onClick={() => updateGameSession(gameSessionPlayersState)}
                        loading={isProcessingRequest}
                    >
                        Save game session
                    </Button>
                    <p className="m-0">{"Saves the changes you've made. Makes sure that 'Get player names' will fetch the correct names."}</p>
                </div>
            </div>
        </>
    )
}

/**
 * Transforms game session players state to API put request body
 * @param {GameSessionPlayersState} gspState
 * @return {PutGameSessionRequestBody}
 */
function gameSessionPlayersStateToPutRequestBody(gspState) {
    /** @type {PutGameSessionRequestBodyPlayer[]} */
    const gameSessionPlayers = []
    for (let i = 0, length = gspState.currentGamePlayers.length; i < length; i++) {
        const player = gspState.currentGamePlayers[i]
        // Skip empty slots
        if (player === null)
            continue

        /** @type {PutGameSessionRequestBodyPlayer} */
        const gameSessionPlayer = {
            playerNumber: player.playerNumber,
            nickname: player.nickname,
        }
        if (typeof player.customerProfileId === "number") {
            gameSessionPlayer.customerProfileId = player.customerProfileId
        }

        gameSessionPlayers.push(gameSessionPlayer)
    }

    /** @type {PutGameSessionRequestBody} */
    const body = {
        gameSessionPlayers: gameSessionPlayers,
    }

    if (gspState.currentTeams.length > 0) {
        body.teams = gspState.currentTeams.map(team => {
            /** @type {PutGameSessionRequestBodyTeam} */
            const t = {
                id: team.id,
                name: team.name,
            }
            if (typeof team.newTeamAvatarImgMediaItemId === "number") {
                t.newAvatarImageMediaId = team.newTeamAvatarImgMediaItemId
            }
            return t
        })
    }

    return body
}

/**
 * @typedef {Object} UploadResponseData
 * @property {Object} data
 * @property {number} data.id
 * @property {string} data.created_at
 * @property {string} data.updated_at
 */

/**
 * Uploads team images if there are any new ones and returns whether it was successful
 * @param {GameSessionPlayersState} gspState
 * @return {Promise<boolean>}
 */
async function uploadNewTeamImages(gspState) {
    for (let i = 0, length = gspState.currentTeams.length; i < length; i++) {
        const team = gspState.currentTeams[i]
        // console.log("Team:", team)
        const hasNewImg = team.newImageWasAssigned &&
            typeof team.teamAvatarImgSrc === "string" &&
            team.teamAvatarImgSrc.length > 0

        // console.log("Has new image:", hasNewImg)
        if (!hasNewImg) {
            continue
        }

        // Download the local image
        // console.log("Image url:", team.teamAvatarImgSrc)
        const imageResponse = await fetch(team.teamAvatarImgSrc)
        // console.log("Image response status:", imageResponse.status)
        const blob = await imageResponse.blob()

        const accessToken = await storage.getAccessToken()
        const formData = new FormData()
        formData.append("file", blob, team.teamAvatarFileName)
        const uri = `${process.env.REACT_APP_API}/api/internal/upload`
        // console.log("uri:", uri)
        const uploadResponse= await fetch(uri, {
            method: "POST",
            headers: {
                "Accept": "image/*",
                "Authorization": `Bearer ${accessToken}`,
            },
            body: formData,
        })

        // console.log("Upload response status:", uploadResponse.status)
        if (uploadResponse.status !== 201)
            return false

        /** @type {UploadResponseData} */
        const uploadResponseBody = await uploadResponse.json()
        // console.log("Upload response body:", uploadResponseBody)
        // Set team media id based on response
        team.newTeamAvatarImgMediaItemId = uploadResponseBody.data.id
    }

    return true
}
import React from 'react'
import CreateGameSessionModal from './CreateGameSessionModal'
import CreateGameSessionModalState from './CreateGameSessionModalState'
import CreateGameSessionFormState from '../../forms/create/CreateGameSessionFormState'

function CreateGameSessionModalWithState() {
    return (
        <CreateGameSessionModalState>
            {({ isOpen, closeModal }) => (
                <CreateGameSessionFormState onCancel={closeModal} onCreateSuccess={closeModal}>
                    {({
                        values,
                        errors,
                        isLoading,
                        mergeValues,
                        handleChangeAsValue,
                        handleCancel,
                        handleSubmit,
                    }) => (
                        <CreateGameSessionModal
                            isOpen={isOpen}
                            onClose={closeModal}
                            values={values}
                            errors={errors}
                            isLoading={isLoading}
                            mergeValues={mergeValues}
                            onChangeAsValue={handleChangeAsValue}
                            onCancel={handleCancel}
                            onSubmit={handleSubmit}
                        />
                    )}
                </CreateGameSessionFormState>
            )}
        </CreateGameSessionModalState>
    )
}
export default CreateGameSessionModalWithState

import { useEffect, useState } from 'react'
import callApi from 'store/middleware/callApi'
import { METHODS } from 'store/middleware/api'
import { useActiveFilters } from 'components/hooks/useActiveFilters'
// eslint-disable-next-line no-unused-vars
import { STATUS_AVAILABLE } from './TSConstants'
import { APP_DATE_FORMAT } from '../../../../util/constants'

/**
 *
 * @param {string} requestedDate
 * @returns
 */

const useFetchRoomsAndSlots = (requestedDate) => {
    const { venueId } = useActiveFilters()
    const [fetchStatus, setFetchStatus] = useState('idle')
    const [errors, setErrors] = useState(undefined)
    const [roomsAndTimeSlots, setRoomsAndTimeSlots] = useState([])

    const fetchRoomsAndTimeSlots = async () => {
        setFetchStatus('fetching')

        // E.g. 2021-03-30T00:00:00.000Z => 2021-03-30
        /** @type {string} */
        const date = requestedDate.split('T')[0]

        try {
            const result = await callApi(
                METHODS.GET,
                `/venues/${venueId}/availability`,
                {
                    date,
                },
            )

            setRoomsAndTimeSlots(result.data)
            setErrors(undefined)
            setFetchStatus('idle')
        } catch (error) {
            setErrors(error)
            setFetchStatus('error')
        }
    }

    const postRoomsAndTimeSlots = async (
        roomsAndTimeSlotsToPost,
        dateToPost,
        statusToPost,
        commentToPost,
    ) => {
        const requestBody = {
            comment: commentToPost,
            date: dateToPost.format(APP_DATE_FORMAT),
            status: statusToPost,
            roomsAndSlots: roomsAndTimeSlotsToPost,
        }

        try {
            await callApi(
                METHODS.POST,
                `/venues/${venueId}/rooms/time-slots`,
                undefined,
                undefined,
                requestBody,
            )

            setErrors(undefined)
            fetchRoomsAndTimeSlots()
        } catch (error) {
            // error syntax:
            // {
            //   "message": "invalid data given",
            //   "errors": {
            //     "comment": ['comment is required'],
            //   },
            // }

            setErrors(error)
            return new Promise((resolve, reject) => {
                reject(error)
            })
        }

        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
            resolve('All data has been submitted successfully!')
        })
    }

    useEffect(() => {
        fetchRoomsAndTimeSlots()
    }, [requestedDate, venueId])

    return {
        errors,
        roomsAndTimeSlots,
        fetchStatus,
        postRoomsAndTimeSlots,
    }
}

export default useFetchRoomsAndSlots

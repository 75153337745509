import callApi, { METHODS } from './callApi'

export { METHODS } from './callApi'
// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = 'Call API'

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default (store) => (next) => (action) => {
    const callAPI = action[CALL_API]
    if (typeof callAPI === 'undefined') {
        return next(action)
    }

    let { endpoint } = callAPI
    const {
        schema,
        types,
        params,
        method = METHODS.GET,
        entityId,
        body,
        minRequestTime,
    } = callAPI

    if (typeof endpoint === 'function') {
        endpoint = endpoint(store.getState())
    }

    if (typeof endpoint !== 'string') {
        throw new Error('Specify a string endpoint URL.')
    }
    // if (!schema) {
    //   throw new Error('Specify one of the exported Schemas.')
    // }
    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error('Expected an array of three action types.')
    }
    if (!types.every((type) => typeof type === 'string')) {
        throw new Error('Expected action types to be strings.')
    }

    const actionWith = (data) => {
        const finalAction = { ...action, ...data }
        delete finalAction[CALL_API]
        return finalAction
    }

    const [requestType, successType, failureType] = types
    next(actionWith({
        type: requestType,
        params,
        entityId,
        method,
    }))

    return callApi(method, endpoint, params, schema, body, minRequestTime)
        .then((response) => next(actionWith({
            type: successType,
            response,
            params,
            entityId,
            method,
        })))
        .catch((error = {}) => next(actionWith({
            type: failureType,
            error: {
                message: error.message || 'Something bad happened',
                errors: error.errors,
                exception: error.exception,
            },
        })))
}

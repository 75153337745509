import React from 'react'
import { useAppState } from 'components/hooks/useAppState'
import UnauthorizedRoutes from './UnauthorizedRoutes'
import AuthorizedRoutes from './AuthorizedRoutes'

function AppRouter() {
    const appState = useAppState()

    if (!appState.isAuthenticated)
        return <UnauthorizedRoutes />

    return <AuthorizedRoutes />
}

export default AppRouter

import React from 'react'
import { WithActiveModal }from 'components/util/modals/WithActiveModal'
import { MODAL_IDS } from 'util/constants'

function DeleteCouponTimeFrameModalState({
    timeFrame,
    children,
}) {
    return (
        <WithActiveModal
            modalId={MODAL_IDS.DELETE_COUPON_TIME_FRAME}
            entity={timeFrame}
        >
            {children}
        </WithActiveModal>
    )
}

export default DeleteCouponTimeFrameModalState

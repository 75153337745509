import {capitalizeFirstChar, isValidString} from "../string-utils"
import {roundNumber} from "../math-utils"

export function toLegalEntityApiRequest(values) {
    /** @type {string[]} */
    const supportedLanguages = Array.isArray(values.supportedLanguages) ? values.supportedLanguages : []

    const requestBody = {
        public: values.public,
        name: values.name,
        address: values.address,

        termsAndConditionsUrlTranslations: {},
        privacyPolicyUrlTranslations: {},

        supportedLanguages: values.supportedLanguages,

        bic: values.bic,
        rpr: values.rpr,
        accountNumber: values.accountNumber,
        countryCode: values.countryCode,
        vatNumber: values.vatNumber, // game vat number

        vatPercentage: roundNumber(values.vatPercentage / 100.0, 2), // game vat percentage

        openBarVatPercentage: roundNumber(values.openBarVatPercentage / 100.0, 2),
        openBarUnitPriceInclVat: values.openBarUnitPriceInclVat,

        drinksVatPercentage: roundNumber(values.drinksVatPercentage / 100.0, 2),
        drinksUnitPriceInclVat: values.drinksUnitPriceInclVat,

        nachosVatPercentage: roundNumber(values.nachosVatPercentage / 100.0, 2),
        nachosUnitPriceInclVat: values.nachosUnitPriceInclVat,

        drinkTokensVatPercentage: roundNumber(values.drinkTokensVatPercentage / 100.0, 2),
        drinkTokensUnitPriceInclVat: values.drinksUnitPriceInclVat,

        mediaItemId: values.mediaItemId,
    }

    for (let i = 0, length = supportedLanguages.length; i < length; i++) {
        const supportedLanguageCode = capitalizeFirstChar(supportedLanguages[i].toLowerCase())
        const localizedTermsAndConditionsUrl = values["termsAndConditions" + supportedLanguageCode]
        if (isValidString(localizedTermsAndConditionsUrl)) {
            requestBody.termsAndConditionsUrlTranslations[supportedLanguageCode] = localizedTermsAndConditionsUrl
        }
        const localizedPrivacyPolicyUrl = values["privacyPolicy" + supportedLanguageCode]
        if (isValidString(localizedPrivacyPolicyUrl)) {
            requestBody.privacyPolicyUrlTranslations[supportedLanguageCode] = localizedPrivacyPolicyUrl
        }
    }

    return requestBody
}

/**
 * @param {LegalEntityResource} legalEntity
 * @return {Object}
 */
export function mapLegalEntityToValues(legalEntity) {
    const financialConfig = legalEntity.financialConfig
    const companyDetails = financialConfig.companyDetails

    const supportedLanguages = Array.isArray(legalEntity.supportedLanguages) ? legalEntity.supportedLanguages : []

    const values = {
        public: legalEntity.public,
        name: legalEntity.name,
        address: companyDetails.address,
        countryCode: legalEntity.country.code,

        supportedLanguages: supportedLanguages,

        bic: companyDetails.bic,
        rpr: companyDetails.rpr,
        accountNumber: companyDetails.accountNumber,
        vatNumber: companyDetails.vatNumber,

        vatPercentage: roundNumber(legalEntity.vatPercentage * 100.0, 2),

        openBarVatPercentage: roundNumber(legalEntity.openBarVatPercentage * 100.0, 2),
        openBarUnitPriceInclVat: legalEntity.openBarUnitPriceInclVat,

        drinksVatPercentage: roundNumber(legalEntity.drinksVatPercentage * 100.0, 2),
        drinksUnitPriceInclVat: legalEntity.drinksUnitPriceInclVat,

        nachosVatPercentage: roundNumber(legalEntity.nachosVatPercentage * 100.0, 2),
        nachosUnitPriceInclVat: legalEntity.nachosUnitPriceInclVat,

        drinkTokensVatPercentage: roundNumber(legalEntity.drinkTokensVatPercentage * 100.0, 2),
        drinkTokensUnitPriceInclVat: legalEntity.drinkTokensUnitPriceInclVat,

        mediaItem: Array.isArray(financialConfig.mediaItems) && financialConfig.mediaItems.legalEntity !== 0 ? financialConfig.mediaItems[0] : null,
    }

    for (let i = 0, length = supportedLanguages.length; i < length; i++) {
        // E.g. "en"
        const supportedLanguageCode = supportedLanguages[i].toLowerCase()
        const key = capitalizeFirstChar(supportedLanguageCode)
        // E.g. "termsAndConditionsEn"
        values["termsAndConditions" + key] = financialConfig.termsAndConditionsUrlTranslations[supportedLanguageCode]
        values["privacyPolicy" + key] = financialConfig.privacyPolicyUrlTranslations[supportedLanguageCode]
    }

    // console.log("Values:", values)

    return values
}

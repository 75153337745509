import { createStore, applyMiddleware, compose } from 'redux'
import { thunk } from 'redux-thunk'
import api from './middleware/api'
import rootReducer from './reducers/rootReducer'

const devToolsComposeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const enhancer = devToolsComposeEnhancers(applyMiddleware(thunk, api))

const configureReduxStore = (preloadedState) => createStore(
    rootReducer,
    preloadedState,
    enhancer,
)

export default configureReduxStore

import React from 'react'
import { deleteGameSessionForActiveVenue, fetchGameSessionsExtendCurrentParams } from 'store/actions/gameSessions'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    deleteGameSession: (gameSession) => dispatch(deleteGameSessionForActiveVenue(gameSession.id)),
    deleteGameSessionSuccess: (gameSession) => {
        notification.success({
            message: 'Game session deleted!',
            description: (
                <span>
                    Game session is succesfully deleted.
                </span>
            ),
        })
    },
    deleteGameSessionFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchGameSessions: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchGameSessionsExtendCurrentParams(paginationKey))
    },
})

const DeleteGameSessionRequest = withReduxState(null, mapDispatchToProps)

export default DeleteGameSessionRequest

import { editDiscountBanner, fetchDiscountBannersForActiveVenue } from 'store/actions/discountBanners'
import withReduxState from 'components/hoc/withReduxState'
import { notification } from 'antd'

const mapDispatchToProps = (dispatch, ownProps) => ({
    editDiscountBanner: (values) => dispatch(editDiscountBanner(ownProps.discountBanner.id, values)),
    editDiscountBannerSuccess: () => {
        notification.success({
            message: 'Discount banner edited!',
            description: 'The discount banner was successfully edited.',
        })
    },
    editDiscountBannerFailed: (error) => {
        notification.error({
            message: 'Something went wrong',
            description: `${error.message}`,
        })
    },
    fetchDiscountBanners: async () => {
        const { paginationKey } = ownProps
        return dispatch(fetchDiscountBannersForActiveVenue(paginationKey))
    },
})

const EditDiscountBannerRequest = withReduxState(null, mapDispatchToProps)

export default EditDiscountBannerRequest
